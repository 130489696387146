import { createSelector } from "reselect";
import * as _ from "lodash";
import * as selectors from "../selectors";
import * as validators from "../../../shared/validators";

const getCompany = (state, props) =>
    state.companies[props.match.params.companyId];

const getDepartmentId = (_, props) => props.match.params.departmentId;

const getDepartment = createSelector(
    [getCompany, getDepartmentId],
    (company, departmentId) => {
        return _.get(company, `departments.${departmentId}`);
    }
);

export const getMergedDepartmentView = createSelector(
    [getCompany, getDepartment],
    (company, department) => {
        if (company !== undefined && department !== undefined) {
            return {
                ...company.view,
                ...department.view
            };
        }
    }
);

export const getPresentationItems = createSelector(
    [getMergedDepartmentView],
    presentation => {
        if (!presentation) return;
        return [
            {
                displayName: "Faktureringsgrad",
                value: presentation.invoiceFactor,
                key: "invoiceFactor"
            },
            {
                displayName: "Omsetning",
                value: presentation.monthlyTurnover,
                key: "monthlyTurnover"
            },
            {
                displayName: "Utbetal timer knapp",
                value: presentation.withdrawnHoursButton,
                key: "withdrawnHoursButton"
            },
            {
                displayName: "Utbetalt kolonne",
                value: presentation.withdrawnHours,
                key: "withdrawnHours"
            },
            {
                displayName: "Mertid",
                value: presentation.extraHours,
                key: "extraHours"
            },
            {
                displayName: "Tillegg",
                value: presentation.addition,
                key: "addition"
            },
            {
                displayName: "FGN",
                value: presentation.invoiceFactorNormal,
                key: "invoiceFactorNormal"
            },
            {
                displayName: "FGN-F",
                value: presentation.invoiceFactorNormalVacation,
                key: "invoiceFactorNormalVacation"
            }
        ];
    }
);

export const getCompanyCalculationRules = createSelector(
    [selectors.getCompanyCalculationRulesDisplay, getDepartmentId],
    (calculationRules, departmentId) =>
        _.map(calculationRules, rule => {
            return {
                ...rule,
                active:
                    !_.isObject(rule.ignoredDepartments) ||
                    rule.ignoredDepartments[departmentId] !== true
            };
        })
);

export const getDepartmentCalculationRules = createSelector(
    [selectors.getCalculationRulesDisplay, getDepartmentId],
    (calculationRules, departmentId) =>
        _.filter(
            calculationRules,
            rule =>
                _.isString(rule.departmentId) &&
                rule.departmentId === departmentId
        )
);

export const getRelevantCalculationRules = createSelector(
    [selectors.getCalculationRulesDisplay, getDepartmentId],
    (calculationRules, departmentId) => {
        const rules = validators.findRelevantCalculationRules(
            calculationRules,
            departmentId
        );
        return rules;
    }
);
