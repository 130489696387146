import React, { Fragment } from "react";
import Avatar from "../icons/Avatar";
import * as utils from "../../utils";
import { Link } from "react-router-dom";
import Typography from "../Typography";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    linkCell: {
        maxWidth: "calc(100% - 30px)" // Avatar and margin
    },
    nameFont: {
        marginLeft: "8px",
        "&:hover": {
            color: theme.palette.C_LB,
            textDecoration: "underline"
        }
    }
});
const TimeGridAvatar = ({ item, classes }) => {
    return (
        <Fragment>
            <Avatar
                extraSmall
                firstName={item.firstName}
                lastName={item.lastName}
                color={utils.getColorFromId(item.id)}
            />
            <Link to={item.nameLink} className={classes.linkCell}>
                <Typography className={classes.nameFont} singleLine>
                    {item.fullName}
                </Typography>
            </Link>
        </Fragment>
    );
};

export default withStyles(styles)(TimeGridAvatar);
