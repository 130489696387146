import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classnames from "classnames";
import ButtonBase from "@material-ui/core/ButtonBase";

const styles = theme => ({
    root: {
        display: "flex",
        height: theme.spacing.inputHeight,
        ...theme.gridItemBorder,
        borderRadius: "6px",
        "&:hover": {
            borderColor: theme.palette.C_DB40
        },
        borderColor: theme.palette.C_DB15,
        backgroundColor: "white"
    },
    textField: {
        height: theme.spacing.inputHeight,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: `0 ${theme.spacing.gridMargin}`
    },
    icon: {
        width: "16px",
        height: "16px",
        color: theme.palette.C_DB60
    },
    label: {
        marginRight: "10px",
        fontSize: "12px",
        fontWeight: 600
    },
    error: {
        borderColor: theme.palette.C_R
    }
});

class BorderIconButton extends Component {
    render() {
        const {
            classes,
            className,
            icon,
            onClick,
            label,
            iconClassName,
            buttonClassName,
            disabled,
            error,
            ...other
        } = this.props;

        const combinedRootClasses = classnames(
            classes.root,
            { [classes.error]: !!error },
            className
        );
        const buttonClasses = classnames(classes.textField, buttonClassName);

        return (
            <div className={combinedRootClasses} {...other}>
                <ButtonBase
                    onClick={onClick}
                    className={buttonClasses}
                    disableRipple
                    disabled={disabled}
                >
                    {label ? (
                        <Typography variant="body1" className={classes.label}>
                            {label}
                        </Typography>
                    ) : null}
                    {React.cloneElement(icon, {
                        className: classnames(classes.icon, iconClassName)
                    })}
                </ButtonBase>
            </div>
        );
    }
}

export default withStyles(styles)(BorderIconButton);
