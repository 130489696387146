import { connect } from "react-redux";
import {
    notificationActions,
    REFRESH_NOTIFICATIONS,
    REQUEST_UPDATE_NOTIFICATION,
    REQUEST_USER_NOTIFICATION,
    TRACK_EVENT
} from "../../actionTypes";
import { filter, forEach, map, orderBy } from "lodash";
import { withRouter } from "react-router-dom";
import { createSelector } from "reselect";
import NotificationList from "./NotificationList";
import { formatRecentDate } from "../../utils/index";
import { analytics } from "../../utils/strings";

const getSignedInUser = state => state.auth.user;
const getNotifications = state => state.notifications;
const getUsers = state => state.users.items;

const formatDescription = (type, approvedBy) => {
    if (type === notificationActions.PAYOUT_COMPLETED) {
        return "Godkjent utbetaling av";
    } else if (type === notificationActions.PAYOUT_REQUESTED) {
        if (approvedBy) {
            return "Godkjent utbetaling av";
        } else {
            return "Forespør utbetaling av";
        }
    }
};

const getNotificationArray = createSelector([getNotifications], notifications =>
    map(notifications, (not, key) => ({ ...not, key }))
);

const getJoinedNotifications = createSelector(
    [getUsers, getNotificationArray],
    (users, notifications) =>
        map(notifications, (notification, key) => {
            const isConfirmed = Boolean(notification.approvedBy);
            const creator = users[notification.creator] || {};
            const approvedByUser = users[notification.approvedBy] || {};
            const timestamp = isConfirmed
                ? notification.updatedAt
                : notification.timestamp;
            return {
                ...notification,
                firstName: creator.firstName,
                lastName: creator.lastName,
                description: formatDescription(
                    notification.action.type,
                    notification.approvedBy
                ),
                amountDisplay: `${notification.action.amount}t`,
                timestamp: `${formatRecentDate(timestamp)}`,
                timestampDate: new Date(timestamp),
                approvedByFirstName: approvedByUser.firstName,
                approvedByLastName: approvedByUser.lastName
            };
        })
);
const getOrderedNotifications = createSelector(
    [getJoinedNotifications],
    notifications => orderBy(notifications, "timestampDate", "desc")
);

const getNewNotifications = createSelector(
    getOrderedNotifications,
    notifications =>
        filter(
            notifications,
            not => not.isCancelled === false && not.isApproved === false
        )
);

const getOldNotifications = createSelector(
    getOrderedNotifications,
    notifications =>
        filter(
            notifications,
            not => not.isCancelled === true || not.isApproved === true
        )
);

const mapStateToProps = (state, props) => {
    return {
        signedInUser: getSignedInUser(state, props),
        oldNotifications: getOldNotifications(state, props),
        newNotifications: getNewNotifications(state, props)
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const companyId = ownProps.match.params.companyId;
    return {
        requestNotifications: userId => {
            dispatch({ type: REQUEST_USER_NOTIFICATION, userId });
        },
        updateReadNotifications: (notifications, userId) => {
            let count = 0;
            forEach(notifications, not => {
                if (not.isCancelled === false) {
                    dispatch({
                        type: REQUEST_UPDATE_NOTIFICATION,
                        notificationId: not.notificationId,
                        userId,
                        payload: {
                            isCancelled: true
                        }
                    });
                    count += 1;
                }
            });
            dispatch({
                type: TRACK_EVENT,
                payload: {
                    category: analytics.categories.NOTIFICATION,
                    action: analytics.actions.MARK_ALL_NOTIFICATIONS,
                    value: count
                }
            });
        },
        cancelNotification: (notificationId, userId) => {
            dispatch({
                type: REQUEST_UPDATE_NOTIFICATION,
                notificationId: notificationId,
                userId,
                payload: {
                    isCancelled: true
                }
            });
            dispatch({
                type: TRACK_EVENT,
                payload: {
                    category: analytics.categories.NOTIFICATION,
                    action: analytics.actions.MARK_NOTIFICATION
                }
            });
        },
        redirectConfirmNotifications: notification => {
            ownProps.history.push(
                `/dashboard/${companyId}/users/${
                    notification.creator
                }?var=payout&amount=${notification.action.amount}&ref=${
                    notification.notificationId
                }&issuedForDate=${notification.action.issuedForDate}`
            );
            dispatch({ type: REFRESH_NOTIFICATIONS });
            dispatch({
                type: TRACK_EVENT,
                payload: {
                    category: analytics.categories.NOTIFICATION,
                    action: analytics.actions.NOTIFICATION_CLICK
                }
            });
        }
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(NotificationList)
);
