import React from "react";
import List from "./List";
import Typgraphy from "@material-ui/core/Typography";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import Switch from "../forms/Switch";

const SwitchWithTextList = ({ classes, data, title, ...other }) => {
    const renderItem = item => {
        return (
            <ListItem key={item.primary}>
                <ListItemText
                    disableTypography
                    primary={
                        <Typgraphy variant="body1">{item.primary}</Typgraphy>
                    }
                />
                <ListItemSecondaryAction>
                    <Switch
                        disableRipple
                        onChange={() => item.onChange(!item.value)}
                        checked={item.value}
                    />
                </ListItemSecondaryAction>
            </ListItem>
        );
    };
    return (
        <List data={data} title={title} renderItem={renderItem} {...other} />
    );
};

export default SwitchWithTextList;
