import React from "react";
import Avatar from "common/icons/Avatar";
import { getColorFromId } from "../../utils/index";
import PaperCard from "./PaperCard";

const UserCard = ({
    firstName,
    lastName,
    department,
    userTitle,
    userId,
    photoURL,
    onItemClick
}) => {
    return (
        <PaperCard
            avatar={
                <Avatar
                    firstName={firstName}
                    lastName={lastName}
                    color={getColorFromId(userId)}
                    photoURL={photoURL}
                />
            }
            title={`${firstName} ${lastName}`.trim()}
            subtitle={`${department || ""}`}
            onItemClick={onItemClick}
        />
    );
};
export default UserCard;
