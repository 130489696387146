import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import * as utils from "../../utils";
import Avatar from "./Avatar";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
const styles = theme => ({
    nameRoot: {
        display: "flex",
        alignItems: "center",
        "&::firstChild": {
            marginRight: "10px"
        }
    },

    name: {
        marginLeft: "10px",
        fontSize: theme.typography.F_N,
        color: theme.palette.C_DB120,
        marginTop: "1px"
    },
    clickable: {
        "&:hover": {
            color: theme.palette.C_LB
        }
    }
});

class AvatarLabel extends PureComponent {
    render() {
        const {
            classes,
            firstName,
            lastName,
            textClassName,
            id,
            photoURL,
            isClickable
        } = this.props;

        return (
            <div className={classes.nameRoot}>
                <Avatar
                    firstName={firstName}
                    lastName={lastName}
                    photoURL={photoURL}
                    color={utils.getColorFromId(id)}
                    extraSmall
                />

                <Typography
                    variant={"body1"}
                    className={classNames(
                        classes.name,
                        { [classes.clickable]: isClickable },
                        textClassName
                    )}
                    color={"inherit"}
                >{`${firstName} ${lastName}`}</Typography>
            </div>
        );
    }
}

AvatarLabel.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    id: PropTypes.string,
    photoURL: PropTypes.string,
    isClickable: PropTypes.bool
};

AvatarLabel.defaultProps = {
    isClickabled: false
};
export default withStyles(styles)(AvatarLabel);
