import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';
export default function (props) {
    return (
        <SvgIcon {...props} viewBox='0 0 18.75 25'>
            <path d='M14.06,12.11v1.37a.58.58,0,0,1-.58.58H5.27a.58.58,0,0,1-.58-.58V12.11a.59.59,0,0,1,.58-.59h8.21A.59.59,0,0,1,14.06,12.11Zm-.58,3.51H5.27a.59.59,0,0,0-.58.59v1.37a.58.58,0,0,0,.58.58h8.21a.58.58,0,0,0,.58-.58V16.21A.59.59,0,0,0,13.48,15.62Zm5.27-9.18V22.66A2.34,2.34,0,0,1,16.41,25H2.34A2.34,2.34,0,0,1,0,22.66V2.34A2.34,2.34,0,0,1,2.34,0h10A2.31,2.31,0,0,1,14,.69l4.1,4.1A2.31,2.31,0,0,1,18.75,6.44ZM12.5,2.53V6.25h3.72Zm3.91,20.13V8.59H11.33a1.17,1.17,0,0,1-1.17-1.17V2.34H2.34V22.66Z'
            />
        </SvgIcon>

    )
}
