import * as types from "actionTypes";
import { REQUEST_UPDATE_MULT_USERS } from "actionTypes";

export const getUser = userId => {
    return {
        type: types.REQUEST_USER,
        payload: { userId }
    };
};

export const updateUser = (userId, payload) => {
    return {
        type: types.REQUEST_UPDATE_USER,
        userId,
        payload
    };
};

export const setUserDetailsFilters = filters => {
    return {
        type: types.SET_USER_DETAILS_FILTERS,
        payload: filters
    };
};

export const updateMultipleUsers = (
    companyId,
    departmentId,
    userIds,
    payload
) => {
    return {
        type: REQUEST_UPDATE_MULT_USERS,
        userIds,
        companyId,
        departmentId,
        payload
    };
};
