import React from "react";
import KeyPerformanceIndicatorsHeader from "./KeyPerformanceIndicatorsHeader";
import KeyPerformanceIndicatorsBuilder from "./KeyPerformanceIndicatorsBuilder";
import ContentPaper from "../../../components/layout/paper/ContentPaper";

const KeyPerformanceIndicators = props => {
    return (
        <ContentPaper>
            <KeyPerformanceIndicatorsHeader />
            <KeyPerformanceIndicatorsBuilder />
        </ContentPaper>
    );
};

export default KeyPerformanceIndicators;
