import React, { Fragment } from "react";
import InputField from "../../common/forms/text-fields/InputField";
import GridIcon from "../../common/icons/GridIcon";
import ListIcon from "../../common/icons/ListIcon";
import SearchIcon from "../../common/icons/SearchIcon";
import Slider from "../../common/forms/Slider";
import PaperHeader from "../../components/layout/paper/PaperHeader";
import { componentDescriptions, dialog } from "../../constants/strings";
import PaperHeaderTitle from "../../components/layout/paper/PaperHeaderTitle";
import IconPopupMenu from "../../common/poppers/IconPopupMenu";
import moment from "moment";
import SimpleDialog from "../../common/dialogs/SimpleDialog";
import DialogButtonGroup from "../../common/dialogs/dialogContent/DialogButtonGroup";
import SelectInput from "../../common/forms/SelectInput";
import * as _ from "lodash";
import { getObjectTrueValuesCount, getTrueValueKeys } from "../../utils";
import { userGroups } from "actionTypes";
import InputDatePicker from "../../common/forms/date-picker/InputDatePicker";
const primaryTitle = "Brukere";

const options = ["Endre avdeling", "Endre startdato"];

const initialState = {
    startDate: {
        open: false,
        date: moment().startOf("d")
    },
    department: {
        open: false,
        departmentId: null
    }
};
class UsersHeader extends React.Component {
    state = {
        ...initialState
    };

    resetState = () => {
        this.setState(initialState);
    };

    handleMenuChange = index => {
        if (index === 0) {
            this.setState({
                department: { ...this.state.department, open: true }
            });
        } else if (index === 1) {
            this.setState({
                startDate: { ...this.state.startDate, open: true }
            });
        }
    };
    handleConfirm = type => () => {
        const { selected, company, signedInUser } = this.props;
        if (type === "department" && !this.state.department.departmentId) {
            return;
        }

        const userIds = getTrueValueKeys(selected);

        if (userIds.length === 0) return;

        let payload = {};
        if (type === "department") {
            payload = { departmentId: this.state.department.departmentId };
        } else if (type === "startDate") {
            payload = { startDate: this.state.startDate.date };
        }
        const departmentId =
            signedInUser.userGroup === userGroups.DEPARTMENT_LEADER
                ? signedInUser.departmentId
                : undefined;

        this.props.updateMultipleUsers(
            company.id,
            departmentId,
            userIds,
            payload
        );
        this.resetState();
    };
    renderDialogs = numSelected => {
        const { departments } = this.props;

        return (
            <Fragment>
                <SimpleDialog
                    open={this.state.department.open}
                    buttonGroup={
                        <DialogButtonGroup
                            onConfirm={this.handleConfirm("department")}
                            onCancel={this.resetState}
                        />
                    }
                    description={dialog.department.assignMultiple.description}
                    title={dialog.department.assignMultiple.title}
                    name={
                        numSelected === 1
                            ? `${numSelected} bruker valgt`
                            : `${numSelected} brukere valgt`
                    }
                >
                    <SelectInput
                        options={_.map(departments, dep => dep.name)}
                        value={_.findIndex(
                            departments,
                            dep => dep.id === this.state.department.departmentId
                        )}
                        placeholder={`Velg avdeling`}
                        onChange={index =>
                            this.setState({
                                department: {
                                    ...this.state.department,
                                    departmentId: departments[index].id
                                }
                            })
                        }
                    />
                </SimpleDialog>
                <SimpleDialog
                    open={this.state.startDate.open}
                    buttonGroup={
                        <DialogButtonGroup
                            onConfirm={this.handleConfirm("startDate")}
                            onCancel={this.resetState}
                        />
                    }
                    description={dialog.startDate.multiple.description}
                    title={dialog.startDate.multiple.title}
                    name={
                        numSelected === 1
                            ? `${numSelected} bruker valgt`
                            : `${numSelected} brukere valgt`
                    }
                >
                    <InputDatePicker
                        date={this.state.startDate.date}
                        onChange={date =>
                            this.setState({
                                startDate: {
                                    ...this.state.startDate,
                                    date: date
                                }
                            })
                        }
                    />
                </SimpleDialog>
            </Fragment>
        );
    };

    render() {
        const {
            onChangeSearch,
            searchString,
            onChangeDisplayType,
            displayType,
            selected,
            signedInUser
        } = this.props;
        const numSelected = getObjectTrueValuesCount(selected);
        const isGrid = displayType === "GRID";

        return (
            <Fragment>
                <PaperHeader
                    description={componentDescriptions.users}
                    title={<PaperHeaderTitle primary={primaryTitle} />}
                >
                    {signedInUser.userGroup === userGroups.ADMIN &&
                    !isGrid &&
                    numSelected > 0 ? (
                        <IconPopupMenu
                            items={options}
                            onChange={this.handleMenuChange}
                        />
                    ) : null}
                    <InputField
                        leftIcon={<SearchIcon />}
                        value={searchString}
                        onChange={e => onChangeSearch(e.target.value)}
                    />
                    <Slider
                        background={"paper"}
                        selectedTab={isGrid ? 0 : 1}
                        onSelectTab={i =>
                            onChangeDisplayType(i === 0 ? "GRID" : "LIST")
                        }
                        tabs={[<GridIcon />, <ListIcon />]}
                        variant={"icon"}
                    />
                </PaperHeader>
                {this.renderDialogs(numSelected)}
            </Fragment>
        );
    }
}

UsersHeader.defaultProps = {
    activeDepartment: { name: "" }
};
export default UsersHeader;
