import * as _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as types from "../../../actionTypes";
import GenericTable from "common/table/Table";
import * as selectors from "./selectors";
import CellInputField from "common/forms/text-fields/CellInputField";
import * as userSelectors from "selectors/user";
import CellPeoplePicker from "components/pickers/people/CellPeoplePicker";
import * as actionCreators from "actions/company";
import * as companySelectors from "selectors/company";
import IconPopupMenu from "../../../common/poppers/IconPopupMenu";
import { deleteDepartment } from "actions/company";

class DepartmentTable extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                label: "Navn",
                type: "string",
                id: "name",
                width: "25%",
                bold: true,
                renderCell: this.renderName
            },
            {
                label: "Ansatte",
                type: "custom",
                id: "members",
                renderCell: this.renderMembers,
                width: "60%"
            },
            {
                label: "",
                type: "custom",
                id: "more",
                renderCell: this.renderMore,
                width: "5%"
            }
        ];
    }

    renderMembers = (members, item) => {
        return (
            <CellPeoplePicker
                people={this.props.users}
                selected={_.map(members, p => p.id)}
                onAddPerson={userId => this.props.onAddPerson(item.id, userId)}
                onRemovePerson={userId => this.props.onRemovePerson(userId)}
            />
        );
    };

    renderName = (name, department) => {
        return (
            <CellInputField
                onChange={name =>
                    this.props.updateDepartment(
                        this.props.company.id,
                        department.id,
                        { name }
                    )
                }
                initialValue={name}
            />
        );
    };

    renderMore = (_, item) => (
        <IconPopupMenu
            whiteBackground
            items={["Slett avdeling"]}
            highlighted={[0]}
            onChange={index =>
                index === 0
                    ? this.props.deleteDepartment(
                          this.props.company.id,
                          item.id
                      )
                    : null
            }
        />
    );

    render() {
        return (
            <GenericTable
                columns={this.columns}
                data={this.props.departments}
            />
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        departments: selectors.getJoinedDepartments(state, props),
        users: userSelectors.getActiveUsers(state, props),
        company: companySelectors.getCurrentCompany(state, props)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateDepartment: actionCreators.updateDepartment,
        onAddPerson: (departmentId, userId) => {
            dispatch({
                type: types.REQUEST_UPDATE_USER,
                userId,
                payload: { departmentId }
            });
        },
        onRemovePerson: userId => {
            dispatch({
                type: types.REQUEST_UPDATE_USER,
                userId,
                payload: { departmentId: "0" }
            });
        },
        deleteDepartment: (companyId, departmentId) =>
            dispatch(deleteDepartment(companyId, departmentId))
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DepartmentTable)
);
