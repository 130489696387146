import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "../Typography";
import Switch from "common/forms/Switch";
import classNames from "classnames";
import LockIcon from "../icons/LockIcon";
import InfoTag from "../InfoTag";

const styles = theme => ({
    root: {
        height: "35px",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        //borderTop: `solid 1px ${theme.palette.C_DB15}`,
        paddingLeft: "2px" // For borders in other inputs
    },
    label: {
        color: theme.palette.C_DB80
    },
    border: {
        borderBottom: `solid 1px ${theme.palette.C_DB15}`,
        height: "48px"
    },
    font: {
        marginLeft: "4px" // Optically aligned
    },
    lockIcon: {
        height: "15px",
        width: "10px",
        color: theme.palette.C_DB40
    },
    switchContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    switch: {
        position: "relative",
        top: "-3px"
    }
});

class SwitchInput extends PureComponent {
    renderTag = (disabled, description, darkBackground) => {
        if (disabled) {
            return <LockIcon className={this.props.classes.lockIcon} />;
        } else if (description) {
            return (
                <InfoTag
                    description={description}
                    size={"small"}
                    darkBackground={darkBackground}
                />
            );
        }
        return null;
    };
    render() {
        const {
            classes,
            label,
            value,
            variant,
            className,
            onChange,
            disabled,
            labelSize,
            description,
            style,
            darkBackground,
            name
        } = this.props;

        return (
            <div
                className={classNames(
                    classes.root,
                    { [classes.border]: variant === "border" },
                    className
                )}
                style={style}
            >
                <Typography className={classes.font} size={labelSize}>
                    {label}
                </Typography>
                <div className={classes.switchContainer}>
                    {this.renderTag(disabled, description, darkBackground)}

                    <Switch
                        checked={value}
                        onChange={!disabled ? onChange : undefined}
                        className={classes.switch}
                        name={name}
                    />
                </div>
            </div>
        );
    }
}

SwitchInput.propTypes = {
    /**
     * The value of the switch true => on, false => off
     */
    value: PropTypes.bool.isRequired,
    /**
     * A label to be placed text to the switch
     */
    label: PropTypes.string.isRequired,

    /**
     * A function which is called when the switch's value changes
     */
    onChange: PropTypes.func.isRequired,

    variant: PropTypes.oneOf(["border", "default"]),
    labelSize: PropTypes.oneOf(["small", "medium"]),

    /**
     * An optional description of the switch. Will be shown with an infotag
     */
    description: PropTypes.string,

    /*
     * Sets a darker background for the infotag
     */
    darkBackground: PropTypes.bool
};

SwitchInput.defaultProps = {
    variant: "default",
    labelSize: "medium",
    darkBackground: true
};
export default withStyles(styles)(SwitchInput);
