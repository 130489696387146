import React, { Fragment } from "react";
import Typography from "common/Typography";

export default () => {
    return (
        <Fragment>
            <Typography component={"h2"}>1. Introduction</Typography>
            <Typography>
                This page tells you the terms on which you may use our site
                (includes both our website www.busy.no and our application
                app.busy.no), whether as registered user or guest. Please read
                carefully before use.
            </Typography>
            <Typography>
                By using the site, you accept the terms and agree to obey them.
                If you don't accept them, please don't use the site.
            </Typography>
            <Typography component={"h2"}>2. Who We Are</Typography>
            <Typography>
                Busy is operated by DOTS Technologies AS, a Norwegian Limited
                company registered in Norway under company number 914 761 719.
            </Typography>
            <Typography component={"h4"}>
                Some important details about us:
            </Typography>
            <Typography>
                Our office is at: Tordenskiolds gate 2, 0160
            </Typography>
            <Typography>Our VAT number is: 914 761 719 MVA</Typography>
            <Typography component={"h2"}>3. Use of the Site</Typography>
            <Typography>
                You have permission for temporary use of the site, but we can
                withdraw or change our service at any time without telling you
                and without being legally responsible to you.
            </Typography>
            <Typography>
                You must treat all identification codes, passwords and other
                security information as confidential. If we think you have
                failed to keep confidentiality, we are allowed to disable any
                security information (including your passwords and codes).
            </Typography>
            <Typography>
                You agree to follow our Acceptable Use Policy.
            </Typography>
            <Typography>
                If you allow anyone else to use our site, you must make sure
                that they read these terms first, and that they follow them.
            </Typography>
            <Typography>
                Only use the site as allowed by law and these terms. If you
                don't, we may suspend your usage, or stop it completely.
            </Typography>
            <Typography>
                We frequently update the site and make changes to it, but we
                don't have to do this, and material on the site may be
                out-of-date. No material on the site is intended to contain
                advice, and you shouldn't rely on it. We exclude all legal
                responsibility and costs for reliance placed on the site by
                anyone.
            </Typography>
            <Typography>
                We follow our Privacy Policy in handling information about you.
            </Typography>
            <Typography>
                By using the site, you agree to us handling this information and
                confirm that data you provide is accurate.
            </Typography>
            <Typography component={"h2"}>
                4. Intellectual Property Rights
            </Typography>
            <Typography>
                We are the owner or licensee of all intellectual property rights
                in the site (for example the copyright and any rights in the
                designs) and in any of the material posted on it. They are
                protected by copyright.
            </Typography>
            <Typography>
                You are allowed to print one copy and download extracts of any
                page on the site for your personal reference, but not for
                commercial use without a licence from us. You must not alter
                anything, or use any illustrations, video, audio or photographs
                separately from the text that goes with them.
            </Typography>
            <Typography>
                If you breach these terms, you lose your right to use our site,
                and must destroy or return any copies you have made.
            </Typography>
            <Typography component={"h2"}>
                5. Our Legal Responsibility to You
            </Typography>
            <Typography>
                We do not guarantee the accuracy of material on our site. As far
                as legally possible, we exclude legal responsibility for the
                following:
            </Typography>
            <Typography>
                Any loss to you arising from use of our site.
            </Typography>
            <Typography>
                Loss of income, profit, business, data, contracts, goodwill or
                savings.
            </Typography>
            <Typography>
                We also exclude, as far as legally possible, all terms and
                warranties or promises implied by law or by statutes.
            </Typography>
            <Typography>
                We don't exclude legal responsibility for death or personal
                injury owing to our negligence, or legal responsibility for
                fraud or fraudulent misrepresentation, or for anything else
                where exclusion is not allowed by the law.
            </Typography>
            <Typography component={"h2"}>6. Uploading to our Site</Typography>
            <Typography>
                If you contact other users of our site or upload material to it,
                you must follow our Acceptable Use Policy, which sets out
                standards for usage. You agree to reimburse us for any costs or
                expenses we incur as a result of any breach of this term.
            </Typography>
            <Typography>
                Material that you upload will be regarded as non-confidential
                and not owned. This means that we can copy it, distribute it,
                and show it to other people for any purpose. You agree that if
                anyone else claims to own the material, or says that it breaches
                their rights, we can give them your identity.
            </Typography>
            <Typography>
                We won't be legally responsible to anybody for the accuracy of
                material that you upload to the site, and we can remove it at
                any time if we think it doesn't follow our acceptable use
                policy.
            </Typography>
            <Typography component={"h2"}>7. Computer Offences</Typography>
            <Typography>
                If you do anything which is a criminal offence under a law
                called the Computer Misuse Act 1990, your right to use the site
                will end straightaway. We will report you to the relevant
                authorities and give them your identity.
            </Typography>
            <Typography>
                Examples of computer misuse include introducing viruses, worms,
                Trojans and other technologically harmful or damaging material.
            </Typography>
            <Typography>
                You mustn't try to get access to our site or server or any
                connected database or make any 'attack' on the site. We won't be
                legally responsible to you for any damage from viruses or other
                harmful material that you pick up via our site.
            </Typography>
            <Typography component={"h2"}>8. Links to Our Site</Typography>
            <Typography>
                You are allowed to make a legal link to our website's homepage
                from your website if the content on your site meets the
                standards of our acceptable use policy. We can end this
                permission at any time.
            </Typography>
            <Typography>
                You mustn't suggest any endorsement by us or association with us
                unless we agree in writing.
            </Typography>
            <Typography component={"h2"}>9. Links From Our Site</Typography>
            <Typography>
                Links from our site to other sites are only for information. We
                don't accept responsibility for other sites or any loss you
                suffer from using them.
            </Typography>
            <Typography component={"h2"}>11. Trade Mark</Typography>
            <Typography>Busy is our Norwegian Registered trademark.</Typography>
            <Typography component={"h2"}>12. Applicable Law</Typography>
            <Typography>
                <Typography component={"span"} bold>
                    12.1
                </Typography>{" "}
                The Parties will use their best efforts to negotiate in good
                faith and settle any dispute that may arise out of or relate to
                this Agreement or any breach of it.
            </Typography>
            <Typography>
                <Typography component={"span"} bold>
                    12.2
                </Typography>{" "}
                If any such dispute cannot be settled amicably through ordinary
                negotiations between the Parties, or either or both is or are
                unwilling to engage in this process, either Party may propose to
                the other in writing that structured negotiations be entered
                into with the assistance of a fully accredited mediator before
                resorting to litigation.
            </Typography>
            <Typography>
                <Typography component={"span"} bold>
                    12.3
                </Typography>{" "}
                All negotiations connected with the relevant dispute(s) will be
                conducted in confidence and without prejudice to the rights of
                the Parties in any further proceedings.
            </Typography>
            <Typography>
                <Typography component={"span"} bold>
                    12.4
                </Typography>{" "}
                If the Parties agree on a resolution of the dispute at
                mediation, the agreement shall be reduced to writing and, once
                signed by the duly authorised representatives of both Parties,
                shall be final and binding on them.
            </Typography>
            <Typography>
                <Typography component={"span"} bold>
                    12.5
                </Typography>{" "}
                Any dispute shall not affect the Parties' ongoing obligations
                under the{" "}
            </Typography>
            <Typography>
                <Typography component={"span"} bold>
                    12.6
                </Typography>{" "}
                The Norwegian courts have the only right to hear claims related
                to our site, and all disputes are governed by Norwegian law.
            </Typography>
            <Typography component={"h2"}>13. Contact Us</Typography>
            <Typography>
                Please email us at support@busy.no to contact us about any
                issues.
            </Typography>
        </Fragment>
    );
};
