import * as actions from "../../actionTypes";

export default function userLogs(state = {}, action) {
    switch (action.type) {
        case actions.SET_USER_LOGS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}
