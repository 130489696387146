import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "../../Typography";
import classnames from "classnames";
import Button from "../CustomButton";
import PropTypes from "prop-types";

const styles = theme => ({
    root: {
        display: "flex",
        height: "30px",
        width: "100px",
        borderRadius: "50%",
        minWidth: "40px",
        boxShadow: "none",
        minHeight: "30px",
        padding: "0",
        backgroundColor: theme.palette.C_W,
        color: theme.palette.C_DB120,
        border: `solid 1px ${theme.palette.C_DB15}`,
        "&:hover": {
            color: theme.palette.C_LB,
            backgroundColor: theme.palette.C_W,
            border: `solid 1px ${theme.palette.C_DB20}`
        }
    },
    travel: {
        boxShadow: "none",
        "&:hover, &:focus": {
            boxShadow: "none"
        }
    },

    icon: {
        width: "10px",
        height: "10px",
        color: "inherit"
    },
    largeIcon: {
        width: "12px",
        height: "12px"
    },
    labelLeft: {
        marginLeft: "4px"
    },
    labelRight: {
        marginRight: "4px"
    },
    iconOnly: {
        width: "40px",
        height: "40px"
    },
    disabled: {
        color: theme.palette.C_DB40,
        backgroundColor: theme.palette.C_W,
        "&:hover": {
            color: theme.palette.C_DB40,
            backgroundColor: theme.palette.C_W
        },
        cursor: "initial"
    },
    hidden: {
        opacity: "0",
        cursor: "initial"
    }
});

class CustomIconButton extends Component {
    render() {
        const {
            classes,
            className,
            icon,
            label,
            iconClassName,
            labelClassName,
            largeIcon,
            variant,
            disabled,
            hidden,
            iconSide,
            ...other
        } = this.props;

        const cominedRootClasses = classnames(
            classes.root,
            {
                [classes.travel]: variant === "travel",
                [classes.iconOnly]: !label,
                [classes.disabled]: disabled,
                [classes.hidden]: hidden
            },
            className
        );

        return (
            <Button className={cominedRootClasses} {...other}>
                {icon && iconSide === "left"
                    ? React.cloneElement(icon, {
                          className: classnames(
                              classes.icon,
                              { [classes.largeIcon]: largeIcon },
                              iconClassName
                          ),
                          style: { marginLeft: this.props.offset || 0 }
                      })
                    : null}
                {label ? (
                    <Typography
                        semibold
                        color={"inherit"}
                        className={classnames(
                            {
                                [classes.labelLeft]: Boolean(
                                    icon && iconSide === "left"
                                ),
                                [classes.labelRight]: Boolean(
                                    icon && iconSide === "right"
                                )
                            },
                            labelClassName
                        )}
                        size={"smallMed"}
                    >
                        {label}
                    </Typography>
                ) : null}
                {icon && iconSide === "right"
                    ? React.cloneElement(icon, {
                          className: classnames(
                              classes.icon,
                              { [classes.largeIcon]: largeIcon },
                              iconClassName
                          ),
                          style: { marginLeft: this.props.offset || 0 }
                      })
                    : null}
            </Button>
        );
    }
}

CustomIconButton.propTypes = {
    /**
     * If travel, will remove all shadows
     */
    variant: PropTypes.oneOf(["action", "travel"]),

    /**
     * The icon to show
     */
    icon: PropTypes.node.isRequired,

    /**
     * Which side of the label the icon is shown
     */
    iconSide: PropTypes.oneOf(["left", "right"]),

    /**
     * The label to show aside the icon
     */
    label: PropTypes.string,
    largeIcon: PropTypes.bool
};
CustomIconButton.defaultProps = {
    variant: "action",
    largeIcon: false,
    iconSide: "left"
};

export default withStyles(styles)(CustomIconButton);
