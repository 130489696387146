import { isNumber } from "lodash";
import Typography from "@material-ui/core/Typography";
import { green, red } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import DownArrowIcon from "../../../common/icons/DownArrowIcon";
import UpArrowIcon from "../../../common/icons/UpArrowIcon";
import * as utils from "../../../utils/index";

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: theme.spacing.keyFigureWidth,
        marginRight: theme.spacing.gridMargin,
        borderRadius: theme.spacing.cornerRadius,
        overflow: "hidden",
        ...theme.gridItemBorder
    },
    label: {
        marginBottom: "5px"
    },
    danger: {
        color: red[500]
    },
    positive: {
        color: green[500]
    },
    headContainer: {
        backgroundColor: theme.palette.C_DB3,
        height: theme.spacing.keyFigureTopHeight,
        width: "100%",
        display: "flex",
        alignItems: "center",
        borderBottom: `solid 1px ${theme.palette.C_DB10}`
    },
    bodyContainer: {
        backgroundColor: theme.palette.C_W,
        height: theme.spacing.keyFigureBotHeight,
        width: "100%"
    },
    head: {
        paddingLeft: theme.spacing.gridMargin,
        paddingRight: theme.spacing.gridMargin
    },
    body: {
        paddingLeft: theme.spacing.gridMargin,
        paddingRight: theme.spacing.gridMargin,
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "space-between",
        height: "100%"
    },
    textContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        marginTop: "3px"
    },
    posIcon: {
        color: theme.palette.C_G
    },
    posGraph: {
        width: "50px",
        color: theme.palette.C_G,
        height: "auto"
    },
    negGraph: {
        width: "50px",
        color: theme.palette.C_R,
        height: "auto"
    },
    neutralGraph: {
        color: theme.palette.C_AB,
        width: "50px",
        height: "auto"
    },
    negArrow: {
        color: theme.palette.C_R,
        height: "11px",
        width: "15px",
        marginBottom: "2px",
        marginRight: "3px"
    },
    posArrow: {
        color: theme.palette.C_G,
        height: "11px",
        width: "15px",
        marginBottom: "2px",
        marginRight: "3px"
    },
    graphContainer: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        marginBottom: `calc(${theme.spacing.gridMargin} - 10px)`
    },
    header: {
        marginBottom: `calc(${theme.spacing.gridMargin} - 9px)`,
        whiteSpace: "nowrap",
        fontSize: theme.typography.F_M
    },
    headerText: {
        fontWeight: "600",
        fontSize: theme.typography.F_S_N
    },
    change: {
        whiteSpace: "nowrap",
        fontSize: theme.typography.F_S_N
    }
});

class KeyFigure extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        value: PropTypes.number,
        change: PropTypes.number,
        type: PropTypes.oneOf(["percent", "hours", "days"]),
        changeType: PropTypes.oneOf(["percent", "hours", "days"])
    };
    static defaultProps = {
        change: null,
        type: "hours",
        changeType: "hours",
        value: 0
    };

    renderChange = (change, followupLetter) => {
        const { classes, theme } = this.props;
        let iconData = {};
        if (change === null) return;

        if (change > 0) {
            iconData = {
                text: utils.formatThousands(Math.abs(change)) + followupLetter,
                color: theme.palette.C_G,
                textIcon: <UpArrowIcon className={classes.posArrow} />
            };
        } else if (change < 0) {
            iconData = {
                text: utils.formatThousands(Math.abs(change)) + followupLetter,
                color: theme.palette.C_R,
                textIcon: <DownArrowIcon className={classes.negArrow} />
            };
        } else {
            iconData = {
                text: utils.formatThousands(change) + followupLetter,
                color: theme.palette.C_DB30,
                textIcon: null
            };
        }
        return (
            <div className={classes.textContainer}>
                {iconData.textIcon}
                <Typography
                    variant="body2"
                    style={{ color: iconData.color }}
                    className={classes.change}
                >
                    {iconData.text}
                </Typography>
            </div>
        );
    };

    renderByNormalHours = value => {
        const { classes } = this.props;
        return (
            <div className={classes.textContainer}>
                <Typography
                    title={"Prosent av normaltid"}
                    variant="body2"
                    className={classes.change}
                >
                    {value + "%"}
                </Typography>
            </div>
        );
    };

    render() {
        const {
            classes,
            name,
            value,
            change,
            byNormalHours,
            type
        } = this.props;

        let followupLetter;

        //
        const originalValue = value !== null ? value : 0; //Present number even if null

        const roundedValue = utils.round(originalValue, 2); // Force two decimals
        const hasFormattedValue = roundedValue !== value;
        const formattedByThousand = utils.formatThousands(roundedValue);

        if (type === "percent") {
            followupLetter = "%";
        } else if (type === "hours") {
            followupLetter = "t";
        } else if (type === "currency") {
            followupLetter = "'";
        } else {
            followupLetter = "d";
        }

        return (
            <div className={classes.root}>
                <div className={classes.headContainer}>
                    <div className={classes.head}>
                        <Typography
                            className={classes.headerText}
                            variant="body2"
                        >
                            {name}
                        </Typography>
                    </div>
                </div>
                <div className={classes.bodyContainer}>
                    <div className={classes.body}>
                        <Typography
                            className={classes.header}
                            variant="display2"
                            title={
                                hasFormattedValue ? originalValue : undefined
                            }
                        >
                            {formattedByThousand + followupLetter}
                        </Typography>
                        <div className={classes.graphContainer}>
                            {!isNumber(byNormalHours) && isNumber(change)
                                ? this.renderChange(change, followupLetter)
                                : null}
                            {isNumber(byNormalHours)
                                ? this.renderByNormalHours(byNormalHours)
                                : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(KeyFigure);
