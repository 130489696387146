import classNames from "classnames";
import { forEach, isFunction } from "lodash";
import MoreIcon from "common/icons/MoreIcon";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import Dropdown from "../dropdown/Dropdown";

const styles = theme => ({
    root: {
        display: "flex",
        zIndex: 10000,
        maxHeight: "500px",
        color: theme.palette.C_DB30
    },
    popperClose: {
        pointerEvents: "none"
    },
    menuItem: {
        height: "50px",
        minWidth: "150px"
    },
    menuTypo: {
        textOverflow: "ellipsis",
        display: "inline",
        whiteSpace: "nowrap",
        overflowX: "hidden",
        fontSize: theme.typography.F_S_N,
        fontWeight: 600
    },
    button: {
        "&:hover": {
            color: theme.palette.C_AB
        }
    }
});

class Menu extends React.Component {
    state = {
        open: false
    };

    handleToggle = open => {
        this.setState({ open });
    };

    onClick = item => {
        this.props.onSelect(item);
        this.setState({ open: false });
    };

    render() {
        const {
            classes,
            options,
            icon,
            buttonClassName,
            renderOption
        } = this.props;
        const { open } = this.state;

        const menuItems = [];

        if (open) {
            forEach(options, (option, i) => {
                menuItems.push(
                    <MenuItem
                        onClick={() => this.onClick(option)}
                        className={classes.menuItem}
                        key={option}
                    >
                        {isFunction(renderOption) ? (
                            renderOption(option, i)
                        ) : (
                            <Typography
                                variant={"body2"}
                                className={classes.menuTypo}
                            >
                                {option}
                            </Typography>
                        )}
                    </MenuItem>
                );
                if (i !== options.length - 1) {
                    menuItems.push(<Divider key={option + "_divider"} />);
                }
            });
        }

        return (
            <Dropdown
                onToggle={this.handleToggle}
                open={open}
                targetRef={target => (this.target = target)}
                target={
                    <IconButton
                        aria-label="More"
                        aria-owns={open ? "long-menu" : null}
                        aria-haspopup="true"
                        className={classNames(classes.button, buttonClassName)}
                        disableRipple
                    >
                        {icon}
                    </IconButton>
                }
                placement="bottom-end"
            >
                {menuItems}
            </Dropdown>
        );
    }
}

Menu.propTypes = {
    classes: PropTypes.object.isRequired,
    /**
     * A custom icon. Defaults to vertical dots.
     */
    icon: PropTypes.node,
    /**
     * List of options in menu
     */
    options: PropTypes.array,
    /**
     * Called with the option value on click
     */
    onSelect: PropTypes.func,
    /**
     * Used for rendering a custom menu item.
     * An example is icons with menu icons
     */
    renderOption: PropTypes.func
};

Menu.defaultProps = {
    icon: <MoreIcon />
};

export default withStyles(styles)(Menu);
