import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { REQUEST_ERROR } from "./actionTypes";
import SimpleDialog from "./common/dialogs/SimpleDialog";
import { Login, ContentView, Register } from "./common";
import { connect } from "react-redux";
import NotFound from "./common/NotFound";
import DefaultErrorBoundry from "./common/errors/DefaultErrorBoundry";
import DialogButtonGroup from "./common/dialogs/dialogContent/DialogButtonGroup";
import NotificatorController from "./containers/globals/notificator/NotificationController";
import Intercom from "./containers/globals/Intercom";
import Modal from "./containers/modals";
import Tutorials from "./containers/on-boarding/tutorials";
import Loader from "./containers/globals/Loader";
/**
 * This component handles routing.
 * It is also in control of the global error dialog,
 * by listening for changes to request error in redux state.
 * When an error has been approved (ok pressed), it will
 * dispatch an action to remove the current error from redux state.
 */
class AppContainer extends Component {
    render() {
        const { location, appState, dispatch } = this.props;

        if (location.pathname === "/") {
            return <Redirect to={"/login"} />;
        }

        return (
            <DefaultErrorBoundry>
                <div>
                    <NotificatorController />
                    <Intercom appID="op9na2to" />
                    <Modal />
                    <Loader />
                    <Switch>
                        <Route path="/login" component={Login} />
                        <Route path="/register" component={Register} />
                        <Route path="/tutorial/:topic" component={Tutorials} />
                        <Route
                            exact
                            path="/dashboard/:companyId/"
                            component={ContentView}
                        />
                        <Route
                            exact
                            path="/users/:companyId"
                            component={ContentView}
                        />
                        <Route
                            exact
                            path="/users/:companyId/user/:userId"
                            component={ContentView}
                        />
                        <Route
                            path="/settings/:companyId"
                            component={ContentView}
                        />
                        <Route
                            exact
                            path="/dashboard/:companyId/users/:userId"
                            component={ContentView}
                        />
                        <Route path="*" component={NotFound} />
                    </Switch>

                    <SimpleDialog
                        open={Boolean(
                            appState.requestError &&
                                appState.requestError.displayMessage
                        )}
                        name={
                            appState.requestError
                                ? appState.requestError.statusCode
                                : ""
                        }
                        buttonGroup={
                            <DialogButtonGroup
                                type="ok"
                                onConfirm={() =>
                                    dispatch({
                                        type: REQUEST_ERROR,
                                        payload: null
                                    })
                                }
                            />
                        }
                        title="En feil har oppstått"
                        description={
                            appState.requestError &&
                            appState.requestError.displayMessage
                                ? appState.requestError.displayMessage
                                : "En feil har oppstått, forsøk å last siden på nytt."
                        }
                    />
                </div>
            </DefaultErrorBoundry>
        );
    }
}

export default connect(state => ({
    appState: state.appState
}))(AppContainer);
