import classnames from "classnames";
import * as _ from "lodash";
import { isArray, orderBy as orderByFunc } from "lodash";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import TableCell from "./cells/TableCell";
import TableColumnHeader, { TableColumnHeaderProps } from "./TableColumnHeader";
import Checkbox from "../forms/Checkbox";
import List from "react-virtualized/dist/commonjs/List";

const styles = theme => ({
    table: {
        maxWidth: "100%",
        borderCollapse: "separate",
        height: "calc(100% - 5px)" // Fix for unwanted scrollbar in parent
    },
    tableWrapper: {
        height: "calc(100% - 80px)", // Without header
        overflowX: "auto",
        width: "100%",
        borderTopLeftRadius: "6px",
        borderTopRightRadius: "6px",
        borderLeft: `solid 1px ${theme.palette.C_DB10}`,
        borderRight: `solid 1px ${theme.palette.C_DB10}`
    },
    tableRow: {
        display: "flex",
        justifyContent: "space-between"
    },
    tableBody: {
        overflowY: "auto",
        outline: "none",
        height: "calc(100% - 37px)" // Height of table head
    },
    placeholder: {
        margin: "15px 50px",
        backgroundColor: theme.palette.C_DB10,
        borderRadius: "6px",
        height: "25px"
    }
});
const containerStyle = { overflow: "visible" };

class Table extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            orderBy: props.initialOrder.orderBy,
            direction: props.initialOrder.direction,
            hasChangedOrder: false,
            tableRef: null
        };
        this.debouncedResize = _.debounce(this.onResize, 500);
    }

    componentWillMount() {
        const { orderBy, order } = this.state;

        this.orderDataInitial(this.props.data, orderBy, order);
    }
    componentDidMount() {
        window.addEventListener("resize", this.debouncedResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.debouncedResize);
    }
    onResize = () => {
        this.forceUpdate();
    };

    componentWillReceiveProps(nextProps) {
        const { orderBy, direction } = this.state;

        this.orderDataInitial(nextProps.data, orderBy, direction);
    }
    orderDataInitial(data, orderBy, direction) {
        //Reorder initial data by name asc
        if (isArray(data)) {
            const dataCopy = [...data];

            const orderedData = orderByFunc(dataCopy, orderBy, direction);
            this.setState({ data: orderedData });
        }
    }

    findSortDirection = (columnId, numeric) => {
        const { direction, orderBy } = this.state;

        // If the clicked row is the same as the already sorted row, switch sort direction
        if (columnId === orderBy) {
            return direction === "asc" ? "desc" : "asc";
        }
        //if the column is numeric, initial sort should be desc, otherwise asc

        return numeric ? "asc" : "desc";
    };

    handleSort = (columnId, numeric) => {
        const { data, orderBy } = this.state;
        const { onSort } = this.props;

        const direction = this.findSortDirection(columnId, numeric);

        const column = _.find(this.props.columns, col => col.id === columnId);
        const orderByValue = column.orderFunc ? column.orderFunc : orderBy;
        const orderedData = _.orderBy(data, orderByValue, direction);

        this.setState({
            data: orderedData,
            orderBy: columnId,
            direction,
            hasChangedOrder: true
        });

        if (typeof onSort === "function") onSort(columnId);
    };
    handleTableRef = r => {
        this.setState({ tableRef: r });
    };

    renderPlaceholder = () => {
        const { placeholder } = this.props;
        return _.isString(placeholder) ? (
            <ListItem disableTypography>
                <Typography variant="body1">{placeholder}</Typography>
            </ListItem>
        ) : (
            placeholder
        );
    };

    renderRow = ({ index, isVisible, key, parent, style, isScrolling }) => {
        const { data } = this.state;
        const {
            classes,
            columns,
            RowComponent,
            checkbox,
            renderFooter
        } = this.props;

        if (renderFooter && index === data.length) {
            return renderFooter(style); // Last row
        }

        const item = data[index];
        const withCheckbox = Boolean(checkbox);

        const tableCells = [];
        if (withCheckbox) {
            tableCells.push(
                <div key={"checkbox"}>
                    <Checkbox
                        onChange={e => checkbox.onCheckboxChange(e, item)}
                        className={classes.checkbox}
                        checked={Boolean(checkbox.selected[item.id])}
                    />
                </div>
            );
        }
        _.forEach(columns, (column, columnIndex) => {
            tableCells.push(
                <TableCell
                    key={column.id}
                    item={item}
                    column={column}
                    tot={columns.length}
                    index={columnIndex}
                    isScrolling={isScrolling}
                />
            );
        });

        return React.cloneElement(RowComponent, {
            key: key,
            className: classes.tableRow,
            item: item,
            columns: columns,
            children: tableCells,
            style: style,
            index
        });
    };
    handleListRef = r => {
        this.list = r;
        this.props.listRef && this.props.listRef(r);
    };

    renderTable = shouldRenderPlaceholder => {
        const { data, direction, orderBy, hasChangedOrder } = this.state;
        const {
            classes,
            columns,
            style,
            checkbox,
            bodyStyle,
            rowCount,
            cache
        } = this.props;

        return (
            <div
                className={classes.table}
                data-test-id="table"
                style={style}
                ref={this.handleTableRef}
            >
                <TableColumnHeader
                    columns={columns}
                    orderBy={hasChangedOrder ? orderBy : null}
                    direction={direction}
                    onSort={this.handleSort}
                    checkbox={checkbox}
                    numbRows={data.length}
                />
                {shouldRenderPlaceholder ? (
                    this.renderPlaceholder()
                ) : (
                    <List
                        ref={this.handleListRef}
                        className={classes.tableBody}
                        data-test-id="table-body"
                        style={bodyStyle}
                        height={
                            this.state.tableRef
                                ? this.state.tableRef.clientHeight - 37 // Without header
                                : 0
                        }
                        rowHeight={cache ? cache.rowHeight : 50}
                        rowCount={rowCount ? rowCount : data.length}
                        width={5000} // Set a width larger than auto (will be used as max-width)
                        autoWidth
                        rowRenderer={
                            this.props.renderRow
                                ? this.props.renderRow(data)
                                : this.renderRow
                        }
                        containerStyle={containerStyle}
                        deferredMeasurementCache={
                            cache
                        } /* To ensure list is rerendered on change*/
                        data={data}
                        checkbox={checkbox}
                    />
                )}
            </div>
        );
    };

    render() {
        const { classes, className, placeholder } = this.props;
        const { data } = this.state;

        const shouldRenderPlaceholder =
            placeholder !== undefined && data.length === 0;

        return (
            <div className={classnames(classes.tableWrapper, className)}>
                {this.renderTable(shouldRenderPlaceholder)}
            </div>
        );
    }
}

Table.propTypes = {
    /***
     * Columns as described in TableColumnHeader
     */
    columns: TableColumnHeaderProps.columns,

    /**
     * The full object of data to render. Should match the columns described in columns prop
     */
    data: PropTypes.arrayOf(PropTypes.object),

    /**
     * Used to order the data initially. Will be the direction all data will be sorted by on initial load and when data is updated
     */
    initialOrder: PropTypes.shape({
        direction: PropTypes.oneOf(["asc", "desc"]),
        orderBy: PropTypes.string // The id of the column to order by
    }),
    RowComponent: PropTypes.node,
    footer: PropTypes.node,

    checkbox: PropTypes.shape({
        onCheckboxChange: PropTypes.func,
        onSelectAll: PropTypes.func,
        numSelected: PropTypes.number,
        selected: PropTypes.object
    }),
    /**
     * Total number of rows. Should include expanded rows and footers
     */
    rowCount: PropTypes.number,

    /**
     * A custom cache. See react-virtualized docs.
     */
    cache: PropTypes.any,

    /**
     * A custom row rendering function. See react-virtualized docs
     */
    renderRow: PropTypes.func,
    listRef: PropTypes.func
};

Table.defaultProps = {
    data: [],
    footer: null,
    initialOrder: {
        direction: "asc",
        orderBy: null
    },
    styles: {},
    RowComponent: <div />,
    checkbox: null
};

export default withStyles(styles)(Table);
