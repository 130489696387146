import React, { Component } from "react";
import Card from "../../../../components/layout/card/Card";
import CardHeader from "../../../../components/layout/card/CardHeader";
import CardForm from "../../../../components/layout/card/CardForm";
import SwitchInput from "../../../../common/forms/SwitchInput";
import * as _ from "lodash";
import HiddenButtonGroup from "../../../../common/forms/buttons/HiddenButtonGroup";
import Button from "../../../../common/forms/buttons/Button";
import PropTypes from "prop-types";
import CardDividerGroup from "../../../../components/layout/card/CardDividerGroup";

class DisplaySettingsCard extends Component {
    state = {
        displaySettings: {},
        hasInitialStateReset: false
    };

    componentDidMount() {
        this.resetState();
    }
    componentDidUpdate(prevProps) {
        if (this.props.displaySettings !== prevProps.displaySettings) {
            this.resetState();
        }
    }
    resetState = () => {
        const displaySettings = {};
        if (_.isEmpty(this.props.displaySettings)) return null;

        _.forEach(this.props.items, item => {
            displaySettings[item.id] = this.props.displaySettings[item.id];
        });

        this.setState({ displaySettings, hasInitialStateReset: true });
    };

    getChangedInput = () => {
        const updatedValues = {};
        _.forEach(this.props.items, item => {
            if (
                this.props.displaySettings[item.id] !==
                this.state.displaySettings[item.id]
            ) {
                updatedValues[item.id] = this.state.displaySettings[item.id];
            }
        });
        return updatedValues;
    };
    hasInputChanged = () => {
        return !_.isEmpty(this.getChangedInput());
    };
    handleItemSwitch = id => e => {
        this.setState({
            displaySettings: {
                ...this.state.displaySettings,
                [id]: !this.state.displaySettings[id]
            }
        });
    };
    handleConfirm = () => {
        const update = this.getChangedInput();

        this.props.updateDisplaySettings(this.props.companyId, update);
    };
    render() {
        const { items, title } = this.props;
        const { displaySettings } = this.state;

        const canSubmit = this.hasInputChanged();

        const itemViews = !_.isEmpty(displaySettings)
            ? _.map(items, item => (
                  <SwitchInput
                      key={item.id}
                      onChange={this.handleItemSwitch(item.id)}
                      label={item.label}
                      value={displaySettings[item.id]}
                      description={item.desc}
                      name={item.id}
                  />
              ))
            : null;

        return (
            <Card>
                <CardHeader title={title} />
                <CardForm>
                    <CardDividerGroup>{itemViews}</CardDividerGroup>
                    <HiddenButtonGroup show={canSubmit}>
                        <Button variant={"secondary"} onClick={this.resetState}>
                            {"Forkast"}
                        </Button>
                        <Button
                            onClick={this.handleConfirm}
                            variant={"primary"}
                        >
                            {"Lagre"}
                        </Button>
                    </HiddenButtonGroup>
                </CardForm>
            </Card>
        );
    }
}

DisplaySettingsCard.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            desc: PropTypes.string,
            label: PropTypes.string
        })
    ),
    displaySettings: PropTypes.object,
    title: PropTypes.string.isRequired,
    updateDisplaySettings: PropTypes.func.isRequired
};
DisplaySettingsCard.defaultProps = {
    displaySettings: {}
};

export default DisplaySettingsCard;
