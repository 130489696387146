import * as _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import Popup from "common/poppers/Popup";
import MiniSearchField from "common/forms/mini/MiniSearchField";
import Typography from "common/Typography";
import PopupButton from "common/poppers/PopupButton";
import ButtonBase from "components/buttons/ButtonBase";
import MenuItem from "common/poppers/MenuItem";
import Radio from "common/forms/Radio";
import FormControl from "common/forms/FormControl";
import classNames from "classnames";
import InputField from "common/forms/text-fields/InputField";

const styles = theme => ({
    root: {
        maxHeight: "375px",
        width: "250px"
    },
    rootMultiple: {
        width: "350px"
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 14px",
        height: "50px",
        backgroundColor: theme.palette.C_DB5,
        borderBottom: `solid 1px ${theme.palette.C_DB10}`
    },
    popper: {
        marginTop: "15px"
    },
    target: {
        width: "100%"
    },
    placeholder: {
        minHeight: "50px",
        padding: "0 20px",
        display: "flex",
        alignItems: "center"
    },
    headerText: {
        marginRight: "20px",
        color: theme.palette.C_DB40,
        "&:hover": {
            color: theme.palette.C_DB120
        }
    },
    searchField: {
        flexBasis: "100%"
    },
    button: {
        minWidth: "150px"
    },
    buttonChildren: {
        display: "flex",
        "& > *": {
            marginRight: "5px"
        }
    },
    menu: {
        maxHeight: "300px",
        overflowY: "auto"
    },
    arrow: {
        color: theme.palette.C_DB5
    }
});

class SearchableSelect extends React.Component {
    state = {
        open: false,
        search: "",
        target: null
    };

    handleClose = e => {
        this.setState({ open: false });
    };

    togglePopup = e => {
        this.setState({ open: !this.state.open });
    };

    handleTargetRef = r => {
        this.setState({ target: r });
    };
    onClick = selectedId => e => {
        const { onChange, onAddOption, onRemoveOption, multiple } = this.props;

        if (!multiple) {
            this.setState({ open: false });
            return this.props.onChange(selectedId);
        }
        const newSelected = [...this.props.value];

        const index = newSelected.indexOf(selectedId);
        if (index < 0) {
            onAddOption && onAddOption(selectedId);
            newSelected.push(selectedId);
        } else {
            onRemoveOption && onRemoveOption(selectedId);
            newSelected.splice(index, 1);
        }
        onChange && onChange(newSelected);
    };

    handleSelectAll = () => {
        const { onChange, options } = this.props;

        const newSelected = _.map(options, o => o.id);
        onChange && onChange(newSelected);
    };
    handleSelectNone = () => {
        const { onChange } = this.props;

        onChange && onChange([]);
    };

    handleSearch = e => {
        this.setState({ search: e.target.value });
    };

    renderOptionList = filteredOptions => {
        const { value, multiple } = this.props;

        return _.map(filteredOptions, (option, i) => {
            const isSelected = multiple
                ? value.indexOf(option.id) >= 0
                : value === option.id;
            return (
                <MenuItem
                    selected={isSelected}
                    onClick={this.onClick(option.id)}
                    key={option.id}
                    multiselect
                >
                    {this.props.renderChild(option, isSelected)}

                    {multiple ? (
                        <div style={{ transform: "translate(4px, -4px)" }}>
                            <Radio checked={isSelected} />
                        </div>
                    ) : null}
                </MenuItem>
            );
        });
    };

    renderPlaceholder = () => {
        return (
            <div className={this.props.classes.placeholder}>
                <Typography>{"Ingen treff ..."}</Typography>
            </div>
        );
    };
    renderPopup = filteredOptions => {
        const { classes, multiple } = this.props;
        const { search } = this.state;

        return (
            <div
                className={classNames(classes.root, {
                    [classes.rootMultiple]: multiple
                })}
            >
                {multiple ? (
                    <div className={classes.header}>
                        <ButtonBase onClick={this.handleSelectAll}>
                            <Typography
                                className={classes.headerText}
                                color={"inherit"}
                                size={"small"}
                                dynamicUnderline
                            >
                                {"All"}
                            </Typography>
                        </ButtonBase>
                        <ButtonBase onClick={this.handleSelectNone}>
                            <Typography
                                color={"inherit"}
                                size={"small"}
                                className={classes.headerText}
                                dynamicUnderline
                            >
                                {"Reset"}
                            </Typography>
                        </ButtonBase>
                        <MiniSearchField
                            className={classes.searchField}
                            value={search}
                            onChange={this.handleSearch}
                        />
                    </div>
                ) : null}
                <div className={classes.menu}>
                    {filteredOptions.length > 0
                        ? this.renderOptionList(filteredOptions)
                        : this.renderPlaceholder()}
                </div>
            </div>
        );
    };

    renderMultipleButton = (label, selectedOptions) => {
        const { options, classes, target } = this.props;

        const labelName =
            options.length === selectedOptions.length
                ? "all"
                : selectedOptions.length;

        return target
            ? React.cloneElement(target, {
                  children: (
                      <div className={classes.buttonChildren}>
                          <Typography
                              singleLine
                              color={"inherit"}
                              size={"small"}
                          >
                              {label}
                          </Typography>
                          {selectedOptions.length > 0 ? (
                              <Typography
                                  size={"small"}
                                  color={"inherit"}
                                  component={"span"}
                              >{`(${labelName})`}</Typography>
                          ) : null}
                      </div>
                  ),
                  label: label,
                  onClick: this.togglePopup,
                  buttonRef: this.handleTargetRef,
                  className: classNames(classes.button, target.props.className)
              })
            : null;
    };

    renderSingleButton = selected => {
        const { open, search } = this.state;
        if (open) {
            return (
                <InputField
                    inputRef={r => {
                        this.handleTargetRef(r);
                        r && r.focus();
                    }}
                    value={search}
                    onChange={this.handleSearch}
                />
            );
        } else {
            return React.cloneElement(this.props.target, {
                children: selected ? selected.name : "",
                onClick: this.togglePopup,
                buttonRef: this.handleTargetRef,
                className: this.props.classes.button
            });
        }
    };

    render() {
        const {
            options,
            value,
            label,
            buttonLabel,
            className,
            classes,
            multiple
        } = this.props;
        const { open, search } = this.state;

        const selected = multiple
            ? _.filter(options, option => value.indexOf(option.id) >= 0)
            : _.find(options, option => option.id === value);

        const filteredOptions = _.filter(
            options,
            option =>
                option.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
        );

        const content = open ? this.renderPopup(filteredOptions) : null;

        return (
            <FormControl className={className} label={label}>
                {multiple
                    ? this.renderMultipleButton(buttonLabel, selected)
                    : this.renderSingleButton(selected)}
                <Popup
                    onClickAway={this.handleClose}
                    open={open}
                    targetRef={this.state.target}
                    placement="bottom"
                    preventOverflow={false}
                    arrowClassName={classes.arrow}
                >
                    {content}
                </Popup>
            </FormControl>
        );
    }
}

export const SearchableSelectProps = {
    /**
     * The currently selected value
     */
    value: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string), // If multiple
        PropTypes.string // If single
    ]).isRequired,
    /**
     * The possible options. Name is the displayed value. Id is the id of the item. Id is needed for searchable select.
     */
    options: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired
        })
    ).isRequired,
    /**
     * If multiple, will return a list of the currently selected after the select. If single, will return the index of the item selected.
     */
    onChange: PropTypes.func,

    /**
     * Called when an option was added. Will only be called when multiple select
     */
    onAddOption: PropTypes.func,
    /**
     * Called when a option is removed. Will only be called when multiple select
     */
    onRemoveOption: PropTypes.func,
    /**
     * A label to be used in the title of the picker
     */
    buttonLabel: PropTypes.string.isRequired,

    /**
     * A classname which will be sent to the target.
     */
    targetClassName: PropTypes.string,

    /**
     * A custom target. This target will be passed a provided label a value (the selected options)
     */
    target: PropTypes.node,

    /**
     * Whether the select allows for multple selected values.
     */
    multiple: PropTypes.bool,
    /**
     *  An optional function to render the child in the item list.
     */
    renderChild: PropTypes.func
};

SearchableSelect.propTypes = SearchableSelectProps;

SearchableSelect.defaultProps = {
    target: <PopupButton />,
    multiple: true,
    renderChild: option => (
        <Typography singleLine size={"small"} semibold color={"inherit"}>
            {option.name}
        </Typography>
    )
};

export default withStyles(styles)(SearchableSelect);
