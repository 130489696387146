import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";

import classNames from "classnames";
import FormControl from "../FormControl";
import Typography from "@material-ui/core/Typography";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import LockIcon from "../../icons/LockIcon";
import * as _ from "lodash";

const styles = theme => ({
    root: {
        alignItems: "center",
        height: "38px", // 40 - 2px border
        fontSize: theme.typography.F_N,
        backgroundColor: theme.palette.C_W,
        borderColor: theme.palette.C_DB15,
        paddingLeft: "10px",
        paddingRight: "10px",
        borderWidth: "1px",
        borderRadius: "6px",
        width: `calc(100% - ${theme.spacing.gridMargin} - 2px)`,
        "&:focus": {
            borderColor: theme.palette.C_DB30
        }
    },
    focus: {
        borderColor: theme.palette.C_DB30
    },

    defaultPadding: {
        paddingLeft: "15px",
        paddingRight: "15px",
        width: `calc(100% - calc(${theme.spacing.gridMargin} * 1.5) - 2px)`
    },
    densePadding: {
        paddingLeft: "7px",
        paddingRight: "7px"
    },
    adornment: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    endAdornment: {
        marginRight: 0
    },
    icon: {
        color: theme.palette.C_DB40,
        width: "15px",
        height: "15px" // Needed to override material ui
    },
    smallIcon: {
        width: "12px",
        height: "12px"
    },
    label: {
        color: theme.palette.C_DB80
    },
    fullWidth: {
        width: "100%"
    },
    sublabel: {
        color: theme.palette.C_DB40
    },
    error: {
        borderColor: theme.palette.C_R
    },
    disabled: {
        backgroundColor: "transparent",
        color: theme.palette.C_DB60
    },
    displayBlock: {
        display: "block"
    }
});

class InputField extends PureComponent {
    state = {
        hasFocus: false
    };
    onNumberFormatChange = value => {
        //Returns value as comma separator doesnt work when parsing string
        _.isFunction(this.props.onChange) &&
            this.props.onChange({
                target: { value: value.value, name: this.props.name }
            });
    };

    render() {
        const {
            className,
            classes,
            leftIcon,
            rightIcon,
            inputRef,
            label,
            fullWidth,
            numeric,
            NumberFormatProps,
            onChange,
            value,
            subLabel,
            error,
            formControlClassName,
            smallIcon,
            disabled,
            inputClassName,
            InputProps,
            padding,
            innerRef,
            disabledReason,
            description,
            displayBlock,
            actionButton,
            onActionButtonClick,
            ...other
        } = this.props;

        const composedClassName = classNames(
            classes.root,
            {
                [classes.defaultPadding]: padding === "default",
                [classes.densePadding]: padding === "dense",
                [classes.error]: Boolean(error),
                [classes.focus]: this.state.hasFocus
            },
            inputClassName
        );

        const iconClasses = classNames(classes.icon, {
            [classes.smallIcon]: Boolean(smallIcon)
        });

        const sublabelClasses = classNames(classes.sublabel);

        const endAdornmentClasses = classNames(classes.adornment, {
            [classes.endAdornment]:
                Boolean(subLabel) || Boolean(smallIcon) || disabled
        });

        const formControlClasses = classNames(formControlClassName, className, {
            [classes.fullWidth]: fullWidth,
            [classes.displayBlock]: displayBlock
        });

        const clonedLeftIcon =
            leftIcon &&
            React.cloneElement(leftIcon, {
                className: classNames(iconClasses, leftIcon.props.className),
                classes: { root: iconClasses }
            });
        const clonedRightIcon =
            rightIcon &&
            React.cloneElement(rightIcon, {
                className: classNames(iconClasses, rightIcon.props.className)
            });

        //Override clonedRightIcon with sublabel if defined
        const sublabelComponent = subLabel ? (
            <Typography variant="body1" className={sublabelClasses}>
                {subLabel}
            </Typography>
        ) : null;

        const disabledIcon =
            disabled === true ? (
                <LockIcon
                    className={classNames(classes.icon, classes.smallIcon)}
                />
            ) : null;

        const input = (
            <Input
                disableUnderline
                disabled={disabled}
                margin={"none"}
                classes={{ input: classes.input, disabled: classes.disabled }}
                padding={"none"}
                className={composedClassName}
                error={true}
                onFocus={() => this.setState({ hasFocus: true })}
                onBlur={() => this.setState({ hasFocus: false })}
                startAdornment={
                    leftIcon && (
                        <InputAdornment
                            position="start"
                            className={classes.adornment}
                        >
                            {clonedLeftIcon}
                        </InputAdornment>
                    )
                }
                endAdornment={
                    (rightIcon || subLabel || disabledIcon) && (
                        <InputAdornment
                            position="start"
                            className={endAdornmentClasses}
                        >
                            {sublabelComponent ||
                                clonedRightIcon ||
                                disabledIcon}
                        </InputAdornment>
                    )
                }
                inputRef={inputRef}
                onChange={numeric ? undefined : onChange}
                value={value}
                {...InputProps}
                {...other}
            />
        );
        return (
            <FormControl
                className={formControlClasses}
                innerRef={innerRef}
                label={label}
                title={disabled ? disabledReason : undefined}
                description={description}
                actionButton={actionButton}
                onActionButtonClick={onActionButtonClick}
            >
                {numeric ? (
                    <NumberFormat
                        customInput={Input}
                        decimalSeparator=","
                        value={value.toString()}
                        isNumericString
                        {...input.props}
                        {...NumberFormatProps}
                        onValueChange={this.onNumberFormatChange}
                    />
                ) : (
                    input
                )}
            </FormControl>
        );
    }
}

export const InputFieldPropTypes = {
    /**
     * If default adds 20px padding on left and right side
     * If dense adds 10 px padding on left and right side
     */
    padding: PropTypes.oneOf(["default", "dense"]),

    /**
     * Adds a label as endAdornement. No endAdornment will be shown if specified both
     */
    subLabel: PropTypes.string,

    /**
     * Adds a thick label to above input field
     */
    label: PropTypes.string,

    /**
     * Value should always be string if not numeric, or number if numeric
     */

    value: PropTypes.any,

    /**
     * Props propagated to NumberFormat
     *
     */
    NumberFormatProps: PropTypes.object,

    /**
     * Whether a number input field should be used. Value returned will not be an event
     * if true, but a string with correct decimalSeparator
     */
    numeric: PropTypes.bool,

    /**
     * Whether the input field should cover the entire containers width
     */
    fullWidth: PropTypes.bool,

    /**
     * Whether there is an error in the input field. Will show a red border
     */

    error: PropTypes.bool,

    /**
     * Function called when the input field changes
     */
    onChange: PropTypes.func,

    /**
     * An icon to be placed on the right side inside the input field
     */
    rightIcon: PropTypes.node,
    /**
     * An icon to be placed on the left side inside the input field
     */
    leftIcon: PropTypes.node
};
InputField.propTypes = InputFieldPropTypes;

InputField.defaultProps = {
    numeric: false,
    padding: "default",
    label: null,
    NumberFormatProps: {},
    smallIcon: false,
    fullWidth: false,
    error: false
};

export default withStyles(styles)(InputField);
