import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import * as userSelectors from "selectors/user";
import {
    getKeyPerformanceIndicators,
    getKeyPerformanceIndicatorsMetaData
} from "../selectors";
import * as companySelectors from "selectors/company";
import * as _ from "lodash";
import Card from "components/layout/card/Card";
import KeyPerformanceIndicatorsColumnSection from "./KeyPerformanceIndicatorsColumnSection";
import KeyPerformanceIndicatorsList from "./KeyPerformanceIndicatorsList";
import KeyPerformanceIndicatorsSettings from "./KeyPerformanceIndicatorsSettings";
import KeyPerformanceIndicatorsFormula from "./KeyPerformanceIndicatorsFormula";
import KeyPerformanceIndicatorsVariables from "./KeyPerformanceIndicatorsVariables";
import Flex from "components/layout/flex/Flex";
import InfoTag from "common/InfoTag";
import RoundIconButton from "common/forms/buttons/RoundIconButton";
import AddIcon from "common/icons/AddIcon";
import * as types from "actionTypes";
import IconPopupMenu from "common/poppers/IconPopupMenu";
import { DragDropContext } from "react-beautiful-dnd";
import Button from "common/forms/buttons/Button";
import { fontSizes, colors } from "utils/styles";

const styles = theme => ({
    root: {
        display: "flex",
        padding: "0",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        height: "calc(100% - 102px)",
        marginTop: "20px",
        flexWrap: "wrap",
        overflow: "hidden"
    }
});

const elementTypeNone = "NONE";

class KeyPerformanceIndicatorsBuilder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dragActive: false,
            groupBeingEdited: [], // Index of group currently being edited, the length of the list is how deep the group is nested into other groups
            selectedKPI: {
                active: true,
                definition: [],
                displaySettings: {
                    employee: false,
                    administrator: false,
                    displayWithPercent: false
                },
                id: "",
                name: ""
            }
        };
    }

    componentDidMount() {
        if (this.props.company.id) {
            this.props.getKeyPerformanceIndicators(this.props.company.id);
            this.props.getKeyPerformanceIndicatorsMetaData(
                this.props.company.id
            );
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.company.id !== this.props.company.id) {
            this.props.getKeyPerformanceIndicators(nextProps.company.id);
            this.props.getKeyPerformanceIndicatorsMetaData(
                nextProps.company.id
            );
        }

        if (
            this.state.selectedKPI.id === "" &&
            nextProps.keyPerformanceIndicators.length > 0
        ) {
            this.setState({
                selectedKPI: nextProps.keyPerformanceIndicators[0]
            });
        }
    }

    getLastItemType = definition => {
        if (definition.length === 0) {
            return elementTypeNone;
        }

        const lastItem = definition[definition.length - 1];

        if (lastItem) {
            if (
                lastItem.type ===
                this.props.keyPerformanceIndicatorsMetaData.elementTypes.GROUP
            ) {
                if (this.state.groupBeingEdited.length === 0) {
                    return this.props.keyPerformanceIndicatorsMetaData
                        .elementTypes.GROUP;
                }
                return this.getLastItemType(lastItem.definition);
            }
            return lastItem.type;
        }
    };

    createTemplateKeyPerformanceIndicator = () => {
        this.props.createKeyPerformanceIndicator(this.props.company.id, {
            name: "Nøkkeltall",
            definition: [],
            displaySettings: {
                employee: false,
                administrator: true,
                displayWithPercent: false
            }
        });
    };

    updateKeyPerformanceIndicator = payload => {
        /*this.props.updateKeyPerformanceIndicator(
            this.props.company.id,
            this.state.selectedKPI.id,
            payload
        );*/
    };

    deleteKeyPerformanceIndicator = () => {
        this.props.deleteKeyPerformanceIndicator(
            this.props.company.id,
            this.state.selectedKPI.id
        );
    };

    handleSelectedKPIChange = id => {
        const selectedKPI = _.find(
            this.props.keyPerformanceIndicators,
            kpi => kpi.id === id
        );
        this.setState({
            selectedKPI: {
                ...this.state.selectedKPI,
                ...selectedKPI
            }
        });
    };

    handleSettingsChange = payload => {
        this.setState({
            selectedKPI: {
                ...this.state.selectedKPI,
                displaySettings: {
                    ...this.state.selectedKPI.displaySettings,
                    ...payload
                }
            }
        });
        this.updateKeyPerformanceIndicator({
            displaySettings: {
                ...this.state.selectedKPI.displaySettings,
                ...payload
            }
        });
    };

    handleRemoveFromDefinition = index => {
        let activeGroupDefinition = this.getDefinitionInGroup(
            this.state.selectedKPI.definition,
            this.state.groupBeingEdited
        );

        const lastElement =
            activeGroupDefinition[activeGroupDefinition.length - 1];

        // If last element is an unactive group, set it to active and do not remove anything
        if (
            lastElement &&
            lastElement.type ===
                this.props.keyPerformanceIndicatorsMetaData.elementTypes.GROUP
        ) {
            this.setState({
                groupBeingEdited: [
                    ...this.state.groupBeingEdited,
                    activeGroupDefinition.length - 1
                ]
            });
            return;
        }

        let newGroupBeingEdited = this.state.groupBeingEdited;

        // Remove group instead of element if length is 0
        if (
            activeGroupDefinition.length === 0 &&
            this.state.groupBeingEdited.length > 0
        ) {
            newGroupBeingEdited = this.state.groupBeingEdited.splice(
                0,
                this.state.groupBeingEdited.length - 1
            );
            activeGroupDefinition = this.getDefinitionInGroup(
                this.state.selectedKPI.definition,
                newGroupBeingEdited
            );
        }

        activeGroupDefinition.pop();

        const newMainDefinition = this.generateMainDefinitionWithUpdatedGroup(
            this.state.selectedKPI.definition,
            newGroupBeingEdited,
            activeGroupDefinition
        );

        this.setState({
            selectedKPI: {
                ...this.state.selectedKPI,
                definition: newMainDefinition
            },
            groupBeingEdited: newGroupBeingEdited
        });
    };

    handleAddElementToMainDefinition = element => {
        this.setState({
            selectedKPI: {
                ...this.state.selectedKPI,
                definition: [
                    ...this.state.selectedKPI.definition,
                    {
                        ...element
                    }
                ]
            }
        });

        this.updateKeyPerformanceIndicator({
            definition: [...this.state.selectedKPI.definition, { ...element }]
        });
    };

    handleAddIdAndTypeToDefinition = (id, type) => {
        if (this.state.groupBeingEdited.length > 0) {
            this.handleAddElementToDefinitionGroup({ id, type });
        } else {
            this.handleAddElementToMainDefinition({ id, type });
        }
    };

    getDefinitionInGroup = (mainDefinition, indexes) => {
        if (indexes.length > 0) {
            return this.getDefinitionInGroup(
                mainDefinition[indexes[0]].definition,
                indexes.slice(1)
            );
        } else {
            return mainDefinition;
        }
    };

    generateMainDefinitionWithUpdatedGroup = (
        mainDefinition,
        indexes,
        newGroup
    ) => {
        let newDefinition = mainDefinition;

        if (indexes.length > 0) {
            newDefinition[
                indexes[0]
            ].definition = this.generateMainDefinitionWithUpdatedGroup(
                newDefinition[indexes[0]].definition,
                indexes.slice(1),
                newGroup
            );
        } else {
            newDefinition = newGroup;
        }

        return newDefinition;
    };

    handleAddElementToDefinitionGroup = element => {
        const mainDefinition = this.state.selectedKPI.definition;
        const indexes = this.state.groupBeingEdited;

        let definitionInGroup = this.getDefinitionInGroup(
            mainDefinition,
            indexes,
            this.props.keyPerformanceIndicatorsMetaData
        );

        const newDefinitionInGroup = [...definitionInGroup, element];
        const newMainDefinition = this.generateMainDefinitionWithUpdatedGroup(
            mainDefinition,
            indexes,
            newDefinitionInGroup
        );

        this.setState({
            selectedKPI: {
                ...this.state.selectedKPI,
                definition: newMainDefinition
            }
        });
    };

    handleBeginGroup = () => {
        const parentDefinition = this.getDefinitionInGroup(
            this.state.selectedKPI.definition,
            this.state.groupBeingEdited
        );

        const group = {
            id: [...this.state.groupBeingEdited, parentDefinition.length],
            type: this.props.keyPerformanceIndicatorsMetaData.elementTypes
                .GROUP,
            definition: []
        };

        if (this.state.groupBeingEdited.length > 0) {
            this.handleAddElementToDefinitionGroup(group);
        } else {
            this.handleAddElementToMainDefinition(group);
        }

        this.setState({
            groupBeingEdited: [
                ...this.state.groupBeingEdited,
                parentDefinition.length
            ]
        });
    };

    handleEndGroup = () => {
        // Remove last index from being edited
        this.setState({
            groupBeingEdited: this.state.groupBeingEdited.splice(
                0,
                this.state.groupBeingEdited.length - 1
            )
        });
    };

    handleUpdateConstant = (id, value) => {
        const newDefinition = this.state.selectedKPI.definition;
        newDefinition[id].value = value;

        this.setState({
            selectedKPI: {
                ...this.state.selectedKPI,
                definition: newDefinition
            }
        });

        this.updateKeyPerformanceIndicator({
            definition: newDefinition
        });
    };

    handleAddConstantToDefinition = () => {
        const constant = {
            id: this.state.selectedKPI.definition.length,
            type: this.props.keyPerformanceIndicatorsMetaData.elementTypes
                .CUSTOM_CONSTANT_VALUE,
            value: 1
        };

        this.setState({
            selectedKPI: {
                ...this.state.selectedKPI,
                definition: [...this.state.selectedKPI.definition, constant]
            }
        });

        this.updateKeyPerformanceIndicator({
            definition: [...this.state.selectedKPI.definition, constant]
        });
    };

    onDragStart = result => {
        this.setState({ dragActive: true });
        console.log("drag started");
    };

    onDragUpdate = result => {};

    onDragEnd = result => {
        if (
            result.source.droppableId === "variables" &&
            result.destination !== null &&
            result.destination.droppableId === "formula"
        ) {
            this.handleAddIdAndTypeToDefinition(
                result.draggableId,
                this.props.keyPerformanceIndicatorsMetaData.elementTypes
                    .STANDARD_VALUE
            );
        }
        this.setState({ dragActive: false });
    };

    render() {
        const {
            classes,
            keyPerformanceIndicators,
            keyPerformanceIndicatorsMetaData
        } = this.props;
        const { selectedKPI, groupBeingEdited } = this.state;

        const lastItemType = keyPerformanceIndicatorsMetaData.elementTypes
            ? this.getLastItemType(selectedKPI.definition)
            : {};

        const disableOperators = keyPerformanceIndicatorsMetaData.elementTypes
            ? lastItemType ===
                  keyPerformanceIndicatorsMetaData.elementTypes.OPERATOR ||
              lastItemType === elementTypeNone
            : true;
        const disableVariables = keyPerformanceIndicatorsMetaData.elementTypes
            ? lastItemType !==
                  keyPerformanceIndicatorsMetaData.elementTypes.OPERATOR &&
              lastItemType !== elementTypeNone
            : true;
        const disableBackspace = selectedKPI.definition.length === 0;
        const disableCloseGroup = keyPerformanceIndicatorsMetaData.elementTypes
            ? lastItemType ===
                  keyPerformanceIndicatorsMetaData.elementTypes.OPERATOR ||
              groupBeingEdited.length === 0
            : true;


        return (
            <Card className={classes.root}>
                <DragDropContext
                    onDragStart={this.onDragStart}
                    onDragUpdate={this.onDragUpdate}
                    onDragEnd={this.onDragEnd}
                >
                    <KeyPerformanceIndicatorsColumnSection
                        title={"Nøkkeltall"}
                        droppableId={"list"}
                        content={
                            <KeyPerformanceIndicatorsList
                                keyPerformanceIndicators={
                                    keyPerformanceIndicators
                                }
                                selectedKeyPerformanceIndicatorId={
                                    selectedKPI.id
                                }
                                onSelect={this.handleSelectedKPIChange}
                                updateKeyPerformanceIndicatorName={
                                    this.updateKeyPerformanceIndicator
                                }
                            />
                        }
                        headerIcon={
                            <Flex>
                                <IconPopupMenu
                                    onChange={
                                        this.deleteKeyPerformanceIndicator
                                    }
                                    items={["Slett nøkkeltall"]}
                                />
                                <RoundIconButton
                                    icon={<AddIcon />}
                                    title={"Legg til nøkkeltall"}
                                    onClick={
                                        this
                                            .createTemplateKeyPerformanceIndicator
                                    }
                                    darkBackground
                                    style={{ marginLeft: "6px" }}
                                />
                                <InfoTag
                                    description={"Nøkkeltall"}
                                    darkBackground
                                />
                            </Flex>
                        }
                    />
                    <KeyPerformanceIndicatorsColumnSection
                        title={"Innstillinger"}
                        droppableId={"settings"}
                        content={
                            <KeyPerformanceIndicatorsSettings
                                currentKPISettings={selectedKPI.displaySettings}
                                onChange={this.handleSettingsChange}
                            />
                        }
                        headerIcon={
                            <InfoTag
                                description={"Innstillinger"}
                                darkBackground
                            />
                        }
                    />
                    <KeyPerformanceIndicatorsColumnSection
                        title={"Formel"}
                        droppableId={"formula"}
                        content={
                            <KeyPerformanceIndicatorsFormula
                                currentKPIDefinition={selectedKPI.definition}
                                metaData={keyPerformanceIndicatorsMetaData}
                                groupBeingEdited={groupBeingEdited}
                                disableOperators={disableOperators}
                                disableVariables={disableVariables}
                                disableCloseGroup={disableCloseGroup}
                                disableBackspace={disableBackspace}
                                handleAddOperator={
                                    this.handleAddIdAndTypeToDefinition
                                }
                                handleRemoveFromDefinition={
                                    this.handleRemoveFromDefinition
                                }
                                handleUpdateConstant={this.handleUpdateConstant}
                                handleBeginGroup={this.handleBeginGroup}
                                handleEndGroup={this.handleEndGroup}
                            />
                        }
                        headerIcon={
                            <Flex>
                                <Button
                                    variant={"action"}
                                    style={{
                                        minHeight: "unset",
                                        height: "25px",
                                        padding: "0 10px",
                                        width: "110px",
                                        fontSize: fontSizes.F_XS,
                                        background: colors.C_DB5,
                                        border: `1px solid ${colors.C_DB15}`,
                                        color: disableVariables
                                            ? colors.C_DB40
                                            : ""
                                    }}
                                    onClick={this.handleAddConstantToDefinition}
                                    disabled={disableVariables}
                                >
                                    {"Legg til konstant"}
                                </Button>
                                <InfoTag
                                    description={"Formel"}
                                    darkBackground
                                />
                            </Flex>
                        }
                    />
                    <KeyPerformanceIndicatorsColumnSection
                        title={"Variabler"}
                        droppableId={"variables"}
                        content={
                            <KeyPerformanceIndicatorsVariables
                                metaData={keyPerformanceIndicatorsMetaData}
                                handleAddVariable={
                                    this.handleAddIdAndTypeToDefinition
                                }
                                disableVariables={disableVariables}
                            />
                        }
                        headerIcon={
                            <InfoTag description={"Variabler"} darkBackground />
                        }
                    />
                </DragDropContext>
            </Card>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        keyPerformanceIndicators: getKeyPerformanceIndicators(state, props),
        keyPerformanceIndicatorsMetaData: getKeyPerformanceIndicatorsMetaData(
            state,
            props
        ),
        users: userSelectors.getActiveUsers(state, props),
        company: companySelectors.getCurrentCompany(state, props)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getKeyPerformanceIndicatorsMetaData: companyId => {
            dispatch({
                type: types.REQUEST_KEY_PERFORMANCE_INDICATORS_META_DATA,
                companyId
            });
        },
        getKeyPerformanceIndicators: companyId => {
            dispatch({
                type: types.REQUEST_KEY_PERFORMANCE_INDICATORS,
                companyId
            });
        },
        updateKeyPerformanceIndicator: (
            companyId,
            keyPerformanceIndicatorId,
            payload
        ) =>
            dispatch({
                type: types.REQUEST_UPDATE_KEY_PERFORMANCE_INDICATOR,
                companyId,
                keyPerformanceIndicatorId,
                payload
            }),
        createKeyPerformanceIndicator: (companyId, payload) => {
            dispatch({
                type: types.REQUEST_CREATE_KEY_PERFORMANCE_INDICATOR,
                companyId,
                payload
            });
        },
        deleteKeyPerformanceIndicator: (
            companyId,
            keyPerformanceIndicatorId
        ) => {
            dispatch({
                type: types.REQUEST_DELETE_KEY_PERFORMANCE_INDICATOR,
                companyId,
                keyPerformanceIndicatorId
            });
        }
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
        withStyles(styles)(KeyPerformanceIndicatorsBuilder)
    )
);
