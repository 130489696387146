import React from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import DefaultFormControl from "@material-ui/core/FormControl";
import Typography from "../Typography";
import PropTypes from "prop-types";
import InfoTag from "../InfoTag";
import Flex from "../../components/layout/flex/Flex";
import ButtonBase from "../../components/buttons/ButtonBase";

const styles = theme => ({
    label: {
        marginBottom: "5px"
    },
    root: {
        marginLeft: "1px", // To align with borders in inputs
        marginRight: "1px"
    },
    fullWidth: {
        width: "100%"
    },
    description: {
        marginTop: "2px",
        marginBottom: "5px"
    },
    actionButton: {
        cursor: "pointer",
        color: theme.palette.C_R
    }
});

const FormControl = ({
    classes,
    className,
    label,
    fullWidth,
    children,
    description,
    labelType,
    actionButton,
    onActionButtonClick,
    title
}) => {
    const formControlClasses = classNames(classes.root, className, {
        [classes.fullWidth]: fullWidth
    });

    return (
        <DefaultFormControl className={formControlClasses} title={title}>
            <Flex justify={"between"}>
                {label ? (
                    <Typography
                        size={
                            labelType !== "descrete" ? "smallMed" : "smallMed"
                        }
                        className={classes.label}
                    >
                        {label}
                    </Typography>
                ) : null}
                <Flex>
                    {actionButton ? (
                        <ButtonBase onClick={onActionButtonClick}>
                            <Typography
                                className={classes.actionButton}
                                underline
                                size={"small"}
                            >
                                {actionButton}
                            </Typography>
                        </ButtonBase>
                    ) : null}
                    {description ? (
                        <InfoTag
                            className={classes.description}
                            description={description}
                            size={"small"}
                        >
                            {description}
                        </InfoTag>
                    ) : null}
                </Flex>
            </Flex>
            {children}
        </DefaultFormControl>
    );
};

FormControl.propTypes = {
    label: PropTypes.string,

    fullWidth: PropTypes.bool,
    description: PropTypes.string,
    labelType: PropTypes.oneOf(["descrete", "default"])
};
FormControl.defaultProps = {
    label: null,
    fullWidth: true,
    description: null,
    labelType: "default"
};

export default withStyles(styles)(FormControl);
