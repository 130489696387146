import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 8 8">
            <path d="M4.85,4l3-3a.59.59,0,0,0,0-.85A.59.59,0,0,0,7,.18l-3,3-3-3a.59.59,0,0,0-.85,0A.59.59,0,0,0,.18,1l3,3-3,3a.59.59,0,0,0,0,.85.59.59,0,0,0,.85,0l3-3,3,3A.6.6,0,0,0,7.82,7Z" />
        </SvgIcon>
    );
}
