import React from "react";
import { withStyles } from "@material-ui/core";
import BorderIconButton from "../../../components/buttons/BorderIconButton";
import PropTypes from "prop-types";
import classNames from "classnames";
import { capitalize } from "@material-ui/core/utils/helpers";
const styles = theme => ({
    icon: {
        height: "10px",
        width: "10px"
    },
    iconContainer: {
        borderRadius: "50%",
        width: "22px",
        height: "22px",
        minWidth: "22px",
        minHeight: "22px",
        backgroundColor: theme.palette.C_DB5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "20px",
        border: `solid 1px ${theme.palette.C_DB15}`,
        "&:hover": {
            border: `solid 1px ${theme.palette.C_DB20}`,
            backgroundColor: theme.palette.C_DB5,

            "& $icon": {
                color: theme.palette.C_LB
            }
        },
        padding: 0
    },
    darkBackground: {
        backgroundColor: theme.palette.C_DB10,
        border: `solid 1px ${theme.palette.C_DB15}`,
        "&:hover": {
            border: `solid 1px ${theme.palette.C_DB20}`,
            backgroundColor: theme.palette.C_DB10,

            "& $icon": {
                color: theme.palette.C_LB
            }
        }
    },
    containerSmall: {
        height: "16px",
        width: "16px",
        minHeight: "16px",
        minWidth: "16px"
    },
    iconSmall: {
        height: "8px",
        width: "8px"
    }
});

const RoundIconButton = ({
    classes,
    children,
    darkBackground,
    size,
    className,
    ...other
}) => {
    return (
        <BorderIconButton
            className={classNames(
                classes.iconContainer,
                classes[`container${capitalize(size)}`],
                {
                    [classes.darkBackground]: !!darkBackground
                },
                className
            )}
            variant={"travel"}
            iconClassName={classNames(
                classes.icon,
                classes[`icon${capitalize(size)}`]
            )}
            {...other}
        >
            {children}
        </BorderIconButton>
    );
};
RoundIconButton.propTypes = {
    darkBackground: PropTypes.bool,
    size: PropTypes.oneOf(["small", "regular"])
};
RoundIconButton.defaultProps = {
    darkBackground: false,
    size: "regular"
};

export default withStyles(styles)(RoundIconButton);
