import * as actions from "../../actionTypes";
const companiesInitialState = {};

export default function companies(state = companiesInitialState, action) {
    switch (action.type) {
        case actions.SET_COMPANY:
            return {
                ...state,
                [action.key]: { ...action.payload }
            };
        case actions.SET_COMPANIES:
            return {
                ...state,
                ...action.payload
            };

        case actions.UPDATE_DISPLAY_SETTINGS:
            return {
                ...state,
                [action.payload.companyId]: {
                    ...state[action.payload.companyId],
                    view: {
                        ...state[action.payload.companyId].view,
                        ...action.payload.view
                    }
                }
            };
        default:
            return state;
    }
}
