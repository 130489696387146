import React, { Component } from "react";
import { connect } from "react-redux";
import PaperHeader from "../../../components/layout/paper/PaperHeader";
import PaperHeaderTitle from "../../../components/layout/paper/PaperHeaderTitle";
import * as companySelectors from "../../../selectors/company";
import { withRouter } from "react-router-dom";
import * as selectors from "./selectors";
import * as types from "actionTypes";
import Slider from "common/forms/Slider";

const tabs = [
    "Privacy and Cookie Policy",
    "Terms and Conditions",
    "Acceptable Use Policy"
];
export class WorkspaceHeader extends Component {
    render() {
        const { onTabChange, currentPrivacyTab } = this.props;
        return (
            <PaperHeader
                title={<PaperHeaderTitle primary={tabs[currentPrivacyTab]} />}
                withBorder
            >
                <Slider
                    tabWidth={200}
                    tabs={tabs}
                    selectedTab={currentPrivacyTab}
                    onSelectTab={onTabChange}
                    background={"paper"}
                />
            </PaperHeader>
        );
    }
}

const mapStateToProps = (state, props) => ({
    companyName: companySelectors.getCompanyName(state, props),
    currentPrivacyTab: selectors.getSelectedTab(state, props)
});

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onTabChange: tab => {
            dispatch({ type: types.SET_ACTIVE_PRIVACY_TAB, payload: tab });
        }
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(WorkspaceHeader)
);
