import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 20 22.47">
            <path d="M4.92,11.25H.6a.6.6,0,0,0-.6.6v10a.6.6,0,0,0,.6.6H4.92a.6.6,0,0,0,.6-.6v-10A.6.6,0,0,0,4.92,11.25Z" />
            <path d="M12.16,0H7.84a.6.6,0,0,0-.6.6V21.87a.6.6,0,0,0,.6.6h4.32a.6.6,0,0,0,.6-.6V.6A.6.6,0,0,0,12.16,0Z" />
            <path d="M19.4,7H15.08a.6.6,0,0,0-.6.61V21.87a.6.6,0,0,0,.6.6H19.4a.6.6,0,0,0,.6-.6V7.56A.6.6,0,0,0,19.4,7Z" />
        </SvgIcon>
    );
}
