import * as actions from "../actionTypes";
import { dialog } from "../constants/strings";

const initalModalState = {
    modalType: null,
    modalProps: {
        meta: {}
    },
    loader: {
        isActive: false,
        displayText: "Laster data"
    }
};
export default function modal(state = initalModalState, action) {
    switch (action.type) {
        case actions.OPEN_MODAL:
            return {
                ...state,
                modalType: action.payload.modalType,
                modalProps: {
                    ...dialog[action.payload.modalType],
                    ...action.payload.modalProps
                }
            };

        case actions.CLOSE_MODAL:
            return {
                ...state,
                modalProps: initalModalState.modalProps,
                modalType: initalModalState.modalType
            };
        case actions.SHOW_LOADER: {
            return {
                ...state,
                loader: {
                    isActive: true,
                    displayText: action.payload
                }
            };
        }
        case actions.HIDE_LOADER: {
            return {
                ...state,
                loader: {
                    isActive: false
                }
            };
        }

        default:
            return state;
    }
}
