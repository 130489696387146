import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const styles = theme => ({
    root: {
        background: "none",
        font: "inherit",

        // From material-ui
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        position: "relative",
        // Remove grey highlight
        WebkitTapHighlightColor: "transparent",
        backgroundColor: "transparent", // Reset default value
        border: 0,
        outline: "none",

        margin: 0, // Remove the margin in Safari
        borderRadius: 0,
        padding: 0, // Remove the padding in Firefox
        cursor: "pointer",
        userSelect: "none",
        verticalAlign: "middle",
        "-moz-appearance": "none", // Reset
        "-webkit-appearance": "none", // Reset
        textDecoration: "none",
        // So we take precedent over the style of a native <a /> element.
        color: "inherit",
        "&::-moz-focus-inner": {
            borderStyle: "none" // Remove Firefox dotted outline.
        },
        "&$disabled": {
            pointerEvents: "none", // Disable link interactions
            cursor: "default"
        }
    },
    disabled: {
        pointerEvents: "none", // Disable link interactions
        cursor: "default"
    }
});

class ButtonBase extends PureComponent {
    render() {
        const {
            classes,
            className,
            innerRef,
            children,
            disabled,
            ...other
        } = this.props;
        return (
            <div
                className={classNames(
                    classes.root,
                    { [classes.disabled]: disabled },
                    className
                )}
                ref={innerRef}
                role={"button"}
                tabIndex={"0"}
                {...other}
            >
                {children}
            </div>
        );
    }
}

ButtonBase.propTypes = {};

export default withStyles(styles)(ButtonBase);
