import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Table from "../../../common/table/Table";
import * as selectors from "../selectors";
import Typography from "../../../common/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import TablePlaceholder from "../../../common/table/TablePlaceholder";
import { componentDescriptions } from "../../../constants/strings";
import { updateCalculationRule } from "containers/modals/actions";
import { fetchCalculationRules, deleteCalculationRule } from "actions/company";
import * as companySelectors from "selectors/company";
import IconPopupMenu from "../../../common/poppers/IconPopupMenu";
import { ruleCategories } from "../../../actionTypes";

const ruleCategoryDisplayText = {
    [ruleCategories.OVERTIME_ADDITIONAL]: "Overtidstillegg",
    [ruleCategories.NORMAL_HOURS]: "Normaltid"
};
export class CalculationRuleTable extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                label: "Beskrivelse",
                type: "custom",
                id: "name",
                renderCell: this.renderNameCell,
                width: "40%"
            },
            {
                label: "Regeltype",
                type: "custom",
                width: "20%",
                renderCell: val => ruleCategoryDisplayText[val],
                id: "ruleCategory"
            },
            {
                label: "Tidsrom",
                type: "string",
                id: "formattedDate",
                width: "20%"
            },
            {
                label: "Verdi",
                type: "string",
                orderable: false,
                id: "ruleValue",
                width: "20%",
                renderCell: (val, item) => {
                    return item.ruleCategory === ruleCategories.NORMAL_HOURS
                        ? item.formattedValue // From selector
                        : `${parseFloat(val, 10) * 100}%`;
                }
            },

            {
                label: "",
                type: "custom",
                id: "more",
                renderCell: this.renderMore,
                width: "5%"
            }
        ];
    }

    componentDidMount = () => {
        this.props.fetchCalculationRules(this.props.company.id);
    };

    renderMore = (id, item) => (
        <IconPopupMenu
            items={["Slett regel"]}
            highlighted={[0]}
            whiteBackground
            onChange={index =>
                index === 0
                    ? this.props.deleteCalculationRule(
                          this.props.company.id,
                          item.id
                      )
                    : null
            }
        />
    );

    renderNameCell = (name, item) => {
        return (
            <ButtonBase onClick={() => this.onNameClick(item)} disableRipple>
                <Typography clickable>{name}</Typography>
            </ButtonBase>
        );
    };

    onNameClick = item => {
        this.props.updateCalculationRule(this.props.company.id, item.id);
    };

    render() {
        const { calculationRulesDisplay } = this.props;

        return (
            <Table
                columns={this.columns}
                data={calculationRulesDisplay}
                placeholder={
                    <TablePlaceholder
                        description={
                            componentDescriptions.ruleSettings.tablePlaceholder
                        }
                    />
                }
            />
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        companyNormalHours: selectors.getCompanyNormalHours(state, props),
        calculationRules: selectors.getCompanyCalculationRules(state, props),
        calculationRulesDisplay: selectors.getCompanyCalculationRulesDisplay(
            state,
            props
        ),
        relevantCalculationRules: selectors.getRelevantCalculationRules(
            state,
            props
        ),
        company: companySelectors.getCurrentCompany(state, props)
    };
};

export default withRouter(
    connect(mapStateToProps, {
        updateCalculationRule,
        fetchCalculationRules,
        deleteCalculationRule
    })(CalculationRuleTable)
);
