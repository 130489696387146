import * as _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { userGroups } from "actionTypes";
import InfoBar from "components/infobar/InfoBar";
import SelectMenu from "../../common/poppers/ButtonPopupMenu";
import MonthPicker from "components/pickers/MonthPicker";
import * as actionCreators from "./actions";
import * as userSelectors from "../../selectors/user";
import * as selectors from "./selectors";

class TimebankInfoBar extends Component {
    shouldComponentUpdate(nextProps) {
        if (
            nextProps.keyFigures === this.props.keyFigures &&
            nextProps.currentDate === this.props.currentDate &&
            nextProps.signedInUser === this.props.signedInUser &&
            nextProps.departments === this.props.departments &&
            nextProps.currentDepartment === this.props.currentDepartment
        ) {
            return false;
        }
        return true;
    }

    handleDateChange = (year, month) => {
        const { updateTimebankMeta } = this.props;

        const date = new Date(year, month);
        updateTimebankMeta({ currentDate: date.getTime() });
    };

    handleDepartmentChange = index => {
        const { departments, signedInUser, updateTimebankMeta } = this.props;
        //Don't change department if request is not admin
        if (signedInUser.userGroup !== userGroups.ADMIN) return;

        //Get timebank for the selected department
        if (index !== 0) {
            updateTimebankMeta({ currentDepartment: departments[index].id });
        } else {
            //Get all departments if the selected index is last entry
            updateTimebankMeta({ currentDepartment: null });
        }
    };

    render() {
        const {
            keyFigures,
            currentDate,
            departments,
            currentDepartment,
            signedInUser
        } = this.props;

        const date = new Date(currentDate);
        const year = date.getFullYear();
        const month = date.getMonth();

        return (
            <InfoBar
                keyFigures={keyFigures}
                year={year}
                month={month}
                departments={_.map(departments, dep => dep.name)}
                currentDepartment={currentDepartment}
                onChangeDate={this.handleDateChange}
                onChangeDepartement={this.handleDepartmentChange}
            >
                <SelectMenu
                    items={_.map(departments, dep => dep.name)}
                    value={_.findIndex(
                        departments,
                        dep => dep.id === currentDepartment
                    )}
                    onChange={this.handleDepartmentChange}
                    disabled={signedInUser.userGroup !== userGroups.ADMIN}
                />
                <MonthPicker
                    month={month}
                    year={year}
                    onChange={this.handleDateChange}
                />
            </InfoBar>
        );
    }
}

const mapDispatchToProps = (state, props) => {
    return {
        currentDate: selectors.getCurrentDate(state, props),
        keyFigures: selectors.getFilteredCompanyKeyFiguresWithLimit(
            state,
            props
        ),
        departments: selectors.getDepartmentsByAuth(state, props),
        currentDepartment: selectors.getCurrentDepartmentOrDefault(
            state,
            props
        ),
        signedInUser: userSelectors.getSignedInUser(state, props)
    };
};
export default withRouter(
    connect(mapDispatchToProps, actionCreators)(TimebankInfoBar)
);
