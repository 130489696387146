import * as modalTypes from "containers/modals/modalTypes";
import React from "react";

export const dialog = {
    [modalTypes.CREATE_DEPARTMENT_MODAL]: {
        title: "Opprett ny avdeling",
        description:
            "Skriv navnet på den nye avdelingen i feltet under. Personer kan tilknyttes avdelinger fra «Brukere» siden.",
        actionButton: "Opprett avdeling",
        deleteButton: ""
    },
    [modalTypes.CREATE_KEY_PERFORMANCE_INDICATOR_MODAL]: {
        title: "Lag nytt nøkkeltall",
        description: "Nytt nøkkeltall",
        actionButton: "Lag nøkkeltall",
        deleteButton: ""
    },
    [modalTypes.CREATE_FULL_TIME_EQUIVALENT]: {
        title: "Legg til stillingsprosent",
        description:
            "Skriv inn fradato og stillingsprosent i feltene under. Denne stillingsprosenten vil overstyre tidligere stillingsprosenter fra og med datoen den gjelder.",
        actionButton: "Lagre"
    },
    [modalTypes.UPDATE_FULL_TIME_EQUIVALENT]: {
        title: "Endre stillingsprosent",
        description:
            "Skriv inn ny stillingsprosent og/eller fradato i feltene under. Ønsker du flere stillingsprosenter for ulike tidsperioder kan dette oppnås ved å legge til en ny stillingsprosent.",
        actionButton: "Lagre"
    },
    [modalTypes.CREATE_CALCULATION_RULE]: {
        title: "Legg til regel",
        description:
            "Bygg regler spesialtilpasset din virksomhet. Regel for normaltid benyttes for å definere avvik fra ordinær arbeidstid på en dag eller over en periode (f.eks sommertid eller fri i romjulen). Grunnoppsettet for hvilke dager normaltid akkumuleres er definert ut fra standard norske helligdager. Regel for overtidstillegg benyttes for å definere endring i overtidstillegget over en periode. Endringer her vil påvirke alle brukere i selskapet."
    },
    [modalTypes.UPDATE_CALCULATION_RULE]: {
        title: "Endre regel",
        description:
            "Bygg regler spesialtilpasset din virksomhet. Regel for normaltid benyttes for å definere avvik fra ordinær arbeidstid på en dag eller over en periode (f.eks sommertid eller fri i romjulen). Grunnoppsettet for hvilke dager normaltid akkumuleres er definert ut fra standard norske helligdager. Regel for overtidstillegg benyttes for å definere endring i overtidstillegget over en periode. Endringer her vil påvirke alle brukere i selskapet."
    },

    [modalTypes.CREATE_NOTIFICATION_TARGET_GROUP]: {
        title: "Opprett ny varsel",
        description:
            "Velg hvem som skal motta varsler om utbetalinger for den valgte avdelingen."
    },
    department: {
        add: {
            title: "Opprett ny avdeling",
            description:
                "Skriv navnet på den nye avdelingen i feltet under. Personer kan tilknyttes avdelinger fra «Brukere» siden.",
            actionButton: "Opprett avdeling",
            deleteButton: ""
        },
        edit: {
            title: "Endre avdeling",
            description:
                "Endre navn på avdelingen i feltet under, eller slett avdelingen ved å trykke «Slett» knappen.",
            actionButton: "Lagre endring",
            deleteButton: "Slett avdeling"
        },
        assignOne: {
            head: "person.name",
            title: "Endre avdeling",
            description:
                "Velg avdeling fra menyen under. Nye avdelinger opprettes på «Bedrift» siden.",
            actionButton: "Ferdig",
            label: "Avdeling"
        },
        assignMultiple: {
            head: "x personer er valgt",
            title: "Endre avdeling",
            description:
                "Velg avdeling fra menyen under. Nye avdelinger opprettes på «Bedrift» siden.",
            actionButton: "Ferdig"
        },
        delete: {
            title: "Slett avdeling",
            description:
                "Er du sikker på at du vil slette avdelingen? Dette kan gi konsekvenser i andre deler av applikasjonen. Denne handlingen kan ikke angres.",
            actionButton: "Ja, jeg er sikker"
        }
    },
    calculationRule: {
        delete: {
            title: "Slett regel",
            description:
                "Er du sikker på at du vil slette regelen? Denne handlingen kan ikke angres.",
            actionButton: "Ja, jeg er sikker"
        },
        edit: {
            title: "Endre regel",
            description:
                "Bygg regler spesialtilpasset din virksomhet. Eksempler er sommertid, halvdag på julaften og lignende. Merk at periodebaserte regler som opprettes ikke påvirker helligdager. For å overstyre helligdager må dagsbasert regeltype velges.",
            actionButton: "Ferdig"
        },
        add: {
            title: "Opprett ny regel",
            description:
                "Bygg regler spesialtilpasset din virksomhet. Eksempler er sommertid, halvdag på julaften og lignende. Merk at periodebaserte regler som opprettes ikke påvirker helligdager. For å overstyre helligdager må dagsbasert regeltype velges."
        }
    },

    keyPerformanceIndicatorRule: {
        delete: {
            title: "Slett regel",
            description:
                "Er du sikker på at du vil slette regelen? Denne handlingen kan ikke angres.",
            actionButton: "Ja, jeg er sikker"
        },
        edit: {
            title: "Endre KPI",
            description: "Endre KPI",
            actionButton: "Ferdig"
        },
        add: {
            title: "Opprett ny KPI",
            description: "Bygg KPI"
        }
    },

    startDate: {
        one: {
            head: "person.name",
            title: "Endre startdato",
            description: "Skriv inn startdato i feltet under.",
            actionButton: "Ferdig",
            label: "Startdato"
        },

        multiple: {
            head: "x personer er valgt",
            title: "Endre startdato",
            description: "Skriv inn startdato i feltet under.",
            actionButton: "Ferdig"
        }
    },

    endDate: {
        head: "person.name",
        title: "Endre sluttdato",
        description: "Skriv inn startdato i feltet under.",
        actionButton: "Ferdig",
        label: "Sluttdato"
    },

    fulltimeEquivalent: {
        one: {
            head: "person.name",
            title: "Endre stillingsprosent",
            description:
                "Skriv inn ny stillingsprosent og/eller fradato i feltene under. Ønsker du flere stillingsprosenter for ulike tidsperioder kan dette oppnås ved å legge til en ny stillingsprosent.",
            actionButton: "Ferdig",
            value: "100",
            subLabel: "prosent",
            label: "Stillingsprosent"
        },
        multiple: {
            head: "x personer er valgt",
            title: "Endre stillingsprosent",
            description: "Skriv inn stillingsprosent i feltet under.",
            actionButton: "Ferdig",
            value: "100",
            subLabel: "prosent"
        },
        add: {
            head: "person.name",
            title: "Legg til stillingsprosent",
            description:
                "Skriv inn fradato og stillingsprosent i feltene under. Denne stillingsprosenten vil overstyre tidligere stillingsprosenter fra og med datoen den gjelder.",
            actionButton: "Ferdig",
            value: "100",
            subLabel: "prosent",
            label: "Stillingsprosent"
        },
        editWithoutFrom: {
            head: "person.name",
            title: "Endre stillingsprosent",
            description: "Skriv inn stillingsprosent i feltet under.",
            actionButton: "Ferdig",
            value: "100",
            subLabel: "prosent",
            label: "Stillingsprosent"
        },
        delete: {
            title: "Slett stillingsprosent",
            description:
                "Er du sikker på at du ønsker å slette stillingsprosent? Denne handlingen kan ikke angres.",
            actionButton: "Ja, slett stillingsprosent"
        }
    },

    active: {
        on: {
            head: "person.name",
            title: "Aktiver bruker",
            description: "Sikker på at du vil aktivere brukeren?",
            actionButton: "Ja, aktiver brukeren"
        },
        off: {
            head: "person.name",
            title: "Deaktiver bruker",
            description: "Sikker på at du vil deaktivere brukeren?",
            actionButton: "Ja, deaktiver brukeren"
        }
    },

    overtime: {
        one: {
            on: {
                head: "person.name",
                title: "Deaktiver overtid",
                description: "Sikker på at du vil deaktivere overtid?",
                actionButton: "Ja, deaktiver overtid"
            },
            off: {
                head: "person.name",
                title: "Aktiver overtid",
                description: "Sikker på at du vil aktivere overtid?",
                actionButton: "Ja, aktiver overtid"
            }
        },

        multiple: {
            on: {
                head: "x personer er valgt",
                title: "Deaktiver overtid",
                description: "Sikker på at du vil deaktivere overtid?",
                actionButton: "Ja, deaktiver overtid"
            },
            off: {
                head: "x personer er valgt",
                title: "Aktiver overtid",
                description: "Sikker på at du vil aktivere overtid?",
                actionButton: "Ja, aktiver overtid"
            }
        }
    },

    userGroup: {
        one: {
            head: "person.name",
            title: "Endre brukergruppe",
            desciption:
                "Sikker på at du vil endre personen sin brukergruppe? Personen vil få ett nytt rettighetsnivå.",
            actionButton: "Ja, endre brukergruppe",
            label: "Brukergruppe"
        }
    },

    note: {
        title: "Notat for ansatt",
        description:
            "Legg til et notat tilknyttet den respektive måneden. Notatat er kun synlig for administratorer og avdelingsledere på avdelingen tilknyttet personen.",
        subLabel: "Notat..."
    },

    incomingBalance: {
        timeBalance: {
            title: "Inngående timesaldo",
            description:
                "Endre inngående timesaldo for valgt år. Denne verdien overstyrer eventuell saldo hentet fra året før.",
            value: "0",
            subLabel: "timer",
            actionButton: "Ferdig"
        },
        vacationBalance: {
            title: "Inngående feriesaldo",
            description:
                "Endre inngående feriesaldo for valgt år. Denne verdien overstyrer eventuell saldo hentet fra året før.",
            value: "25",
            subLabel: "dager",
            actionButton: "Ferdig"
        }
    },

    payout: {
        payoutInstant: {
            head: "person.name",
            title: "Utbetal timer",
            description:
                "Utbetal timer for denne personen for den selekterte måneden. Utbetalte timer vises «Utbetalt» kolonnen.",
            value: "0",
            subLabel: "timer"
        },
        payoutAsk: {
            head: "person.name",
            title: "Forespør timeutbetaling",
            description:
                "Forespør utbetaling av timer for den selekterte måneden. Et varsel blir sendt til avdelingsleder/administrator som må godkjenne. Dersom utbetalingen godkjennes vil du motta en bekreftelsesvarsel på dette og utbetalte timer vil vises «Utbetalt» kolonnen.",
            value: "0",
            subLabel: "timer"
        }
    },
    register: {
        chooseClient: {
            title: "Koble selskap til konto",
            description:
                "Velg selskap fra 24SevenOffice i menyen under.\n\nSelskapet kobles til din konto i Busy, og timedata synkroniseres automatisk. \n\nMerk at dette kan ta noen minutter. Du blir automatisk logget inn når synkroniseringen er fullført.",
            actionButton: "Koble til"
        }
    },
    workType: {
        setSingel: {
            title: "Endre kategori for arbeidstype",
            description: "Velg en kategori i menyen under.",
            actionButton: "Endre kategori"
        },
        setMultiple: {
            title: "Endre kategori for arbeidstypene",
            description: "Velg en kategori i menyen under.",
            actionButton: "Endre kategori"
        }
    },
    notificationGroup: {
        add: {
            title: "Opprett ny varsel",
            description:
                "Velg hvem som skal motta varslinger om utbetalinger og annet for den selekterte avdelingen.",
            actionButton: "Ferdig",
            deleteButton: ""
        },
        edit: {
            title: "Endre varsel",
            description:
                "Velg hvem som skal motta varslinger om utbetalinger og annet for den selekterte avdelingen.",
            actionButton: "Ferdig",
            deleteButton: "Slett varsling"
        },
        delete: {
            title: "Slett varsel",
            description:
                "Er du sikker på at du ønsker å slette varselen? Denne handlingen kan ikke angres.",
            actionButton: "Ja, slett varsel"
        }
    }
};

export const columnDescriptions = {
    normalHours:
        "Normaltid til den ansatte pr. dag ganget med antall akkumulerte arbeidsdager for den respektive måneden.",
    clientHours:
        "Totalt antall registrerte timer tilknyttet kategorien «Kundetid» for den respektive måneden.",
    internalHours:
        "Totalt antall registrerte timer tilknyttet kategorien «Interntid» for den respektive måneden.",
    vacationHours:
        "Totalt antall registrerte timer tilknyttet kategorien «Ferie» for den respektive måneden.",
    timeOffHours:
        "Totalt antall registrerte timer tilknyttet kategorien «Avspasering» for den respektive måneden.",
    sickLeaveHours:
        "Totalt antall registrerte timer tilknyttet kategorien «Sykefravær» for den respektive måneden.",
    vacatedHours:
        "Totalt antall registrerte timer tilknyttet kategorien «Permisjon» for den respektive måneden.",
    registeredHours:
        "Totalt antall registrerte timer i 24SevenOffice, med unntak av timer tilknyttet kategorien «Ignorer».",
    extraHours:
        "Totalt antall registrert timer over ansattes stillingsprosent, men under grensen for en 100% stilling regnes som mertid. Gjelder kun for ansatte med en stillingsprosent under 100%.",
    overtimeHours: "Totalt antall registrerte timer over ansattes normaltid.",
    addition: "Overtid ganget med selskapets overtidstillegg.",
    minusHours: "Totalt antall registrerte timer under ansattes normaltid.",
    balanceVacationDays:
        "Disponibel feriesaldo. Beregning: [Forrige måneds feriesaldo] - [Totalt antall registrerte timer tilknyttet «Ferie» for inneværende måned].",
    balanceHours:
        "Disponibel akkumulert timesaldo. Beregning: [Forrige måneds timesaldo] + [Inneværende måneds overtid] + [Inneværende måneds minustid] - [Inneværende måneds avspasering] - [Inneværende måneds utbetalt]",
    withdrawnHours: "Totalt antall utbetalte timer for den respektive måneden.",
    invoiceFactor:
        "Prosent fakturerbare timer av totalt antall timer på jobb. Beregning: [Total fakturerbar tid] / [Total kundetid + total interntid]",
    invoiceFactorNormal:
        "Prosent fakturerbare timer av normaltid. Beregning: [Total fakturerbar tid / Normaltid]",
    invoiceFactorNormalVacation:
        "Prosent fakturerbare timer av normaltid minus ferie. Beregning: [Total fakturerbar tid + Timer i kategorien Interntid (Fakturerbar)] / [Normaltid - Timer i kategorien Ferie]",
    monthlyTurnover:
        "Omsetning for den respektive måneden. Beregning: [Totalt antall fakturerbare timer] x [Timepris for de respektive arbeidstypene timene er ført på]"
};

/*

export const componentDescriptions = {
    users: "A description about users.",
    department: "Desc about departments",
    workTypes: "Desc about workTypes",
    userDetails: {
        generic: "A description about user details",
        fullTimeEquivalent: "Desc about full time equivalent",
        settings: {
            department: "choose dep",
            userGroup: "choose usergroup",
            startDate: "choose startdate",
            endDate: "choose enddate",
            overTime: "choose overtime",
            showInDashboard: "choose showInDashboard",
            shouldReceiveEmailNotifications: "choose"
        }
    },
    ruleSettings: {
        tablePlaceholder: "A description about rules"
    },
    logic: {
        basis: "Desc",
        calculations: "Desc",
        subtractTimeOffFromOvertime: "Desc",
        accumulateAddition: "Desc",
        normalHours: "Desc",
        overtime: "Desc",
        overtimeAdditional: "Desc",
        vacationDays: "Desc"
    },

    displaySettings: {
        category: "Desc",
        balances: "Desc",
        calculations: "Desc",
        normalHours: "Desc",
        clientHours: "Desc",
        internalHours: "Desc",
        vacationHours: "Desc",
        timeOffHours: "Desc",
        extraHours: "Desc",
        overtimeHours: "Desc",
        addition: "Desc",
        minusHours: "Desc",
        monthlyTurnover: "Desc",
        invoiceFactor: "Desc",
        invoiceFactorNormal: "Desc",
        invoiceFactorNormalVacation: "Desc",
        withdrawnHours: "Desc",
        withdrawnHoursButton: "Desc",
        balanceVacationDays: "Desc",
        balanceHours: "Desc",
        sickLeaveHours: "Desc",
        vacatedHours: "Desc"
    },
    setup: {
        displaySettings: "displaySettings",
        rules: "rules",
        logic: "logic"
    }
};
*/
export const componentDescriptions = {
    timereport:
        "Timerapport gir oversikt over beregnet timedata. Grunnlaget i rapporten defineres ut fra ansattes stillingsprosent, samt oppsett og arbeidstyper i selskapsinnstillingene.. Les forklaring for hver enkelt utregning ved å hovre over kolonnetittel.",
    timecontrol:
        "Timekontroll gir oversikt over forventet arbeidstid. Rød indikerer timer ført under normaltid. Grønn indikerer timer ført på normaltid. Blå indikerer timer ført over normaltid.",
    timereportUser:
        "Timerapport gir oversikt over beregnet timedata. Grunnlaget i rapporten defineres ut fra stillingsprosent, samt oppsett og arbeidstyper i selskapsinnstillingene. Les forklaring for hver enkelt utregning ved å hovre over kolonnetittel.",
    timecontrolUser:
        "Timekontroll gir oversikt over forventet arbeidstid. Rød indikerer timer ført under normaltid. Grønn indikerer timer ført på normaltid. Blå indikerer timer ført over normaltid.",
    users:
        "Brukersiden gir oversikt over alle brukere i selskapet. Filtrer på avdeling og aktiv/inaktiv oppe til høyre. Endre innstillingene til en bruker ved å trykke på navnet til brukeren.",
    user:
        "Brukerprofilen danner grunnlaget for beregninger i rapporten tilknyttet den respektive brukeren. Alle endringer her vil bli synlig i loggen for den respektive brukeren.",
    department:
        "Avdelingssiden gir mulighet for å opprette nye avdelinger, samt legge til ansatte tilknyttet avdelingen. Filtrering av data tilknyttet de respektive avdelingene gjøres fra rapport-siden. Oppsett for hvem som skal motta varsler for de respektive avdelingen gjøres gjennom innstillingene under «Oppsett» og «Varsler».",
    workTypes:
        "Arbeidstyper er hentet fra 24SO, hvor de så kategoriseres med kundetid, interntid, ferie, avspasering, sykdom og permisjon i Busy. Kategoriene danner grunnlaget for nøkkeltall og saldoer i Busy.",
    userDetails: {
        generic:
            "Brukerprofilen danner grunnlaget for beregninger i rapporten tilknyttet den respektive brukeren. Alle endringer her vil bli synlig i loggen for den respektive brukeren.",
        fullTimeEquivalent:
            "Stillingsprosenten til brukeren danner grunnlaget for beregnet normaltid. Beregningen av normaltid gjøres ved å ta utgangspunkt i selskapets normaltid for perioden og gange det med brukerens stillingsprosent. Ved 8t normaltid for selskapet vil en bruker med 50% stilling akkumulere 4t normaltid hver arbeidsdag. Det er også mulig å opprette flere periodebaserte stillingsprosenter hvor beregning av normaltid mellom datoer gjøres automatisk.",
        settings: {
            department:
                "Avdelingen til brukeren gjør det mulig å filtrere data brukerdata tilknyttet avdelingen i rapportene. Opprettelse av nye avdelinger gjøres gjennom «Innstillinger» under «Avdelinger».",
            userGroup:
                "Rettighetsnivået avgjør hva brukeren kan se og endre. Ansatte kan kun se sin egen data. Avdelingsledere kan se og endre sin egen data, samt gjøre endringer på alle ansatte tilknyttet sin avdeling. Administratorer kan gjøre endringer på alle brukere, samt selskapsinnstillinger.",
            startDate:
                "Startdato for brukeren avgjør fra hvilken dato Busy skal hente timer fra 24SO, når normaltid skal akkumuleres fra og fra når brukeren vises i timerapporten.",
            endDate:
                "Sluttdato for brukeren avgjør fra hvilken dato Busy ikke lenger skal hente timer fra 24SO og når normaltid ikke lenger skal akkumuleres. Brukeren vil fra og med sluttdato ikke lenger vises i timerapporten.",
            overTime:
                "Overtid avgjør om brukeren skal akkumulere mertid, overtid og minustid som gir grunnlaget for timesaldoen. Dersom overtid ikke er aktivert vil brukeren heller ikke akkumulere en timesaldo.",
            showInDashboard:
                "Vis timer avgjør om brukeren skal vises i timerapporten eller ikke. Denne funksjonaliteten er hovedsakelig aktuell dersom en har brukere som ikke fører timer, men det er ønskelig at de skal ha tilgang til dataen i Busy. Brukere som har timene skjult vil heller ikke tas med i nøkkeltallsberegninger.",
            shouldReceiveEmailNotifications:
                "Varslinger på e-post avgjør om du vil motta varslinger tilknyttet utbetaling av timer og annet fra Busy på e-post. Du vil fortsatt motta varslinger inne i Busy selv om denne funksjonen er deaktivert."
        }
    },
    ruleSettings: {
        tablePlaceholder:
            "Regler gir virksomheten mulighet til å definere avvik fra ordinær arbeidstid. Dette kan være periodebaserte regel for normaltid (f.eks sommertid) eller dagsbaserte regler (f.eks halvdag på julaften). Grunnoppsettet for hvilke dager normaltid akkumuleres er definert ut fra standard norske helligdager. Endringer her vil påvirke alle brukere i selskapet."
    },
    logic: {
        logicBasis:
            "Logikk legger grunnlaget for hva resten av regelsettet i Busy skal basere seg på. Endringer her vil påvirke alle brukere i selskapet.",
        subtractTimeOffFromOvertime:
            "Avspaseringstrekk gjør at timer ført på arbeidstyper tilknyttet kategorien avspasering vil trekke fra overtiden. Dette er gjeldende for virksomheter som opererer med fleksitid. I de tilfeller hvor overtiden er større enn 0 vil overtiden reduseres med tid som er avspasert, men aldri lavere enn 0. Dette gjøres pr. periode (uke/mnd) avhenger av hvilken overtidsberegning som er valgt.",
        accumulateAddition:
            "Akkumulering av overtidstillegg gjør at overtidstillegget akkumuleres inn i timesaldoen pr. periode (uke/mnd) avhenger av hvilken overtidsberegning som er valgt. Hvor mange prosent av overtiden som skal akkumuleres inn i timesaldoen avgjøres av overtidstillegget spesifisert under «Grunnlag».",
        normalHours:
            "Normaltid danner grunnlaget for hvor mange timer som skal akkumuleres som normaltid for alle brukere tilknyttet selskapet pr. dag. Alle stillingsprosenter, regler o.l tar utgangspunkt i selskapets normaltid ved beregninger.",
        overtime:
            "Overtidsberegning danner grunnlaget for hvilken periode overtiden skal akkumuleres og «låses» inn i timesaldoen.",
        overtimeAdditional:
            "Overtidstillegget danner grunnlaget for hva som skal vises i «Tillegg» kolonnen. Hvor mye tillegg som skal genereres beregnes ut fra overtiden ganget med overtidstillegget.", //. Overtidstillegg akkumuleres kun inn i timesaldoen dersom «Akkumuler overtidstillegg» er aktivert under «Beregninger».",
        vacationDays:
            "Feriedager danner grunnlaget for hvor mange feriedager som skal legges til for hver bruker hvert år. Ved nytt år flyttes utgående feriesaldo over det nye året og det legges til 25 dager. Stillingsprosent påvirker antall tildelte feriedager hvert år, hvor en bruker med 50% stilling vil få tildelt 12,5 dager dersom virksomheten har 25 dager i grunnoppsettet. Antall feriedager for en bruker kan overstyres ved å sette inngående feriesaldo i timerapport for brukeren.",
        vacationDaysAdditionMonth: "Måned for ferietrekk bestemmer hvilken måned feriedagene legges til i feriesaldoen. Dette vil skje den første i den spesifiserte måneden."
    },

    displaySettings: {
        normalHours:
            "Velg om akkumulert normaltid for perioden skal vises på rapportsiden. Merk at dette kun påvirker hva som vises og ikke får noen konsekvenser for beregninger.",
        clientHours:
            "Velg om totalt antall timer ført på arbeidstyper tilknyttet kategorien «Kundetid» skal vises på rapportsiden. Merk at dette kun påvirker hva som vises og ikke får noen konsekvenser for beregninger.",
        internalHours:
            "Velg om totalt antall timer ført på arbeidstyper tilknyttet kategorien «Interntid» skal vises på rapportsiden. Merk at dette kun påvirker hva som vises og ikke får noen konsekvenser for beregninger.",
        vacationHours:
            "Velg om totalt antall timer ført på arbeidstyper tilknyttet kategorien «Ferie» skal vises på rapportsiden. Merk at dette kun påvirker hva som vises og ikke får noen konsekvenser for beregninger.",
        timeOffHours:
            "Velg om totalt antall timer ført på arbeidstyper tilknyttet kategorien «Avspasering» skal vises på rapportsiden. Merk at dette kun påvirker hva som vises og ikke får noen konsekvenser for beregninger.",
        sickLeaveHours:
            "Velg om totalt antall timer ført på arbeidstyper tilknyttet kategorien «Sykefravær» skal vises på rapportsiden. Merk at dette kun påvirker hva som vises og ikke får noen konsekvenser for beregninger.",
        vacatedHours:
            "Velg om totalt antall timer ført på arbeidstyper tilknyttet kategorien «Permisjon» skal vises på rapportsiden. Merk at dette kun påvirker hva som vises og ikke får noen konsekvenser for beregninger.",
        extraHours:
            "Velg om kolonne med akkumulert mertid for perioden (uke/mnd, avhenger av overtidsberegning) skal vises på rapportsiden. Merk at dette kun påvirker hva som vises og ikke får noen konsekvenser for beregninger.",
        overtimeHours:
            "Velg om kolonne og nøkkeltall med akkumulert overtid for perioden (uke/mnd, avhenger av overtidsberegning) skal vises på rapportsiden. Merk at dette kun påvirker hva som vises og ikke får noen konsekvenser for beregninger.",
        addition:
            "Velg om akkumulert overtidstillegg for perioden (uke/mnd, avhenger av overtidsberegningen) skal vises på rapportsiden. Merk at dette kun påvirker hva som vises og ikke får noen konsekvenser for beregninger.",
        minusHours:
            "Velg om kolonne med akkumulert minustid for perioden (uke/mnd, avhenger av overtidsberegning) skal vises på rapportsiden. Merk at dette kun påvirker hva som vises og ikke får noen konsekvenser for beregninger.",
        monthlyTurnover:
            "Velg om kolonne og nøkkeltall med omsetning skal vises på rapportsiden. Merk at dette kun påvirker hva som vises og ikke får noen konsekvenser for beregninger. Beregning: [Totalt antall fakturerbare timer] x [Timepris for de respektive arbeidstypene timene er ført på]",
        invoiceFactor:
            "Velg om kolonne og nøkkeltall med faktureringsgrad skal vises på rapportsiden. Merk at dette kun påvirker hva som vises og ikke får noen konsekvenser for beregninger. FG er definert som prosent fakturerbare timer av totalt antall timer på jobb. Beregning: [Total fakturerbar tid] / [Total kundetid + total interntid]",
        invoiceFactorNormal:
            "Velg om kolonne og nøkkeltall med FGN skal vises på rapportsiden. Merk at dette kun påvirker hva som vises og ikke får noen konsekvenser for beregninger. FGN er definert som prosent fakturerbare timer normaltid. Beregning: [Total fakturerbar tid] / [Normaltid]",
        invoiceFactorNormalVacation:
            "Velg om kolonne og nøkkeltall med FGN-F skal vises på rapportsiden. Merk at dette kun påvirker hva som vises og ikke får noen konsekvenser for beregninger. FGN-F er definert som prosent fakturerbare timer av normaltid, minus ferie. Beregning: [Total fakturerbar tid + timer i kategorien «Interntid (Fakturerbar)»] / [Normaltid - Timer i kategorien «Ferie»]",
        withdrawnHours:
            "Velg om kolonne med utbetalte timer skal vises på rapportsiden. Merk at dette kun påvirker hva som vises og ikke får noen konsekvenser for beregninger.",
        withdrawnHoursButton:
            "Velg om knapp med funksjonalitet for utbetaling timer skal vises på rapportsiden til en bruker. Dette vil gjøre det mulig for brukere å forespørre og gjennomføre utbetalinger, hvor antall timer godkjent vil fremkomme i utbetalt-kolonnen. Merk at dette kun påvirker hva som vises og ikke får noen konsekvenser for beregninger.",
        balanceVacationDays:
            "Velg om kolonne og nøkkeltall med feriesaldo skal vises på rapportsiden. Merk at dette kun påvirker hva som vises og ikke får noen konsekvenser for beregninger.",
        balanceHours:
            "Velg om kolonne og nøkkeltall med timesaldo skal vises på rapportsiden. Merk at dette kun påvirker hva som vises og ikke får noen konsekvenser for beregninger."
    },
    setup: {
        displaySettings:
            "Visning danner grunnlaget for hva som skal vises og ikke vises i Busy. Merk at dette kun påvirker hva som vises og ikke får noen konsekvenser for beregninger.",
        logic:
            "Logikk danner hovedgrunnlaget for selskapets beregninger i Busy. Merk at endringer her påvirker beregninger for hele selskapet, avdelinger og brukere.",
        rules:
            "Bygg regler spesialtilpasset din virksomhet. Regel for normaltid benyttes for å definere avvik fra ordinær arbeidstid på en dag eller over en periode (f.eks sommertid eller fri i romjulen). Grunnoppsettet for hvilke dager normaltid akkumuleres er definert ut fra standard norske helligdager. Regel for overtidstillegg benyttes for å definere endring i overtidstillegget over en periode. Endringer her vil påvirke alle brukere i selskapet.",
        rulePeriod:
            "Merk at periodebaserte regler som opprettes ikke påvirker helligdager. For å overstyre helligdager må dagsbasert regeltype velges.",
        notificationTargetGroup:
            "Varsler gir selskapet mulighet til å velge hvem som skal motta varsler om utbetalinger og lignende for selskapet og avdelinger. Varsler sendes både inne i Busy og på e-post (om aktivert på brukerprofilen)."
    },
    profile:
        "Profilen din danner grunnlaget for beregninger i timerapporten. Alle endringer her vil bli synlig i loggen for den respektive brukeren."
};

export const confirmationMessages = {
    changeHasWeekBasedCalculation: "Endring av overtidsberegningen vil påvirke utregningene og slette eventuelle regler tilknyttet overtidstillegg. Denne handlingen kan ikke angres."
};
export const disabledReasons = {
    UNAUTHORIZED: "Du har ikke rettigheter til å endre dette feltet.",
    IS_SYNCHRONIZED:
        "Dette feltet blir synkronisert fra 24SO, og kan ikke endres i Busy."
};
