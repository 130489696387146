import React, { Component } from "react";
import SimpleDialog from "common/dialogs/SimpleDialog";
import ButtonGroup from "common/dialogs/dialogContent/DialogButtonGroup";
import {
    REQUEST_CREATE_FULL_TIME_EQUIVALENT,
    REQUEST_DELETE_FULL_TIME_EQUIVALENT,
    REQUEST_UPDATE_FULL_TIME_EQUIVALENT
} from "../../actionTypes";
import InputField from "common/forms/text-fields/InputField";
import { connect } from "react-redux";
import * as modalActions from "./actions";
import { withRouter } from "react-router-dom";
import InputDatePicker from "../../common/forms/date-picker/InputDatePicker";
import moment from "moment";
import IconPopupMenu from "../../common/poppers/IconPopupMenu";
import { getFullTimeEquivalentFromUser } from "../users/userDetails/selectors";

class FullTimeEquivalentModal extends Component {
    state = {
        fullTimeEquivalent: {
            fromDate: moment(),
            value: "100"
        },
        editId: null
    };
    componentDidMount() {
        const { fullTimeEquivalent } = this.props;
        // Is editing
        if (fullTimeEquivalent) {
            this.setState({
                fullTimeEquivalent: {
                    fromDate: moment(fullTimeEquivalent.fromDate),
                    value: fullTimeEquivalent.value
                },
                editId: fullTimeEquivalent.id
            });
        }
    }

    handleInputChange = e => {
        this.setState({
            fullTimeEquivalent: {
                ...this.state.fullTimeEquivalent,
                [e.target.name]: e.target.value
            }
        });
    };
    handleDateChange = date => {
        this.setState({
            fullTimeEquivalent: {
                ...this.state.fullTimeEquivalent,
                fromDate: date
            }
        });
    };

    handleMenuChange = index => {
        if (index === 0) {
            this.props.deleteFullTimeEquivalent(
                this.props.user.id,
                this.state.editId
            );
            this.props.onClose();
        }
    };

    onConfirm = () => {
        const { isFirstFullTimeEquivalent } = this.props;
        const ftp = {
            ...this.state.fullTimeEquivalent,
            value: parseFloat(this.state.fullTimeEquivalent.value)
        };
        if (!this.state.editId) {
            this.props.createFullTimeEquivalent(this.props.user.id, ftp);
        } else {
            this.props.updateFullTimeEquivalent(
                this.props.user.id,
                this.state.editId,
                !isFirstFullTimeEquivalent ? ftp : { value: ftp.value }
            );
        }
        this.props.onClose();
    };

    render() {
        const { fromDate, value } = this.state.fullTimeEquivalent;
        const { isFirstFullTimeEquivalent } = this.props;

        const isEditing = this.state.editId !== null;
        return (
            <SimpleDialog
                {...this.props.modalProps}
                open={true}
                buttonGroup={
                    <ButtonGroup
                        onConfirm={this.onConfirm}
                        onCancel={this.props.onClose}
                    />
                }
                menu={
                    isEditing && !isFirstFullTimeEquivalent ? (
                        <IconPopupMenu
                            placement={"left"}
                            items={["Slett stillingsprosent"]}
                            highlighted={[0]}
                            onChange={this.handleMenuChange}
                            arrow={false}
                            inTable
                        />
                    ) : null
                }
            >
                {!isFirstFullTimeEquivalent ? (
                    <InputDatePicker
                        name={"fromDate"}
                        label={"Fra dato"}
                        onChange={this.handleDateChange}
                        date={fromDate}
                    />
                ) : null}
                <InputField
                    onChange={this.handleInputChange}
                    label={"Verdi"}
                    name={"value"}
                    value={value}
                    NumberFormatProps={{
                        isAllowed: ({ floatValue }) =>
                            !floatValue ||
                            (floatValue >= 0 && floatValue <= 100)
                    }}
                    numeric
                />
            </SimpleDialog>
        );
    }
}

const mapStateToProps = (state, props) => {
    const { fullTimeEquivalentId, userId } = props.modalProps;

    const user = state.users.items[userId] || {};

    const fullTimeEquivalent =
        fullTimeEquivalentId && user.fullTimeEquivalent
            ? {
                  ...user.fullTimeEquivalent[fullTimeEquivalentId],
                  id: fullTimeEquivalentId
              }
            : null;

    const fte = getFullTimeEquivalentFromUser(user);

    const isFirstFullTimeEquivalent = fullTimeEquivalent
        ? fte.length > 0 && fte[fte.length - 1].id === fullTimeEquivalent.id
        : false;

    return {
        fullTimeEquivalent,
        isFirstFullTimeEquivalent,
        user: user
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        updateFullTimeEquivalent: (userId, fullTimeEquivalentId, payload) => {
            //    const {companyKey, userId, key, payload} = action;
            dispatch({
                type: REQUEST_UPDATE_FULL_TIME_EQUIVALENT,
                userId,
                fullTimeEquivalentId,
                payload
            });
        },
        createFullTimeEquivalent: (userId, payload) => {
            dispatch({
                type: REQUEST_CREATE_FULL_TIME_EQUIVALENT,
                userId,
                payload
            });
        },
        deleteFullTimeEquivalent: (userId, fullTimeEquivalentId) => {
            dispatch({
                type: REQUEST_DELETE_FULL_TIME_EQUIVALENT,
                payload: {
                    fullTimeEquivalentId,
                    userId
                }
            });
        },
        onClose: () => dispatch(modalActions.closeModal())
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(FullTimeEquivalentModal)
);
