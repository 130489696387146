import React, { Component } from "react";
import * as _ from "lodash";
import PropTypes from "prop-types";
import Typography from "../Typography";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";

import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "../forms/Checkbox";

const styles = theme => {
    return {
        //leftCell: { paddingLeft: theme.spacing.gridMargin },
        //rightCell: { paddingRight: theme.spacing.gridMargin },
        topRow: {
            textAlign: "left",
            display: "flex",
            alignItems: "center",
            "&:first-child": {
                paddingLeft: "20px"
            },
            "&:last-child": {
                paddingRight: "20px"
            },
            "&:last-child $numeric": {
                paddingRight: "10px"
            }
        },
        numeric: {
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "10px",
            boxSizing: "border-box"
        },
        sortLabelNumeric: {
            flexDirection: "row-reverse"
        },
        tableHeadContainer: {
            border: `${theme.spacing.normalStroke} solid ${theme.palette.C_DB10}`,
            borderLeft: "none",
            borderRight: "none",
            backgroundColor: theme.palette.C_DB5,
            borderTopRightRadius: "6px",
            borderTopLeftRadius: "6px"
        },
        sortLabel: {
            width: "12px"
        },
        tableRow: {
            height: "40px",
            display: "flex",
            justifyContent: "space-between"
        },
        checkbox: {
            bottom: "5px"
        }
    };
};

/**
 * @description A component which is used as the columns headers in a table.
 */
class TableColumnHeader extends Component {
    renderTableColumnContent = column => (
        <Typography
            title={column.description}
            size={"smallMed"}
            bold
            singleLine
        >
            {column.label}
        </Typography>
    );

    render() {
        const {
            columns,
            classes,
            orderBy,
            direction,
            onSort,
            checkbox,
            numbRows
        } = this.props;

        const columnsHeaders = [];
        const withCheckbox = Boolean(checkbox);

        if (withCheckbox) {
            const intederminate =
                checkbox.numSelected > 0 && checkbox.numSelected < numbRows;
            const selected =
                checkbox.numSelected === numbRows && checkbox.numSelected > 0;
            columnsHeaders.push(
                <div key={"checkbox"}>
                    <Checkbox
                        onChange={() => checkbox.onSelectAll(!selected)}
                        className={classes.checkbox}
                        checked={selected}
                        indeterminate={intederminate}
                    />
                </div>
            );
        }

        _.forEach(columns, (column, i) => {
            const isNumeric = column.type === "numeric";

            const isLastCell = i === columns.length - 1;

            const columnRootClassname = classNames(classes.topRow, {
                [classes.leftCell]: i === 0,
                [classes.numeric]: isNumeric,
                [classes.rightCell]: isLastCell
            });
            const columnStyle = { width: column.width };

            const tableHeaderContent =
                column.orderable !== false ? (
                    <TableSortLabel
                        active={orderBy === column.id}
                        direction={direction}
                        className={classNames({
                            [classes.sortLabelNumeric]: isNumeric
                        })}
                        onClick={() => onSort(column.id, isNumeric)}
                        classes={{ icon: classes.sortLabel }}
                    >
                        {this.renderTableColumnContent(column)}
                    </TableSortLabel>
                ) : (
                    this.renderTableColumnContent(column)
                );

            columnsHeaders.push(
                <div
                    key={column.id}
                    className={columnRootClassname}
                    style={columnStyle}
                >
                    {tableHeaderContent}
                </div>
            );
        });

        return (
            <div
                className={classes.tableHeadContainer}
                data-test-id="table-head"
            >
                <div className={classes.tableRow} data-test-id="table-row">
                    {columnsHeaders}
                </div>
            </div>
        );
    }
}
export const TableColumnHeaderProps = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.oneOf([
                "icon",
                "numeric",
                "string",
                "switch",
                "name",
                "custom"
            ]),
            // The value to show in the corresponding cell.
            value: PropTypes.oneOfType([
                //For switch columns
                PropTypes.bool,
                //For numeric columns
                PropTypes.number,
                //For string columns
                PropTypes.string
            ]),

            //Will be clickable if onClick is defined
            onClick: PropTypes.func,

            //Should be same as the corresponding item key
            id: PropTypes.string.isRequired,

            //Title of column. Can be empty string
            label: PropTypes.string.isRequired,

            // A custom component to render
            ColumnComponent: PropTypes.node,

            /*
             * A function to call for custom rendering of a cell
             * Will be called as renderColumn(rowData, index)
             */

            renderColumn: PropTypes.func,
            order: PropTypes.func
        })
    ).isRequired,

    /**
     * The id of the column which is currently ordered by
     */
    orderBy: PropTypes.string,
    /**
     * The direction the orderBy column is ordered in
     */
    direction: PropTypes.string,

    /**
     * A function called when sorting by a column is requested.
     * Is called with the column id and whether the column is numeric
     */
    onSort: PropTypes.func
};

TableColumnHeader.propTypes = TableColumnHeaderProps;

export default withStyles(styles)(TableColumnHeader);
