import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "common/forms/FormControl";
import ButtonBase from "./ButtonBase";
import Typography from "../../common/Typography";
import * as _ from "lodash";
import LockIcon from "common/icons/LockIcon";
import classNames from "classnames";

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.C_W,
        border: `solid 1px ${theme.palette.C_DB20}`,
        borderRadius: "6px",
        display: "flex",
        flexDirection: "column",
        borderBottom: "none",
        alignItems: "center",
        justifyContent: "flex-start",
        "&:hover": {
            "& $itemContentFont": {
                color: theme.palette.C_LB
            }
        }
    },
    disabled: {
        backgroundColor: theme.palette.C_DB5
    },
    item: {
        height: "40px",
        padding: "0 10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: `solid 1px ${theme.palette.C_DB20}`,
        width: "calc(100% - 20px)" // padding
    },
    itemContentFont: {},
    itemEndContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        minWidth: "80px"
    },
    lockIcon: {
        width: "15px",
        height: "10px",
        color: theme.palette.C_DB40
    }
});

class InfoListButton extends PureComponent {
    renderItems = () => {
        const { items, classes, disabled } = this.props;
        return _.map(items, (item, i) => (
            <div className={classes.item} key={i}>
                <Typography color={"superFaded"}>{item.label}</Typography>
                <div className={classes.itemEndContainer}>
                    {disabled ? (
                        <LockIcon className={classes.lockIcon} />
                    ) : null}
                    <Typography semibold className={classes.itemContentFont}>
                        {typeof item.value === "function"
                            ? item.value()
                            : item.value}
                    </Typography>
                </div>
            </div>
        ));
    };
    render() {
        const {
            label,
            items,
            onClick,
            classes,
            disabled,
            ...other
        } = this.props;

        return (
            <FormControl label={label} {...other}>
                {items.length > 0 ? (
                    <ButtonBase
                        disabled={disabled}
                        onClick={onClick}
                        className={classNames(classes.root, {
                            [classes.disabled]: disabled
                        })}
                    >
                        {this.renderItems()}
                    </ButtonBase>
                ) : null}
            </FormControl>
        );
    }
}

InfoListButton.propTypes = {
    /**
     * A label which works as a title for the button
     */
    label: PropTypes.string,

    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
                .isRequired
        })
    ),
    onClick: PropTypes.func
};

InfoListButton.defaultProps = {
    items: [],
    onClick: () => {},
    label: ""
};
export default withStyles(styles)(InfoListButton);
