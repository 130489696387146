import React from "react";
import WorkTypesHeader from "./WorkTypesHeader";
import WorkTypesTable from "./WorkTypesTable";
import ContentPaper from "../../../components/layout/paper/ContentPaper";

const WorkTypes = props => {
    return (
        <ContentPaper>
            <WorkTypesHeader />
            <WorkTypesTable />
        </ContentPaper>
    );
};

export default WorkTypes;
