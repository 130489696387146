import React, { Component, Fragment } from "react";
import Table from "../../common/table/Table";
import * as _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as selectors from "./selectors";
import { getComputedTableRows } from "./selectors";
import ExpandableTableRow from "../../common/table/ExpandableTableRow";
import TotalsFooter from "../../common/table/TotalsFooter";
import * as actionCreators from "./actions";
import * as companySelectors from "../../selectors/company";
import CellMeasurer, {
    CellMeasurerCache
} from "react-virtualized/dist/commonjs/CellMeasurer";
import TableCell from "../../common/table/cells/TableCell";
import { compose } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";

const MIN_COL_WIDTH = 85;
const INITIAL_ORDER = { direction: "asc", orderBy: "name" };

const styles = {
    tableRow: {
        display: "flex",
        justifyContent: "space-between"
    }
};
class TimeBankTable extends Component {
    constructor(props) {
        super(props);
        this.cache = new CellMeasurerCache({
            defaultHeight: 50,
            minHeight: 50,
            fixedWidth: true
        });

        this.state = {
            columnData: [],
            tableStyle: { minWidth: "1500px" },
            expanded: {}
        };
    }

    handleExpandChange = measure => (index, numChildren) => {
        this.cache.clear(index);
        this.setState(
            {
                expanded: { ...this.state.expanded, [index]: { numChildren } }
            },
            measure
        );
    };
    updateColumnsAndWidth = () => {
        const newColumnWidths = _.map(this.props.columns, c => {
            let width = `${90 / this.props.columns.length}%`;
            if (c.id === "name") width = "12%";
            else if (c.id === "comment") width = "1%";
            return {
                ...c,
                width
            };
        });
        this.setState({
            columnData: newColumnWidths,
            tableStyle: {
                minWidth: `${this.props.columns.length * MIN_COL_WIDTH}px`
            }
        });
    };

    componentDidMount() {
        this.requestTimebank();
        this.updateColumnsAndWidth();
    }

    componentWillUpdate(nextProps) {
        this.updateColumnsAndWidth();
        this.list && this.list.recomputeRowHeights();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            nextProps.timebank === this.props.timebank &&
            nextProps.hasStaleData === this.props.hasStaleData &&
            nextProps.meta === this.props.meta &&
            nextProps.columns === this.props.columns &&
            nextState.columnData !== this.state.columnData
        ) {
            return false;
        }
        return true;
    }

    componentDidUpdate(prevProps) {
        //If new date has been set, or department has been changed
        if (
            prevProps.currentDepartment !== this.props.currentDepartment ||
            prevProps.currentDate !== this.props.currentDate ||
            (prevProps.hasStaleData === false &&
                this.props.hasStaleData === true)
        ) {
            this.requestTimebank();
        }
    }

    requestTimebank = () => {
        const {
            currentDate,
            fetchTimebank,
            companyId,
            currentDepartment
        } = this.props;

        fetchTimebank(companyId, currentDate, currentDepartment);
    };

    onRowClick = item => {
        const { currentDate, requestTimebankWeeks } = this.props;
        requestTimebankWeeks(item.id, currentDate);
    };

    renderRow = data => ({ index, isVisible, key, parent, style }) => {
        if (index === data.length) {
            return (
                <CellMeasurer
                    cache={this.cache}
                    columnIndex={0}
                    key={key}
                    parent={parent}
                    rowIndex={index}
                >
                    <TotalsFooter
                        columns={this.state.columnData}
                        row={this.props.totals}
                        style={style}
                        key={"footer"}
                    />
                </CellMeasurer>
            );
        }
        const item = data[index];

        const tableCells = _.map(
            this.state.columnData,
            (column, columnIndex) => (
                <TableCell
                    key={column.id}
                    item={item}
                    column={column}
                    tot={this.state.columnData.length}
                    index={columnIndex}
                />
            )
        );

        return (
            <CellMeasurer
                cache={this.cache}
                columnIndex={0}
                key={key}
                parent={parent}
                rowIndex={index}
            >
                {({ measure }) => (
                    <div style={style}>
                        <ExpandableTableRow
                            className={this.props.classes.tableRow}
                            item={item}
                            columns={this.state.columnData}
                            children={tableCells}
                            index={index}
                            onRowClick={this.onRowClick}
                            onHeightChange={measure}
                        />
                    </div>
                )}
            </CellMeasurer>
        );
    };
    render() {
        const { timebank, onSort } = this.props;
        const { columnData } = this.state;

        return (
            <Table
                data={timebank}
                columns={columnData}
                onSort={onSort}
                initialOrder={INITIAL_ORDER}
                style={this.state.tableStyle}
                rowCount={timebank.length + 1}
                cache={this.cache}
                renderRow={this.renderRow}
                listRef={r => (this.list = r)}
            />
        );
    }
}

const mapStateToProps = (state, props) => {
    const { timebank, totals } = getComputedTableRows(state, props);
    return {
        companyId: companySelectors.getCompanyId(state, props),
        currentDate: selectors.getCurrentDate(state, props),
        currentDepartment: selectors.getCurrentDepartmentOrDefault(
            state,
            props
        ),
        timebank,
        totals,
        hasStaleData: selectors.getHasStaleData(state, props),
        meta: selectors.getTimebankMeta(state, props),
        columns: selectors.getFilteredColumnsByView(state, props)
    };
};

export default withRouter(
    compose(connect(mapStateToProps, actionCreators), withStyles(styles))(
        TimeBankTable
    )
);
