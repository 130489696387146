import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import SwitchBase from "@material-ui/core/internal/SwitchBase";

export const styles = theme => ({
    /* Styles applied to the root element. */
    root: {
        display: "inline-flex",
        width: 62,
        position: "relative",
        flexShrink: 0,
        // For correct alignment with the text.
        verticalAlign: "middle",
        "& $disabled": {
            color: theme.palette.C_W
        }
    },
    /* Styles used to create the `icon` passed to the internal `SwitchBase` component `icon` prop. */
    icon: {
        backgroundColor: "currentColor",
        width: 12,
        height: 12,
        borderRadius: "50%",
        top: 3,
        position: "relative"
    },
    /* Styles applied the icon element component if `checked={true}`. */
    iconChecked: {},
    /* Styles applied to the internal `SwitchBase` component's `root` class. */
    switchBase: {
        zIndex: 1,
        left: 13,
        color:
            theme.palette.type === "light"
                ? theme.palette.grey[50]
                : theme.palette.grey[400],
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        }),
        "&:hover": {
            "& + $bar": {
                backgroundColor: theme.palette.C_DB60
            }
        }
    },
    /* Styles applied to the internal `SwitchBase` component's `checked` class. */
    checked: {
        transform: "translateX(14px)"
    },

    /* Styles applied to the internal SwitchBase component's root element if `color="primary"`. */
    colorChecked: {
        "&$checked": {
            "& + $bar": {
                backgroundColor: theme.palette.C_LB90
            }
        }
    },
    /* Styles applied to the internal SwitchBase component's root element if `color="secondary"`. */
    colorSecondary: {
        "&$checked": {
            color: theme.palette.secondary.main,
            "& + $bar": {
                backgroundColor: theme.palette.secondary.main
            }
        }
    },
    /* Styles applied to the internal SwitchBase component's disabled class. */
    disabled: {},
    /* Styles applied to the bar element. */
    bar: {
        borderRadius: "10px", // Max radius
        display: "block",
        position: "absolute",
        width: 35,
        height: 20,
        top: "50%",
        left: "50%",
        marginTop: -7,
        marginLeft: -10,
        transition: theme.transitions.create(["opacity"], {
            duration: theme.transitions.duration.shortest
        }),
        backgroundColor: theme.palette.C_DB40
    }
});

function Switch(props) {
    const { classes, className, color, ...other } = props;

    return (
        <span className={classNames(classes.root, className)}>
            <SwitchBase
                disableRipple
                icon={<span className={classes.icon} />}
                type={"checkbox"}
                classes={{
                    root: classNames(classes.switchBase, classes.colorChecked),
                    checked: classes.checked,
                    disabled: classes.disabled
                }}
                checkedIcon={
                    <span
                        className={classNames(
                            classes.icon,
                            classes.iconChecked
                        )}
                    />
                }
                {...other}
            />
            <span className={classes.bar} />
        </span>
    );
}

Switch.propTypes = {
    /**
     * If `true`, the component is checked.
     */
    checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /**
     * The icon to display when the component is checked.
     */
    checkedIcon: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     * See [CSS API](#css-api) below for more details.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * @ignore
     */
    defaultChecked: PropTypes.bool,
    /**
     * If `true`, the switch will be disabled.
     */
    disabled: PropTypes.bool,
    /**
     * If `true`, the ripple effect will be disabled.
     */
    disableRipple: PropTypes.bool,
    /**
     * The icon to display when the component is unchecked.
     */
    icon: PropTypes.node,
    /**
     * The id of the `input` element.
     */
    id: PropTypes.string,
    /**
     * Attributes applied to the `input` element.
     */
    inputProps: PropTypes.object,
    /**
     * Use that property to pass a ref callback to the native input component.
     */
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    /**
     * Callback fired when the state is changed.
     *
     * @param {object} event The event source of the callback.
     * You can pull out the new value by accessing `event.target.checked`.
     * @param {boolean} checked The `checked` value of the switch
     */
    onChange: PropTypes.func,
    /**
     * The input component property `type`.
     */
    type: PropTypes.string,
    /**
     * The value of the component.
     */
    value: PropTypes.string
};

Switch.defaultProps = {
    color: "secondary",
    disabled: false
};

export default withStyles(styles)(Switch);
