import React from "react";
import TimebankTable from "./TimeBankTable";
import { userGroups } from "../../actionTypes";
import Authenticated from "../../common/auth/Authenticated";
import DefaultErrorBoundry from "../../common/errors/DefaultErrorBoundry";
import ContentPaper from "../../components/layout/paper/ContentPaper";
import TimebankHeader from "./TimebankHeader";
import TimebankInfoBar from "./TimebankInfoBar";
import * as selectors from "./selectors";
import TimebankGrid from "./TimebankGrid";
import { connect } from "react-redux";
const TimeBank = ({ meta }) => {
    return (
        <Authenticated
            userGroups={[userGroups.ADMIN, userGroups.DEPARTMENT_LEADER]}
            fullPage
        >
            <DefaultErrorBoundry>
                <TimebankInfoBar />
            </DefaultErrorBoundry>
            <DefaultErrorBoundry>
                <ContentPaper>
                    <TimebankHeader />
                    {meta.currentTab === "GRID" ? (
                        <TimebankGrid />
                    ) : (
                        <TimebankTable />
                    )}
                </ContentPaper>
            </DefaultErrorBoundry>
        </Authenticated>
    );
};

export default connect(state => ({
    meta: selectors.getTimebankMeta(state)
}))(TimeBank);
