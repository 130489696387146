import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    root: {
        overflowY: "auto",
        maxHeight: "calc(100% - 80px)", // Minus header

        display: "flex",
        justifyContent: "flex-start"
    },
    innerRoot: {
        marginBottom: "40px",
        height: "100%",
        "& > h2": {
            fontSize: theme.typography.F_M,
            fontWeight: "bold",
            marginBottom: "20px",
            marginTop: "40px"
        },
        "& > h4": {
            fontWeight: "bold",
            margin: "20px 0 10px 0"
        },
        "& > p": {
            "& > span": {
                display: "inline"
            },
            margin: "10px 0px 20px 0px"
        },
        "& > ul": {
            marginTop: "-10px",
            "& > li": {
                display: "list-item"
            }
        },
        "& > table": {
            width: "100%",
            tableLayout: "fixed",
            "& > tr": {
                "& > td": {
                    display: "table-cell"
                }
            },
            "& > thead": {
                "& > tr": {
                    "& > td": {
                        display: "table-cell",
                        fontWeight: "bold"
                    }
                }
            }
        },
        "& > hr": {
            borderColor: theme.palette.C_DB15,
            margin: "20px 0"
        }
    }
});

class TextContent extends PureComponent {
    render() {
        return (
            <div className={this.props.classes.root}>
                <div className={this.props.classes.innerRoot}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

TextContent.propTypes = {};

export default withStyles(styles)(TextContent);
