import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import Button from "../../forms/buttons/Button";

const styles = theme => ({
    defaultGroup: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        marginTop: "25px"
    },
    singleButtonGroup: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginTop: "20px"
    },
    cancelBtn: {
        width: "35%"
    },
    confirmBtn: {
        width: "60%"
    },
    okBtn: {
        width: "100%"
    }
});

class DialogButtonGroup extends PureComponent {
    // Stop form from refreshing page
    onConfirm = event => {
        event.preventDefault();
        this.props.onConfirm();
    };
    render() {
        const {
            type,
            onCancel,
            cancelButton,
            confirmButton,
            okButton,
            deleteButton,
            classes,
            disabled
        } = this.props;

        if (type === "default") {
            return (
                <div className={classes.defaultGroup}>
                    <Button
                        variant={"secondary"}
                        onClick={onCancel}
                        children={cancelButton}
                        className={classes.cancelBtn}
                    />
                    <Button
                        variant={"primary"}
                        onClick={this.onConfirm}
                        children={confirmButton}
                        className={classes.confirmBtn}
                        disabled={disabled}
                        type={"submit"}
                    />
                </div>
            );
        } else if (type === "ok") {
            return (
                <div className={classes.singleButtonGroup}>
                    <Button
                        variant={"primary"}
                        onClick={this.onConfirm}
                        children={okButton}
                        className={classes.okBtn}
                    />
                </div>
            );
        } else if (type === "delete") {
            return (
                <div className={classes.defaultGroup}>
                    <Button
                        variant={"secondary"}
                        onClick={onCancel}
                        children={cancelButton}
                        className={classes.cancelBtn}
                    />
                    <Button
                        variant={"warning"}
                        onClick={this.onConfirm}
                        children={deleteButton}
                        className={classes.confirmBtn}
                        type={"submit"}
                    />
                </div>
            );
        } else
            throw new Error(
                "Invalid type of button group. Expected one of [ok, default, delete]"
            );
    }
}

DialogButtonGroup.propTypes = {
    type: PropTypes.oneOf(["ok", "default", "delete"]),
    cancelButton: PropTypes.node,
    confirmButton: PropTypes.node,
    okButton: PropTypes.node,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    disabled: PropTypes.bool
};

DialogButtonGroup.defaultProps = {
    type: "default",
    okButton: "Ok",
    cancelButton: "Avbryt",
    confirmButton: "Lagre",
    deleteButton: "Slett",
    disabled: false
};

export default withStyles(styles)(DialogButtonGroup);
