import * as _ from "lodash";
import { findIndex, forEach, keys } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    REQUEST_UPDATE_FULL_TIME_EQUIVALENT,
    REQUEST_UPDATE_MULT_USERS,
    REQUEST_UPDATE_USER,
    SET_USER_FILTERS,
    userGroups
} from "../../actionTypes";
import Menu from "../../common/dropdown/Menu";
import Table from "../../common/table/Table";
import {
    createTrueObjectFromIds,
    getObjectTrueValuesCount
} from "../../utils/index";
import { fetchUsers, setShallowUserFilters } from "./actions";
import * as userSelectors from "../../selectors/user";
import * as selectors from "./selectors";
import { getCurrentFullTimeEquivalent } from "./selectors";
import * as companySelectors from "../../selectors/company";
import CellInputField from "../../common/forms/text-fields/CellInputField";
import CellPopupMenu from "../../common/poppers/CellPopupMenu";
import CellDatePicker from "../../common/forms/date-picker/CellDatePicker";
import moment from "moment";
import AvatarCell from "../../common/table/cells/AvatarCell";
const options = ["Endre avdeling", "Endre startdato"];
const tableStyles = { minWidth: "1200px" };
const tableBodyStyle = { height: "calc(100% - 37px)" };

class UserTableContainer extends Component {
    constructor(props) {
        super(props);
        this.columnData = [
            {
                label: "Navn",
                renderCell: this.renderName,
                width: "20%"
            },
            {
                label: "Avdeling",
                type: "string",
                id: "departmentId",
                renderCell: this.renderDepartment,
                width: "15%"
            },
            {
                label: "Brukergruppe",
                type: "string",
                id: "userGroup",
                width: "15%",
                renderCell: this.renderUserGroup
            },
            {
                label: "Startdato",
                type: "string",
                id: "startDate",
                renderCell: this.renderStartDate,
                width: "15%"
            },
            {
                label: "Stillings %",
                type: "string",
                id: "fullTimeEquivalent",
                width: "15%",
                renderCell: this.renderFullTimeEquivalent
            },
            {
                label: "Vis timer",
                type: "switch",
                id: "showInDashboard",
                onChange: this.handleShowInDashboardClick,
                width: "15%"
            },
            {
                label: "Overtid",
                type: "switch",
                id: "hasOverTime",
                onChange: this.handleHasOverTimeClick,
                width: "15%"
            }
        ];

        this.state = {
            open: false,
            initialValue: "",
            currentColumn: null,
            currentUser: null,
            search: "",
            selected: {},
            isOpen: ""
        };
        this.icons = [
            {
                component: <Menu onSelect={this.onSelect} options={options} />
            }
        ];
    }

    componentDidMount() {
        const {
            signedInUser,
            filters,
            displayType,
            fetchInitialUsers
        } = this.props;

        fetchInitialUsers(signedInUser, filters, displayType);
    }

    renderName = (name, item) => {
        return (
            <AvatarCell
                item={{
                    ...item,
                    nameLink: `/users/${this.props.company.id}/user/${item.id}`
                }}
            />
        );
    };
    renderDepartment = (departmentId, item, isScrolling) => {
        const { signedInUser, departments } = this.props;
        return (
            <CellPopupMenu
                isScrolling={isScrolling}
                locked={signedInUser.userGroup !== userGroups.ADMIN}
                items={_.map(departments, d => d.name)}
                value={_.findIndex(departments, d => d.id === departmentId)}
                onChange={index =>
                    this.props.updateUser(item.id, {
                        departmentId: departments[index].id
                    })
                }
            />
        );
    };
    renderUserGroup = (userGroup, item, isScrolling) => {
        const { signedInUser, userGroupList } = this.props;

        return (
            <CellPopupMenu
                isScrolling={isScrolling}
                locked={
                    signedInUser.userGroup !== userGroups.ADMIN ||
                    signedInUser.id === item.id
                }
                items={_.map(userGroupList, d => d.name)}
                value={_.findIndex(userGroupList, d => d.id === userGroup)}
                onChange={index =>
                    this.props.updateUser(item.id, {
                        userGroup: userGroupList[index].id
                    })
                }
            />
        );
    };

    renderStartDate = (startDate, item, isScrolling) => {
        return (
            <CellDatePicker
                isScrolling={isScrolling}
                date={moment(startDate)}
                onChange={date =>
                    this.props.updateUser(item.id, {
                        startDate: date
                    })
                }
            />
        );
    };

    renderFullTimeEquivalent = (fullTimeEquivalent, item, isScrolling) => {
        const ftp = getCurrentFullTimeEquivalent(fullTimeEquivalent);
        return (
            <CellInputField
                isScrolling={isScrolling}
                initialValue={ftp ? ftp.value : "Ikke satt"}
                onChange={() => true}
                disabled
            />
        );
    };

    onSelect = option => {
        if (option === options[0]) {
            this.setState({ isOpen: "department" });
        } else if (option === options[1]) {
            this.setState({ isOpen: "startDate" });
        }
    };

    resetState = () => {
        this.setState({
            open: false,
            currentColumn: null,
            currentUser: null,
            initialValue: ""
        });
    };

    handleShowInDashboardClick = clickedUser => {
        const { updateUser } = this.props;

        if (!clickedUser) return;

        const showInDashboard = !clickedUser.showInDashboard;

        updateUser(clickedUser.id, { showInDashboard });

        this.resetState();
    };

    handleHasOverTimeClick = clickedUser => {
        const { updateUser } = this.props;

        if (!clickedUser) return;

        const hasOverTime = !clickedUser.hasOverTime;

        updateUser(clickedUser.id, { hasOverTime });
    };

    handleCheckboxChange = (e, item) => {
        const prevValue = Boolean(this.props.selected[item.id]);
        const selected = { ...this.props.selected };
        selected[item.id] = !prevValue;

        this.props.setSelectedUsers(selected);
    };

    render() {
        const { users, selected } = this.props;

        return (
            <Table
                columns={this.columnData}
                data={users}
                style={tableStyles}
                initialOrder={{ direction: "asc", orderBy: "name" }}
                bodyStyle={tableBodyStyle}
                checkbox={{
                    onCheckboxChange: this.handleCheckboxChange,
                    selected: selected,
                    numSelected: getObjectTrueValuesCount(selected),
                    onSelectAll: newVal =>
                        this.props.setSelectedUsers(
                            newVal ? createTrueObjectFromIds(users) : {}
                        )
                }}
            />
        );
    }
}

export const mapStateToProps = (state, props) => {
    const filters = selectors.getUserFilters(state, props);
    return {
        users: selectors.getFilteredUserItems(state, props),
        departments: companySelectors.getCompanyDepartments(state, props),
        signedInUser: userSelectors.getSignedInUser(state, props),
        company: companySelectors.getCurrentCompany(state, props),
        filters: filters,
        selected: filters.selectedUsers,
        userGroupList: userSelectors.getUserGroupList()
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const companyId = ownProps.match.params.companyId;

    return {
        fetchInitialUsers: (signedInUser, filters, displayType) => {
            if (signedInUser.userGroup === userGroups.DEPARTMENT_LEADER) {
                filters.departmentId = signedInUser.departmentId;
            } else if (signedInUser.userGroup === userGroups.ADMIN) {
                filters.departmentId = null;
            } else {
                //Invalid usergroup to see this page
                return;
            }

            dispatch(fetchUsers(filters, companyId, displayType));
            dispatch(setShallowUserFilters(filters));
        },

        updateUser: (userId, payload) => {
            dispatch({
                type: REQUEST_UPDATE_USER,
                userId,
                companyId,
                payload
            });
        },
        updateFullTimeEquivalent: (userId, fteKey, value) => {
            dispatch({
                type: REQUEST_UPDATE_FULL_TIME_EQUIVALENT,
                userId: userId,
                key: fteKey,
                payload: {
                    value
                }
            });
        },

        updateMultipleUsers: (userIds, departmentId, payload) => {
            dispatch({
                type: REQUEST_UPDATE_MULT_USERS,
                userIds,
                companyId,
                departmentId,
                payload
            });
        },
        setSelectedUsers: selectedUsers => {
            dispatch({ type: SET_USER_FILTERS, payload: { selectedUsers } });
        }
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(UserTableContainer)
);
