import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 13.93 15">
            <path d="M13.61,2.46a1,1,0,0,0-.75-.32H11.79v-.8a1.32,1.32,0,0,0-.4-.95A1.29,1.29,0,0,0,10.45,0H9.91A1.33,1.33,0,0,0,8.57,1.34v.8H5.36v-.8A1.29,1.29,0,0,0,5,.39,1.28,1.28,0,0,0,4,0H3.48a1.28,1.28,0,0,0-.94.39,1.29,1.29,0,0,0-.4,1v.8H1.07a1,1,0,0,0-.75.32A1,1,0,0,0,0,3.21V13.93a1,1,0,0,0,.32.75,1,1,0,0,0,.75.32H12.86a1.09,1.09,0,0,0,1.07-1.07V3.21A1,1,0,0,0,13.61,2.46Zm-4-1.12a.27.27,0,0,1,.27-.27h.54a.27.27,0,0,1,.19.08.25.25,0,0,1,.07.19V3.75a.25.25,0,0,1-.07.19.27.27,0,0,1-.19.08H9.91a.27.27,0,0,1-.27-.27Zm-6.43,0a.27.27,0,0,1,.27-.27H4a.27.27,0,0,1,.27.27V3.75A.27.27,0,0,1,4,4H3.48a.27.27,0,0,1-.27-.27Zm9.65,12.59H1.07V5.36H12.86Z" />
        </SvgIcon>
    );
}
