import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { componentDescriptions } from "../../../../constants/strings";
import * as companySelectors from "../../../../selectors/company";
import { updateDisplaySettings } from "./actions";
import DisplaySettingsCard from "./DisplaySettingsCard";

const items = [
    {
        label: "Normaltid",
        id: "normalHours",
        desc: componentDescriptions.displaySettings.normalHours
    },
    {
        label: "Kundetid",
        id: "clientHours",
        desc: componentDescriptions.displaySettings.clientHours
    },
    {
        label: "Interntid",
        id: "internalHours",
        desc: componentDescriptions.displaySettings.internalHours
    },
    {
        label: "Ferie",
        id: "vacationHours",
        desc: componentDescriptions.displaySettings.vacationHours
    },
    {
        label: "Avspasering",
        id: "timeOffHours",
        desc: componentDescriptions.displaySettings.timeOffHours
    },
    {
        label: "Sykdom",
        id: "sickLeaveHours",
        desc: componentDescriptions.displaySettings.sickLeaveHours
    },
    {
        label: "Permisjon",
        id: "vacatedHours",
        desc: componentDescriptions.displaySettings.vacatedHours
    }
];

const mapStateToProps = (state, props) => {
    const company = companySelectors.getCurrentCompany(state, props);
    return {
        items,
        displaySettings: company.view,
        title: "Kategorier",
        companyId: company.id
    };
};

export default withRouter(
    connect(mapStateToProps, { updateDisplaySettings })(DisplaySettingsCard)
);
