import { Paper, Typography } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "../forms/CustomButton";
import LockIcon from "../icons/LockIcon";
import LogoWithText from "../icons/LogoWithText";
import MailIcon from "../icons/MailIcon";
import InputField from "../forms/text-fields/InputField";

const styles = theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.C_AW
    },
    paper: {
        padding: "40px",
        height: "400px",
        width: "300px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        ...theme.gridItemBorder
    },
    logoIcon: {
        display: "inline-block",
        height: "40px",
        width: "75px",
        userSelect: "none",
        flexShrink: 0
    },
    button: {
        width: "100%"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    formControl: {
        width: "100%",
        height: "80%"
    },
    item: {
        margin: "15px 0"
    },
    splitText: {
        display: "flex",
        alignItems: "center"
    },
    horizional: {
        display: "flex",
        alignItems: "center",
        margin: "0 1rem",
        justifyContent: "space-between"
    },
    bottomContainer: {
        display: "flex",
        alignItems: "center",
        margin: "10px 0",
        justifyContent: "center"
    },
    coloredText: {
        color: theme.palette.C_LB,
        marginLeft: "0.3rem"
    },
    unfocusedText: {
        color: theme.palette.C_DB60
    },
    checkbox: {
        width: "18px",
        marginRight: "10px"
    },
    header: {
        margin: "10px 0 5px 0"
    },
    label: {
        color: theme.palette.C_DB60
    },
    designedBy: {
        marginTop: "70px",
        marginLeft: "auto",
        marginRight: "auto",
        width: "200px" /* Need a specific value to work */,
        fontWeight: "normal",
        color: theme.palette.C_DB30,
        textAlign: "center"
    },
    inputIcon: {
        color: theme.palette.C_DB40,
        width: "15px",
        height: "15px"
    },
    progress: {
        top: 0,
        position: "absolute",
        left: 0,
        width: "100%"
    }
});
class Login extends Component {
    state = {
        windowHeight: window.innerHeight
    };

    componentDidMount() {
        window.addEventListener("resize", this.resize);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
    }

    resize = () => {
        this.setState({ windowHeight: window.innerHeight });
    };

    render() {
        const {
            classes,
            onSubmit,
            emailRef,
            passwordRef,
            isFetching,
            disabledConfirm
        } = this.props;

        return (
            <div
                className={classes.root}
                style={{ height: this.state.windowHeight }}
            >
                <div>
                    <Paper className={classes.paper} elevation={0}>
                        {isFetching && (
                            <LinearProgress className={classes.progress} />
                        )}
                        <div>
                            <LogoWithText className={classes.logoIcon} />
                            <div>
                                <Typography
                                    className={classes.header}
                                    variant="display2"
                                >
                                    {"Logg inn"}
                                </Typography>
                                <Typography
                                    variant="display1"
                                    className={classes.label}
                                >
                                    {"Bruk din 24SevenOffice konto"}
                                </Typography>
                            </div>
                        </div>
                        <form onSubmit={onSubmit} className={classes.form}>
                            <InputField
                                inputRef={emailRef}
                                autoComplete={"email"}
                                type={"email"}
                                className={classes.item}
                                rightIcon={
                                    <MailIcon className={classes.inputIcon} />
                                }
                                placeholder={"Epost i 24SevenOffice"}
                            />
                            <InputField
                                inputRef={passwordRef}
                                autoComplete={"password"}
                                type={"password"}
                                className={classes.item}
                                rightIcon={
                                    <LockIcon className={classes.inputIcon} />
                                }
                                placeholder={"Passord i 24SevenOffice"}
                            />

                            <Button
                                onClick={onSubmit}
                                className={classes.item}
                                browserType="submit"
                                type="confirm"
                                value="Logg inn"
                                disabled={disabledConfirm}
                            />
                            {
                                <div className={classes.bottomContainer}>
                                    <Typography
                                        variant="display1"
                                        className={classes.unfocusedText}
                                    >
                                        {"Har du ikke konto?"}{" "}
                                    </Typography>
                                    <Link to={"/register"}>
                                        <Typography
                                            variant="display1"
                                            className={classes.coloredText}
                                        >
                                            {"Registrer deg"}
                                        </Typography>
                                    </Link>
                                </div>
                            }
                        </form>
                    </Paper>
                    <Typography
                        variant="display1"
                        className={classes.designedBy}
                    >
                        {"Designed by DOTS in Oslo"}
                    </Typography>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(Login);
