import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Manager, Target, Popper } from "react-popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import { withStyles } from "@material-ui/core/styles";
import { map, isFunction } from "lodash";

const styles = theme => ({
    root: {
        display: "flex",
        position: "relative"
    },
    popperClose: {
        pointerEvents: "none"
    },
    popperOpen: {
        zIndex: 1000
    },
    paper: {
        ...theme.gridItemBorder,
        backgroundColor: "white",
        borderRadius: "4px",
        zIndex: 100000,
        boxShadow: "0px 4px 15px 0px rgba(34,34,34,0.1)",
        maxHeight: "400px",
        overflowY: "auto"
    },
    arrow: {
        marginTop: "3px",
        "&::before": {
            content: "''",
            display: "block",
            position: "absolute",
            width: 0,
            height: 0,
            borderStyle: "solid",
            borderWidth: "0 10px 10px 10px",
            borderColor: `transparent transparent ${theme.palette.C_DB10} transparent`,
            bottom: "100%",
            left: "calc(100% - 34px)"
        },
        "&::after": {
            content: "''",
            display: "block",
            position: "absolute",
            width: 0,
            height: 0,
            borderStyle: "solid",
            borderWidth: "0 10px 10px 10px",
            borderColor: "transparent transparent white transparent",
            bottom: "100%",
            left: "calc(100% - 34px)",
            marginBottom: "-1px"
        }
    },
    midArrow: {
        "&::before": {
            left: "calc(50% - 5px)"
        },
        "&::after": {
            left: "calc(50% - 5px)"
        }
    }
});

class Dropdown extends React.Component {
    handleToggle = () => {
        this.props.onToggle(!this.props.open);
    };

    handleClose = event => {
        if (this.target.contains(event.target)) {
            return;
        }
        this.props.onToggle(false);
    };

    render() {
        const {
            classes,
            className,
            target,
            targetRef,
            paperStyles,
            children,
            open,
            placement,
            arrow,
            popperClassName,
            targetClassName
        } = this.props;

        return (
            <div
                className={classNames(
                    classes.root,
                    {
                        [classes.popperOpen]: open
                    },
                    className
                )}
            >
                <Manager className={targetClassName}>
                    <Target>
                        <div
                            ref={node => {
                                this.target = node;
                                isFunction(targetRef) && targetRef(node);
                            }}
                        >
                            {React.cloneElement(target, {
                                onClick: this.handleToggle
                            })}
                        </div>
                    </Target>
                    <Popper
                        placement={placement}
                        eventsEnabled={open}
                        className={classNames(
                            {
                                [classes.popperClose]: !open,
                                [classes.arrow]: open && arrow,
                                [classes.midArrow]:
                                    open && arrow && placement === "bottom"
                            },
                            popperClassName
                        )}
                        modifiers={{ computeStyle: { gpuAcceleration: false } }}
                    >
                        <ClickAwayListener onClickAway={this.handleClose}>
                            <Grow
                                in={open}
                                id="menu-list-grow"
                                style={{ transformOrigin: "0 0 0" }}
                                timeout={0}
                            >
                                <div
                                    className={classNames(classes.paper)}
                                    style={paperStyles}
                                    data-placement={placement}
                                >
                                    {children}
                                </div>
                            </Grow>
                        </ClickAwayListener>
                    </Popper>
                </Manager>
            </div>
        );
    }
}

Dropdown.propTypes = {
    onToggle: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    target: PropTypes.node.isRequired,
    targetRef: PropTypes.func,
    paperStyles: PropTypes.object,
    placement: PropTypes.string,
    arrow: PropTypes.bool
};

Dropdown.defaultProps = {
    placement: "bottom",
    arrow: true
};

export default withStyles(styles)(Dropdown);
