//React
import classNames from "classnames";
//MaterialUI
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Tooltip from "../poppers/Tooltip";

const styles = theme => ({
    root: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "25px 0",
        color: theme.palette.C_DB40,
        "&:hover": {
            color: theme.palette.C_LB
        },
        alignItems: "center"
    },
    container: {
        position: "relative",
        width: "100%"
    },
    activeRoot: {
        color: theme.palette.C_LB
    },
    text: {
        color: "inherit"
    },
    activeText: {},
    colorLabel: {
        position: "absolute",
        height: "30px",
        width: "5px",
        borderTopRightRadius: "6px",
        borderBottomRightRadius: "6px",
        left: "0px",
        top: "20px",
        backgroundColor: theme.palette.C_LB
    }
});
class IconWrapper extends Component {
    componentDidMount() {
        if (this.props.active) {
            const position = this.divElement.getBoundingClientRect().top;
            this.props.positionHandler(position);
        }
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.active &&
            (this.props.active !== prevProps.active || this.props.newWindow)
        ) {
            const position = this.divElement.getBoundingClientRect().top;
            this.props.positionHandler(position);
        }
    }

    render() {
        const {
            classes,
            label,
            linkTo,
            children,
            className,
            active
        } = this.props;

        const combinedRootClasses = classNames({
            [classes.root]: true,
            [classes.activeRoot]: active,
            [className]: true
        });

        if (!linkTo) {
            return <div className={combinedRootClasses}>{children}</div>;
        }

        return (
            <div
                className={classes.container}
                ref={divElement => (this.divElement = divElement)}
            >
                {/* active ? <div className={classes.colorLabel} /> : null */}
                <Tooltip title={label} disabled={active}>
                    <Link className={combinedRootClasses} to={linkTo}>
                        {children}
                    </Link>
                </Tooltip>
            </div>
        );
    }
}
IconWrapper.propTypes = {
    classes: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    linkTo: PropTypes.string.isRequired,
    children: PropTypes.object.isRequired
};

export default withStyles(styles)(IconWrapper);
/*
//React
import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

//Utils
import { map, indexOf } from "lodash";
import classnames from "classnames";
//MaterialUI
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import { blueGrey, green } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";

//Icons
import DashboardIcon from "@material-ui/core-icons/Dashboard";
import PermIdentityIcon from "@material-ui/core-icons/PermIdentity";
import BusinessIcon from "@material-ui/core-icons/Business";

const sideBarWidth = "121px";

const styles = theme => ({
    root: {
        width: "100%",
        height: "3.2rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        margin: "1rem 0",
        color: theme.palette.C_DB80,
        "&:hover": {
            color: theme.palette.C_W
        },
        transition: "all 100ms",
        alignItems: "center"
    },
    activeRoot: {
        color: theme.palette.C_W
    },
    text: {
        color: "inherit"
    },
    activeText: {}
});
class IconWrapper extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        label: PropTypes.string.isRequired,
        linkTo: PropTypes.string.isRequired,
        children: PropTypes.object.isRequired
    };

    render() {
        const {
            classes,
            label,
            linkTo,
            children,
            className,
            active
        } = this.props;

        const combinedClasses = classnames({
            [classes.text]: true,
            [classes.activeText]: active
        });
        const combinedRootClasses = classnames({
            [classes.root]: true,
            [classes.activeRoot]: active,
            [className]: true
        });

        return (
            <Link className={combinedRootClasses} to={linkTo} title={label}>
                {children}
                <Typography variant="display1" className={combinedClasses}>
                    {label}
                </Typography>
            </Link>
        );
    }
}

export default compose(withStyles(styles))(IconWrapper);
*/
