

import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';
export default function (props) {
    return (
        <SvgIcon {...props} viewBox='0 0 15 15'>
            <path d='M10.77,9.7l4.05,4.06a.75.75,0,0,1-1.07,1.05l-4-4a6,6,0,1,1,1.06-1.06ZM6,10.53A4.52,4.52,0,1,0,1.5,6,4.51,4.51,0,0,0,6,10.53Z'
            />
        </SvgIcon>

    )
}
