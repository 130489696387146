import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../actionTypes";
import Notificator from "./Notificator";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import * as _ from "lodash";
import { updateCompleteStrings } from "../../../utils/strings";

const timeoutDuration = 6500;

class NotificatorController extends Component {
    _timeouts = [];

    /*componentDidMount() {
        // Uncomment for
        this.props.addStaleAction(actions.REQUEST_SET_INCOMING_BALANCE);
        let count = 0;
        const interval = setInterval(() => {
            this.props.addStaleAction(actions.REQUEST_SET_INCOMING_BALANCE);
            count += 1;
            if (count > 2) {
                clearInterval(interval);
            }
        }, 15000);
    }*/
    componentDidUpdate(prevProps) {
        const { confirmedActions } = this.props;
        // If an action has been added
        if (prevProps.confirmedActions.length < confirmedActions.length) {
            // Store the action just added
            const newAction = {
                ...confirmedActions[confirmedActions.length - 1]
            };
            // Set a timeout to remove the action after 3 seconds. This will become a memory leak, however actions are very rare and its therefore a noissue.
            this._timeouts.push(
                setTimeout(() => {
                    // Make sure the action is not removed
                    const currentAction = _.find(
                        confirmedActions,
                        action => action.createdAt === newAction.createdAt
                    );
                    if (currentAction) {
                        this.props.removeStaleAction(currentAction);
                    }
                }, timeoutDuration)
            );
        }
    }
    componentWillUnmount() {
        _.forEach(this._timeouts, t => {
            clearTimeout(t);
        });
    }
    render() {
        const { removeStaleAction, confirmedActions } = this.props;

        const notificatorView = _.map(confirmedActions, (type, i) => {
            return (
                <CSSTransition
                    key={type.createdAt}
                    classNames="notificator"
                    timeout={{ enter: 300, exit: 300 }}
                >
                    <Notificator
                        description={updateCompleteStrings[type.subType]}
                        onClose={() => removeStaleAction(type)}
                        index={i}
                    />
                </CSSTransition>
            );
        });
        return (
            <TransitionGroup
                style={{ display: "flex", justifyContent: "center" }}
            >
                {notificatorView}
            </TransitionGroup>
        );
    }
}

const mapStateToProps = state => {
    return { confirmedActions: state.appState.confirmedActions };
};

const mapDispatchToProps = dispatch => ({
    removeStaleAction: action => {
        dispatch({
            type: actions.REMOVE_CONFIRM_ACTION,
            payload: action
        });
    },
    addStaleAction: subType => {
        dispatch({
            type: actions.SET_CONFIRM_ACTION,
            payload: {
                subType,
                createdAt: Date.now() // In order to uniqly identify actions
            }
        });
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificatorController);
