import * as actions from "../actionTypes";
import * as _ from "lodash";
import * as immutable from "lodash/fp";
export const timebankInitialState = {
    meta: {
        currentDate: Date.now(),
        searchString: "",
        currentOpenUser: {
            month: null,
            key: null,
            year: null
        },
        currentDepartment: null,
        currentTab: "TABLE"
    },
    monthly: {},
    daily: {},
    yearly: {},
    weekly: {},
    incomingBalance: {}
};

export default function timebank(state = timebankInitialState, action) {
    let date;
    switch (action.type) {
        case actions.SET_TIMEBANK_MONTHLY:
            date = new Date(action.date);
            return {
                ...state,
                monthly: {
                    ...state.monthly,
                    [date.getFullYear()]: {
                        [date.getMonth()]: { ...action.payload }
                    }
                }
            };
        case actions.SET_TIMEBANK_MONTHLY_BY_YEAR: {
            //Value is userData, key is month

            const newState = { ...state };
            let months = newState.monthly[action.year];
            if (!months) months = {};

            _.forEach(action.payload, (value, key) => {
                //Key is sent as 1-12, we use 0-11
                const month = parseInt(key, 10) - 1;
                months[month] = {
                    ...months[month],
                    users: {
                        ...(months[month] ? months[month]["users"] : {}),
                        [action.userId]: {
                            ...value
                        }
                    }
                };
            });
            return {
                ...state,
                monthly: {
                    ...state.monthly,
                    [action.year]: {
                        ...months
                    }
                }
            };
        }

        case actions.UPDATE_TIMEBANK_META:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    ...action.payload
                }
            };

        case actions.SET_USER_KEY_FIGURES:
            return {
                ...state,
                yearly: {
                    ...state.yearly,
                    [action.year]: {
                        ...state.yearly[action.year],
                        ...action.payload
                    }
                }
            };
        case actions.SET_INCOMING_BALANCE:
            return {
                ...state,
                incomingBalance: {
                    ...state.incomingBalance,
                    [action.year]: {
                        ...state.incomingBalance[action.year],
                        [action.userId]: {
                            ...action.payload
                        }
                    }
                }
            };
        case actions.SET_TIMEBANK_WEEKS:
            return immutable.set(
                `weekly.${action.userId}.${action.year}.${action.month}`,
                action.payload,
                state
            );

        case actions.SET_COMPANY_HOURS_DAILY:
            return immutable.set(
                `daily.${action.payload.year}`,
                action.payload.hours,
                state
            );

        case actions.SET_USER_HOURS_DAILY:
            return immutable.set(
                `daily.${action.payload.year}.${action.payload.userId}`,
                action.payload.days,
                state
            );

        default:
            return state;
    }
}
