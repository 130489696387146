import classNames from "classnames";
import jss from "jss";
import * as _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { colors } from "../../utils/styles";
import ExpandIcon from "../icons/ExpandIcon";
import TableCell from "./cells/TableCell";

const styles = {
    leftCellInner0: {
        paddingLeft: "22px",
        marginLeft: "16px",
        display: "flex",
        alignItems: "center",
        height: "102%",
        marginTop: "15px"
    },
    leftCellInner1: {
        paddingLeft: "22px",
        marginLeft: "40px",
        display: "flex",
        alignItems: "center",
        height: "100%",
        marginTop: "15px"
    },
    borderLeft: {
        borderLeft: `solid 1px ${colors.C_DB5}` //DB10
    },
    borderBottom: {
        borderBottom: `solid 1px ${colors.C_DB3}`
    },
    borderTop: {
        borderTop: `solid 1px ${colors.C_DB3}`
    },
    noBorderBottom: {
        borderBottom: "none"
    },
    leftCellContainer: {
        paddingBottom: 0,
        paddingTop: 0,
        paddingRight: 0
    },
    topRow: {
        backgroundColor: colors.C_DB3
    },

    expandIcon: {
        position: "absolute",
        marginLeft: "210px",
        marginTop: "20px"
    },
    iconOpen: {
        transform: "rotate(180deg)"
    },
    row: {
        display: "flex",
        justifyContent: "space-between"
    }
};

//Component uses original jss stylesheet to simplify withStyles for recursive component.
const { classes } = jss.createStyleSheet(styles).attach();

/**
 * This component works as a clickable tablerow.
 * It will call onRowClick with the current row data when clicked, and set itself open.
 * Otherwise it works as a regular table row component.
 * .
 * When the row has an item called tableChildren and its internal state is open,
 * it will render these children recursivly. The recursivly called tablerows
 * work in the same way, and will render tableChildren if the child also has children and has an open internal state.
 */
class ExpandableTableRow extends Component {
    state = {
        open: false,
        childrenOpen: {}
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            (prevState.open === false && this.state.open === true) ||
            (this.state.open &&
                prevProps.item.tableChildren.length !==
                    this.props.item.tableChildren.length) ||
            (prevState.open === true && this.state.open === false)
        ) {
            this.props.onHeightChange();
        }
    }
    renderRow = (
        item,
        index,
        isFinalRow,
        total,
        prevSiblingOpen,
        thisItemOpen,
        isLastRow
    ) => {
        const { columns, nested } = this.props;

        return _.map(columns, (column, i) => {
            if (column.type !== "numeric")
                // Remove icons etc
                column = {
                    ...column,
                    type: "string"
                };

            const isLeftCell = i === 0;

            const innerClasses = classNames({
                [classes.borderTop]: prevSiblingOpen && isLeftCell,
                [classes.borderBottom]:
                    (!isLastRow || thisItemOpen) && isLeftCell,
                [classes.borderLeft]: isLeftCell,
                [classes.leftCellInner0]: nested === 0 && isLeftCell,
                [classes.leftCellInner1]: nested === 1 && isLeftCell
            });

            const hasOuterBorderBottom =
                isLastRow &&
                this.props.isParentLastRow !== false &&
                !thisItemOpen;
            const outerClasses = classNames({
                [classes.borderBottom]: hasOuterBorderBottom || !isLeftCell,
                [classes.noBorderBottom]: !hasOuterBorderBottom && isLeftCell,
                [classes.leftCellContainer]: isLeftCell
            });
            // Only add border if there are no more recursive table rows

            return (
                <TableCell
                    key={column.id + item.id}
                    item={item}
                    column={column}
                    index={i}
                    itemIndex={i}
                    tot={columns.length}
                    footerCell={false}
                    className={innerClasses}
                    containerClassName={outerClasses}
                />
            );
        });
    };

    onRowClick = e => {
        // Don't handle click if not expandable

        // Only call onRowClick when item has been opened. Will only call for root row
        if (
            typeof this.props.onRowClick === "function" &&
            this.state.open === false
        ) {
            this.props.onRowClick(this.props.item);
        }
        if (typeof this.props.onRowToggle === "function") {
            this.props.onRowToggle();
        }
        this.setState({ open: !this.state.open });
    };

    /**
     * To keep track of whether a border should be shown on the last row,
     * we need to know if the child's row is open.
     */
    handleChildRowToggle = i => () => {
        const childrenOpen = { ...this.state.childrenOpen };
        childrenOpen[i] = childrenOpen[i] ? !childrenOpen[i] : true;

        this.setState({ childrenOpen });
    };

    renderExpandIcon = () => {
        const { open } = this.state;

        const combinedClasses = classNames(classes.expandIcon, {
            [classes.iconOpen]: open
        });
        return <ExpandIcon className={combinedClasses} />;
    };
    render() {
        const { open } = this.state;
        const {
            item,
            columns,
            nested,
            onRowClick,
            isFinalRow,
            style,
            siblingsOpen,
            onHeightChange,
            ...other
        } = this.props;

        const isClickable = _.isArray(item.tableChildren) || open;

        //Render regular if not open
        if (!open) {
            return (
                <div
                    {...other}
                    onClick={isClickable ? this.onRowClick : undefined}
                />
            );
        }

        const rowClassName = classNames(classes.row, {
            [classes.topRow]: nested === 0,
            [classes.clickableRow]: isClickable
        });
        const rows = [];

        rows.push(
            <div
                {...other}
                key={item.id}
                className={rowClassName}
                onClick={this.onRowClick}
            />
        );

        _.forEach(item.tableChildren, (childItem, i) => {
            const isFinalRow =
                this.props.isFinalRow || i === item.tableChildren.length - 1;

            const prevSiblingOpen = this.state.childrenOpen[i - 1] === true;
            const thisItemOpen = this.state.childrenOpen[i] === true;
            const isLastRow = i === item.tableChildren.length - 1;

            rows.push(
                <ExpandableTableRow
                    key={childItem.id}
                    columns={columns}
                    item={childItem}
                    nested={nested + 1}
                    onRowToggle={this.handleChildRowToggle(i)}
                    isParentLastRow={isLastRow}
                    onHeightChange={onHeightChange}
                    {...other}
                >
                    {this.renderRow(
                        childItem,
                        i,
                        isFinalRow,
                        item.tableChildren.length,
                        prevSiblingOpen,
                        thisItemOpen,
                        isLastRow
                    )}
                </ExpandableTableRow>
            );
        });

        return (
            <div style={style} className={classes.rowRoot}>
                {rows}
            </div>
        );
    }
}

ExpandableTableRow.propTypes = {
    hover: PropTypes.bool,
    className: PropTypes.string,
    tableChildren: PropTypes.array,
    columns: PropTypes.array,
    nested: PropTypes.number,
    onHeightChange: PropTypes.func
};

ExpandableTableRow.defaultProps = {
    nested: 0
};
export default ExpandableTableRow;
