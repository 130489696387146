import React, { Component } from "react";
import Select from "../forms/Select";
import PropTypes from "prop-types";
import { map } from "lodash";
import MenuItem from "@material-ui/core/MenuItem";
import SimpleDialog from "./SimpleDialog";
import DialogButtonGroup from "./dialogContent/DialogButtonGroup";
class PickerDialog extends Component {
    static propTypes = {
        initialValue: PropTypes.any,
        onCancel: PropTypes.func.isRequired,
        onConfirm: PropTypes.func.isRequired
    };
    state = {
        value: 0
    };

    componentDidMount() {
        if (this.props.initialValue) {
            this.setState({ value: this.props.initialValue });
        }
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.open === false && nextProps.open === true)
            this.setState({ value: nextProps.initialValue });
    }
    onChange = e => {
        this.setState({ value: e.target.value });
    };
    onCancel = e => {
        this.props.onCancel(e);
    };
    onConfirm = comment => {
        this.props.onConfirm(this.state.value, comment);
    };

    render() {
        const {
            classes,
            values,
            onConfirm,
            onCancel,
            SelectProps,
            actionButton,
            deleteButton,
            okButton,
            buttonType,
            ...other
        } = this.props;
        const { value } = this.state;

        const menuItems = map(values, (value, i) => {
            return (
                <MenuItem key={i} value={i}>
                    {value}
                </MenuItem>
            );
        });

        return (
            <SimpleDialog
                buttonGroup={
                    <DialogButtonGroup
                        type={buttonType}
                        onConfirm={this.onConfirm}
                        onCancel={this.onCancel}
                        actionButton={actionButton}
                        deleteButton={deleteButton}
                        okButton={okButton}
                    />
                }
                {...other}
            >
                <Select
                    value={parseInt(value, 10)}
                    onChange={this.onChange}
                    fullWidth
                    {...SelectProps}
                >
                    {menuItems}
                </Select>
            </SimpleDialog>
        );
    }
}

export default PickerDialog;
