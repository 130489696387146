import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import classnames from "classnames";
import { withStyles } from "@material-ui/core/styles";
function CommentIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 20 17.99">
            <path d="M0,12V2A2,2,0,0,1,2,0H18a2,2,0,0,1,2,2V17a1,1,0,0,1-1,1,1,1,0,0,1-.69-.29L14.58,14H2A2,2,0,0,1,0,12ZM18,2H2V12H15a1,1,0,0,1,.7.3L18,14.59Z" />
        </SvgIcon>
    );
}

const commentStyles = theme => ({
    active: {
        color: theme.palette.C_DB60
    },
    inactive: {
        color: theme.palette.C_DB15,
        "&:hover": {
            color: theme.palette.C_DB40
        },
        transition: "all 100ms"
    }
});

const CommentRaw = ({ classes, active }) => {
    const combinedClasses = classnames({
        [classes.active]: active,
        [classes.inactive]: !active
    });
    return <CommentIcon className={combinedClasses} />;
};

const CommentIconWithStyles = withStyles(commentStyles)(CommentRaw);

export default CommentIconWithStyles;
