import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import DatePickerBase from "./InlineDatePicker";
import Popup from "common/poppers/Popup";
import BorderIconButton from "../BorderIconButton";
import CalendarIcon from "../../icons/CalendarIcon";
import classNames from "classnames";
const styles = theme => ({
    input: {
        border: 0,
        font: "inherit",
        color: "currentColor",
        boxSizing: "content-box",
        verticalAlign: "middle",
        background: "none",
        fontSize: "14px",
        // Remove grey highlight
        WebkitTapHighlightColor: "transparent",
        display: "block",
        // Make the flex item shrink with Firefox
        minWidth: 0,
        padding: "0 10px",
        "&:focus": {
            outline: 0,
            borderColor: theme.palette.C_DB40
        },
        "&:active": {
            borderColor: theme.palette.C_DB40
        },
        backgroundColor: theme.palette.C_W
    },
    calendar: {
        fontSize: "13px"
    },
    day: {
        "&:hover": {
            backgroundColor: theme.palette.C_DB15
        }
    },
    arrow: {
        color: theme.palette.C_DB5
    },
    popup: {
        border: "none"
    },
    hide: {
        height: "0"
    }
});

class DatePicker extends Component {
    state = {
        open: false,
        hide: true
    };

    componentDidUpdate(prevProps, prevState) {
        if (!prevState.open && this.state.open) {
            this.showSlowly();
        }
    }
    showSlowly = () => {
        // Force update to stop weird initial render jump
        setTimeout(() => {
            this.setState({ hide: false });
        }, 0);
    };

    closePopup = () => {
        this.setState({ open: false, hide: true });
    };
    handleToggle = () => {
        if (this.state.open) {
            this.closePopup();
        } else {
            this.setState({ open: true });
        }
    };
    onChange = (...args) => {
        this.props.onChange(...args);
        if (this.props.closeOnSelect) {
            this.closePopup();
        }
    };
    render() {
        const {
            classes,
            target,
            flip,
            onChange,
            error,
            preventOverflow,
            ...other
        } = this.props;
        return (
            <Popup
                onClickAway={this.closePopup}
                open={this.state.open}
                arrow={false}
                arrowClassName={classes.arrow}
                className={classNames(classes.popup, {
                    [classes.hide]: this.state.hide
                })}
                placement={"bottom-start"}
                offset={4}
                flip={flip}
                preventOverflow={preventOverflow}
                target={
                    target ? (
                        React.cloneElement(target, {
                            onClick: this.handleToggle
                        })
                    ) : (
                        <BorderIconButton
                            largeIcon
                            variant={"travel"}
                            icon={<CalendarIcon />}
                            onClick={() =>
                                this.setState({ open: !this.state.open })
                            }
                            error={error}
                        />
                    )
                }
            >
                <DatePickerBase
                    fixedHeight
                    inline
                    onChange={this.onChange}
                    {...other}
                />
            </Popup>
        );
    }
}

DatePicker.propTypes = {
    target: PropTypes.node,
    closeOnSelect: PropTypes.bool,
    flip: PropTypes.bool
};

DatePicker.defaultProps = {
    closeOnSelect: false,
    flip: false
};
export default withStyles(styles)(DatePicker);
