import React from "react";
import FullTimeEquivalent from "./FullTimeEquivalent";
import UserLogContainer from "./UserLogContainer";
import Profile from "./Profile";
import Settings from "./Settings";
import Authenticated from "../../../common/auth/Authenticated";
import { userGroups } from "../../../actionTypes";
import Grid from "@material-ui/core/Grid";
import DefaultErrorBoundry from "../../../common/errors/DefaultErrorBoundry";
import UserDetailHeader from "./UserDetailsHeader";
import ContentPaper from "../../../components/layout/paper/ContentPaper";
import UserDetailsInfoBar from "./UserDetailsInfoBar";
import PaperScrollContainer from "../../../components/layout/paper/PaperScrollContainer";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import { getUser } from "../../../actions/user";
import * as userSelectors from "selectors/user";

const GeneralSettings = () => (
    <PaperScrollContainer>
        <Grid container spacing={24}>
            <Grid item xs={12} sm={6} md={6} lg={3}>
                <DefaultErrorBoundry>
                    <Profile />
                </DefaultErrorBoundry>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
                <DefaultErrorBoundry>
                    <Settings />
                </DefaultErrorBoundry>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
                <DefaultErrorBoundry>
                    <FullTimeEquivalent />
                </DefaultErrorBoundry>
            </Grid>
        </Grid>
    </PaperScrollContainer>
);
class Index extends React.Component {
    componentDidMount() {
        const { getUser, match } = this.props;
        getUser(match.params.userId);
    }

    componentWillUpdate(nextProps) {
        if (nextProps.match.params.userId !== this.props.match.params.userId) {
            this.props.getUser(nextProps.params.userId);
        }
    }

    render() {
        return (
            <Authenticated
                userGroups={[userGroups.ADMIN, userGroups.DEPARTMENT_LEADER]}
                fullPage
            >
                <UserDetailsInfoBar />
                <ContentPaper>
                    <UserDetailHeader />
                    {this.props.filters.selectedTab === 0 ? (
                        <GeneralSettings />
                    ) : (
                        <UserLogContainer />
                    )}
                </ContentPaper>
            </Authenticated>
        );
    }
}

const mapStateToProps = state => ({
    filters: userSelectors.getUserDetailsFilters(state)
});
export default withRouter(connect(mapStateToProps, { getUser })(Index));
