import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

const styles = {
    root: {
        display: "flex",
        flexDirection: "column",
        height: "500px",
        justifyContent: "space-around",
        alignItems: "center"
    },
    container: {
        height: "300px",
        width: "500px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center"
    },
    headWrap: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center"
    }
};
class NotFound extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <div className={classes.container}>
                    <div className={classes.headWrap}>
                        <Typography
                            variant="display4"
                            style={{ fontSize: "70px" }}
                            component={"span"}
                        >
                            404
                        </Typography>
                        <Typography
                            variant="body1"
                            component={"span"}
                            style={{ fontSize: "20px" }}
                        >
                            {
                                "Vi kunne dessverre ikke finne siden du leter etter"
                            }
                        </Typography>
                    </div>
                    <Typography variant="display2">
                        <Link to="/login">{"Tilbake til login"}</Link>
                    </Typography>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(NotFound);
