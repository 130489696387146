import * as actions from "../actionTypes";

export default function notifications(state = [], action) {
    switch (action.type) {
        case actions.SET_USER_NOTIFICATIONS:
            return action.payload;

        case actions.REFRESH_NOTIFICATIONS:
            return { ...state };
        default:
            return state;
    }
}
