import React, { Component } from "react";
import SimpleDialog from "../../common/dialogs/SimpleDialog";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as modalActions from "./actions";
import DialogButtonGroup from "../../common/dialogs/dialogContent/DialogButtonGroup";
import * as types from "../../actionTypes";

class ConfirmActionModal extends Component {
    render() {
        return (
            <SimpleDialog
                title={"Er du sikker?"}
                description={
                    this.props.modalProps.confirmationMessage ||
                    "Er du sikker på at du ønsker å utføre handlingen?"
                }
                open={true}
                onClose={this.props.onClose}
                buttonGroup={
                    <DialogButtonGroup
                        confirmButton={"Ja, sikker"}
                        onCancel={this.props.onClose}
                        onConfirm={this.props.onConfirm}
                    />
                }
            />
        );
    }
}

ConfirmActionModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    modalProps: PropTypes.object,
    onConfirm: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
    return {
        onConfirm: () => {
            dispatch({
                type: types.CONFIRM_DIALOG_YES
            });
            dispatch(modalActions.closeModal());
        },
        onClose: () => {
            dispatch({
                type: types.CONFIRM_DIALOG_NO
            });
            dispatch(modalActions.closeModal());
        }
    };
};
export default connect(null, mapDispatchToProps)(ConfirmActionModal);
