import classnames from "classnames";
import jss from "jss";
import * as _ from "lodash";
import Switch from "../../forms/Switch";
import React from "react";
import { colors, fontSizes } from "../../../utils/styles";
import AvatarCell from "./AvatarCell";
import NumericCell from "./NumericCell";
import camelCase from "jss-camel-case";
import jssNested from "jss-nested";

jss.use(camelCase(), jssNested());

const styles = {
    typography: {
        fontSize: fontSizes.F_N,
        fontWeight: "normal",
        color: colors.C_AB,
        height: "50px",
        borderBottom: `1px solid ${colors.C_DB3}`, //DB3
        display: "flex",
        alignItems: "center",
        "&:first-child": {
            paddingLeft: "20px"
        },
        "&:last-child": {
            paddingRight: "20px"
        },
        "&:last-child $numeric": {
            paddingRight: "10px"
        }
    },
    switch: {
        left: "-15px"
    },
    alignRight: {
        display: "flex",
        justifyContent: "flex-end",
        textAlign: "right"
    },

    switchContainer: {
        left: "-15px"
    },

    separator: {
        backgroundColor: colors.C_DB3,
        borderWidth: "0 1px 0 1px",
        borderColor: colors.C_DB8,
        borderStyle: "solid"
    },
    invertedSeparator: {
        backgroundColor: colors.C_DB3,
        borderWidth: "1px 0 1px 0",
        borderColor: colors.C_DB8,
        borderStyle: "solid"
    },
    footerStyle: {
        fontWeight: "bold",
        fontSize: fontSizes.F_S_N
    },

    buttonDefaults: {
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        // Remove grey highlight
        WebkitTapHighlightColor: "transparent",
        backgroundColor: "transparent", // Reset default value
        outline: "none",
        border: 0,
        margin: 0, // Remove the margin in Safari
        borderRadius: 0,
        padding: 0, // Remove the padding in Firefox
        cursor: "pointer",
        userSelect: "none",
        verticalAlign: "middle",
        "-moz-appearance": "none", // Reset
        "-webkit-appearance": "none", // Reset
        textDecoration: "none",
        // So we take precedent over the style of a native <a /> element.
        color: "inherit",
        "&::-moz-focus-inner": {
            borderStyle: "none" // Remove Firefox dotted outline.
        }
    },
    numeric: {
        textAlign: "right",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: "10px",
        boxSizing: "border-box"
    },
    numericPadding: {
        paddingRight: "10px"
    },

    bold: {
        fontWeight: "bold",
        fontSize: fontSizes.F_S_N
    },
    string: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    }
};

const { classes } = jss.createStyleSheet(styles).attach();

class TableCell extends React.Component {
    shouldComponentUpdate(nextProps) {
        return (
            nextProps.item[nextProps.column.id] !==
                this.props.item[this.props.column.id] ||
            nextProps.className !== this.props.className ||
            nextProps.containerClassName !== this.props.containerClassName ||
            typeof nextProps.column.renderCell === "function" ||
            nextProps.column.width !== this.props.column.width
        );
    }

    renderInnerCell = () => {
        const { item, column, className } = this.props;

        const textStyle = classnames(classes.string, className, {
            [classes.bold]: !!column.bold
        });

        switch (column.type) {
            case "string":
                return <p className={textStyle}>{item[column.id]}</p>;

            case "numeric": {
                return (
                    <NumericCell
                        value={item[column.id]}
                        affix={item.affix || column.affix}
                        bold={column.bold}
                        className={className}
                    />
                );
            }
            case "switch":
                return (
                    <div className={classes.switchContainer}>
                        <Switch
                            className={classes.switch}
                            checked={item[column.id] || false}
                            onChange={() => column.onChange(item, column.id)}
                            disableRipple
                        />
                    </div>
                );
            case "name":
                return <AvatarCell item={item.nameValue} />;
            case "icon":
                return item[column.id];

            default:
                return null;
        }
    };
    render() {
        const { item, column, containerClassName } = this.props;

        let innerCell = _.isFunction(column.renderCell)
            ? column.renderCell(item[column.id], item, this.props.isScrolling)
            : this.renderInnerCell();

        const classNames = classnames(
            classes.typography,
            {
                [classes.separator]: !!column.separator,
                [classes.invertedSeparator]: !!item.separator,
                [classes.icon]: column.type === "icon",
                [classes.alignRight]: column.alignRight === true,
                [classes.numeric]: column.type === "numeric"
            },
            containerClassName
        );

        return (
            <div
                className={classNames}
                style={{ width: column.width }}
                data-test-id="table-cell"
            >
                {innerCell}
            </div>
        );
    }
}

export default TableCell;
