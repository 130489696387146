import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    root: {
        height: "50px",
        padding: "0 14px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "230px"
    },
    icon: {
        width: "55px",
        height: "40px"
    }
});

class PopupHeader extends PureComponent {
    render() {
        const { classes, icon } = this.props;
        return (
            <div className={classes.root}>
                {React.cloneElement(icon, { className: classes.icon })}
            </div>
        );
    }
}

PopupHeader.propTypes = {};

export default withStyles(styles)(PopupHeader);
