import React, { Fragment, PureComponent } from "react";
import * as selectors from "./selectors";
import * as _ from "lodash";
import Card from "../../../components/layout/card/Card";
import CardHeader from "../../../components/layout/card/CardHeader";
import CardForm from "../../../components/layout/card/CardForm";
import SelectInput from "../../../common/forms/SelectInput";
import InputDatePicker from "../../../common/forms/date-picker/InputDatePicker";
import ButtonGroup from "common/forms/buttons/HiddenButtonGroup";
import { connect } from "react-redux";
import * as userSelectors from "selectors/user";
import Button from "common/forms/buttons/Button";
import { withRouter } from "react-router-dom";
import moment from "moment";
import * as companySelectors from "selectors/company";
import SwitchInput from "../../../common/forms/SwitchInput";
import { updateUser } from "actions/user";
import { userGroups } from "../../../actionTypes";
import {
    disabledReasons,
    componentDescriptions
} from "../../../constants/strings";
import CardDivider from "../../../components/layout/card/CardDivider";

export class Settings extends PureComponent {
    state = {
        settings: {
            departmentId: null,
            userGroup: null,
            startDate: moment(),
            endDate: null,
            showInDashboard: true
        },
        hasInitialStateReset: false
    };

    componentDidMount() {
        if (this.props.currentUser) {
            this.resetStateSettings();
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.currentUser !== this.props.currentUser) {
            this.resetStateSettings();
        }
    }

    resetStateSettings = () => {
        const user = this.props.currentUser;
        this.setState({
            settings: {
                departmentId: user.departmentId,
                userGroup: user.userGroup,
                startDate: moment(user.startDate),
                endDate: user.endDate ? moment(user.endDate) : user.endDate,
                hasOverTime: user.hasOverTime,
                showInDashboard: user.showInDashboard
            },
            hasInitialStateReset: true
        });
    };

    findUpdatedValues = () => {
        const { settings } = this.state;
        const { currentUser } = this.props;

        return {
            departmentId: settings.departmentId !== currentUser.departmentId,
            userGroup: settings.userGroup !== currentUser.userGroup,
            startDate: !settings.startDate.isSame(currentUser.startDate, "d"),
            endDate:
                settings.endDate !== null
                    ? !moment(settings.endDate).isSame(currentUser.endDate, "d")
                    : currentUser.endDate !== null,
            hasOverTime: settings.hasOverTime !== currentUser.hasOverTime,
            showInDashboard:
                settings.showInDashboard !== currentUser.showInDashboard
        };
    };
    hasInputChanged = () => {
        return _.values(this.findUpdatedValues()).indexOf(true) >= 0;
    };

    filterUnchangedValues = () => {
        const updatedValues = this.findUpdatedValues();
        const { settings } = this.state;
        const returnValue = {};
        _.forEach(updatedValues, (val, key) => {
            if (val === true) {
                returnValue[key] = settings[key];
            }
        });
        return returnValue;
    };
    mergeSettings = settings => {
        this.setState({ settings: { ...this.state.settings, ...settings } });
    };

    onDepartmentChange = index => {
        const { companyDepartments } = this.props;
        const newDepartment = companyDepartments[index];
        this.mergeSettings({ departmentId: newDepartment.id });
    };

    onUserGroupChange = index => {
        const { userGroupList } = this.props;
        const newUserGroup = userGroupList[index];
        this.mergeSettings({ userGroup: newUserGroup.id });
    };

    onDateChange = name => value => {
        this.mergeSettings({ [name]: value });
    };
    handleEndDateRemove = () => {
        this.mergeSettings({ endDate: null });
    };

    handleSwitchChange = e => {
        this.mergeSettings({
            [e.target.name]: !this.state.settings[e.target.name]
        });
    };

    onSubmit = e => {
        e.preventDefault();
        const update = this.filterUnchangedValues();
        this.props.updateUser(this.props.currentUser.id, update);
    };

    onCancel = e => {
        e.preventDefault();
        this.resetStateSettings();
    };

    render() {
        const { companyDepartments, userGroupList, signedInUser } = this.props;
        const { settings, hasInitialStateReset } = this.state;

        const canSubmit = this.hasInputChanged() && hasInitialStateReset;
        const desc = componentDescriptions.userDetails.settings;
        return (
            <Card>
                <CardHeader title={"Innstillinger"} />
                <CardForm>
                    <SelectInput
                        label={"Avdeling"}
                        options={_.map(companyDepartments, dep => dep.name)}
                        value={_.findIndex(
                            companyDepartments,
                            dep => dep.id === settings.departmentId
                        )}
                        onChange={this.onDepartmentChange}
                        fullWidth
                        locked={signedInUser.userGroup !== userGroups.ADMIN}
                        disabledReason={disabledReasons.UNAUTHORIZED}
                        description={desc.department}
                    />
                    <SelectInput
                        label={"Rettighetsnivå"}
                        options={_.map(userGroupList, group => group.name)}
                        value={_.findIndex(
                            userGroupList,
                            group => group.id === settings.userGroup
                        )}
                        fullWidth
                        onChange={this.onUserGroupChange}
                        locked={signedInUser.userGroup !== userGroups.ADMIN}
                        disabledReason={disabledReasons.UNAUTHORIZED}
                        description={desc.userGroup}
                    />
                    <InputDatePicker
                        label={"Startdato"}
                        date={settings.startDate}
                        onChange={this.onDateChange("startDate")}
                        name={"startDate"}
                        locked={signedInUser.userGroup === userGroups.USER}
                        disabledReason={disabledReasons.UNAUTHORIZED}
                        description={desc.startDate}
                        flip={false}
                    />
                    <InputDatePicker
                        label={"Sluttdato"}
                        date={settings.endDate}
                        onChange={this.onDateChange("endDate")}
                        placeholder={"(Ikke satt)"}
                        locked={signedInUser.userGroup === userGroups.USER}
                        disabledReason={disabledReasons.UNAUTHORIZED}
                        actionButton={
                            settings.endDate ? "Fjern sluttdato" : null
                        }
                        onActionButtonClick={this.handleEndDateRemove}
                        description={desc.endDate}
                        flip={false}
                    />
                    <SwitchInput
                        value={settings.hasOverTime === true}
                        onChange={this.handleSwitchChange}
                        label={"Overtid"}
                        name={"hasOverTime"}
                        disabled={signedInUser.userGroup === userGroups.USER}
                        disabledReason={disabledReasons.UNAUTHORIZED}
                        description={desc.overTime}
                    />
                    <CardDivider />
                    <SwitchInput
                        value={settings.showInDashboard}
                        onChange={this.handleSwitchChange}
                        label={"Vis timer"}
                        name={"showInDashboard"}
                        disabled={signedInUser.userGroup === userGroups.USER}
                        disabledReason={disabledReasons.UNAUTHORIZED}
                        description={desc.showInDashboard}
                    />

                    <ButtonGroup show={canSubmit}>
                        <Button variant={"secondary"} onClick={this.onCancel}>
                            {"Forkast"}
                        </Button>
                        <Button
                            variant={"primary"}
                            type={"submit"}
                            onClick={this.onSubmit}
                        >
                            {"Lagre"}
                        </Button>
                    </ButtonGroup>
                </CardForm>
            </Card>
        );
    }
}

export const mapStateToProps = (state, props) => {
    return {
        companyDepartments: companySelectors.getCompanyDepartments(
            state,
            props
        ),
        currentUser: userSelectors.getCurrentUser(state, props),
        userGroupList: userSelectors.getUserGroupList(state, props),
        signedInUser: userSelectors.getSignedInUser(state, props),
        isOwnPage: false
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        { updateUser }
    )(Settings)
);
