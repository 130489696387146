import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import SwitchBase from "@material-ui/core/internal/SwitchBase";

const styles = theme => ({
    /* Styles applied to the root element. */
    root: {
        display: "inline-flex",
        position: "relative",
        flexShrink: 0,
        // For correct alignment with the text.
        verticalAlign: "middle"
    },
    /* Styles used to create the `icon` passed to the internal `SwitchBase` component `icon` prop. */
    icon: {
        width: 8,
        height: 8,
        borderRadius: "1px",
        top: 4,
        left: 1,
        position: "relative",
        backgroundColor: theme.palette.C_DB15,
        opacity: 0
    },
    /* Styles applied the icon element component if `checked={true}`. */
    iconChecked: {
        backgroundColor: theme.palette.C_LB,
        opacity: 1
    },
    /* Styles applied to the internal `SwitchBase` component's `root` class. */
    switchBase: {
        zIndex: 1,
        color:
            theme.palette.type === "light"
                ? theme.palette.grey[50]
                : theme.palette.grey[400],
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        }),
        "&:hover": {
            "& + $bar": {},
            "& + $icon": {
                backgroundColor: theme.palette.C_DB30
            }
        }
    },
    /* Styles applied to the internal `SwitchBase` component's `checked` class. */
    checked: {},
    /* Styles applied to the internal SwitchBase component's root element if `color="primary"`. */
    colorChecked: {
        "&$checked": {
            "& + $bar": {}
        }
    },
    /* Styles applied to the internal SwitchBase component's root element if `color="secondary"`. */
    colorSecondary: {
        "&$checked": {
            color: theme.palette.secondary.main,
            "& + $bar": {
                backgroundColor: theme.palette.secondary.main
            }
        }
    },
    /* Styles applied to the internal SwitchBase component's disabled class. */
    disabled: {
        "& + $bar": {
            opacity: theme.palette.type === "light" ? 0.12 : 0.1
        },
        "& $icon": {
            boxShadow: theme.shadows[1]
        },
        "&$switchBase": {
            color:
                theme.palette.type === "light"
                    ? theme.palette.grey[400]
                    : theme.palette.grey[800],
            "& + $bar": {
                backgroundColor:
                    theme.palette.type === "light"
                        ? theme.palette.common.black
                        : theme.palette.common.white
            }
        }
    },
    /* Styles applied to the bar element. */
    bar: {
        borderRadius: "3px",
        display: "block",
        position: "absolute",
        width: 20,
        height: 20,
        top: "50%",
        left: "50%",
        marginTop: -7,
        marginLeft: -10,
        transition: theme.transitions.create(["opacity"], {
            duration: theme.transitions.duration.shortest
        }),
        backgroundColor: theme.palette.W,
        border: `solid 1px ${theme.palette.C_DB15}`
    }
});

class Radio extends PureComponent {
    render() {
        const { classes, className, ...other } = this.props;
        return (
            <span className={classNames(classes.root, className)}>
                <SwitchBase
                    disableRipple
                    icon={<span className={classes.icon} />}
                    classes={{
                        root: classNames(
                            classes.switchBase,
                            classes.colorChecked
                        ),
                        checked: classes.checked,
                        disabled: classes.disabled
                    }}
                    checkedIcon={
                        <span
                            className={classNames(
                                classes.icon,
                                classes.iconChecked
                            )}
                        />
                    }
                    {...other}
                />
                <span className={classes.bar} />
            </span>
        );
    }
}

Radio.propTypes = {};

export default withStyles(styles)(Radio);
