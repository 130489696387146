import React, { Component } from "react";
import { connect } from "react-redux";
import PaperHeader from "../../../components/layout/paper/PaperHeader";
import PaperHeaderTitle from "../../../components/layout/paper/PaperHeaderTitle";
import * as companySelectors from "../../../selectors/company";
import { withRouter } from "react-router-dom";
import Button from "common/forms/buttons/Button";
import * as modalActions from "containers/modals/actions";
import { componentDescriptions } from "../../../constants/strings";

export class DepartmentHeader extends Component {
    render() {
        return (
            <PaperHeader
                description={componentDescriptions.department}
                title={
                    <PaperHeaderTitle
                        primary={"Avdelinger"}
                        secondary={`i ${this.props.companyName}`}
                    />
                }
            >
                <Button
                    variant={"secondary"}
                    onClick={this.props.createDepartment}
                >
                    {"Legg til avdeling"}
                </Button>
            </PaperHeader>
        );
    }
}

const mapStateToProps = (state, props) => ({
    companyName: companySelectors.getCompanyName(state, props)
});

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        createDepartment: () => {
            dispatch(modalActions.createDepartment());
        }
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DepartmentHeader)
);
