import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { capitalize } from "@material-ui/core/utils/helpers";
import classNames from "classnames";

export const styles = theme => ({
    root: {
        display: "block",
        margin: 0,
        fontFamily: theme.typography.fontFamily,
        fontWeight: "inherit",
        color: theme.palette.C_DB120
    },
    alignLeft: { textAlign: "left" },
    alignCenter: { textAlign: "center" },
    alignRight: { textAlign: "right" },
    alignJustify: { textAlign: "justify" },
    noWrap: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    gutterBottom: { marginBottom: "0.35em" },
    paragraph: { marginBottom: theme.spacing.unit * 2 },
    colorInherit: { color: "inherit" },
    colorWhite: { color: theme.palette.C_W },
    colorWhiteFaded: { color: theme.palette.C_W_60 },
    colorDefault: { color: theme.palette.C_DB120 },
    colorFaded: { color: theme.palette.C_DB60 },
    colorSuperFaded: { color: theme.palette.C_DB40 },
    colorPositive: { color: theme.palette.C_LB },
    colorNegative: { color: theme.palette.C_R },
    sizeXxl: { fontSize: theme.typography.F_XXL },
    sizeSpecial: { fontSize: theme.typography.F_XL },
    sizeSpecialSmall: { fontSize: theme.typography.F_M },
    sizeSupreme: { fontSize: theme.typography.F_L },
    sizeLarge: { fontSize: theme.typography.F_N_M },
    sizeSmallMed: { fontSize: theme.typography.F_S_N },
    sizeMedium: { fontSize: theme.typography.F_N },
    sizeSmall: { fontSize: theme.typography.F_S },
    sizeMini: { fontSize: theme.typography.F_XS },
    bold: { fontWeight: "bold" },
    semibold: { fontWeight: 600 },
    uppercase: { textTransform: "uppercase" },
    singleLine: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    italic: { fontStyle: "italic" },
    underline: { textDecoration: "underline" },
    dynamicUnderline: {
        textDecoration: "underline",
        "&:hover": {
            textDecoration: "none"
        }
    },
    noSelect: { userSelect: "none" },
    description: {
        lineHeight: "150%"
    },
    clickable: {
        "&:hover": {
            color: theme.palette.C_LB
        }
    }
});

function Typography(props) {
    const {
        align,
        classes,
        size,
        bold,
        className: classNameProp,
        component: componentProp,
        color,
        gutterBottom,
        headlineMapping,
        noWrap,
        paragraph,
        variant,
        uppercase,
        singleLine,
        semibold,
        italic,
        rootRef,
        underline,
        dynamicUnderline,
        noSelect,
        description,
        clickable,
        ...other
    } = props;

    const className = classNames(
        classes.root,
        classes[variant],
        classes[`size${capitalize(size)}`],
        {
            [classes[`color${capitalize(color)}`]]: color !== "default",
            [classes.noWrap]: noWrap,
            [classes.gutterBottom]: gutterBottom,
            [classes.paragraph]: paragraph,
            [classes[`align${capitalize(align)}`]]: align !== "inherit",
            [classes.bold]: bold,
            [classes.uppercase]: uppercase,
            [classes.singleLine]: singleLine,
            [classes.semibold]: semibold,
            [classes.italic]: italic,
            [classes.underline]: underline,
            [classes.dynamicUnderline]: dynamicUnderline,
            [classes.noSelect]: noSelect,
            [classes.description]: description,
            [classes.clickable]: clickable
        },
        classNameProp
    );

    const Component =
        componentProp || (paragraph ? "p" : headlineMapping[size]) || "span";

    return <Component ref={rootRef} className={className} {...other} />;
}

Typography.propTypes = {
    /**
     * Set the text-align on the component.
     */
    align: PropTypes.oneOf(["inherit", "left", "center", "right", "justify"]),
    /**
     * The content of the component.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     * See [CSS API](#css-api) below for more details.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The color of the component. It supports those theme colors that make sense for this component.
     */
    color: PropTypes.oneOf([
        "inherit",
        "white",
        "whiteFaded",
        "default",
        "faded",
        "superFaded",
        "positive",
        "negative"
    ]),
    /**
     * The component used for the root node.
     * Either a string to use a DOM element or a component.
     * By default, it maps the variant to a good default headline component.
     */
    component: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.object
    ]),
    /**
     * If `true`, the text will have a bottom margin.
     */
    gutterBottom: PropTypes.bool,
    /**
     * We are empirically mapping the variant property to a range of different DOM element types.
     * For instance, h1 to h6. If you wish to change that mapping, you can provide your own.
     * Alternatively, you can use the `component` property.
     */
    headlineMapping: PropTypes.object,
    /**
     * If `true`, the text will not wrap, but instead will truncate with an ellipsis.
     */
    noWrap: PropTypes.bool,
    /**
     * If `true`, the text will have a bottom margin.
     */
    paragraph: PropTypes.bool,
    /**
     * Applies the theme typography styles.
     */
    size: PropTypes.oneOf([
        "xxl",
        "special",
        "specialSmall",
        "supreme",
        "large",
        "medium",
        "smallMed",
        "small",
        "mini"
    ]),
    bold: PropTypes.bool,
    uppercase: PropTypes.bool,
    singleLine: PropTypes.bool,
    semibold: PropTypes.bool,
    italic: PropTypes.bool,
    underline: PropTypes.bool,
    dynamicUnderline: PropTypes.bool,
    description: PropTypes.bool,
    clickable: PropTypes.bool
};

Typography.defaultProps = {
    align: "inherit",
    color: "default",
    gutterBottom: false,
    headlineMapping: {
        special: "h1",
        specialSmall: "h2",
        supreme: "h1",
        large: "h2",
        medium: "p",
        small: "p",
        mini: "p"
    },
    noWrap: false,
    paragraph: false,
    size: "medium",
    bold: false,
    uppercase: false,
    singleLine: false,
    semibold: false,
    italic: false,
    underline: false,
    dynamicUnderline: false,
    clickable: false
};

export default withStyles(styles)(Typography);
