import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function UsersIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 20 21.82">
            <path d="M10,10.91a5.25,5.25,0,0,0,3.86-1.6,5.26,5.26,0,0,0,1.59-3.86A5.25,5.25,0,0,0,13.86,1.6,5.25,5.25,0,0,0,10,0,5.25,5.25,0,0,0,6.14,1.6,5.25,5.25,0,0,0,4.55,5.45,5.26,5.26,0,0,0,6.14,9.31,5.25,5.25,0,0,0,10,10.91Z" />
            <path d="M20,16.67a13.46,13.46,0,0,0-.2-1.55,10.84,10.84,0,0,0-.38-1.54,7.08,7.08,0,0,0-.61-1.39A4.93,4.93,0,0,0,17.88,11a3.78,3.78,0,0,0-1.21-.76A4.28,4.28,0,0,0,15.08,10a1.68,1.68,0,0,0-.59.31L13.43,11a6.5,6.5,0,0,1-1.53.68,6.17,6.17,0,0,1-3.8,0A6.5,6.5,0,0,1,6.57,11l-1.06-.68a1.61,1.61,0,0,0-.6-.31,4.27,4.27,0,0,0-1.58.28A3.78,3.78,0,0,0,2.12,11a4.67,4.67,0,0,0-.88,1.15,7.08,7.08,0,0,0-.61,1.39,10.84,10.84,0,0,0-.38,1.54,13.46,13.46,0,0,0-.2,1.55c0,.48,0,1,0,1.47a3.54,3.54,0,0,0,1,2.69,3.82,3.82,0,0,0,2.75,1H16.21a3.82,3.82,0,0,0,2.75-1,3.54,3.54,0,0,0,1-2.69C20,17.64,20,17.15,20,16.67Z" />
        </SvgIcon>
    );
}
