import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import ButtonBase from "@material-ui/core/ButtonBase";
import * as _ from "lodash";
const styles = theme => ({
    root: {
        //
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position: "relative",
        textDecoration: "none",
        textAlign: "left",
        boxSizing: "content-box",
        width: "auto",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontSize: theme.typography.F_S,
        fontWeight: "600",
        color: theme.palette.C_DB120,
        height: "40px",
        padding: "0 14px",
        "&:hover, &:focus": {
            color: theme.palette.C_LB,
            "& $icon": {
                color: theme.palette.C_LB
            },
            backgroundColor: theme.palette.C_DB3
        },
        cursor: "pointer",

        "& $icon": {
            color: theme.palette.C_DB40
        }
    },
    selected: {
        color: theme.palette.C_LB
    },
    selectedMulti: {
        color: theme.palette.C_LB,
        backgroundColor: theme.palette.C_DB3
    },
    icon: {
        width: "10px",
        height: "10px"
    },
    iconSelected: {
        color: `${theme.palette.C_LB} !important`
    },
    small: {
        fontSize: theme.typography.F_S
    },
    highlighted: {
        color: theme.palette.C_R,
        "&:hover, &:focus": {
            color: theme.palette.C_R90,
            "& $icon": {
                color: theme.palette.C_R
            }
        }
    },
    typography: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        pointerEvents: "none" // The tag would sometimes be the target of the onClick event.
    }
});

class MenuItem extends PureComponent {
    render() {
        const {
            className,
            classes,
            selected,
            onClick,
            icon,
            small,
            children,
            multiselect,
            inFocus,
            highlighted,
            ...other
        } = this.props;

        return (
            <ButtonBase
                disableRipple
                component={"li"}
                tabIndex={-1}
                {...other}
                onClick={onClick}
                className={classNames(
                    classes.root,
                    {
                        [classes.selected]:
                            (selected && !multiselect) || inFocus,
                        [classes.selectedMulti]: selected && multiselect,
                        [classes.small]: small,
                        [classes.highlighted]: highlighted
                    },
                    className
                )}
            >
                {_.isString(children) ? (
                    <p className={classes.typography}>{children}</p>
                ) : (
                    children
                )}
                {icon
                    ? React.cloneElement(icon, {
                          className: classNames(
                              classes.icon,
                              { [classes.iconSelected]: selected },
                              icon.props.className
                          )
                      })
                    : null}
            </ButtonBase>
        );
    }
}

MenuItem.propTypes = {
    /**
     * A class name to customize the list item
     */
    className: PropTypes.string,
    /**
     * Whether the menu item is selected or not
     */
    selected: PropTypes.bool,

    /**
     * Whether the item should have highligted color on hover
     */
    highlighted: PropTypes.bool
};

MenuItem.defaultProps = {
    icon: null,
    selected: false,
    value: -1,
    multiselect: false,
    highlighted: false
};
export default withStyles(styles)(MenuItem);
