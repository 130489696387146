import { userGroups } from "../actionTypes";
import { createSelector } from "reselect";
import * as _ from "lodash";
export const getSignedInUser = state => state.auth.user;
export const getUserItems = state => state.users.items;
const userGroupList = [
    {
        name: "Ansatt",
        id: 1
    },
    { name: "Avdelingsleder", id: 2 },
    { name: "Administrator", id: 3 }
];
export const getUserGroupList = () => userGroupList;

/**
 * @description Gets the user attached to the current URL param
 */
export const getCurrentUser = (state, props) =>
    state.users.items[props.match.params.userId] || {};

export const getUserDetailsFilters = state => state.users.userDetailsFilters;

export const getOrderedUserItems = createSelector(getUserItems, users =>
    _.orderBy(users, "displayName")
);

export const getActiveUsers = createSelector(getOrderedUserItems, users =>
    _.filter(users, user => user.isActive)
);
export const getInactiveUsers = createSelector(getUserItems, users =>
    _.filter(users, user => !user.isActive)
);
export const getAdministrators = createSelector(getActiveUsers, users =>
    _.filter(users, user => user.userGroup === userGroups.ADMIN)
);

export const getDepartmentLeaders = createSelector(getActiveUsers, users =>
    _.filter(users, user => user.userGroup === userGroups.DEPARTMENT_LEADER)
);
export const getEditAuthorizedUsers = createSelector(
    getAdministrators,
    getDepartmentLeaders,
    (admins, depLeaders) => [...admins, ...depLeaders]
);

export const getEmployees = createSelector(getActiveUsers, users =>
    _.filter(users, user => user.userGroup === userGroups.USER)
);

export const getHasEditAuthorization = createSelector(
    getSignedInUser,
    user => user.userGroup !== userGroups.USER
);
export const getFullSignedInUser = createSelector(
    [getSignedInUser, getUserItems],
    (signedInUser, userItems) => userItems[signedInUser.id]
);
