import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Dropdown from "../dropdown/Dropdown";
import SelectButton from "./SelectButton";
import FormControl from "./FormControl";
const styles = theme => ({
    root: {
        display: "flex",
        zIndex: 10000,
        maxHeight: "500px"
    },
    popperClose: {
        pointerEvents: "none"
    },
    paper: {
        ...theme.gridItemBorder,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderTopStyle: "none",
        maxHeight: "100px",
        overflowY: "auto",
        backgroundColor: "white",
        borderRadius: "6px",
        zIndex: 100000,
        boxShadow: "0px 36px 80px -17px rgba(99,99,99,0.75)"
    },
    button: {
        justifyContent: "space-between",
        fontSize: theme.typography.F_N,
        backgroundColor: theme.palette.C_W,
        ...theme.gridItemBorder,
        borderWidth: "1px",
        borderRadius: "6px",
        height: "42px"
    },
    buttonOpen: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomStyle: "none"
    },
    caretIcon: {
        width: "10px",
        right: "15px",
        color: theme.palette.C_DB60,
        "pointer-events": "none", // Don't block pointer events on the select under the icon.
        marginLeft: "15px",
        marginTop: "1px"
    },
    menuTypo: {
        textOverflow: "ellipsis",
        display: "inline",
        whiteSpace: "nowrap",
        overflowX: "hidden",
        fontWeight: 600
    },
    fullWidth: {
        width: "100%"
    }
});

class Select extends React.Component {
    state = {
        open: false,
        menuWidth: null
    };

    componentDidMount() {
        const { clientWidth } = this.target;
        this.setState({
            menuWidth: clientWidth
        });
    }

    handleToggle = open => {
        if (open === true) {
            const { clientWidth } = this.target;
            this.setState({
                open: true,
                menuWidth: clientWidth
            });
        } else {
            this.setState({ open: false });
        }
    };

    onClick = child => event => {
        const value = child.props.value;
        let target;

        if (event.target) {
            target = event.target;
        }

        event.target = { ...target, value };
        this.props.onChange(event);
        this.setState({ open: false });
    };

    render() {
        const {
            classes,
            children,
            value,
            className,
            label,
            fullWidth
        } = this.props;
        const { open, menuWidth } = this.state;

        let displayValue = null;
        const menuItems = React.Children.map(children, child => {
            const selected = child.props.value === value;
            if (selected) displayValue = child.props.children;

            return React.cloneElement(child, {
                onClick: this.onClick(child),
                selected,
                value: undefined,
                "data-value": child.props.value
            });
        });

        const paperStyle =
            menuWidth !== null
                ? {
                      width: menuWidth,
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                      borderTopStyle: "none",
                      overflowY: "auto",
                      maxHeight: "210px"
                  }
                : {};

        return (
            <FormControl label={label}>
                <Dropdown
                    onToggle={this.handleToggle}
                    open={open}
                    targetRef={target => (this.target = target)}
                    paperStyles={paperStyle}
                    className={className}
                    arrow={false}
                    targetClassName={classNames({
                        [classes.fullWidth]: !!fullWidth
                    })}
                    target={
                        <SelectButton
                            className={classNames({
                                [classes.fullWidth]: !!fullWidth
                            })}
                        >
                            {displayValue}
                        </SelectButton>
                    }
                >
                    {menuItems}
                </Dropdown>
            </FormControl>
        );
    }
}

Select.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Select);
