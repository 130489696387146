import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
/** Modal Components */
import SignInModal from "./SignInModal";
import CreateDepartmentModal from "./CreateDepartmentModal";
import CreateKeyPerformanceIndicatorModal from "./CreateKeyPerformanceIndicatorModal";
import FullTimeEquivalentModal from "./FullTimeEquivalentModal";
import CalculationRuleModal from "./CalculationRuleModal";
import NotificationTargetGroupModal from "./NotificationTargetGroupModal";
import * as modalTypes from "./modalTypes";
import ConfirmActionModal from "./ConfirmActionModal";

const MODAL_COMPONENTS = {
    [modalTypes.SIGN_IN_MODAL]: SignInModal,
    [modalTypes.CREATE_DEPARTMENT_MODAL]: CreateDepartmentModal,
    [modalTypes.CREATE_KEY_PERFORMANCE_INDICATOR_MODAL]: CreateKeyPerformanceIndicatorModal,
    [modalTypes.CREATE_FULL_TIME_EQUIVALENT]: FullTimeEquivalentModal,
    [modalTypes.UPDATE_FULL_TIME_EQUIVALENT]: FullTimeEquivalentModal,
    [modalTypes.CREATE_CALCULATION_RULE]: CalculationRuleModal,
    [modalTypes.UPDATE_CALCULATION_RULE]: CalculationRuleModal,
    [modalTypes.CREATE_NOTIFICATION_TARGET_GROUP]: NotificationTargetGroupModal,
    [modalTypes.CONFIRM_DIALOG]: ConfirmActionModal
};

const ModalContainer = props => {
    if (!props.modalType) {
        return null;
    }
    const SpecificModal = MODAL_COMPONENTS[props.modalType];

    return <SpecificModal modalProps={props.modalProps} />;
};

ModalContainer.propTypes = {
    modalType: PropTypes.string,
    modalProps: PropTypes.shape({})
};

ModalContainer.defaultProps = {
    modalType: null,
    modalProps: {}
};

const mapStateToProps = state => {
    return {
        modalType: state.modal.modalType,
        modalProps: state.modal.modalProps
    };
};

export default connect(mapStateToProps)(ModalContainer);
