import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import classNames from "classnames";
import BorderIconButton from "components/buttons/BorderIconButton";
import AdditionIcon from "common/icons/AdditionIcon";
import SubtractionIcon from "common/icons/SubtractionIcon";
import MultiplyIcon from "common/icons/MultiplyIcon";
import DivisionIcon from "common/icons/DivisionIcon";
import ParanthesesOpenIcon from "common/icons/ParanthesesOpenIcon";
import ParanthesesCloseIcon from "common/icons/ParanthesesCloseIcon";
import BackspaceIcon from "common/icons/BackspaceIcon";
import RoundLabelContainer from "./RoundLabelContainer";
import RoundInputContainer from "./RoundInputContainer";
import { Droppable } from "react-beautiful-dnd";
import { colors } from "utils/styles";

const styles = theme => ({
    operators: {
        height: "40px",
        borderBottom: `1px solid ${theme.palette.C_DB10}`,
        boxSizing: "border-box",
        padding: "2px 10px",
        display: "flex",
        justifyContent: "start"
    },
    formula: {
        height: "100%",
        padding: "13px 20px",
        display: "flex",
        flexFlow: "column"
    },
    iconContainer: {
        left: "-2px",
        width: "13px",
        padding: "0px",
        height: "13px",
        position: "relative"
    },
    group: {
        minHeight: "32px",
        width: "90%",
        //border: `1px solid ${theme.palette.C_DB10}`,
        alignSelf: "flex-end",
        float: "right",
        borderRadius: "18px",
        display: "flex",
        flexFlow: "column",
        "&:before": {
            content: "'('"
        },
        "&:after": {
            content: "')'"
        }
    },
    activeGroup: {
        //border: `1px solid ${theme.palette.C_DB15}`
    },
    operatorLastChild: {
        borderBottomLeftRadius: "7px"
        //borderBottomRightRadius: "7px"
    },
    nestedGroup: {
        position: "relative",
        right: "-1px",
        top: "-1px"
    }
});

const operatorButtonStyle = {
    height: "35px",
    width: "35px",
    minWidth: "35px",
    minHeight: "35px",
    padding: 0,
    borderRadius: "6px",
    margin: "0px 1px"
};

const disabledOperatorButtonStyle = {
    ...operatorButtonStyle,
    color: colors.C_DB40,
    background: colors.C_DB5,
    border: `1px solid ${colors.C_DB10}`
};

const operatorIconStyle = {
    width: "8px",
    height: "8px",
    position: "relative",
    top: "-3px",
    left: "1px"
};

class KeyPerformanceIndicatorsFormula extends Component {
    constructor(props) {
        super(props);
    }

    findOperatorLabel = (operator, operators) => {
        if (operator.id === operators.ADDITION) {
            return {
                name: "+",
                id: operators.ADDITION,
                icon: <AdditionIcon style={operatorIconStyle} />
            };
        } else if (operator.id === operators.SUBTRACTION) {
            return {
                name: "-",
                id: operators.SUBTRACTION,
                icon: <SubtractionIcon style={operatorIconStyle} />
            };
        } else if (operator.id === operators.MULTIPLICATION) {
            return {
                name: "*",
                id: operators.MULTIPLICATION,
                icon: <MultiplyIcon style={operatorIconStyle} />
            };
        } else if (operator.id === operators.DIVISION) {
            return {
                name: "/",
                id: operators.DIVISION,
                icon: <DivisionIcon style={operatorIconStyle} />
            };
        }
    };

    mapDefinitionIdToLabels = (
        definition,
        types,
        standardVariables,
        operators
    ) =>
        _.map(definition, element => {
            if (element.type === types.STANDARD_VALUE) {
                return {
                    ..._.find(standardVariables, {
                        id: element.id
                    }),
                    type: types.STANDARD_VALUE
                };
            } else if (element.type === types.OPERATOR) {
                return {
                    ...this.findOperatorLabel(element, operators),
                    type: types.OPERATOR
                };
            } else if (element.type === types.GROUP) {
                // Recursive call if the type is a group / expression inside parentheses
                return {
                    definition: this.mapDefinitionIdToLabels(
                        element.definition,
                        types,
                        standardVariables,
                        operators
                    ),
                    type: types.GROUP,
                    id: element.id
                };
            } else if (element.type === types.CUSTOM_CONSTANT_VALUE) {
                return {
                    id: element.id,
                    value: element.value,
                    type: types.CUSTOM_CONSTANT_VALUE
                };
            }
        });

    handleAddOperator = id => {
        this.props.handleAddOperator(
            id,
            this.props.metaData.elementTypes.OPERATOR
        );
    };

    handleAdditionOperatorClick = e => {
        this.handleAddOperator(this.props.metaData.operators.ADDITION);
    };

    handleSubtractionOperatorClick = e => {
        this.handleAddOperator(this.props.metaData.operators.SUBTRACTION);
    };

    handleMultiplicationOperatorClick = e => {
        this.handleAddOperator(this.props.metaData.operators.MULTIPLICATION);
    };

    handleDivisionOperatorClick = e => {
        this.handleAddOperator(this.props.metaData.operators.DIVISION);
    };

    handleParenthesisOpenClick = e => {
        this.props.handleBeginGroup();
    };

    handleParenthesisCloseClick = e => {
        this.props.handleEndGroup();
    };

    handleBackspaceOperatorClick = e => {
        this.props.handleRemoveFromDefinition(1);
    };

    operators = [
        {
            id: "ADDITION",
            icon: <AdditionIcon />,
            onClick: this.handleAdditionOperatorClick
        },
        {
            id: "SUBTRACTION",
            icon: <SubtractionIcon />,
            onClick: this.handleSubtractionOperatorClick
        },
        {
            id: "MULTIPLICATION",
            icon: <MultiplyIcon />,
            onClick: this.handleMultiplicationOperatorClick
        },
        {
            id: "DIVISION",
            icon: <DivisionIcon />,
            onClick: this.handleDivisionOperatorClick
        }
    ];

    getGroupHeight = (children, types) => {
        if (children.length === 0) {
            return 34;
        }

        return _.reduce(
            children,
            (acc, child) => {
                if (child.type === types.OPERATOR) {
                    return 13 + acc;
                } else if (child.type === types.GROUP) {
                    return this.getGroupHeight(child.definition, types) + acc;
                } else {
                    return 34 + acc;
                }
            },
            0
        );
    };

    renderDefinition = (definition, types, inGroup, inActiveGroup) =>
        _.map(definition, ele => {
            if (ele.type === types.CUSTOM_CONSTANT_VALUE) {
                return (
                    <RoundInputContainer
                        value={ele.value}
                        id={ele.id}
                        handleUpdateConstant={this.props.handleUpdateConstant}
                    />
                );
            } else if (ele.type === types.GROUP) {
                const isActiveGroup = _.isEqual(
                    this.props.groupBeingEdited,
                    ele.id
                );
                const lastElementInGroup =
                    ele.definition[ele.definition.length - 1];
                const lastElementType = lastElementInGroup
                    ? lastElementInGroup.type
                    : null;

                const groupHeight = this.getGroupHeight(ele.definition, types);

                console.log(groupHeight);
                return (
                    <div
                        className={classNames(this.props.classes.group, {
                            [this.props.classes.activeGroup]: isActiveGroup,
                            [this.props.classes.operatorLastChild]:
                                lastElementType === types.OPERATOR,
                            [this.props.classes.nestedGroup]: inGroup
                        })}
                        style={
                            {
                                /*height: `calc(${groupHeight}px - ${
                                inGroup ? 0 : 2
                            }px)`*/
                            }
                        }
                    >
                        {this.renderDefinition(
                            ele.definition,
                            types,
                            true,
                            isActiveGroup
                        )}
                    </div>
                );
            } else
                return (
                    <RoundLabelContainer
                        label={ele.name}
                        isOperator={ele.type === types.OPERATOR}
                        inActiveGroup={inActiveGroup}
                        variableInGroup={inGroup && ele.type !== types.OPERATOR}
                        iconInGroup={inGroup && ele.type === types.OPERATOR}
                        icon={ele.icon}
                    />
                );
        });

    render() {
        const {
            classes,
            currentKPIDefinition,
            groupBeingEdited,
            metaData,
            disableOperators,
            disableVariables,
            disableCloseGroup,
            disableBackspace
        } = this.props;

        const definitionWithCorrectLabels = metaData.elementTypes
            ? this.mapDefinitionIdToLabels(
                  currentKPIDefinition,
                  metaData.elementTypes,
                  metaData.standardVariables,
                  metaData.operators
              )
            : [];

        return (
            <Fragment>
                <div className={classes.operators}>
                    {_.map(this.operators, operator => (
                        <div>
                            <BorderIconButton
                                icon={operator.icon}
                                onClick={operator.onClick}
                                variant={"travel"}
                                style={
                                    disableOperators
                                        ? disabledOperatorButtonStyle
                                        : operatorButtonStyle
                                }
                                disabled={disableOperators}
                            />
                        </div>
                    ))}
                    <BorderIconButton
                        icon={<ParanthesesOpenIcon />}
                        onClick={this.handleParenthesisOpenClick}
                        variant={"travel"}
                        style={
                            disableVariables
                                ? disabledOperatorButtonStyle
                                : operatorButtonStyle
                        }
                        disabled={disableVariables}
                    />
                    <BorderIconButton
                        icon={<ParanthesesCloseIcon />}
                        onClick={this.handleParenthesisCloseClick}
                        variant={"travel"}
                        style={
                            disableCloseGroup
                                ? disabledOperatorButtonStyle
                                : operatorButtonStyle
                        }
                        disabled={disableCloseGroup}
                    />
                    <BorderIconButton
                        icon={<BackspaceIcon />}
                        onClick={this.handleBackspaceOperatorClick}
                        variant={"travel"}
                        style={
                            disableBackspace
                                ? disabledOperatorButtonStyle
                                : operatorButtonStyle
                        }
                        disabled={disableBackspace}
                    />
                </div>

                <Droppable droppableId={"formula"}>
                    {(provided, snapshot) => (
                        <div
                            className={classes.formula}
                            ref={provided.innerRef}
                        >
                            {metaData.elementTypes
                                ? this.renderDefinition(
                                      definitionWithCorrectLabels,
                                      metaData.elementTypes,
                                      false,
                                      false
                                  )
                                : null}
                        </div>
                    )}
                </Droppable>
            </Fragment>
        );
    }
}

export default withStyles(styles)(KeyPerformanceIndicatorsFormula);
