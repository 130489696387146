import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "common/poppers/MenuItem";
import * as _ from "lodash";
import { colors } from "utils/styles";
import CellInputField from "../../../common/forms/text-fields/CellInputField";

const styles = theme => ({
    root: {}
});

class KPIMenuItem extends Component {
    handleOnClick = () => {
        this.props.onClick(this.props.id);
    };

    render() {
        return (
            <MenuItem
                onClick={this.handleOnClick}
                style={{
                    borderBottom: `1px solid ${colors.C_DB10}`,
                    height: "40px",
                    boxSizing: "border-box",
                    color: this.props.highlighted ? colors.C_LB : "",
                    backgroundColor: this.props.highlighted ? colors.C_DB3 : "",
                    fontSize: "14px",
                    padding: "0 20px",
                    overflow: "unset"
                }}
            >
                <CellInputField
                    onChange={name =>
                        this.props.updateKeyPerformanceIndicatorName({ name })
                    }
                    initialValue={this.props.name}
                />
            </MenuItem>
        );
    }
}

class KeyPerformanceIndicatorsList extends Component {
    constructor(props) {
        super(props);
    }

    renderKeyPerformanceIndicatorsItems = (items, id) =>
        _.map(items, kpi => (
            <KPIMenuItem
                onClick={this.props.onSelect}
                name={kpi.name}
                id={kpi.id}
                highlighted={kpi.id === id}
                updateKeyPerformanceIndicatorName={
                    this.props.updateKeyPerformanceIndicatorName
                }
            />
        ));

    render() {
        const {
            classes,
            keyPerformanceIndicators,
            selectedKeyPerformanceIndicatorId
        } = this.props;

        return (
            <Fragment>
                {this.renderKeyPerformanceIndicatorsItems(
                    keyPerformanceIndicators,
                    selectedKeyPerformanceIndicatorId
                )}
            </Fragment>
        );
    }
}

export default withStyles(styles)(KeyPerformanceIndicatorsList);
