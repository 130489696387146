import { withStyles } from "@material-ui/core";
import React, { PureComponent } from "react";
import * as _ from "lodash";
import BorderIconButton from "components/buttons/BorderIconButton";
import LeftPointIcon from "common/icons/LeftPointIcon";
import RightPointIcon from "common/icons/RightPointIcon";
import PropTypes from "prop-types";

const styles = theme => ({
    container: {
        display: "flex"
    },
    button: {
        boxShadow: "none",
        width: "100px",
        margin: "0 2px",
        "&:disabled": {
            backgroundColor: "inherit",
            border: `solid 1px ${theme.palette.C_DB15}`
        }
    }
});

class IndexPicker extends PureComponent {
    render() {
        const {
            classes,
            onCenterClick,
            onLeftClick,
            onRightClick,
            leftIcon,
            rightIcon,
            center,
            rightDisabled,
            leftDisabled,
            hideCenter
        } = this.props;

        return (
            <div className={classes.container}>
                <BorderIconButton
                    variant={"travel"}
                    onClick={onLeftClick}
                    icon={leftIcon}
                    disabled={leftDisabled}
                    style={hideCenter ? { marginRight: "2px" } : null}
                />
                {hideCenter ? null : _.isString(center) ? (
                    <BorderIconButton
                        onClick={onCenterClick}
                        variant="travel"
                        icon={null}
                        label={center}
                        className={classes.button}
                        disabled={!onCenterClick}
                    />
                ) : (
                    center
                )}
                <BorderIconButton
                    disabled={rightDisabled}
                    variant={"travel"}
                    onClick={onRightClick}
                    icon={rightIcon}
                />
            </div>
        );
    }
}

IndexPicker.propTypes = {
    /**
     * Function to be called when center button is clicked
     */
    onCenterClick: PropTypes.func,

    /**
     * Function to be called when left button is clicked
     */
    onLeftClick: PropTypes.func,

    /**
     * Function to be called when right button is clicked
     */
    onRightClick: PropTypes.func,

    leftIcon: PropTypes.node,
    rightIcon: PropTypes.node,
    center: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    leftDisabled: PropTypes.bool,
    rightDisabled: PropTypes.bool,
    hideCenter: PropTypes.bool
};

IndexPicker.defaultProps = {
    leftIcon: <LeftPointIcon />,
    rightIcon: <RightPointIcon />,
    center: "Today",
    leftDisabled: false,
    rightDisabled: false,
    hideCenter: false
};

export default withStyles(styles)(IndexPicker);
