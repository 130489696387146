import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 5.82 10">
            <circle cx="2.91" cy="9.09" r="0.91" />
            <path d="M2.91,0A2.92,2.92,0,0,0,0,2.91a.73.73,0,0,0,.73.73.73.73,0,0,0,.72-.73,1.46,1.46,0,1,1,2.91,0A1.45,1.45,0,0,1,2.91,4.36a.73.73,0,0,0-.73.73V6.91a.73.73,0,0,0,1.46,0V5.73A2.91,2.91,0,0,0,2.91,0Z" />{" "}
        </SvgIcon>
    );
}
