import React from "react";
import TimeGridBasis from "./TimeGridBasis";
import TimeGridAvatar from "./TimeGridAvatar";
import moment from "moment";

class SingleMonthTimeGrid extends React.Component {
    getDaysInMonth = currentDate => {
        return moment(currentDate).daysInMonth();
    };
    render() {
        const { data, currentDate } = this.props;
        return (
            <TimeGridBasis
                data={data}
                currentDate={currentDate}
                renderLeftColumnTitle={(item, isScrolling) => (
                    <TimeGridAvatar item={item} isScrolling={isScrolling} />
                )}
                leftColumnHeader={"Navn"}
                daysInMonth={this.getDaysInMonth(currentDate)}
                dataBasis={"monthly"}
            />
        );
    }
}

export default SingleMonthTimeGrid;
