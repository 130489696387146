import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getUserLogProps } from "./selectors";
import UserLog from "./UserLog";
import { REQUEST_USER_LOG } from "../../../actionTypes";

const mapDispatchToProps = (dispatch, ownProps) => {
    const { userId } = ownProps.match.params;
    return {
        fetchUserLogs: () => {
            dispatch({
                type: REQUEST_USER_LOG,
                payload: {
                    userId,
                    limit: 21
                }
            });
        }
    };
};
export default withRouter(
    connect(getUserLogProps, mapDispatchToProps)(UserLog)
);
