import React, { Component, Fragment } from "react";
import SimpleDialog from "common/dialogs/SimpleDialog";
import ButtonGroup from "common/dialogs/dialogContent/DialogButtonGroup";
import * as types from "../../actionTypes";
import InputField from "common/forms/text-fields/InputField";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as modalActions from "./actions";
import * as companySelectors from "../../selectors/company";
import { withRouter } from "react-router-dom";
import { MenuItem } from "@material-ui/core";
import Select from "../../common/forms/Select";
import { operatorsForKPI } from "../../actionTypes";
import { elementTypesForKPI } from "../../actionTypes";

class CreateKeyPerformanceIndicatorModal extends Component {
    state = {
        keyPerformanceIndicator: {
            name: "",
            definition: [
                {
                    id: "overtimeHours",
                    type: elementTypesForKPI.MONTH_VALUE
                },
                {
                    id: operatorsForKPI.ADDITION,
                    type: elementTypesForKPI.OPERATOR
                },
                {
                    id: "normalHours",
                    type: elementTypesForKPI.MONTH_VALUE
                }
            ]
        }
    };

    handleNameChange = e => {
        this.setState({
            keyPerformanceIndicator: {
                ...this.state.keyPerformanceIndicator,
                [e.target.name]: e.target.value
            }
        });
    };

    handleDefinitionChange = (value, index) => {
        const newDefinition = this.state.keyPerformanceIndicator.definition;
        newDefinition[index].id = value;

        this.setState({
            keyPerformanceIndicator: {
                ...this.state.keyPerformanceIndicator,
                definition: newDefinition
            }
        });
    };

    onConfirm = () => {
        this.props.createKeyPerformanceIndicator(
            this.props.companyId,
            this.state.keyPerformanceIndicator
        );
        this.props.onClose();
    };

    renderVariableSelector = (variableType, definitionIndex) => {
        const variableMenuItems = [
            <MenuItem value={"normalHours"} key={"normalHours"}>
                {"Normaltid"}
            </MenuItem>,
            <MenuItem value={"overtimeHours"} key={"overtimeHours"}>
                {"Overtid"}
            </MenuItem>
        ];

        return (
            <Select
                value={variableType}
                onChange={e =>
                    this.handleDefinitionChange(e.target.value, definitionIndex)
                }
                fullWidth
                label="Variabel"
            >
                {variableMenuItems}
            </Select>
        );
    };

    renderOperatorSelector = (operatorType, definitionIndex) => {
        const operatorMenuItems = [
            <MenuItem
                value={operatorsForKPI.ADDITION}
                key={operatorsForKPI.ADDITION}
            >
                {"+"}
            </MenuItem>,
            <MenuItem
                value={operatorsForKPI.SUBTRACTION}
                key={operatorsForKPI.SUBTRACTION}
            >
                {"-"}
            </MenuItem>,
            <MenuItem
                value={operatorsForKPI.MULTIPLICATION}
                key={operatorsForKPI.MULTIPLICATION}
            >
                {"*"}
            </MenuItem>,
            <MenuItem
                value={operatorsForKPI.DIVISION}
                key={operatorsForKPI.DIVISION}
            >
                {"/"}
            </MenuItem>
        ];

        return (
            <Select
                value={operatorType}
                onChange={e =>
                    this.handleDefinitionChange(e.target.value, definitionIndex)
                }
                fullWidth
                label="Operator"
            >
                {operatorMenuItems}
            </Select>
        );
    };

    renderKPIDefinitionBuilder = () => {
        const { definition } = this.state.keyPerformanceIndicator;

        return (
            <Fragment>
                {this.renderVariableSelector(definition[0].id, 0)}
                {this.renderOperatorSelector(definition[1].id, 1)}
                {this.renderVariableSelector(definition[2].id, 2)}
            </Fragment>
        );
    };

    render() {
        const { name } = this.state.keyPerformanceIndicator;
        return (
            <SimpleDialog
                {...this.props.modalProps}
                open={true}
                buttonGroup={
                    <ButtonGroup
                        onConfirm={this.onConfirm}
                        onCancel={this.props.onClose}
                    />
                }
            >
                <InputField
                    name={"name"}
                    label={"Name"}
                    onChange={this.handleNameChange}
                    value={name}
                    fullWidth
                />
                {this.renderKPIDefinitionBuilder()}
            </SimpleDialog>
        );
    }
}

CreateKeyPerformanceIndicatorModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    modalProps: PropTypes.object,
    createKeyFigure: PropTypes.func.isRequired
};
CreateKeyPerformanceIndicatorModal.defaultProps = {
    modalProps: {}
};

const mapStateToProps = (state, props) => ({
    companyId: companySelectors.getCompanyId(state, props)
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        createKeyPerformanceIndicator: (companyId, keyPerformanceIndicator) => {
            dispatch({
                type: types.REQUEST_CREATE_KEY_PERFORMANCE_INDICATOR,
                companyId,
                keyPerformanceIndicator
            });
            dispatch(modalActions.closeModal());
        },
        onClose: () => dispatch(modalActions.closeModal())
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
        CreateKeyPerformanceIndicatorModal
    )
);
