import React from "react";
import InputField from "../../common/forms/text-fields/InputField";
import GridIcon from "../../common/icons/GridIcon";
import ListIcon from "../../common/icons/ListIcon";
import SearchIcon from "../../common/icons/SearchIcon";
import Slider from "../../common/forms/Slider";
import PaperHeader from "../../components/layout/paper/PaperHeader";
import { componentDescriptions } from "../../constants/strings";
import PaperHeaderTitle from "../../components/layout/paper/PaperHeaderTitle";
import { connect } from "react-redux";
import * as selectors from "./selectors";
import moment from "moment";
import * as actionCreators from "./actions";
import * as companySelectors from "selectors/company";
import * as _ from "lodash";
import {
    createCSV,
    createXLSX,
    downloadCSV,
    formatTimebankCSV
} from "../../utils/exports";
import IconPopupMenu from "../../common/poppers/IconPopupMenu";
import { withRouter } from "react-router-dom";

const primaryTitle = isGrid => (isGrid ? "Timekontroll" : "Timerapport");
const gridSelectOptions = ["Eksporter til Excel"];
const tableSelectOptions = ["Eksporter til Excel", "Eksporter til CSV"];

class TimebankHeader extends React.Component {
    handleMenuChange = index => {
        if (this.props.meta.currentTab === "TABLE") {
            if (index === 0) {
                this.handleExportTable("xlsx");
            } else if (index === 1) {
                this.handleExportTable("csv");
            }
        } else {
            if (index === 0) {
                this.exportGridToXLSX();
            }
        }
    };
    formatGridData = () => {
        const { dailyTimebank, currentDate } = this.props;

        const headers = [{ label: "Navn", type: "string", id: "name" }];
        const daysInMonth = moment(currentDate).daysInMonth();
        for (let i = 1; i <= daysInMonth; i++) {
            headers.push({ label: i.toString(), type: "numeric", id: i });
        }
        const bodyContent = _.map(dailyTimebank, item => {
            const tableRow = {};
            const currentMoment = moment(currentDate);
            for (let i = 1; i <= daysInMonth; i++) {
                currentMoment.date(i);
                tableRow.name = `${item.firstName || ""} ${item.lastName ||
                    ""}`.trim();
                const currentDay = item.days[currentMoment.dayOfYear()];

                tableRow[i] =
                    currentDay && currentDay.registeredHours
                        ? _.round(currentDay.registeredHours, 2)
                        : 0;
            }
            return tableRow;
        });
        return [bodyContent, headers];
    };

    exportGridToXLSX = () => {
        const filename = this.generateFileName();

        const [formattedData, formattedCols] = this.formatGridData();
        createXLSX(formattedData, formattedCols, filename, true);
    };

    generateFileName = () => {
        const { meta, company } = this.props;
        const date = moment(meta.currentDate);

        let filename = date.format("MMM YYYY") + " " + company.name;
        return filename.split(" ").join("_");
    };

    handleExportTable = type => {
        const { timebank, onExportTable, columns } = this.props;

        const orderedData = _.orderBy(timebank, "name", "asc");

        const filename = this.generateFileName();

        if (type === "csv") {
            const [formattedData, formattedCols] = formatTimebankCSV(
                orderedData,
                columns
            );
            downloadCSV(createCSV(formattedData, formattedCols), filename);
        } else if (type === "xlsx") {
            const [formattedData, formattedCols] = formatTimebankCSV(
                orderedData,
                columns
            );
            createXLSX(formattedData, formattedCols, filename, false);
        }
        onExportTable(type);
    };

    render() {
        const { onChangeSearch, meta } = this.props;

        const isGrid = meta.currentTab === "GRID";
        const menuOptions = isGrid ? gridSelectOptions : tableSelectOptions;

        return (
            <PaperHeader
                description={
                    isGrid
                        ? componentDescriptions.timecontrol
                        : componentDescriptions.timereport
                }
                title={<PaperHeaderTitle primary={primaryTitle(isGrid)} />}
            >
                <InputField
                    leftIcon={<SearchIcon />}
                    value={meta.searchString}
                    onChange={e => onChangeSearch(e.target.value)}
                />
                <Slider
                    background={"paper"}
                    selectedTab={isGrid ? 1 : 0}
                    onSelectTab={this.props.setTab}
                    tabs={[<ListIcon />, <GridIcon />]}
                    variant={"icon"}
                />
                <IconPopupMenu
                    items={menuOptions}
                    onChange={this.handleMenuChange}
                    whiteBackground
                />
            </PaperHeader>
        );
    }
}
const mapStateToProps = (state, props) => {
    const { timebank, totals } = selectors.getComputedTableRows(state, props);

    return {
        meta: selectors.getTimebankMeta(state, props),
        columns: selectors.getFilteredColumnsByView(state, props),
        company: companySelectors.getCurrentCompany(state, props),
        timebank,
        totals,
        dailyTimebank: selectors.getDailyComputedTableRows(state, props)
    };
};

export default withRouter(
    connect(mapStateToProps, actionCreators)(TimebankHeader)
);
