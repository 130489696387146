import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { componentDescriptions } from "../../../../constants/strings";
import * as companySelectors from "../../../../selectors/company";
import { updateDisplaySettings } from "./actions";
import DisplaySettingsCard from "./DisplaySettingsCard";

const items = [
    {
        label: "Utbetalt kolonne",
        id: "withdrawnHours",
        desc: componentDescriptions.displaySettings.withdrawnHours
    },
    {
        label: "Utbetal timer knapp",
        id: "withdrawnHoursButton",
        desc: componentDescriptions.displaySettings.withdrawnHoursButton
    },
    {
        label: "Feriesaldo",
        id: "balanceVacationDays",
        desc: componentDescriptions.displaySettings.balanceVacationDays
    },
    {
        label: "Timesaldo",
        id: "balanceHours",
        desc: componentDescriptions.displaySettings.balanceHours
    }
];

const mapStateToProps = (state, props) => {
    const company = companySelectors.getCurrentCompany(state, props);
    return {
        items,
        displaySettings: company.view,
        title: "Saldoer",
        companyId: company.id
    };
};

export default withRouter(
    connect(mapStateToProps, { updateDisplaySettings })(DisplaySettingsCard)
);
