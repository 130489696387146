import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "./cells/TableCell";
import * as _ from "lodash";

const styles = theme => ({
    root: {
        display: "flex",
        justifyContent: "space-between"
    }
});
class TotalsFooter extends React.Component {
    render() {
        const { columns, row, style, classes } = this.props;
        if (!row) return null;
        const cells = [
            <TableCell
                bold
                key={"totals"}
                item={{ total: "Totalt" }}
                column={{
                    id: "total",
                    type: "string",
                    width: "12%",
                    bold: true
                }}
            />
        ];
        const colsWithoutFirst = columns.length > 0 ? columns.slice(1) : [];
        _.forEach(colsWithoutFirst, col => {
            cells.push(
                <TableCell
                    key={col.id}
                    item={row}
                    column={{ ...col, bold: true }}
                />
            );
        });

        return (
            <div style={style} className={classes.root}>
                {cells}
            </div>
        );
    }
}

export default withStyles(styles)(TotalsFooter);
