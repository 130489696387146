import React from "react";
import UserTimebankTable from "./UserTimebankTable";
import { UserInfoBar } from "../infobar/index";
import DefaultErrorBoundry from "../../../common/errors/DefaultErrorBoundry";
import ContentPaper from "../../../components/layout/paper/ContentPaper";
import Authenticated from "../../../common/auth/Authenticated";
import { userGroups } from "../../../actionTypes";
import UserTimebankHeader from "./UserTimebankHeader";

const UserTimebank = () => {
    return (
        <Authenticated
            userGroups={[
                userGroups.ADMIN,
                userGroups.DEPARTMENT_LEADER,
                userGroups.USER
            ]}
            fullPage
        >
            <DefaultErrorBoundry>
                <UserInfoBar />
            </DefaultErrorBoundry>
            <DefaultErrorBoundry>
                <ContentPaper>
                    <UserTimebankHeader />
                    <UserTimebankTable />
                </ContentPaper>
            </DefaultErrorBoundry>
        </Authenticated>
    );
};

export default UserTimebank;
