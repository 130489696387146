import { forEach } from "lodash";
import { all, call, put } from "redux-saga/effects";
import * as actions from "../actionTypes";
import * as CompaniesApi from "../api/companies";
import * as UserApi from "../api/users";
import { confirmationMessages } from "../constants/strings";
import {
    takeEveryAsync,
    takeFirstAsync,
    takeLastAsync,
    confirmMessageSaga,
    setConfirmAction
} from "./helpers";

//WORKERS

export function* getCompany(action) {
    const { companyId } = action.payload;

    const company = yield call(CompaniesApi.getCompany, companyId);

    yield put({ type: actions.SET_COMPANIES, payload: company });
}

export function* getCompanyHoursDaily(action) {
    const { companyId, month, year, departmentId } = action.payload;

    const hours = yield call(
        CompaniesApi.getCompanyHoursDaily,
        companyId,
        departmentId,
        year,
        month
    );
    yield put({
        type: actions.SET_COMPANY_HOURS_DAILY,
        payload: { hours, year }
    });
}

export function* updateCompanyRules(action) {
    const { companyId, ruleId, payload } = action;

    if (payload.hasWeekBasedCalculation !== undefined) {
        const confirmed = yield confirmMessageSaga(
            confirmationMessages.changeHasWeekBasedCalculation
        );
        if (!confirmed) return;
    }

    yield call(CompaniesApi.updateCompanyRules, companyId, ruleId, payload);

    yield put({
        type: actions.REQUEST_COMPANY,
        payload: { companyId: companyId }
    });

    yield setConfirmAction(actions.REQUEST_UPDATE_COMPANY_RULES);
}
export function* addDepartment(action) {
    const { companyId, department } = action.payload;

    yield call(CompaniesApi.addDepartment, companyId, department);
    yield put({ type: actions.REQUEST_COMPANY, payload: { companyId } });
}

export function* updateDepartment(action) {
    const { companyId, department, departmentId } = action.payload;

    yield call(
        CompaniesApi.updateDepartment,
        companyId,
        departmentId,
        department
    );
    yield put({ type: actions.REQUEST_COMPANY, payload: { companyId } });
}

export function* deleteDepartment(action) {
    const { companyId, departmentId } = action.payload;

    yield call(CompaniesApi.deleteCompanyDepartment, companyId, departmentId);
    yield put({ type: actions.REQUEST_COMPANY, payload: { companyId } });

    // Deleting a department may cause notification target group to change.
    yield put({
        type: actions.REQUEST_COMPANY_NOTIFICATION_TARGET_GROUPS,
        companyId
    });
}

export function* deleteDepartmentView(action) {
    const { companyId, departmentId } = action;
    yield call(CompaniesApi.deleteDepartmentView, companyId, departmentId);
    yield put({ type: actions.REQUEST_COMPANY, payload: { companyId } });
}

export function* updateWorktypeCategories(action) {
    const { selected, category, companyId } = action.payload;

    const newWorkTypes = {};

    forEach(selected, id => {
        newWorkTypes[id.toString()] = {
            category: parseInt(category)
        };
    });
    yield call(CompaniesApi.updateCompanyDetails, companyId, {
        workTypes: newWorkTypes
    });
    yield put({ type: actions.SET_SELECTED_WORKTYPES, payload: {} });
    yield put({ type: actions.REQUEST_COMPANY, payload: { companyId } });
}

export function* updateDisplaySettings(action) {
    const { companyId, departmentId, view } = action.payload;

    const payload = { view };

    if (departmentId !== undefined) {
        //Should update only department
        yield call(
            CompaniesApi.updateDepartment,
            companyId,
            departmentId,
            payload
        );
    } else {
        yield call(CompaniesApi.updateCompanyDetails, companyId, payload);
    }

    yield put({ type: actions.REQUEST_COMPANY, payload: { companyId } });
}

export function* accountVerificationFlow(action) {
    const { email, password } = action.payload;

    const verifyAccountResult = yield call(
        UserApi.verifyAccount,
        email,
        password
    );

    yield put({
        type: actions.SET_VERIFY_ACCOUNT_STATUS,
        payload: {
            status: actions.verifyAccountStatus.IS_VERIFIED.code,
            clients: verifyAccountResult.clients
        }
    });
}

export function* handleRequestedRegisterCompany(action) {
    const {
        email,
        password,
        companyId,
        normalHours,
        extraHours,
        overtimeAdditional,
        vacationDays,
        name,
        userId,
        hasWeekBasedCalculation
    } = action.payload;

    yield call(CompaniesApi.createCompany, {
        company: {
            companyId,
            normalHours,
            extraHours,
            overtimeAdditional,
            vacationDays,
            name,
            hasWeekBasedCalculation,
            owner: userId
        },
        email,
        password
    });

    yield put({
        type: actions.NAVIGATE,
        payload: { url: `/login?redirectTo=/tutorial/timeline` }
    });

    yield put({
        type: actions.REQUEST_LOG_IN,
        payload: { email, password }
    });
}

export function* runCalculations(action) {
    yield call(CompaniesApi.runCompanyCalculations, action.companyId);
}

export function* getNotificationTargetGroups(action) {
    const { companyId } = action;

    const result = yield call(
        CompaniesApi.getNotificationTargetGroups,
        companyId
    );

    yield put({
        type: actions.SET_NOTIFICATION_GROUPS,
        payload: result,
        companyId
    });
}

export function* createNotificationTargetGroup(action) {
    const { companyId, payload } = action;

    yield call(CompaniesApi.createNotificationTargetGroup, companyId, payload);

    yield put({
        type: actions.REQUEST_COMPANY_NOTIFICATION_TARGET_GROUPS,
        companyId
    });
}

export function* updateNotificationTargetGroup(action) {
    const { companyId, notificationTargetGroupId, payload } = action;

    yield call(
        CompaniesApi.updateNotificationTargetGroup,
        companyId,
        notificationTargetGroupId,
        payload
    );

    yield put({
        type: actions.REQUEST_COMPANY_NOTIFICATION_TARGET_GROUPS,
        companyId
    });
}

export function* deleteNotificationTargetGroup(action) {
    const { companyId, notificationTargetGroupId } = action;

    yield call(
        CompaniesApi.deleteNotificationTargetGroup,
        companyId,
        notificationTargetGroupId
    );

    yield put({
        type: actions.REQUEST_COMPANY_NOTIFICATION_TARGET_GROUPS,
        companyId
    });
}

export function* getCalculationRules(action) {
    const { companyId } = action;

    const result = yield call(CompaniesApi.getCalculationRules, companyId);

    yield put({
        type: actions.SET_CALCULATION_RULES,
        payload: result,
        companyId
    });
}

export function* createCalculationRule(action) {
    const { companyId, payload, departmentId } = action;

    if (departmentId !== undefined) {
        yield call(
            CompaniesApi.createDepartmentCalculationRule,
            companyId,
            departmentId,
            payload
        );
    } else {
        yield call(CompaniesApi.createCalculationRule, companyId, payload);
    }

    yield put({
        type: actions.REQUEST_CALCULATION_RULES,
        companyId
    });
}

export function* updateCalculationRule(action) {
    const { companyId, departmentId, calculationRuleId, payload } = action;

    if (departmentId !== undefined) {
        yield call(
            CompaniesApi.updateDepartmentCalculationRule,
            companyId,
            departmentId,
            calculationRuleId,
            payload
        );
    } else {
        yield call(
            CompaniesApi.updateCalculationRule,
            companyId,
            calculationRuleId,
            payload
        );
    }

    yield put({
        type: actions.REQUEST_CALCULATION_RULES,
        companyId
    });
}

export function* setCalculationRuleActive(action) {
    const { companyId, calculationRuleId, departmentId, payload } = action;

    try {
        yield call(
            CompaniesApi.setCalculationRuleActive,
            companyId,
            departmentId,
            calculationRuleId,
            payload
        );
    } catch (error) {
        // Listen for invalid state, if so create a custom display message.
        if (error.statusCode === "400/6") {
            // Invalid state
            error.displayMessage =
                "Regelen kan ikke settes aktiv fordi det eksisterer en overlappende regel. Denne regelen må fjernes før handlingen kan utføres.";
        }
        throw error;
    }

    yield put({ type: actions.REQUEST_CALCULATION_RULES, companyId });
}
export function* deleteCalculationRule(action) {
    const { companyId, departmentId, calculationRuleId } = action;

    if (departmentId !== undefined) {
        yield call(
            CompaniesApi.deleteDepartmentCalculationRule,
            companyId,
            departmentId,
            calculationRuleId
        );
    } else {
        yield call(
            CompaniesApi.deleteCalculationRule,
            companyId,
            calculationRuleId
        );
    }
    yield put({
        type: actions.REQUEST_CALCULATION_RULES,
        companyId
    });
}

export function* getKeyPerformanceIndicators(action) {
    const { companyId } = action;

    const result = yield call(
        CompaniesApi.getKeyPerformanceIndicators,
        companyId
    );

    yield put({
        type: actions.SET_KEY_PERFORMANCE_INDICATORS,
        payload: result
    });
}

export function* createKeyPerformanceIndicator(action) {
    const { companyId, payload } = action;

    yield put({
        type: actions.SET_NEW_KEY_PERFORMANCE_INDICATOR,
        payload
    });

    yield call(CompaniesApi.createKeyPerformanceIndicator, companyId, payload);

    yield put({
        type: actions.REQUEST_KEY_PERFORMANCE_INDICATORS,
        companyId
    });
}

export function* updateKeyPerformanceIndicator(action) {
    const { companyId, keyPerformanceIndicatorId, payload } = action;

    yield put({
        type: actions.UPDATE_KEY_PERFORMANCE_INDICATOR,
        payload: { keyPerformanceIndicatorId, payload }
    });

    yield call(
        CompaniesApi.updateKeyPerformanceIndicator,
        companyId,
        keyPerformanceIndicatorId,
        payload
    );

    yield put({
        type: actions.REQUEST_KEY_PERFORMANCE_INDICATORS,
        companyId
    });
}

export function* deleteKeyPerformanceIndicator(action) {
    const { companyId, keyPerformanceIndicatorId } = action;

    yield put({
        type: actions.DELETE_KEY_PERFORMANCE_INDICATOR,
        payload: { keyPerformanceIndicatorId }
    });

    yield call(
        CompaniesApi.deleteKeyPerformanceIndicator,
        companyId,
        keyPerformanceIndicatorId
    );

    yield put({
        type: actions.REQUEST_KEY_PERFORMANCE_INDICATORS,
        companyId
    });
}

export function* getKeyPerformanceIndicatorsMetaData(action) {
    const { companyId } = action;

    const result = yield call(
        CompaniesApi.getKeyPerformanceIndicatorsMetaData,
        companyId
    );

    yield put({
        type: actions.SET_KEY_PERFORMANCE_INDICATORS_META_DATA,
        payload: result
    });
}

//ROOT
export default function* rootSaga() {
    yield all([
        takeFirstAsync(actions.REQUEST_COMPANY, getCompany),
        takeFirstAsync(actions.REQUEST_CREATE_DEPARTMENT, addDepartment),
        takeFirstAsync(
            actions.UPDATE_WORKTYPE_CATEGORIES,
            updateWorktypeCategories
        ),

        takeEveryAsync(actions.UPDATE_DISPLAY_SETTINGS, updateDisplaySettings),
        takeFirstAsync(
            actions.REQUEST_ACCOUNT_VERIFICATION,
            accountVerificationFlow
        ),
        takeFirstAsync(
            actions.REQUEST_REGISTER_COMPANY,
            handleRequestedRegisterCompany
        ),
        takeFirstAsync(actions.REQUEST_UPDATE_DEPARTMENT, updateDepartment),
        takeFirstAsync(actions.REQUEST_DELETE_DEPARTMENT, deleteDepartment),
        takeFirstAsync(actions.REQUEST_COMPANY_CALC, runCalculations),
        takeFirstAsync(
            actions.REQUEST_COMPANY_NOTIFICATION_TARGET_GROUPS,
            getNotificationTargetGroups
        ),
        takeFirstAsync(
            actions.REQUEST_CREATE_NOTIFICATION_TARGET_GROUP,
            createNotificationTargetGroup
        ),
        takeFirstAsync(
            actions.REQUEST_UPDATE_NOTIFICATION_TARGET_GROUP,
            updateNotificationTargetGroup
        ),
        takeFirstAsync(
            actions.REQUEST_DELETE_NOTIFICATION_TARGET_GROUP,
            deleteNotificationTargetGroup
        ),
        takeFirstAsync(
            actions.REQUEST_CREATE_CALCULATION_RULE,
            createCalculationRule
        ),
        takeFirstAsync(
            actions.REQUEST_UPDATE_CALCULATION_RULE,
            updateCalculationRule
        ),
        takeFirstAsync(
            actions.REQUEST_DELETE_CALCULATION_RULE,
            deleteCalculationRule
        ),
        takeFirstAsync(actions.REQUEST_CALCULATION_RULES, getCalculationRules),
        takeFirstAsync(
            actions.REQUEST_DELETE_DEPARTMENT_VIEW,
            deleteDepartmentView
        ),
        takeFirstAsync(
            actions.REQUEST_UPDATE_CALCULATION_RULE_ACTIVE,
            setCalculationRuleActive
        ),
        takeFirstAsync(
            actions.REQUEST_COMPANY_HOURS_DAILY,
            getCompanyHoursDaily
        ),
        takeLastAsync(
            actions.REQUEST_UPDATE_COMPANY_RULES,
            updateCompanyRules,
            false
        ),
        takeFirstAsync(
            actions.REQUEST_KEY_PERFORMANCE_INDICATORS,
            getKeyPerformanceIndicators
        ),
        takeFirstAsync(
            actions.REQUEST_CREATE_KEY_PERFORMANCE_INDICATOR,
            createKeyPerformanceIndicator
        ),
        takeFirstAsync(
            actions.REQUEST_UPDATE_KEY_PERFORMANCE_INDICATOR,
            updateKeyPerformanceIndicator
        ),
        takeFirstAsync(
            actions.REQUEST_DELETE_KEY_PERFORMANCE_INDICATOR,
            deleteKeyPerformanceIndicator
        ),
        takeFirstAsync(
            actions.REQUEST_KEY_PERFORMANCE_INDICATORS_META_DATA,
            getKeyPerformanceIndicatorsMetaData
        )
    ]);
}
