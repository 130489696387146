import classNames from "classnames";
import { isFunction } from "lodash";
import ButtonBase from "@material-ui/core/ButtonBase";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import React, { PureComponent } from "react";
import { getColorFromId } from "../../utils/index";
import Avatar from "../../common/icons/Avatar";
import NotificationActiveIcon from "../../common/icons/NotificationActiveIcon";
import RemoveIcon from "../../common/icons/RemoveIcon";
import NotificationContent from "./NotificationContent";

const styles = theme => ({
    root: {
        width: "100%",
        padding: "13px 20px 13px 20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start"
    },
    container: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: theme.palette.C_LB10,
        minHeight: "50px",
        width: "100%",
        alignItems: "center"
    },
    hover: {
        "&:hover": {
            backgroundColor: theme.palette.C_LB15
        }
    },
    canceled: {
        backgroundColor: "white"
    },
    body: {
        display: "flex",
        alignItems: "center",
        textAlign: "left"
    },

    avatar: {
        marginRight: "10px"
    },
    markItemIcon: {
        width: "11px",
        height: "11px",
        color: theme.palette.C_R,
        margin: "10px 21px"
    },
    remove: {
        width: "9px",
        height: "9px"
    },
    markItemButton: {
        width: "20px",
        height: "20px",
        margin: "10px 16px"
    }
});

class NotificationActionItem extends PureComponent {
    state = {
        isHoveringMark: false
    };

    toggleIsHoveringMark = () => {
        this.setState({ isHoveringMark: !this.state.isHoveringMark });
    };
    render() {
        const {
            firstName,
            lastName,
            creator,
            description,
            amount,
            timestamp,
            isCancelled,
            onConfirm,
            onCancel,
            approvedByFirstName,
            approvedByLastName,
            approvedBy,
            classes
        } = this.props;
        const { isHoveringMark } = this.state;

        const isDisabled = !isFunction(onConfirm);

        return (
            <div
                className={classNames(classes.container, {
                    [classes.canceled]: isCancelled,
                    [classes.hover]: !isDisabled
                })}
            >
                <ButtonBase
                    disableRipple
                    className={classes.root}
                    disabled={isDisabled}
                    onClick={onConfirm}
                >
                    <div className={classes.body}>
                        <Avatar
                            firstName={firstName}
                            lastName={lastName}
                            color={getColorFromId(creator)}
                            extraSmall
                            className={classes.avatar}
                        />
                        <div>
                            <NotificationContent
                                firstName={firstName}
                                lastName={lastName}
                                amount={amount}
                                description={description}
                                date={timestamp}
                            />
                        </div>
                    </div>
                    {approvedByFirstName || approvedByLastName ? (
                        <Avatar
                            firstName={approvedByFirstName}
                            lastName={approvedByLastName}
                            color={getColorFromId(approvedBy)}
                            extraSmall
                        />
                    ) : null}
                </ButtonBase>
                {!isCancelled ? (
                    isHoveringMark ? (
                        <IconButton
                            onClick={onCancel}
                            title="Merk som lest"
                            className={classes.markItemButton}
                            onMouseLeave={this.toggleIsHoveringMark}
                            disableRipple
                        >
                            <RemoveIcon className={classes.remove} />
                        </IconButton>
                    ) : (
                        <NotificationActiveIcon
                            onMouseEnter={this.toggleIsHoveringMark}
                            className={classes.markItemIcon}
                        />
                    )
                ) : null}
            </div>
        );
    }
}
export default withStyles(styles)(NotificationActionItem);
