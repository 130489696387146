import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const cls1 = {
    fill: "#fff",
    stroke: "#e0e2e5",
    height: "21px"
};
const cls2 = {
    fill: "#e0e2e5"
};

export default function(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 992.25 31.45">
            <path
                style={cls1}
                d="M.5,31H991.75V4.7a4.2,4.2,0,0,0-4.2-4.2L4.83.63A4.2,4.2,0,0,0,.5,4.83Z"
            />
            <circle id="Oval" style={cls2} cx="49.85" cy="17.3" r="4.2" />
            <circle
                id="Oval-2"
                data-name="Oval"
                style={cls2}
                cx="35.15"
                cy="17.3"
                r="4.2"
            />
            <circle
                id="Oval-3"
                data-name="Oval"
                style={cls2}
                cx="20.45"
                cy="17.3"
                r="4.2"
            />
        </SvgIcon>
    );
}
