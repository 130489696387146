import * as _ from "lodash";
export const findInvalidTargetGroups = (
    existingTargetGroups,
    newDepartmentId,
    currentTarget
) => {
    const overlapping = _.find(existingTargetGroups, targetGroup => {
        //
        if (
            newDepartmentId !== targetGroup.departmentId &&
            targetGroup.departmentId !== null
        ) {
            return false;
        }

        return targetGroup.targets.indexOf(currentTarget) >= 0;
    });

    return overlapping;
};

// Finds targets in targets who will overridden by all departments
export const findOverriddenTargetGroupsTargets = (
    existingTargetGroups,
    newTargetGroup
) => {
    const overridden = [];

    // Only all departments can override others
    if (newTargetGroup.departmentId !== null) {
        return overridden;
    }

    _.forEach(existingTargetGroups, targetGroup => {
        const newTargets = _.filter(targetGroup.targets, target => {
            return newTargetGroup.targets.indexOf(target) < 0;
        });
        if (newTargets.length !== targetGroup.targets.length) {
            overridden.push({
                notificationTargetGroupId: targetGroup.key,
                targets: newTargets
            });
        }
    });
    return overridden;
};

// CALCULATION RULE VALIDATION

/**
 * Finds all rules which should be checked for overlapping when creating a new rules
 *
 * @param {Array<object>} existingCalculationRules The rules existing for this company
 * @param {string | null | undefined} departmentId The department for the new rule. If null or undefined, it is regarded as a company rule
 */
export const findRelevantCalculationRules = (
    existingCalculationRules,
    departmentId
) => {
    // If the rule to be created is for the whole company, all rules count.
    if (departmentId === undefined || departmentId === null) {
        return existingCalculationRules;
    }

    return _.filter(existingCalculationRules, calculationRule => {
        if (
            calculationRule.departmentId === null ||
            calculationRule.departmentId === undefined
        ) {
            // It is a company rule, and should count if the department is not ignored

            return (
                !_.isObject(calculationRule.ignoredDepartments) ||
                calculationRule.ignoredDepartments[departmentId] !== true
            );
        }
        return calculationRule.departmentId === departmentId; // Only include rules if its for the same department
    });
};
