import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "../../common/Typography";
import classnames from "classnames";
import Button from "common/forms/buttons/Button";
import PropTypes from "prop-types";

const styles = theme => ({
    root: {
        display: "flex",
        height: "40px",
        borderRadius: "6px",
        minWidth: "40px",
        width: "auto"
    },
    fullWidth: {
        width: "100%"
    },
    travel: {
        boxShadow: "none",
        "&:hover, &:focus": {
            boxShadow: "none"
        }
    },

    icon: {
        width: "11px",
        height: "11px",
        color: "inherit"
    },
    largeIcon: {
        width: "13px",
        height: "13px"
    },
    label: {
        marginLeft: "4px"
    },
    iconOnly: {
        width: "40px",
        height: "40px"
    }
});

class BorderIconButton extends Component {
    render() {
        const {
            classes,
            className,
            icon,
            label,
            iconClassName,
            labelClassName,
            largeIcon,
            variant,
            fullWidth,
            ...other
        } = this.props;

        const cominedRootClasses = classnames(
            classes.root,
            {
                [classes.travel]: variant === "travel",
                [classes.iconOnly]: !label,
                [classes.fullWidth]: Boolean(fullWidth)
            },
            className
        );

        return (
            <Button
                variant={"action"}
                className={cominedRootClasses}
                {...other}
            >
                {icon
                    ? React.cloneElement(icon, {
                          className: classnames(
                              classes.icon,
                              { [classes.largeIcon]: largeIcon },
                              iconClassName,
                              icon.props.className
                          )
                      })
                    : null}
                {label ? (
                    <Typography
                        semibold
                        color={"inherit"}
                        className={classnames(
                            {
                                [classes.label]: Boolean(icon)
                            },
                            labelClassName
                        )}
                        size={"smallMed"}
                    >
                        {label}
                    </Typography>
                ) : null}
            </Button>
        );
    }
}

BorderIconButton.propTypes = {
    /**
     * If travel, will remove all shadows
     */
    variant: PropTypes.oneOf(["action", "travel"]),

    /**
     * The icon to show
     */
    icon: PropTypes.node,

    /**
     * The label to show aside the icon
     */
    label: PropTypes.string,
    largeIcon: PropTypes.bool
};
BorderIconButton.defaultProps = {
    variant: "action",
    largeIcon: false
};

export default withStyles(styles)(BorderIconButton);
