import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "common/Typography";

const styles = theme => ({
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 20px",
        height: "50px"
    },

    titleRoot: {
        display: "flex",
        alignItems: "center",
        marginBottom: "4px"
    },
    title: {
        marginRight: "5px"
    },
    count: {
        color: theme.palette.C_DB60
    }
});

class PeopleCount extends PureComponent {
    render() {
        const { count, label, classes } = this.props;
        return (
            <div className={classes.titleRoot}>
                <Typography size={"small"} className={classes.title}>
                    {label}
                </Typography>
                <Typography
                    size={"small"}
                    color={"faded"}
                >{`(${count})`}</Typography>
            </div>
        );
    }
}

PeopleCount.propTypes = {};

export default withStyles(styles)(PeopleCount);
