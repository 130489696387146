import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import { getFullNameInitials } from "../../utils";
import DefaultAvatar from "@material-ui/core/Avatar";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import PropTypes from "prop-types";
const styles = theme => ({
    avatar: {
        height: "35px",
        width: "35px"
    },
    extraSmall: {
        height: "25px",
        width: "25px"
    },
    largeAvatar: {
        height: "60px",
        width: "60px"
    },
    avatarText: {
        color: theme.palette.C_AW
    },
    avatarColor: {
        backgroundColor: theme.palette.C_LB
    },
    extraSmallText: {
        fontSize: "11px",
        marginTop: "1px"
    },
    smallText: {
        fontSize: "13px"
    },
    largeText: {
        fontSize: "19px"
    }
});

const createTooltip = (firstName, lastName) => {
    if (!firstName && !lastName) {
        return "Bruker utilgjengelig";
    }
    if (!firstName) {
        return lastName;
    }
    if (!lastName) {
        return firstName;
    }
    return `${firstName} ${lastName}`.trim();
};

class Avatar extends Component {
    shouldComponentUpdate(nextProps) {
        return (
            nextProps.firstName !== this.props.firstName ||
            nextProps.lastName !== this.props.lastName ||
            nextProps.color !== this.props.color
        );
    }
    render() {
        const {
            firstName,
            lastName,
            color,
            small,
            classes,
            extraSmall,
            className,
            textClassName,
            large,
            customTooltip
        } = this.props;

        const initials = getFullNameInitials(firstName, lastName);

        const combinedRootClasses = classnames(
            {
                [classes.avatarColor]: !Boolean(color),
                [classes.avatar]: !small,
                [classes.extraSmall]: extraSmall,
                [classes.largeAvatar]: large
            },
            className
        );

        const combinedAvatarClasses = classnames(
            classes.avatarText,
            {
                [classes.extraSmallText]: extraSmall,
                [classes.largeText]: large,
                [classes.smallText]: small
            },
            textClassName
        );

        const avatarStyle = color
            ? {
                  backgroundColor: color
              }
            : {};

        return (
            <DefaultAvatar
                className={combinedRootClasses}
                style={avatarStyle}
                title={
                    customTooltip
                        ? customTooltip
                        : createTooltip(firstName, lastName)
                }
            >
                <Typography
                    variant="display3"
                    className={combinedAvatarClasses}
                >
                    {initials}
                </Typography>
            </DefaultAvatar>
        );
    }
}

Avatar.propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    color: PropTypes.string,
    small: PropTypes.bool,
    extraSmall: PropTypes.bool,
    large: PropTypes.bool,
    removeToolTip: PropTypes.bool
};
Avatar.defaultProps = {
    small: false,
    extraSmall: false,
    large: false,
    removeToolTip: false
};

export default withStyles(styles)(Avatar);
