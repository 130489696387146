import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 3.87 15">
            <path d="M3.71,1.07A.66.66,0,0,0,3.65.16a.65.65,0,0,0-.91.06,11,11,0,0,0,0,14.56.62.62,0,0,0,.48.22.65.65,0,0,0,.49-1.07A9.77,9.77,0,0,1,3.71,1.07Z" />
        </SvgIcon>
    );
}
