import * as types from "../../actionTypes";
import * as reducerUtils from "../reducerUtils";

const initialUserState = {
    items: {},
    filters: {
        includeUsers: "active",
        selectedUsers: {}
    },
    displayType: "GRID",
    userDetailsFilters: {
        selectedTab: 0
    },
    logs: []
};
export default function users(state = initialUserState, action) {
    switch (action.type) {
        case types.SET_USERS:
            return {
                ...state,
                items: {
                    ...state.items,
                    ...action.payload
                }
            };
        case types.SET_SHALLOW_USERS: {
            return {
                ...state,
                items: reducerUtils.mergeObjects(state.items, action.payload)
            };
        }
        case types.SET_AUTH:
            if (!action.payload || !action.payload.user) return state;
            return {
                ...state,
                items: {
                    ...state.items,
                    [action.payload.user.id]: {
                        ...action.payload.user
                    }
                }
            };
        case types.SET_USER_LOGS:
            return {
                ...state,
                logs: {
                    ...state.logs,
                    ...action.payload
                }
            };
        case types.SET_USER_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload
                }
            };
        case types.SET_DISPLAY_TYPE:
            return {
                ...state,
                displayType: action.payload
            };
        case types.SET_USER_DETAILS_FILTERS:
            return {
                ...state,
                userDetailsFilters: {
                    ...state.userDetailsFilters,
                    ...action.payload
                }
            };

        default:
            return state;
    }
}
