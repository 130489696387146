import * as _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import KeyFigure, { propTypes as KeyFigurePropTypes } from "./KeyFigure";
import classNames from "classnames";

const styles = theme => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        minHeight: theme.spacing.infoBar.height,
        paddingLeft: "30px",
        paddingRight: "30px",
        borderBottom: `solid ${theme.gridItemBorder.borderColor} ${
            theme.gridItemBorder.borderWidth
        }`,
        boxSizing: "border-box",
        flexWrap: "wrap",
        marginTop: "-21px"
    },
    keyFigures: {
        display: "flex",
        alignItems: "center",
        height: "80px",
        marginTop: "20px"
    },
    keyFiguresPadding: {
        paddingLeft: "20px"
    },
    select: {
        borderColor: theme.palette.C_DB15,
        "&:hover": {
            borderColor: theme.palette.C_DB40
        },
        transition: "all 100ms",
        minWidth: "150px",
        height: "44px"
    },
    selecters: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginTop: "20px"
    },
    selector: {
        marginLeft: "20px",
        height: "40px"
    },
    monthPicker: {
        marginLeft: theme.spacing.gridMargin
    }
});

class InfoBar extends PureComponent {
    renderKeyFigures = () => {
        const { keyFigures } = this.props;
        return _.map(keyFigures, (keyFigure, i) => {
            return (
                <KeyFigure
                    {...keyFigure}
                    name={keyFigure.name}
                    value={keyFigure.value}
                    change={keyFigure.change}
                    changeType={keyFigure.changeType}
                    key={keyFigure.id || keyFigure.name}
                    type={keyFigure.type}
                    byNormalHours={keyFigure.byNormalHours}
                    border={(i + 1) % 2 === 0}
                />
            );
        });
    };

    render() {
        const { classes, keyFigures, mainContent } = this.props;

        const selectors = React.Children.map(this.props.children, child => {
            if (!child) return null;
            return <div className={classes.selector}>{child}</div>;
        });

        const rootClassName = classNames(classes.root, {
            [classes.keyFiguresPadding]: Array.isArray(keyFigures)
        });
        return (
            <div className={rootClassName}>
                <div className={classes.keyFigures}>
                    {Array.isArray(keyFigures)
                        ? this.renderKeyFigures()
                        : mainContent}
                </div>

                <div className={classes.selecters}>{selectors}</div>
            </div>
        );
    }
}

InfoBar.propTypes = {
    /**
     * Optional key figures. These will be placed in the main content
     */
    keyFigures: PropTypes.arrayOf(PropTypes.shape(KeyFigurePropTypes)),
    /**
     * If key figures is not defined. Main content can be used to place
     * a custom item in the left side of the infobar
     */
    mainContent: PropTypes.node
};

InfoBar.defaultProps = {
    keyFigures: null,
    mainContent: null
};
export default withStyles(styles)(InfoBar);
