import * as _ from "lodash";
import moment from "moment";
import { createSelector } from "reselect";
import { userGroups } from "../../actionTypes";
import * as companySelectors from "../../selectors/company";
import { formatDate } from "../../utils/index";
import * as userSelectors from "selectors/user";

export const userGroupOptions = [
    "Ansatt",
    "Avdelingsleder",
    "Admin og avd. leder",
    "Admin"
];

const getSignedInCompany = state => state.companies[state.auth.user.companyId];

export const getUserFilters = state => state.users.filters;
const getSearchString = state => state.shallowUsers.filters.search;
export const getDisplayType = state => state.shallowUsers.displayType;

export const getDefaultNormalHours = createSelector(
    getSignedInCompany,
    company => company && company.normalHours
);

export const getCurrentFullTimeEquivalent = data => {
    const date = moment();

    const withKey = _.map(data, (item, id) => ({ ...item, id }));
    const orderedData = _.orderBy(withKey, "fromDate", "desc");

    //Check if fromdate is before today and toDate is after today or does not exist
    return _.find(
        orderedData,
        (val, i) =>
            date.isSameOrAfter(val.fromDate) &&
            (date.isSameOrBefore(val.toDate) || !val.toDate)
    );
};

export const getDepartmentById = (departments, departmentId) => {
    return _.find(departments, dep => dep.id === departmentId);
};

export const getActiveIndex = createSelector(getUserFilters, filters => {
    if (filters.includeUsers === "active") return 0;
    else return 1;
});
export const getUserGroupName = (userGroup, isDepartmentLeader) => {
    if (!userGroup) return "";
    if (userGroup === userGroups.USER) {
        return userGroupOptions[0];
    } else if (userGroup === userGroups.DEPARTMENT_LEADER) {
        return userGroupOptions[1];
    } else if (userGroup === userGroups.ADMIN && isDepartmentLeader === true) {
        return userGroupOptions[2];
    } else if (userGroup === userGroups.ADMIN) {
        return userGroupOptions[3];
    } else {
        throw new Error(
            `Usergroup and departmentLeader are not in a valid state. userGroup=${userGroup} isDepartmentLeader=${isDepartmentLeader}`
        );
    }
};

export const filterUsersByFilters = (users, filters) => {
    return _.filter(users, user => {
        if (
            (filters.includeUsers === "active" && !user.isActive) ||
            (filters.includeUsers === "inactive" && user.isActive)
        ) {
            return false;
        }
        return !(
            filters.departmentId !== undefined &&
            filters.departmentId !== null &&
            filters.departmentId !== user.departmentId
        );
    });
};

export const getConvertedUserData = createSelector(
    [
        companySelectors.getCompanyDepartments,
        getDefaultNormalHours,
        userSelectors.getUserItems,
        getUserFilters
    ],
    (departments, defaultNormalHours, users, filters) => {
        const filteredUsers = filterUsersByFilters(users, filters);

        return _.map(filteredUsers, user => {
            const currentFullTimeEquivalent = getCurrentFullTimeEquivalent(
                user.fullTimeEquivalent
            );
            const userDepartment = getDepartmentById(
                departments,
                user.departmentId
            );
            const normalHours = user.normalHours
                ? user.normalHours
                : defaultNormalHours;

            return {
                ...user,
                department: userDepartment
                    ? userDepartment.name
                    : "Avdeling slettet",
                startDate: formatDate(user.startDate),
                endDate: formatDate(user.endDate),
                normalHours,
                fullTimeEquivalent: currentFullTimeEquivalent
                    ? currentFullTimeEquivalent.value
                    : "Ikke satt",
                fullTimeEquivalentKey: currentFullTimeEquivalent
                    ? currentFullTimeEquivalent.key
                    : null,
                nameValue: {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    id: user.id,
                    nameLink: `/users/${user.companyId}/user/${user.id}`
                },
                name: `${user.firstName} ${user.lastName}`.trim(),
                userGroupName: getUserGroupName(
                    user.userGroup,
                    user.isDepartmentLeader
                )
            };
        });
    }
);

export const getUserPreviews = createSelector(
    [
        companySelectors.getCompanyDepartments,
        userSelectors.getOrderedUserItems,
        getUserFilters
    ],
    (departments, users, filters) => {
        const filteredUsers = filterUsersByFilters(users, filters);

        return _.map(filteredUsers, user => {
            const dep = getDepartmentById(departments, user.departmentId);
            return {
                ...user,
                department: dep ? dep.name : ""
            };
        });
    }
);

const filterUsersByName = (users, search) => {
    return search
        ? _.filter(
              users,
              user =>
                  _.lowerCase(user.displayName).indexOf(_.lowerCase(search)) >=
                  0
          )
        : users;
};

export const getSearchFilteredUserPreviews = createSelector(
    [getUserPreviews, getUserFilters],
    (users, filters) => filterUsersByName(users, filters.search)
);
export const getFilteredUserItems = createSelector(
    userSelectors.getOrderedUserItems,
    getUserFilters,
    (users, filters) => {
        let filteredUsers = filterUsersByName(users, filters.search);

        return filterUsersByFilters(filteredUsers, filters);
    }
);

export const getCurrentDepartment = createSelector(
    [getUserFilters, userSelectors.getSignedInUser],
    (filters, signedInUser) => {
        if (filters.departmentId) return filters.departmentId;
        else {
            if (signedInUser.userGroup === userGroups.ADMIN) return null;
            else return signedInUser.departmentId;
        }
    }
);

export const getSignedInUserDepartment = createSelector(
    userSelectors.getSignedInUser,
    user => user.departmentId
);

// Show only the departmentleader's department. If not found, set to blank to safeguard error.
export const getDepartmentLeaderDepartments = createSelector(
    [getSignedInUserDepartment, companySelectors.getCompanyDepartments],
    (userDepartment, departments) =>
        _.filter(departments, dep => userDepartment === dep.id)
);

export const getDepartmentsByAuth = createSelector(
    [
        userSelectors.getSignedInUser,
        companySelectors.getOrderedCompanyDepartmentsWithAll,
        getDepartmentLeaderDepartments
    ],
    (signedInUser, adminDepartments, depLeaderDepartments) => {
        return signedInUser.userGroup === userGroups.ADMIN
            ? adminDepartments
            : depLeaderDepartments;
    }
);

export const getUsersKeyFigures = createSelector(
    userSelectors.getAdministrators,
    userSelectors.getDepartmentLeaders,
    userSelectors.getEmployees,
    (admins, teamLeaders, employees) => {
        return [
            {
                name: "Antall brukere",
                value: admins.length + teamLeaders.length + employees.length
            },
            { name: "Ansatte", value: employees.length },
            { name: "Avdelingsledere", value: teamLeaders.length },
            { name: "Administratorer", value: admins.length }
        ];
    }
);

export const getUserGridProps = (state, props) => {
    return {
        users: getSearchFilteredUserPreviews(state, props),
        signedInUser: userSelectors.getSignedInUser(state, props),
        filters: getUserFilters(state, props),
        displayType: getDisplayType(state, props)
    };
};
export const getUserGridHeaderProps = (state, props) => {
    return {
        filters: getUserFilters(state, props),
        activeIndex: getActiveIndex(state, props),
        activeDepartment: getCurrentDepartment(state, props),
        departments: companySelectors.getOrderedCompanyDepartments(
            state,
            props
        ),
        searchString: getSearchString(state, props),
        displayType: getDisplayType(state, props),
        selected: getUserFilters(state, props).selectedUsers,
        company: companySelectors.getCurrentCompany(state, props),
        signedInUser: userSelectors.getSignedInUser(state, props)
    };
};

export const GetUsersProps = (state, props) => {
    return {
        displayType: getDisplayType(state, props)
    };
};
