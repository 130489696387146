import React, { PureComponent } from "react";
import Button from "@material-ui/core/Button";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import CaretIcon from "../icons/CaretIcon";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
const styles = theme => ({
    button: {
        justifyContent: "space-between",
        fontSize: theme.typography.F_N,
        backgroundColor: theme.palette.C_W,
        ...theme.gridItemBorder,
        borderWidth: "1px",
        borderRadius: "6px",
        minWidth: "150px",
        minHeight: "40px",
        "&:hover": {
            backgroundColor: theme.palette.C_W,
            borderColor: theme.palette.C_DB40
        }
    },
    buttonOpen: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomStyle: "none"
    },
    caretIcon: {
        width: "10px",
        right: "15px",
        color: theme.palette.C_DB60,
        "pointer-events": "none", // Don't block pointer events on the select under the icon.
        marginLeft: "15px",
        marginTop: "1px"
    },
    text: {
        fontSize: theme.typography.F_S_N,
        fontWeight: 600
    },
    simpleText: {
        fontWeight: 500,
        color: theme.palette.C_DB60,
        fontSize: theme.typography.F_N
    },
    simple: {
        border: "none",
        backgroundColor: "unset",
        "&:hover": {
            backgroundColor: "unset",
            borderColor: "unset"
        },
        minHeight: "unset",
        padding: "0",
        "&:disabled": {
            "& $caretIcon": {
                display: "none"
            }
        }
    }
});
class SelectButton extends PureComponent {
    render() {
        const {
            classes,
            className,
            textClassName,
            children,
            variant,
            disabled,
            ...other
        } = this.props;
        const isSimple = variant === "simple";

        return (
            <Button
                aria-haspopup="true"
                className={classNames(
                    classes.button,
                    { [classes.simple]: isSimple },
                    className
                )}
                disableFocusRipple
                disableRipple
                disabled={disabled}
                {...other}
            >
                <Typography
                    className={classNames(
                        classes.text,
                        { [classes.simpleText]: isSimple },
                        textClassName
                    )}
                    color={isSimple ? "faded" : "default"}
                >
                    {children}
                </Typography>
                <CaretIcon className={classes.caretIcon} />
            </Button>
        );
    }
}

SelectButton.propTypes = {
    variant: PropTypes.oneOf(["default", "simple"])
};
SelectButton.defaultProps = {
    variant: "default"
};
export default withStyles(styles)(SelectButton);
