import * as companySelectors from "../../../selectors/company";
import { createSelector } from "reselect";
import * as _ from "lodash";
import { userGroups } from "../../../actionTypes";

const getSignedInUser = state => state.auth.user;
export const getCurrentDepartment = (_, props) =>
    props.match.params.departmentId;

export const getHasCompanyAuthorization = createSelector(
    getSignedInUser,
    signedInUser => signedInUser.userGroup === userGroups.ADMIN
);

export const getDepartmentName = createSelector(
    [companySelectors.getCreatedCompanyDepartmentWithAll, getCurrentDepartment],
    (departments, currentDepartment) => {
        if (currentDepartment === undefined) {
            return;
        }
        const fullDepartment = _.find(
            departments,
            dep => dep.id === currentDepartment
        );
        return fullDepartment ? fullDepartment.name : "Ikke funnet";
    }
);

export const getPossibleDepartments = createSelector(
    [
        getSignedInUser,
        companySelectors.getCreatedCompanyDepartmentWithAll,
        getCurrentDepartment
    ],

    (signedInUser, departments, currentDepartment) => {
        if (signedInUser.userGroup !== userGroups.ADMIN) {
            //Current department should always be the signed in user's department if not admin.
            const thisDepartment = _.find(
                departments,
                dep => dep.id === currentDepartment
            );

            return thisDepartment ? [thisDepartment] : undefined;
        }

        return departments;
    }
);

export const getSelectedDepartment = createSelector(
    [getCurrentDepartment, getPossibleDepartments],
    (currentDepartment, possibleDepartments) => {
        //All departments are selected
        if (!currentDepartment) {
            if (!possibleDepartments) return;
            return 0; // All departments should always be first entry
        }
        return _.findIndex(
            possibleDepartments,
            dep => dep.id === currentDepartment
        );
    }
);
