import calculationRules from "./calculationRules";
import companies from "./companies";
import keyPerformanceIndicators from "./keyPerformanceIndicators";
import notificationSettings from "./notificationSettings";
//import company from "./company";

export default {
    calculationRules,
    companies,
    keyPerformanceIndicators,
    notificationSettings
};
