import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import InfoIcon from "./icons/InfoIcon";
import Typography from "./Typography";
import Popup from "./poppers/Popup";
import PropTypes from "prop-types";
import classNames from "classnames";
import { capitalize } from "@material-ui/core/utils/helpers";
import RoundIconButton from "./forms/buttons/RoundIconButton";

const styles = theme => ({
    link: {
        color: theme.palette.C_LB,
        "a:visited": {
            color: theme.palette.C_LB
        }
    },
    iconButton: {
        cursor: "help"
    },
    infoContainer: {
        padding: "20px",
        backgroundColor: theme.palette.C_DB140,
        width: "200px",
        zIndex: 200000
    },
    arrow: {
        color: theme.palette.C_DB140
    },
    popup: {
        border: "none"
    },
    infoText: {
        whiteSpace: "normal"
    }
});

class InfoTag extends PureComponent {
    state = {
        open: false
    };

    handleMouseEnter = e => {
        this.setState({ open: true });
        this.props.onHover(true);
    };
    handleMouseLeave = e => {
        this.setState({ open: false });
        this.props.onHover(false);
    };
    render() {
        const {
            classes,
            description,
            placement,
            className,
            size,
            darkBackground
        } = this.props;
        return (
            <Popup
                onClickAway={this.handleMouseLeave}
                open={this.state.open}
                arrowClassName={classes.arrow}
                className={classes.popup}
                placement={placement}
                target={
                    <RoundIconButton
                        darkBackground={darkBackground}
                        size={size}
                        className={classNames(classes.iconButton, className)}
                        onMouseEnter={this.handleMouseEnter}
                        onMouseLeave={this.handleMouseLeave}
                        icon={<InfoIcon />}
                    />
                }
            >
                <div className={classes.infoContainer}>
                    <Typography
                        color={"white"}
                        size={"smallMed"}
                        className={classes.infoText}
                    >
                        {description}
                    </Typography>
                </div>
            </Popup>
        );
    }
}

InfoTag.propTypes = {
    placement: PropTypes.oneOf(["left", "right"]),
    description: PropTypes.string,
    onHover: PropTypes.func,
    size: PropTypes.oneOf(["small", "regular"]),
    darkBackground: PropTypes.bool
};

InfoTag.defaultProps = {
    placement: "left",
    size: "regular",
    onHover: () => {},
    darkBackground: false
};
export default withStyles(styles)(InfoTag);
