import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { componentDescriptions } from "../../../../constants/strings";
import * as companySelectors from "../../../../selectors/company";
import { updateDisplaySettings } from "./actions";
import DisplaySettingsCard from "./DisplaySettingsCard";

const items = [
    {
        label: "Mertid",
        id: "extraHours",
        desc: componentDescriptions.displaySettings.extraHours
    },
    {
        label: "Overtid",
        id: "overtimeHours",
        desc: componentDescriptions.displaySettings.overtimeHours
    },
    {
        label: "Tillegg",
        id: "addition",
        desc: componentDescriptions.displaySettings.addition
    },
    {
        label: "Minustid",
        id: "minusHours",
        desc: componentDescriptions.displaySettings.minusHours
    },
    {
        label: "Omsetning",
        id: "monthlyTurnover",
        desc: componentDescriptions.displaySettings.monthlyTurnover
    },
    {
        label: "FG",
        id: "invoiceFactor",
        desc: componentDescriptions.displaySettings.invoiceFactor
    },
    {
        label: "FGN",
        id: "invoiceFactorNormal",
        desc: componentDescriptions.displaySettings.invoiceFactorNormal
    },
    {
        label: "FGN-F",
        id: "invoiceFactorNormalVacation",
        desc: componentDescriptions.displaySettings.invoiceFactorNormalVacation
    }
];

const mapStateToProps = (state, props) => {
    const company = companySelectors.getCurrentCompany(state, props);
    return {
        items,
        displaySettings: company.view,
        title: "Beregninger",
        companyId: company.id
    };
};

export default withRouter(
    connect(mapStateToProps, { updateDisplaySettings })(DisplaySettingsCard)
);
