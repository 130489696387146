import { httpRequest, assert } from "./helpers";

export const getSyncStatus = async companyId => {
    assert(companyId, "CompanyId has to be defined");

    return await httpRequest(`/api/v1/resync/${companyId}/status`, "GET");
};

export const requestSynchronization = async (
    companyId,
    userId,
    providerToken
) => {
    assert(companyId, "CompanyId has to be defined");

    return await httpRequest(
        `/api/v1/resync/${companyId}/${userId}/synchronize`,
        "POST",
        {
            providerToken
        }
    );
};
export const requestManualCompanySynchronization = async (
    companyId,
    type,
    providerToken
) => {
    assert(companyId, "CompanyId has to be defined");
    assert(type, "Type has to be defined");
    assert(providerToken, "ProviderToken has to be defined");

    return await httpRequest(
        `/api/v1/resync/manual/company/${companyId}`,
        "POST",
        {
            providerToken,
            type
        }
    );
};
export const requestManualUserSynchronization = async (
    userId,
    providerToken
) => {
    assert(userId, "CompanyId has to be defined");

    return await httpRequest(`/api/v1/resync/manual/user/${userId}`, "POST", {
        providerToken
    });
};

export const refreshProviderToken = async (email, password) => {
    assert(email, "Email has to be defined");
    assert(password, "Password has to be defined");

    return await httpRequest(
        `/api/v1/authentication/refreshProviderToken`,
        "POST",
        {
            email,
            password
        }
    );
};
