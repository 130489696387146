import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import { map } from "lodash";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    icon: {
        color: theme.palette.C_DB40,
        "&:hover": {
            color: theme.palette.C_AB
        }
    },

    root: {
        display: "flex",
        alignItems: "center",
        "& > *": {
            marginLeft: "10px"
        }
    },
    iconButton: {
        width: "40px"
    }
});
class IconBar extends Component {
    shouldComponentUpdate(nextProps) {
        return nextProps.icons !== this.props.icons;
    }
    render() {
        const { icons, classes } = this.props;
        const iconViews = map(icons, (icon, i) => {
            let thisIcon = null;
            if (icon.onClick) {
                thisIcon = (
                    <IconButton
                        onClick={icon.onClick}
                        key={i}
                        title={icon.tooltip || ""}
                        disableRipple
                        className={classes.iconButton}
                    >
                        {React.cloneElement(icon.component, {
                            className: classes.icon
                        })}
                    </IconButton>
                );
            } else {
                thisIcon = React.cloneElement(icon.component, {
                    className: classes.unclickableIcon,
                    key: i,
                    title: icon.tooltip || ""
                });
            }
            return thisIcon;
        });
        return <div className={classes.root}>{iconViews}</div>;
    }
}

IconBar.propTypes = {
    icons: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default withStyles(styles)(IconBar);
