import React from "react";
import TimeGridBasis from "./TimeGridBasis";
import TimeGridDate from "./TimeGridDate";

class SingleMonthTimeGrid extends React.Component {
    render() {
        const { data, currentDate } = this.props;
        return (
            <TimeGridBasis
                data={data}
                currentDate={currentDate}
                renderLeftColumnTitle={item => (
                    <TimeGridDate date={item.moment} />
                )}
                leftColumnHeader={"Uke"}
                daysInMonth={31}
                dataBasis={"yearly"}
                headers={[
                    "Mandag",
                    "Tirsdag",
                    "Onsdag",
                    "Torsdag",
                    "Fredag",
                    "Lørdag",
                    "Søndag"
                ]}
                minLeftColWidth={"200px"}
                businessDayHeaderIndices={[5, 6]}
            />
        );
    }
}

export default SingleMonthTimeGrid;
