import React, { Component } from "react";
import PropTypes from "prop-types";
import { ruleTypes, ruleCategories } from "actionTypes";
import SimpleDialog from "common/dialogs/SimpleDialog";
import InputField from "common/forms/text-fields/InputField";
import DatePicker from "common/forms/date-picker/InputDatePicker";
import * as utils from "utils";
import DialogButtonGroup from "common/dialogs/dialogContent/DialogButtonGroup";
import { connect } from "react-redux";
import Flex from "../../components/layout/flex/Flex";
import SelectInput from "../../common/forms/SelectInput";
import * as actions from "../../actionTypes";
import moment from "moment";
import IconPopupMenu from "../../common/poppers/IconPopupMenu";

import * as _ from "lodash";
import * as modalActions from "./actions";
import { componentDescriptions } from "../../constants/strings";

const dayOptions = {
    FULL_DAY: 2,
    HALF_DAY: 1,
    EMPTY_DAY: 0,
    OTHER: 3
};
const ruleTypeOptions = [ruleTypes.SINGLE_DAY, ruleTypes.PERIODIC];
const ruleCategoryOptions = [
    ruleCategories.NORMAL_HOURS,
    ruleCategories.OVERTIME_ADDITIONAL
];
export const buildRuleInputs = existingRule => {
    const ruleInputs = {};

    ruleInputs.ruleCategory = existingRule.ruleCategory;
    ruleInputs.ruleValue =
        existingRule.ruleCategory === ruleCategories.OVERTIME_ADDITIONAL // overtime additional are shown in percent in UI
            ? existingRule.ruleValue * 100
            : existingRule.ruleValue;
    ruleInputs.ruleType = existingRule.ruleType;
    ruleInputs.description = existingRule.description;

    if (existingRule.ruleType === ruleTypes.SINGLE_DAY) {
        ruleInputs.singleDate = moment(existingRule.singleDate);
    } else if (existingRule.ruleType === ruleTypes.PERIODIC) {
        ruleInputs.fromDate = moment(existingRule.fromDate);
        ruleInputs.toDate = moment(existingRule.toDate);
    }
    return ruleInputs;
};

export class CalculationRuleModal extends Component {
    state = {
        ruleInputs: {
            ruleValue: 0,
            ruleCategory: ruleCategories.NORMAL_HOURS,
            ruleType: ruleTypes.SINGLE_DAY,
            fromDate: moment(),
            toDate: moment(),
            singleDate: moment(),
            description: ""
        },
        hasExtraInput: false,
        isDeleting: false,
        canShowError: {
            description: false,
            fromDate: false,
            toDate: false,
            singleDate: false
        }
    };
    inputRef = null;

    componentDidMount() {
        if (this.props.existingRuleData) {
            this.mergeRule(buildRuleInputs(this.props.existingRuleData));
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.hasExtraInput === false &&
            this.state.hasExtraInput === true
        ) {
            setTimeout(() => {
                this.inputRef && this.inputRef.select();
            }, 100);
        }
    }

    transformRulePayload = unTransformedPayload => {
        const payload = { ...unTransformedPayload };
        if (payload.ruleType === ruleTypes.SINGLE_DAY) {
            delete payload.fromDate;
            delete payload.toDate;
        } else if (payload.ruleType === ruleTypes.PERIODIC) {
            delete payload.singleDate;
        }

        payload.ruleValue = parseFloat(payload.ruleValue, 10);

        if (
            payload.ruleCategory === ruleCategories.OVERTIME_ADDITIONAL &&
            payload.ruleType === ruleTypes.PERIODIC
        ) {
            payload.ruleValue = payload.ruleValue / 100; // Input is in percent
            if (this.props.hasWeekBasedCalculation) {
                payload.fromDate = moment(payload.fromDate).startOf("isoWeek");
                payload.toDate = moment(payload.toDate).endOf("isoWeek");
            }
        }

        return payload;
    };

    handleInputBlur = e => {
        this.setState({
            canShowError: { ...this.state.canShowError, [e.target.name]: true }
        });
    };
    handleDateFocusChange = name => () => {
        this.setState({
            canShowError: { ...this.state.canShowError, [name]: true }
        });
    };
    mergeRule = payload => {
        this.setState({ ruleInputs: { ...this.state.ruleInputs, ...payload } });
    };

    onDayOptionsChange = index => {
        const { ruleMaxValue } = this.props;

        if (index === dayOptions.EMPTY_DAY) {
            // Empty Day
            this.mergeRule({ ruleValue: 0 });
        } else if (index === dayOptions.HALF_DAY) {
            this.mergeRule({ ruleValue: _.round(ruleMaxValue / 2, 4) });
        } else if (index === dayOptions.FULL_DAY) {
            this.mergeRule({ ruleValue: ruleMaxValue });
        }
        this.setState({ hasExtraInput: index === dayOptions.OTHER });
    };

    handleRuleTypeChange = index => {
        this.mergeRule({ ruleType: ruleTypeOptions[index] });
    };

    handleFromDateChange = date => {
        this.mergeRule({ fromDate: date });
    };
    handleToDateChange = date => {
        this.mergeRule({ toDate: date });
    };
    handleSingleDateChange = date => {
        this.mergeRule({ singleDate: date });
    };
    handleRuleCategoryChange = index => {
        const category = ruleCategoryOptions[index];
        const update = {
            ruleCategory: category
        };
        if (category === ruleCategories.OVERTIME_ADDITIONAL) {
            update.ruleType = ruleTypes.PERIODIC;
            if (this.props.hasWeekBasedCalculation) {
                update.fromDate = moment(
                    this.state.ruleInputs.fromDate
                ).startOf("isoWeek");
                update.toDate = moment(this.state.ruleInputs.toDate).endOf(
                    "isoWeek"
                );
            } else {
                update.fromDate = moment(
                    this.state.ruleInputs.fromDate
                ).startOf("month");
                update.toDate = moment(this.state.ruleInputs.toDate).endOf(
                    "month"
                );
            }
        }
        this.mergeRule(update);
    };
    handleInputChange = e => {
        this.mergeRule({ [e.target.name]: e.target.value });
    };

    handleConfirm = () => {
        const payload = this.transformRulePayload(this.state.ruleInputs);
        if (this.props.currentRule) {
            this.props.updateRuleSetting(this.props.currentRule, payload);
        } else {
            this.props.createRuleSetting(payload);
        }
        this.props.onClose();
    };

    isFromDateBlocked = date => {
        if (
            this.state.ruleInputs.ruleCategory ===
            ruleCategories.OVERTIME_ADDITIONAL
        ) {
            if (this.props.hasWeekBasedCalculation) {
                return moment(date).isoWeekday() !== 1; // Monday
            }

            return moment(date).date() !== 1;
        }
        return false;
    };
    isToDateBlocked = date => {
        if (
            this.state.ruleInputs.ruleCategory ===
            ruleCategories.OVERTIME_ADDITIONAL
        ) {
            if (this.props.hasWeekBasedCalculation) {
                return moment(date).isoWeekday() !== 7; // Sunday
            }
            return moment(date).date() !== moment(date).daysInMonth();
        }
        return false;
    };

    renderSingleDayDates = error => {
        const { singleDate } = this.state.ruleInputs;
        const { canShowError } = this.state;

        return (
            <DatePicker
                date={singleDate}
                onChange={this.handleSingleDateChange}
                label="For dato"
                name="singleDate"
                error={canShowError.singleDate && error.singleDate}
                onFocusChange={this.handleDateFocusChange("singleDate")}
            />
        );
    };
    renderPeriodicDates = error => {
        const { toDate, fromDate } = this.state.ruleInputs;
        const { canShowError } = this.state;

        return (
            <Flex>
                <DatePicker
                    date={fromDate}
                    onChange={this.handleFromDateChange}
                    onFocusChange={this.handleDateFocusChange("fromDate")}
                    label="Fra dato"
                    name="fromDate"
                    isDayBlocked={this.isFromDateBlocked}
                    error={canShowError.fromDate && error.fromDate}
                />
                <DatePicker
                    date={toDate}
                    onChange={this.handleToDateChange}
                    onFocusChange={this.handleDateFocusChange("toDate")}
                    isDayBlocked={this.isToDateBlocked}
                    label="Til dato"
                    name="toDate"
                    error={canShowError.toDate && error.toDate}
                />
            </Flex>
        );
    };
    onDelete = () => {
        this.toggleIsDeleting();
        this.props.onDelete();
    };

    canShowTextError = error => {
        return _.some(this.state.canShowError, (value, key) => {
            return value && error[key];
        });
    };
    verifyInputs = () => {
        const {
            toDate,
            fromDate,
            description,
            ruleType,
            ruleValue,
            ruleCategory
        } = this.state.ruleInputs;

        const { relevantCalculationRules } = this.props;

        if (!description)
            return {
                description: true,
                message: "Beskrivelse må fylles ut."
            };

        if (
            ruleValue === "" ||
            ruleValue === "-" ||
            parseFloat(ruleValue) < 0
        ) {
            return {
                ruleValue: true
            };
        }
        const floatRuleValue = parseFloat(ruleValue, 10);
        if (ruleType === ruleTypes.PERIODIC) {
            if (
                ruleCategory === ruleCategories.NORMAL_HOURS &&
                floatRuleValue < 1
            ) {
                return {
                    ruleValue: true,
                    message:
                        "Normaltid for periodebasert regel kan ikke være mindre enn 1 time."
                };
            } else if (
                ruleCategory === ruleCategories.OVERTIME_ADDITIONAL &&
                (floatRuleValue < 0 || floatRuleValue > 100)
            ) {
                return {
                    ruleValue: true,
                    message: "Overtidstillegg må være mellom 0 og 100 prosent."
                };
            } else if (!toDate) {
                return {
                    toDate: true
                };
            } else if (!fromDate) {
                return {
                    fromDate: true
                };
            }
        }

        const calculationRulesWithoutCurrent =
            this.props.currentRule !== undefined
                ? _.filter(
                      relevantCalculationRules,
                      rule => rule.id !== this.props.currentRule
                  )
                : relevantCalculationRules;

        const error = utils.validateCalculationRulesAgainstExisting(
            calculationRulesWithoutCurrent,
            this.state.ruleInputs
        );

        switch (error) {
            case utils.calculationRuleErrors.VALID:
                return {};
            case utils.calculationRuleErrors.ALREADY_EXISTING_DAY:
                return {
                    singleDate: true,
                    message: "Det eksisterer allerede en regel for denne dagen."
                };
            case utils.calculationRuleErrors.ALREADY_EXISTING_PERIOD:
                return {
                    fromDate: true,
                    toDate: true,
                    message:
                        "Det eksisterer allerede en regel for denne perioden"
                };
            case utils.calculationRuleErrors.INVALID_RANGE:
                return {
                    fromDate: true,
                    toDate: true,
                    message: "Til dato må være etter fra dato."
                };
            default:
                return {};
        }
    };

    render() {
        const { onClose, ruleMaxValue, onDelete, modalProps } = this.props;

        const { hasExtraInput, canShowError, ruleInputs } = this.state;

        const error = this.verifyInputs();

        const hasSelectedSingleDay =
            ruleInputs.ruleType === ruleTypes.SINGLE_DAY;

        const ruleCategoryMenuItems = ["Normaltid", "Overtidstillegg"];

        const ruleTypeMenuItems = ["Dagsbasert", "Periodebasert"];

        let selectedValue = dayOptions.EMPTY_DAY;

        if (hasExtraInput) {
            selectedValue = dayOptions.OTHER;
        } else if (_.round(ruleMaxValue / 2, 4) === ruleInputs.ruleValue) {
            selectedValue = dayOptions.HALF_DAY;
        } else if (ruleMaxValue === ruleInputs.ruleValue) {
            selectedValue = dayOptions.FULL_DAY;
        }

        const isEditing = _.isFunction(onDelete);

        const isRuleTypeEnabled =
            ruleInputs.ruleCategory !== ruleCategories.OVERTIME_ADDITIONAL;

        let ruleValueLabel = undefined;
        let ruleValueSubLabel = undefined;
        if (
            !hasSelectedSingleDay &&
            ruleInputs.ruleCategory === ruleCategories.NORMAL_HOURS
        ) {
            ruleValueLabel = "Normaltid";
            ruleValueSubLabel = "timer";
        }
        if (ruleInputs.ruleCategory === ruleCategories.OVERTIME_ADDITIONAL) {
            ruleValueLabel = "Overtidstillegg";
            ruleValueSubLabel = "prosent";
        }
        return (
            <SimpleDialog
                open={true}
                {...modalProps}
                errorMessage={this.canShowTextError(error) && error.message}
                menu={
                    isEditing ? (
                        <IconPopupMenu
                            options={["Slett regel"]}
                            highlighted={[0]}
                            onSelect={this.toggleIsDeleting}
                        />
                    ) : null
                }
                buttonGroup={
                    <DialogButtonGroup
                        type="default"
                        onCancel={onClose}
                        onConfirm={this.handleConfirm}
                        disabled={!_.isEmpty(error)}
                    />
                }
            >
                <SelectInput
                    value={ruleCategoryOptions.indexOf(ruleInputs.ruleCategory)}
                    onChange={this.handleRuleCategoryChange}
                    fullWidth
                    label={"Regetype"}
                    options={ruleCategoryMenuItems}
                />
                <SelectInput
                    value={ruleTypeOptions.indexOf(ruleInputs.ruleType)}
                    onChange={this.handleRuleTypeChange}
                    fullWidth
                    label="Tidsrom"
                    options={ruleTypeMenuItems}
                    disabled={!isRuleTypeEnabled}
                    description={componentDescriptions.setup.rulePeriod}
                />
                {hasSelectedSingleDay ? (
                    <SelectInput
                        label="Regel"
                        value={selectedValue}
                        onChange={this.onDayOptionsChange}
                        fullWidth
                        options={[
                            "Fridag",
                            "Halvdag",
                            "Heldag",
                            "Spesifikk normaltid"
                        ]}
                    />
                ) : null}
                {hasExtraInput || !hasSelectedSingleDay ? (
                    <InputField
                        value={ruleInputs.ruleValue}
                        fullWidth
                        numeric
                        onChange={this.handleInputChange}
                        label={ruleValueLabel}
                        subLabel={ruleValueSubLabel}
                        inputRef={r => (this.inputRef = r)}
                        name="ruleValue"
                        onBlur={this.handleInputBlur}
                        error={error.ruleValue && canShowError.ruleValue}
                    />
                ) : null}
                {hasSelectedSingleDay
                    ? this.renderSingleDayDates(error)
                    : this.renderPeriodicDates(error)}
                <InputField
                    value={ruleInputs.description}
                    fullWidth
                    onChange={this.handleInputChange}
                    label="Beskrivelse*"
                    error={error.description && canShowError.description}
                    name="description"
                    placeholder={"Beskriv denne regelen..."}
                    onBlur={this.handleInputBlur}
                />
            </SimpleDialog>
        );
    }
}

CalculationRuleModal.propTypes = {
    ruleType: PropTypes.oneOf([ruleTypes.PERIODIC, ruleTypes.SINGLE_DAY]),
    ruleMaxValue: PropTypes.number,
    ruleValue: PropTypes.number,
    fromDate: PropTypes.object, //moment
    toDate: PropTypes.object,
    singleDate: PropTypes.object,
    open: PropTypes.bool,
    onRuleChange: PropTypes.func,
    onRuleValueChange: PropTypes.func,
    onSingleDateChange: PropTypes.func,
    error: PropTypes.shape({
        fromDate: PropTypes.bool,
        toDate: PropTypes.bool,
        singleDate: PropTypes.bool,
        name: PropTypes.bool,
        message: PropTypes.string
    })
};

const mapStateToProps = (state, props) => {
    const { companyId } = props.modalProps;
    const company = state.companies[companyId] || {};
    const currentRule = props.modalProps.calculationRuleId;
    const existingRuleData = currentRule
        ? state.calculationRules[currentRule]
        : null;
    return {
        ruleMaxValue: company.normalHours,
        relevantCalculationRules: _.values(state.calculationRules),
        currentRule,
        existingRuleData,
        hasWeekBasedCalculation: company.hasWeekBasedCalculation
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    const companyId = ownProps.modalProps.companyId;
    return {
        createRuleSetting: payload => {
            dispatch({
                type: actions.REQUEST_CREATE_CALCULATION_RULE,
                companyId,
                payload
            });
        },
        updateRuleSetting: (calculationRuleId, payload) => {
            dispatch({
                type: actions.REQUEST_UPDATE_CALCULATION_RULE,
                companyId,
                calculationRuleId,
                payload
            });
        },
        deleteRuleSetting: calculationRuleId => {
            dispatch({
                type: actions.REQUEST_DELETE_CALCULATION_RULE,
                companyId,
                calculationRuleId
            });
        },
        fetchCalculationRules: () => {
            dispatch({
                type: actions.REQUEST_CALCULATION_RULES,
                companyId
            });
        },
        onClose: () => dispatch(modalActions.closeModal())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(
    CalculationRuleModal
);
