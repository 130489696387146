import * as types from "actionTypes";
import { notificationActions } from "../actionTypes";

export const createDepartment = (companyId, department) => {
    return {
        type: types.REQUEST_CREATE_DEPARTMENT,
        payload: {
            department,
            companyId
        }
    };
};
export const updateDepartment = (companyId, departmentId, department) => {
    return {
        type: types.REQUEST_UPDATE_DEPARTMENT,
        payload: {
            companyId,
            department,
            departmentId: departmentId
        }
    };
};
export const deleteDepartment = (companyId, departmentId) => {
    return {
        type: types.REQUEST_DELETE_DEPARTMENT,
        payload: {
            companyId,
            departmentId
        }
    };
};

export const updateWorkTypeCategory = (companyId, workTypeId, category) => {
    return {
        type: types.UPDATE_WORKTYPE_CATEGORIES,
        payload: {
            selected: [workTypeId],
            category: category,
            companyId
        }
    };
};

export const updateSeveralWorkTypeCategories = (
    companyId,
    workTypeIds,
    category
) => {
    return {
        type: types.UPDATE_WORKTYPE_CATEGORIES,
        payload: {
            selected: workTypeIds,
            category: category,
            companyId
        }
    };
};

export const fetchCalculationRules = companyId => ({
    type: types.REQUEST_CALCULATION_RULES,
    companyId
});

export const updateCompanyRules = (companyId, ruleId, payload) => {
    return {
        type: types.REQUEST_UPDATE_COMPANY_RULES,
        payload,
        companyId,
        ruleId
    };
};

export const deleteCalculationRule = (companyId, calculationRuleId) => {
    return {
        type: types.REQUEST_DELETE_CALCULATION_RULE,
        companyId,
        calculationRuleId
    };
};
export const createNotificationTargetGroup = (
    companyId,
    departmentId,
    targets
) => ({
    type: types.REQUEST_CREATE_NOTIFICATION_TARGET_GROUP,
    companyId,
    payload: {
        departmentId,
        targets,
        notificationType: notificationActions.PAYOUT_REQUESTED
    }
});
