import React, { Component, Fragment } from "react";
import * as utils from "../../../utils/index";
import CardHeader from "../../../components/layout/card/CardHeader";
import Card from "../../../components/layout/card/Card";
import CardForm from "../../../components/layout/card/CardForm";
import InfoListButton from "components/buttons/InfoListButton";
import * as _ from "lodash";
import { userGroups } from "../../../actionTypes";
import AddIcon from "../../../common/icons/AddIcon";
import PropTypes from "prop-types";
import { getFullTimeEquivalentHistory } from "./selectors";
import * as userSelectors from "../../../selectors/user";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {
    createFullTimeEquivalent,
    updateFullTimeEquivalent
} from "../../modals/actions";
import RoundIconButton from "../../../common/forms/buttons/RoundIconButton";
import { componentDescriptions } from "../../../constants/strings";
import Flex from "../../../components/layout/flex/Flex";
import InfoTag from "../../../common/InfoTag";

export class FullTimeEquivalent extends Component {
    renderItem = (label, item) => {
        return (
            <InfoListButton
                label={label}
                onClick={() =>
                    this.props.updateFullTimeEquivalent(
                        this.props.currentUser.id,
                        item.id
                    )
                }
                key={item.id}
                disabled={this.props.signedInUser.userGroup === userGroups.USER}
                items={[
                    {
                        label: "Fra",
                        value: utils.formatDate(item.fromDate)
                    },
                    {
                        label: "Prosent",
                        value: _.isNumber(item.value) ? item.value + "%" : ""
                    }
                ]}
            />
        );
    };

    renderHistory = history => {
        if (history.length === 0) {
            return null;
        }
        return _.map(history, (item, i) => {
            return this.renderItem(i === 0 ? "Tidligere" : undefined, item);
        });
    };

    render() {
        const { fullTimeEquivalentHistory, signedInUser } = this.props;

        return (
            <Card>
                <CardHeader
                    title={"Stillingsprosent"}
                    icon={
                        <Flex>
                            <InfoTag
                                description={
                                    componentDescriptions.userDetails
                                        .fullTimeEquivalent
                                }
                                darkBackground
                            />
                            {signedInUser.userGroup !== userGroups.USER ? (
                                <RoundIconButton
                                    onClick={() =>
                                        this.props.createFullTimeEquivalent(
                                            this.props.currentUser.id
                                        )
                                    }
                                    darkBackground
                                    icon={<AddIcon />}
                                    title={"Legg til stillingsprosent"}
                                />
                            ) : null}
                        </Flex>
                    }
                />
                <CardForm>
                    {this.renderItem(
                        "Nåværende",
                        fullTimeEquivalentHistory[0] || {}
                    )}
                    {fullTimeEquivalentHistory.length > 0
                        ? this.renderHistory(
                              fullTimeEquivalentHistory.slice(
                                  1,
                                  fullTimeEquivalentHistory.length
                              )
                          )
                        : null}
                </CardForm>
            </Card>
        );
    }
}

FullTimeEquivalent.propTypes = {
    currentUser: PropTypes.object.isRequired,
    signedInUser: PropTypes.object.isRequired,
    fullTimeEquivalentHistory: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateFullTimeEquivalent: PropTypes.func.isRequired,
    createFullTimeEquivalent: PropTypes.func.isRequired
};

export const mapStateToProps = (state, props) => {
    return {
        fullTimeEquivalentHistory: getFullTimeEquivalentHistory(state, props),
        signedInUser: userSelectors.getSignedInUser(state, props),
        currentUser: userSelectors.getCurrentUser(state, props)
    };
};
export default withRouter(
    connect(mapStateToProps, {
        updateFullTimeEquivalent,
        createFullTimeEquivalent
    })(FullTimeEquivalent)
);
