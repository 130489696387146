import React, { PureComponent } from "react";
import PeoplePickerPopup, { PeoplePickerPopupProps } from "./PeoplePickerPopup";
import AvatarList from "../../people/AvatarList";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    root: {
        width: "100%"
    },
    target: {
        backgroundColor: "transparent"
    }
});

class CellPeoplePicker extends PureComponent {
    render() {
        const { classes, people, label, ...other } = this.props;

        return (
            <PeoplePickerPopup
                people={people}
                label={"People"}
                target={<AvatarList className={classes.target} />}
                {...other}
            />
        );
    }
}

CellPeoplePicker.propTypes = PeoplePickerPopupProps;

CellPeoplePicker.defaultProps = {
    label: "People"
};

export default withStyles(styles)(CellPeoplePicker);
