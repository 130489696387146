import React from "react";
import Profile from "./Profile";
import Settings from "./Settings";
import ContentPaper from "../../../components/layout/paper/ContentPaper";
import FullTimeEquivalent from "./FullTimeEquivalent";
import Grid from "@material-ui/core/Grid";
import DefaultErrorBoundry from "common/errors/DefaultErrorBoundry";
import ProfileHeader from "./ProfileHeader";
import PaperScrollContainer from "../../../components/layout/paper/PaperScrollContainer";

const ProfileContent = props => {
    return (
        <ContentPaper>
            <ProfileHeader />
            <PaperScrollContainer>
                <Grid container spacing={24}>
                    <Grid item xs={24} sm={12} md={6} lg={3}>
                        <DefaultErrorBoundry>
                            <Profile />
                        </DefaultErrorBoundry>
                    </Grid>
                    <Grid item xs={24} sm={12} md={6} lg={3}>
                        <DefaultErrorBoundry>
                            <Settings />
                        </DefaultErrorBoundry>
                    </Grid>
                    <Grid item xs={24} sm={12} md={6} lg={3}>
                        <DefaultErrorBoundry>
                            <FullTimeEquivalent />
                        </DefaultErrorBoundry>
                    </Grid>
                </Grid>
            </PaperScrollContainer>
        </ContentPaper>
    );
};

export default ProfileContent;
