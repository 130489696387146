import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 25 25">
            <circle cx="12.5" cy="12.5" r="12.5" />
        </SvgIcon>
    );
}
