import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 7 10.5">
            <path d="M5.75.32.1,9.47a.67.67,0,0,0,.58,1,.66.66,0,0,0,.57-.32L6.9,1A.67.67,0,0,0,6.68.1.67.67,0,0,0,5.75.32Z" />
        </SvgIcon>
    );
}
