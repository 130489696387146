import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Card from "../../../components/layout/card/Card";
import CardHeader from "../../../components/layout/card/CardHeader";
import CardForm from "../../../components/layout/card/CardForm";
import SwitchInput from "../../../common/forms/SwitchInput";
import { componentDescriptions } from "../../../constants/strings";
import { updateCompanyRules } from "../../../actions/company";
import ButtonGroup from "../../../common/forms/buttons/HiddenButtonGroup";
import Button from "../../../common/forms/buttons/Button";
import * as _ from "lodash";

class CalculationTypeSettings extends Component {
    state = {
        settings: {
            subtractTimeOffFromOvertime: false,
            accumulateOvertimeAdditional: false
        },
        hasInitialStateReset: false
    };
    componentDidMount() {
        if (this.props.company) {
            this.resetStateSettings();
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.company !== this.props.company) {
            this.resetStateSettings();
        }
    }

    resetStateSettings = () => {
        const company = this.props.company;
        this.setState({
            settings: {
                subtractTimeOffFromOvertime:
                    company.subtractTimeOffFromOvertime,
                accumulateOvertimeAdditional:
                    company.accumulateOvertimeAdditional
            },
            hasInitialStateReset: true
        });
    };

    filterUnchangedValues = () => {
        const updatedValues = this.findUpdatedValues();
        const { settings } = this.state;
        const returnValue = {};
        _.forEach(updatedValues, (val, key) => {
            if (val === true) {
                returnValue[key] = settings[key];
            }
        });
        return returnValue;
    };
    findUpdatedValues = () => {
        const { settings } = this.state;
        const { company } = this.props;

        return {
            subtractTimeOffFromOvertime:
                company.subtractTimeOffFromOvertime !==
                settings.subtractTimeOffFromOvertime,
            accumulateOvertimeAdditional:
                company.accumulateOvertimeAdditional !==
                settings.accumulateOvertimeAdditional
        };
    };
    hasInputChanged = () => {
        return _.values(this.findUpdatedValues()).indexOf(true) >= 0;
    };
    onSubmit = () => {
        const { company } = this.props;

        const updatedValues = this.filterUnchangedValues();

        this.props.updateCompanyRules(
            company.id,
            company.ruleId,
            updatedValues
        );
    };
    onCancel = e => {
        e.preventDefault();
        this.resetStateSettings();
    };

    handleInputChange = inputName => e => {
        this.setState({
            settings: {
                ...this.state.settings,
                [inputName]: !this.state.settings[inputName]
            }
        });
    };
    render() {
        const { settings } = this.state;

        const canSubmit =
            this.hasInputChanged() && this.state.hasInitialStateReset;

        return (
            <Card>
                <CardHeader title={"Beregninger"} />
                <CardForm>
                    <SwitchInput
                        value={settings.subtractTimeOffFromOvertime}
                        onChange={this.handleInputChange(
                            "subtractTimeOffFromOvertime"
                        )}
                        label={"Avspaseringstrekk"}
                        description={
                            componentDescriptions.logic
                                .subtractTimeOffFromOvertime
                        }
                    />
                    <SwitchInput
                        value={settings.accumulateOvertimeAdditional}
                        onChange={this.handleInputChange(
                            "accumulateOvertimeAdditional"
                        )}
                        label={"Akkumuler tillegg"}
                        description={
                            componentDescriptions.logic.accumulateAddition
                        }
                    />
                    <ButtonGroup show={canSubmit}>
                        <Button variant={"secondary"} onClick={this.onCancel}>
                            {"Forkast"}
                        </Button>
                        <Button
                            variant={"primary"}
                            type={"submit"}
                            onClick={this.onSubmit}
                        >
                            {"Lagre"}
                        </Button>
                    </ButtonGroup>
                </CardForm>
            </Card>
        );
    }
}

const mapDispatchToProps = ({ companies }, { match }) => ({
    company: companies[match.params.companyId]
});

export default withRouter(
    connect(mapDispatchToProps, { updateCompanyRules })(CalculationTypeSettings)
);
