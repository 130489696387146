import React from "react";

import ContentPaper from "../../../components/layout/paper/ContentPaper";
import PrivacyHeader from "./PrivacyHeader";
import TextContent from "./TextContent";
import PrivacyAndCookie from "./PrivacyAndCookie";
import TermsAndConditions from "./TermsAndConditions";
import AcceptableUse from "./AcceptableUse";
import { connect } from "react-redux";
import * as selectors from "./selectors";

const Component = {
    0: PrivacyAndCookie,
    1: TermsAndConditions,
    2: AcceptableUse
};
const Privacy = props => {
    const CurrentComponent = Component[props.selectedPrivacyTab];
    return (
        <ContentPaper>
            <PrivacyHeader />
            <TextContent>
                <CurrentComponent />
            </TextContent>
        </ContentPaper>
    );
};

const mapStateToProps = (state, props) => {
    return {
        selectedPrivacyTab: selectors.getSelectedTab(state, props)
    };
};

export default connect(mapStateToProps)(Privacy);
