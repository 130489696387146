import * as actionTypes from "../actionTypes";
import { all, put, call, take, fork } from "redux-saga/effects";
import { analytics } from "../utils/strings";
import { takeFirst } from "./helpers";
import moment from "moment";
import ReactGA from "react-ga";
import { IntercomAPI } from "../containers/globals/Intercom";
import * as _ from "lodash";
/* eslint-disable no-constant-condition */

export function* openTableRowTracker(action) {
    if (!action || !action.payload) return;
    yield put({
        type: actionTypes.TRACK_EVENT,
        payload: {
            category: analytics.categories.DASHBOARD,
            action: analytics.actions.OPEN_TABLE_ROW,
            label: `Y: ${action.payload.year}. M: ${action.payload.month}`
        }
    });
}

export function* setUserPayoutTracker(action) {
    if (!action || !action.payload) return;
    const issuedFor = moment(action.payload.issuedForMonth);
    yield put({
        type: actionTypes.TRACK_EVENT,
        payload: {
            category: analytics.categories.USER_DASHBOARD,
            action: analytics.actions.SET_USER_PAYOUT,
            label: `Y: ${issuedFor.year()}. M: ${issuedFor.month()}`,
            value: action.payload.amount
        }
    });

    if (action.notificationRef) {
        yield put({
            type: actionTypes.TRACK_EVENT,
            payload: {
                category: analytics.categories.USER_DASHBOARD,
                action: analytics.actions.SET_USER_PAYOUT_FROM_NOT,
                label: `Y: ${issuedFor.year()}. M: ${issuedFor.month()}`,
                value: action.amount
            }
        });
    }
}

export function* requestedUserPayoutTracker(action) {
    if (!action || !action.payload || !action.payload.issuedForMonth) return;
    const issuedFor = moment(action.payload.issuedForMonth);
    yield put({
        type: actionTypes.TRACK_EVENT,
        payload: {
            category: analytics.categories.USER_DASHBOARD,
            action: analytics.actions.REQUEST_USER_PAYOUT,
            label: `Y: ${issuedFor.year()}. M: ${issuedFor.month()}`,
            value: action.amount
        }
    });
}

//WORKERS

export function* trackEvent(reduxAction) {
    try {
        const {
            category,
            action,
            value,
            label,
            nonInteraction = false
        } = reduxAction.payload;

        yield call(ReactGA.event, {
            category,
            action,
            label,
            nonInteraction,
            value
        });
    } catch (error) {
        console.log("Error", error);
    }
}

export function* setAuth(action) {
    try {
        if (action.payload && action.payload.user && action.payload.user.id) {
            yield call(ReactGA.set, { userId: action.payload.user.id });

            yield call(IntercomAPI, "update", {
                user_id: action.payload.user.id,
                email: action.payload.user.email,
                company_id: action.payload.user.companyId,
                name: `${action.payload.user.firstName} ${
                    action.payload.user.lastName
                }`.trim()
            });
        }
    } catch (error) {
        console.log("Error", error);
    }
}

const formatUserGroup = userGroup => {
    if (userGroup === actionTypes.userGroups.USER) {
        return "User";
    } else if (userGroup === actionTypes.userGroups.DEPARTMENT_LEADER) {
        return "Department leader";
    } else if (userGroup === actionTypes.userGroups.ADMIN) {
        return "Administrator";
    }
};
export function* setIntercomValues(user, company) {
    yield call(IntercomAPI, "update", {
        user_id: user.id,
        email: user.email,
        name: `${user.firstName} ${user.lastName}`.trim(),
        user_group: formatUserGroup(user.userGroup),
        company: {
            id: user.companyId,
            name: company.name
        }
    });
}

//WATCHERS
const wait = ms =>
    new Promise(resolve => {
        setTimeout(() => resolve(), ms);
    });

export function* trackEventThrottledWatcher() {
    while (true) {
        const action = yield take(actionTypes.TRACK_EVENT_THROTTLED);
        yield call(trackEvent, action);
        yield wait(5000);
    }
}

export function* trackIntercomUser() {
    const authAction = yield take(actionTypes.SET_AUTH);
    const companyAction = yield take(actionTypes.SET_COMPANIES);

    const user = authAction.payload.user;
    const companyList = _.values(companyAction.payload);
    if (companyList.length === 1) {
        yield call(setIntercomValues, user, companyList[0]);
        yield take(actionTypes.REQUEST_LOG_OUT);
        yield call(IntercomAPI, "shutdown");
    }
}

//ROOT
export default function* rootSaga() {
    yield all([
        takeFirst(
            actionTypes.REQUEST_TIMEBANK_WEEK_BY_MONTH,
            openTableRowTracker
        ),
        takeFirst(actionTypes.REQUEST_SET_USER_PAYOUT, setUserPayoutTracker),
        takeFirst(actionTypes.USER_REQUEST_PAYOUT, requestedUserPayoutTracker),
        takeFirst(actionTypes.TRACK_EVENT, trackEvent),
        takeFirst(actionTypes.SET_AUTH, setAuth),
        fork(trackEventThrottledWatcher),
        fork(trackIntercomUser)
    ]);
}
