import React, { Component } from "react";
import SimpleDialog from "common/dialogs/SimpleDialog";
import ButtonGroup from "common/dialogs/dialogContent/DialogButtonGroup";
import * as types from "../../actionTypes";
import PeoplePicker from "components/pickers/people/InputPeoplePicker";
import InputField from "common/forms/text-fields/InputField";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as modalActions from "./actions";
import * as userSelectors from "../../selectors/user";
import * as companySelectors from "../../selectors/company";
import { withRouter } from "react-router-dom";

class CreateDepartmentModal extends Component {
    state = {
        department: {
            members: [],
            name: "",
            active: true
        }
    };

    handleInputChange = e => {
        this.setState({
            department: {
                ...this.state.department,
                [e.target.name]: e.target.value
            }
        });
    };
    handlePeopleChange = members => {
        this.setState({
            department: {
                ...this.state.department,
                members
            }
        });
    };

    onConfirm = () => {
        this.props.createDepartment(
            this.props.companyId,
            this.state.department
        );
        this.props.onClose();
    };

    render() {
        const { name, members } = this.state.department;
        return (
            <SimpleDialog
                {...this.props.modalProps}
                open={true}
                buttonGroup={
                    <ButtonGroup
                        onConfirm={this.onConfirm}
                        onCancel={this.props.onClose}
                    />
                }
            >
                <InputField
                    name={"name"}
                    label={"Name"}
                    onChange={this.handleInputChange}
                    value={name}
                    fullWidth
                />
                <PeoplePicker
                    selected={members}
                    people={this.props.people}
                    onChange={this.handlePeopleChange}
                    label={"Members"}
                    preventOverflow={false}
                    placement={"bottom-start"}
                />
            </SimpleDialog>
        );
    }
}

CreateDepartmentModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    modalProps: PropTypes.object,
    createDepartment: PropTypes.func.isRequired
};
CreateDepartmentModal.defaultProps = {
    modalProps: {},
    people: []
};

const mapStateToProps = (state, props) => ({
    people: userSelectors.getActiveUsers(state, props),
    companyId: companySelectors.getCompanyId(state, props)
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        createDepartment: (companyId, department) => {
            dispatch({
                type: types.REQUEST_CREATE_DEPARTMENT,
                payload: { department, companyId }
            });
            dispatch(modalActions.closeModal());
        },
        onClose: () => dispatch(modalActions.closeModal())
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CreateDepartmentModal)
);
