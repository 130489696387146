import React, { Component } from "react";
import AppContainer from "./AppContainer";
import rootReducer from "./reducers";
import "./assets/css/index.css";
import { createStore, applyMiddleware } from "redux";
import { Router, Route } from "react-router-dom";
import { Provider } from "react-redux";
import logger from "redux-logger";
import { MuiThemeProvider } from "@material-ui/core/styles";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";
import ReactGA from "react-ga";
import createBrowserHistory from "history/createBrowserHistory";
import { createTheme } from "./utils/styles";

/**
 * This component mounts all the application with providers and middleware.
 * It also listenes to browser history changes and dispatches message to analytics.
 */

const sagaMiddleware = createSagaMiddleware();

const allMiddleware = [sagaMiddleware];

if (process.env.NODE_ENV === "development") {
    allMiddleware.push(logger);
}

const store = createStore(rootReducer, applyMiddleware(...allMiddleware));

sagaMiddleware.run(rootSaga);

export const browserHistory = createBrowserHistory();

browserHistory.listen(logPageView);

function logPageView() {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
}

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <MuiThemeProvider theme={createTheme()}>
                    <Router history={browserHistory}>
                        <Route path="/" component={AppContainer} />
                    </Router>
                </MuiThemeProvider>
            </Provider>
        );
    }
}

export default App;
