import classNames from "classnames";
import { map } from "lodash";
import { FormControl, Paper, Typography } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import Button from "../forms/CustomButton";
import InputField from "../forms/text-fields/InputField";
import Select from "../forms/Select";
import LockIcon from "../icons/LockIcon";
import LogoWithText from "../icons/LogoWithText";
import MailIcon from "../icons/MailIcon";

const styles = theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.C_AW
    },
    paper: {
        padding: "40px",
        width: "300px",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "space-around",
        ...theme.gridItemBorder
    },
    logoIcon: {
        display: "inline-block",
        height: "40px",
        width: "75px",
        userSelect: "none",
        flexShrink: 0,
        transition: theme.transitions.create("fill", {
            duration: theme.transitions.duration.shorter
        }),
        marginBottom: "10px"
    },
    button: {
        width: "100%"
    },
    form: {
        width: "100%",
        height: "80%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    formControl: {
        width: "100%",
        height: "80%"
    },
    item: {
        marginBottom: "20px"
    },

    splitText: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    horizional: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "5px 0"
    },
    bottomContainer: {
        display: "flex",
        alignItems: "center",
        margin: "0 1rem",
        justifyContent: "center",
        fontWeight: "regular"
    },
    coloredText: {
        color: theme.palette.C_LB,
        marginLeft: "0.3rem"
    },
    unfocusedText: {
        color: theme.palette.C_DB60
    },
    checkbox: {
        width: "18px",
        marginRight: "10px"
    },
    inputColumn: {
        display: "flex",
        marginTop: "20px",
        justifyContent: "space-between"
    },
    label: {
        color: theme.palette.C_DB60,
        marginTop: "5px"
    },
    inputIcon: {
        color: theme.palette.C_DB40,
        width: "15px",
        height: "15px"
    },
    header: {
        marginBottom: "20px"
    },
    select: {
        borderWidth: "1px",
        marginTop: "10px"
    },
    innerSelect: {
        paddingLeft: "10px"
    },
    selectForm: {
        marginTop: "20px"
    },
    selectLabel: {
        top: "-22px"
    },
    progress: {
        top: 0,
        position: "absolute",
        left: 0,
        width: "100%"
    },
    credentials: {
        marginTop: "20px"
    },
    password: {
        margin: "20px 0 30px 0"
    },
    submit: {
        marginTop: "30px"
    }
});
class Login extends Component {
    state = {
        windowHeight: window.innerHeight
    };

    componentDidMount() {
        window.addEventListener("resize", this.resize);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
    }

    resize = () => {
        this.setState({ windowHeight: window.innerHeight });
    };

    render() {
        const {
            classes,
            onSubmit,
            emailRef,
            normalHoursRef,
            overtimeAdditionalRef,
            vacationDaysRef,
            disabledConfirm,
            selectOptions,
            selectedOption,
            onChangeSelect,
            onChange,
            inputs,
            isFetching,
            passwordRef
        } = this.props;

        const menuItems = map(selectOptions, (item, i) => {
            return (
                <MenuItem value={i} key={i}>
                    {item}
                </MenuItem>
            );
        });

        return (
            <div
                className={classes.root}
                style={{ height: this.state.windowHeight }}
            >
                <Paper className={classes.paper} elevation={0}>
                    {isFetching && (
                        <LinearProgress className={classes.progress} />
                    )}
                    <div className={classes.header}>
                        <LogoWithText className={classes.logoIcon} />
                        <div>
                            <Typography variant="display2">
                                {"Opprett konto"}
                            </Typography>
                            <Typography
                                variant="display1"
                                className={classes.label}
                            >
                                {"Bruk din 24SevenOffice konto"}
                            </Typography>
                        </div>
                    </div>
                    <form onSubmit={onSubmit} className={classes.form}>
                        <FormControl>
                            <InputField
                                inputRef={emailRef}
                                autoComplete={"email"}
                                name={"email"}
                                type={"email"}
                                formControlClassName={classes.credentials}
                                rightIcon={
                                    <MailIcon className={classes.inputIcon} />
                                }
                                placeholder={"Epost i 24SevenOffice"}
                                onChange={e => onChange("email", false, e)}
                                value={inputs["email"]}
                                smallIcon
                                data-test-id={"emailField"}
                            />
                            <InputField
                                inputRef={passwordRef}
                                autoComplete={"password"}
                                name={"password"}
                                type={"password"}
                                placeholder={"Passord i 24SevenOffice"}
                                formControlClassName={classes.password}
                                rightIcon={
                                    <LockIcon className={classes.inputIcon} />
                                }
                                onChange={e => onChange("password", false, e)}
                                value={inputs["password"]}
                                smallIcon
                                data-test-id={"passwordField"}
                            />
                            <InputField
                                className={classNames(classes.item)}
                                label="Normaltid pr. dag"
                                inputRef={normalHoursRef}
                                numeric
                                subLabel="timer"
                                NumberFormatProps={{
                                    isAllowed: ({ floatValue }) =>
                                        !floatValue ||
                                        (floatValue >= 0 && floatValue <= 24)
                                }}
                                onChange={value =>
                                    onChange("normalHours", true, value)
                                }
                                value={inputs["normalHours"]}
                            />

                            <InputField
                                className={classNames(classes.item)}
                                label="Overtidstillegg"
                                inputRef={overtimeAdditionalRef}
                                numeric
                                subLabel="prosent"
                                NumberFormatProps={{
                                    isAllowed: ({ floatValue }) =>
                                        !floatValue ||
                                        (floatValue >= 0 && floatValue <= 100)
                                }}
                                onChange={value =>
                                    onChange("overtimeAdditional", true, value)
                                }
                                value={inputs["overtimeAdditional"]}
                            />
                            <InputField
                                className={classNames(classes.item)}
                                label="Feriedager"
                                inputRef={vacationDaysRef}
                                numeric
                                subLabel="dager"
                                onChange={value =>
                                    onChange("vacationDays", true, value)
                                }
                                value={inputs["vacationDays"]}
                                NumberFormatProps={{
                                    isAllowed: ({ floatValue }) =>
                                        !floatValue ||
                                        (floatValue >= 0 && floatValue <= 365)
                                }}
                            />
                            <Select
                                label={"Overtidsberegning"}
                                options={selectOptions}
                                value={selectedOption}
                                onChange={onChangeSelect}
                                fullWidth
                            >
                                {menuItems}
                            </Select>

                            <Button
                                onClick={onSubmit}
                                className={classes.submit}
                                value="Opprett konto og prøv gratis i 14 dager"
                                disabled={disabledConfirm}
                                browserType="submit"
                            />
                            {false && (
                                <div className={classes.bottomContainer}>
                                    <Typography
                                        variant="display1"
                                        className={classes.coloredText}
                                    >
                                        {"Jeg har ikke bruker i 24SevenOffice"}
                                    </Typography>
                                </div>
                            )}
                        </FormControl>
                    </form>
                </Paper>
            </div>
        );
    }
}

export default withStyles(styles)(Login);
