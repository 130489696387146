import React, { PureComponent } from "react";
import PeoplePickerPopup, { PeoplePickerPopupProps } from "./PeoplePickerPopup";
import PeopleCount from "./PeopleCount";
import AvatarList from "../../people/AvatarList";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    root: {
        width: "100%"
    },
    target: {
        paddingTop: "7px",
        borderTop: `solid 1px ${theme.palette.C_DB10}`
    },
    avatar: {
        marginRight: "10px"
    }
});

class InputPeoplePicker extends PureComponent {
    render() {
        const {
            classes,
            people,
            selected,
            label,
            className,
            ...other
        } = this.props;
        return (
            <div className={className}>
                <PeopleCount label={label} count={selected.length} />
                <PeoplePickerPopup
                    {...other}
                    people={people}
                    label={"Personer"}
                    target={
                        <AvatarList
                            className={classes.target}
                            avatarClassName={classes.avatar}
                            avatarWidth={70}
                        />
                    }
                    selected={selected}
                />
            </div>
        );
    }
}

InputPeoplePicker.propTypes = PeoplePickerPopupProps;

InputPeoplePicker.defaultProps = {
    label: "Members"
};

export default withStyles(styles)(InputPeoplePicker);
