import { filter, isEmpty, forEach, map } from "lodash";

import zipcelx from "../tmp/zipcelx/zipcelx";

/*
    Takes inn an array of objects with keys. If key in selected it will be included in csv.
    If selected is an empty object. Full table will be exported
 */

const formatValue = (val, type, includeZeros) => {
    if (val === undefined) return "";
    if (val === 0 && includeZeros) return 0;
    if (!val) return "";
    return val;
};

export const formatTimebankCSV = (table, columns) => {
    const newCols = map(columns, col => {
        if (col.id === "comment") {
            return {
                ...col,
                label: "Kommentar"
            };
        }
        return col;
    });
    const newTable = map(table, row => {
        return {
            ...row,
            comment: row.commentValue
        };
    });

    return [newTable, newCols];
};

export const createCSV = (table, columns, selected = {}) => {
    const selectedTable = !isEmpty(selected)
        ? filter(table, (val, key) => selected[val.key])
        : table;

    let csvDoc = "";

    //Create headers
    forEach(columns, (col, i) => {
        csvDoc += col.label;
        if (i !== columns.length - 1) csvDoc += ",";
    });

    csvDoc += "\r\n";

    forEach(selectedTable, row => {
        forEach(columns, (col, i) => {
            csvDoc += formatValue(row[col.id], col.type);
            if (i !== columns.length - 1) csvDoc += ",";
        });
        csvDoc += "\r\n";
    });
    return csvDoc;
};

export const createXLSX = (content, cols, filename, includeZeros = false) => {
    if (!content || !cols) return;

    //Create header

    const data = [];

    const header = map(cols, col => {
        return {
            value: col.label,
            type: "string"
        };
    });

    data.push(header);

    forEach(content, row => {
        const r = [];
        forEach(cols, col => {
            const value = formatValue(row[col.id], col.type, includeZeros);
            r.push({
                value,
                type:
                    col.type === "numeric" && !isNaN(parseInt(value))
                        ? "number"
                        : "string"
            });
        });
        data.push(r);
    });

    const config = {
        filename,
        sheet: {
            data
        }
    };

    zipcelx(config);
};

export const downloadCSV = (CSVContent, filename) => {
    if (CSVContent == null) return;

    if (!CSVContent.match(/^data:text\/csv/i)) {
        CSVContent = "data:text/csv;charset=windows-1252," + CSVContent;
    }
    let data = encodeURI(CSVContent);

    const link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("download", filename + ".csv");
    link.click();
};
