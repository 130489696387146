import React from "react";
import PaperHeader from "../../../components/layout/paper/PaperHeader";
import PaperHeaderTitle from "../../../components/layout/paper/PaperHeaderTitle";
import { componentDescriptions } from "../../../constants/strings";
import * as selectors from "./selectors";
import * as types from "actionTypes";
import { connect } from "react-redux";
import Slider from "../../../common/forms/Slider";
import Button from "../../../common/forms/buttons/Button";
import {
    createCalculationRule,
    createNotificationTargetGroup
} from "containers/modals/actions";
const tabNames = ["Visning", "Logikk", "Regler", "Varsler"];
const tabIds = ["displaySettings", "logic", "rules", "notificationTargetGroup"];

export function ProfileHeader(props) {
    const renderButton = selectedTab => {
        if (selectedTab === 2) {
            return (
                <Button
                    variant={"secondary"}
                    onClick={() => props.onCreateRule(props.companyId)}
                >
                    {"Legg til regel"}
                </Button>
            );
        } else if (selectedTab === 3) {
            return (
                <Button
                    variant={"secondary"}
                    onClick={() => props.onCreateNotificationTargetGroup(props.companyId)}
                >
                    {"Legg til varsel"}
                </Button>
            );
        }
        return null;
    };
    return (
        <PaperHeader
            description={componentDescriptions.setup[tabIds[props.selectedTab]]}
            title={
                <PaperHeaderTitle
                    primary={tabNames[props.selectedTab]}
                    secondary={`for ${props.company.name}`}
                />
            }
        >
            {renderButton(props.selectedTab)}
            <Slider
                tabs={tabNames}
                selectedTab={props.selectedTab}
                onSelectTab={props.setTab}
                background={"paper"}
            />
        </PaperHeader>
    );
}

const mapStateToProps = (state, props) => ({
    selectedTab: selectors.getSelectedTab(state),
    companyId: props.match.params.companyId
});
const mapDispatchToProps = {
    setTab: tab => ({ type: types.SET_ACTIVE_SETUP_TAB, payload: tab }),
    onCreateRule: createCalculationRule,
    onCreateNotificationTargetGroup: createNotificationTargetGroup
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileHeader);
