import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core";
import MoreIcon from "common/icons/MoreIcon";
import AddIcon from "common/icons/AddIcon";
import ButtonBase from "@material-ui/core/ButtonBase";

const styles = theme => ({
    avatar: {
        backgroundColor: "transparent",
        border: `solid 1px ${theme.palette.C_DB40}`,
        borderRadius: "50%",
        width: "26px",
        height: "26px",
        "&:hover": {
            "& $icon": {
                color: theme.palette.C_LB
            },
            "& $addIcon": {
                color: theme.palette.C_LB
            }
        }
    },
    icon: {
        width: "15px",
        color: theme.palette.C_DB40
    },
    addIcon: {
        width: "10px",
        color: theme.palette.C_DB40
    }
});

class MoreAvatar extends PureComponent {
    render() {
        const { classes, small, showPluss, ...other } = this.props;
        return (
            <ButtonBase disableRipple className={classes.avatar} {...other}>
                {showPluss ? (
                    <AddIcon className={classes.addIcon} />
                ) : (
                    <MoreIcon className={classes.icon} />
                )}
            </ButtonBase>
        );
    }
}

export default withStyles(styles)(MoreAvatar);
