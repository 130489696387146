import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Fade from "@material-ui/core/Fade";
import LoaderComponent from "components/common/Loader";

class Loader extends PureComponent {
    handleExit = () => {};
    render() {
        const { loader } = this.props;
        return (
            <Fade in={loader.isActive} timeout={500}>
                <LoaderComponent
                    displayText={loader.displayText}
                    isActive={loader.isActive}
                />
            </Fade>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        loader: state.modal.loader
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {};
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Loader);
