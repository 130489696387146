import {
    REQUEST_UPDATE_NOTIFICATION,
    REQUEST_ERROR,
    IS_FETCHING,
    FETCH_SUCCESS,
    REQUEST_USER_NOTIFICATION
} from "../actionTypes";
import * as UserApi from "../api/users";
import { fork, call, all, put, take } from "redux-saga/effects";
import { takeEvery } from "redux-saga/effects";

//WORKERS

export function* updateNotification(action) {
    try {
        const { userId, notificationId, payload } = action;
        yield put({ type: IS_FETCHING });

        yield call(UserApi.updateNotification, notificationId, payload);
        yield put({ type: FETCH_SUCCESS });

        yield put({ type: REQUEST_USER_NOTIFICATION, userId });
    } catch (error) {
        yield put({ type: FETCH_SUCCESS });
        yield put({ type: REQUEST_ERROR, payload: error });
    }
}

//WATCHERS

export function* updateNotificationWatcher() {
    yield takeEvery(REQUEST_UPDATE_NOTIFICATION, updateNotification);
}

//ROOT
export default function* rootSaga() {
    yield all([fork(updateNotificationWatcher)]);
}
