import * as _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import * as actions from "../../../actionTypes";
import Table from "../../../common/table/Table";
import TableHeader from "../../../common/table/TableHeader";
import TableWrapper from "../../dashboard/TableWrapper";
import * as selectors from "./selectors";

const styles = theme => ({
    name: {
        "&:hover": {
            color: theme.palette.C_LB,
            textDecoration: "underline"
        }
    }
});
const tableBodyStyle = { height: "225px" };

export class RuleSettings extends Component {
    constructor(props) {
        super(props);
        this.columnData = [
            {
                label: "Beskrivelse",
                type: "string",
                id: "name",
                className: props.classes.name,
                width: "40%"
            },
            { label: "Dato(er)", type: "string", id: "date", width: "20%" },
            { label: "Verdi", type: "string", id: "value", width: "20%" },
            {
                label: "Aktiv",
                type: "switch",
                id: "active",
                onChange: this.onCalculationRuleClick,
                width: "20%"
            }
        ];
    }

    componentDidMount = () => {
        this.props.fetchCalculationRules();
    };

    onCalculationRuleClick = (item, columnId) => {
        const { calculationRules } = this.props;

        const matchingRule = _.find(
            calculationRules,
            rule => rule.id === item.id
        );
        if (!matchingRule) return;

        this.props.setCalculationRuleActive(
            matchingRule.id,
            !matchingRule.active
        );
    };

    render() {
        const { calculationRules } = this.props;
        return (
            <Fragment>
                <TableWrapper>
                    <TableHeader title="Regelmotor (selskap)" />
                    <Table
                        columns={this.columnData}
                        bodyStyle={tableBodyStyle}
                        data={calculationRules}
                        placeholder={"Ingen regler ..."}
                    />
                </TableWrapper>
            </Fragment>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        calculationRules: selectors.getCompanyCalculationRules(state, props)
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const { companyId, departmentKey } = ownProps.match.params;
    return {
        setCalculationRuleActive: (calculationRuleKey, isActive) => {
            dispatch({
                type: actions.REQUEST_UPDATE_CALCULATION_RULE_ACTIVE,
                companyId,
                calculationRuleKey,
                departmentKey,
                payload: isActive
            });
        },
        fetchCalculationRules: () => {
            dispatch({
                type: actions.REQUEST_CALCULATION_RULES,
                companyId
            });
        }
    };
};

export default withRouter(
    compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(
        RuleSettings
    )
);
