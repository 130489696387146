import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import LeftPointIcon from "../icons/LeftPointIcon";
import RightPointIcon from "../icons/RightPointIcon";
import Typography from "@material-ui/core/Typography";
import classnames from "classnames";
import { isNumber } from "lodash";
import ButtonBase from "@material-ui/core/ButtonBase";
import moment from "moment";
const styles = theme => ({
    root: {
        display: "flex",
        height: theme.spacing.inputHeight,
        backgroundColor: "white"
    },
    textField: {
        height: "42px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: `0 ${theme.spacing.gridMargin}`,
        borderStyle: "solid",
        borderBottomWidth: theme.spacing.normalStroke,
        borderTopWidth: theme.spacing.normalStroke,
        borderLeftStyle: "none",
        borderRightStyle: "none",
        borderColor: theme.palette.C_DB15
    },
    icon: {
        width: theme.spacing.inputHeight,
        height: theme.spacing.inputHeight,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    leftIcon: {
        borderColor: theme.palette.C_DB15,
        borderWidth: theme.spacing.normalStroke,
        borderStyle: "solid",
        borderBottomLeftRadius: theme.spacing.cornerRadius,
        borderTopLeftRadius: theme.spacing.cornerRadius,
        transition: "all 100ms",
        "&:hover": {
            borderColor: theme.palette.C_DB40
        },
        height: "44px"
    },
    rightIcon: {
        borderColor: theme.palette.C_DB15,
        borderWidth: theme.spacing.normalStroke,
        borderStyle: "solid",
        borderBottomRightRadius: theme.spacing.cornerRadius,
        borderTopRightRadius: theme.spacing.cornerRadius,
        "&:hover": {
            borderColor: theme.palette.C_DB40
        },
        transition: "all 100ms",
        height: "44px"
    },
    arrowIcon: {
        color: theme.palette.C_DB60,
        height: "11px"
    },
    text: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "70px",
        textAlign: "center",
        fontSize: theme.typography.F_S_N,
        fontWeight: 600
    },
    disabled: {
        "&:hover": {
            borderColor: theme.palette.C_DB15
        }
    }
});

const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mai",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Okt",
    "Nov",
    "Des"
];
class MonthPicker extends PureComponent {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        //Dateobject of current date
        year: PropTypes.number.isRequired,
        month: PropTypes.number.isRequired
    };

    static defaultProps = {
        year: moment().year(),
        month: moment().month()
    };

    onChangeNext = () => {
        const { year, month, onChange } = this.props;
        const thisMoment = moment([year, month]).add(1, "M");

        onChange(thisMoment.year(), thisMoment.month());
    };
    onChangePrev = () => {
        const { year, month, onChange } = this.props;
        const thisMoment = moment([year, month]).subtract(1, "M");

        onChange(thisMoment.year(), thisMoment.month());
    };

    formatDate = (year, month) => {
        return `${months[month]} ${year}`;
    };

    render() {
        const { classes, year, month, className } = this.props;

        const currentMoment = moment([year, month]);
        const hasNextMonth = moment(currentMoment)
            .startOf("M")
            .isBefore(moment().subtract(1, "M"));

        const hasPrevMonth =
            currentMoment.year() >= 2018 && currentMoment.month() !== 0;

        const cominedRootClasses = classnames(classes.root, className);

        const leftIconClasses = classnames(classes.icon, classes.leftIcon, {
            [classes.disabled]: !hasPrevMonth
        });
        const rightIconClasses = classnames(classes.icon, classes.rightIcon, {
            [classes.disabled]: !hasNextMonth
        });

        const textValue = this.formatDate(year, month);

        return (
            <div className={cominedRootClasses}>
                {
                    <ButtonBase
                        onClick={this.onChangePrev}
                        className={leftIconClasses}
                        disabled={!hasPrevMonth}
                        disableRipple
                    >
                        <LeftPointIcon className={classes.arrowIcon} />
                    </ButtonBase>
                }
                <div className={classes.textField}>
                    <Typography className={classes.text} variant="body1">
                        {textValue}
                    </Typography>
                </div>
                {
                    <ButtonBase
                        onClick={this.onChangeNext}
                        className={rightIconClasses}
                        disableRipple
                        disabled={!hasNextMonth}
                    >
                        <RightPointIcon className={classes.arrowIcon} />
                    </ButtonBase>
                }
            </div>
        );
    }
}

export default withStyles(styles)(MonthPicker);
