import {
    all,
    call,
    fork,
    put,
    take,
    select,
    takeLatest
} from "redux-saga/effects";
import {
    FETCH_SUCCESS,
    IS_FETCHING,
    REQUEST_AUTH_UPDATE,
    REQUEST_COMPANY,
    REQUEST_LOG_IN,
    REQUEST_LOG_OUT,
    REQUEST_POSSIBLE_CLIENTS,
    REQUEST_UPDATE_CLIENT,
    REQUEST_ERROR,
    SET_AUTH,
    SET_AUTH_ACTION_NEEDED,
    SET_LOGOUT,
    SET_POSSIBLE_USERS,
    REQUEST_SYNCHRONIZE_DATA,
    REQUEST_SYNCHRONIZE_STATUS,
    RESET_STATE,
    NAVIGATE,
    REQUEST_ALL_USERS_SHALLOW
} from "../actionTypes";
import * as UserApi from "../api/users";

//WORKERS

export function* refreshTokens(action) {
    try {
        const authResult = yield call(UserApi.refreshTokens, action.personId);

        const providerToken = action.providerToken
            ? action.providerToken
            : yield select(store => store.auth.providerToken);

        const payload = {
            ...authResult,
            hasValidatedToken: true,
            providerToken
        };

        yield put({ type: SET_AUTH, payload });
        yield put({
            type: REQUEST_COMPANY,
            payload: { companyId: payload.user.companyId }
        });
        yield put({
            type: REQUEST_ALL_USERS_SHALLOW,
            payload: { companyId: payload.user.companyId }
        });
        yield put({
            type: REQUEST_SYNCHRONIZE_STATUS,
            companyId: payload.user.companyId
        });
    } catch (error) {
        yield put({ type: SET_LOGOUT });
    }
}

export function* loginFlow(action) {
    const { email, password, userId } = action.payload;
    try {
        yield put({ type: IS_FETCHING });

        //Issue login request
        const auth = yield call(UserApi.login, email, password, userId);
        //Action needed
        yield put({ type: FETCH_SUCCESS });

        if (auth.status === 1000) {
            return yield put({ type: SET_AUTH_ACTION_NEEDED, payload: auth });
        }
        yield put({ type: SET_AUTH, payload: auth });
        yield put({
            type: REQUEST_COMPANY,
            payload: { companyId: auth.user.companyId }
        });
        yield put({
            type: REQUEST_ALL_USERS_SHALLOW,
            payload: { companyId: auth.user.companyId }
        });
        yield put({
            type: REQUEST_SYNCHRONIZE_DATA,
            companyId: auth.user.companyId,
            userId: auth.user.id
        });
    } catch (error) {
        yield put({ type: FETCH_SUCCESS });

        yield put({ type: REQUEST_ERROR, payload: error });
    }
}

export function* getPossibleUsers(action) {
    try {
        yield put({ type: IS_FETCHING });

        const possibleUsers = yield call(
            UserApi.getPossibleUsers,
            action.personId
        );
        yield put({ type: FETCH_SUCCESS });

        yield put({ type: SET_POSSIBLE_USERS, payload: possibleUsers });
    } catch (error) {
        yield put({ type: FETCH_SUCCESS });
        yield put({ type: REQUEST_ERROR, payload: error });
    }
}

export function* logoutFlow(action) {
    try {
        yield call(UserApi.logout);
        yield put({ type: SET_LOGOUT });
    } catch (error) {
        yield put({ type: REQUEST_ERROR, payload: error });
    }
}
export function* updateClient(action) {
    try {
        yield put({ type: IS_FETCHING });

        yield call(UserApi.updatePerson, action.personId, action.payload);

        const providerToken = yield select(store => store.auth.providerToken);

        yield put({ type: RESET_STATE });

        yield put({
            type: REQUEST_AUTH_UPDATE,
            personId: action.personId,
            providerToken
        });
        yield put({ type: FETCH_SUCCESS });
        yield put({ type: NAVIGATE, payload: { url: "/login" } });
    } catch (error) {
        yield put({ type: FETCH_SUCCESS });
        yield put({ type: REQUEST_ERROR, payload: error });
    }
}
//WATCHERS

export function* refreshTokensWatcher() {
    while (true) {
        const action = yield take(REQUEST_AUTH_UPDATE);
        yield call(refreshTokens, action);
    }
}
export function* authenticationFlowWatcher() {
    yield takeLatest(REQUEST_LOG_IN, loginFlow);
}
export function* logoutWatcher() {
    yield takeLatest(REQUEST_LOG_OUT, logoutFlow);
}
export function* getPossibleUsersWatcher() {
    while (true) {
        const action = yield take(REQUEST_POSSIBLE_CLIENTS);
        yield call(getPossibleUsers, action);
    }
}
export function* updateClientWatcher() {
    while (true) {
        const action = yield take(REQUEST_UPDATE_CLIENT);
        yield call(updateClient, action);
    }
}

export default function* rootSaga() {
    yield all([
        fork(authenticationFlowWatcher),
        fork(refreshTokensWatcher),
        fork(logoutWatcher),
        fork(getPossibleUsersWatcher),
        fork(updateClientWatcher)
    ]);
}
