import * as companySelectors from "../../../selectors/company";
import * as userSelectors from "../../../selectors/user";

import { createSelector } from "reselect";
import * as _ from "lodash";
import { activityStates } from "../../../constants/strings";

export const getJoinedDepartments = createSelector(
    companySelectors.getCompanyDepartmentsWithoutDefault,
    userSelectors.getOrderedUserItems,
    (departments, users) => {
        return _.map(departments, dep => ({
            ...dep,
            members: _.filter(users, user => user.departmentId === dep.id)
        }));
    }
);
