export const timeline = {
    shortenDayWeekFilterLabels: "(max-width: 955px)",
    hideTodayButton: "(max-width: 920px)",
    hideTimerLabel: "(max-width: 830px)",
    hideFlowKeyFigure: "(max-width: 750px)"
};

export const reports = {
    hideSecondaryTitleLabel: "(max-width: 1300px)"
};

export const projects = {
    showOnlyActiveProjectsKeyFigure: "(max-width: 700px)",
    hideSecondaryTitleLabel: "(max-width: 1070px)"
};

export const users = {
    showOnlyTotalKeyFigure: "(max-width: 1100px)",
    hideSecondaryTitleLabel: "(max-width: 1070px)"
};

export const clients = {
    showOnlyActiveClientsKeyFigure: "(max-width: 680px)",
    hideSecondaryTitleLabel: "(max-width: 1070px)"
};
