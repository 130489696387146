import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import DatePicker from "./DatePicker";
import Typography from "common/Typography";
import moment from "moment";
import ButtonBase from "components/buttons/ButtonBase";

const styles = theme => ({
    root: {
        color: theme.palette.C_DB120,

        "&:hover": {
            color: theme.palette.C_LB
        },
        textAlign: "left",
        width: "min-content"
    }
});

class CellDatePicker extends PureComponent {
    render() {
        const { date, classes, isScrolling, ...other } = this.props;

        const text = (
            <Typography color={"inherit"} singleLine>
                {moment(date).format("YYYY-MM-DD")}
            </Typography>
        );
        if (isScrolling) return text;
        return (
            <DatePicker
                closeOnSelect
                target={
                    <ButtonBase className={classes.root}>{text}</ButtonBase>
                }
                date={date}
                flip
                {...other}
            />
        );
    }
}

CellDatePicker.propTypes = {};

export default withStyles(styles)(CellDatePicker);
