import React from "react";
import Typography from "@material-ui/core/Typography";
import ReactGA from "react-ga";
import Flex from "../../components/layout/flex/Flex";

class DefaultErrorBoundary extends React.Component {
    state = {
        hasError: false,
        error: null,
        info: null
    };

    componentDidCatch(error, info) {
        if (process.env.NODE_ENV !== "development") {
            ReactGA.exception({
                description: error.toString(),
                fatal: true
            });
        }
        this.setState({
            hasError: true,
            error: error,
            info: info
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <Flex
                    align={"center"}
                    justify={"center"}
                    style={{ height: "100%", width: "100%" }}
                >
                    <div>
                        <Typography variant="display2">
                            Det har oppstått en feil
                        </Typography>
                        <Typography variant="body1">
                            Forsøk å oppdatere siden, eventuelt prøv igjen
                            senere.
                        </Typography>
                    </div>
                </Flex>
            );
        } else {
            return this.props.children;
        }
    }
}

export default DefaultErrorBoundary;
