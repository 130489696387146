import * as actions from "../actionTypes";
import * as _ from "lodash";
export const initialAppState = {
    requestError: null,
    isFetching: false,
    fetchers: 0,
    confirmedActions: [],
    hasStaleData: false
};
export default function appState(state = initialAppState, action) {
    switch (action.type) {
        case actions.REQUEST_ERROR:
            return {
                ...state,
                requestError: action.payload,
                isFetching: false
            };

        case actions.SET_CONFIRM_ACTION: // Append the action at the end of the array
            return {
                ...state,
                confirmedActions: [...state.confirmedActions, action.payload]
            };
        case actions.REMOVE_CONFIRM_ACTION: // Remove the type from the array
            return {
                ...state,
                confirmedActions: _.filter(
                    state.confirmedActions,
                    a => a.createdAt !== action.payload.createdAt
                )
            };
        case actions.IS_FETCHING:
            return {
                ...state,
                isFetching: true,
                fetchers: state.fetchers + 1
            };
        case actions.FETCH_SUCCESS:
            return {
                ...state,
                isFetching: state.fetchers - 1 > 0,
                fetchers: state.fetchers - 1
            };

        case actions.SET_AUTH:
            localStorage.setItem("auth", JSON.stringify(action.payload));
            return state;

        case actions.SET_LOGOUT:
            localStorage.removeItem("auth");
            return state;

        case actions.SET_APP_STATE:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}
