import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 12 12">
            <path d="M6.92,12h4.62a.47.47,0,0,0,.46-.46V6.92a.47.47,0,0,0-.46-.46H6.92a.47.47,0,0,0-.46.46v4.62A.47.47,0,0,0,6.92,12Z" />
            <path d="M.46,12H5.08a.47.47,0,0,0,.46-.46V6.92a.47.47,0,0,0-.46-.46H.46A.47.47,0,0,0,0,6.92v4.62A.47.47,0,0,0,.46,12Z" />
            <path d="M6.92,5.54h4.62A.47.47,0,0,0,12,5.08V.46A.47.47,0,0,0,11.54,0H6.92a.47.47,0,0,0-.46.46V5.08A.47.47,0,0,0,6.92,5.54Z" />
            <path d="M.46,5.54H5.08a.47.47,0,0,0,.46-.46V.46A.47.47,0,0,0,5.08,0H.46A.47.47,0,0,0,0,.46V5.08A.47.47,0,0,0,.46,5.54Z" />
        </SvgIcon>
    );
}
