import React, { Component } from "react";
import Popper from "./Popper";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import CalendarArrow from "../icons/CalendarArrow";

const styles = theme => ({
    root: {
        backgroundColor: "white",
        borderRadius: "6px",
        zIndex: 100000,
        boxShadow: `0px 2px 4px 0px ${theme.palette.C_AB + "32"}`, // 20% opacity
        overflow: "hidden",
        border: `solid 1px ${theme.palette.C_DB10}`,
        maxHeight: "600px"
        // overflowY: "auto"
    }
});

class Popup extends Component {
    render() {
        const {
            classes,
            children,
            style,
            className,
            whiteBackground,
            ...other
        } = this.props;
        return (
            <Popper
                ArrowComponent={whiteBackground ? CalendarArrow : undefined}
                {...other}
            >
                <div
                    className={classNames(
                        classes.root,
                        { [classes.whiteBackground]: whiteBackground },
                        className
                    )}
                    style={style}
                >
                    {children}
                </div>
            </Popper>
        );
    }
}

Popup.propTypes = {
    /**
     * Whether the arrow needs extra border around arrow for visibility
     */
    whiteBackground: PropTypes.bool
};

Popup.defaultProps = {
    whiteBackground: false
};

export default withStyles(styles)(Popup);
