

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function (props) {
    return (
        <SvgIcon {...props} viewBox='0 0 17.07 16'>
            <path d='M11.2,0C8.36,0,5.33.93,5.33,2.67V4.8a.54.54,0,0,0,1.07,0V4.24a9.6,9.6,0,0,0,4.8,1.09A9.6,9.6,0,0,0,16,4.24V4.8c0,.52-1.31,1.35-3.78,1.56a.53.53,0,0,0,0,1.06h0A8.39,8.39,0,0,0,16,6.36v.57c0,.46-1,1.11-2.76,1.43a.53.53,0,0,0-.43.62.53.53,0,0,0,.52.44h.1A7.05,7.05,0,0,0,16,8.51v.56c0,.45-1,1.11-2.76,1.42a.54.54,0,0,0-.43.62.53.53,0,0,0,.52.44h.1a7.05,7.05,0,0,0,2.57-.9v.56c0,.46-1,1.11-2.76,1.43a.53.53,0,0,0,.09,1.06h.1a7.24,7.24,0,0,0,2.57-.9v.55c0,.52-1.31,1.36-3.78,1.56a.54.54,0,0,0-.48.58.53.53,0,0,0,.53.49h0c2.37-.2,4.76-1.09,4.76-2.63V2.67C17.07.93,14,0,11.2,0Zm0,4.27c-2.93,0-4.8-.95-4.8-1.6s1.87-1.6,4.8-1.6S16,2,16,2.67,14.13,4.27,11.2,4.27Z'
            />
            <path d='M5.87,6.4C3,6.4,0,7.33,0,9.07v4.26C0,15.07,3,16,5.87,16s5.86-.93,5.86-2.67V9.07C11.73,7.33,8.71,6.4,5.87,6.4Zm4.8,6.93c0,.66-1.87,1.6-4.8,1.6s-4.8-.94-4.8-1.6v-.55a9.71,9.71,0,0,0,4.8,1.09,9.74,9.74,0,0,0,4.8-1.09Zm0-2.13c0,.65-1.87,1.6-4.8,1.6s-4.8-1-4.8-1.6v-.56a9.6,9.6,0,0,0,4.8,1.09,9.63,9.63,0,0,0,4.8-1.09Zm-4.8-.53c-2.93,0-4.8-.95-4.8-1.6s1.87-1.6,4.8-1.6,4.8.94,4.8,1.6S8.8,10.67,5.87,10.67Z'
            />
        </SvgIcon>

    )
};
