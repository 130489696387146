import React from "react";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import * as _ from "lodash";
import Typography from "../Typography";
import { colors } from "../../utils/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import List from "react-virtualized/dist/commonjs/List";

const styles = theme => ({
    root: {
        width: "100%",
        height: "calc(100% - 80px)",
        overflowX: "auto",
        borderTopLeftRadius: "6px",
        borderTopRightRadius: "6px",
        borderLeft: `solid 1px ${theme.palette.C_DB10}`,
        borderRight: `solid 1px ${theme.palette.C_DB10}`
    },
    tableWrapper: {
        maxWidth: "100%",
        minWidth: "1430px",
        height: "calc(100% - 5px)"
    },
    headerRow: {
        display: "flex",
        "&:first-child": {
            display: "flex",
            alignItems: "center"
        },
        backgroundColor: theme.palette.C_DB5,
        paddingLeft: "20px",
        border: `solid 1px ${theme.palette.C_DB10}`,
        height: "42px",
        boxSizing: "border-box",
        borderLeft: "none",
        borderRight: "none"
    },
    row: {
        display: "flex",
        justifyContent: "stretch",
        paddingLeft: "20px",
        minHeight: "48px",
        boxSizing: "border-box"
    },
    body: {
        outline: "none"
    },
    bodyWrapper: {
        height: "calc(100vh - 45px)"
    },
    tableCell: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "1px",
        marginTop: "1px",
        overflow: "hidden",
        boxSizing: "border-box",
        flexGrow: "1",
        height: "100%"
    },
    nameCell: {
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box"
    },

    offDayHeader: {
        backgroundColor: theme.palette.C_DB10,
        border: `solid 1px ${theme.palette.C_DB15}`
    },
    tableCellFont: {
        pointerEvents: "none",
        fontSize: theme.typography.F_S_N,
        color: theme.palette.C_DB120
    },
    nameHeader: {
        marginTop: "1px",
        minWidth: "160px"
    }
});

const convertOffsetToColor = (offset, isBusinessDay) => {
    if (!isBusinessDay && offset === 0) return colors.C_DB5;
    if (offset >= 2) {
        return "#7794fc";
    }
    if (offset >= 1) {
        return "#9fadfd";
    }
    if (offset > 0) {
        return "#c1c7fe";
    }
    if (offset === 0) {
        return "#4edda0";
    }
    if (offset >= -1) {
        return "#ffb9a9";
    }
    if (offset >= -2) {
        return "#ff9785";
    }
    return "#ff7561";
};

const containerStyle = { overflow: "visible" };

class TimeGridBasis extends React.PureComponent {
    cellRefs = {};
    state = {
        rootRef: null,
        clientWidth: 1000,
        currentHover: {
            ref: null,
            item: null,
            hideHoverPopper: true
        }
    };

    constructor() {
        super();

        this.debouncedRezise = _.debounce(this.handleResize, 200);
    }
    componentDidMount() {
        this.resizeListener = window.addEventListener(
            "resize",
            this.debouncedRezise
        );
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeListener);
    }

    handleResize = () => {
        const { rootRef } = this.state;
        if (!rootRef) return;
        const clientWidth = rootRef !== null ? rootRef.clientWidth - 20 : 1000; // Minus padding
        this.setState({ clientWidth });
    };
    handleRootRef = r => {
        const clientWidth = r !== null ? r.clientWidth - 20 : 1000; // Minus padding
        this.setState({ rootRef: r, clientWidth });
    };

    renderDaysInMonth = (year, month, item, columnWidth, isBusinessDay) => {
        const { classes, daysInMonth } = this.props;
        const currentMoment = moment([year, month]).startOf("M");
        const tableCells = [];

        for (let i = 1; i <= daysInMonth; i++) {
            currentMoment.date(i);

            const currentDay = item.days[currentMoment.dayOfYear()];

            const isCurrentBusinessDay =
                Boolean(currentDay) && currentDay.normalHours > 0;

            if (isBusinessDay[i] === false) {
                isBusinessDay[i] = isCurrentBusinessDay;
            }

            const displayValue = currentDay
                ? _.round(currentDay.registeredHours, 2)
                : "-";
            const offsetValue = currentDay
                ? _.round(
                      currentDay.registeredHours - currentDay.normalHours,
                      2
                  )
                : undefined;
            tableCells.push(
                <div
                    className={classes.tableCell}
                    key={currentMoment.dayOfYear()}
                    style={{
                        width: columnWidth,
                        height: "47px",
                        backgroundColor: currentDay
                            ? convertOffsetToColor(
                                  offsetValue,
                                  isCurrentBusinessDay
                              )
                            : colors.C_DB5
                    }}
                >
                    <p className={classes.tableCellFont}>{displayValue}</p>
                </div>
            );
        }
        return tableCells;
    };
    renderDaysInWeek = (date, item, columnWidth) => {
        const { classes } = this.props;
        const tableCells = [];

        const currentMoment = moment(date);

        for (let i = 1; i <= 7; i++) {
            currentMoment.isoWeekday(i);

            const dayOfYear = currentMoment.dayOfYear();

            const currentDay = item.days[dayOfYear];

            const isCurrentBusinessDay = currentDay
                ? currentDay.normalHours > 0.01
                : false;

            const displayValue = currentDay
                ? _.round(currentDay.registeredHours, 2)
                : "-";

            const offsetValue = currentDay
                ? _.round(
                      currentDay.registeredHours - currentDay.normalHours,
                      2
                  )
                : 0;

            tableCells.push(
                <div
                    className={classes.tableCell}
                    key={dayOfYear}
                    style={{
                        width: columnWidth,
                        height: "47px",
                        backgroundColor: currentDay
                            ? convertOffsetToColor(
                                  offsetValue,
                                  isCurrentBusinessDay
                              )
                            : colors.C_DB5
                    }}
                >
                    <p className={classes.tableCellFont}>{displayValue}</p>
                </div>
            );
        }
        return tableCells;
    };

    renderYearlyBody = (columnWidth, nameWidth) => {
        const { data, classes, minLeftColWidth } = this.props;

        return _.map(data, item => {
            const currentMoment = moment(item.moment);

            const tableCells = this.renderDaysInWeek(
                currentMoment,
                item,
                columnWidth
            );

            return (
                <div className={classes.row}>
                    <div
                        style={{
                            width: nameWidth,
                            minWidth: minLeftColWidth,
                            height: "47px",
                            paddingRight: "5px"
                        }}
                        className={classes.nameCell}
                    >
                        {this.props.renderLeftColumnTitle(item)}
                    </div>

                    {tableCells}
                </div>
            );
        });
    };

    renderMonthRow = (columnWidth, nameWidth) => ({
        index,
        style,
        isScrolling,
        key
    }) => {
        const { currentDate, data, minLeftColWidth, classes } = this.props;
        const item = data[index];

        const currentMoment = moment(currentDate);

        const tableCells = this.renderDaysInMonth(
            currentMoment.year(),
            currentMoment.month(),
            item,
            columnWidth,
            false
        );
        return (
            <div className={classes.row} style={style} key={key}>
                <div
                    style={{
                        width: nameWidth,
                        height: "47px",
                        paddingRight: "5px",
                        minWidth: minLeftColWidth
                    }}
                    className={classes.nameCell}
                >
                    {this.props.renderLeftColumnTitle(item, isScrolling)}
                </div>

                {tableCells}
            </div>
        );
    };
    renderWeekRow = (columnWidth, nameWidth) => ({
        index,
        style,
        isScrolling,
        key
    }) => {
        const { data, minLeftColWidth, classes } = this.props;
        const item = data[index];
        const currentMoment = moment(item.moment);

        const tableCells = this.renderDaysInWeek(
            currentMoment,
            item,
            columnWidth
        );

        return (
            <div className={classes.row} style={style} key={key}>
                <div
                    style={{
                        width: nameWidth,
                        minWidth: minLeftColWidth,
                        height: "47px",
                        paddingRight: "5px"
                    }}
                    className={classes.nameCell}
                >
                    {this.props.renderLeftColumnTitle(item, isScrolling)}
                </div>

                {tableCells}
            </div>
        );
    };

    renderMonthlyBody = (columnWidth, nameWidth) => {
        const {
            data,
            currentDate,
            classes,
            minLeftColWidth,
            daysInMonth
        } = this.props;

        const isBusinessDay = new Array(daysInMonth + 1).fill(false);

        const body = _.map(data, item => {
            const currentMoment = moment(currentDate);

            const tableCells = this.renderDaysInMonth(
                currentMoment.year(),
                currentMoment.month(),
                item,
                columnWidth,
                isBusinessDay
            );
            return (
                <div className={classes.row}>
                    <div
                        style={{
                            width: nameWidth,
                            height: "47px",
                            paddingRight: "5px",
                            minWidth: minLeftColWidth
                        }}
                        className={classes.nameCell}
                    >
                        {this.props.renderLeftColumnTitle(item)}
                    </div>

                    {tableCells}
                </div>
            );
        });
        return { body, isBusinessDay };
    };
    renderMonthHeaders = (headerStyle, currentDate) => {
        const { daysInMonth, classes } = this.props;
        const businessDayClassNames = classNames(classes.tableCell);

        const regularDayClassNames = classNames(
            classes.tableCell,
            classes.offDayHeader
        );
        const header = [];
        const thisDate = moment(currentDate);

        for (let i = 1; i <= daysInMonth; i++) {
            const dayOfWeek = thisDate.date(i).isoWeekday();
            const currentClassName =
                dayOfWeek !== 6 && dayOfWeek !== 7 // Sat or sun
                    ? businessDayClassNames
                    : regularDayClassNames;

            header.push(
                <div style={headerStyle} className={currentClassName}>
                    <Typography bold size={"smallMed"}>
                        {i}
                    </Typography>
                </div>
            );
        }
        return header;
    };

    renderDefaultHeaders = (headers, headerStyle) => {
        const { classes, businessDayHeaderIndices } = this.props;

        const businessDayClassNames = classNames(classes.tableCell);

        const regularDayClassNames = classNames(
            classes.tableCell,
            classes.offDayHeader
        );

        return _.map(headers, (h, i) => {
            const currentClassName =
                businessDayHeaderIndices.indexOf(i) < 0
                    ? businessDayClassNames
                    : regularDayClassNames;

            return (
                <div style={headerStyle} className={currentClassName}>
                    <Typography bold size={"smallMed"}>
                        {h}
                    </Typography>
                </div>
            );
        });
    };

    render() {
        const {
            classes,
            leftColumnHeader,
            daysInMonth,
            dataBasis,
            headers,
            minLeftColWidth,
            data,
            currentDate
        } = this.props;

        const columnWidth = Math.round(
            this.state.clientWidth * 0.9 / daysInMonth
        );

        const nameWidth = this.state.clientWidth - columnWidth * daysInMonth;

        const header = [];
        const headerStyle = {
            width: columnWidth,
            height: "39px" // - 1 for border
        };

        let renderRowFunction = null;
        if (dataBasis === "monthly") {
            renderRowFunction = this.renderMonthRow(columnWidth, nameWidth);
        } else {
            renderRowFunction = this.renderWeekRow(columnWidth, nameWidth);
        }

        header.push(
            <div
                style={{
                    width: nameWidth,
                    minWidth: minLeftColWidth
                }}
            >
                <Typography
                    bold
                    size={"smallMed"}
                    className={classes.nameHeader}
                >
                    {leftColumnHeader}
                </Typography>
            </div>
        );

        if (!headers) {
            header.push(...this.renderMonthHeaders(headerStyle, currentDate));
        } else {
            header.push(...this.renderDefaultHeaders(headers));
        }

        return (
            <div ref={this.handleRootRef} className={classes.root}>
                <div className={classes.tableWrapper}>
                    <div className={classes.headerRow}>{header}</div>
                    <List
                        className={classes.body}
                        rowCount={data.length}
                        rowHeight={48}
                        rowRenderer={renderRowFunction}
                        width={5000}
                        autoWidth
                        containerStyle={containerStyle}
                        height={
                            this.state.rootRef
                                ? this.state.rootRef.clientHeight - 43
                                : 0
                        }
                    />
                </div>
            </div>
        );
    }
}

TimeGridBasis.propTypes = {
    renderLeftColumnTitle: PropTypes.func.isRequired,
    leftColumnHeader: PropTypes.string.isRequired,
    daysInMonth: PropTypes.number.isRequired,
    dataBasis: PropTypes.oneOf(["yearly", "monthly"]),
    headers: PropTypes.arrayOf(PropTypes.string),
    minLeftColWidth: PropTypes.string,
    businessDayHeaderIndices: PropTypes.arrayOf(PropTypes.number)
};
TimeGridBasis.defaultProps = {
    minLeftColWidth: "160px",
    businessDayHeaderIndices: []
};

export default withStyles(styles)(TimeGridBasis);
