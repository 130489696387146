import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 12 12">
            <path d="M11.78.22A.73.73,0,0,0,11.24,0H.76A.73.73,0,0,0,.22.22.73.73,0,0,0,0,.76V2.27a.73.73,0,0,0,.22.54A.73.73,0,0,0,.76,3H11.24A.75.75,0,0,0,12,2.27V.76A.73.73,0,0,0,11.78.22Z" />
            <path d="M11.78,9.19A.73.73,0,0,0,11.24,9H.76a.73.73,0,0,0-.54.22A.73.73,0,0,0,0,9.73v1.51a.73.73,0,0,0,.22.54A.73.73,0,0,0,.76,12H11.24a.75.75,0,0,0,.76-.76V9.73A.73.73,0,0,0,11.78,9.19Z" />
            <path d="M11.78,4.71a.74.74,0,0,0-.54-.23H.76a.74.74,0,0,0-.54.23A.69.69,0,0,0,0,5.24V6.76a.69.69,0,0,0,.22.53.74.74,0,0,0,.54.23H11.24a.74.74,0,0,0,.54-.23A.69.69,0,0,0,12,6.76V5.24A.69.69,0,0,0,11.78,4.71Z" />
        </SvgIcon>
    );
}
