import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 10 1.2">
            <path d="M.6,1.2H9.4A.6.6,0,0,0,9.4,0H.6a.6.6,0,0,0,0,1.2Z" />
        </SvgIcon>
    );
}
