import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Table from "common/table/Table";
import * as companySelectors from "selectors/company";
import * as _ from "lodash";
import TablePlaceholder from "common/table/TablePlaceholder";
import CellPopupMenu from "../../../common/poppers/CellPopupMenu";
import { updateWorkTypeCategory } from "../../../actions/company";
import {
    createTrueObjectFromIds,
    getObjectTrueValuesCount
} from "../../../utils";
import * as types from "actionTypes";
import * as selectors from "../selectors";
const worktypeCategories = [
    { name: "Kundetid", id: 4 },
    { name: "Interntid", id: 5 },
    { name: "Interntid (Fakturerbar)", id: 8 },
    { name: "Sykdom", id: 1 },
    { name: "Avspasering", id: 2 },
    { name: "Ferie", id: 3 },
    { name: "Permisjon", id: 6 },
    { name: "Ignorer", id: 7 },
    { name: "-", id: 0 }
];

const workTypeEnum = _.reduce(
    worktypeCategories,
    (accumulator, value, key) => {
        accumulator[value.id] = value.name;
        return accumulator;
    },
    {}
);

class WorkTypesTable extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            { label: "Navn", type: "string", id: "name", width: "40%" },
            {
                label: "Kategori",
                type: "string",
                id: "category",
                width: "20%",
                renderCell: this.renderCategory
            },
            {
                label: "Fakturerbar",
                type: "string",
                id: "invoiceable",
                width: "20%",
                renderCell: invoicable =>
                    invoicable ? "Fakturerbar" : "Ikke fakturerbar"
            },
            {
                label: "Pris",
                type: "numeric",
                id: "price",
                width: "15%",
                affix: "kr"
            }
        ];
        this.state = {
            selected: {}
        };
    }

    renderCategory = (category, workType) => {
        return (
            <CellPopupMenu
                items={_.map(worktypeCategories, wt => wt.name)}
                value={_.findIndex(
                    worktypeCategories,
                    wt => wt.id === category
                )}
                onChange={index =>
                    this.props.updateWorkTypeCategory(
                        this.props.company.id,
                        workType.id,
                        worktypeCategories[index].id
                    )
                }
            />
        );
    };
    onCheckboxChange = (e, item) => {
        const prevValue = Boolean(this.props.selected[item.id]);
        const selected = { ...this.props.selected };
        selected[item.id] = !prevValue;

        this.props.setSelectedWorkTypes(selected);
    };

    render() {
        const { workTypes, setSelectedWorkTypes } = this.props;
        const { selected } = this.props;
        const numSelected = getObjectTrueValuesCount(selected);

        return (
            <Table
                columns={this.columns}
                data={workTypes}
                initialOrder={{ direction: "asc", orderBy: "name" }}
                placeholder={
                    <TablePlaceholder
                        description={
                            "A tag is a type of task that is often being completed.\nCreate your first tag by pressing «Add tag» in the upper right corner."
                        }
                    />
                }
                checkbox={{
                    onCheckboxChange: this.onCheckboxChange,
                    selected: selected,
                    onSelectAll: newVal =>
                        setSelectedWorkTypes(
                            newVal ? createTrueObjectFromIds(workTypes) : {}
                        ),

                    numSelected: numSelected
                }}
            />
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        workTypes: companySelectors.getOrderedCompanyWorkTypes(state, props),
        company: companySelectors.getCurrentCompany(state, props),
        selected: selectors.getWorkTypesMeta(state, props).selectedWorkTypes
    };
};
const setSelectedWorkTypes = selected => ({
    type: types.SET_SELECTED_WORKTYPES,
    payload: selected
});

export default withRouter(
    connect(mapStateToProps, { updateWorkTypeCategory, setSelectedWorkTypes })(
        WorkTypesTable
    )
);
