import React from "react"; //eslint-disable-line no-unused-vars
import { connect } from "react-redux";
import CompanyHeader from "./CompanyHeader";
import * as selectors from "./selector";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state, ownProps) => ({
    departmentName: selectors.getDepartmentName(state, ownProps),
    departments: selectors.getPossibleDepartments(state, ownProps),
    selectedDepartment: selectors.getSelectedDepartment(state, ownProps),
    hasCompanyAuthorization: selectors.getHasCompanyAuthorization(
        state,
        ownProps
    )
});

const mapDispatchToProps = (dispatch, ownProps) => {
    const { companyId } = ownProps.match.params;

    return {
        onBreadCrumbsPress: () => {
            ownProps.history.push(`/company/${companyId}`);
        },
        onChangeDepartment: departmentId => {
            if (departmentId === null) {
                ownProps.history.push(`/company/${companyId}`);
            } else {
                ownProps.history.push(
                    `/company/${companyId}/department/${departmentId}`
                );
            }
        }
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CompanyHeader)
);
