import * as _ from "lodash";
import { avatarColors } from "../constants/colors";
import moment from "moment";
import { ruleTypes } from "../actionTypes";
import { fullMonthNames, weekdayShort, shortLowerMonthNames } from "./strings";
export const getFullNameInitials = (firstName, lastName) => {
    const fn = firstName ? firstName.trim() : "";
    const ln = lastName ? lastName.trim() : "";

    let initials = "";
    if (fn && fn.length > 0) {
        initials += fn[0];
    }
    if (ln && ln.length > 0) {
        initials += ln[0];
    }

    return initials.toUpperCase();
};

export const timeout = ms => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
};

export const formatThousands = x => {
    if (!_.isNumber(x)) return "";
    const parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
};

/**
 *
 * @param people A list of people with firstName and lastName
 * @returns {string} A continous string with all the peoples names separated by comma.
 */
export const generateNames = people =>
    _.map(people, target =>
        `${target.firstName} ${target.lastName}`.trim()
    ).join(", ");

export const formatDate = date => {
    if (!date) return "";

    const timestamp = Date.parse(date);
    if (isNaN(timestamp)) return "";

    const thisDate = new Date(date);
    const yearFormat = thisDate.getFullYear().toString();

    let thisDay = thisDate.getDate().toString();
    let thisMonth = thisDate.getMonth();

    return `${thisDay}. ${shortLowerMonthNames[thisMonth]} ${yearFormat}`;
};
export const formatShortDate = date => {
    if (!date) return "";
    const thisDate = moment(date);

    let thisDay = thisDate.date();
    let thisMonth = (thisDate.month() + 1).toString();
    if (thisDay.length === 1) thisDay = `0${thisDay}`;
    if (thisMonth.length === 1) thisMonth = `0${thisMonth}`;

    return `${thisDay}.${thisMonth}`;
};

const isSameDay = (dateFirst, dateSecond) => {
    const thisDate = moment(dateFirst);
    const other = moment(dateSecond);

    return (
        thisDate.year() === other.year() &&
        thisDate.month() === other.month() &&
        thisDate.date() === other.date()
    );
};

export const formatTime = date => {
    return moment(date).format("HH:mm");
};

export const formatRecentDate = (
    date,
    options = { year: false, uppercase: false }
) => {
    if (!date) return "";
    const timestamp = moment(date);

    if (!timestamp.isValid()) return "";

    const today = moment();

    if (isSameDay(today, timestamp)) {
        return `${options.uppercase ? "I" : "i"} dag kl. ${formatTime(
            timestamp
        )}`;
    }
    const isYesterday = isSameDay(timestamp, moment().subtract(1, "d"));

    if (isYesterday)
        return `${options.uppercase ? "I" : "i"} går kl. ${formatTime(
            timestamp
        )}`;

    let str = `${timestamp.date()}. ${fullMonthNames[timestamp.month()]}`;
    if (options.year) str += ` ${timestamp.year()}`;
    return str;
};

export const formatWeekdayDate = date => {
    const thisMoment = moment(date);
    if (!date || !thisMoment.isValid()) {
        return "Ugyldig dato";
    }
    return `${
        weekdayShort[thisMoment.isoWeekday() - 1]
    } ${thisMoment.date()}. ${fullMonthNames[thisMoment.month()]}`;
};
export const getObjectTrueValuesCount = object => {
    return _.filter(_.values(object)).length;
};

export const getTrueValueKeys = object => {
    const filtered = {};

    _.forEach(object, (val, key) => {
        if (val === true) {
            filtered[key] = true;
        }
    });
    return _.keys(filtered);
};

export const filterTableFromSearch = (table, search) => {
    if (!search || search === "") return table;
    return _.filter(table, column => {
        return _.some(
            _.values(column),
            cellVal => _.lowerCase(cellVal).indexOf(_.lowerCase(search)) >= 0
        );
    });
};

export const createTrueObjectFromIds = arrayWithIds => {
    const returnObj = {};
    _.forEach(arrayWithIds, (value, index) => {
        returnObj[value.id] = true;
    });

    return returnObj;
};

export const filterTableColumns = (view, columnData) => {
    return _.filter(columnData, column => {
        return view[column.id] !== false;
    });
};

export const getColorFromId = id => {
    return avatarColors[parseInt(id, 10) % avatarColors.length];
};

export const isZero = value => {
    const val = parseFloat(value);

    return !isNaN(val) && val === 0;
};

export const formatTableName = (firstName, lastName) => {
    let fullName = firstName + " " + lastName;
    fullName = fullName.trim();

    if (fullName.length > 18) {
        fullName = fullName.substring(0, 16) + "...";
    }
    return fullName;
};

export const round = (value, decimals) => {
    return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
};

export const addKeysToObjectValue = (object, keyName, keyValue) => {
    const newObject = {};

    _.forEach(object, (value, key) => {
        newObject[key] = { ...value, [keyName]: keyValue };
    });
    return newObject;
};

export const removeKeyFromObject = (object, key) => {
    const newObject = { ...object };
    delete newObject[key];
    return newObject;
};

export const getKeysFromTrueValues = object => {
    const objectKeys = [];

    _.forEach(object, (val, key) => {
        if (val) objectKeys.push(key);
    });
    return objectKeys;
};

export const isValidNotificationTarget = (
    currentTarget,
    currentTargetGroupDepartment,
    allTargetGroups,
    selectedNotificationGroup
) => {
    if (allTargetGroups.length === 0) return true;

    //Remove targets which are already in a targetGroup that covers this notification
    return _.every(allTargetGroups, targetGroup => {
        //
        if (
            (currentTargetGroupDepartment !== targetGroup.departmentId &&
                targetGroup.departmentId !== null) ||
            targetGroup.id === selectedNotificationGroup
        ) {
            return true;
        }

        return targetGroup.targets.indexOf(currentTarget) < 0;
    });
};
export const mergeViews = (companyView, departmentView, kpiViews) => {
    if (!_.isObject(companyView) && !_.isObject(departmentView)) return {};

    if (!_.isObject(departmentView)) return companyView;
    return {
        ...companyView,
        ...departmentView,
        ...kpiViews
    };
};

/**
 * FROM SERVER UTILS
 */

//https://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
export const hasOverlappingDates = (firstPeriod, secondPeriod) => {
    return (
        moment(firstPeriod.from)
            .startOf("d")
            .isSameOrBefore(moment(secondPeriod.to).endOf("d")) &&
        moment(firstPeriod.to)
            .endOf("d")
            .isSameOrAfter(moment(secondPeriod.from).startOf("d"))
    );
};

export const isValidRange = ({ from, to }) => {
    return moment(from)
        .endOf("d")
        .isBefore(moment(to).startOf("d"));
};

export const calculationRuleErrors = {
    VALID: "VALID",
    INVALID_RANGE: "INVALID_RANGE",
    ALREADY_EXISTING_DAY: "ALREADY_EXISTING_DAY",
    ALREADY_EXISTING_PERIOD: "ALREADY_EXISTING_PERIOD"
};

export const validateCalculationRulesAgainstExisting = (
    existingCalculationRules,
    newCalculationRule
) => {
    let error = calculationRuleErrors.VALID;

    const newRange = {
        to: newCalculationRule.toDate,
        from: newCalculationRule.fromDate
    };

    if (
        newCalculationRule.ruleType === ruleTypes.PERIODIC &&
        !isValidRange(newRange)
    ) {
        return calculationRuleErrors.INVALID_RANGE;
    }

    _.every(existingCalculationRules, calculationRule => {
        if (
            calculationRule.ruleType !== newCalculationRule.ruleType ||
            calculationRule.ruleCategory !== newCalculationRule.ruleCategory
        ) {
            return true;
        }

        if (calculationRule.ruleType === ruleTypes.SINGLE_DAY) {
            const sameDay = isSameDay(
                calculationRule.singleDate,
                newCalculationRule.singleDate
            );

            if (sameDay) {
                error = calculationRuleErrors.ALREADY_EXISTING_DAY;
                return false;
            }
        }

        if (calculationRule.ruleType === ruleTypes.PERIODIC) {
            const existingRange = {
                from: calculationRule.fromDate,
                to: calculationRule.toDate
            };

            const hasOverlapping = hasOverlappingDates(existingRange, newRange);
            if (hasOverlapping) {
                error = calculationRuleErrors.ALREADY_EXISTING_PERIOD;
                return false;
            }
        }
        return true;
    });
    return error;
};
