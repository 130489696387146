import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 8 8">
            <path d="M7.57,3.44h-3v-3A.55.55,0,0,0,4,0a.55.55,0,0,0-.56.43v3h-3A.55.55,0,0,0,0,4a.55.55,0,0,0,.43.56h3v3A.55.55,0,0,0,4,8a.55.55,0,0,0,.56-.43v-3h3A.55.55,0,0,0,8,4,.55.55,0,0,0,7.57,3.44Z" />
        </SvgIcon>
    );
}
