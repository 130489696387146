import React from "react";
import DepartmentHeader from "./DepartmentHeader";
import DepartmentTable from "./DepartmentTable";
import ContentPaper from "../../../components/layout/paper/ContentPaper";

const WorkTypes = props => {
    return (
        <ContentPaper>
            <DepartmentHeader />
            <DepartmentTable />
        </ContentPaper>
    );
};

export default WorkTypes;
