import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import NotificationInactiveIcon from "../../common/icons/NotificationInactiveIcon";

const styles = theme => ({
    icon: {
        width: "18px",
        height: "18px"
    }
});

class NotificationsIcon extends PureComponent {
    static defaultProps = {
        count: 0
    };

    render() {
        const { classes, count } = this.props;

        return (
            <NotificationInactiveIcon
                active={count > 0}
                className={classes.icon}
            />
        );
    }
}

export default withStyles(styles)(NotificationsIcon);
