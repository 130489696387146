import * as actions from "../../actionTypes";
import * as utils from "../../utils";

const initialCalculationRulesState = {};

export default function calulationRules(
    state = initialCalculationRulesState,
    action
) {
    switch (action.type) {
        case actions.SET_CALCULATION_RULES: {
            return utils.addKeysToObjectValue(
                action.payload,
                "companyId",
                action.companyId
            );
        }

        default:
            return state;
    }
}
