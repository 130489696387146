import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import * as selectors from "./selectors";
import TimeGrid from "../../common/time-grid/SingleMonthTimeGrid";
import { fetchTimebankDaily, fetchTimebank } from "./actions";
import * as companySelectors from "../../selectors/company";
class TimebankGrid extends Component {
    componentDidMount() {
        this.requestTimebank();
    }

    componentDidUpdate(prevProps) {
        //If new date has been set, or department has been changed
        if (
            prevProps.currentDepartment !== this.props.currentDepartment ||
            prevProps.currentDate !== this.props.currentDate ||
            (prevProps.hasStaleData === false &&
                this.props.hasStaleData === true)
        ) {
            this.requestTimebank();
        }
    }

    requestTimebank = () => {
        const {
            meta,
            fetchTimebankDaily,
            companyId,
            currentDepartment,
            fetchTimebank
        } = this.props;

        const currentMomentDate = moment(meta.currentDate);

        fetchTimebankDaily(
            companyId,
            currentDepartment,
            currentMomentDate.month(),
            currentMomentDate.year()
        );
        fetchTimebank(companyId, meta.currentDate, currentDepartment); // This includes key figures
    };

    handleNameClick = user => {
        const { history, companyId } = this.props;
        history.push(`/dashboard/${companyId}/users/${user.id}`);
    };

    render() {
        const { dailyTimebank, meta } = this.props;

        return <TimeGrid data={dailyTimebank} currentDate={meta.currentDate} />;
    }
}

const mapDispatchToProps = (state, props) => {
    return {
        companyId: companySelectors.getCompanyId(state, props),
        currentDate: selectors.getCurrentDate(state, props),
        currentDepartment: selectors.getCurrentDepartmentOrDefault(
            state,
            props
        ),
        dailyTimebank: selectors.getDailyComputedTableRows(state, props),
        hasStaleData: selectors.getHasStaleData(state, props),
        meta: selectors.getTimebankMeta(state, props)
    };
};

export default withRouter(
    connect(mapDispatchToProps, { fetchTimebankDaily, fetchTimebank })(
        TimebankGrid
    )
);
