import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    SET_DISPLAY_TYPE,
    SET_USER_FILTERS,
    TRACK_EVENT,
    TRACK_EVENT_THROTTLED
} from "../../actionTypes";
import { analytics } from "../../utils/strings";
import UsersHeader from "./UsersHeader";
import { fetchUsers, setShallowUserFilters } from "./actions";
import { getUserGridHeaderProps } from "./selectors";
import { updateMultipleUsers } from "../../actions/user";
const mapDispatchToProps = (dispatch, ownProps) => {
    const companyId = ownProps.match.params.companyId;

    return {
        onChangeActive: (filters, selected, displayType) => {
            if (selected === 0) filters.includeUsers = "active";
            else if (selected === 1) filters.includeUsers = "inactive";

            dispatch(fetchUsers(filters, companyId, displayType));
            dispatch(setShallowUserFilters(filters));
        },

        onChangeDepartment: (filters, department, displayType) => {
            filters.departmentId = department.id;
            dispatch(fetchUsers(filters, companyId, displayType));
            dispatch(setShallowUserFilters(filters));
        },

        onChangeSearch: searchString => {
            dispatch({
                type: SET_USER_FILTERS,
                payload: {
                    search: searchString
                }
            });
            dispatch({
                type: TRACK_EVENT_THROTTLED,
                payload: {
                    category: analytics.categories.USERS,
                    action: analytics.actions.SEARCH_USERS
                }
            });
        },
        onChangeDisplayType: newDisplayType => {
            dispatch({
                type: SET_DISPLAY_TYPE,
                payload: newDisplayType
            });
            dispatch({
                type: TRACK_EVENT,
                payload: {
                    category: analytics.categories.USERS,
                    action: analytics.actions.SET_DISPLAY_TYPE,
                    label: "Display type set to " + newDisplayType
                }
            });
        },
        updateMultipleUsers: (...args) => dispatch(updateMultipleUsers(...args))
    };
};

export default withRouter(
    connect(getUserGridHeaderProps, mapDispatchToProps)(UsersHeader)
);
