import * as actions from "../../actionTypes";

export const initialShallowUserState = {
    filters: {
        includeUsers: "active"
    },
    displayType: "GRID"
};

export default function shallowUsers(state = initialShallowUserState, action) {
    switch (action.type) {
        case actions.SET_USER_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload
                }
            };
        case actions.SET_DISPLAY_TYPE:
            return {
                ...state,
                displayType: action.payload
            };
        default:
            return state;
    }
}
