import { createMuiTheme } from "@material-ui/core/styles/index";
import { blue, grey } from "@material-ui/core/colors/index";

export const fontSizes = {
    F_XS: "0.625rem", // mini
    F_S: "0.6875rem", //11pt
    F_S_N: "0.725rem", //12pt
    F_N: "0.875rem", //14pt
    F_N_M: "1rem", // 16pt
    F_M: "1.125rem", //18pt
    F_L: "1.4375rem", //23pt
    F_XL: "1.875rem", //30pt,
    F_XXL: "2.0625rem" // 33pt
};
export const colors = {
    C_AB: "#111111",
    C_AW: "#fafafa",
    C_B: "#000000",
    C_G: "#2ecc71",
    C_R: "#e74c3c",
    C_R10: "#ffeeed",
    C_R90: "#ff5f54",
    C_R105: "#db4839",
    C_W: "#ffffff",
    C_W_60: "#ffffff96",
    C_LB: "#4b78fa",
    C_LB10: "#ecf3ff",
    C_LB15: "#e2ecfe",
    C_LB90: "#4889fb",
    C_LB130: "#3755aa",
    C_LB140: "#324b91",

    C_DB: "#2d3c50",
    C_DB3: "#f9f9fa",
    C_DB5: "#f5f5f6",
    C_DB8: "#eeeff1",
    C_DB10: "#eaecee",
    C_DB12: "#e6e9eb",
    C_DB15: "#e0e2e5",
    C_DB20: "#d5d9dd",
    C_DB30: "#c0c5cb",
    C_DB40: "#abb1b9",
    C_DB60: "#818a96",
    C_DB80: "#576373",
    C_DB120: "#182d3e",
    C_DB140: "#0f1f2c",
    C_AB80: "#424242",
    C_star: "#f7b731"
};

export const spacing = {
    cornerRadius: "6px",
    thinStroke: "0.5px",
    normalStroke: "1px",
    thickStroke: "2px",
    gridMargin: "20px",
    topBarHeight: "60px",
    topMenuHeight: "60px",
    topMenuWidth: "60px",
    sidebarWidth: "60px",
    keyFigureTopHeight: "35px",
    keyFigureBotHeight: "50px",
    keyFigureWidth: "160px",
    tableHeaderHeight: "60px",
    tableSearchBarHeight: "40px",
    tableSearchBarWidth: "190px",
    tableRowHeight: "50px",
    inputHeight: "40px",
    listHeight: "48px",
    maxGridRowHeight: "260px",
    maxGridTableRowHeight: "340px",
    keyFigure: {
        width: "120px"
    },
    infoBar: {
        height: "80px"
    }
};
export const regularBorder = {
    borderColor: colors.C_DB10,
    borderWidth: spacing.normalStroke,
    borderStyle: "solid",
    overflow: "hidden"
};
export const thickBorder = {
    ...regularBorder,
    borderColor: colors.C_DB15,
    borderWidth: spacing.thickStroke,
    borderRadius: spacing.cornerRadius
};

const primary = {
    ...blue,
    "400": "A400"
};
export const createTheme = () =>
    createMuiTheme({
        palette: {
            primary,
            secondary: grey,
            ...colors
        },
        spacing: {
            ...spacing
        },
        ripple: {
            color: blue
        },
        buttonBase: {
            textTransform: "none"
        },
        gridItemBorder: {
            ...regularBorder
        },
        gridItemBorderThick: {
            ...thickBorder
        },
        typography: {
            ...fontSizes,
            fontFamily: "Proxima Nova, Helvetica, sans-serif",
            display4: {
                fontSize: fontSizes.F_XL,
                fontWeight: "bold",
                color: colors.C_AB
            },
            display3: {
                fontSize: fontSizes.F_N,
                fontWeight: "normal",
                color: colors.C_DB60
            },
            display2: {
                fontSize: fontSizes.F_L,
                fontWeight: "bold",
                color: colors.C_AB
            },
            display1: {
                fontSize: fontSizes.F_S,
                fontWeight: "normal",
                color: colors.C_AB
            },
            body1: {
                fontSize: fontSizes.F_N,
                fontWeight: "normal",
                color: colors.C_AB
            },
            body2: {
                fontSize: fontSizes.F_N,
                fontWeight: "bold",
                color: colors.C_AB
            },
            subheading: {
                fontSize: fontSizes.F_N,
                fontWeight: "bold",
                color: colors.C_DB40
            },
            button: {
                textTransform: "none"
            }
        },
        direction: "rtl",
        overrides: {
            MuiButton: {
                root: {
                    borderRadius: spacing.cornerRadius
                },
                // Name of the styleSheet

                raisedPrimary: {
                    color: "white",
                    backgroundColor: blue["A400"],
                    "&:hover": {
                        backgroundColor: blue["A400"],
                        // Reset on mouse devices
                        "@media (hover: none)": {
                            backgroundColor: blue["A400"]
                        }
                    }
                },
                flatPrimary: {
                    color: colors.C_AW,
                    backgroundColor: colors.C_LB,
                    "&:hover": {
                        backgroundColor: colors.C_LB,
                        // Reset on mouse devices
                        "@media (hover: none)": {
                            backgroundColor: colors.C_LB
                        }
                    }
                },
                disabled: {
                    backgroundColor: colors.C_DB15,
                    color: colors.C_AB
                }
            },
            MuiIconButton: {
                root: {
                    color: colors.C_DB40,
                    "&:hover": {
                        backgroundColor: "inherit"
                    }
                }
            },

            MuiAvatar: {
                root: {
                    width: 30,
                    height: 30,
                    fontSize: 13,
                    borderRadius: "50%",
                    overflow: "hidden",
                    userSelect: "none"
                }
            },
            MuiTooltip: {
                tooltipOpen: {
                    backgroundColor: colors.C_AB80,
                    opacity: 0.9,
                    borderRadius: spacing.cornerRadius
                },
                tooltip: {
                    fontSize: `${fontSizes.F_N} !important`,
                    fontWeight: "normal",
                    lineHeight: "17px",
                    padding: "8px 16px !important"
                }
            },
            MuiPaper: {
                rounded: {
                    borderRadius: spacing.cornerRadius
                }
            },
            MuiTableCell: {
                head: {
                    fontSize: fontSizes.F_N,
                    fontWeight: 600,
                    color: colors.C_AB
                },
                root: {
                    borderBottom: `${spacing.normalStroke} solid ${colors.C_DB5}`
                }
            },
            MuiTableRow: {
                hover: {
                    "&:hover": {
                        backgroundColor: colors.C_AW
                    }
                }
            },
            MuiListItem: {
                root: {
                    "&:hover": {
                        backgroundColor: colors.C_DB3
                    },
                    height: "50px",
                    "&:first-child": {
                        paddingTop: "4px"
                    }
                },
                default: {
                    paddingTop: "0",
                    paddingBottom: "0"
                },

                gutters: {
                    paddingLeft: spacing.gridMargin,
                    paddingRight: spacing.gridMargin
                }
            },
            MuiSelect: {
                select: {
                    padding: "5px 0",
                    paddingLeft: spacing.gridMargin,
                    paddingRight: `calc(${spacing.gridMargin} + 10px)`
                },
                selectMenu: {
                    lineHeight: "calc(1em + 17px)"
                },
                icon: {
                    top: "8px",
                    marginRight: "5px"
                },
                root: {
                    borderWidth: spacing.thinStroke
                }
            },
            MuiInput: {
                root: {
                    color: colors.C_AB,
                    fontSize: fontSizes.F_N,
                    fontWeight: 500,
                    height: "40px",
                    ...thickBorder
                },
                focused: {
                    borderColor: colors.C_DB40
                }
            },
            MuiDialogContent: {
                root: {
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    alignItems: "flex-start"
                }
            },
            MuiDivider: {
                light: {
                    backgroundColor: colors.C_DB5
                },
                root: {
                    height: spacing.normalStroke,
                    backgroundColor: colors.C_DB5
                }
            },
            MuiSwitch: {
                checked: {
                    color: colors.C_LB,
                    transform: "translateX(14px)",
                    "& + $bar": {
                        backgroundColor: "#abcbfd",
                        opacity: 1
                    }
                },
                checkedSecondary: {
                    color: colors.C_LB,
                    transform: "translateX(14px)",
                    "& + $bar": {
                        backgroundColor: "#abcbfd",
                        opacity: 1
                    }
                },
                default: {
                    color: colors.C_AW
                },
                bar: {
                    backgroundColor: colors.C_DB30,
                    opacity: 1,
                    width: 32,
                    height: 12,
                    marginLeft: -16,
                    marginTop: -6
                },
                icon: {
                    width: "18px",
                    height: "18px"
                }
            },
            MuiCheckbox: {
                default: {
                    color: colors.C_DB30
                },
                checked: {
                    color: colors.C_G
                }
            },
            MuiMenuItem: {
                root: {
                    color: colors.C_AB,
                    height: "50px",
                    "&:hover": {
                        backgroundColor: colors.C_DB8
                    },
                    "&$selected": {
                        backgroundColor: "white",
                        "&:hover": {
                            backgroundColor: colors.C_DB8
                        }
                    },
                    fontWeight: "normal"
                },
                selected: {
                    fontWeight: "bold",
                    color: colors.C_LB
                }
            },
            MuiLinearProgress: {
                root: {
                    height: "1.5px"
                },
                barColorPrimary: {
                    backgroundColor: colors.C_LB
                },
                barColorSecondary: {
                    backgroundColor: colors.C_DB10
                }
            },
            MuiFormControl: {
                root: {}
            }
        }
    });
