

import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';
export default function (props) {
    return (
        <SvgIcon {...props} viewBox='0 0 6.01 10'>
            <path d='M.2,1.63A1,1,0,0,1,.41.2,1,1,0,0,1,1.63.2L5.71,4.29a1,1,0,0,1,0,1.42L1.63,9.8A1,1,0,0,1,.2,9.59a1,1,0,0,1,0-1.22L3.55,5,.18,1.63Z'
            />
        </SvgIcon>

    )
}
