import { httpRequest, assert } from "./helpers";
import * as _ from "lodash";

/**
 * All API calls for reading and writing data about users
 */

/**
 * GETTERS
 */

/**
 * getTimeBanksByCompany - Gets all timebank data about users who workes at a company
 *
 * @param  {string}  firebaseToken Token recieved by server on login
 * @param  {string}  companyId     Id for company
 * @return {Promise}               Promise list of user objects. Name, standard time, registered time, extra time, overtime,
 *                                          additional time, minus time, time off, absence, vacation, paid time, time balance, vacation balance
 */

export const getTimebanksByCompany = async (
    companyId,
    department,
    year,
    month
) => {
    if (companyId === undefined || year === undefined || month === undefined) {
        throw new Error(
            "Invalid input parameters in getTimeBanksByCompany",
            companyId,
            year,
            month,
            department
        );
    }
    if (department) {
        return await httpRequest(
            `/api/v1/companies/${companyId}/hours?year=${year}&month=${month}&departmentId=${department}`,
            "GET"
        );
    } else {
        return await httpRequest(
            `/api/v1/companies/${companyId}/hours?year=${year}&month=${month}`,
            "GET"
        );
    }
};

export const getUserDailyHoursByYear = async (userId, year) => {
    assert(userId !== undefined, "Userid has to be defined");
    assert(year !== undefined, "Year has to be defined");

    return await httpRequest(
        `/api/v1/users/${userId}/hours/daily?year=${year}`,
        "GET"
    );
};

export const getNotificationsByUser = async userId => {
    if (!userId) {
        throw new Error("Invalid input parameters in getNotificationsByUser");
    }
    return await httpRequest(`/api/v1/notifications/users/${userId}`, "GET");
};

export const requestUserPayout = async (userId, payload) => {
    if (!userId) {
        throw new Error("Invalid input parameters in getNotificationsByUser");
    }
    return await httpRequest(
        `/api/v1/users/${userId}/payout/request/`,
        "POST",
        payload
    );
};

export const getUsersByCompany = async (companyId, options) => {
    if (!companyId) {
        throw new Error(
            "Invalid input parameters in getUsersByCompany",
            companyId
        );
    }

    return await httpRequest(
        `/api/v1/companies/${companyId}/users`,
        "GET",
        {},
        {
            params: { ...options }
        }
    );
};

export const getUserById = async userId => {
    if (!userId) {
        throw new Error("Invalid input parameters in getUserById");
    }
    return await httpRequest(`/api/v1/users/${userId}`, "GET");
};

export const getShallowUserById = async userId => {
    if (!userId) {
        throw new Error("Invalid input parameters in getShallowUserById");
    }
    return await httpRequest(`/api/v1/users/${userId}/shallow`, "GET");
};

export const getShallowUserByCompany = async (companyId, options = {}) => {
    if (!companyId) {
        throw new Error("Invalid input parameters in getShallowUserById");
    }
    return await httpRequest(
        `/api/v1/companies/${companyId}/users/shallow`,
        "GET",
        {},
        {
            params: { ...options }
        }
    );
};

export const logout = async () => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, 500);
    });
};

/**
 * POSTS
 */

/**
 * getTimeBankByUser - Gets all timebank data about users who workes at a company
 *
 * @param  {string}  firebaseToken Token recieved by server on login
 * @param  {string}  companyId     Id for company
 * @return {Promise}               Promise list of user objects. Name, standard time, registered time, extra time, overtime,
 *                                          additional time, minus time, time off, absence, vacation, paid time, time balance, vacation balance
 */
export const login = async (email, password, currentUser) => {
    if (!email || !password) {
        throw new Error("Invalid input parameters in login ");
    }
    //Request without authentication
    return await httpRequest(
        "/api/v1/authentication/login",
        "POST",
        { email, password, currentUser },
        {},
        false
    );
};

export const updateUser = async (userId, payload) => {
    if (!userId || _.isEmpty(payload)) {
        throw new Error("Invalid input parameters in updateUser ");
    }
    return await httpRequest(`/api/v1/users/${userId}`, "POST", {
        ...payload
    });
};
export const refreshTokens = async personId => {
    let path = "/api/v1/authentication/refreshToken";
    if (personId) {
        path += `?personId=${personId}`;
    } //TODO SET TO POST
    return await httpRequest(path, "GET");
};

export const setIncomingBalance = async (userId, year, payload) => {
    if (userId === undefined || year === undefined || !_.isObject(payload)) {
        throw new Error("Invalid input parameters in setIncomingBalance");
    }

    return await httpRequest(
        `/api/v1/users/${userId}/incomingBalance?year=${year}`,
        "POST",
        payload
    );
};

/**
 * PUTS
 */

/**
 * updateUserDetails - Updates details about a user
 *
 *      Possible details options
 *
 *          * workplace - Where user works                               (string)
 *          * fullTimeEquivalent - Percentage of full time in percent    (number)
 *          * overtime - Whether user has overtime.                      (bool)
 *          *
 *
 *
 *
 * @param  {string}  firebaseToken Token recieved by server on login
 * @param  {string}  companyId     Id for company
 * @return {Promise}               Promise list of user objects. Name, standard time, registered time, extra time, overtime,
 *                                          additional time, minus time, time off, absence, vacation, paid time, time balance, vacation balance
 */

export const verifyAccount = async (email, password) => {
    if (!email || !password) {
        throw new Error("Invalid input parameters to verifyAccount");
    }
    return await httpRequest(`/api/v1/authentication/verifyAccount`, "POST", {
        email,
        password
    });
};

export const getTimebankByUser = async (userId, year) => {
    if (userId === undefined || year === undefined) {
        throw new Error("Invalid input parameters in getTimebankByUser ");
    }
    return await httpRequest(
        `/api/v1/users/${userId}/hours?year=${year}`,
        "GET"
    );
};

export const setCommentForUser = async (comment, userId, year, month) => {
    if (
        !_.isString(comment) ||
        !_.isString(userId) ||
        !_.isInteger(year) ||
        !_.isInteger(month)
    ) {
        throw new Error("Invalid input parameters in setCommentForUser");
    }
    return await httpRequest(
        `/api/v1/users/${userId}/comment?year=${year}&month=${month}`,
        "POST",
        {
            comment
        }
    );
};

export const getKeyFiguresByUser = async (userId, year) => {
    if (userId === undefined || year === undefined) {
        throw new Error("Invalid input parameters to getKeyFiguresByUser");
    }

    return await httpRequest(
        `/api/v1/users/${userId}/keyfigures/calc?year=${year}`,
        "GET"
    );
};

export const updateFullTimeEquivalent = async (
    userId,
    fullTimeEqId,
    payload
) => {
    if (
        !_.isString(userId) ||
        !_.isString(fullTimeEqId) ||
        !_.isObject(payload)
    ) {
        throw new Error("Invalid input parameters to updateFullTimeEquivalent");
    }
    return await httpRequest(
        `/api/v1/users/${userId}/fullTimeEquivalent/${fullTimeEqId}`,
        "POST",
        payload
    );
};

export const createFullTimeEquivalent = async (userId, payload) => {
    if (!_.isString(userId) || !_.isObject(payload)) {
        throw new Error("Invalid input parameters to createFullTimeEquivalent");
    }
    return await httpRequest(
        `/api/v1/users/${userId}/fullTimeEquivalent/`,
        "POST",
        payload
    );
};

export const deleteFullTimeEquivalent = async (userId, itemId) => {
    if (!_.isString(userId) || !_.isString(itemId)) {
        throw new Error("Invalid input parameters to deleteFullTimeEquivalent");
    }
    return await httpRequest(
        `/api/v1/users/${userId}/fullTimeEquivalent/${itemId}`,
        "DELETE"
    );
};
export const updateNotification = async (notificationId, payload) => {
    if (!_.isString(notificationId) || !_.isObject(payload)) {
        throw new Error("Invalid input parameters to updateNotification");
    }

    return await httpRequest(
        `/api/v1/notifications/${notificationId}`,
        "POST",
        payload
    );
};
export const createPayout = async (userId, payload) => {
    if (!_.isString(userId) || !_.isObject(payload)) {
        throw new Error("Invalid input parameters to updateNotification");
    }
    let path = `/api/v1/users/${userId}/payout`;

    return await httpRequest(path, "POST", payload);
};

export const getPossibleUsers = async personId => {
    if (!_.isString(personId)) {
        throw new Error("Invalid input parameters to updateNotification");
    }
    return await httpRequest(`/api/v1/persons/${personId}/`, "GET");
};
export const updatePerson = async (personId, payload) => {
    if (!_.isString(personId) || !_.isObject(payload)) {
        throw new Error("Invalid input parameters to updatePerson");
    }
    return await httpRequest(`/api/v1/persons/${personId}/`, "POST", payload);
};

export const getIncomingBalance = async (userId, year) => {
    if (!userId || !year) {
        throw new Error("Invalid input parameters to getIncomingBalanceForCurrentYear");
    }
    return await httpRequest(
        `/api/v1/users/${userId}/incomingBalance?year=${year}`,
        "GET"
    );
};

export const getUserLog = async (userId, limit) => {
    if (!userId) {
        throw new Error(
            "Invalid input parameters to getUserLog. UserId is required"
        );
    }

    let path = `/api/v1/events/user/${userId}`;
    if (limit !== undefined) path += `?limit=${limit}`;
    return await httpRequest(path, "GET");
};

export const runRecentSynchronization = async (userId, payload) => {
    if (!userId || !_.isObject(payload)) {
        throw new Error(
            "Invalid input parameters to getUserLog. UserId is required"
        );
    }
    return await httpRequest(
        `/api/v1/resync/users/${userId}/recent`,
        "POST",
        payload
    );
};

export const getTimebankWeeksByMonth = async (userId, year, month) => {
    if (!_.isString(userId) || !_.isNumber(year) || !_.isNumber(month)) {
        throw new Error("Invalid input parameters to getTimebankWeeksByMonth");
    }
    return await httpRequest(
        `/api/v1/users/${userId}/hours/weekly?year=${year}&month=${month}`,
        "GET"
    );
};
