import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 15 11.79">
            <path d="M1.44,3.63l1.72,1.2L5.35,6.35l.35.26.46.32.43.27a2.5,2.5,0,0,0,.48.22,1.35,1.35,0,0,0,.42.08h0a1.35,1.35,0,0,0,.42-.08,2.5,2.5,0,0,0,.48-.22l.43-.27.46-.32.35-.26,3.92-2.72a3.84,3.84,0,0,0,1-1A2.22,2.22,0,0,0,15,1.34a1.29,1.29,0,0,0-.4-.95A1.29,1.29,0,0,0,13.66,0H1.34a1.19,1.19,0,0,0-1,.44A1.69,1.69,0,0,0,0,1.52,2,2,0,0,0,.46,2.67,4,4,0,0,0,1.44,3.63Z" />
            <path d="M14.16,4.53Q11.42,6.39,10,7.42c-.31.23-.57.41-.77.54a4.38,4.38,0,0,1-.79.41,2.55,2.55,0,0,1-.92.2h0a2.55,2.55,0,0,1-.92-.2A4.38,4.38,0,0,1,5.78,8c-.2-.13-.46-.31-.77-.54C4.25,6.86,2.87,5.9.85,4.53A4.61,4.61,0,0,1,0,3.8v6.65a1.29,1.29,0,0,0,.39.94,1.32,1.32,0,0,0,1,.4H13.66a1.32,1.32,0,0,0,.95-.4,1.29,1.29,0,0,0,.39-.94V3.8A4.59,4.59,0,0,1,14.16,4.53Z" />{" "}
        </SvgIcon>
    );
}
