import React from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const styles = theme => ({
    root: {
        border: `solid 1px ${theme.palette.C_DB10}`,
        borderRadius: "6px",
        backgroundColor: theme.palette.C_DB5,
        padding: "0 20px"
    }
});

class Card extends React.Component {
    render() {
        const { classes, children, className, ...other } = this.props;
        return (
            <div className={classNames(classes.root, className)} {...other}>
                {children}
            </div>
        );
    }
}

Card.propTypes = {};

export default withStyles(styles)(Card);
