import React from "react";
import UserTableContainer from "./UserTableContainer";
import UserGridContainer from "./UserGridContainer";
import UserGridHeader from "./UserGridHeaderContainer";
import { withStyles } from "@material-ui/core/styles";
import Authenticated from "../../common/auth/Authenticated";
import { userGroups } from "../../actionTypes";
import DefaultErrorBoundry from "../../common/errors/DefaultErrorBoundry";
import { compose } from "redux";
import { connect } from "react-redux";
import { GetUsersProps } from "./selectors";
import ContentPaper from "components/layout/paper/ContentPaper";
import PaperScrollContainer from "components/layout/paper/PaperScrollContainer";
import UsersInfoBar from "./UsersInfoBar";
const styles = {
    root: {
        margin: 24,
        marginBottom: 0
    }
};
const Users = ({ classes, displayType }) => {
    return (
        <Authenticated
            userGroups={[userGroups.ADMIN, userGroups.DEPARTMENT_LEADER]}
            fullPage
        >
            <DefaultErrorBoundry>
                <UsersInfoBar />
            </DefaultErrorBoundry>
            <ContentPaper>
                <DefaultErrorBoundry>
                    <UserGridHeader />
                </DefaultErrorBoundry>
                <DefaultErrorBoundry>
                    {displayType === "GRID" ? (
                        <PaperScrollContainer>
                            <UserGridContainer />
                        </PaperScrollContainer>
                    ) : (
                        <UserTableContainer />
                    )}
                </DefaultErrorBoundry>
            </ContentPaper>
        </Authenticated>
    );
};

export default compose(withStyles(styles), connect(GetUsersProps))(Users);
