import { all, call, put } from "redux-saga/effects";
import {
    REQUEST_ERROR,
    REQUEST_TIMEBANK_MONTHLY,
    REQUEST_TIMEBANK_WEEK_BY_MONTH,
    REQUEST_USER_TIMEBANK_DAILY,
    SET_TIMEBANK_MONTHLY,
    SET_TIMEBANK_MONTHLY_BY_YEAR,
    SET_TIMEBANK_WEEKS,
    SET_USER_HOURS_DAILY,
    SET_USER_KEY_FIGURES,
    SET_USERS
} from "../actionTypes";
import * as UserApi from "../api/users";
import { takeEveryAsync } from "./helpers";

//WORKERS

export function* getTimebankByCompany(action) {
    try {
        const { date, companyId, departmentId } = action.payload;

        const thisDate = new Date(date);

        const apiDepartmentId = departmentId === -1 ? undefined : departmentId;

        const [timebankMonthly, users] = yield all([
            call(
                UserApi.getTimebanksByCompany,
                companyId,
                apiDepartmentId,
                thisDate.getFullYear(),
                thisDate.getMonth() + 1
            )
        ]);
        yield put({
            type: SET_TIMEBANK_MONTHLY,
            payload: timebankMonthly,
            date
        });
    } catch (error) {
        yield put({ type: REQUEST_ERROR, payload: error });
    }
}

//TODO
export function* getTimebankByUser(action) {
    try {
        const { date, userId } = action.payload;

        const thisDate = new Date(date);

        const [timebankMonthly, user] = yield all([
            call(UserApi.getTimebankByUser, userId, thisDate.getFullYear()),
            call(UserApi.getUserById, userId)
        ]);

        const keyFiguresPayload = {
            [userId]: timebankMonthly.keyFigures
        };

        yield put({ type: SET_USERS, payload: user });
        yield put({
            type: SET_TIMEBANK_MONTHLY_BY_YEAR,
            payload: timebankMonthly.users,
            year: thisDate.getFullYear(),
            userId: userId
        });
        yield put({
            type: SET_USER_KEY_FIGURES,
            payload: keyFiguresPayload,
            year: thisDate.getFullYear()
        });
    } catch (error) {
        yield put({ type: REQUEST_ERROR, payload: error });
    }
}

export function* updateTimebank(action) {
    if (action.payload.userId) {
        yield call(getTimebankByUser, action);
    } else {
        yield call(getTimebankByCompany, action);
    }
}

export function* getUserDailyTimebankByYear(action) {
    const { userId, year } = action.payload;
    const days = yield call(UserApi.getUserDailyHoursByYear, userId, year);

    yield put({ type: SET_USER_HOURS_DAILY, payload: { year, days, userId } });
}
export function* getTimebankWeeksByMonth(action) {
    const { userId, month, year } = action.payload;

    try {
        const result = yield call(
            UserApi.getTimebankWeeksByMonth,
            userId,
            year,
            month
        );

        yield put({
            type: SET_TIMEBANK_WEEKS,
            payload: result,
            year,
            month,
            userId
        });
    } catch (error) {
        yield put({ type: REQUEST_ERROR, payload: error });
    }
}

export default function* rootSaga() {
    yield all([
        takeEveryAsync(REQUEST_TIMEBANK_MONTHLY, updateTimebank),
        takeEveryAsync(REQUEST_TIMEBANK_WEEK_BY_MONTH, getTimebankWeeksByMonth),
        takeEveryAsync(REQUEST_USER_TIMEBANK_DAILY, getUserDailyTimebankByYear)
    ]);
}
