import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import BreadCrumbs from "../../../common/other/BreadCrumbs";
import SelectMenu from "../../../common/dropdown/SelectMenu";
import PropTypes from "prop-types";
import * as _ from "lodash";
const styles = theme => ({
    breadCrumbs: {
        display: "flex",
        alignItems: "center",
        marginLeft: "20px",
        height: "42px"
    },
    root: {
        display: "flex",
        justifyContent: "space-between"
    },
    select: {
        minWidth: "100px"
    }
});

class CompanyHeader extends PureComponent {
    render() {
        const {
            classes,
            departmentName,
            departments,
            selectedDepartment,
            hasCompanyAuthorization,
            onChangeDepartment,
            onBreadCrumbsPress
        } = this.props;
        const title =
            departmentName !== null ? ["Selskap", departmentName] : ["Selskap"];

        const breadCrumbsFunc = hasCompanyAuthorization
            ? onBreadCrumbsPress
            : undefined;
        return (
            <div className={classes.root}>
                <div className={classes.breadCrumbs}>
                    <BreadCrumbs
                        title={title}
                        onBreadCrumbsPress={breadCrumbsFunc}
                    />
                </div>
                <div>
                    <SelectMenu
                        value={selectedDepartment}
                        onChange={index =>
                            onChangeDepartment(departments[index].key)
                        }
                        className={classes.select}
                        items={_.map(departments, dep => dep.name)}
                    />
                </div>
            </div>
        );
    }
}

CompanyHeader.propTypes = {
    /**
     * departmentName is used when a department other than all departments is selected.
     * This prop will be displayed next to "Selskap" in the breadcrumbs.
     * If not defined, the breadcrumbs will only include "Selskap"
     */
    departmentName: PropTypes.string,
    /**
     * departments is used for showing a select with possible departments.
     * This should only have one entry if the user does not have authorization
     * to see other departments. If the current department is all department,
     * Null is an allowed key
     */
    departments: PropTypes.arrayOf(
        PropTypes.objectOf({
            name: PropTypes.string,
            key: PropTypes.string
        })
    ).isRequired,

    /**
     * selectedDepartment is used for showing the selected department in the select.
     * It should be a index of an item in the departments array.
     */
    selectedDepartment: PropTypes.number.isRequired,
    /**
     * Function for when a department is selected
     */
    onChangeDepartment: PropTypes.func,
    /**
     * Function for when "Selskap" has been pressed.
     * Will never be called if user does not have companyAuthorization
     */
    onBreadCrumbsPress: PropTypes.func
};

CompanyHeader.defaultProps = {
    departmentName: null
};

export default withStyles(styles)(CompanyHeader);
