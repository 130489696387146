import React, { PureComponent } from "react";
import classnames from "classnames";
import * as utils from "../../../utils/index";
import { colors, fontSizes } from "../../../utils/styles";
import * as _ from "lodash";

import jss from "jss";

const styles = {
    numeric: {
        textAlign: "flex-end",
        paddingRight: "10px"
    },
    bold: {
        fontWeight: "bold",
        fontSize: fontSizes.F_S_N
    },
    fadedCell: {
        color: colors.C_DB15
    },
    text: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden"
    }
};

const { classes } = jss.createStyleSheet(styles).attach();

function addAffixToNumber(numb, affix) {
    if ((_.isNumber(numb) || !isNaN(parseFloat(numb))) && _.isString(affix)) {
        return numb.toString() + affix;
    }
    return numb;
}

function roundNumeric(number, isFooterCell) {
    //Footer should be rounded to 0
    if (_.isNumber(number)) {
        const roundedNumber = utils.round(number, isFooterCell ? 0 : 2);
        return {
            roundedNumber,
            wasFormatted: roundedNumber !== number
        };
    }
    return { roundedNumber: number, wasFormatted: false };
}

class NumericCell extends PureComponent {
    render() {
        const { value, affix, bold, className } = this.props;

        const textStyle = classnames(classes.text, className, {
            [classes.bold]: bold
        });
        const { roundedNumber } = roundNumeric(value); // Force max two decimals
        const formattedThousands = utils.formatThousands(roundedNumber);

        const withAffix = addAffixToNumber(formattedThousands, affix);

        const numericClasses = classnames(textStyle, {
            [classes.fadedCell]: utils.isZero(value) || value === undefined
        });

        // Show a title with original number if formatted is not equal to original
        return (
            <p title={value} className={numericClasses}>
                {withAffix !== undefined ? withAffix : "-"}
            </p>
        );
    }
}
export default NumericCell;
