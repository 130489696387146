import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
export default function(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 16.94 17">
            <path d="M.42,8.64H1.53A.42.42,0,0,0,2,8.22,6.28,6.28,0,0,1,12,3.24a6.65,6.65,0,0,1,1,.93L11,3.81a.42.42,0,0,0-.31.07.41.41,0,0,0-.17.27l-.2,1.1a.41.41,0,0,0,.34.48l4.94.88h.08a.42.42,0,0,0,.24-.07.44.44,0,0,0,.17-.27l.87-4.95a.42.42,0,0,0-.34-.48L15.48.65A.42.42,0,0,0,15,1l-.37,2.07a8,8,0,0,0-1.41-1.37A8.24,8.24,0,0,0,1.69,3.22,8.16,8.16,0,0,0,0,8.22a.42.42,0,0,0,.12.3A.42.42,0,0,0,.42,8.64Z" />
            <path d="M16.81,8.48a.4.4,0,0,0-.29-.12H15.4a.42.42,0,0,0-.42.42A6.27,6.27,0,0,1,8.7,15.05a6.23,6.23,0,0,1-3.8-1.29,5.79,5.79,0,0,1-1-.93L6,13.19a.45.45,0,0,0,.32-.07.41.41,0,0,0,.17-.27l.19-1.1a.41.41,0,0,0-.34-.48l-4.94-.88a.48.48,0,0,0-.31.07.44.44,0,0,0-.17.27L0,15.68a.42.42,0,0,0,.34.48l1.1.19h.08A.42.42,0,0,0,1.94,16l.36-2.07a8.7,8.7,0,0,0,1.41,1.37,8.24,8.24,0,0,0,11.53-1.53,8.17,8.17,0,0,0,1.7-5A.43.43,0,0,0,16.81,8.48Z" />
        </SvgIcon>
    );
}
