import SearchableSelect from "components/pickers/SearchableSelect";
import React from "react";
import * as _ from "lodash";
import PropTypes from "prop-types";
import AvatarList from "../../people/AvatarList";
import AvatarLabel from "../../people/AvatarLabel";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    text: {
        fontSize: theme.typography.F_S,
        fontWeight: 600,
        color: "inherit"
    }
});
class PeoplePickerPopup extends React.Component {
    renderChild = option => (
        <div>
            <AvatarLabel
                firstName={option.firstName}
                lastName={option.lastName}
                id={option.id}
                photoURL={option.photoURL}
                textClassName={this.props.classes.text}
            />
        </div>
    );

    render() {
        const {
            people,
            selected,
            label,
            target,
            disabled,
            onAddPerson,
            onRemovePerson,
            onChange
        } = this.props;

        const selectedPeople = _.filter(
            people,
            person => selected.indexOf(person.id) >= 0
        );

        return (
            <SearchableSelect
                options={_.map(people, p => ({
                    name: p.displayName,
                    id: p.id,
                    firstName: p.firstName,
                    lastName: p.lastName,
                    photoURL: p.photoURL
                }))}
                value={_.map(selectedPeople, p => p.id)}
                buttonLabel={"Personer"}
                onAddOption={onAddPerson}
                onRemoveOption={onRemovePerson}
                onChange={onChange}
                target={React.cloneElement(target, {
                    people: selectedPeople,
                    label: label,
                    onClick: this.togglePopup,
                    buttonRef: r => (this.target = r),
                    disabled: disabled
                })}
                renderChild={this.renderChild}
            />
        );
    }
}
export const PeoplePickerPopupProps = {
    /**
     * A list of selected people id's
     */
    selected: PropTypes.arrayOf(PropTypes.string).isRequired,
    /**
     * The currently selected value
     */
    people: PropTypes.arrayOf(
        PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired
        })
    ).isRequired,
    /**
     * Function called when a person is selected. Is called with the new list of people selected. Is called every time the list changes
     */
    onChange: PropTypes.func,

    /**
     * Called when a person was added
     */
    onAddPerson: PropTypes.func,
    /**
     * Called when a person is removed
     */
    onRemovePerson: PropTypes.func,
    /**
     * A label to be used in the title of the picker
     */
    label: PropTypes.string.isRequired,

    /**
     * A classname which will be sent to the vertical people picker button.
     */
    targetClassName: PropTypes.string,

    /**
     * A custom target. This target will be passed a provided label a people (the selected people)
     */
    target: PropTypes.node
};

PeoplePickerPopup.propTypes = PeoplePickerPopupProps;

PeoplePickerPopup.defaultProps = {
    target: <AvatarList />
};

export default withStyles(styles)(PeoplePickerPopup);
