import { createSelector } from "reselect";
import { userGroups } from "../../actionTypes";
import * as utils from "../../utils/index";
import { ruleTypes } from "../../actionTypes";
import * as _ from "lodash";
import * as userSelectors from "selectors/user";
import * as companySelectors from "selectors/company";
export const getCompany = (state, props) =>
    state.companies[props.match.params.companyId];

export const getNotificationTargetGroups = state =>
    state.notificationSettings.targetGroups;

export const getCalculationRules = (state, props) => {
    return state.calculationRules;
};

export const getKeyPerformanceIndicators = (state, props) => {
    return state.keyPerformanceIndicators.keyPerformanceIndicators;
};

export const getKeyPerformanceIndicatorsMetaData = (state, props) => {
    return state.keyPerformanceIndicators.metaData;
};

export const getWorkTypesMeta = (state, props) => state.settings.workTypes;
export const getShallowUsers = state => state.users;

export const getSelectedNotificationTargetGroup = state =>
    state.notificationSettings.selectedTargetGroup;

export const getCompanyNormalHours = createSelector(
    getCompany,
    company => company && company.normalHours
);

export const getPath = (_, props) => props.location.pathname;

const tabs = [
    {
        path: "profile",
        name: "Profil"
    },
    {
        path: "setup",
        name: "Oppsett",
        auth: "admin"
    },
    /*    {
        path: "kpi",
        name: "Nøkkeltall",
        auth: "admin"
    },*/
    {
        path: "departments",
        name: "Avdelinger",
        auth: "admin"
    },
    {
        path: "workTypes",
        name: "Arbeidstyper",
        auth: "admin"
    },
    {
        path: "privacy",
        name: "Personvern"
    }
];
export const getTabs = createSelector(
    userSelectors.getSignedInUser,
    signedInUser => {
        if (signedInUser.userGroup === userGroups.ADMIN) {
            return tabs;
        } else if (signedInUser.userGroup === userGroups.DEPARTMENT_LEADER) {
            return _.filter(tabs, t => t.auth !== "admin");
        } else if (signedInUser.userGroup === userGroups.USER) {
            return _.filter(
                tabs,
                t => t.auth !== "admin" && t.auth !== "write"
            );
        }
    }
);

const matchTabWithUrl = (pathname, thisTabs) =>
    _.findIndex(thisTabs, tab => pathname.indexOf(tab.path) >= 0);

export const getSelectedTab = createSelector(
    getPath,
    getTabs,
    (pathname, tabs) => matchTabWithUrl(pathname, tabs)
);
/**
 * Returns departments without default "Unspesified". Is returned ordered by name
 */
export const getCompanyDepartments = createSelector(getCompany, company => {
    if (!company) return undefined;

    const departmentClone = { ...company.departments };
    delete departmentClone["0"];

    const departments = _.values(departmentClone);

    return _.orderBy(departments, "name", "asc");
});

/**
 * Return all departments without unspesified, and including "Alle avdelinger" with key null
 */

function createDepartmentWithAll(departments) {
    if (departments) {
        const departmentClone = [
            { name: "Alle avdelinger", id: null },
            ...departments
        ];
        return departmentClone;
    }
}
export const getCompanyDepartmentsWithAll = createSelector(
    getCompanyDepartments,
    createDepartmentWithAll
);

export const getCompanyCalculationRules = createSelector(
    [getCalculationRules],
    calculationRules => {
        return _.filter(calculationRules, rule => {
            return (
                rule.departmentId === null || rule.departmentId === undefined
            );
        });
    }
);

export const getRelevantCalculationRules = createSelector(
    getCalculationRules,
    _.map
);
const formatDateFromRule = rule => {
    if (rule.ruleType === ruleTypes.SINGLE_DAY) {
        return utils.formatDate(rule.singleDate);
    } else if (rule.ruleType === ruleTypes.PERIODIC) {
        return `${utils.formatDate(rule.fromDate)} - ${utils.formatDate(
            rule.toDate
        )}`;
    } else throw new Error("No date included in rule");
};

const formatValueFromRule = (rule, companyNormalHours) => {
    if (!_.isNumber(companyNormalHours) || !_.isNumber(rule.ruleValue))
        return "";
    if (rule.ruleType === ruleTypes.PERIODIC) {
        return `${rule.ruleValue} timer`;
    }
    if (rule.ruleValue === companyNormalHours) {
        return "Heldag";
    } else if (utils.round(companyNormalHours / 2, 4) === rule.ruleValue) {
        return "Halvdag";
    } else if (rule.ruleValue === 0) {
        return "Fridag";
    } else {
        return `${rule.ruleValue} timer`;
    }
};
export const getCompanyCalculationRulesDisplay = createSelector(
    [getCompanyCalculationRules, getCompany],
    (calculationRules, company) =>
        _.map(calculationRules, rule => {
            return {
                ...rule,
                name: rule.description,
                formattedDate: formatDateFromRule(rule),
                formattedValue: formatValueFromRule(rule, company && company.normalHours)
            };
        })
);

export const getCalculationRulesDisplay = createSelector(
    [getCalculationRules, getCompany],
    (calculationRules, company) =>
        _.map(calculationRules, rule => {
            return {
                ...rule,
                name: rule.description,
                date: formatDateFromRule(rule),
                value: formatValueFromRule(rule, company && company.normalHours)
            };
        })
);

export const getCompanyNotificationTargetGroups = createSelector(
    [companySelectors.getCompanyId, getNotificationTargetGroups],
    (companyId, notificationTargetGroups) => {
        return _.filter(notificationTargetGroups, targetGroup => {
            return targetGroup.companyId === companyId;
        });
    }
);

export const getJoinedCompanyNotificationTargetGroups = createSelector(
    [
        getCompanyNotificationTargetGroups,
        userSelectors.getUserItems,
        getCompany
    ],
    (targetGroups, users, company) => {
        const joinedGroups = _.map(targetGroups, targetGroup => {
            let targets = _.map(targetGroup.targets, targetId => {
                if (!users[targetId]) {
                    return null;
                }
                return {
                    firstName: users[targetId].firstName,
                    lastName: users[targetId].lastName,
                    id: targetId
                };
            });
            targets = _.filter(targets);

            const orderedTargets = _.orderBy(
                targets,
                ["firstName", "lastName"],
                ["asc", "asc"]
            );

            let departmentName = "";

            if (company) {
                if (targetGroup.departmentId === null) {
                    departmentName = "Alle avdelinger";
                } else if (company.departments[targetGroup.departmentId]) {
                    departmentName =
                        company.departments[targetGroup.departmentId].name;
                }
            }
            return {
                ...targetGroup,
                departmentName,
                targets: orderedTargets
            };
        });
        return _.orderBy(joinedGroups, "departmentName", "asc");
    }
);
export const getSelectedDepartment = state =>
    state.notificationSettings.selectedDepartment;

export const getSelectedTargets = state =>
    state.notificationSettings.selectedTargets;

/**
 * Used in notification target group. Returnes users who are valid targets, filtered by
 * which rules they are already included in, and by which department they are in.
 */
export const getUsersBySelectedDepartment = createSelector(
    [
        getSelectedDepartment,
        userSelectors.getActiveUsers,
        getCompanyNotificationTargetGroups,
        getSelectedNotificationTargetGroup
    ],
    (
        selectedDepartment,
        users,
        companyNotificationTargetGroups,
        selectedTargetGroup
    ) => {
        const filteredUsers = _.filter(users, user => {
            if (
                !utils.isValidNotificationTarget(
                    user.id,
                    selectedDepartment,
                    companyNotificationTargetGroups,
                    selectedTargetGroup
                )
            ) {
                return false;
            }
            if (user.userGroup === userGroups.ADMIN) return true;

            return (
                user.userGroup === userGroups.DEPARTMENT_LEADER &&
                user.departmentId === selectedDepartment
            );
        });

        return _.orderBy(
            filteredUsers,
            ["firstName", "lastName"],
            ["asc", "asc"]
        );
    }
);
