import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import SideBar from "./SideBar";
import * as types from "actionTypes";
import { SYNC_TYPES } from "actionTypes";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as userSelectors from "selectors/user";
import { findKey, values } from "lodash";
import { userGroups } from "../../actionTypes";
import Popup from "../poppers/Popup";
import ProfilePopup from "./ProfilePopup";
import AppMenu from "./AppMenu";
import PopupDivider from "../poppers/PopupDivider";
import moment from "moment";
import * as utils from "../../utils";
import classNames from "classnames";
import MenuItem from "../poppers/MenuItem";
import Typography from "../Typography";
import InfoTag from "../InfoTag";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { REQUEST_MANUAL_COMPANY_SYNCHRONIZATION } from "actionTypes";
import { REQUEST_MANUAL_USER_SYNCHRONIZATION } from "actionTypes";

const styles = theme => ({
    popupDivider: {
        borderTopLeftRadius: "6px",
        borderTopRightRadius: "6px"
    },
    menuItem: {
        overflow: "visible",
        borderBottom: `solid 1px ${theme.palette.C_DB5}`
    },
    popup: {
        overflow: "visible",
        overflowY: "unset",
        minWidth: "300px"
    },

    menuRightContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    menuItemTitle: {
        marginRight: "40px"
    },
    menuItemInfo: {
        marginLeft: "15px",
        "&:hover": {
            "& $menuItem": {
                backgroundColor: "blue"
            }
        },
        pointerEvents: "auto"
    },
    lastMenuItem: {
        borderBottom: "none"
    },
    menuOnInfoHover: {
        "&:hover": {
            pointerEvents: "none",
            color: theme.palette.C_DB120,
            backgroundColor: theme.palette.C_W
        }
    }
});
const popupStyle = {
    overflowY: "auto"
};
class SideBarContainer extends Component {
    static propTypes = {
        location: PropTypes.object
    };

    state = {
        menuOpen: false,
        avatarOpen: false,
        resyncOpen: false,
        menuRef: null,
        resyncRef: null
    };
    infoTagRefs = {};

    renderResyncOption = (
        title,
        date,
        description,
        key,
        index,
        isLast = false,
        onClick = null
    ) => {
        const { classes } = this.props;
        return (
            <MenuItem
                key={key}
                className={classNames(classes.menuItem, {
                    [classes.lastMenuItem]: isLast,
                    [classes.menuOnInfoHover]:
                        index === this.state.hoveringInfoOption
                })}
                onClick={
                    onClick ? onClick : this.handleResyncOptionClick(index)
                }
            >
                <Typography
                    color={"inherit"}
                    size={"smallMed"}
                    className={classes.menuItemTitle}
                >
                    {title}
                </Typography>
                <span className={classes.menuRightContainer}>
                    <Typography color={"superFaded"} size={"smallMed"}>
                        {utils.formatRecentDate(date)}{" "}
                    </Typography>
                    <InfoTag
                        onHover={this.handleResynOptionHover(index)}
                        placement={"right"}
                        description={description}
                        className={classes.menuItemInfo}
                        ref={this.handleInfoTagRef(index)}
                    />
                </span>
            </MenuItem>
        );
    };

    renderResyncOptions = () => {
        const { signedInUser } = this.props;
        if (signedInUser.userGroup === userGroups.ADMIN) {
            return this.renderAdminResyncOptions();
        } else if (signedInUser.userGroup === userGroups.DEPARTMENT_LEADER) {
            return this.renderDepLeaderResyncOptions();
        } else if (signedInUser.userGroup === userGroups.USER) {
            return this.renderUserResyncOptions();
        }
        return null;
    };
    renderAdminResyncOptions = () => {
        const { classes, syncStatus } = this.props;
        return (
            <div>
                <PopupDivider className={classes.popupDivider}>
                    {"Synkronisering"}
                </PopupDivider>
                {this.renderResyncOption(
                    "Hurtig",
                    moment(syncStatus.lastQuickSync),
                    "Synkroniserer nye timer og endringer av tidligere opprettede timer.",
                    "changedAfterSync",
                    0
                )}

                {this.renderResyncOption(
                    "Omfattende",
                    moment(syncStatus.lastDeletedSync),
                    "Synkroniserer nye timer, endringer av tidligere opprettede timer og fjerner timer som er slettet i 24SO tilbake til den 1. i forrige måned.",
                    "startDateSync",
                    1
                )}
                {this.renderResyncOption(
                    "Innstillinger",
                    moment(syncStatus.lastUserAndWorkTypeSync),
                    "Synkroniserer brukere og arbeidstyper fra 24SO.",
                    "workTypesAndUsers",
                    2
                )}
                {this.renderResyncOption(
                    "Full resynkronisering",
                    moment(syncStatus.lastFullSync),
                    "Synkroniserer alle timer, brukere og arbeidstyper ett år tilbake i tid.",
                    "fullResync",
                    3,
                    true
                )}
            </div>
        );
    };
    renderDepLeaderResyncOptions = () => {
        const { classes, syncStatus } = this.props;
        return (
            <div>
                <PopupDivider className={classes.popupDivider}>
                    {"Synkronisering"}
                </PopupDivider>
                {this.renderResyncOption(
                    "Hurtig",
                    moment(syncStatus.lastQuickSync),
                    "Synkroniserer nye timer og endringer av tidligere opprettede timer.",
                    "changedAfterSync",
                    0,
                    true,
                    this.handleQuickSyncClick
                )}
            </div>
        );
    };
    renderUserResyncOptions = () => {
        const { classes, syncStatus } = this.props;
        return (
            <div>
                <PopupDivider className={classes.popupDivider}>
                    {"Synkronisering"}
                </PopupDivider>
                {this.renderResyncOption(
                    "Hurtig",
                    moment(syncStatus.lastSyncStart),
                    "Synkroniserer nye timer og endringer av tidligere opprettede timer.",
                    "changedAfterSync",
                    0,
                    true,
                    this.handleQuickSyncClick
                )}
            </div>
        );
    };
    handleInfoTagRef = index => ref => {
        this.infoTagRefs[index] = ReactDOM.findDOMNode(ref);
    };
    handleResynOptionHover = index => isHovering => {
        if (isHovering) {
            this.setState({ hoveringInfoOption: index });
        } else {
            this.setState({ hoveringInfoOption: -1 });
        }
    };

    handleResyncOptionClick = index => e => {
        if (!this.infoTagRefs[index]) return null;

        if (this.infoTagRefs[index].contains(e.target)) return null;
        let syncType = null;
        if (index === 0) {
            syncType = SYNC_TYPES.QUICK;
        } else if (index === 1) {
            syncType = SYNC_TYPES.DELETED;
        } else if (index === 2) {
            syncType = SYNC_TYPES.USER_WORKTYPE;
        } else if (index === 3) {
            syncType = SYNC_TYPES.FULL;
        } else return;

        this.props.requestCustomSynchronization(
            this.props.signedInUser.companyId,
            syncType
        );
        this.setState({ resyncOpen: false });
    };
    handleQuickSyncClick = () => {
        this.props.requestQuickSynchronization(this.props.signedInUser);
        this.setState({ resyncOpen: false });
    };
    handleResyncClose = () => {
        this.setState({ resyncOpen: false });
    };
    handleResyncToggle = () => {
        this.setState({ resyncOpen: !this.state.resyncOpen });
    };

    handleMenuClick = () => {
        this.setState({ menuOpen: !this.state.menuOpen });
    };
    handleAvatarClick = () => {
        this.setState({ avatarOpen: !this.state.avatarOpen });
    };
    handleAvatarClose = () => {
        this.setState({ avatarOpen: false });
    };
    handleMenuClose = () => {
        this.setState({ menuOpen: false });
    };
    handleAvatarLogoutClick = event => {
        this.props.logout();
    };
    linkTo = path => {
        this.props.history.push(path);
    };

    findActiveIcon = () => {
        const { location } = this.props;
        if (!location) return "";
        const pathname = location.pathname;

        if (pathname.indexOf("dashboard") > 0) {
            return "dashboard";
        } else if (pathname.indexOf("users") > 0) {
            return "users";
        } else if (pathname.indexOf("settings") > 0) {
            return "settings";
        } else {
            console.error(
                "Unrecognized pathname in SideBarContainer:",
                pathname
            );
            return "";
        }
    };

    handleMenuRef = r => {
        this.setState({ menuRef: r });
    };
    handleResyncRef = r => {
        this.setState({ resyncRef: r ? ReactDOM.findDOMNode(r) : null });
    };

    render() {
        const { auth, signedInUser, syncStatus } = this.props;

        const activeIcon = this.findActiveIcon();

        // Link to department page if dep leader, or to main company page if admin.
        // Restricting users is done in sidebar component.
        // Department leaders in unspecified will link to nowhere
        const companyLink = `/settings/${auth.user.companyId}/profile`;
        const dashbooardLink =
            signedInUser.userGroup === userGroups.USER
                ? `/dashboard/${auth.user.companyId}/users/${signedInUser.id}/`
                : `/dashboard/${auth.user.companyId}/`;
        return (
            <Fragment>
                <SideBar
                    dashboardLink={dashbooardLink}
                    usersLink={`/users/${auth.user.companyId}`}
                    companyLink={companyLink}
                    activeIcon={activeIcon}
                    user={signedInUser}
                    avatarRef={r => (this.avatar = ReactDOM.findDOMNode(r))}
                    menuRef={this.handleMenuRef}
                    resyncRef={this.handleResyncRef}
                    onAvatarClick={this.handleAvatarClick}
                    onMenuClick={this.handleMenuClick}
                    onResyncClick={this.handleResyncToggle}
                    syncInProgress={syncStatus.inProgress}
                />
                <Popup
                    targetRef={this.state.menuRef}
                    placement={"right-start"}
                    onClickAway={this.handleMenuClose}
                    open={this.state.menuOpen}
                    preventOverflow
                    arrow
                    arrowSize={"small"}
                    offset={15}
                    whiteBackground
                    style={popupStyle}
                >
                    <AppMenu />
                </Popup>
                <Popup
                    preventOverflow
                    targetRef={this.avatar}
                    placement={"right-end"}
                    onClickAway={this.handleAvatarClose}
                    open={this.state.avatarOpen}
                    arrowSize={"small"}
                    whiteBackground
                >
                    <ProfilePopup
                        onLogout={this.handleAvatarLogoutClick}
                        signedInUser={this.props.signedInUser}
                        onProfileClick={this.handleAvatarClose}
                        onTutorialClick={this.handleAvatarClose}
                    />
                </Popup>
                <Popup
                    open={this.state.resyncOpen}
                    onClickAway={this.handleResyncClose}
                    placement={"right-end"}
                    targetRef={this.state.resyncRef}
                    className={this.props.classes.popup}
                >
                    {this.renderResyncOptions()}
                </Popup>
            </Fragment>
        );
    }
}
const getSyncStatus = state => state.auth.synchronizationStatus || {};

const mapStateToProps = (state, props) => ({
    auth: state.auth,
    signedInUser: userSelectors.getSignedInUser(state, props),

    syncStatus: getSyncStatus(state)
});

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        logout: () => {
            dispatch({ type: types.REQUEST_LOG_OUT });
        },
        requestQuickSynchronization: signedInUser => {
            if (signedInUser.userGroup === userGroups.DEPARTMENT_LEADER) {
                dispatch({
                    type: REQUEST_MANUAL_COMPANY_SYNCHRONIZATION,
                    syncType: SYNC_TYPES.QUICK,
                    companyId: signedInUser.companyId
                });
            } else if (signedInUser.userGroup === userGroups.USER) {
                dispatch({
                    type: REQUEST_MANUAL_USER_SYNCHRONIZATION,
                    syncType: SYNC_TYPES.QUICK,
                    userId: signedInUser.id,
                    companyId: signedInUser.companyId
                });
            }
        },
        requestCustomSynchronization: (companyId, syncType) => {
            dispatch({
                type: REQUEST_MANUAL_COMPANY_SYNCHRONIZATION,
                syncType: syncType,
                companyId
            });
        }
    };
};

export default withRouter(
    compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(
        SideBarContainer
    )
);
