import React, { Component } from "react";
import Select from "../forms/Select";
import PropTypes from "prop-types";
import { map } from "lodash";
import MenuItem from "@material-ui/core/MenuItem";
import SimpleDialog from "./SimpleDialog";
import InputField from "../forms/text-fields/InputField";
import DialogButtonGroup from "./dialogContent/DialogButtonGroup";

class SelectAndTextFieldDialog extends Component {
    state = {
        selectValue: 0,
        textValue: ""
    };

    componentDidMount() {
        if (this.props.selectValue || this.props.initialValue) {
            this.setState({
                selectValue: this.props.initialSelect,
                textValue: this.props.initialValue
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.open === false && nextProps.open === true) {
            this.setState({
                selectValue: nextProps.initialSelect,
                textValue: nextProps.initialValue
            });
        }
    }

    onChange = e => {
        this.setState({ selectValue: e.target.value });
    };

    handleTextFieldChange = e => {
        const { numeric } = this.props;
        this.setState({ textValue: e.target.value });
    };

    onCancel = e => {
        this.props.onCancel(e);
    };
    onConfirm = (...args) => {
        const { textValue } = this.state;
        const { numeric, onConfirm } = this.props;
        console.log("SelectAndTextFieldDialog on confirm", textValue, args);
        onConfirm(
            {
                selectValue: this.state.selectValue,
                textValue: numeric ? parseFloat(textValue) : textValue
            },
            ...args
        );
    };

    render() {
        const {
            classes,
            values,
            numeric,
            TextFieldProps,
            SelectProps,
            onCancel,
            onConfirm,
            buttonType,
            actionButton,
            deleteButton,
            okButton,
            ...other
        } = this.props;
        const { selectValue, textValue } = this.state;

        const menuItems = map(values, (v, i) => {
            return (
                <MenuItem value={i} key={i}>
                    {v}
                </MenuItem>
            );
        });

        return (
            <SimpleDialog
                buttonGroup={
                    <DialogButtonGroup
                        type={buttonType}
                        onCancel={this.onCancel}
                        onConfirm={this.onConfirm}
                        actionButton={actionButton}
                        deleteButton={deleteButton}
                        okButton={okButton}
                        disabled={textValue.length === 0}
                    />
                }
                {...other}
            >
                <Select
                    value={parseInt(selectValue, 10)}
                    onChange={this.onChange}
                    fullWidth
                    {...SelectProps}
                >
                    {menuItems}
                </Select>
                <InputField
                    type="text"
                    onChange={this.handleTextFieldChange}
                    value={textValue}
                    fullWidth
                    numeric={numeric}
                    padding="default"
                    {...TextFieldProps}
                />
            </SimpleDialog>
        );
    }
}

SelectAndTextFieldDialog.propTypes = {
    initialValue: PropTypes.any,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    initialSelect: PropTypes.number,
    numeric: PropTypes.bool,
    TextFieldProps: PropTypes.object,
    SelectProps: PropTypes.object
};
SelectAndTextFieldDialog.defaultProps = {
    initialSelect: 0,
    initialValue: "",
    TextFieldProps: {},
    SelectProps: {}
};
export default SelectAndTextFieldDialog;
