import {
    fork,
    call,
    take,
    put,
    takeEvery,
    takeLatest,
    race
} from "redux-saga/effects";
import * as _ from "lodash";
import * as types from "../actionTypes";
import { updateCompleteStrings } from "../utils/strings";
import * as modalActions from "../containers/modals/actions";
/* eslint-disable no-constant-condition */

export function* takeFirst(pattern, saga, ...args) {
    const task = yield fork(function*() {
        while (true) {
            const action = yield take(pattern);
            yield call(saga, ...args.concat(action));
        }
    });
    return task;
}

export function* takeFirstAsync(
    pattern,
    saga,
    withConfirmAction = true,
    withFetching = true,
    ...args
) {
    const task = yield fork(function*() {
        while (true) {
            const action = yield take(pattern);

            if (withFetching) yield put({ type: types.IS_FETCHING });

            try {
                yield call(saga, ...args.concat(action));
                if (withFetching) yield put({ type: types.FETCH_SUCCESS });
                if (
                    withConfirmAction === true &&
                    _.isString(updateCompleteStrings[pattern])
                ) {
                    yield call(setConfirmAction, pattern);
                }
            } catch (error) {
                yield put({ type: types.REQUEST_ERROR, payload: error });
                if (withFetching) yield put({ type: types.FETCH_SUCCESS });
            }
        }
    });

    return task;
}
export function* takeLastAsync(pattern, saga, withConfirmAction = true) {
    const wrappedSaga = function*(action) {
        try {
            yield call(saga, action);
            if (
                withConfirmAction === true &&
                _.isString(updateCompleteStrings[pattern])
            ) {
                yield call(setConfirmAction, pattern);
            }
        } catch (e) {
            console.log("ERROR", e);
            yield put({ type: types.REQUEST_ERROR, payload: e });
        }
    };
    yield takeLatest(pattern, wrappedSaga);
}
export function* takeEveryAsync(pattern, saga) {
    const wrappedSaga = function*(action) {
        try {
            yield call(saga, action);
        } catch (e) {
            yield put({ type: types.REQUEST_ERROR, payload: e });
        }
    };
    yield takeEvery(pattern, wrappedSaga);
}
export function* setConfirmAction(subType) {
    yield put({
        type: types.SET_CONFIRM_ACTION,
        payload: { subType, createdAt: Date.now() }
    });
}

export function* confirmMessageSaga(confirmationMessage) {
    // Cause the dialog to be shown (reducer will put the message
    // in the store; the main shell UI component will receive the
    // message in its props and then display the dialog)
    yield put(modalActions.showConfirmModal(confirmationMessage));
    // Wait for either a yes or a no.
    // The dialog UI component receives yes and no event handlers
    // in its props that dispatch these actions.
    const { yes } = yield race({
        yes: take(types.CONFIRM_DIALOG_YES),
        no: take(types.CONFIRM_DIALOG_NO)
    });
    // Tell a reducer to hide the dialog
    yield put({ type: types.CLOSE_MODAL });
    // Returns true if the 'yes' action was received
    return !!yes;
}
