import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { IconButton } from "@material-ui/core";
import RemoveIcon from "common/icons/RemoveIcon";

const styles = theme => ({
    root: {
        position: "fixed",
        bottom: "20px",
        display: "flex",
        alignItems: "center",
        padding: "2px 2px 2px 15px",
        borderRadius: "6px",
        backgroundColor: theme.palette.C_LB,
        zIndex: 10000,
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.2)"
    },
    textStyles: {
        color: theme.palette.C_W,
        fontSize: theme.palette.F_S,
        paddingRight: "20px",
        fontWeight: 600
    },
    icon: {
        color: theme.palette.C_W,
        width: "8px",
        height: "8px"
    },
    iconContainer: {
        width: "26px",
        height: "26px",
        background: theme.palette.C_LB140,
        padding: 0,
        borderRadius: "6px",
        "&:hover": {
            background: theme.palette.C_LB130
        }
    }
});

export class Notificator extends PureComponent {
    render() {
        const { classes, description, onClose } = this.props;

        const style = {};
        if (!description) return null; // Don't show an empty box.
        return (
            <Paper className={classes.root} style={style}>
                <Typography className={classes.textStyles}>
                    {description}
                </Typography>

                <IconButton
                    disableRipple
                    onClick={onClose}
                    className={classes.iconContainer}
                >
                    <RemoveIcon className={classes.icon} />
                </IconButton>
            </Paper>
        );
    }
}

export default withStyles(styles)(Notificator);
