import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import Typography from "../Typography";
import classNames from "classnames";
import ButtonBase from "@material-ui/core/ButtonBase";
import PropTypes from "prop-types";
import * as utils from "../../utils";

const styles = theme => ({
    root: {
        height: "40px",
        borderRadius: "6px",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        position: "relative"
    },
    rootBackground: {
        backgroundColor: theme.palette.C_DB10
    },
    paperBackground: {
        backgroundColor: theme.palette.C_DB5
    },
    tabs: {
        height: "35px",
        display: "flex",
        alignItems: "center",
        padding: "0 2px"
    },
    tab: {
        display: "flex",
        justifyContent: "center",
        boxSizing: "border-box",
        alignItems: "center",
        height: "100%",
        padding: "0 20px",
        borderRadius: "6px",
        "&:hover": {
            fontWeight: "bold",
            color: theme.palette.C_DB120
        },
        "&:focus": {
            fontWeight: "bold",
            color: theme.palette.C_DB120
        },
        color: theme.palette.C_DB40
    },
    activeFont: {
        color: theme.palette.C_DB120
    },

    font: {
        zIndex: 3
    },
    cover: {
        boxSizing: "border-box",
        height: "36px",
        padding: "0 15px",
        borderRadius: "6px",
        width: "80px",
        position: "absolute",
        backgroundColor: theme.palette.C_DB5,
        left: "2px",
        border: `solid 1px ${theme.palette.C_DB15}`
    },
    activeTab: {},
    icon: {
        width: "12px",
        height: "12px",
        zIndex: 3
    },
    iconTab: {
        minWidth: "55px",
        width: "55px"
    },
    paperCover: {
        backgroundColor: theme.palette.C_W,
        border: `solid 1px ${theme.palette.C_DB10}`
    },
    iconCover: {
        width: "55px"
    }
});

class Slider extends PureComponent {
    state = {
        selectedTab: 0,
        tabSizes: [],
        shouldAnimate: false
    };
    tabRefs = [];

    async componentDidMount() {
        await utils.timeout(0);
        this.calculateTabWidths();
        await utils.timeout(1000);
        this.setState({ shouldAnimate: true });
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedTab !== this.props.selectedTab) {
            this.calculateTabWidths();

            this.setState({ selectedTab: nextProps.selectedTab });
        }
    }

    calculateTabWidths = () => {
        const tabSizes = _.map(this.tabRefs, ref => {
            if (!ref) return 0;
            return ref.clientWidth; // Border
        });
        this.setState({ tabSizes });
    };

    handleTabRef = index => r => {
        this.tabRefs[index] = r;
    };

    renderIconTab = (icon, isActive, i) => {
        const { classes } = this.props;
        const containerClasses = classNames(classes.tab, classes.iconTab, {
            [classes.activeTab]: isActive
        });

        return (
            <ButtonBase
                className={containerClasses}
                onClick={() => this.props.onSelectTab(i)}
                disabled={isActive}
                disableRipple
                buttonRef={this.handleTabRef(i)}
                key={i}
            >
                {React.cloneElement(icon, {
                    className: classNames(classes.icon, {
                        [classes.activeFont]: isActive
                    })
                })}
            </ButtonBase>
        );
    };

    renderTextTab = (text, isActive, i) => {
        const { classes } = this.props;

        const containerClasses = classNames(classes.tab, {
            [classes.activeTab]: isActive
        });

        return (
            <ButtonBase
                className={containerClasses}
                onClick={() => this.props.onSelectTab(i)}
                disabled={isActive}
                disableRipple
                buttonRef={this.handleTabRef(i)}
                key={text}
            >
                <Typography
                    size={"smallMed"}
                    color={"inherit"}
                    bold
                    className={classNames(classes.font, {
                        [classes.activeFont]: isActive
                    })}
                >
                    {text}
                </Typography>
            </ButtonBase>
        );
    };
    calculateLengthToTab = selectedTab => {
        let result = 0;

        _.forEach(this.state.tabSizes.slice(0, selectedTab), tabSize => {
            result += tabSize;
        });
        return result;
    };

    render() {
        const {
            classes,
            tabs,
            variant,
            background,
            selectedTab,
            className
        } = this.props;

        const tabViews = _.map(tabs, (tab, i) => {
            const isActive = i === selectedTab;

            return variant === "icon"
                ? this.renderIconTab(tab, isActive, i)
                : this.renderTextTab(tab, isActive, i);
        });

        return (
            <div
                className={classNames(classes.root, className, {
                    [classes.paperBackground]: background === "paper",
                    [classes.rootBackground]: background === "root"
                })}
            >
                <div className={classes.tabs}>{tabViews}</div>
                <div
                    className={classNames(classes.cover, {
                        [classes.paperCover]: background === "paper",
                        [classes.iconCover]: variant === "icon"
                    })}
                    style={{
                        transform: `translate(${this.calculateLengthToTab(
                            selectedTab
                        )}px, 0)`,
                        width: this.state.tabSizes[selectedTab],
                        transition: this.state.shouldAnimate
                            ? "all 200ms"
                            : undefined
                    }}
                />
            </div>
        );
    }
}

Slider.propTypes = {
    /**
     * A list of tabs. Can be react components or strings
     */
    tabs: PropTypes.arrayOf(PropTypes.node).isRequired,

    /**
     * Function called when new tab is selected
     */
    onSelectTab: PropTypes.func.isRequired,
    /**
     * The currently selected tab. Should be the index of the item in the tabs array
     */
    selectedTab: PropTypes.number.isRequired,

    /**
     * The type of items in the slider.
     */
    variant: PropTypes.oneOf(["text", "icon"]),

    /**
     * A optional tab width
     */
    tabWidth: PropTypes.number,

    /**
     * An optional classname to configure the component's root styles
     */
    className: PropTypes.string,

    /**
     * The type of background for the slider. If the slider is positioned on a paper,
     * it will have the background color of the info bar. If root, the background color will
     * be the color of the side bar.
     */
    background: PropTypes.oneOf(["paper", "root"])
};

Slider.defaultProps = {
    variant: "text",
    background: "root"
};

export default withStyles(styles)(Slider);
