import React, { Component } from "react";
import SimpleDialog from "common/dialogs/SimpleDialog";
import * as _ from "lodash";
import DialogButtonGroup from "common/dialogs/dialogContent/DialogButtonGroup";
import InputPeoplePicker from "components/pickers/people/InputPeoplePicker";
import SelectInput from "common/forms/SelectInput";
import * as companySelectors from "selectors/company";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as types from "../../actionTypes";
import { SET_NOTIFICATIONS_SETTINGS, userGroups } from "../../actionTypes";
import * as utils from "../../utils";
import {
    getJoinedCompanyNotificationTargetGroups,
    getSelectedDepartment,
    getSelectedNotificationTargetGroup,
    getSelectedTargets,
    getUsersBySelectedDepartment
} from "../settings/selectors";
import * as modalActions from "./actions";
import { createNotificationTargetGroup } from "../../actions/company";

class NotificationTargetGroupDialog extends Component {
    state = {
        isDeleting: false
    };

    handleConfirm = () => {
        this.props.onConfirm(
            this.props.companyId,
            this.props.selectedDepartment,
            this.props.selectedTargets
        );
    };

    handleDepartmentChange = index => {
        this.props.setSelectedDepartment(
            this.props.departments[index].id,
            this.props.selectedTargets,
            this.props.targets,
            this.props.selectedTargetGroup,
            this.props.targetGroups
        );
    };

    handleAddPerson = targetId => {
        this.props.setSelectedTargets([
            ...this.props.selectedTargets,
            targetId
        ]);
    };
    handleRemovePerson = targetId => {
        this.props.setSelectedTargets(
            this.props.selectedTargets.filter(target => target !== targetId)
        );
    };

    render() {
        const {
            departments,
            targets,
            selectedDepartment,
            selectedTargets,
            isDisabled,
            modalProps,
            ...other
        } = this.props;

        return (
            <SimpleDialog
                {...modalProps}
                open={true}
                buttonGroup={
                    <DialogButtonGroup
                        onCancel={this.props.onClose}
                        onConfirm={this.handleConfirm}
                        disabled={isDisabled}
                    />
                }
                {...other}
            >
                <SelectInput
                    onChange={this.handleDepartmentChange}
                    value={_.findIndex(
                        departments,
                        d => d.id === selectedDepartment
                    )}
                    options={_.map(departments, d => d.name)}
                    fullWidth
                    label="Avdeling"
                />
                <InputPeoplePicker
                    people={targets}
                    selected={selectedTargets}
                    onRemovePerson={this.handleRemovePerson}
                    onAddPerson={this.handleAddPerson}
                />
            </SimpleDialog>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        departments: companySelectors.getCreatedCompanyDepartmentWithAll(
            state,
            props
        ),
        targets: getUsersBySelectedDepartment(state, props),
        selectedDepartment: getSelectedDepartment(state, props),
        selectedTargets: getSelectedTargets(state, props),
        targetGroups: getJoinedCompanyNotificationTargetGroups(state, props),
        selectedTargetGroup: getSelectedNotificationTargetGroup(state, props),
        companyId: companySelectors.getCompanyId(state, props)
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setSelectedDepartment: (
            departmentId,
            selectedTargets,
            targets,
            selectedTargetGroup,
            allTargetGroups
        ) => {
            const fullSelectedTargets = _.filter(targets, t => {
                return selectedTargets.indexOf(t.id) >= 0;
            });
            const filteredSelectedTargets = _.filter(
                fullSelectedTargets,
                target => {
                    return (
                        (target.departmentId === departmentId ||
                            target.userGroup === userGroups.ADMIN) &&
                        utils.isValidNotificationTarget(
                            target.key,
                            departmentId,
                            allTargetGroups,
                            selectedTargetGroup
                        )
                    );
                }
            );
            dispatch({
                type: SET_NOTIFICATIONS_SETTINGS,
                payload: {
                    selectedDepartment: departmentId,
                    selectedTargets: _.map(filteredSelectedTargets, t => t.id)
                }
            });
        },
        setSelectedTargets: targets => {
            dispatch({
                type: SET_NOTIFICATIONS_SETTINGS,
                payload: {
                    selectedTargets: targets
                }
            });
        },

        onConfirm: (companyId, departmentId, targets) => {
            dispatch(
                createNotificationTargetGroup(companyId, departmentId, targets)
            );
            dispatch(modalActions.closeModal());
            dispatch({
                type: types.SET_INITIAL_NOTIFICATION_TARGET_GROUP_STATE
            });
        },
        onClose: () => {
            dispatch(modalActions.closeModal());
            dispatch({
                type: types.SET_INITIAL_NOTIFICATION_TARGET_GROUP_STATE
            });
        }
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(NotificationTargetGroupDialog)
);
