import classNames from "classnames";
import DefaultButton from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { colors } from "../../../utils/styles";
import * as _ from "lodash";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    root: {
        height: "40px",
        borderRadius: "6px",
        whiteSpace: "nowrap",
        fontWeight: "600",
        padding: "8px 20px",
        minWidth: "initial",
        transition: "none",
        "&:disabled": {
            backgroundColor: colors.C_DB15
        }
    },
    focusAction: {
        "&:focus": {
            color: theme.palette.C_LB,
            borderColor: theme.palette.C_DB20,
            boxShadow: `0 2px 0 0 ${theme.palette.C_DB20}`,
            backgroundColor: "transparent"
        }
    },
    focusPrimary: {
        "&:focus": {
            backgroundColor: theme.palette.C_LB90
        }
    },
    focusSecondary: {
        "&:focus": {
            backgroundColor: theme.palette.C_LB10
        }
    },

    action: {
        backgroundColor: "transparent",
        "&:hover": {
            color: theme.palette.C_LB,
            borderColor: theme.palette.C_DB20,
            boxShadow: `0 2px 0 0 ${theme.palette.C_DB20}`,
            backgroundColor: "transparent"
        },
        color: theme.palette.C_DB120,
        border: `solid 1px ${theme.palette.C_DB15}`,
        boxShadow: `0 2px 0 0 ${theme.palette.C_DB15}`
    },
    primary: {
        backgroundColor: theme.palette.C_LB,
        "&:hover": {
            backgroundColor: theme.palette.C_LB90
        },
        color: "white",
        border: `solid 1px ${theme.palette.C_LB}`,

        borderRadius: "6px"
    },
    secondary: {
        color: theme.palette.C_LB,
        backgroundColor: "transparent",
        "&:hover": {
            backgroundColor: theme.palette.C_LB10
        },
        border: `solid 1px ${theme.palette.C_LB}`,
        borderRadius: "6px"
    },
    warning: {
        color: theme.palette.C_R,
        backgroundColor: "transparent",
        "&:hover": {
            backgroundColor: theme.palette.C_R10
        },
        border: `solid 1px ${theme.palette.C_R}`,
        borderRadius: "6px"
    },
    disabled: {
        backgroundColor: theme.palette.C_DB15,
        border: "none",
        "&:hover": {
            backgroundColor: theme.palette.C_DB15,
            "@media (hover: none)": {
                backgroundColor: theme.palette.C_DB15
            }
        },
        color: `${colors.C_DB120} !important`,
        "&$disabled": {
            backgroundColor: theme.palette.C_DB15
        },
        fontWeight: "normal"
    },
    fullWidth: {
        width: "100%"
    },
    text: {
        color: "inherit",
        fontSize: theme.typography.F_S_N,
        fontWeight: "inherit",
        textAlign: "center"
    },
    smallFont: {
        fontSize: theme.typography.F_S
    },
    whiteBackground: {
        backgroundColor: theme.palette.C_W
    }
});

class Button extends PureComponent {
    render() {
        const {
            className,
            children,
            type,
            classes,
            disabled,
            variant,
            fullWidth,
            background,
            disabledReason,
            ...other
        } = this.props;

        const combinedClasses = classNames(
            classes.root,
            {
                [classes.primary]: variant === "primary",
                [classes.secondary]: variant === "secondary",
                [classes.action]: variant === "action",
                [classes.warning]: variant === "warning",
                [classes.whiteBackground]:
                    variant === "action" && background === "grey",
                [classes.fullWidth]: fullWidth === true,
                [classes.disabled]: Boolean(disabled)
            },
            className
        );
        const focusClassName = classNames({
            [classes.focusPrimary]: variant === "primary",
            [classes.focusSecondary]: variant === "secondary",
            [classes.focusAction]: variant === "action"
        });

        const content = _.isString(children) ? (
            <Typography className={classes.text}>{children}</Typography>
        ) : (
            children
        );
        return (
            <DefaultButton
                color={"default"}
                className={combinedClasses}
                disabled={disabled}
                disableRipple
                title={disabled ? disabledReason : undefined}
                classes={{
                    disabled: classes.disabled,
                    keyboardFocused: focusClassName
                }}
                //style={disabled ? disabledStyles : activeStyles}
                type={type}
                {...other}
            >
                {content}
            </DefaultButton>
        );
    }
}

Button.propTypes = {
    /**
     * Either a string or a component. If only a string. It will be wrapped in a typography component.
     */
    value: PropTypes.node,
    /**
     * Type of design for button. Primary for important actions. Secondary for less importand actions. Action for a button which will result in an action.
     */
    variant: PropTypes.oneOf(["primary", "secondary", "action", "warning"]),
    /**
     * Value passed to the button component. Is important for submitting forms with enter.
     */
    type: PropTypes.oneOf(["button", "submit"]),
    /**
     * Whether the button should be disabled. Causes the button to be unclickable. And gives a grey design.
     */
    disabled: PropTypes.bool,

    /**
     * Whether width of the button should fill the entire parent container.
     */
    fullWidth: PropTypes.bool,

    background: PropTypes.oneOf(["grey", "white"]),
    font: PropTypes.oneOf(["small", "default"]),
    /**
     * A tip for the user as to why the button is disabled.
     */
    disabledReason: PropTypes.string
};

Button.defaultProps = {
    variant: "primary",
    disabled: false,
    type: "button",
    fullWidth: false,
    background: "white",
    font: "default"
};
export default withStyles(styles)(Button);
