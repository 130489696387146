import React, { Component, Fragment } from "react";
import DoubleTextList from "../../common/lists/DoubleTextList";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { REQUEST_UPDATE_COMPANY_RULES } from "../../actionTypes";
import Switch from "../../common/forms/Switch";
class CalculationSettings extends Component {
    state = {
        initialValue: "",
        currentItem: null
    };
    createItems = (company, editable) => {
        const items = [
            {
                leftText: "Normaltid",
                rightText: company.normalHours + " timer"
                //onClick: () => this.handleItemClick('normalHours')
            },
            {
                leftText: "Overtid",
                rightText: company.hasWeekBasedCalculation
                    ? "Ukesbasert"
                    : "Månedsbasert"
                //onClick: () => this.handleItemClick('Not implemented')
            },
            {
                leftText: "Overtidstillegg",
                rightText: company.overtimeAdditional * 100 + "%"
                //onClick: () => this.handleItemClick('overtimeAdditional')
            },
            {
                leftText: "Feriedager",
                rightText: company.vacationDays + " dager"
                //onClick: () => this.handleItemClick('vacationDays')
            }
        ];
        if (editable) {
            items.push({
                leftText: "Avspaseringstrekk",
                rightText: (
                    <Switch
                        checked={company.subtractTimeOffFromOvertime === true}
                        onChange={this.handleSubtractTimeOffClick}
                    />
                )
            });
        }
        return items;
    };

    handleSubtractTimeOffClick = e => {
        const { company } = this.props;
        const update = {
            subtractTimeOffFromOvertime:
                company.subtractTimeOffFromOvertime !== true
        };
        this.props.updateCompanyRules(update, company.ruleId);
    };

    render() {
        const { company, editable } = this.props;

        let data = [];
        if (company) {
            data = this.createItems(company, editable);
        }
        return (
            <Fragment>
                <DoubleTextList
                    data={data}
                    title="Utregninger"
                    fadedSecondary={false}
                />
            </Fragment>
        );
    }
}
const mapStateToProps = ({ companies, auth }, { match }) => ({
    company: companies[match.params.companyId]
});

const mapDispatchToProps = (dispatch, ownProps) => {
    const companyId = ownProps.match.params.companyId;

    return {
        updateCompanyRules: (payload, ruleId) => {
            dispatch({
                type: REQUEST_UPDATE_COMPANY_RULES,
                payload,
                companyId,
                ruleId
            });
        }
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CalculationSettings)
);
