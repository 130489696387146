import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { colors } from "../../utils/styles";

const activeStyle = { fill: colors.C_R };
export default function({ active, ...props }) {
    return (
        <SvgIcon {...props} viewBox="0 0 18.12 20">
            <path d="M4.14,17.41a4.35,4.35,0,0,0,8,0Z" />
            <path d="M16.2,15.46,15,11.79v-2a5.41,5.41,0,0,1-1.21.13A5.51,5.51,0,0,1,10,.28,6.56,6.56,0,0,0,8.12,0,6.86,6.86,0,0,0,1.26,6.86v4.93L0,15.46A.59.59,0,0,0,.11,16a.6.6,0,0,0,.48.24H15.65a.58.58,0,0,0,.47-.24A.59.59,0,0,0,16.2,15.46Z" />
            <path
                style={active ? activeStyle : undefined}
                d="M13.77,0a4.35,4.35,0,1,0,4.35,4.35A4.36,4.36,0,0,0,13.77,0Z"
            />
        </SvgIcon>
    );
}
