import { put, all, call, select } from "redux-saga/effects";
import * as actions from "../actionTypes";
import { map, filter, forEach, uniqWith, uniq } from "lodash";
import * as UserApi from "../api/users";
import { takeFirstAsync, setConfirmAction } from "./helpers";
//WORKERS

export function* getAllUsers(action) {
    const { companyId, options } = action.payload;

    const users = yield call(UserApi.getUsersByCompany, companyId, options);

    yield put({ type: actions.SET_USERS, payload: users });
}

export function* getUserById(action) {
    const { userId } = action.payload;

    const user = yield call(UserApi.getUserById, userId);

    yield put({ type: actions.SET_USERS, payload: user });
}
export function* getShallowUserById(action) {
    const { userId } = action.payload;

    const user = yield call(UserApi.getShallowUserById, userId);

    yield put({ type: actions.SET_USERS, payload: user });
}
export function* getShallowUserByCompany(action) {
    const { companyId, options } = action.payload;
    const users = yield call(
        UserApi.getShallowUserByCompany,
        companyId,
        options
    );

    yield put({ type: actions.SET_SHALLOW_USERS, payload: users });
}

export function* getUserLog(action) {
    const { limit, userId } = action.payload;

    const state = yield select();

    const logs = yield call(UserApi.getUserLog, userId, limit);

    const itemsWithActorNotInStore = filter(
        logs,
        item => !state.shallowUsers[item.actor]
    );

    const itemsWithUniqueActor = uniqWith(
        itemsWithActorNotInStore,
        (item1, item2) => item1.actor === item2.actor
    );

    const shallowUsers = yield all(
        map(itemsWithUniqueActor, item => {
            return call(UserApi.getShallowUserById, item.actor);
        })
    );

    const shallowUsersObject = {};

    forEach(shallowUsers, arr => {
        forEach(arr, (item, key) => {
            shallowUsersObject[key] = item;
        });
    });

    yield all([
        put({ type: actions.SET_USER_LOGS, payload: logs }),
        put({ type: actions.SET_SHALLOW_USERS, payload: shallowUsersObject })
    ]);
}
export function* updateUser(action) {
    const { userId, payload } = action;

    yield call(UserApi.updateUser, userId, payload);

    yield put({
        type: actions.REQUEST_USER,
        payload: { userId }
    });
}

export function* updateFullTimeEquivalent(action) {
    const { userId, fullTimeEquivalentId, payload } = action;

    yield call(
        UserApi.updateFullTimeEquivalent,
        userId,
        fullTimeEquivalentId,
        payload
    );

    yield all([put({ type: actions.REQUEST_USER, payload: { userId } })]);
}

export function* createFullTimeEquivalent(action) {
    const { userId, payload } = action;

    yield call(UserApi.createFullTimeEquivalent, userId, payload);

    yield put({ type: actions.REQUEST_USER, payload: { userId } });
}

export function* deleteFullTimeEquivalent(action) {
    const { fullTimeEquivalentId, userId } = action.payload;

    yield call(UserApi.deleteFullTimeEquivalent, userId, fullTimeEquivalentId);

    yield put({ type: actions.REQUEST_USER, payload: { userId } });
}

export function* setUserComment(action) {
    const {
        comment,
        userId,
        year,
        month,
        companyId,
        departmentId
    } = action.payload;

    yield call(UserApi.setCommentForUser, comment, userId, year, month);
    yield put({
        type: actions.REQUEST_TIMEBANK_MONTHLY,
        payload: {
            companyId,
            date: new Date(year, month - 1),
            departmentId
        }
    });
}

export function* setIncomingBalance(action) {
    yield call(
        UserApi.setIncomingBalance,
        action.userId,
        action.year,
        action.payload
    );
    if (action.payload.fixedHours) {
        yield call(setConfirmAction, actions.REQUEST_SET_INCOMING_BALANCE);
    } else {
        yield call(
            setConfirmAction,
            actions.REQUEST_SET_INCOMING_VACATION_BALANCE
        );
    }

    yield put({
        type: actions.REQUEST_TIMEBANK_MONTHLY,
        payload: {
            userId: action.userId,
            date: new Date(action.year, 5, 1).getTime() // Doesnt matter which month. Only year is used (because its for user)
        }
    });
    yield put({
        type: actions.REQUEST_GET_INCOMING_BALANCE,
        payload: {
            userId: action.userId,
            year: action.year
        }
    });
}

export function* updateMultipleUsers({
    companyId,
    userIds,
    payload,
    departmentId
}) {
    const userUpdates = map(userIds, userId => {
        return call(UserApi.updateUser, userId, payload);
    });

    yield all(userUpdates);

    yield put({
        type: actions.REQUEST_ALL_USERS,
        payload: { companyId, departmentId }
    });
}
export function* getNotifications(action) {
    const state = yield select();

    const notifications = yield call(
        UserApi.getNotificationsByUser,
        action.userId
    );

    const usersToFetch = [];
    //Check if all users in notifications are present in state
    forEach(notifications, not => {
        if (!state.users[not.creator]) {
            usersToFetch.push(not.creator);
        }
        if (not.approvedBy && !state.users[not.approvedBy]) {
            usersToFetch.push(not.approvedBy);
        }
    });

    const uniqueUsersToFetch = uniq(usersToFetch);

    const promises = map(uniqueUsersToFetch, user =>
        call(UserApi.getShallowUserById, user)
    );

    const users = yield all(promises);

    let userPayload = {};
    forEach(users, (user, key) => {
        userPayload = {
            ...userPayload,
            ...user
        };
    });

    yield put({ type: actions.SET_SHALLOW_USERS, payload: userPayload });

    yield put({ type: actions.SET_USER_NOTIFICATIONS, payload: notifications });
}

export function* requestedPayout(action) {
    const { userId, payload } = action;

    yield call(UserApi.requestUserPayout, userId, payload);
}

export function* setUserPayout(action) {
    const { userId, date, payload, notificationRef, signedInUserId } = action;

    yield call(UserApi.createPayout, userId, payload);

    if (notificationRef) {
        yield put({
            type: actions.REQUEST_UPDATE_NOTIFICATION,
            notificationId: notificationRef,
            userId: signedInUserId,
            payload: {
                isApproved: true
            }
        });
    }
    yield put({
        type: actions.REQUEST_TIMEBANK_MONTHLY,
        payload: {
            userId,
            date
        }
    });
}

export function* getIncomingBalance(action) {
    const { userId, year } = action.payload;

    const incomingBalance = yield call(
        UserApi.getIncomingBalance,
        userId,
        year
    );

    yield put({
        type: actions.SET_INCOMING_BALANCE,
        userId,
        year,
        payload: incomingBalance
    });
}

export default function* rootSaga() {
    yield all([
        takeFirstAsync(actions.REQUEST_ALL_USERS, getAllUsers),
        takeFirstAsync(actions.REQUEST_UPDATE_USER, updateUser),
        takeFirstAsync(actions.REQUEST_SET_COMMENT, setUserComment),
        takeFirstAsync(actions.REQUEST_USER, getUserById),
        takeFirstAsync(
            actions.REQUEST_UPDATE_FULL_TIME_EQUIVALENT,
            updateFullTimeEquivalent
        ),
        takeFirstAsync(
            actions.REQUEST_SET_INCOMING_BALANCE,
            setIncomingBalance,
            false
        ),
        takeFirstAsync(actions.REQUEST_UPDATE_MULT_USERS, updateMultipleUsers),
        takeFirstAsync(actions.REQUEST_USER_NOTIFICATION, getNotifications),
        takeFirstAsync(actions.USER_REQUEST_PAYOUT, requestedPayout),
        takeFirstAsync(actions.REQUEST_SET_USER_PAYOUT, setUserPayout),
        takeFirstAsync(
            actions.REQUEST_GET_INCOMING_BALANCE,
            getIncomingBalance
        ),
        takeFirstAsync(actions.REQUEST_USER_SHALLOW, getShallowUserById),
        takeFirstAsync(
            actions.REQUEST_ALL_USERS_SHALLOW,
            getShallowUserByCompany
        ),
        takeFirstAsync(actions.REQUEST_USER_LOG, getUserLog),
        takeFirstAsync(
            actions.REQUEST_CREATE_FULL_TIME_EQUIVALENT,
            createFullTimeEquivalent
        ),
        takeFirstAsync(
            actions.REQUEST_DELETE_FULL_TIME_EQUIVALENT,
            deleteFullTimeEquivalent
        )
    ]);
}
