import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../actionTypes";
import { REQUEST_GET_INCOMING_BALANCE } from "../../../actionTypes";
import moment from "moment";
import {
    createCSV,
    createXLSX,
    downloadCSV,
    formatTimebankCSV
} from "../../../utils/exports";
import { getUserTimebankTableProps } from "./selectors";
import { columnDescriptions } from "../../../constants/strings";
import Table from "../../../common/table/Table";
import TimeGrid from "../../../common/time-grid/YearTimeGrid";
import Typography from "../../../common/Typography";

const tableStyles = { minWidth: "1400px" };
class UserTimebankTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columnData: [
                {
                    label: "Datatype",
                    type: "string",
                    id: "dataType",
                    bold: true,
                    width: "12%",
                    renderCell: this.renderDataType
                },
                {
                    label: "Inn",
                    type: "numeric",
                    id: "incomingBalance",
                    width: "6%"
                },
                {
                    label: "Jan",
                    type: "numeric",
                    id: 0,
                    width: "6%"
                },
                {
                    label: "Feb",
                    type: "numeric",
                    id: 1,
                    width: "6%"
                },
                {
                    label: "Mar",
                    type: "numeric",
                    id: 2,
                    width: "6%"
                },
                {
                    label: "Apr",
                    type: "numeric",
                    id: 3,
                    width: "6%"
                },
                {
                    label: "Mai",
                    type: "numeric",
                    id: 4,
                    width: "6%"
                },
                {
                    label: "Jun",
                    type: "numeric",
                    id: 5,
                    width: "6%"
                },
                {
                    label: "Jul",
                    type: "numeric",
                    id: 6,
                    width: "6%"
                },
                {
                    label: "Aug",
                    type: "numeric",
                    id: 7,
                    width: "6%"
                },
                {
                    label: "Sep",
                    type: "numeric",
                    id: 8,
                    width: "6%"
                },
                {
                    label: "Okt",
                    type: "numeric",
                    id: 9,
                    width: "6%"
                },
                {
                    label: "Nov",
                    type: "numeric",
                    id: 10,
                    width: "6%"
                },
                {
                    label: "Des",
                    type: "numeric",
                    id: 11,
                    width: "6%"
                },
                {
                    label: "Total",
                    type: "numeric",
                    id: "total",
                    bold: true,
                    width: "10%"
                }
            ]
        };
    }

    renderDataType = (value, item) => (
        <Typography bold size={"small"} title={columnDescriptions[item.id]}>
            {value}
        </Typography>
    );

    componentDidMount() {
        this.requestTimebank();
    }
    componentDidUpdate(prevProps) {
        //If new date has been set

        if (
            prevProps.currentDate !== this.props.currentDate ||
            (prevProps.hasStaleData === false &&
                this.props.hasStaleData === true)
        ) {
            this.requestTimebank();
        }
    }

    handleExportTable = type => {
        const { name, currentDate, tableData } = this.props;
        const { selected, columnData } = this.state;

        const date = moment(currentDate);

        const filename = (name + " " + date.format("YYYY"))
            .split(" ")
            .join("_");

        const [formattedData, formattedCols] = formatTimebankCSV(
            tableData,
            columnData
        );
        if (type === "csv") {
            downloadCSV(
                createCSV(formattedData, formattedCols, selected),
                filename
            );
        } else if (type === "xlsx") {
            createXLSX(formattedData, formattedCols, filename);
        }
    };

    requestTimebank = () => {
        const { currentDate } = this.props;

        this.props.fetchTimebank(currentDate);
        this.props.fetchIncomingBalance(currentDate);
        this.props.fetchTimebankDaily(currentDate);
    };

    render() {
        const { tableData, meta, timebankDaily } = this.props;
        const { columnData } = this.state;

        return (
            <Fragment>
                {meta.currentTab === "GRID" ? (
                    <TimeGrid
                        data={timebankDaily}
                        currentDate={meta.currentDate}
                    />
                ) : (
                    <Table
                        data={tableData}
                        columns={columnData}
                        style={tableStyles}
                    />
                )}
            </Fragment>
        );
    }
}

UserTimebankTable.defaultProps = {
    currentUser: {}
};

const mapDispatchToProps = (dispatch, { match }) => {
    const userId = match.params.userId;
    return {
        setTab: index => {
            dispatch({
                type: actions.UPDATE_TIMEBANK_META,
                payload: {
                    currentTab: index === 0 ? "GRID" : "TABLE"
                }
            });
        },
        fetchTimebank: currentDate => {
            dispatch({
                type: actions.REQUEST_TIMEBANK_MONTHLY,
                payload: {
                    userId: match.params.userId,
                    date: currentDate
                }
            });
        },
        fetchTimebankDaily: currentDate => {
            const year = moment(currentDate).year();
            dispatch({
                type: actions.REQUEST_USER_TIMEBANK_DAILY,
                payload: {
                    userId,
                    year
                }
            });
        },
        fetchIncomingBalance: currentDate => {
            dispatch({
                type: REQUEST_GET_INCOMING_BALANCE,
                payload: {
                    userId: match.params.userId,
                    year: moment(currentDate).year()
                }
            });
        }
    };
};

export default withRouter(
    connect(getUserTimebankTableProps, mapDispatchToProps)(UserTimebankTable)
);
