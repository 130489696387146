import React, { Component } from "react";
import { connect } from "react-redux";
import PaperHeader from "../../../components/layout/paper/PaperHeader";
import PaperHeaderTitle from "../../../components/layout/paper/PaperHeaderTitle";
import * as companySelectors from "../../../selectors/company";
import { withRouter } from "react-router-dom";
import * as modalActions from "containers/modals/actions";

export class KeyPerformanceIndicatorsHeader extends Component {
    render() {
        return (
            <PaperHeader
                title={
                    <PaperHeaderTitle
                        primary={"Nøkkeltall"}
                        secondary={`for ${this.props.companyName}`}
                    />
                }
                description={"Nøkkeltall"}
                withBorder
            />
        );
    }
}

const mapStateToProps = (state, props) => ({
    companyName: companySelectors.getCompanyName(state, props),
    keyPerformanceIndicators: companySelectors.getKeyPerformanceIndicators(
        state,
        props
    )
});

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        createKeyFigure: () => {
            dispatch(modalActions.createKeyPerformanceIndicator());
        }
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(KeyPerformanceIndicatorsHeader)
);
