import Typography from "common/Typography";
import { green, red } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import classNames from "classnames";
import * as utils from "../../utils/index";
import { isNumber } from "lodash";
import UpArrowIcon from "../../common/icons/UpArrowIcon";
import DownArrowIcon from "../../common/icons/DownArrowIcon";

const styles = theme => ({
    root: {
        alignItems: "center",
        paddingLeft: theme.spacing.gridMargin,
        minWidth: theme.spacing.keyFigure.width,
        height: theme.spacing.infoBar.height,
        display: "flex"
    },
    rootBorder: {
        ...theme.gridItemBorder,
        borderBottom: "none",
        borderTop: "none"
    },
    label: {
        marginBottom: "5px"
    },
    danger: {
        color: red[500]
    },
    positive: {
        color: green[500]
    },
    headContainer: {
        backgroundColor: theme.palette.C_DB8,
        height: theme.spacing.keyFigureTopHeight,
        width: "100%",
        display: "flex",
        alignItems: "center"
    },
    bodyContainer: {
        backgroundColor: theme.palette.C_DB3,
        height: theme.spacing.keyFigureBotHeight,
        width: "100%"
    },
    head: {
        paddingLeft: theme.spacing.gridMargin,
        paddingRight: theme.spacing.gridMargin
    },
    body: {
        display: "flex",
        alignItems: "flex-end",
        position: "relative",
        justifyContent: "space-between"
    },
    content: {
        height: "100%",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        width: "100%",
        padding: "15px 20px 15px 0"
    },
    textContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        marginBottom: "3px"
    },

    followupLetter: {
        fontSize: theme.typography.F_S,
        color: theme.palette.C_DB40,
        fontWeight: 600,
        marginLeft: "5px",
        margnBottom: "2px"
    },
    negArrow: {
        color: theme.palette.C_R,
        height: "11px",
        width: "15px",
        marginBottom: "2px",
        marginRight: "3px"
    },
    posArrow: {
        color: theme.palette.C_G,
        height: "11px",
        width: "15px",
        marginBottom: "2px",
        marginRight: "3px"
    },
    progress: {
        height: "10px",
        borderRadius: "6px"
    },

    progressColorDanger: {
        backgroundColor: theme.palette.C_DB20
    },
    progressColorWarning: {
        backgroundColor: theme.palette.C_DB20
    },
    progressColorNormal: {
        backgroundColor: theme.palette.C_DB20
    },
    progressColorPositive: {
        backgroundColor: theme.palette.C_DB20
    },
    progressBarColor: {
        backgroundColor: theme.palette.C_DB10
    },
    lineColor: {
        backgroundColor: theme.palette.C_R
    },
    important: {
        color: theme.palette.C_R
    },
    subValue: {
        marginLeft: "4px"
    },
    otherValue: {
        position: "absolute",
        right: 0,
        bottom: "3px"
    },
    largeHeader: {
        fontSize: "1.313rem" // 21px
    },
    change: {
        whiteSpace: "nowrap",
        fontSize: theme.typography.F_S_N
    }
});

class KeyFigure extends PureComponent {
    renderDuration = value => {
        return moment([1970, 0, 1, value]).format("hh:mm");
    };

    renderDays = value => value + "d";
    renderChange = (change, followupLetter) => {
        const { classes, theme } = this.props;
        let iconData = {};
        if (change === null) return;

        if (change > 0) {
            iconData = {
                text: utils.formatThousands(Math.abs(change)) + followupLetter,
                color: theme.palette.C_G,
                textIcon: <UpArrowIcon className={classes.posArrow} />
            };
        } else if (change < 0) {
            iconData = {
                text: utils.formatThousands(Math.abs(change)) + followupLetter,
                color: theme.palette.C_R,
                textIcon: <DownArrowIcon className={classes.negArrow} />
            };
        } else {
            iconData = {
                text: utils.formatThousands(change) + followupLetter,
                color: theme.palette.C_DB30,
                textIcon: null
            };
        }
        return (
            <div className={classes.textContainer}>
                {iconData.textIcon}
                <Typography
                    variant="body2"
                    style={{ color: iconData.color }}
                    className={classes.change}
                >
                    {iconData.text}
                </Typography>
            </div>
        );
    };

    renderByNormalHours = value => {
        const { classes } = this.props;
        return (
            <div className={classes.textContainer}>
                <Typography
                    title={"Prosent av normaltid"}
                    variant="body2"
                    className={classes.change}
                >
                    {value + "%"}
                </Typography>
            </div>
        );
    };
    render() {
        const {
            classes,
            name,
            value,
            type,
            border,
            filled,
            isImportant,
            change,
            byNormalHours
        } = this.props;

        const originalValue = value !== null ? value : 0; //Present number even if null

        let roundedValue = utils.round(originalValue, 2); // Force two decimals
        let followupLetter = "";
        const hasFormattedValue = roundedValue !== value;
        const formattedByThousand = utils.formatThousands(roundedValue);

        switch (type) {
            case "percent":
                followupLetter = "%";
                break;
            case "days":
                followupLetter = "d";
                break;

            case "hours":
                followupLetter = "t";
                break;
            case "currency":
                followupLetter = "'";
                break;

            case "duration":
                roundedValue = this.renderDuration(roundedValue);
                break;
            default:
                roundedValue = value;
        }
        const rootClasses = classNames(classes.root, {
            [classes.rootBorder]: Boolean(border)
        });

        const bodyWidth =
            formattedByThousand && formattedByThousand.length > 5
                ? "140px"
                : "80px";

        return (
            <div className={rootClasses}>
                <div className={classes.content}>
                    <Typography
                        color={isImportant ? "negative" : "superFaded"}
                        size={"smallMed"}
                    >
                        {name}
                    </Typography>
                    <div
                        className={classes.body}
                        style={{ minWidth: bodyWidth }}
                    >
                        <Typography
                            size={"specialSmall"}
                            title={
                                hasFormattedValue ? originalValue : undefined
                            }
                            bold
                            className={classNames({
                                [classes.largeHeader]:
                                    filled === undefined &&
                                    !isNumber(byNormalHours) &&
                                    !isNumber(change)
                            })}
                        >
                            {followupLetter
                                ? formattedByThousand + followupLetter
                                : formattedByThousand}
                        </Typography>
                        <div className={classes.graphContainer}>
                            {!isNumber(byNormalHours) && isNumber(change)
                                ? this.renderChange(change, followupLetter)
                                : null}
                            {isNumber(byNormalHours)
                                ? this.renderByNormalHours(byNormalHours)
                                : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export const propTypes = {
    /**
     * Name of key figure. Will be title
     */
    name: PropTypes.string.isRequired,
    /**
     * Value of number. Either in percent, hours or days.
     */
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    /**
     * Change from last period.
     */
    change: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * The key figure by the person / people's normal hours. WIll always get percent affix
     */
    byNormalHours: PropTypes.number,
    /**
     * The type of number. Will determine the number's affix
     */
    type: PropTypes.oneOf(["percent", "hours", "days", "duration", "numeric"]),

    /**
     * A value in percent of how much the key figure fill bar should be filled. If undefined, will
     * not show a filled bar.
     */
    filled: PropTypes.number,
    /**
     * Whether the key figure should have border on both sides
     */
    border: PropTypes.bool,

    isImportant: PropTypes.bool
};

export const defaultProps = {
    change: null,
    type: "numeric",
    border: false,
    isImportant: false
};

KeyFigure.propTypes = propTypes;
KeyFigure.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(KeyFigure);
