import React from "react";
import List from "./List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import { withStyles } from "@material-ui/core/styles";
import { isFunction } from "lodash";
import classNames from "classnames";

const styles = theme => ({
    secondaryText: {
        textAlign: "right",
        color: theme.palette.C_AB,
        fontWeight: 400
    },
    faded: {
        color: theme.palette.C_DB40
    }
});
const DoubleTextList = ({ classes, data, title, footer }) => {
    const renderItem = item => {
        const secondaryText = (
            <Typography variant="body1" className={classes.secondaryText}>
                {item.rightText}
            </Typography>
        );

        const secondaryView = isFunction(item.onClick) ? (
            <ButtonBase disableRipple onClick={item.onClick}>
                {secondaryText}
            </ButtonBase>
        ) : (
            secondaryText
        );
        return (
            <ListItem key={item.leftText}>
                <ListItemText
                    disableTypography
                    primary={
                        <Typography variant="body1">{item.leftText}</Typography>
                    }
                />
                {secondaryView}
            </ListItem>
        );
    };
    return (
        <List
            data={data}
            title={title}
            renderItem={renderItem}
            footer={footer}
        />
    );
};

DoubleTextList.defaultProps = {
    fadedSecondary: false
};
export default withStyles(styles)(DoubleTextList);
