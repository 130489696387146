import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "common/poppers/MenuItem";
import Avatar from "common/icons/Avatar";
import { getColorFromId } from "utils";
import Typography from "common/Typography";
import { Link } from "react-router-dom";

const styles = theme => ({
    profileMenuItem: {
        justifyContent: "flex-start",
        paddingTop: "5px",
        alignItems: "center",
        height: "50px",
        borderBottom: `solid 1px ${theme.palette.C_DB10}`,
        "&:hover": {
            "& $displayName": {
                color: theme.palette.C_LB
            },
            opacity: 0.9
        }
    },
    avatarText: {
        display: "flex",
        flexDirection: "column",
        marginLeft: "10px",
        width: "160px"
    },
    root: {
        width: "230px",
        "&:last-child": {
            border: "none"
        }
    },
    displayName: {},
    email: {
        fontWeight: "normal"
    },
    item: {
        borderBottom: `solid 1px ${theme.palette.C_DB10}`,
        height: "40px"
    },
    logout: {
        height: "40px"
    }
});

class ProfilePopup extends PureComponent {
    render() {
        const {
            classes,
            onLogout,
            signedInUser,
            onProfileClick,
            onTutorialClick
        } = this.props;

        return (
            <div className={classes.root}>
                <Link
                    to={`/settings/${signedInUser.companyId}/profile`}
                    onClick={onProfileClick}
                >
                    <MenuItem
                        className={classes.profileMenuItem}
                        onClick={onProfileClick}
                    >
                        <Avatar
                            firstName={signedInUser.firstName}
                            lastName={signedInUser.lastName}
                            color={getColorFromId(signedInUser.id)}
                            photoURL={signedInUser.photoURL}
                            small
                        />
                        <div className={classes.avatarText}>
                            <Typography
                                className={classes.displayName}
                                size={"smallMed"}
                                singleLine
                            >
                                {signedInUser.displayName}
                            </Typography>
                            <Typography
                                color={"superFaded"}
                                size={"smallMed"}
                                className={classes.email}
                                singleLine
                            >
                                {signedInUser.email}
                            </Typography>
                        </div>
                    </MenuItem>
                </Link>
                <Link to={"/tutorial/timeline"}>
                    <MenuItem
                        onClick={onTutorialClick}
                        className={classes.item}
                    >
                        {"Tutorial"}
                    </MenuItem>
                </Link>
                <MenuItem onClick={onLogout} className={classes.logout}>
                    {"Logg ut"}
                </MenuItem>
            </div>
        );
    }
}

export default withStyles(styles)(ProfilePopup);
