import React, { Fragment } from "react";
import GridIcon from "common/icons/GridIcon";
import ListIcon from "common/icons/ListIcon";
import Slider from "common/forms/Slider";
import PaperHeader from "components/layout/paper/PaperHeader";
import { componentDescriptions, dialog } from "constants/strings";
import PaperHeaderTitle from "components/layout/paper/PaperHeaderTitle";
import * as actions from "actionTypes";
import { connect } from "react-redux";
import * as timebankSelectors from "../selectors";
import * as userSelectors from "selectors/user";
import { withRouter } from "react-router-dom";
import { userGroups } from "../../../actionTypes";
import IconPopupMenu from "../../../common/poppers/IconPopupMenu";
import SelectAndTextFieldDialog from "../../../common/dialogs/SelectAndTextFieldDialog";
import { REQUEST_SET_INCOMING_BALANCE } from "actionTypes";
import * as selectors from "./selectors";
import CSVIcon from "common/icons/CSVIcon";
import XLSXIcon from "common/icons/XLSXIcon";
import { columnData } from "./selectors";

import moment from "moment";
import {
    createCSV,
    createXLSX,
    downloadCSV,
    formatTimebankCSV
} from "../../../utils/exports";
import IconBar from "../../../common/table/IconBar";

const primaryTitle = isGrid => (isGrid ? "Timekontroll" : "Timerapport");

const options = ["IB Timesaldo", "IB Feriesaldo"];

class UserTimebankHeader extends React.Component {
    state = {
        menuDialog: { currentlyOpen: null }
    };

    handleMenuChange = option => {
        if (option === 0) {
            // Incoming Balance Hours
            this.setState({ menuDialog: { currentlyOpen: "fixedHours" } });
        } else if (option === 1) {
            //Incoming Vacation Hours
            this.setState({
                menuDialog: { currentlyOpen: "fixedVacationDays" }
            });
        }
    };

    resetMenuDialogs = () => {
        this.setState({ menuDialog: {} });
    };

    handleMenuConfirm = (key, value, year, comment) => {
        if (key !== undefined && value !== undefined && year !== undefined) {
            this.props.setIncomingBalance(year, {
                [key]: value,
                comment: comment || ""
            });
        }
        this.resetMenuDialogs();
    };

    handleExportTable = type => () => {
        const { currentUser, currentDate, tableData } = this.props;

        const date = moment(currentDate);

        const filename = (currentUser.displayName + " " + date.format("YYYY"))
            .split(" ")
            .join("_");

        const [formattedData, formattedCols] = formatTimebankCSV(
            tableData,
            columnData
        );
        if (type === "csv") {
            downloadCSV(createCSV(formattedData, formattedCols), filename);
        } else if (type === "xlsx") {
            createXLSX(formattedData, formattedCols, filename);
        }
    };

    renderDialogs = () => {
        const { menuDialog } = this.state;
        const {
            currentUser,
            incomingBalance,
            possibleBalanceYears
        } = this.props;

        return (
            <Fragment>
                <SelectAndTextFieldDialog
                    title={dialog.incomingBalance.timeBalance.title}
                    description={dialog.incomingBalance.timeBalance.description}
                    actionButton={
                        dialog.incomingBalance.timeBalance.actionButton
                    }
                    onCancel={this.resetMenuDialogs}
                    onConfirm={({ textValue, selectValue }, comment) =>
                        this.handleMenuConfirm(
                            "fixedHours",
                            textValue,
                            possibleBalanceYears[selectValue],
                            comment
                        )
                    }
                    name={currentUser.displayName}
                    values={possibleBalanceYears}
                    withComment
                    initialValue={incomingBalance.fixedHours}
                    initialSelect={possibleBalanceYears.length - 1}
                    open={menuDialog.currentlyOpen === "fixedHours"}
                    numeric
                    TextFieldProps={{
                        subLabel: "timer",
                        label: "Antall timer"
                    }}
                    SelectProps={{
                        label: "For år"
                    }}
                />
                <SelectAndTextFieldDialog
                    title={dialog.incomingBalance.vacationBalance.title}
                    description={
                        dialog.incomingBalance.vacationBalance.description
                    }
                    actionButton={
                        dialog.incomingBalance.vacationBalance.actionButton
                    }
                    onCancel={this.resetMenuDialogs}
                    onConfirm={({ textValue, selectValue }, comment) =>
                        this.handleMenuConfirm(
                            "fixedVacationDays",
                            textValue,
                            possibleBalanceYears[selectValue],
                            comment
                        )
                    }
                    name={currentUser.displayName}
                    values={possibleBalanceYears}
                    initialValue={incomingBalance.fixedVacationDays}
                    initialSelect={possibleBalanceYears.length - 1}
                    withComment
                    open={menuDialog.currentlyOpen === "fixedVacationDays"}
                    numeric
                    TextFieldProps={{
                        label: "Antall dager",
                        subLabel: "dager"
                    }}
                    SelectProps={{
                        label: "For år"
                    }}
                />
            </Fragment>
        );
    };
    render() {
        const { meta, currentUser, hasEditAuthorization } = this.props;

        const backLink = hasEditAuthorization
            ? `/dashboard/${currentUser.companyId}`
            : undefined;

        const isGrid = meta.currentTab === "GRID";
        return (
            <Fragment>
                <PaperHeader
                    description={
                        isGrid
                            ? componentDescriptions.timecontrolUser
                            : componentDescriptions.timereportUser
                    }
                    title={
                        <PaperHeaderTitle
                            primary={[
                                primaryTitle(isGrid),
                                currentUser.displayName
                            ]}
                            backLink={backLink}
                        />
                    }
                    menu={
                        hasEditAuthorization ? (
                            <IconPopupMenu
                                items={options}
                                onChange={this.handleMenuChange}
                            />
                        ) : null
                    }
                >
                    {!isGrid ? (
                        <IconBar
                            icons={[
                                {
                                    component: <CSVIcon />,
                                    onClick: this.handleExportTable("csv"),
                                    tooltip: "Eksporter til CSV"
                                },
                                {
                                    component: <XLSXIcon />,
                                    onClick: this.handleExportTable("xlsx"),
                                    tooltip: "Eksporter til Excel"
                                }
                            ]}
                        />
                    ) : null}
                    <Slider
                        background={"paper"}
                        selectedTab={isGrid ? 0 : 1}
                        onSelectTab={this.props.setTab}
                        tabs={[<GridIcon />, <ListIcon />]}
                        variant={"icon"}
                    />
                </PaperHeader>
                {this.renderDialogs()}
            </Fragment>
        );
    }
}

const mapStateToProps = (state, props) => ({
    meta: timebankSelectors.getTimebankMeta(state, props),
    currentUser: userSelectors.getCurrentUser(state, props),
    hasEditAuthorization: userSelectors.getHasEditAuthorization(state, props),
    incomingBalance: selectors.getIncomingBalance(state, props),
    possibleBalanceYears: selectors.getPossibleBalanceYears(state, props),
    tableData: selectors.getComputedTableData(state, props)
});
const mapDispatchToProps = (dispatch, ownProps) => {
    const { userId } = ownProps.match.params;
    return {
        setTab: index => {
            dispatch({
                type: actions.UPDATE_TIMEBANK_META,
                payload: {
                    currentTab: index === 0 ? "GRID" : "TABLE"
                }
            });
        },
        onChangeSearch: searchString => {
            dispatch({
                type: actions.UPDATE_TIMEBANK_META,
                payload: {
                    searchString
                }
            });
        },
        setIncomingBalance: (year, payload) => {
            dispatch({
                type: REQUEST_SET_INCOMING_BALANCE,
                year,
                userId,
                payload
            });
        }
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(UserTimebankHeader)
);
