import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import CardHeader from "components/layout/card/CardHeader";
import { colors } from "utils/styles";

const styles = theme => ({
    root: {
        display: "flex",
        flexFlow: "column",
        minWidth: "250px",
        "@media (max-width: 1200px)": {
            flex: "1 0 49%",
            "&:nth-child(2n)": {
                borderLeft: `1px solid ${theme.palette.C_DB10}`
            }
        },
        "@media (min-width: 1200px)": {
            flex: "1 0 21%",
            "&:not(:last-child)": {
                borderRight: `1px solid ${theme.palette.C_DB10}`
            }
        }
    },
    contentContainer: {
        height: "100%",
        backgroundColor: theme.palette.C_W,
        overflow: "hidden"
    }
});

class KeyPerformanceIndicatorsColumnSection extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { classes, title, content, headerIcon } = this.props;

        return (
            <div className={classes.root}>
                <CardHeader
                    title={title}
                    icon={headerIcon}
                    style={{
                        padding: "0 20px",
                        boxSizing: "border-box",
                        borderBottom: `solid 1px ${colors.C_DB10}`,
                        backgroundColor: colors.C_DB5,
                        borderTopLeftRadius: "6px",
                        borderTopRightRadius: "6px"
                    }}
                />
                <div className={classes.contentContainer}>{content}</div>
            </div>
        );
    }
}

export default withStyles(styles)(KeyPerformanceIndicatorsColumnSection);
