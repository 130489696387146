import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import classnames from "classnames";
import InputField from "../forms/text-fields/InputField";
import DialogTextContainer from "./dialogContent/DialogTextContainer";

const transitionDuration = { enter: 300, exit: 0 };
const styles = theme => ({
    dialog: {
        padding: "0 40px 60px 40px",
        width: "350px"
    },
    input: {
        marginBottom: "20px !important"
    },
    buttonGroup: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        marginTop: "25px"
    },
    cancelBtn: {
        width: "35%"
    },
    confirmBtn: {
        width: "60%"
    },

    paper: {
        overflowY: "auto",
        maxHeight: "100vh"
    },
    okButton: {
        width: "100%"
    },
    confirmMargin: {
        margin: "0 20px"
    },
    menuContainer: {
        position: "absolute",
        right: "20px",
        top: "15px"
    }
});
class SimpleDialog extends Component {
    state = {
        commentValue: ""
    };

    onConfirm = (...args) => {
        console.log("Args", args);
        this.props.buttonGroup.props.onConfirm(
            ...args,
            this.state.commentValue
        );

        this.setState({ commentValue: "" });
    };

    render() {
        const {
            classes,
            open,
            children,
            title,
            name,
            description,
            withComment,
            buttonGroup,
            menu,
            errorMessage
        } = this.props;

        const { commentValue } = this.state;

        const clonedChildren = React.Children.map(children, child => {
            if (!child) return null;
            return React.cloneElement(child, {
                className: classnames(child.props.className, classes.input)
            });
        });

        const hijackedButtonGroup = React.cloneElement(buttonGroup, {
            onConfirm: this.onConfirm
        });

        return (
            <Dialog
                open={open}
                classes={{
                    paper: classes.paper
                }}
                transitionDuration={transitionDuration}
            >
                <form className={classes.dialog}>
                    <div className={classes.menuContainer}>{menu}</div>
                    <DialogTextContainer
                        name={name}
                        errorMessage={errorMessage}
                        title={title}
                        description={description}
                    />
                    {clonedChildren}
                    {withComment ? (
                        <InputField
                            type="text"
                            onChange={e =>
                                this.setState({ commentValue: e.target.value })
                            }
                            value={commentValue}
                            className={classes.input}
                            fullWidth
                            padding="default"
                            label="Kommentar"
                        />
                    ) : null}
                    {hijackedButtonGroup}
                </form>
            </Dialog>
        );
    }
}

SimpleDialog.propTypes = {
    description: PropTypes.string,
    menu: PropTypes.node,
    buttonGroup: PropTypes.node
};
SimpleDialog.defaultProps = {
    description: "",
    menu: null
};

export default withStyles(styles)(SimpleDialog);
