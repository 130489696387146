import classNames from "classnames";
import MoreIcon from "../icons/MoreIcon";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import ButtonPopupMenu, { SelectPopupPropTypes } from "./ButtonPopupMenu";

const styles = theme => ({
    root: {
        display: "flex",
        zIndex: 10000,
        maxHeight: "500px",
        color: theme.palette.C_DB30
    },
    popperClose: {
        pointerEvents: "none"
    },
    menuItem: {
        height: "50px",
        minWidth: "150px"
    },
    menuTypo: {
        textOverflow: "ellipsis",
        display: "inline",
        whiteSpace: "nowrap",
        overflowX: "hidden"
    },
    button: {
        "&:hover": {
            color: theme.palette.C_LB
        },
        height: "25px",
        width: "48px",
        padding: 0
    },

    icon: {
        width: "16px",
        height: "16px"
    }
});

class IconPopupMenu extends React.Component {
    render() {
        const { classes, icon, className, ...other } = this.props;

        return (
            <ButtonPopupMenu
                {...other}
                target={
                    <IconButton
                        aria-label="More"
                        aria-haspopup="true"
                        className={classNames(classes.button, className)}
                        disableRipple
                        onClick={this.props.handleToggle}
                    >
                        {React.cloneElement(icon, {
                            className: classNames(
                                classes.icon,
                                icon.props.className
                            )
                        })}
                    </IconButton>
                }
                keepTargetChildren
                value={-1}
                preventOverflow
            />
        );
    }
}

IconPopupMenu.propTypes = {
    /**
     * See description in SelectPopup
     */
    ...SelectPopupPropTypes,

    /**
     * An icon to be used a the menu icon.
     */
    icon: PropTypes.node,

    placement: PropTypes.string
};

IconPopupMenu.defaultProps = {
    icon: <MoreIcon />,
    placement: "bottom-end",
    inTable: false
};

export default withStyles(styles)(IconPopupMenu);
