import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { colors } from "../../utils/styles";

const cls1 = {
    fill: colors.C_LB
};
const cls2 = {
    opacity: 0.4,
    fill: colors.C_LB
};
const cls1_W = {
    fill: colors.C_W
};
const cls2_W = {
    opacity: 0.4,
    fill: colors.C_W
};
export default function LogoIcon({ white, ...other }) {
    return (
        <SvgIcon {...other} viewBox="0 0 75 75">
            <path
                style={white ? cls1_W : cls1}
                d="M9.79,0H35.52A24.48,24.48,0,0,1,60,24.48v1A24.48,24.48,0,0,1,35.52,50H0a0,0,0,0,1,0,0V9.79A9.79,9.79,0,0,1,9.79,0Z"
            />
            <path
                style={white ? cls2_W : cls2}
                d="M0,25H50.52A24.48,24.48,0,0,1,75,49.48v1A24.48,24.48,0,0,1,50.52,75H9.79A9.79,9.79,0,0,1,0,65.21V25a0,0,0,0,1,0,0Z"
            />
        </SvgIcon>
    );
}
