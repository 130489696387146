import React, { Component } from "react";
import { connect } from "react-redux";
import InfoBar from "components/infobar/InfoBar";
import SelectMenu from "common/poppers/ButtonPopupMenu";
import * as selectors from "./selectors";
import { withRouter } from "react-router-dom";
import * as _ from "lodash";
import MediaQuery from "react-responsive";
import { users } from "constants/mediaQueries";
import { fetchUsers, setShallowUserFilters } from "./actions";
import * as userSelectors from "selectors/user";
import { userGroups } from "actionTypes";
import Authenticated from "../../common/auth/Authenticated";

const active = ["Aktiv", "Inaktiv"];

export class UsersInfoBar extends Component {
    render() {
        const {
            keyFigures,
            activeIndex,
            departments,
            filters,
            displayType,
            activeDepartment,
            signedInUser
        } = this.props;

        return (
            <MediaQuery query={users.showOnlyTotalKeyFigure}>
                {matches => {
                    return (
                        <InfoBar
                            keyFigures={matches ? [keyFigures[0]] : keyFigures}
                        >
                            <SelectMenu
                                items={_.map(departments, d => d.name)}
                                value={_.findIndex(
                                    departments,
                                    d => d.id === activeDepartment
                                )}
                                onChange={i =>
                                    this.props.onDepartmentChange(
                                        filters,
                                        departments[i],
                                        displayType
                                    )
                                }
                                disabled={
                                    signedInUser.userGroup !== userGroups.ADMIN
                                }
                            />
                            <Authenticated userGroups={[userGroups.ADMIN]}>
                                <SelectMenu
                                    items={active}
                                    value={activeIndex}
                                    onChange={selected =>
                                        this.props.onChangeActivityState(
                                            filters,
                                            selected,
                                            displayType
                                        )
                                    }
                                />
                            </Authenticated>
                        </InfoBar>
                    );
                }}
            </MediaQuery>
        );
    }
}

const mapStateToProps = (state, props) => ({
    keyFigures: selectors.getUsersKeyFigures(state, props),
    signedInUser: userSelectors.getSignedInUser(state, props),
    filters: selectors.getUserFilters(state, props),
    activeIndex: selectors.getActiveIndex(state, props),
    activeDepartment: selectors.getCurrentDepartment(state, props),
    departments: selectors.getDepartmentsByAuth(state, props),
    displayType: selectors.getDisplayType(state, props)
});

const mapDispatchToProps = (dispatch, ownProps) => {
    const { companyId } = ownProps.match.params;
    return {
        onChangeActivityState: (filters, selected, displayType) => {
            if (selected === 0) filters.includeUsers = "active";
            else if (selected === 1) filters.includeUsers = "inactive";

            dispatch(fetchUsers(filters, companyId, displayType));
            dispatch(setShallowUserFilters(filters));
        },

        onDepartmentChange: (filters, department, displayType) => {
            filters.departmentId = department.id;
            dispatch(fetchUsers(filters, companyId, displayType));
            dispatch(setShallowUserFilters(filters));
        }
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(UsersInfoBar)
);
