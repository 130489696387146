import React, { PureComponent } from "react";
import { colors } from "../../../utils/styles";
import jss from "jss";
import * as utils from "../../../utils/index";
import Avatar from "../../icons/Avatar";
import { Link } from "react-router-dom";
import camelCase from "jss-camel-case";
import jssNested from "jss-nested";

jss.use(camelCase(), jssNested());

const styles = {
    nameRoot: {
        display: "flex",
        "align-items": "center",
        width: "100%"
    },
    name: {
        marginLeft: "8px",
        textAlign: "left",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "160px",
        "&:hover": {
            color: colors.C_LB,
            textDecoration: "underline"
        },
        color: colors.C_AB
    }
};

const { classes } = jss.createStyleSheet(styles).attach();

class AvatarCell extends PureComponent {
    render() {
        const { item } = this.props;
        if (!item) return null;

        return (
            <div className={classes.nameRoot}>
                <Avatar
                    firstName={item.firstName}
                    lastName={item.lastName}
                    color={utils.getColorFromId(item.id)}
                    photoURL={item.photoURL}
                    extraSmall
                />
                {item.nameLink ? (
                    <Link to={item.nameLink}>
                        <p className={classes.name}>{`${item.firstName} ${
                            item.lastName
                        }`}</p>
                    </Link>
                ) : (
                    <p className={classes.name}>{`${item.firstName} ${
                        item.lastName
                    }`}</p>
                )}
            </div>
        );
    }
}

export default AvatarCell;
