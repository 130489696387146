import {
    HELP_DATA_REQUEST,
    SET_HELP_DATA,
    REQUEST_ERROR,
    SYNCHRONIZE_HELP_DATA,
    TRACK_EVENT,
    SET_AUTH,
    TRACK_EVENT_THROTTLED,
    NAVIGATE
} from "../actionTypes";
import * as CommonApi from "../api/common";
import {
    fork,
    takeEvery,
    call,
    all,
    put,
    select,
    take
} from "redux-saga/effects";
import ReactGA from "react-ga";
import { browserHistory } from "../App";

//WORKERS

export function* getHelpData(action) {
    try {
        const [FAQData, calculationData] = yield all([
            call(CommonApi.getHelpFAQData),
            call(CommonApi.getHelpCalculationData)
        ]);

        yield put({
            type: SET_HELP_DATA,
            payload: { FAQData, calculationData }
        });
    } catch (error) {
        yield put({ type: REQUEST_ERROR, payload: error });
    }
}

export function* synchronizeHelpData(action) {
    try {
        const state = yield select();
        const { FAQData, calculationData } = state.help;

        yield all([
            call(CommonApi.setHelpFAQData, FAQData),
            call(CommonApi.setHelpCalculationData, calculationData)
        ]);
    } catch (error) {
        put({ type: REQUEST_ERROR, payload: error });
    }
}

export function* trackEvent(reduxAction) {
    try {
        const {
            category,
            action,
            value,
            label,
            nonInteraction = false
        } = reduxAction.payload;
        yield call(ReactGA.event, {
            category,
            action,
            label,
            nonInteraction,
            value
        });
    } catch (error) {
        console.log("Error", error);
    }
}

//WORKERS

export function navigateTo(action) {
    browserHistory.push(action.payload.url);
}

export function* setAuth(action) {
    try {
        if (action.payload && action.payload.user && action.payload.user.id) {
            yield call(ReactGA.set, { userId: action.payload.user.id });
        }
    } catch (error) {
        console.log("Error", error);
    }
}
//WATCHERS
const wait = ms =>
    new Promise(resolve => {
        setTimeout(() => resolve(), ms);
    });

export function* getHelpDataWatcher() {
    yield takeEvery(HELP_DATA_REQUEST, getHelpData);
}
export function* synchronizeHelpDataWatcher() {
    yield takeEvery(SYNCHRONIZE_HELP_DATA, synchronizeHelpData);
}
export function* trackEventWatcher() {
    if (process.env.NODE_ENV === "production") {
        yield takeEvery(TRACK_EVENT, trackEvent);
    }
}
export function* trackEventThrottledWatcher() {
    if (process.env.NODE_ENV === "production") {
        while (true) {
            const action = yield take(TRACK_EVENT_THROTTLED);
            yield call(trackEvent, action);
            yield wait(5000);
        }
    }
}
export function* setAuthWatcher() {
    if (process.env.NODE_ENV === "production") {
        yield takeEvery(SET_AUTH, setAuth);
    }
}
//ROOT
export default function* rootSaga() {
    yield all([
        fork(getHelpDataWatcher),
        fork(synchronizeHelpDataWatcher),
        fork(trackEventWatcher),
        fork(trackEventThrottledWatcher),
        fork(setAuthWatcher),
        takeEvery(NAVIGATE, navigateTo)
    ]);
}
