import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import * as types from "actionTypes";
import * as authSelectors from "selectors/auth";
import * as userSelectors from "selectors/user";
import LogoWithText from "common/icons/LogoWithText";
import PopupHeader from "common/poppers/PopupHeader";
import PopupDivider from "common/poppers/PopupDivider";
import MenuItem from "common/poppers/MenuItem";

import * as _ from "lodash";
import MenuItemPlaceholder from "common/poppers/MenuItemPlaceholder";

class AppMenu extends PureComponent {
    componentDidMount() {
        this.props.fetchPossibleUsers(this.props.auth.personId);
    }

    onUserClick = userId => event => {
        this.props.updateCurrentUser(this.props.auth.personId, userId);
    };
    renderPossibleUsers = () => {
        return _.map(this.props.possibleUsers, user => {
            const isSelected = user.userId === this.props.signedInUser.id;
            return (
                <MenuItem
                    onClick={this.onUserClick(user.userId)}
                    selected={isSelected}
                    key={user.userId}
                    value={user.userId}
                >{`${user.companyName} (${user.userFirstName || ""} ${user.userLastName || ""})`}</MenuItem>
            );
        });
    };
    render() {
        const isLoaded =
            this.props.possibleUsers.length === 0 &&
            !this.props.isPossibleUsersLoaded;
        return (
            <Fragment>
                <PopupHeader icon={<LogoWithText />} />
                <PopupDivider>{"selskaper"}</PopupDivider>
                {isLoaded ? (
                    <MenuItemPlaceholder count={2} />
                ) : (
                    <Fragment>{this.renderPossibleUsers()}</Fragment>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        possibleUsers: authSelectors.getPossibleUsers(state, props),
        isPossibleUsersLoaded: authSelectors.isPossibleUsersLoaded(
            state,
            props
        ),

        auth: state.auth,
        signedInUser: userSelectors.getSignedInUser(state, props)
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        fetchPossibleUsers: personId => {
            dispatch({
                type: types.REQUEST_POSSIBLE_CLIENTS,
                personId
            });
        },
        updateCurrentUser: (personId, userId) => {
            dispatch({
                type: types.REQUEST_UPDATE_CLIENT,
                personId,
                payload: {
                    currentUser: userId
                }
            });
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AppMenu);
