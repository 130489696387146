import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import LogoIcon from "common/icons/LogoIcon";
import Typography from "common/Typography";
import classNames from "classnames";

const styles = theme => ({
    root: {
        display: "flex",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: "fixed",
        backgroundColor: theme.palette.C_DB5,
        justifyContent: "center",
        alignItems: "center",
        zIndex: -999999999
    },
    activeRoot: {
        zIndex: 99999999999
    },
    wrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    icon: {
        width: "60px",
        height: "60px",
        marginBottom: "30px"
    },
    text: {
        display: "flex"
    },
    displayText: {
        marginRight: "4px"
    },
    "@keyframes raise": {
        /**
         * At the start of the animation the dot
         * has an opacity of .2
         */
        "0%": {
            opacity: "1"
        },
        /**
         * At 20% the dot is fully visible and
         * then fades out slowly
         */
        "50%": {
            opacity: ".1"
        },
        /**
         * Until it reaches an opacity of .2 and
         * the animation can start again
         */
        "100%": {
            opacity: "1"
        }
    },
    dot: {
        marginLeft: "1px"
    },
    middleDot: {
        animationDelay: "400ms"
    },
    lastDot: {
        animationDelay: "800ms"
    }
});

class Loader extends PureComponent {
    render() {
        const { classes, displayText, isActive, ...other } = this.props;
        return (
            <div
                className={classNames(classes.root, {
                    [classes.activeRoot]: isActive
                })}
                {...other}
            >
                <div className={classes.wrapper}>
                    <LogoIcon className={classes.icon} />

                    <div className={classes.text}>
                        <Typography
                            size={"specialSmall"}
                            className={classes.displayText}
                        >
                            {displayText}
                        </Typography>

                        <Typography
                            className={classNames(
                                classes.dot,
                                classes.firstDot
                            )}
                            size={"specialSmall"}
                        >
                            {"."}
                        </Typography>
                        <Typography
                            className={classNames(
                                classes.dot,
                                classes.middleDot
                            )}
                            size={"specialSmall"}
                        >
                            {"."}
                        </Typography>
                        <Typography
                            className={classNames(classes.dot, classes.lastDot)}
                            size={"specialSmall"}
                        >
                            {"."}
                        </Typography>
                    </div>
                </div>
            </div>
        );
    }
}

Loader.propTypes = {
    displayText: PropTypes.string
};
Loader.defaultProps = {
    displayText: "Loading"
};

export default withStyles(styles)(Loader);
