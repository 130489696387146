import * as actions from "../../actionTypes";
import * as utils from "../../utils";
const initialNotificationSettingsState = {
    selectedDepartment: null,
    selectedTargets: [],
    selectedTargetGroup: null,
    targetGroups: {}
};

export default function notificationSettings(
    state = initialNotificationSettingsState,
    action
) {
    switch (action.type) {
        case actions.SET_NOTIFICATIONS_SETTINGS:
            return {
                ...state,
                ...action.payload
            };
        case actions.SET_NOTIFICATION_GROUPS:
            return {
                ...state,
                targetGroups: utils.addKeysToObjectValue(
                    action.payload,
                    "companyId",
                    action.companyId
                )
            };

        case actions.SET_INITIAL_NOTIFICATION_TARGET_GROUP_STATE:
            return {
                ...initialNotificationSettingsState,
                targetGroups: state.targetGroups
            };

        default:
            return state;
    }
}
