import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Popup from "../poppers/Popup";
import SelectButton from "../poppers/PopupButton";
import FormControl from "./FormControl";
import MenuItem from "../poppers/MenuItem";
import * as _ from "lodash";
import RootRef from "@material-ui/core/RootRef";
import Menu from "common/poppers/Menu";
import InputField from "./text-fields/InputField";
import ReactDOM from "react-dom";
import CaretIcon from "common/icons/CaretIcon";
import Typography from "../Typography";

const styles = theme => ({
    caret: {
        color: theme.palette.C_DB40
    },
    fullWidth: {
        width: "100%"
    },
    button: {
        height: "40px",
        padding: "0 20px 0 15px",
        fontSize: theme.typography.F_S,
        color: theme.palette.C_DB120,
        fontWeight: "600",
        minWidth: "160px",

        "&:hover": {
            color: theme.palette.C_LB,
            backgroundColor: "white",
            "& $caret": {
                color: theme.palette.C_LB
            },
            cursor: "pointer"
        },
        backgroundColor: theme.palette.C_W
    },
    buttonOpen: {
        color: theme.palette.C_LB,
        backgroundColor: "white",
        "& $caret": {
            color: theme.palette.C_LB,
            transform: "rotate(180deg)"
        }
    },
    fitToDropdown: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
    },
    searchInnerInput: {
        fontSize: theme.typography.F_S,
        color: theme.palette.C_DB120,
        fontWeight: "600",
        "& $searchCaret": {
            transform: "rotate(180deg)"
        },
        paddingLeft: "14px",
        marginBottom: "-1px",
        cursor: "pointer"
    },
    searchCaret: {
        width: "10px",
        marginRight: "-3px"
    },
    searchInputTag: {
        marginTop: "2px",
        marginRight: "5px",
        cursor: "pointer"
    },
    paper: {
        border: "none",
        boxShadow: `0px 2px 2px 0px ${theme.palette.C_AB + "32"}` // 20% opacity
    }
});

class SelectInput extends React.Component {
    state = {
        open: false,
        menuWidth: null,
        search: "",
        targetRef: null,
        currentFocus: {
            value: null,
            index: -1
        }
    };

    componentDidMount() {}

    componentDidUpdate(prevState) {
        if (prevState.open !== this.state.open && this.menu) {
            if (this.state.open) {
                this.menu.blur();
            } else {
                if (!this.props.searchable) {
                    this.menu.focus();
                }
            }
        }
    }
    handleToggle = () => {
        if (this.state.open === true) {
            return this.handleClose();
        }
        this.setState({
            open: true,
            menuWidth: this.state.targetRef
                ? this.state.targetRef.clientWidth
                : this.state.clientWidth
        });
    };

    handleTargetRef = r => {
        this.setState({ targetRef: r });
        if (r) {
            this.setState({
                menuWidth: r.clientWidth
            });
        }
    };
    handleSearchRef = r => {
        const domNode = ReactDOM.findDOMNode(r);
        if (domNode !== this.state.targetRef) {
            this.setState({
                targetRef: domNode,
                menuWidth: domNode
                    ? domNode.clientWidth
                    : this.state.clientWidth
            });
        }
    };
    handleInputRef = r => {
        r && r.focus();
    };

    handleInputFieldClick = e => {
        if (e.target === document.activeElement) {
            this.handleClose();
        }
    };

    handleSearch = e => {
        const payload = { search: e.target.value };

        if (e.target.value.length > 0) {
            const newOptions = this.filterOptions(e.target.value);
            if (newOptions.length > 0) {
                payload.currentFocus = this.getItemValue(newOptions, 0, "down");
            }
        }
        this.setState(payload);
    };

    handleClose = () => {
        this.setState({ open: false, search: "" });
    };

    // Removes the keyboard focued item if mouse is hovered over item
    handleMouseEnter = e => {
        const value = e.target && e.target.getAttribute("data-value");

        if (
            value !== "" &&
            value !== null &&
            this.state.currentFocus.value !== null &&
            value.toString() === this.state.currentFocus.value.toString()
        ) {
            this.setState({ currentFocus: { index: -1, value: null } });
        }
    };

    onClick = child => event => {
        const value = child.props.value;
        let target;

        if (event.target) {
            target = event.target;
        }

        event.target = { ...target, value };
        this.props.onChange(event.target.value);
        this.handleClose();
    };

    onDefaultClick = event => {
        this.props.onChange(event.target.value);
        this.handleClose();
    };

    validateInput = (children, options) => {
        if (children === undefined && !Array.isArray(options)) {
            throw new Error("Either children or options has to be provided.");
        }

        if (children !== undefined && Array.isArray(options)) {
            throw new Error(
                "Both options and children was provided. Provide only one of them."
            );
        }
    };

    /**
     *
     * @param filteredItems
     * @param newFocusIndex
     * @param direction Enum "up" | "down
     * @param count used internally
     * @returns {*}
     */
    getItemValue = (
        filteredItems,
        newFocusIndex,
        direction = "down",
        count = 0
    ) => {
        if (count > 5) return { value: null, index: -1 };
        const item = filteredItems[newFocusIndex];
        if (item && item.props && item.props.value !== undefined) {
            return { value: item.props.value, index: newFocusIndex };
        }
        if (
            filteredItems.length === newFocusIndex - 1 &&
            direction === "down"
        ) {
            return this.getItemValue(filteredItems, 0, count + 1);
        } else if (newFocusIndex === 0 && direction === "up") {
            return this.getItemValue(
                filteredItems,
                filteredItems.length - 1,
                direction,
                count + 1
            );
        } else if (direction === "up") {
            return this.getItemValue(
                filteredItems,
                newFocusIndex - 1,
                direction,
                count + 1
            );
        } else if (direction === "down") {
            return this.getItemValue(
                filteredItems,
                newFocusIndex + 1,
                direction,
                count + 1
            );
        }
        return { value: null, index: -1 };
    };
    handleSearchKeyDown = e => {
        const filteredItems = this.filterOptions(this.state.search);
        if (e.key === "Enter") {
            if (filteredItems[this.state.currentFocus.index]) {
                e.stopPropagation();
                e.preventDefault();

                this.props.onChange(
                    filteredItems[this.state.currentFocus.index].props.value
                );
                this.handleClose();
            }
        }
        if (e.key === "ArrowDown") {
            if (filteredItems.length > 0) {
                if (this.state.currentFocus.index < filteredItems.length - 1) {
                    this.setState({
                        currentFocus: this.getItemValue(
                            filteredItems,
                            this.state.currentFocus.index + 1,
                            "down"
                        )
                    });
                } else {
                    this.setState({
                        currentFocus: this.getItemValue(
                            filteredItems,
                            0,
                            "down"
                        )
                    });
                }
            }
        }
        if (e.key === "ArrowUp") {
            if (filteredItems.length > 0) {
                if (this.state.currentFocus.index > 0) {
                    this.setState({
                        currentFocus: this.getItemValue(
                            filteredItems,
                            this.state.currentFocus.index - 1,
                            "up"
                        )
                    });
                } else {
                    this.setState({
                        currentFocus: this.getItemValue(
                            filteredItems,
                            filteredItems.length - 1,
                            "up"
                        )
                    });
                }
            }
        }
    };

    renderTarget = displayValue => {
        const {
            target,
            classes,
            fullWidth,
            targetClassName,
            disabled,
            background,
            searchable,
            name,
            overflow,
            locked
        } = this.props;
        const { open } = this.state;
        return (
            <RootRef rootRef={this.handleTargetRef}>
                {searchable && open ? (
                    <InputField
                        value={this.state.search}
                        onChange={this.handleSearch}
                        ref={this.handleSearchRef}
                        inputRef={this.handleInputRef}
                        inputClassName={classNames(classes.searchInnerInput, {
                            [classes.fitToDropdown]: !overflow
                        })}
                        rightIcon={
                            <CaretIcon className={classes.searchCaret} />
                        }
                        placeholder={displayValue}
                        onKeyDown={this.handleSearchKeyDown}
                        classes={{ input: classes.searchInputTag }}
                        onClick={this.handleInputFieldClick}
                    />
                ) : (
                    React.cloneElement(target, {
                        className: classNames(
                            classes.button,

                            {
                                [classes.fullWidth]: fullWidth,
                                [classes.buttonOpen]: open,
                                [classes.fitToDropdown]: open && !overflow
                            },

                            target.props.className,
                            targetClassName
                        ),
                        caretClassName: classNames(
                            classes.caret,
                            target.props.caretClassName
                        ),
                        children: displayValue,
                        onClick: this.handleToggle,
                        disabled,
                        locked,
                        background,
                        name
                    })
                )}
            </RootRef>
        );
    };
    handleSelectedItemRef = r => {
        this.setState({ selectedItemRef: r });
    };

    renderSuppliedMenuItems = () => {
        const { value, disabled, placeholder, locked } = this.props;
        let displayValue = placeholder;

        const filteredOptions = this.filterOptions(this.state.search);

        const menuItems = React.Children.map(filteredOptions, (child, i) => {
            const selected = child.props.value === value;

            if (selected) displayValue = child.props.children;

            return React.cloneElement(child, {
                onClick: this.onClick(child),
                selected,
                value: undefined,
                "data-value": child.props.value,
                role: "option",
                disabled: disabled,
                locked: locked,
                inFocus: this.state.currentFocus.value === child.props.value,
                onMouseEnter: this.handleMouseEnter
            });
        });
        return { displayValue, menuItems };
    };

    filterOptions = (search, options) => {
        const { children } = this.props;

        if (children) {
            return _.filter(
                React.Children.map(children, child => {
                    if (_.isString(child.props.children)) {
                        if (
                            child.props.children
                                .toLowerCase()
                                .indexOf(search.toLowerCase()) < 0
                        ) {
                            return null;
                        }
                        return child;
                    }
                })
            );
        } else {
            return _.filter(
                options,
                option =>
                    option.toLowerCase().indexOf(search.toLowerCase()) >= 0
            );
        }
    };
    renderDefaultMenuItems = (options, currentIndex) => {
        const { value, placeholder } = this.props;
        let displayValue = placeholder || "";

        const filteredOptions = this.filterOptions(this.state.search, options);

        const menuItems = _.map(filteredOptions, (option, i) => {
            const index = currentIndex + i;
            if (i === value) displayValue = option;

            return (
                <MenuItem
                    key={option}
                    value={index}
                    selected={index === value}
                    inFocus={this.state.currentFocus === option}
                    onMouseEnter={this.handleMouseEnter}
                    onClick={this.onDefaultClick}
                    ref={
                        index === value ? this.handleSelectedItemRef : undefined
                    }
                >
                    {option}
                </MenuItem>
            );
        });
        return { displayValue, menuItems };
    };

    formatGroupLabel = label => (
        <Typography key={"label_" + label}>{label}</Typography>
    );

    renderDefaultMenuItemsWithGroups = () => {
        const { options, formatGroupLabel } = this.props;

        const formatFunction = _.isFunction(formatGroupLabel)
            ? formatGroupLabel
            : this.formatGroupLabel;

        let totalDisplayValue = "";
        let currentIndex = 0;
        const totalMenuItems = _.map(options, group => {
            const { displayValue, menuItems } = this.renderDefaultMenuItems(
                group.options,
                currentIndex
            );
            //currentIndex += group.options ? .length;
            if (displayValue) totalDisplayValue = displayValue;
            return (
                <Fragment>
                    {formatFunction(group.label, currentIndex)}
                    {menuItems}
                </Fragment>
            );
        });
        return { displayValue: totalDisplayValue, menuItems: totalMenuItems };
    };

    renderMenuItems = () => {
        const { children, options } = this.props;
        if (children) {
            return this.renderSuppliedMenuItems();
        }

        if (options.length > 0 && _.isObject(options[0]) && options[0].label) {
            return this.renderDefaultMenuItemsWithGroups();
        }

        return this.renderDefaultMenuItems(options, 0);
    };
    render() {
        const {
            classes,
            children,
            className,
            label,
            fullWidth,
            options,
            description,
            footer,
            overflow,
            ...other
        } = this.props;

        this.validateInput(children, options);

        const { open, menuWidth } = this.state;

        const { menuItems, displayValue } = this.renderMenuItems();

        const paperStyle = {};

        if (menuWidth !== null) {
            paperStyle.width = menuWidth - 2;
            paperStyle.overflowY = "auto";
            paperStyle.maxHeight = "220px";
        }
        if (overflow) {
            paperStyle.width = `calc(${menuWidth}px + 50px)`;
            paperStyle.left = "27px"; // Half of extra with + 2
            paperStyle.top = "2px";
            paperStyle.position = "relative";
        } else {
            paperStyle.borderTopLeftRadius = 0;
            paperStyle.borderTopRightRadius = 0;
            paperStyle.borderTopStyle = "none";
        }

        return (
            <FormControl
                label={label}
                className={className}
                description={description}
                {...other}
            >
                {this.renderTarget(displayValue)}
                <Popup
                    onClickAway={this.handleToggle}
                    open={open}
                    style={paperStyle}
                    arrow={false}
                    className={classes.paper}
                    targetRef={this.state.targetRef}
                    targetClassName={classNames({
                        [classes.fullWidth]: fullWidth // Needs to be applied on div inside target aswell.
                    })}
                >
                    <Menu
                        selectedItemRef={this.state.selectedItemRef}
                        onClose={this.handleClose}
                        innerRef={r => (this.menu = r)}
                    >
                        {menuItems}
                    </Menu>
                    {footer}
                </Popup>
            </FormControl>
        );
    }
}

SelectInput.propTypes = {
    classes: PropTypes.object.isRequired,
    /**
     * A list of menu items. The component can either be used by adding custom menu items as children,
     * or by sending a value in the options props described below.
     */
    children: PropTypes.arrayOf(PropTypes.node),
    /**
     * The value of the currently selected item
     */
    value: PropTypes.any,
    /**
     * An optional classname for styling
     */
    className: PropTypes.string,
    /**
     * A lable to sit on top of the select field
     */
    label: PropTypes.string,
    /**
     * Whether the input field should fill the parents width.
     */
    fullWidth: PropTypes.bool,

    /**
     * A custom target for the input button
     */
    target: PropTypes.node,

    /**
     * A list of strings to be displayed. Will override
     */
    options: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                label: PropTypes.string,
                options: PropTypes.arrayOf(PropTypes.string)
            })
        ])
    ),

    /**
     *  A function which takes the group label and returns a node to display.
     */
    formatGroupLabel: PropTypes.func,

    /**
     * A function which is called when an item is selected.
     * Will be called with the clicked item's value. If options was used,
     * this will be the index in the array
     */
    onChange: PropTypes.func.isRequired,

    /**
     * A description to be forwarded to FormControl
     */
    description: PropTypes.string,
    background: PropTypes.oneOf(["grey", "white"]),
    footer: PropTypes.node,

    /**
     * Whether the popup should overflow a given percentage to the right
     */
    overflow: PropTypes.bool,

    placeholder: PropTypes.string,
    locked: PropTypes.bool,
    disabled: PropTypes.bool
};

SelectInput.defaultProps = {
    label: "",
    fullWidth: false,
    target: <SelectButton />,
    background: "white",
    footer: null,
    overflow: false,
    placeholder: "",
    locked: false,
    disabled: false
};
export default withStyles(styles)(SelectInput);
