import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 3.87 15">
            <path d="M1.13.22a.64.64,0,0,0-1,.85,9.79,9.79,0,0,1,0,12.86.65.65,0,0,0,.06.91A.61.61,0,0,0,.64,15a.64.64,0,0,0,.49-.22A11.06,11.06,0,0,0,3.87,7.5,11.06,11.06,0,0,0,1.13.22Z" />
        </SvgIcon>
    );
}
