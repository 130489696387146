import * as _ from "lodash";
import moment from "moment";
import { createSelector } from "reselect";
import { userGroups } from "../../../actionTypes";
import * as timebankSelectors from "../selectors";
import * as companySelectors from "../../../selectors/company";

const getSignedInUser = state => state.auth.user;

const getCurrentDate = state => state.timebank.meta.currentDate;

const getUserId = (_, props) => props.match.params.userId;

const getCompanyId = (_, props) => props.match.params.companyId;

const getSignedInUserDepartment = createSelector(
    getSignedInUser,
    user => user && user.departmentId
);

//Whether the company wishes to show withdrawn hours
const isWithdrawnHoursActive = createSelector(
    timebankSelectors.getUserView,
    view => view && view.withdrawnHoursButton
);

const getHasSetWithdrawnHours = createSelector(
    [isWithdrawnHoursActive, getSignedInUser, getUserId],
    (isActive, signedInUser) => {
        if (!isActive) return false;

        return (
            signedInUser.userGroup === userGroups.ADMIN ||
            signedInUser.userGroup === userGroups.DEPARTMENT_LEADER
        );
    }
);
const getHasRequestWithdrawnHours = createSelector(
    [isWithdrawnHoursActive, getSignedInUser, getUserId],
    (isActive, signedInUser) => {
        if (!isActive) return false;

        return signedInUser.userGroup === userGroups.USER;
    }
);

const getCurrentDepartment = state => state.timebank.meta.currentDepartment;

// Show only the departmentleader's department. If not found, set to blank to safeguard error.
const getDepartmentLeaderDepartments = createSelector(
    [getSignedInUserDepartment, companySelectors.getCompanyDepartments],
    (userDepartment, departments) =>
        _.filter(departments, dep => dep.id === userDepartment)
);

const getDepartmentsByAuth = createSelector(
    [
        getSignedInUser,
        companySelectors.getOrderedCompanyDepartmentsWithAll,
        getDepartmentLeaderDepartments
    ],
    (signedInUser, adminDepartments, depLeaderDepartments) => {
        return signedInUser.userGroup === userGroups.ADMIN
            ? adminDepartments
            : depLeaderDepartments;
    }
);

const getTimeBankYearly = state => state.timebank.yearly;

const getTimeBankMonthly = state => state.timebank.monthly;

const getCurrentUser = (state, props) => state.users[props.match.params.userId];

const getCurrentYear = createSelector([getCurrentDate], date =>
    moment(date).year()
);
const getCurrentMonth = createSelector([getCurrentDate], date =>
    moment(date).month()
);

const getKeyFigures = createSelector(
    [getTimeBankMonthly, getCurrentDate],
    (timebank, d) => {
        const date = new Date(d);
        const y = date.getFullYear();
        const m = date.getMonth();
        return timebank[y] && timebank[y][m] && timebank[y][m]["keyFigures"];
    }
);
const getUserKeyFigures = createSelector(
    [getTimeBankYearly, getUserId, getCurrentYear],
    (timebank, userId, currentYear) =>
        timebank[currentYear] && timebank[currentYear][userId]
);

const getFilteredKeyFigures = createSelector(
    timebankSelectors.getUserView,
    getUserKeyFigures,
    (view, keyFigures) =>
        view
            ? _.filter(keyFigures, (keyFigure, key) => view[key] !== false)
            : keyFigures // Filter keyfigures from view
);
const getFilteredUserFiguresWithLimit = createSelector(
    getFilteredKeyFigures,
    keyFigures => {
        if (keyFigures.length > 6) {
            return keyFigures.slice(0, 6);
        }
        return keyFigures;
    }
);
const getFilteredCompanyKeyFigures = createSelector(
    timebankSelectors.getCurrentView,
    getKeyFigures,
    (view, keyFigures) =>
        view
            ? _.filter(keyFigures, (keyFigure, key) => view[key] !== false)
            : keyFigures // Filter keyfigures from view
);

const getFilteredCompanyKeyFiguresWithLimit = createSelector(
    getFilteredCompanyKeyFigures,
    keyFigures => {
        if (keyFigures.length > 5) {
            return keyFigures.slice(0, 5);
        }
        return keyFigures;
    }
);
const getActiveDepartment = createSelector(
    [
        getCurrentDepartment,
        companySelectors.getOrderedCompanyDepartmentsWithAll
    ],
    (currentDepKey, departments) =>
        _.findIndex(departments, dep => dep.id === currentDepKey)
);
export const getUserInfoBar = (state, props) => {
    return {
        currentYear: getCurrentYear(state, props), //Month and
        currentMonth: getCurrentMonth(state, props),
        userId: getUserId(state, props),
        companyId: getCompanyId(state, props),
        hasRequestWithdrawnHoursButton: getHasRequestWithdrawnHours(
            state,
            props
        ),
        hasSetWithdrawnHoursButton: getHasSetWithdrawnHours(state, props),
        keyFigures: getFilteredUserFiguresWithLimit(state, props),
        signedInUser: getSignedInUser(state, props),
        currentUser: getCurrentUser(state, props)
    };
};

export const getInfoBar = (state, props) => {
    return {
        companyId: getCompanyId(state, props),
        currentDate: getCurrentDate(state, props),
        keyFigures: getFilteredCompanyKeyFiguresWithLimit(state, props),
        departments: getDepartmentsByAuth(state, props),
        currentDepartment: getActiveDepartment(state, props),
        signedInUser: getSignedInUser(state, props)
    };
};
