
import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';
export default function (props) {
    return (
        <SvgIcon {...props} viewBox='0 0 8.06 10'>
            <path d='M4.53,1.7V9.5A.5.5,0,0,1,4,10a.5.5,0,0,1-.5-.5V1.7L.88,4.35a.51.51,0,0,1-.71.05.49.49,0,0,1-.05-.7l.06-.06L3.68.14a.5.5,0,0,1,.7,0l3.5,3.5a.5.5,0,1,1-.65.77l-.05-.06L4.53,1.7Z'
            />
        </SvgIcon>

    )
}

