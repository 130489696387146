import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";

import PropTypes from "prop-types";
import React, { Component } from "react";
import { userGroups } from "../../actionTypes";
import LogoIcon from "../icons/LogoIcon";
import ReportIcon from "../icons/ReportIcon";
import SettingsIcon from "../icons/SettingsIcon";
import UsersIcon from "../icons/UsersIcon";
import { Authenticated } from "../auth";
import IconWrapper from "./IconWrapper";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Avatar from "../icons/Avatar";
import { getColorFromId } from "../../utils";
import ButtonBase from "@material-ui/core/ButtonBase/ButtonBase";
import Notifications from "containers/notifications/NotificationsContainer";
import SyncIcon from "../icons/SyncIcon";

const styles = theme => ({
    root: {
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.C_DB15,
        width: theme.spacing.sidebarWidth,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        zIndex: 10,
        boxShadow: "inset -1px 0px 10px 0px rgba(0, 0, 0, 0.15)"
    },
    corner: {
        width: "35px",
        height: "35px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.C_LB,
        borderRadius: "6px"
    },
    iconGroup: {
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center"
    },

    iconGroup1: {
        flexGrow: 1
    },
    iconGroup2: {
        flexGrow: 1
    },
    iconGroup3: {
        flexGrow: 1
    },

    iconGroup4: {
        flexGrow: 6,
        justifyContent: "flex-end",
        marginBottom: "20px"
    },
    iconContainer: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        height: "100%"
    },
    icon: {
        width: "18px",
        height: "18px"
    },
    syncIcon: {
        width: "16px",
        height: "16px"
    },
    timeIcon: {
        width: "32px",
        height: "32px"
    },
    logoIcon: {
        width: "13px",
        height: "13px",
        color: theme.palette.C_W
    },

    divider: {
        display: "block",
        width: "20px",
        backgroundColor: theme.palette.C_DB20
    },
    avatarButton: {
        width: "unset",
        height: "60px"
    },
    colorLabel: {
        position: "absolute",
        height: "30px",
        width: "5px",
        borderTopRightRadius: "6px",
        borderBottomRightRadius: "6px",
        left: "0px",
        backgroundColor: theme.palette.C_LB,
        transition: "all 100ms"
    },
    headerContainer: {
        height: theme.spacing.infoBar.height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },

    popupIcon: {
        width: "100%",
        height: "60px",
        "&:hover": {
            color: theme.palette.C_LB
        },
        color: theme.palette.C_DB40
    },
    bottomDivider: {
        margin: "30px 0"
    },
    syncInProgress: {
        animation: "spin 2000ms linear infinite"
    },
    "@keyframes spin": {
        to: {
            transform: "rotate(359deg)"
        }
    }
});

class SideBar extends Component {
    state = {
        activePosition: 10,
        windowHeightUpdated: false
    };

    static propTypes = {
        classes: PropTypes.object.isRequired,
        dashboardLink: PropTypes.string.isRequired,
        usersLink: PropTypes.string.isRequired,
        companyLink: PropTypes.string.isRequired,
        activeIcon: PropTypes.oneOf(["dashboard", "users", "settings"])
            .isRequired
    };

    iconPositions = {};
    componentDidMount() {
        window.addEventListener("resize", this.handleWindowChange);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowChange);
    }

    handleWindowChange = e => {
        this.setState({
            windowHeightUpdated: true
        });
    };

    handlePositionChange = position => {
        this.setState({
            activePosition: position,
            windowHeightUpdated: false
        });
    };

    render() {
        const {
            classes,
            dashboardLink,
            usersLink,
            companyLink,
            activeIcon,
            avatarRef,
            onAvatarClick,
            onMenuClick,
            onResyncClick,
            menuRef,
            resyncRef,
            user,
            syncInProgress
        } = this.props;

        return (
            <div className={classes.root} data-test-id="sidebar">
                <div className={classes.iconContainer}>
                    <div
                        className={classes.colorLabel}
                        style={{
                            transform: `translate(0, ${this.state
                                .activePosition + 19}px)`
                        }}
                    />
                    <div className={classes.headerContainer}>
                        <ButtonBase
                            disableRipple
                            className={classes.corner}
                            onClick={onMenuClick}
                            buttonRef={menuRef}
                        >
                            <LogoIcon className={classes.logoIcon} white />
                        </ButtonBase>
                    </div>
                    <Divider className={classes.divider} />

                    <div
                        className={classNames(
                            classes.iconGroup,
                            classes.iconGroup1
                        )}
                    >
                        <IconWrapper
                            active={activeIcon === "dashboard"}
                            id={"dashboard"}
                            linkTo={dashboardLink}
                            label={"Timerapport"}
                            positionHandler={this.handlePositionChange}
                            newWindow={this.state.windowHeightUpdated}
                        >
                            <ReportIcon className={classes.icon} />
                        </IconWrapper>

                        <Authenticated
                            userGroups={[
                                userGroups.DEPARTMENT_LEADER,
                                userGroups.ADMIN
                            ]}
                        >
                            <IconWrapper
                                active={activeIcon === "users"}
                                id={"users"}
                                linkTo={usersLink}
                                label={"Brukere"}
                                positionHandler={this.handlePositionChange}
                                newWindow={this.state.windowHeightUpdated}
                            >
                                <UsersIcon className={classes.icon} />
                            </IconWrapper>
                        </Authenticated>
                        <IconWrapper
                            active={activeIcon === "settings"}
                            linkTo={companyLink}
                            label={"Innstillinger"}
                            positionHandler={this.handlePositionChange}
                            newWindow={this.state.windowHeightUpdated}
                        >
                            <SettingsIcon className={classes.icon} />
                        </IconWrapper>
                    </div>

                    <div
                        className={classNames(
                            classes.iconGroup,
                            classes.iconGroup4
                        )}
                    >
                        <IconButton
                            disableRipple
                            className={classes.popupIcon}
                            onClick={onResyncClick}
                            disabled={syncInProgress}
                            ref={resyncRef}
                        >
                            <SyncIcon
                                className={classNames(classes.syncIcon, {
                                    [classes.syncInProgress]: syncInProgress
                                })}
                            />
                        </IconButton>

                        <Notifications />

                        <IconButton
                            disableRipple
                            onClick={onAvatarClick}
                            className={classes.avatarButton}
                            data-test-id={"sidebar-avatar-profile"}
                        >
                            <Avatar
                                color={getColorFromId(user.id)}
                                firstName={user.firstName}
                                lastName={user.lastName}
                                photoURL={user.photoURL}
                                innerRef={avatarRef}
                            />
                        </IconButton>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(SideBar);
