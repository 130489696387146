import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PaperHeader from "../../../components/layout/paper/PaperHeader";
import PaperHeaderTitle from "../../../components/layout/paper/PaperHeaderTitle";
import * as companySelectors from "../../../selectors/company";
import { withRouter } from "react-router-dom";
import { componentDescriptions, dialog } from "../../../constants/strings";
import SelectInput from "../../../common/forms/SelectInput";
import * as _ from "lodash";
import * as selectors from "../selectors";
import {
    getKeysFromTrueValues,
    getObjectTrueValuesCount
} from "../../../utils";
import { updateSeveralWorkTypeCategories } from "../../../actions/company";
import SimpleDialog from "../../../common/dialogs/SimpleDialog";
import Button from "../../../common/forms/buttons/Button";
import DialogButtonGroup from "../../../common/dialogs/dialogContent/DialogButtonGroup";

const worktypeCategories = [
    { name: "Kundetid", id: 4 },
    { name: "Interntid", id: 5 },
    { name: "Interntid (Fakturerbar)", id: 8 },
    { name: "Sykdom", id: 1 },
    { name: "Avspasering", id: 2 },
    { name: "Ferie", id: 3 },
    { name: "Permisjon", id: 6 },
    { name: "Ignorer", id: 7 },
    { name: "-", id: 0 }
];

export class WorkTypesHeader extends Component {
    state = {
        setWorkTypesOpen: false,
        selectedWorkTypeIndex: 0
    };
    handleConfirm = () => {
        const category = worktypeCategories[this.state.selectedWorkTypeIndex];
        const selectedIds = getKeysFromTrueValues(this.props.selected);
        this.props.updateSeveralWorkTypeCategories(
            this.props.company.id,
            selectedIds,
            category.id
        );
        this.setState({ setWorkTypesOpen: false });
    };
    handleInputChange = index => {
        this.setState({ selectedWorkTypeIndex: index });
    };

    render() {
        const { selected } = this.props;
        const numSelected = getObjectTrueValuesCount(selected);

        return (
            <Fragment>
                <PaperHeader
                    description={componentDescriptions.workTypes}
                    title={
                        <PaperHeaderTitle
                            primary={"Arbeidstyper"}
                            secondary={`for ${this.props.company.name}`}
                        />
                    }
                >
                    <Button
                        disabled={numSelected === 0}
                        aria-label={"Minst en arbeidstype må være valgt."}
                        onClick={() =>
                            this.setState({
                                setWorkTypesOpen: true,
                                selectedWorkType: 0
                            })
                        }
                        variant={"action"}
                    >
                        {`Kategoriser arbeidstyper (${numSelected})`}
                    </Button>
                </PaperHeader>
                <SimpleDialog
                    open={this.state.setWorkTypesOpen}
                    buttonGroup={
                        <DialogButtonGroup
                            onConfirm={this.handleConfirm}
                            onCancel={() =>
                                this.setState({
                                    setWorkTypesOpen: false
                                })
                            }
                        />
                    }
                    description={dialog.workType.setMultiple.description}
                    title={dialog.workType.setMultiple.title}
                    name={
                        numSelected === 1
                            ? `${numSelected} arbeidstype valgt`
                            : `${numSelected} arbeidstyper valgt`
                    }
                >
                    <SelectInput
                        options={_.map(worktypeCategories, wt => wt.name)}
                        value={this.state.selectedWorkTypeIndex}
                        placeholder={`Kategoriser arbeidstyper (${numSelected})`}
                        onChange={this.handleInputChange}
                    />
                </SimpleDialog>
            </Fragment>
        );
    }
}

const mapStateToProps = (state, props) => ({
    company: companySelectors.getCurrentCompany(state, props),
    selected: selectors.getWorkTypesMeta(state, props).selectedWorkTypes
});

export default withRouter(
    connect(mapStateToProps, {
        updateSeveralWorkTypeCategories
    })(WorkTypesHeader)
);
