// @inheritedComponent Popover

import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { withStyles } from "@material-ui/core/styles";

export const styles = {
    paper: {
        // specZ: The maximum height of a simple menu should be one or more rows less than the view
        // height. This ensures a tappable area outside of the simple menu with which to dismiss
        // the menu.
        maxHeight: "calc(100% - 96px)",
        // Add iOS momentum scrolling.
        WebkitOverflowScrolling: "touch",
        // Fix a scrolling issue on Chrome.
        transform: "translateZ(0)"
    }
};

class Menu extends React.Component {
    menuList = null;

    handleMouseEnter = e => {
        if (document.activeElement && document.activeElement === e.target) {
            this.blur();
        }
    };
    focus = () => {
        if (this.menuList && this.props.selectedItemRef) {
            ReactDOM.findDOMNode(this.props.selectedItemRef).focus();
            return;
        }

        const menuList = ReactDOM.findDOMNode(this.menuList);
        if (menuList && menuList.firstChild) {
            menuList.firstChild.focus();
        }
    };

    blur = () => {
        const currentFocus = document.activeElement;
        const menuList = ReactDOM.findDOMNode(this.menuList);
        if (menuList && menuList.contains(currentFocus)) {
            currentFocus.blur();
        }
    };
    handleListKeyDown = event => {
        if (event.key === "Tab") {
            event.preventDefault();

            if (this.props.onClose) {
                this.props.onClose(event);
            }
        }

        const list = ReactDOM.findDOMNode(this.menuList);
        const key = event.key;
        const currentFocus = document.activeElement;

        if (
            (key === "ArrowUp" || key === "ArrowDown") &&
            (!currentFocus || (currentFocus && !list.contains(currentFocus)))
        ) {
            if (this.props.selectedItemRef) {
                ReactDOM.findDOMNode(this.props.selectedItemRef).focus();
            } else {
                list.firstChild.focus();
            }
        } else if (key === "ArrowDown") {
            event.preventDefault();
            if (currentFocus.nextElementSibling) {
                currentFocus.nextElementSibling.focus();
            }
        } else if (key === "ArrowUp") {
            event.preventDefault();
            if (currentFocus.previousElementSibling) {
                currentFocus.previousElementSibling.focus();
            }
        }

        if (this.props.onKeyDown) {
            this.props.onKeyDown(event, key);
        }
    };

    render() {
        const { children, MenuListProps } = this.props;

        return (
            <div
                data-mui-test="Menu"
                onKeyDown={this.handleListKeyDown}
                onMouseEnter={this.handleMouseEnter}
                {...MenuListProps}
                ref={node => {
                    this.menuList = node;
                }}
            >
                {children}
            </div>
        );
    }
}

Menu.propTypes = {
    /**
     * The DOM element used to set the position of the menu.
     */
    anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    /**
     * Menu contents, normally `MenuItem`s.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     * See [CSS API](#css-api) below for more details.
     */
    classes: PropTypes.object.isRequired,
    /**
     * If `true`, the selected / first menu item will not be auto focused.
     */
    disableAutoFocusItem: PropTypes.bool,
    /**
     * Properties applied to the [`MenuList`](/api/menu-list) element.
     */
    MenuListProps: PropTypes.object,
    /**
     * Callback fired when the component requests to be closed.
     *
     * @param {object} event The event source of the callback
     */
    onClose: PropTypes.func,
    /**
     * Callback fired before the Menu enters.
     */
    onEnter: PropTypes.func,
    /**
     * Callback fired when the Menu has entered.
     */
    onEntered: PropTypes.func,
    /**
     * Callback fired when the Menu is entering.
     */
    onEntering: PropTypes.func,
    /**
     * Callback fired before the Menu exits.
     */
    onExit: PropTypes.func,
    /**
     * Callback fired when the Menu has exited.
     */
    onExited: PropTypes.func,
    /**
     * Callback fired when the Menu is exiting.
     */
    onExiting: PropTypes.func,
    /**
     * If `true`, the menu is visible.
     */
    open: PropTypes.bool.isRequired,
    /**
     * @ignore
     */
    PaperProps: PropTypes.object,
    /**
     * `classes` property applied to the `Popover` element.
     */
    PopoverClasses: PropTypes.object,
    /**
     * @ignore
     */
    theme: PropTypes.object.isRequired,
    /**
     * The length of the transition in `ms`, or 'auto'
     */
    transitionDuration: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape({ enter: PropTypes.number, exit: PropTypes.number }),
        PropTypes.oneOf(["auto"])
    ])
};

Menu.defaultProps = {
    disableAutoFocusItem: false,
    transitionDuration: "auto"
};

export default withStyles(styles, { name: "MuiMenu", withTheme: true })(Menu);
