import React from "react";
import { connect } from "react-redux";
import * as selectors from "./selectors";
import { withRouter } from "react-router-dom";
import { FullTimeEquivalent } from "../../users/userDetails/FullTimeEquivalent";
import * as userSelectors from "../../../selectors/user";
import {
    createFullTimeEquivalent,
    updateFullTimeEquivalent
} from "../../modals/actions";

export const mapStateToProps = (state, props) => {
    return {
        fullTimeEquivalentHistory: selectors.getFullTimeEquivalent(
            state,
            props
        ),
        signedInUser: userSelectors.getSignedInUser(state, props),
        currentUser: userSelectors.getSignedInUser(state, props)
    };
};
export default withRouter(
    connect(mapStateToProps, {
        updateFullTimeEquivalent,
        createFullTimeEquivalent
    })(FullTimeEquivalent)
);
