import React from "react";
import { withStyles } from "@material-ui/core/styles";
import DatePicker from "./DatePicker";
import moment from "moment";
import FormControl from "../FormControl";
import SelectButton from "common/poppers/PopupButton";
import * as utils from "utils";
const styles = theme => ({
    root: {
        backgroundColor: theme.palette.C_W
    },
    caret: {
        width: "7px",
        height: "7px",
        color: theme.palette.C_DB20
    }
});

class InputDatePicker extends React.PureComponent {
    render() {
        const {
            date,
            classes,
            label,
            className,
            disabled,
            locked,
            placeholder,
            error,
            actionButton,
            onActionButtonClick,
            description,
            flip,
            ...other
        } = this.props;
        return (
            <FormControl
                label={label}
                className={className}
                actionButton={actionButton}
                onActionButtonClick={onActionButtonClick}
                description={description}
            >
                <DatePicker
                    flip={flip == null ? true : flip}
                    target={
                        <SelectButton
                            className={classes.root}
                            disabled={disabled}
                            locked={locked}
                            error={error}
                        >
                            {date ? utils.formatDate(date) : placeholder}
                        </SelectButton>
                    }
                    date={date}
                    closeOnSelect
                    {...other}
                />
            </FormControl>
        );
    }
}

InputDatePicker.propTypes = {};

export default withStyles(styles)(InputDatePicker);
