// AUTH ACTIONS
export const SET_AUTH = "SET_AUTH";
export const REQUEST_AUTH_UPDATE = "REQUEST_AUTH_UPDATE";
export const REQUEST_LOG_OUT = "REQUEST_LOG_OUT";
export const REQUEST_LOG_IN = "REQUEST_LOG_IN";
export const SET_VERIFY_ACCOUNT_STATUS = "SET_VERIFY_ACCOUNT_STATUS";
export const SET_LOGOUT = "SET_LOGOUT";
export const REFRESH_TOKEN_FAILED = "REFRESH_TOKEN_FAILED";
export const SET_AUTH_ACTION_NEEDED = "SET_AUTH_ACTION_NEEDED";
export const REQUEST_POSSIBLE_CLIENTS = "REQUEST_POSSIBLE_CLIENTS";
export const SET_POSSIBLE_USERS = "SET_POSSIBLE_USERS";
export const REQUEST_UPDATE_CLIENT = "REQUEST_UPDATE_CLIENT";
export const UPDATE_AUTH_USER = "UPDATE_AUTH_USER";

//USER ACTIONS
export const SET_USERS = "SET_USERS";
export const REQUEST_ALL_USERS = "REQUEST_ALL_USERS";
export const REQUEST_USER = "REQUEST_USER";
export const REQUEST_USER_SHALLOW = "REQUEST_USER_SHALLOW";
export const REQUEST_USER_LOG = "REQUEST_USER_LOG";
export const REQUEST_RECENT_LOG = "REQUEST_RECENT_LOG";
export const REQUEST_CONTINUATION_LOG = "REQUEST_CONTINUATION_LOG";
export const SET_USER_LOGS = "SET_USER_LOGS";
export const REQUEST_UPDATE_USER = "REQUEST_UPDATE_USER";
export const REQUEST_SET_COMMENT = "REQUEST_SET_COMMENT";
export const REQUEST_UPDATE_FULL_TIME_EQUIVALENT =
    "REQUEST_UPDATE_FULL_TIME_EQUIVALENT";
export const REQUEST_CREATE_FULL_TIME_EQUIVALENT =
    "REQUEST_CREATE_FULL_TIME_EQUIVALENT";
export const REQUEST_DELETE_FULL_TIME_EQUIVALENT =
    "REQUEST_DELETE_FULL_TIME_EQUIVALENT";
export const REQUEST_SET_INCOMING_BALANCE = "REQUEST_SET_INCOMING_BALANCE";
export const REQUEST_SET_INCOMING_VACATION_BALANCE =
    "REQUEST_SET_INCOMING_VACATION_BALANCE";
export const REQUEST_UPDATE_USERS_DEP = "REQUEST_UPDATE_USERS_DEP";
export const REQUEST_USER_NOTIFICATION = "REQUEST_USER_NOTIFICATION";
export const SET_USER_NOTIFICATIONS = "SET_USER_NOTIFICATIONS";
export const USER_REQUEST_PAYOUT = "USER_REQUEST_PAYOUT";
export const REQUEST_UPDATE_NOTIFICATION = "REQUEST_UPDATE_NOTIFICATION";
export const REQUEST_SET_USER_PAYOUT = "REQUEST_SET_USER_PAYOUT";
export const REFRESH_NOTIFICATIONS = "REFRESH_NOTIFICATIONS";
export const SET_INCOMING_BALANCE = "SET_INCOMING_BALANCE";
export const REQUEST_GET_INCOMING_BALANCE = "REQUEST_GET_INCOMING_BALANCE";
export const REQUEST_UPDATE_MULT_USERS = "REQUEST_UPDATE_MULT_USERS";
export const SET_SHALLOW_USERS = "SET_SHALLOW_USERS";
export const REQUEST_ALL_USERS_SHALLOW = "REQUEST_ALL_USERS_SHALLOW";
export const SET_USER_FILTERS = "SET_USER_FILTERS";
export const SET_DISPLAY_TYPE = "SET_DISPLAY_TYPE";
export const SET_USER_DETAILS_FILTERS = "SET_USER_DETAILS_FILTERS";

//TIMEBANK ACTIONS
export const SET_TIMEBANK_MONTHLY = "SET_TIMEBANK_MONTHLY";
export const REQUEST_TIMEBANK_MONTHLY = "REQUEST_TIMEBANK_MONTHLY";
export const UPDATE_TIMEBANK_META = "UPDATE_TIMEBANK_META";
export const SET_TIMEBANK_MONTHLY_BY_YEAR = "SET_TIMEBANK_MONTHLY_BY_YEAR";
export const SET_KEY_FIGURES = "SET_KEY_FIGURES";
export const SET_USER_KEY_FIGURES = "SET_USER_KEY_FIGURES";
export const REQUEST_TIMEBANK_WEEK_BY_MONTH = "REQUEST_TIMEBANK_WEEK_BY_MONTH";
export const SET_TIMEBANK_WEEKS = "SET_TIMEBANK_WEEKS";
export const SET_USER_HOURS_DAILY = "SET_USER_HOURS_DAILY";
export const REQUEST_USER_TIMEBANK_DAILY = "REQUEST_USER_TIMEBANK_DAILY";

//COMPANY ACTIONS
export const SET_COMPANY = "SET_COMPANY";
export const REQUEST_COMPANIES = "REQUEST_COMPANIES";
export const SET_COMPANIES = "SET_COMPANIES";
export const REQUEST_COMPANY = "REQUEST_COMPANY";
export const REQUEST_CREATE_DEPARTMENT = "REQUEST_CREATE_DEPARTMENT";
export const UPDATE_WORKTYPE_CATEGORIES = "UPDATE_WORKTYPE_CATEGORIES";
export const UPDATE_DISPLAY_SETTINGS = "UPDATE_DISPLAY_SETTINGS";
export const REQUEST_REGISTER_COMPANY = "REQUEST_REGISTER_COMPANY";
export const REQUEST_ACCOUNT_VERIFICATION = "REQUEST_ACCOUNT_VERIFICATION";
export const SET_REGISTER_COMPANY_STATUS = "SET_REGISTER_COMPANY_STATUS";
export const REQUEST_UPDATE_DEPARTMENT = "REQUEST_UPDATE_DEPARTMENT";
export const REQUEST_DELETE_DEPARTMENT = "REQUEST_DELETE_DEPARTMENT";
export const REQUEST_COMPANY_CALC = "REQUEST_COMPANY_CALC";
export const REQUEST_CREATE_NOTIFICATION_TARGET_GROUP =
    "REQUEST_CREATE_NOTIFICATION_TARGET_GROUP";
export const REQUEST_UPDATE_NOTIFICATION_TARGET_GROUP =
    "REQUEST_UPDATE_NOTIFICATION_TARGET_GROUP";
export const REQUEST_DELETE_NOTIFICATION_TARGET_GROUP =
    "REQUEST_DELETE_NOTIFICATION_TARGET_GROUP";

export const REQUEST_COMPANY_NOTIFICATION_TARGET_GROUPS =
    "REQUEST_COMPANY_NOTIFICATION_TARGET_GROUPS";
export const SET_NOTIFICATION_GROUPS = "SET_NOTIFICATION_GROUPS";
export const SET_NOTIFICATIONS_SETTINGS = "SET_NOTIFICATIONS_SETTINGS";
export const SET_INITIAL_NOTIFICATION_TARGET_GROUP_STATE =
    "SET_INITIAL_NOTIFICATION_TARGET_GROUP_STATE";

export const REQUEST_CREATE_CALCULATION_RULE =
    "REQUEST_CREATE_CALCULATION_RULE";
export const REQUEST_UPDATE_CALCULATION_RULE =
    "REQUEST_UPDATE_CALCULATION_RULE";
export const REQUEST_DELETE_CALCULATION_RULE =
    "REQUEST_DELETE_CALCULATION_RULE";
export const REQUEST_CALCULATION_RULES = "REQUEST__CALCULATION_RULES";
export const SET_CALCULATION_RULES = "SET_CALCULATION_RULES";
export const REQUEST_DELETE_DEPARTMENT_VIEW = "REQUEST_DELETE_DEPARTMENT_VIEW";
export const REQUEST_UPDATE_CALCULATION_RULE_ACTIVE =
    "REQUEST_UPDATE_CALCULATION_RULE_ACTIVE";
export const REQUEST_COMPANY_HOURS_DAILY = "REQUEST_COMPANY_HOURS_DAILY";
export const SET_COMPANY_HOURS_DAILY = "SET_COMPANY_HOURS_DAILY";
export const REQUEST_UPDATE_COMPANY_RULES = "REQUEST_UPDATE_COMPANY_RULES";
export const REQUEST_KEY_PERFORMANCE_INDICATORS =
    "REQUEST_KEY_PERFORMANCE_INDICATOR";
export const REQUEST_CREATE_KEY_PERFORMANCE_INDICATOR =
    "REQUEST_CREATE_KEY_PERFORMANCE_INDICATOR";
export const REQUEST_UPDATE_KEY_PERFORMANCE_INDICATOR =
    "REQUEST_UPDATE_KEY_PERFORMANCE_INDICATOR";
export const REQUEST_DELETE_KEY_PERFORMANCE_INDICATOR =
    "REQUEST_DELETE_KEY_PERFORMANCE_INDICATOR";
export const SET_KEY_PERFORMANCE_INDICATORS = "SET_KEY_PERFORMANCE_INDICATORS";
export const UPDATE_KEY_PERFORMANCE_INDICATOR =
    "UPDATE_KEY_PERFORMANCE_INDICATOR";
export const SET_NEW_KEY_PERFORMANCE_INDICATOR =
    "SET_NEW_KEY_PERFORMANCE_INDICATOR";
export const DELETE_KEY_PERFORMANCE_INDICATOR =
    "DELETE_KEY_PERFORMANCE_INDICATORS";
export const REQUEST_KEY_PERFORMANCE_INDICATORS_META_DATA =
    "REQUEST_KEY_PERFORMANCE_INDICATORS_META_DATA";
export const SET_KEY_PERFORMANCE_INDICATORS_META_DATA =
    "SET_KEY_PERFORMANCE_INDICATORS_META_DATA";

// SYNC ACTIONS

export const REQUEST_SYNCHRONIZE_DATA = "REQUEST_SYNCHRONIZE_DATA";
export const SET_SYNCHRONIZATION_DATA = "SET_SYNCHRONIZATION_DATA";
export const REQUEST_SYNCHRONIZE_STATUS = "REQUEST_SYNCHRONIZE_STATUS";
export const WAIT_FOR_SYNC_DONE = "WAIT_FOR_SYNC_DONE";
export const REQUEST_MANUAL_USER_SYNCHRONIZATION =
    "REQUEST_MANUAL_USER_SYNCHRONIZATION";
export const REQUEST_MANUAL_COMPANY_SYNCHRONIZATION =
    "REQUEST_MANUAL_COMPANY_SYNCHRONIZATION";
export const SYNCHRONIZATION_FAILED = "SYNCHRONIZATION_FAILED";

// SETTINGS ACTIONS
export const SET_ACTIVE_SETUP_TAB = "SET_ACTIVE_SETUP_TAB";

export const SET_ACTIVE_PRIVACY_TAB = "SET_ACTIVE_PRIVACY_TAB";
export const SET_SELECTED_WORKTYPES = "SET_SELECTED_WORKTYPES";
//COMMON ACTIONS
export const REQUEST_ERROR = "REQUEST_ERROR";
export const SET_HELP_DATA = "SET_HELP_DATA";
export const HELP_DATA_REQUEST = "HELP_DATA_REQUEST";
export const SYNCHRONIZE_HELP_DATA = "SYNCHRONIZE_HELP_DATA";
export const IS_FETCHING = "IS_FETCHING";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const SET_CONFIRM_ACTION = "SET_CONFIRM_ACTION";
export const REMOVE_CONFIRM_ACTION = "REMOVE_CONFIRM_ACTION";
export const SET_APP_STATE = "SET_APP_STATE";
export const TRACK_EVENT = "TRACK_EVENT";
export const TRACK_EVENT_THROTTLED = "TRACK_EVENT_THROTTLED";
export const SET_PROVIDER_TOKEN = "SET_PROVIDER_TOKEN";
export const REQUEST_PROVIDER_TOKEN = "REQUEST_PROVIDER_TOKEN";
export const RESET_STATE = "RESET_STATE";
export const NAVIGATE = "NAVIGATE";
//MODAL ACTIONS
export const CLOSE_MODAL = "CLOSE_MODAL";
export const OPEN_MODAL = "OPEN_MODAL";
export const CONFIRM_MODAL = "CONFIRM_MODAL";
export const DELETE_MODAL = "DELETE_MODAL";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const CONFIRM_DIALOG_YES = "CONFIRM_DIALOG_YES";
export const CONFIRM_DIALOG_NO = "CONFIRM_DIALOG_NO";
//CONSTANTS

export const verifyAccountStatus = {
    INVALID_ACCOUNT: {
        code: 0,
        message: "Vi fant ikke brukeren din hos 24SevenOffice"
    },
    ALREADY_REGISTERED: {
        code: 1,
        message: "Din bruker er allerede registrert i våre systemer"
    },
    IS_VERIFIED: { code: 2 }
};

export const SYNC_TYPES = {
    FULL: "FULL",
    QUICK: "QUICK",
    USER_WORKTYPE: "USER_WORKTYPE",
    DELETED: "DELETED"
};
export const responseValues = {
    SUCCESS: {
        status: 0,
        message: "Resource successfully returned"
    },
    NOT_FOUND: {
        status: 1,
        message: "Resource not found"
    },
    ALREADY_EXISTS: {
        status: 2,
        message: "Resource already exists"
    },
    INVALID_POST_PARAMS: {
        status: 3,
        message: "Resource cannot be created because of invalid parameters"
    }
};

export const userGroups = {
    USER: 1,
    DEPARTMENT_LEADER: 2,
    ADMIN: 3,
    MASTER: 4
};

export const notificationActions = {
    PAYOUT_REQUESTED: "PAYOUT_REQUESTED",
    PAYOUT_COMPLETED: "PAYOUT_COMPLETED"
};

export const ruleTypes = {
    PERIODIC: "PERIODIC",
    SINGLE_DAY: "SINGLE_DAY"
};
export const ruleCategories = {
    NORMAL_HOURS: "NORMAL_HOURS",
    OVERTIME_ADDITIONAL: "OVERTIME_ADDITIONAL"
};

// Element types for KPI engine
export const elementTypesForKPI = {
    MONTH_VALUE: "MONTH_VALUE",
    CUSTOM_VALUE: "CUSTOM_VALUE",
    KEY_PERFORMANCE_INDICATOR: "KEY_PERFORMANCE_INDICATOR",
    GROUP: "GROUP",
    OPERATOR: "OPERATOR"
};

// Operators for KPI engine
export const operatorsForKPI = {
    ADDITION: "ADDITION",
    SUBTRACTION: "SUBTRACTION",
    MULTIPLICATION: "MULTIPLICATION",
    DIVISION: "DIVISION"
};
