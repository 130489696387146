import React, { Component } from "react";
import { connect } from "react-redux";
import {
    REQUEST_COMPANY_NOTIFICATION_TARGET_GROUPS,
    REQUEST_DELETE_NOTIFICATION_TARGET_GROUP,
    REQUEST_UPDATE_NOTIFICATION_TARGET_GROUP,
    userGroups
} from "../../../actionTypes";

import {
    getCompanyNotificationTargetGroups,
    getJoinedCompanyNotificationTargetGroups
} from "../selectors";
import * as userSelectors from "selectors/user";
import * as _ from "lodash";
import { withRouter } from "react-router-dom";
import Table from "../../../common/table/Table";
import CellPopupMenu from "../../../common/poppers/CellPopupMenu";
import CellPeoplePicker from "../../../components/pickers/people/CellPeoplePicker";
import IconPopupMenu from "../../../common/poppers/IconPopupMenu";
import * as companySelectors from "selectors/company";
import * as utils from "../../../utils";
import TablePlaceholder from "../../../common/table/TablePlaceholder";
import { componentDescriptions } from "../../../constants/strings";

export class NotificationsSettings extends Component {
    constructor() {
        super();
        this.columns = [
            {
                label: "Avdeling",
                type: "custom",
                id: "departmentId",
                renderCell: this.renderDepartments,
                width: "30%"
            },
            {
                label: "Mottakere",
                type: "custom",
                id: "departmentId",
                renderCell: this.renderTargets,
                width: "60%"
            },
            {
                label: "",
                type: "custom",
                id: "more",
                renderCell: this.renderMore,
                width: "5%"
            }
        ];
    }

    componentDidMount = () => {
        this.props.fetchTargetGroups();
    };

    getUsersValidForNotificationTargetGroup = notificationTargetGroup => {
        const { targets, originalTargetGroups } = this.props;
        const filteredUsers = _.filter(targets, user => {
            if (
                !utils.isValidNotificationTarget(
                    user.id,
                    notificationTargetGroup.departmentId,
                    originalTargetGroups,
                    notificationTargetGroup.id
                )
            ) {
                return false;
            }
            if (user.userGroup === userGroups.ADMIN) return true;

            return (
                user.userGroup === userGroups.DEPARTMENT_LEADER &&
                user.departmentId === notificationTargetGroup.departmentId
            );
        });

        return _.orderBy(
            filteredUsers,
            ["firstName", "lastName"],
            ["asc", "asc"]
        );
    };

    renderDepartments = (value, item) => {
        const { departments } = this.props;
        return (
            <CellPopupMenu
                items={_.map(departments, dep => dep.name)}
                value={_.findIndex(
                    departments,
                    dep => dep.id === item.departmentId
                )}
                onChange={index => {
                    this.props.updateNotificationTargetGroup(item.id, {
                        departmentId: departments[index].id
                    });
                }}
            />
        );
    };
    renderTargets = (value, item) => {
        return (
            <CellPeoplePicker
                people={this.getUsersValidForNotificationTargetGroup(item)}
                selected={_.map(item.targets, t => t.id)}
                onChange={newTargets =>
                    this.props.updateNotificationTargetGroup(item.id, {
                        targets: newTargets
                    })
                }
            />
        );
    };
    renderMore = (_, item) => (
        <IconPopupMenu
            items={["Slett varsel"]}
            highlighted={[0]}
            onChange={index =>
                index === 0
                    ? this.props.deleteNotificationTargetGroup(item.id)
                    : null
            }
        />
    );
    render() {
        const { targetGroups } = this.props;
        return (
            <Table
                data={targetGroups}
                columns={this.columns}
                placeholder={
                    <TablePlaceholder
                        description={
                            componentDescriptions.setup.notificationTargetGroup
                        }
                    />
                }
            />
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        departments: companySelectors.getCreatedCompanyDepartmentWithAll(
            state,
            props
        ),
        targets: userSelectors.getEditAuthorizedUsers(state, props),
        targetGroups: getJoinedCompanyNotificationTargetGroups(state, props),
        originalTargetGroups: getCompanyNotificationTargetGroups(state, props)
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const companyId = ownProps.match.params.companyId;
    return {
        updateNotificationTargetGroup: (notificationTargetGroupId, payload) => {
            dispatch({
                type: REQUEST_UPDATE_NOTIFICATION_TARGET_GROUP,
                companyId,
                notificationTargetGroupId,
                payload
            });
        },

        deleteNotificationTargetGroup: notificationTargetGroupId => {
            dispatch({
                type: REQUEST_DELETE_NOTIFICATION_TARGET_GROUP,
                companyId,
                notificationTargetGroupId
            });
        },
        fetchTargetGroups: () => {
            dispatch({
                type: REQUEST_COMPANY_NOTIFICATION_TARGET_GROUPS,
                companyId
            });
        }
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(NotificationsSettings)
);
