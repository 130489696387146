import { forEach, isObject } from "lodash";
export const mergeObjects = (object, newObject) => {
    const returnObject = { ...object };
    forEach(newObject, (value, key) => {
        if (isObject(returnObject[key])) {
            returnObject[key] = {
                ...returnObject[key],
                ...value
            };
        } else {
            returnObject[key] = value;
        }
    });
    return returnObject;
};
