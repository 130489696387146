import React, { Fragment } from "react";
import * as utils from "../../utils";
import Typography from "../Typography";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
const styles = theme => ({
    nameFont: {
        marginLeft: "8px"
    }
});
const TimeGridDate = ({ date, classes }) => {
    const thisDate = moment(date);
    const startOfWeek = moment(thisDate).startOf("isoWeek");
    const endOfWeek = moment(thisDate).endOf("isoWeek");
    return (
        <Fragment>
            <Typography singleLine semibold>
                {`Uke ${thisDate.isoWeek()}`}
            </Typography>
            <Typography
                singleLine
                color={"superFaded"}
                className={classes.nameFont}
            >
                {`${utils.formatShortDate(
                    startOfWeek
                )} - ${utils.formatShortDate(endOfWeek)}`}
            </Typography>
        </Fragment>
    );
};

export default withStyles(styles)(TimeGridDate);
