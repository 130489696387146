import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import SearchField from "common/forms/text-fields/SearchField";
import RoundLabelContainer from "./RoundLabelContainer";
import { Draggable, Droppable } from "react-beautiful-dnd";

const styles = theme => ({
    search: {
        height: "40px",
        borderBottom: `1px solid ${theme.palette.C_DB10}`,
        boxSizing: "border-box"
    },
    variables: {
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        overflow: "hidden",
        padding: "0 20px"
    },
    variableContainer: {
        width: "100%"
    }
});

class KeyPerformanceIndicatorsVariables extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: ""
        };
    }

    filterVariablesOnSearch = (variables, search) =>
        _.filter(variables, variable =>
            variable.name.toLowerCase().includes(search.toLowerCase())
        );

    handleSearchChange = e => {
        this.setState({ search: e.target.value });
    };

    render() {
        const { classes, metaData, disableVariables } = this.props;
        const { search } = this.state;

        const variablesToDisplay = this.filterVariablesOnSearch(
            metaData.standardVariables,
            search
        );

        return (
            <Fragment>
                <div className={classes.search}>
                    <SearchField
                        subLabel={"Søk"}
                        onChange={this.handleSearchChange}
                        style={{ border: "0" }}
                        displayBlock
                    />
                </div>
                <Droppable droppableId={"variables"} isDropDisabled={true}>
                    {(provided, snapshot) => (
                        <div
                            className={classes.variables}
                            ref={provided.innerRef}
                        >
                            {_.map(variablesToDisplay, variable => (
                                <Draggable
                                    draggableId={variable.id}
                                    index={0}
                                    isDragDisabled={disableVariables}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={
                                                classes.variableContainer
                                            }
                                        >
                                            <RoundLabelContainer
                                                label={variable.name}
                                                id={variable.id}
                                                type={
                                                    metaData.elementTypes
                                                        .STANDARD_VALUE
                                                }
                                                onClick={
                                                    this.props.handleAddVariable
                                                }
                                                style={{ marginTop: "13px" }}
                                                disabled={disableVariables}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                        </div>
                    )}
                </Droppable>
            </Fragment>
        );
    }
}

export default withStyles(styles)(KeyPerformanceIndicatorsVariables);
