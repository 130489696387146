import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";

import TutorialHeader from "../../../common/icons/TutorialHeader";
import ArrowBack from "../../../common/icons/LeftPointIcon";
import ArrowNext from "../../../common/icons/RightPointIcon";

import CustomIconButton from "../../../common/forms/buttons/CustomIconButton";
import { tutorialsText } from "utils/strings";
import * as _ from "lodash";
import Typography from "common/Typography";
import classNames from "classnames";
export const storageUrl =
    "https://storage.googleapis.com/integration-static-files";

const styles = theme => ({
    root: {
        position: "absolute",
        left: 0,
        top: 0,
        minHeight: "100%",
        width: "100%",
        backgroundColor: theme.palette.C_W,
        display: "flex",
        boxSizing: "border-box"
    },
    logoIcon: {
        height: "35px",
        width: "100px",
        userSelect: "none",
        flexShrink: 0,
        marginBottom: "75px",
        flexBasis: "15%"
    },
    titleRoot: {
        padding: "80px"
    },
    topBar: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: theme.palette.C_W,
        height: "100%",
        transition: "width 800ms ease"
    },
    topBarContainer: {
        display: "flex",
        flexDirection: "column",
        minWidth: "450px",
        backgroundColor: theme.palette.C_W,
        width: "35%",
        minHeight: "100%",
        zIndex: 2,
        transition: "width 800ms ease, min-width 800ms ease"
    },
    header: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        transition: "opacity 400ms ease",
        marginTop: "100px"
    },
    animationContainer: {
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.1)",
        height: "85%",
        position: "absolute",
        left: "100px",
        transition: "left 800ms ease, opacity 400ms ease",
        overflowY: "hidden",
        minHeight: "560px",
        width: "100%",
        overflowX: "hidden"
    },
    tutorialHeader: {
        height: "unset",
        width: "100%",
        position: "relative",
        left: "-1px"
    },
    headerCircle: {
        backgroundColor: theme.palette.C_DB15,
        width: "8px",
        height: "8px",
        marginLeft: "5px",
        borderRadius: "50%"
    },
    animation: {
        height: "calc(100% - 20px)",
        top: -5,
        position: "relative"
    },
    bottomBar: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        padding: "30px 40px",
        boxSizing: "border-box",
        alignItems: "center"
    },
    navigation: {
        display: "flex",
        justifyContent: "flex-end",
        "& > *": {
            marginLeft: "20px"
        },
        alignItems: "center",
        height: "30px"
    },
    contentContainer: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.C_DB5,
        width: "65%",
        justifyContent: "center",
        position: "relative",
        overflow: "hidden",
        transition: "width 800ms ease"
    },
    "@media (max-width: 1024px)": {
        contentContainer: {
            width: "100%"
        },
        topBar: {
            width: "100%",
            minHeight: "40vh"
        },
        topBarContainer: {
            width: "100%"
        },
        root: {
            flexDirection: "column-reverse",
            justifyContent: "flex-end"
        },
        animationContainer: {
            position: "relative",
            left: "80px",
            margin: "50px 0",
            minHeight: "calc(60vh - 100px)",
            height: "500px",
            width: "calc(100% - 80px)"
        },
        titleRoot: {
            padding: "40px"
        },
        header: {
            marginTop: "0px"
        }
    },
    description: {
        marginTop: "20px"
    },
    animateText: {
        opacity: "0"
    },
    animateLeft: {
        left: "-200px",
        opacity: "0"
    },
    animateRight: {
        left: "400px",
        opacity: "0"
    },
    fillScreen: {
        width: "100%"
    },

    emptyScreen: {
        width: "0%",
        minWidth: "unset",
        overflow: "hidden",
        maxHeight: "100vh"
    }
});
class Tutorial extends Component {
    state = {
        windowHeight: window.innerHeight,
        videoIsRunning: true,
        isAnimating: false,
        text: {
            headerText: "",
            subheaderText: "",
            title: ""
        },
        topic: "",
        fillScreen: false,
        isHidingContent: false
    };

    videoElement = null;

    constructor(props) {
        super(props);
        this.state.text = tutorialsText[props.topic];
        this.state.topic = props.topic;
    }
    componentDidMount() {
        window.addEventListener("resize", this.resize);
        window.addEventListener("keydown", this.handleKeyDown);
        if (this.videoElement) {
            this.videoElement.addEventListener("ended", this.handleVideoEnded);
        }
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        window.removeEventListener("keydown", this.handleKeyDown);
    }
    handleKeyDown = e => {
        if (e.key === "ArrowRight") {
            e.preventDefault();
            this.handleNextClick();
        } else if (e.key === "ArrowLeft") {
            e.preventDefault();
            this.handlePreviousClick();
        }
    };

    componentDidUpdate(prevProps) {
        if (this.props.topic !== prevProps.topic) {
            setTimeout(() => {
                this.setState({
                    text: tutorialsText[this.props.topic],
                    topic: this.props.topic
                });
            }, 500);
        }
    }

    resize = () => {
        this.setState({ windowHeight: window.innerHeight });
    };

    handleVideoEnded = () => {
        if (this.videoElement !== null) {
            this.setState({ videoIsRunning: false });
        }
    };

    handlePreviousClick = () => {
        const index = _.findIndex(
            this.props.topics,
            t => t === this.props.topic
        );
        if (index > 0) {
            this.setState({ videoIsRunning: true });
            this.props.navigate(`/tutorial/${this.props.topics[index - 1]}`);
            this.animatePrev();
        }
    };

    handleNextClick = () => {
        const index = _.findIndex(
            this.props.topics,
            t => t === this.props.topic
        );
        if (index < this.props.topics.length - 1) {
            this.setState({ videoIsRunning: true });
            this.props.navigate(`/tutorial/${this.props.topics[index + 1]}`);
            this.animateNext();
        } else {
            this.completeTutorial();
        }
    };

    completeTutorial = () => {
        this.setState({
            fillScreen: true,
            isAnimating: true,
            isHidingContent: true
        });

        setTimeout(() => {
            this.props.navigate("/login");
        }, 1400);
    };

    animateNext = () => {
        this.setState({ isAnimatingLeft: true, isAnimating: true });
        setTimeout(() => {
            this.setState({ isAnimatingLeft: false, isAnimatingRight: true });
        }, 300);

        setTimeout(() => {
            this.setState({
                isAnimating: false,
                isAnimatingLeft: false,
                isAnimatingRight: false
            });
        }, 600);
    };
    animatePrev = () => {
        this.setState({ isAnimatingRight: true, isAnimating: true });
        setTimeout(() => {
            this.setState({ isAnimatingRight: false, isAnimatingLeft: true });
        }, 300);

        setTimeout(() => {
            this.setState({
                isAnimating: false,
                isAnimatingLeft: false,
                isAnimatingRight: false
            });
        }, 600);
    };
    handleVideoRef = ref => {
        this.videoElement = ref;

        if (ref) {
            this.videoElement.pause();
            setTimeout(() => {
                if (this.videoElement) {
                    this.videoElement.play();
                }
            }, 3000);
        }
    };

    renderVideo = (topic, alt) => (
        <video
            className={this.props.classes.animation}
            muted
            ref={this.handleVideoRef}
            key={topic}
            alt={alt}
        >
            <source src={`/tutorial/${topic}.webm`} type={"video/webm"} />
            <source src={`/tutorial/${topic}.ogg`} type={"video/ogg"} />
            <source src={`/tutorial/${topic}.mp4`} type={"video/mp4"} />
        </video>
    );

    renderImage = (topic, alt) => (
        <img
            className={this.props.classes.animation}
            key={topic}
            src={`${storageUrl}/tutorial/${topic}.jpg`}
            alt={alt}
        />
    );
    render() {
        const { classes } = this.props;
        const {
            headerText,
            subheaderText,
            title,
            filetype,
            alt
        } = this.state.text;
        const { topic } = this.state;

        const index = _.findIndex(
            this.props.topics,
            t => t === this.props.topic
        );

        return (
            <div className={classes.root}>
                <div
                    className={classNames(classes.topBarContainer, {
                        [classes.emptyScreen]: this.state.fillScreen
                    })}
                >
                    <div
                        className={classNames(classes.topBar, {
                            [classes.emptyScreen]: this.state.fillScreen
                        })}
                    >
                        <div className={classes.titleRoot}>
                            {/*<BusyLogo className={classes.logoIcon} />*/}
                            <div
                                className={classNames(classes.header, {
                                    [classes.animateText]: Boolean(
                                        this.state.isAnimating
                                    )
                                })}
                            >
                                <Typography
                                    bold
                                    size={"xxl"}
                                    color={"positive"}
                                >
                                    {title}
                                </Typography>

                                <Typography bold size={"xxl"}>
                                    {headerText}
                                </Typography>
                                <Typography
                                    size={"large"}
                                    className={classes.description}
                                    description
                                >
                                    {subheaderText}
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.bottomBar}>
                            <CustomIconButton
                                onClick={this.handlePreviousClick}
                                disabled={index === 0}
                                hidden={index === 0}
                                icon={<ArrowBack />}
                                iconSide={"left"}
                                offset={-2}
                            />
                            <Typography>{`${index + 1} of ${
                                this.props.topics.length
                            }`}</Typography>
                            <CustomIconButton
                                onClick={this.handleNextClick}
                                icon={<ArrowNext />}
                                iconSide={"right"}
                                offset={2}
                            />
                        </div>
                    </div>
                </div>

                <div
                    className={classNames(classes.contentContainer, {
                        [classes.fillScreen]: this.state.fillScreen
                    })}
                >
                    <div
                        className={classNames(classes.animationContainer, {
                            [classes.animateLeft]: this.state.isAnimatingLeft,
                            [classes.animateRight]: this.state.isAnimatingRight,
                            [classes.animateText]: this.state.isHidingContent
                        })}
                    >
                        <TutorialHeader className={classes.tutorialHeader} />
                        {filetype === "img"
                            ? this.renderImage(topic, alt)
                            : this.renderVideo(topic, alt)}
                    </div>
                </div>
            </div>
        );
    }
}

Tutorial.propTypes = {};
Tutorial.defaultProps = {
    navigation: null
};

export default withStyles(styles)(Tutorial);
