import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "../../../common/Typography";
import * as _ from "lodash";

const styles = theme => ({
    root: {
        height: "65px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: `solid 1px ${theme.palette.C_DB15}`
    },
    tag: {
        padding: "10px 15px",
        fontWeight: 600,
        backgroundColor: theme.palette.C_DB10,
        borderRadius: "4px"
    },
    rightContainer: {
        display: "flex",
        alignItems: "center"
    }
});

class CardHeader extends PureComponent {
    render() {
        const { title, icon, tag, classes, ...other } = this.props;

        let titleView = _.isString(title) ? (
            <Typography size={"large"} bold>
                {title}
            </Typography>
        ) : (
            title
        );

        const tagView = _.isString(tag) ? (
            <Typography
                color={"negative"}
                size={"smallMed"}
                className={classes.tag}
            >
                {tag}
            </Typography>
        ) : null;
        return (
            <Fragment>
                <div className={classes.root} {...other}>
                    {titleView}
                    {this.props.children}
                    <div className={classes.rightContainer}>
                        {tagView}
                        {icon}
                    </div>
                </div>
            </Fragment>
        );
    }
}

CardHeader.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.node,
    tag: PropTypes.string
};
CardHeader.defaultProps = {
    title: "",
    icon: null,
    tag: null
};

export default withStyles(styles)(CardHeader);
