import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import IndexPicker from "./IndexPicker";

const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mai",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Okt",
    "Nov",
    "Des"
];
class MonthPicker extends PureComponent {
    onChangeNext = () => {
        const { year, month, onChange } = this.props;
        const thisMoment = moment([year, month]).add(1, "M");

        onChange(thisMoment.year(), thisMoment.month());
    };
    onChangePrev = () => {
        const { year, month, onChange } = this.props;
        const thisMoment = moment([year, month]).subtract(1, "M");

        onChange(thisMoment.year(), thisMoment.month());
    };

    formatDate = (year, month) => {
        return `${months[month]} ${year}`;
    };

    render() {
        const { year, month } = this.props;

        const textValue = this.formatDate(year, month);

        return (
            <IndexPicker
                center={textValue}
                onLeftClick={this.onChangePrev}
                onRightClick={this.onChangeNext}
            />
        );
    }
}

MonthPicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    //Dateobject of current date
    year: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired
};
MonthPicker.defaultProps = {
    year: moment().year(),
    month: moment().month()
};
export default MonthPicker;
