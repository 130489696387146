import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core";
import Typography from "../Typography";
import classNames from "classnames";
const styles = theme => ({
    root: {
        width: "100%",
        height: "30px",
        backgroundColor: theme.palette.C_DB5,
        border: `solid 1px ${theme.palette.C_DB10}`,
        padding: "0 14px",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        color: theme.palette.C_DB40
    }
});

class PopupDivider extends PureComponent {
    render() {
        const { classes, children, className } = this.props;
        return (
            <div className={classNames(classes.root, className)}>
                <Typography color={"inherit"} size={"smallMed"} uppercase>
                    {children}
                </Typography>
            </div>
        );
    }
}

PopupDivider.propTypes = {};

export default withStyles(styles)(PopupDivider);
