import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const cls1 = {
    fill: "#fff",
    stroke: "#eaecee",
    strokeMiterlimit: 10
};
const cls2 = {
    fill: "#abb1b9"
};
export default function(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 21 21">
            <circle style={cls1} cx="10.5" cy="10.5" r="10" />
            <path
                style={cls2}
                d="M7.81,12.46a.83.83,0,0,1-1.15-.16.84.84,0,0,1,0-1L9.93,8.05a.81.81,0,0,1,1.14,0l3.27,3.27a.82.82,0,0,1-1.15,1.14L10.5,9.78,7.81,12.47Z"
            />
        </SvgIcon>
    );
}
