import * as actions from "../../actionTypes";
import { analytics } from "../../utils/strings";
import moment from "moment";

export const fetchTimebank = (companyId, date, departmentId) => {
    return {
        type: actions.REQUEST_TIMEBANK_MONTHLY,
        payload: {
            companyId,
            date,
            departmentId
        }
    };
};
export const fetchTimebankDaily = (companyId, departmentId, month, year) => {
    return {
        type: actions.REQUEST_COMPANY_HOURS_DAILY,
        payload: {
            companyId,
            month,
            year,
            departmentId
        }
    };
};
export const setComment = (comment, userId, date, departmentId, companyId) => {
    return {
        type: actions.REQUEST_SET_COMMENT,
        payload: {
            comment: comment || "",
            userId,
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            departmentId,
            companyId
        }
    };
};
export const onExportTable = type => {
    let action = analytics.actions.EXPORT_EXCEL;
    if (type === "pdf") {
        action = analytics.actions.EXPORT_PDF;
    } else if (type === "csv") {
        action = analytics.actions.EXPORT_PDF;
    }
    return {
        type: actions.TRACK_EVENT,
        payload: {
            category: analytics.categories.TABLE_EXPORT,
            action
        }
    };
};
export const onSort = columnId => {
    return {
        type: actions.TRACK_EVENT,
        payload: {
            category: analytics.categories.DASHBOARD,
            action: analytics.actions.SORT_TABLE_COLUMNS,
            label: "User sorted column " + columnId
        }
    };
};
export const requestTimebankWeeks = (userId, currentDate) => {
    const momentDate = moment(currentDate);
    return {
        type: actions.REQUEST_TIMEBANK_WEEK_BY_MONTH,
        payload: {
            userId: userId,
            month: momentDate.month(),
            year: momentDate.year()
        }
    };
};

export const setTab = index => {
    return {
        type: actions.UPDATE_TIMEBANK_META,
        payload: {
            currentTab: index === 1 ? "GRID" : "TABLE"
        }
    };
};

export const onChangeSearch = searchString => {
    return {
        type: actions.UPDATE_TIMEBANK_META,
        payload: {
            searchString
        }
    };
};

export const updateTimebankMeta = payload => {
    return {
        type: actions.UPDATE_TIMEBANK_META,
        payload
    };
};
