import React, { Component } from "react";
import { connect } from "react-redux";

import PaperHeader from "../../../components/layout/paper/PaperHeader";
import PaperHeaderTitle from "../../../components/layout/paper/PaperHeaderTitle";
import * as userSelectors from "../../../selectors/user";
import { withRouter } from "react-router-dom";
import Slider from "../../../common/forms/Slider";
import { setUserDetailsFilters } from "../../../actions/user";
import { componentDescriptions } from "../../../constants/strings";

class UserDetailsHeader extends Component {
    render() {
        const { currentUser, backLink, filters } = this.props;
        return (
            <PaperHeader
                description={componentDescriptions.userDetails.generic}
                title={
                    <PaperHeaderTitle
                        primary={["Brukere", currentUser.displayName]}
                        backLink={backLink}
                    />
                }
            >
                <Slider
                    tabs={["Generelt", "Logg"]}
                    selectedTab={filters.selectedTab}
                    onSelectTab={i =>
                        this.props.setUserDetailsFilters({ selectedTab: i })
                    }
                    background={"paper"}
                />
            </PaperHeader>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        currentUser: userSelectors.getCurrentUser(state, props),
        filters: userSelectors.getUserDetailsFilters(state),
        backLink: `/users/${props.match.params.companyId}`
    };
};

export default withRouter(
    connect(mapStateToProps, { setUserDetailsFilters })(UserDetailsHeader)
);
