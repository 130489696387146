import { isString } from "lodash";
import * as actions from "../../actionTypes";

export const fetchUsers = (filters, companyId, displayType) => {
    const options = {};

    if (filters.departmentId && filters.departmentId !== null) {
        options.departmentId = filters.departmentId;
    }
    if (isString(filters.includeUsers)) {
        options.includeUsers = filters.includeUsers;
    }

    const requestType =
        displayType === "GRID"
            ? actions.REQUEST_ALL_USERS_SHALLOW
            : actions.REQUEST_ALL_USERS;
    return {
        type: requestType,
        payload: {
            companyId,
            options
        }
    };
};

export const setShallowUserFilters = filters => {
    return {
        type: actions.SET_USER_FILTERS,
        payload: filters
    };
};
