import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import InfoBar from "../../../components/infobar/InfoBar";
import BorderIconButton from "../../../components/buttons/BorderIconButton";
import ReportIcon from "../../../common/icons/ReportIcon";
import * as selectors from "./selectors";
import Link from "react-router-dom/Link";

export class UserDetailsInfoBar extends Component {
    render() {
        const { companyId, userId } = this.props.match.params;
        return (
            <InfoBar keyFigures={this.props.keyFigures}>
                <BorderIconButton
                    icon={<ReportIcon />}
                    label={"Gå til timeoversikt"}
                    variant={"travel"}
                    component={Link}
                    to={`/dashboard/${companyId}/users/${userId}`}
                />
            </InfoBar>
        );
    }
}

const mapStateToProps = (state, props) => ({
    keyFigures: selectors.getUserKeyFigures(state, props)
});

export default withRouter(connect(mapStateToProps, null)(UserDetailsInfoBar));
