import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import CardDivider from "./CardDivider";
const styles = theme => ({
    divider: {
        marginBottom: "10px",
        marginTop: "10px"
    }
});

class CardDividerGroup extends React.Component {
    render() {
        const { classes, children, withDivider, ...other } = this.props;

        return React.Children.map(children, (child, i) => {
            const formChild = child
                ? React.cloneElement(child, {
                      className: classNames(
                          classes.item,
                          { [classes.divider]: withDivider },
                          child.props.className
                      )
                  })
                : null;
            return i !== children.length - 1 ? (
                <Fragment>
                    {formChild}
                    <CardDivider className={classes.divider} />
                </Fragment>
            ) : (
                formChild
            );
        });
    }
}

export default withStyles(styles)(CardDividerGroup);
