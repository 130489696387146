import React, { Component } from "react";
import Tutorial from "./Tutorial";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as types from "../../../actionTypes";
import * as _ from "lodash";
import { storageUrl } from "./Tutorial";

const topics = ["timeReport", "timeControl", "users", "settings"];
const imgTopics = ["timeReport", "timeControl", "users", "settings"];

class Tutorials extends Component {
    componentDidMount() {
        _.forEach(imgTopics, t => {
            const img = new Image();
            img.src = `${storageUrl}/tutorial/${t}.jpg`;
        });
    }
    render() {
        let topic = this.props.match.params.topic;

        if (!topics.includes(topic)) {
            return <Redirect to={"/tutorial/timeReport"} />;
        }

        return (
            <Tutorial
                cameFrom={"/login"}
                topic={topic}
                topics={topics}
                navigate={this.props.navigate}
            />
        );
    }
}

const mapStateToProps = (state, props) => {
    return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        navigate: url => {
            dispatch({
                type: types.NAVIGATE,
                payload: { url }
            });
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Tutorials);
