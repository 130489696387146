import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import CardDivider from "./CardDivider";
const styles = theme => ({
    root: {
        padding: "20px 0"
    },
    item: {
        marginBottom: "20px"
    },
    divider: {
        marginBottom: "10px"
    }
});

class CardForm extends React.Component {
    render() {
        const { classes, children, withDivider, ...other } = this.props;

        const formItems = React.Children.map(children, (child, i) => {
            const formChild = child
                ? React.cloneElement(child, {
                      className: classNames(
                          classes.item,
                          { [classes.divider]: withDivider },
                          child.props.className
                      )
                  })
                : null;
            return withDivider && i !== children.length - 1 ? (
                <Fragment>
                    {formChild}
                    <CardDivider className={classes.divider} />
                </Fragment>
            ) : (
                formChild
            );
        });
        return (
            <div className={classes.root} {...other}>
                {formItems}
            </div>
        );
    }
}

CardForm.propTypes = {
    withDivider: PropTypes.bool
};
CardForm.defaultProps = {
    withDivider: false
};

export default withStyles(styles)(CardForm);
