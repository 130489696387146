import React, { Fragment, PureComponent } from "react";
import NotificationsIcon from "./NotificationsIcon";
import NotificationActionItem from "./NotificationActionItem";
import { map } from "lodash";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ButtonBase from "@material-ui/core/ButtonBase";
import classNames from "classnames";
import Placeholder from "../../common/lists/Placeholder";
import Popup from "../../common/poppers/Popup";
import PopupDivider from "../../common/poppers/PopupDivider";

const types = {
    ACTION_REQUIRED: "ACTION_REQUIRED",
    READ_REQUIRED: "READ_REQUIRED"
};

const styles = theme => ({
    root: {
        width: "330px",
        padding: "0 20px",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    paper: {
        paddingTop: "10px",
        maxHeight: "500px",
        overflowY: "auto",
        overflowX: "hidden"
    },
    subHeader: {
        height: "30px",
        width: "calc(100% - 40px)",
        padding: "0 20px",
        backgroundColor: theme.palette.C_DB5,
        display: "flex",
        alignItems: "center"
    },
    subHeaderFont: {
        color: theme.palette.C_DB40,
        textTransform: "uppercase",
        fontWeight: 600
    },
    markAll: {
        color: theme.palette.C_DB40,
        "&:hover": {
            color: theme.palette.C_LB,
            textDecoration: "underline"
        }
    },
    red: {
        color: theme.palette.C_R
    },
    placeholder: {
        padding: "0 20px"
    },
    iconButton: {
        height: "60px",
        width: "60px",
        "&:hover": {
            color: theme.palette.C_LB
        }
    }
});

class NotificationsList extends PureComponent {
    state = {
        popoverOpen: false
    };

    componentDidMount() {
        const { requestNotifications, signedInUser } = this.props;

        requestNotifications(signedInUser.id);
    }

    handlePopupToggle = open => {
        this.setState({ popoverOpen: !this.state.popoverOpen });
    };
    handleClickAway = open => {
        this.setState({ popoverOpen: false });
    };

    handleMarkAllClick = () => {
        const {
            newNotifications,
            updateReadNotifications,
            signedInUser
        } = this.props;

        updateReadNotifications(newNotifications, signedInUser.id);
    };

    handleNotificationCancel = notification => event => {
        const { signedInUser, cancelNotification } = this.props;

        cancelNotification(notification.notificationId, signedInUser.id);
    };
    //Called when user wants to issue payout
    handleNotificationConfirm = notification => event => {
        const { redirectConfirmNotifications } = this.props;

        this.setState(
            {
                popoverOpen: false
            },
            () => {
                redirectConfirmNotifications(notification);
            }
        );
    };

    renderNotificationItems = (notifications, oldNotifications) => {
        const { classes } = this.props;
        const notificationsHeader = oldNotifications ? "Tidligere" : "Nye";
        return (
            <Fragment>
                {notifications.length > 0 ? (
                    <PopupDivider
                        className={classNames({
                            [classes.red]: !oldNotifications
                        })}
                    >
                        {notificationsHeader}
                    </PopupDivider>
                ) : null}

                {/*<div className={classes.subHeader}>
                        <Typography
                            className={classNames(classes.subHeaderFont, {
                                [classes.red]: !oldNotifications
                            })}
                            type="display3"
                        >
                            {notificationsHeader}
                        </Typography>
                    </div>
                ) : null}*/}
                {map(notifications, (not, i) => {
                    const item = (
                        <NotificationActionItem
                            {...not}
                            amount={not.amountDisplay}
                            onCancel={this.handleNotificationCancel(not)}
                            onConfirm={
                                not.type === types.ACTION_REQUIRED &&
                                !not.approvedBy &&
                                !oldNotifications
                                    ? this.handleNotificationConfirm(not)
                                    : undefined
                            }
                        />
                    );
                    if (i !== notifications.length - 1) {
                        return (
                            <Fragment key={not.notificationId}>
                                {item}
                                <Divider
                                    key={not.notificationId + "_divider"}
                                />
                            </Fragment>
                        );
                    }
                    return item;
                })}
            </Fragment>
        );
    };

    render() {
        const { popoverOpen } = this.state;
        const { oldNotifications, newNotifications, classes } = this.props;


        return (
            <Popup
                onClickAway={this.handleClickAway}
                open={popoverOpen}
                target={
                    <IconButton
                        disableRipple
                        onClick={this.handlePopupToggle}
                        className={classes.iconButton}
                    >
                        <NotificationsIcon count={newNotifications.length} />
                    </IconButton>
                }
                placement="right-end"
                popperClassName={classes.popper}
            >
                <div className={classes.paper}>
                    <div className={classes.root}>
                        <Typography variant="body2">{"Varsler"} </Typography>
                        {/* Only render mark all button if there are new notifications*/}
                        {newNotifications.length > 0 ? (
                            <ButtonBase
                                disableRipple
                                title="Marker alle varsler som lest"
                                onClick={this.handleMarkAllClick}
                            >
                                <Typography
                                    variant="body1"
                                    className={classes.markAll}
                                >
                                    Marker alle lest
                                </Typography>
                            </ButtonBase>
                        ) : null}
                    </div>
                    <Divider />
                    {/* Render placeholder if there are neither new or old notifications*/}

                    {newNotifications.length === 0 &&
                    oldNotifications.length === 0 ? (
                        <Placeholder>{"Ingen varsler ..."}</Placeholder>
                    ) : null}
                    {this.renderNotificationItems(newNotifications, false)}
                    {this.renderNotificationItems(oldNotifications, true)}
                </div>
            </Popup>
        );
    }
}

export default withStyles(styles)(NotificationsList);
