import React, { Component } from "react";
import SimpleDialog from "../../common/dialogs/SimpleDialog";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import * as modalActions from "./actions";
import DialogButtonGroup from "../../common/dialogs/dialogContent/DialogButtonGroup";
import InputField from "../../common/forms/text-fields/InputField";
import MailIcon from "../../common/icons/MailIcon";
import * as types from "../../actionTypes";
import LockIcon from "../../common/icons/LockIcon";

const styles = theme => ({
    buttonGroup: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        marginTop: "25px"
    },
    upperButton: {
        width: "48%",
        marginBottom: "15px"
    },
    inputIcon: {
        color: theme.palette.C_DB40,
        width: "15px",
        height: "15px"
    }
});
class SignInModal extends Component {
    state = {
        email: "",
        password: ""
    };

    handleConfirm = e => {
        const { email, password } = this.state;
        if (!email || !password) return;

        this.props.onConfirm(email, password);
    };
    render() {
        const { classes } = this.props;
        const { email, password } = this.state;
        return (
            <SimpleDialog
                title={"Økten har utløpt"}
                description={
                    "Vennligst logg inn på nytt med din 24SevenOffice konto."
                }
                open={true}
                onClose={this.props.onClose}
                buttonGroup={
                    <DialogButtonGroup
                        confirmButton={"Logg inn"}
                        onCancel={this.props.onClose}
                        onConfirm={this.handleConfirm}
                    />
                }
            >
                <InputField
                    autoComplete={"email"}
                    type={"email"}
                    rightIcon={<MailIcon className={classes.inputIcon} />}
                    placeholder={"Epost i 24SevenOffice"}
                    fullWidth
                    onChange={e => this.setState({ email: e.target.value })}
                    value={email}
                />
                <InputField
                    autoComplete={"password"}
                    type={"password"}
                    className={classes.item}
                    rightIcon={<LockIcon className={classes.inputIcon} />}
                    placeholder={"Passord i 24SevenOffice"}
                    fullWidth
                    onChange={e => this.setState({ password: e.target.value })}
                    value={password}
                />
            </SimpleDialog>
        );
    }
}

SignInModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    modalProps: PropTypes.object,
    onConfirm: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
    return {
        onConfirm: (email, password) => {
            dispatch({
                type: types.REQUEST_PROVIDER_TOKEN,
                payload: { email, password }
            });
            dispatch(modalActions.closeModal());
        },
        onClose: () => dispatch(modalActions.closeModal())
    };
};
export default compose(connect(null, mapDispatchToProps), withStyles(styles))(
    SignInModal
);
