import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import Fade from "@material-ui/core/Fade";
import classNames from "classnames";
import * as utils from "utils";

const styles = theme => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",

        "& > *:first-child": {
            width: "40%"
        },
        "& > *:last-child": {
            width: "55%",
            marginLeft: "20px"
        }
    },
    button: {
        marginTop: "10px"
    }
});

class HiddenButtonGroup extends Component {
    state = {
        collapse: null,
        fade: null,
        show: null,
        canShow: false
    };

    async componentDidMount() {
        const { show } = this.props;
        this.setState({ fade: show, collapse: show, show });

        // Wait unit page has loaded.
        await utils.timeout(1000);
        this.setState({ canShow: true });
    }

    componentDidUpdate(prevProps) {
        if (this.props.show !== this.state.show) {
            if (this.props.show) {
                this.setState({ collapse: true, show: true });
                setTimeout(() => {
                    this.setState({ fade: true });
                }, 200);
            } else {
                this.setState({ fade: false, show: false });
                setTimeout(() => {
                    this.setState({ collapse: false });
                }, 200);
            }
        }
    }
    render() {
        const { children, classes, show, className, ...other } = this.props;
        const childrenButtons = React.Children.map(children, child => {
            return (
                <Collapse
                    in={this.state.collapse && this.state.canShow}
                    timeout={300}
                >
                    <Fade
                        in={this.state.fade && this.state.canShow}
                        timeout={{ enter: 300, exit: 300 }}
                    >
                        {React.cloneElement(child, {
                            fullWidth: true,
                            className: classNames(
                                classes.button,
                                child.props.className
                            )
                        })}
                    </Fade>
                </Collapse>
            );
        });

        return (
            <div
                className={classNames(classes.root, {
                    [classes.active]: this.state.collapse
                })}
                {...other}
            >
                {childrenButtons}
            </div>
        );
    }
}

HiddenButtonGroup.propTypes = {
    /**
     * Whether the buttons should be shown. Will be animated when show changes value
     */
    show: PropTypes.bool
};

HiddenButtonGroup.defaultProps = {
    show: true
};

export default withStyles(styles)(HiddenButtonGroup);
