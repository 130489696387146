import React, { Fragment, PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    enhanced: {
        fontWeight: "bold",
        display: "inline"
    },
    text: {
        display: "inline",
        textAlign: "left"
    },
    timestamp: {
        fontSize: theme.typography.F_S,
        color: theme.palette.C_DB60,
        textAlign: "left"
    }
});
class NotificationContent extends PureComponent {
    render() {
        const {
            firstName,
            lastName,
            description,
            amount,
            date,
            classes
        } = this.props;

        const dateAddition = date ? ` • ${date}` : "";

        return (
            <Fragment>
                <Typography
                    component={"span"}
                    variant="body1"
                    className={classes.text}
                >
                    {`${description} ${amount}`}
                </Typography>
                <Typography className={classes.timestamp} variant="body1">
                    {`${firstName} ${lastName}`.trim() + dateAddition}
                </Typography>
            </Fragment>
        );
    }
}

export default withStyles(styles)(NotificationContent);
