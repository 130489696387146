import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const styles = theme => ({
    root: {
        width: "100%",
        background: theme.palette.C_DB5,
        border: `solid 1px ${theme.palette.C_DB10}`,
        //height: "35px",
        padding: "8px 20px",
        borderRadius: "18px",
        boxSizing: "border-box",
        color: theme.palette.C_AB,
        fontSize: theme.typography.F_N,
        "&:hover": {
            color: theme.palette.C_LB,
            border: `solid 1px ${theme.palette.C_DB15}`
        }
    },
    disabled: {
        border: `solid 1px ${theme.palette.C_DB10}`,
        color: theme.palette.C_DB40,
        "&:hover": {
            color: theme.palette.C_DB40,
            border: `solid 1px ${theme.palette.C_DB10}`
        }
    },
    iconContainer: {
        left: "-2px",
        width: "13px",
        padding: "0px",
        height: "13px",
        position: "relative"
    },
    variableInGroup: {
        position: "relative",
        left: "-1px",
        top: "-1px",
        width: "calc(100% + 2px)",
        "&:hover": {
            color: theme.palette.C_AB,
            border: `solid 1px ${theme.palette.C_DB10}`
        }
    },
    iconInGroup: {
        left: "-1px",
        top: "-1px"
    },
    inActiveGroup: {
        border: `1px solid ${theme.palette.C_DB15}`
    }
});

class RoundLabelContainer extends Component {
    constructor(props) {
        super(props);
    }

    handleOnClick = () => {
        if (this.props.onClick && !this.props.disabled) {
            this.props.onClick(this.props.id, this.props.type);
        }
    };

    render() {
        const {
            classes,
            label,
            disabled,
            isOperator,
            icon,
            style,
            iconInGroup,
            variableInGroup,
            inActiveGroup
        } = this.props;

        return (
            <div
                className={classNames(classes.root, {
                    [classes.disabled]: disabled,
                    [classes.iconContainer]: isOperator,
                    [classes.variableInGroup]: variableInGroup,
                    [classes.iconInGroup]: iconInGroup,
                    [classes.inActiveGroup]: inActiveGroup
                })}
                onClick={this.handleOnClick}
                style={style}
            >
                {isOperator ? icon : label}
            </div>
        );
    }
}

export default withStyles(styles)(RoundLabelContainer);
