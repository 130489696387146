import * as _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../../actionTypes";
import Menu from "../../../common/dropdown/Menu";
import SwitchWithTextList from "../../../common/lists/SwitchWithTextList";
import * as selectors from "./selectors";

const options = ["Tilbakestill til standard"];
class PresentationSettings extends PureComponent {
    onMenuSelect = option => {
        if (option === options[0]) {
            this.props.resetPresentationSettingsToCompany();
        }
    };
    render() {
        const { presentationItems, updatePresentationSetting } = this.props;

        const itemsWithHandlers = _.map(presentationItems, item => {
            return {
                primary: item.displayName,
                value: item.value,
                onChange: newVal => updatePresentationSetting(item.id, newVal)
            };
        });

        return (
            <SwitchWithTextList
                data={itemsWithHandlers}
                title="Visning"
                titleIcon={
                    <Menu options={options} onSelect={this.onMenuSelect} />
                }
            />
        );
    }
}

const mapStateToProps = (state, props) => ({
    presentationItems: selectors.getPresentationItems(state, props)
});

const mapDispatchToProps = (dispatch, ownProps) => {
    const { departmentId, companyId } = ownProps.match.params;
    return {
        updatePresentationSetting: (key, value) => {
            dispatch({
                type: actions.UPDATE_DISPLAY_SETTINGS,
                payload: {
                    companyId,
                    departmentId,
                    itemId: key,
                    value
                }
            });
        },
        resetPresentationSettingsToCompany: () => {
            dispatch({
                type: actions.REQUEST_DELETE_DEPARTMENT_VIEW,
                companyId,
                departmentId
            });
        }
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PresentationSettings)
);
