import * as types from "actionTypes";
export const updateDisplaySettings = (companyId, view, departmentId) => {
    return {
        type: types.UPDATE_DISPLAY_SETTINGS,
        payload: {
            companyId,
            departmentId,
            view
        }
    };
};
