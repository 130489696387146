import * as types from "../actionTypes";

const initialState = {
    privacy: {
        selectedTab: 0
    },
    setup: {
        selectedTab: 0
    },
    workTypes: {
        selectedWorkTypes: {}
    }
};
export default function settings(state = initialState, action) {
    switch (action.type) {
        case types.SET_ACTIVE_PRIVACY_TAB:
            return {
                ...state,
                privacy: {
                    ...state.privacy,
                    selectedTab: action.payload
                }
            };
        case types.SET_ACTIVE_SETUP_TAB:
            return {
                ...state,
                setup: {
                    ...state.setup,
                    selectedTab: action.payload
                }
            };
        case types.SET_SELECTED_WORKTYPES:
            return {
                ...state,
                workTypes: {
                    selectedWorkTypes: action.payload
                }
            };

        default:
            return state;
    }
}
