import React, { Component, Fragment } from "react";
import Register from "./Register";
import SimpleDialog from "../dialogs/SimpleDialog";
import PickerDialog from "../dialogs/PickerDialog";
import { isInteger, forEach, map } from "lodash";
import {
    REQUEST_REGISTER_COMPANY,
    REQUEST_ACCOUNT_VERIFICATION,
    SET_VERIFY_ACCOUNT_STATUS,
    verifyAccountStatus
} from "../../actionTypes";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import qs from "query-string";
import { dialog } from "../../constants/strings";
import DialogButtonGroup from "../dialogs/dialogContent/DialogButtonGroup";
import * as _ from "lodash";
const selectOptions = ["Månedsbasert", "Ukesbasert"];

class RegisterContainer extends Component {
    inputRefs = {};

    state = {
        invalidKey: null,
        confirmedTerms: false,
        verificationStatus: -1,
        verificationMessage: "",
        selectedClient: null,
        selectedOption: 0,
        disabledConfirm: true,
        inputs: {
            email: "",
            password: "",
            normalHours: 8,
            overtimeAdditional: 50,
            vacationDays: 25
        }
    };

    componentDidMount() {
        const query = qs.parse(this.props.history.location.search);
        if (query.email) {
            //Add email to form and focus password if email was sent from landing page
            this.setState({
                inputs: { ...this.state.inputs, email: query.email }
            });
            this.passwordRef.focus();
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.dispatch({
            type: REQUEST_ACCOUNT_VERIFICATION,
            payload: {
                email: this.state.inputs.email,
                password: this.state.inputs.password
            }
        });
    };

    onCancelSelectClient = () => {
        this.props.dispatch({ type: SET_VERIFY_ACCOUNT_STATUS, payload: {} });
    };

    onConfirmClient = value => {
        const { dispatch, accountVerification } = this.props;
        const { selectedOption } = this.state;

        if (
            !isInteger(value) ||
            value < 0 ||
            value >= accountVerification.clients.length
        ) {
            return false;
        }

        const client = accountVerification.clients[value];

        dispatch({
            type: REQUEST_REGISTER_COMPANY,
            payload: {
                email: this.state.inputs.email,
                password: this.state.inputs.password,
                normalHours: parseFloat(this.state.inputs.normalHours),
                extraHours: 0,
                overtimeAdditional: (
                    parseFloat(this.state.inputs.overtimeAdditional) / 100
                ).toString(),
                vacationDays: parseFloat(this.state.inputs.vacationDays),
                companyId: client.clientId,
                name: client.clientName,
                hasWeekBasedCalculation: selectedOption === 1,
                userId: client.userId.toString()
            }
        });
        dispatch({ type: SET_VERIFY_ACCOUNT_STATUS, payload: {} });
    };

    handleChange = (type, numeric, e) => {
        let disabledConfirm = false;

        //Numeric field return value directly
        const currentVal = e.target.value;

        forEach(this.state.inputs, (val, key) => {
            //If not inputfield is filled
            if (!val) {
                //If this is not the field changed or its the current key but it is empty
                if (type !== key || !currentVal) {
                    disabledConfirm = true;
                }
            }
            //If the changed inputfield is now invalid
            if (!currentVal) disabledConfirm = true;
        });

        this.setState({
            inputs: {
                ...this.state.inputs,
                [type]: currentVal
            },
            disabledConfirm
        });
    };

    onChangeSelect = e => {
        this.setState({ selectedOption: e.target.value });
    };

    render() {
        const { selectedOption, inputs, disabledConfirm } = this.state;
        const { accountVerification, dispatch, auth, isFetching } = this.props;

        if (
            (auth.user && auth.user.id) ||
            (auth.actionNeeded && auth.actionNeeded.identities)
        ) {
            return <Redirect to="/login" />;
        }

        const clientNames = map(accountVerification.clients, client => {
            return `${client.clientName} (${client.userName})`;
        });

        return (
            <Fragment>
                <Register
                    onSubmit={this.handleSubmit}
                    onChange={(type, numeric, e) =>
                        this.handleChange(type, numeric, e)
                    }
                    onCheckboxChange={(e, val) =>
                        this.setState({ confirmedTerms: val })
                    }
                    inputs={inputs}
                    disabledConfirm={disabledConfirm || isFetching}
                    selectOptions={selectOptions}
                    selectedOption={selectedOption}
                    onChangeSelect={this.onChangeSelect}
                    isFetching={isFetching}
                    passwordRef={r => (this.passwordRef = r)}
                />
                <SimpleDialog
                    open={
                        accountVerification.status ===
                            verifyAccountStatus.INVALID_ACCOUNT.code ||
                        accountVerification.status ===
                            verifyAccountStatus.ALREADY_REGISTERED.code
                    }
                    title={"Ugyldig konto"}
                    description={accountVerification.message}
                    buttonGroup={
                        <DialogButtonGroup
                            onConfirm={() =>
                                dispatch({
                                    type: SET_VERIFY_ACCOUNT_STATUS,
                                    payload: {}
                                })
                            }
                            type="ok"
                        />
                    }
                />
                <PickerDialog
                    onCancel={this.onCancelSelectClient}
                    onConfirm={this.onConfirmClient}
                    values={clientNames}
                    title={dialog.register.chooseClient.title}
                    description={dialog.register.chooseClient.description}
                    open={
                        accountVerification.status ===
                        verifyAccountStatus.IS_VERIFIED.code
                    }
                />
            </Fragment>
        );
    }
}
const mapStateToProps = ({ auth, appState }) => {
    let accountVerification = auth.accountVerification
        ? { ...auth.accountVerification }
        : {};

    accountVerification.clients =
        accountVerification.clients !== undefined
            ? _.orderBy(accountVerification.clients, "clientName", "asc")
            : undefined;

    return {
        accountVerification,
        auth: auth,
        willUpdateInitially: auth.willUpdateInitially,
        isFetching: appState.isFetching
    };
};
export default withRouter(connect(mapStateToProps)(RegisterContainer));
