import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import SwitchInput from "common/forms/SwitchInput";
import { colors } from "utils/styles";

const styles = theme => ({});

const rowStyle = {
    borderBottom: `1px solid ${colors.C_DB10}`,
    height: "40px",
    padding: "0px 7px 0 17px",
    boxSizing: "border-box"
};

class KeyPerformanceIndicatorsSettings extends Component {
    constructor(props) {
        super(props);
    }

    onEmployeeChange = e =>
        this.props.onChange({
            employee: !this.props.currentKPISettings.employee
        });

    onAdministratorChange = e =>
        this.props.onChange({
            administrator: !this.props.currentKPISettings.administrator
        });

    onDisplayWithPercentChange = e =>
        this.props.onChange({
            displayWithPercent: !this.props.currentKPISettings
                .displayWithPercent
        });

    render() {
        const { currentKPISettings } = this.props;

        return (
            <Fragment>
                <SwitchInput
                    value={currentKPISettings.employee}
                    label={"Vis for ansatt"}
                    description={"Vis for ansatt"}
                    onChange={this.onEmployeeChange}
                    style={rowStyle}
                    darkBackground={false}
                />
                <SwitchInput
                    value={currentKPISettings.administrator}
                    label={"Vis for administrator"}
                    description={"Vis for administrator"}
                    onChange={this.onAdministratorChange}
                    style={rowStyle}
                    darkBackground={false}
                />
                <SwitchInput
                    value={currentKPISettings.displayWithPercent}
                    label={"Presenter som prosent"}
                    description={"Presenter som prosent"}
                    onChange={this.onDisplayWithPercentChange}
                    style={rowStyle}
                    darkBackground={false}
                />
            </Fragment>
        );
    }
}

export default withStyles(styles)(KeyPerformanceIndicatorsSettings);
