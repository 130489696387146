import React, { Fragment } from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function(props) {
    return (
        <Fragment>
            <SvgIcon {...props} viewBox="0 0 20 9.89">
                <path d="M20,9.89,12.26,1A3,3,0,0,0,7.74,1L0,9.89" />
            </SvgIcon>
        </Fragment>
    );
}
