import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import InputField, { InputFieldPropTypes } from "../text-fields/InputField";
import SearchIcon from "../../icons/SearchIcon";
import classNames from "classnames";

const styles = theme => ({
    root: {
        width: "150px",
        height: "35px"
    },
    input: {
        fontSize: theme.typography.F_S
    },
    icon: {
        width: "10px !important",
        height: "10px !important",
        color: theme.palette.C_DB40
    }
});

class MiniSearchField extends PureComponent {
    render() {
        const { classes, className, ...other } = this.props;
        return (
            <InputField
                className={classNames(classes.root, className)}
                inputClassName={classes.input}
                padding={"dense"}
                leftIcon={<SearchIcon className={classes.icon} />}
                {...other}
            />
        );
    }
}

MiniSearchField.propTypes = InputFieldPropTypes;

export default withStyles(styles)(MiniSearchField);
