import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

const styles = theme => ({
    root: {
        ...theme.gridItemBorder,
        borderRadius: "6px"
    }
});

class TableWrapper extends Component {
    render() {
        const { classes, children } = this.props;

        return (
            <Paper className={classes.root} elevation={0}>
                {children}
            </Paper>
        );
    }
}

export default withStyles(styles)(TableWrapper);
