import { findIndex } from "lodash";
import React, { Fragment } from "react";
import { userGroups } from "../../../actionTypes";
import Button from "../../../common/forms/buttons/Button";
import InputField from "../../../common/forms/text-fields/InputField";
import Card from "../../../components/layout/card/Card";
import CardForm from "../../../components/layout/card/CardForm";
import ButtonGroup from "../../../common/forms/buttons/HiddenButtonGroup";
import CardHeader from "../../../components/layout/card/CardHeader";
import IconPopupMenu from "../../../common/poppers/IconPopupMenu";
import * as _ from "lodash";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import { getProfileProps } from "./selectors";
import { updateUser } from "../../../actions/user";
import CardDivider from "../../../components/layout/card/CardDivider";
import SwitchInput from "../../../common/forms/SwitchInput";
import { componentDescriptions } from "../../../constants/strings";

const activeOptions = ["Gjør inaktiv"];
const inactiveOption = ["Gjør aktiv"];

export class Profile extends React.Component {
    state = {
        updateableUser: {
            userTitle: "",
            isActive: true,
            email: "",
            shouldReceiveEmailNotifications: true
        },
        hasInitialStateReset: false,
        currentSelect: null,
        newValue: null
    };
    componentDidMount() {
        if (this.props.currentUser) {
            this.resetStateSettings();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentUser !== this.props.currentUser) {
            this.resetStateSettings();
        }
    }

    resetStateSettings = () => {
        const user = this.props.currentUser;
        this.setState({
            updateableUser: {
                userTitle: user.userTitle,
                isActive: user.isActive,
                email: user.email,
                shouldReceiveEmailNotifications:
                    user.shouldReceiveEmailNotifications
            },
            hasInitialStateReset: true
        });
    };

    findUpdatedValues = () => {
        const { updateableUser } = this.state;
        const { currentUser } = this.props;

        return {
            userTitle: updateableUser.userTitle !== currentUser.userTitle,
            isActive: updateableUser.isActive !== currentUser.isActive,
            email: updateableUser.email !== currentUser.email,
            shouldReceiveEmailNotifications:
                updateableUser.shouldReceiveEmailNotifications !==
                currentUser.shouldReceiveEmailNotifications
        };
    };
    hasInputChanged = () => {
        return _.values(this.findUpdatedValues()).indexOf(true) >= 0;
    };

    filterUnchangedValues = () => {
        const updatedValues = this.findUpdatedValues();
        const { updateableUser } = this.state;
        const returnValue = {};
        _.forEach(updatedValues, (val, key) => {
            if (val === true) {
                returnValue[key] = updateableUser[key];
            }
        });
        return returnValue;
    };
    mergeUser = user => {
        this.setState({
            updateableUser: { ...this.state.updateableUser, ...user }
        });
    };

    handleInputChange = e => {
        this.mergeUser({ [e.target.name]: e.target.value });
    };
    handleSwitchChange = e => {
        this.mergeUser({
            [e.target.name]: !this.state.updateableUser[e.target.name]
        });
    };

    onSubmit = e => {
        e.preventDefault();
        const update = this.filterUnchangedValues();
        this.props.updateUser(this.props.currentUser.id, update);
    };

    onCancel = e => {
        e.preventDefault();
        this.resetStateSettings();
    };

    onSelect = index => {
        if (index === 0) {
            this.mergeUser({ isActive: !this.state.updateableUser.isActive });
        }
    };

    render() {
        const { currentUser, signedInUserGroup, isOwnPage } = this.props;

        const { updateableUser } = this.state;

        const hasDepLeaderRights =
            signedInUserGroup === userGroups.ADMIN ||
            signedInUserGroup === userGroups.DEPARTMENT_LEADER;

        const canSubmit = this.hasInputChanged();
        const desc = componentDescriptions.userDetails.settings;

        return (
            <Card>
                <CardHeader
                    title={"Profil"}
                    tag={updateableUser.isActive ? undefined : "Arkivert"}
                    icon={
                        signedInUserGroup === userGroups.ADMIN ? (
                            <IconPopupMenu
                                onChange={this.onSelect}
                                items={
                                    updateableUser.isActive
                                        ? activeOptions
                                        : inactiveOption
                                }
                            />
                        ) : null
                    }
                />
                <form onSubmit={this.onSubmit}>
                    <CardForm>
                        <InputField
                            label={"Fornavn"}
                            fullWidth
                            disabled
                            value={currentUser.firstName}
                            name={"firstName"}
                        />
                        <InputField
                            label={"Etternavn"}
                            fullWidth
                            disabled
                            value={currentUser.lastName}
                            name={"lastName"}
                        />
                        <InputField
                            label={"Tittel"}
                            fullWidth
                            disabled={!hasDepLeaderRights}
                            value={updateableUser.userTitle || ""}
                            onChange={this.handleInputChange}
                            name={"userTitle"}
                        />

                        <InputField
                            label={"E-post"}
                            fullWidth
                            value={updateableUser.email || ""}
                            onChange={this.handleInputChange}
                            name={"email"}
                        />
                        {isOwnPage ? (
                            <SwitchInput
                                value={
                                    updateableUser.shouldReceiveEmailNotifications
                                }
                                onChange={this.handleSwitchChange}
                                label={"Varsler på e-post"}
                                name={"shouldReceiveEmailNotifications"}
                                description={
                                    desc.shouldReceiveEmailNotifications
                                }
                            />
                        ) : null}
                        <ButtonGroup show={canSubmit}>
                            <Button
                                variant={"secondary"}
                                onClick={this.onCancel}
                            >
                                {"Forkast"}
                            </Button>
                            <Button
                                variant={"primary"}
                                onClick={this.onSubmit}
                                type={"submit"}
                            >
                                {"Lagre"}
                            </Button>
                        </ButtonGroup>
                    </CardForm>
                </form>
            </Card>
        );
    }
}

export default withRouter(connect(getProfileProps, { updateUser })(Profile));
