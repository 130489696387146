import React, { PureComponent } from "react";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    listItem: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: theme.spacing.listHeight,
        paddingLeft: theme.spacing.gridMargin,
        paddingRight: theme.spacing.gridMargin,
        "&:first-child": {
            paddingTop: "4px"
        }
    }
});
class Placeholder extends PureComponent {
    render() {
        return (
            <ListItem className={this.props.classes.listItem}>
                <Typography variant="body1">{this.props.children}</Typography>
            </ListItem>
        );
    }
}

export default withStyles(styles)(Placeholder);
