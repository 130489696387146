import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { isFunction, isArray } from "lodash";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";

const styles = theme => ({
    faded: {
        color: theme.palette.C_DB40,
        "&:hover": {
            color: theme.palette.C_AB
        },
        transition: "all 100ms"
    },
    fadedWithoutClick: {
        color: theme.palette.C_DB40
    },
    titleRoot: {
        display: "flex"
    },
    divider: {
        color: theme.palette.C_DB40,
        marginLeft: "5px",
        marginRight: "9px"
    },
    button: {
        margin: 0,
        padding: 0,
        cursor: "pointer"
    }
});
class BreadCrumbs extends Component {
    renderBreadCrumbs = (titleArr, onPress) => {
        const { classes } = this.props;
        if (titleArr.length !== 2) {
            throw new Error(
                "TableHeader does not support breadcrumbs of more than length 1"
            );
        }

        const componentArr = [];

        const canPressBack = isFunction(onPress);

        const fadedItemClasses = classNames({
            [classes.faded]: canPressBack,
            [classes.fadedWithoutClick]: !canPressBack
        });

        const fadedItem = (
            <Typography
                variant="display2"
                className={fadedItemClasses}
                key={titleArr[0]}
            >
                {titleArr[0]}
            </Typography>
        );
        if (canPressBack) {
            componentArr.push(
                <a
                    onClick={onPress}
                    className={classes.button}
                    key={titleArr[0]}
                >
                    {fadedItem}
                </a>
            );
        } else {
            componentArr.push(fadedItem);
        }
        componentArr.push(
            <Typography
                variant="display2"
                className={classes.divider}
                key={titleArr[0] + "_divider"}
            >
                {" / "}
            </Typography>
        );
        componentArr.push(
            <Typography variant="display2" key={titleArr[1]}>
                {titleArr[1]}
            </Typography>
        );

        return componentArr;
    };

    render() {
        const { title, onBreadCrumbsPress, classes, className } = this.props;

        let breadCrumbs = null;
        // Supports only an array of length 1, 2 or a string
        if (!isArray(title) || title.length === 1) {
            const displayTitle = isArray(title) ? title[0] : title;
            breadCrumbs = (
                <Typography variant="display2">{displayTitle}</Typography>
            );
        } else {
            breadCrumbs = this.renderBreadCrumbs(title, onBreadCrumbsPress);
        }

        return (
            <div className={classNames(classes.titleRoot, className)}>
                {breadCrumbs}
            </div>
        );
    }
}

BreadCrumbs.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    onBreadCrumbsPress: PropTypes.func
};

BreadCrumbs.defaultProps = {
    title: "",
    onBreadCrumbsPress: null
};

export default withStyles(styles)(BreadCrumbs);
