import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "../buttons/Button";
import classNames from "classnames";

const styles = theme => ({
    button: {
        height: "25px",
        minHeight: "25px",
        width: "unset",
        minWidth: "80px",
        boxSizing: "border-box",
        padding: "4px 10px"
    }
});

class MiniButton extends PureComponent {
    render() {
        const { className, classes, ...other } = this.props;
        return (
            <Button
                className={classNames(classes.button, className)}
                {...other}
            />
        );
    }
}

export default withStyles(styles)(MiniButton);
