import React from "react";
import Typography from "common/Typography";
import { withStyles } from "@material-ui/core/styles";
import Card from "components/layout/card/Card";
import classNames from "classnames";

const styles = theme => ({
    root: {
        display: "flex",
        height: "80px",
        alignItems: "center",
        maxWidth: "85%",
        overflow: "hidden"
    },
    clickable: {
        cursor: "pointer",
        "&:hover, &:focus": {
            border: `solid 1px ${theme.palette.C_DB15}`
        }
    },
    avatar: {
        marginRight: "15px"
    },
    a: {
        outline: "none"
    },

    card: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 20px",
        ...theme.gridItemBorder,
        boxShadow: `inherit`,
        outline: "none"
    },
    menu: {
        color: theme.palette.C_DB60,
        transform: "rotate(90deg)",
        position: "relative",
        left: "10px"
    }
});
const PaperCard = ({ classes, avatar, title, subtitle, onItemClick, menu }) => {
    return (
        <Card
            className={classNames(classes.card, {
                [classes.clickable]: Boolean(onItemClick)
            })}
            tabIndex={onItemClick ? "0" : undefined}
            onClick={onItemClick}
        >
            <div className={classNames(classes.root)}>
                <div className={classes.avatar}>{avatar}</div>
                <div>
                    <Typography bold singleLine>
                        {title || ""}
                    </Typography>
                    <Typography singleLine size={"smallMed"}>{`${subtitle ||
                        ""}`}</Typography>
                </div>
            </div>
            {menu
                ? React.cloneElement(menu, { className: classes.menu })
                : null}
        </Card>
    );
};
export default withStyles(styles)(PaperCard);
