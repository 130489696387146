import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "../../../common/table/Table";
import AvatarLabel from "../../../components/people/AvatarLabel";
import moment from "moment";
import * as utils from "utils";

const styles = {
    selectRoot: {
        display: "none"
    },

    input: {
        display: "none"
    }
};

const tableStyles = { minWidth: "1000px" };
const initialOrder = { orderBy: "timestamp", direction: "desc" };
class UserLog extends Component {
    constructor() {
        super();
        this.columns = [
            {
                label: "Hendelse",
                type: "string",
                id: "description",
                renderCell: this.renderDepartment,
                width: "30%"
            },
            {
                label: "Dato",
                id: "timestamp",
                width: "20%",
                renderCell: this.renderTimestamp,
                orderFunc: item => moment(item.timestamp)
            },
            {
                label: "Utført av",
                renderCell: this.renderName,
                width: "20%",
                id: "actor",
                orderFunc: item => `${item.firstName} ${item.lastName}`
            },
            {
                label: "Kommentar",
                type: "string",
                width: "30%",
                id: "comment"
            }
        ];
    }
    componentDidMount() {
        const { fetchUserLogs } = this.props;

        fetchUserLogs();
    }
    renderName = (name, item) => {
        return (
            <AvatarLabel
                firstName={item.firstName}
                lastName={item.lastName}
                id={item.actor}
            />
        );
    };
    renderTimestamp = value => utils.formatRecentDate(value, { year: true });

    render() {
        const { logItems } = this.props;
        return (
            <Table
                data={logItems}
                columns={this.columns}
                initialOrder={initialOrder}
                style={tableStyles}
            />
        );
    }
}

export default withStyles(styles)(UserLog);
