import * as _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { UPDATE_TIMEBANK_META, userGroups } from "../../../actionTypes";
import InfoBar from "./InfoBar";
import { getInfoBar } from "./selectors";

class InfoBarContainer extends Component {
    static defaultProps = {
        currentDate: 0
    };

    shouldComponentUpdate(nextProps) {
        if (
            nextProps.companyId === this.props.companyId &&
            nextProps.keyFigures === this.props.keyFigures &&
            nextProps.currentDate === this.props.currentDate &&
            nextProps.signedInUser === this.props.signedInUser &&
            nextProps.departments === this.props.departments &&
            nextProps.activeDepartment === this.props.activeDepartment
        ) {
            return false;
        }
        return true;
    }

    handleDateChange = (year, month) => {
        const { dispatch } = this.props;

        const date = new Date(year, month);
        dispatch({
            type: UPDATE_TIMEBANK_META,
            payload: { currentDate: date.getTime() }
        });
    };

    handleDepartementChange = index => {
        const { departments, dispatch, signedInUser } = this.props;
        //Don't change department if request is not admin
        if (signedInUser.userGroup !== userGroups.ADMIN) return;

        //Get timebank for the selected department
        if (index !== 0) {
            dispatch({
                type: UPDATE_TIMEBANK_META,
                payload: { currentDepartment: departments[index].key }
            });
        } else {
            //Get all departments if the selected index is last entry
            dispatch({
                type: UPDATE_TIMEBANK_META,
                payload: { currentDepartment: null }
            });
        }
    };

    render() {
        const {
            keyFigures,
            currentDate,
            departments,
            currentDepartment
        } = this.props;

        const date = new Date(currentDate);
        const year = date.getFullYear();
        const month = date.getMonth();

        return (
            <InfoBar
                keyFigures={keyFigures}
                year={year}
                month={month}
                departments={_.map(departments, dep => dep.name)}
                currentDepartment={currentDepartment}
                onChangeDate={this.handleDateChange}
                onChangeDepartement={this.handleDepartementChange}
            />
        );
    }
}

export default withRouter(connect(getInfoBar)(InfoBarContainer));
