import classNames from "classnames";
import DefaultButton from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { colors } from "../../utils/styles";
const styles = theme => ({
    delete: {
        backgroundColor: theme.palette.C_R,
        "&:hover": {
            backgroundColor: theme.palette.C_R
        },
        color: "white"
    },
    confirm: {
        backgroundColor: theme.palette.C_LB,
        "&:hover": {
            backgroundColor: theme.palette.C_LB
        },
        color: "white"
    },
    cancel: {
        backgroundColor: theme.palette.C_DB15,
        "&:hover": {
            backgroundColor: theme.palette.C_DB15
        },
        color: "black"
    },
    disabled: {
        backgroundColor: theme.palette.C_DB15,
        "&:hover": {
            backgroundColor: theme.palette.C_DB15,
            "@media (hover: none)": {
                backgroundColor: theme.palette.C_DB15
            }
        },
        color: "black",
        "&$disabled": {
            backgroundColor: theme.palette.C_DB15
        }
    }
});

const disabledStyles = { backgroundColor: colors.C_DB15 }; // Fix for not being able to override MUI style
const activeStyles = {};
const Button = ({
    value,
    className,
    children,
    type,
    classes,
    disabled,
    browserType,
    ...other
}) => {
    const combinedClasses = classNames(
        {
            [classes.delete]: type === "delete",
            [classes.confirm]: type === "confirm",
            [classes.cancel]: type === "cancel",
            [classes.disabled]: Boolean(disabled)
        },
        className
    );

    return (
        <DefaultButton
            color={"default"}
            className={combinedClasses}
            disabled={disabled}
            disableRipple
            classes={{ disabled: classes.disabled }}
            style={disabled ? disabledStyles : activeStyles}
            type={browserType}
            {...other}
        >
            {value || children}
        </DefaultButton>
    );
};

Button.propTypes = {
    value: PropTypes.node,
    type: PropTypes.oneOf(["delete", "confirm", "cancel"]),
    browserType: PropTypes.oneOf(["button", "submit", "reset"]),
    disabled: PropTypes.bool
};

Button.defaultProps = {
    type: "confirm",
    disabled: false,
    browserType: "button"
};
export default withStyles(styles)(Button);
