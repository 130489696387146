import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const styles = {
    cls1: {
        fill: "#e6e9eb"
    },
    cls2: {
        fill: "#e6e9eb"
    },
    cls3: {
        fill: "white"
    }
};
export default function(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 20 9.89">
            <path
                style={styles.cls1}
                d="M20,9.89,12.26,1A3,3,0,0,0,7.74,1L0,9.89"
            />
            <path
                style={styles.cls2}
                d="M10,0A3,3,0,0,0,8.78.26,3,3,0,0,1,10,0Z"
            />
            <path
                style={styles.cls3}
                d="M18.35,9.14,11.7,1.52A2.26,2.26,0,0,0,10,.75a2.23,2.23,0,0,0-1.69.77L1.65,9.14,1,9.89H19Z"
            />
            <path
                style={styles.cls2}
                d="M1.65,9.14,8.31,1.52A2.23,2.23,0,0,1,10,.75a2.26,2.26,0,0,1,1.7.77l6.65,7.62.65.75h1L12.26,1a3,3,0,0,0-1.64-1A3.18,3.18,0,0,0,10,0,3,3,0,0,0,8.78.26a2.94,2.94,0,0,0-1,.77L0,9.89H1l.65-.75Z"
            />
        </SvgIcon>
    );
}
