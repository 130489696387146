import React, { PureComponent } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DayPickerSingleDateController } from "react-dates";
import LeftPointIcon from "common/icons/LeftPointIcon";
import RightPointIcon from "common/icons/RightPointIcon";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const styles = theme => ({
    icon: {
        fontSize: "13px",
        position: "relative",
        top: 1
    },
    SingleDatePicker_picker: {
        marginLeft: 0,
        marginTop: 0,
        position: "relative"
    }
});
class SingleMonthDatePicker extends PureComponent {
    state = {
        focused: true
    };

    handleDateChange = date => {
        this.props.onChange(date);
        this.setState({ focused: false });
    };

    handleFocusedChange = e => {
        this.props.onFocusChange && this.props.onFocusChange(e);
    };

    render() {
        const { date, format, firstDayOfWeek, ...other } = this.props;

        const thisDate =
            format === "week" ? moment(date).startOf("week") : date;

        return (
            <div style={{ position: "relative" }}>
                <DayPickerSingleDateController
                    date={thisDate} // momentPropTypes.momentObj or null
                    onDateChange={this.handleDateChange} // PropTypes.func.isRequired
                    focused={this.state.focused} // PropTypes.bool
                    onFocusChange={this.handleFocusedChange}
                    numberOfMonths={1}
                    navPrev={<LeftPointIcon style={{ fontSize: "inherit" }} />}
                    navNext={<RightPointIcon style={{ fontSize: "inherit" }} />}
                    firstDayOfWeek={firstDayOfWeek}
                    hideKeyboardShortcutsPanel
                    {...other}
                />
            </div>
        );
    }
}

SingleMonthDatePicker.propTypes = {
    date: PropTypes.object,
    format: PropTypes.oneOf(["week", "day"])
};
SingleMonthDatePicker.defaultProps = {
    date: moment(),
    format: "day"
};
export default withStyles(styles)(SingleMonthDatePicker);
