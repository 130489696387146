import { findIndex, isFunction, map } from "lodash";
import moment from "moment";
import qs from "query-string";
import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    REQUEST_SET_USER_PAYOUT,
    UPDATE_TIMEBANK_META,
    USER_REQUEST_PAYOUT
} from "../../../actionTypes";
import SelectAndTextFieldDialog from "../../../common/dialogs/SelectAndTextFieldDialog";
import { dialog } from "../../../constants/strings";
import InfoBar from "components/infobar/InfoBar";
import { getUserInfoBar } from "../infobar/selectors";
import YearPicker from "components/pickers/YearPicker";
import BorderIconButton from "components/buttons/BorderIconButton";
import CoinIcon from "../../../common/icons/CoinIcon";

const monthNames = [
    "Januar",
    "Februar",
    "Mars",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Desember"
];

class InfoBarContainer extends PureComponent {
    static defaultProps = {
        currentDate: 0
    };

    constructor() {
        super();
        this.state = {
            months: this.getMonthArray(),
            requestPayoutOpen: false,
            payout: {
                open: false,
                amount: 0,
                issuedForDateIndex: 0,
                eventRef: null
            }
        };
    }

    getMonthArray = () => {
        const today = moment();

        const months = [];

        //Only show last 4 months
        for (let i = 0; i < 4; i++) {
            months.push({
                name: `${monthNames[today.month()]} ${today.format("YYYY")}`,
                month: today.month(),
                year: today.year()
            });

            //Dont show months before apps' startdate
            if (today.isBefore(moment([2018, 0, 15]).endOf("M"))) break;
            today.subtract(1, "M");
        }
        return months;
    };

    checkForPayout = history => {
        const { months } = this.state;
        const query = qs.parse(history.location.search);
        //Check query if user has been redirected from a notification
        if (query.var === "payout") {
            const issuedForDateIndex = findIndex(months, m => {
                const mom = moment(query.issuedForDate);
                return mom.year() === m.year && mom.month() === m.month;
            });
            this.setState({
                payout: {
                    amount: query.amount,
                    issuedForDateIndex,
                    open: true,
                    eventRef: query.ref
                }
            });
            //Reset query params to prevent dialog popup on refresh
            history.replace(history.location.pathname);
        }
    };

    componentDidMount() {
        const { history } = this.props;

        this.checkForPayout(history);

        this.unListenHistory = history.listen((location, action) => {
            this.checkForPayout(history);
        });
    }
    componentWillUnmount() {
        isFunction(this.unListenHistory) && this.unListenHistory();
    }

    componentDidUpdate(nextProps) {
        if (
            nextProps.history.location.search !==
            this.props.history.location.search
        ) {
            this.checkForPayout(nextProps.history);
        }
    }

    handleDateChange = year => {
        const { dispatch, currentMonth, currentYear } = this.props;

        const thisDate = moment([currentYear, currentMonth]);
        const nextDate = moment([year, currentMonth]);

        if (thisDate.isAfter(nextDate)) nextDate.endOf("y");
        else nextDate.startOf("y");

        dispatch({
            type: UPDATE_TIMEBANK_META,
            payload: {
                currentDate: nextDate.valueOf()
            }
        });
    };

    handleRequestPayoutClick = () => {
        this.setState({ requestPayoutOpen: true });
    };

    onRequestPayoutConfirm = ({ textValue, selectValue }, comment) => {
        const { months } = this.state;
        const { dispatch, signedInUser } = this.props;

        //Middle of month
        const date = moment([
            months[selectValue].year,
            months[selectValue].month,
            15
        ]);

        const hours = parseFloat(textValue);

        if (!isNaN(hours)) {
            dispatch({
                type: USER_REQUEST_PAYOUT,
                userId: signedInUser.id,
                payload: {
                    issuedForDate: date.toISOString(),
                    amount: hours,
                    comment
                }
            });
        }
        this.setState({ requestPayoutOpen: false });
    };

    onPayoutConfirm = ({ textValue, selectValue }, comment) => {
        const {
            dispatch,
            userId,
            signedInUser,
            currentYear,
            currentMonth
        } = this.props;
        const { months, payout } = this.state;

        const hours = parseFloat(textValue);
        const d = months[selectValue];

        if (!isNaN(hours) && d) {
            dispatch({
                type: REQUEST_SET_USER_PAYOUT,
                userId,
                date: moment([currentYear, currentMonth, 15]),
                payload: {
                    amount: hours,
                    issuedForDate: moment([d.year, d.month, 15]).toISOString(),
                    comment
                },
                notificationRef: payout.eventRef,
                signedInUserId: signedInUser.id
            });

            this.setState({
                payout: {
                    open: false,
                    amount: 0,
                    issuedForDateIndex: 0,
                    eventRef: null
                }
            });
        }
    };
    onPayoutCancel = () => {
        this.setState({
            payout: {
                open: false,
                amount: 0
            }
        });
    };

    handleSetPayoutClick = () => {
        this.setState({
            payout: {
                open: true,
                amount: 0,
                issuedForDateIndex: 0
            }
        });
    };

    render() {
        const {
            keyFigures,
            currentYear,
            hasRequestWithdrawnHoursButton,
            hasSetWithdrawnHoursButton,
            currentUser
        } = this.props;

        const { requestPayoutOpen, months, payout } = this.state;

        let payoutFunc = undefined;
        let payoutTitle = "";
        let payoutText = "";

        if (hasRequestWithdrawnHoursButton) {
            payoutFunc = this.handleRequestPayoutClick;
            payoutTitle = "Forespør utbetaling av timer";
            payoutText = "Forespør utbetaling";
        } else if (hasSetWithdrawnHoursButton) {
            payoutFunc = this.handleSetPayoutClick;
            payoutTitle = "Utbetal timer";
            payoutText = "Utbetal timer";
        }

        const name = currentUser ? currentUser.displayName : undefined;

        return (
            <Fragment>
                <InfoBar keyFigures={keyFigures}>
                    {hasRequestWithdrawnHoursButton ||
                    hasSetWithdrawnHoursButton ? (
                        <BorderIconButton
                            icon={<CoinIcon />}
                            onClick={payoutFunc}
                            title={payoutTitle}
                            label={payoutText}
                        />
                    ) : null}
                    <YearPicker
                        year={currentYear}
                        onChange={this.handleDateChange}
                    />
                </InfoBar>
                <SelectAndTextFieldDialog
                    title={dialog.payout.payoutAsk.title}
                    description={dialog.payout.payoutAsk.description}
                    actionButton={dialog.payout.payoutAsk.actionButton}
                    onConfirm={this.onRequestPayoutConfirm}
                    onCancel={() => this.setState({ requestPayoutOpen: false })}
                    initialValue={"0"}
                    open={requestPayoutOpen}
                    values={map(months, m => m.name)}
                    name={name}
                    withComment
                    numeric
                    TextFieldProps={{
                        subLabel: "timer",
                        label: "Antall timer"
                    }}
                    SelectProps={{
                        label: "For måned"
                    }}
                />
                <SelectAndTextFieldDialog
                    onCancel={this.onPayoutCancel}
                    onConfirm={this.onPayoutConfirm}
                    title={dialog.payout.payoutInstant.title}
                    description={dialog.payout.payoutInstant.description}
                    actionButton={dialog.payout.payoutInstant.actionButton}
                    initialValue={payout.amount}
                    initialSelect={payout.issuedForDateIndex}
                    open={payout.open}
                    values={map(months, m => m.name)}
                    withComment
                    numeric
                    name={name}
                    TextFieldProps={{
                        subLabel: "timer",
                        label: "Antall timer"
                    }}
                    SelectProps={{
                        label: "For måned"
                    }}
                />
            </Fragment>
        );
    }
}

export default withRouter(connect(getUserInfoBar)(InfoBarContainer));
