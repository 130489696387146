import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    root: {
        backgroundColor: "white",
        borderRadius: "6px",
        border: `solid 1px ${theme.palette.C_DB15}`,
        margin: "20px 30px 0px 30px",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        padding: "0 30px",
        height: "calc(100vh - 102px)"
    }
});

class ContentPaper extends Component {
    render() {
        return (
            <div className={this.props.classes.root}>{this.props.children}</div>
        );
    }
}
export default withStyles(styles)(ContentPaper);
