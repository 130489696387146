import { createSelector } from "reselect";
import { userGroups } from "../../../actionTypes";
import * as _ from "lodash";
import { forEach, get, keys, round } from "lodash";
import moment from "moment";
import { columnDescriptions } from "../../../constants/strings";
import * as selectors from "../selectors";
import * as utils from "../../../utils/index";
import { getCurrentUser } from "../../../selectors/user";
import * as userSelectors from "selectors/user";

const getCurrentUserId = (_, props) => props.match.params.userId;
const getSignedInUser = state => state.auth.user;
const getCurrentDate = state => state.timebank.meta.currentDate;
const getTimebank = state => state.timebank;
const getCompany = (state, props) =>
    state.companies[props.match.params.companyId];
const getHasStaleData = state => state.appState.hasStaleData;
const getOvertimeAdditional = createSelector(
    getCompany,
    company => (company ? company.overtimeAdditional : 0)
);
const getTimebankMeta = state => state.timebank.meta;
const userColumData = [
    {
        label: "Normaltid",
        id: "normalHours",
        description: columnDescriptions.normalHours
    },
    {
        label: "Kundetid",
        id: "clientHours",
        description: columnDescriptions.clientHours
    },
    {
        label: "Interntid",
        id: "internalHours",
        description: columnDescriptions.internalHours
    },
    {
        label: "Ferie",
        id: "vacationHours",
        description: columnDescriptions.vacationHours
    },
    {
        label: "Avspasert",
        id: "timeOffHours",
        description: columnDescriptions.timeOffHours
    },
    {
        label: "Sykdom",
        id: "sickLeaveHours",
        description: columnDescriptions.sickLeaveHours
    },
    {
        label: "Permisjon",
        id: "vacatedHours",
        description: columnDescriptions.vacatedHours
    },
    {
        label: "Registrert",
        id: "registeredHours",
        description: columnDescriptions.registeredHours,
        separator: true
    },
    {
        label: "Mertid",
        id: "extraHours",
        description: columnDescriptions.extraHours
    },
    {
        label: "Overtid",
        id: "overtimeHours",
        description: columnDescriptions.overtimeHours
    },
    {
        label: "Tillegg",
        id: "addition",
        description: columnDescriptions.addition
    },
    {
        label: "Minustid",
        id: "minusHours",
        description: columnDescriptions.minusHours
    },
    {
        label: "F.saldo",
        id: "balanceVacationDays",
        affix: "d",
        description: columnDescriptions.balanceVacationDays
    },
    {
        label: "T.saldo",
        id: "balanceHours",
        affix: "t",
        description: columnDescriptions.balanceHours
    },
    {
        label: "Utbetalt",
        id: "withdrawnHours",
        description: columnDescriptions.withdrawnHours
    },

    {
        label: "FG",
        type: "numeric",
        id: "invoiceFactor",
        affix: "%",
        description: columnDescriptions.invoiceFactor
    },
    {
        label: "FGN",
        type: "numeric",
        id: "invoiceFactorNormal",
        affix: "%",
        description: columnDescriptions.invoiceFactorNormal
    },
    {
        label: "FGN-F",
        type: "numeric",
        id: "invoiceFactorNormalVacation",
        affix: "%",
        description: columnDescriptions.invoiceFactorNormalVacation
    },
    {
        label: "Omsetning",
        id: "monthlyTurnover",
        description: columnDescriptions.monthlyTurnover
    }
];
const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mai",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Okt",
    "Nov",
    "Des"
];

const getUserTimebank = createSelector(
    [getCurrentUserId, getCurrentDate, getTimebank],
    (userId, currentDate, timebanks) => {
        const timebank = {};
        const thisYear = new Date(currentDate).getFullYear();

        const monthlyTimeBanks = get(timebanks, ["monthly", thisYear]);
        forEach(monthlyTimeBanks, (data, month) => {
            timebank[month] = get(data, ["users", userId]);
        });
        return {
            timebank,
            incomingBalance: get(
                timebanks,
                ["incomingBalance", moment(currentDate).year(), userId],
                { fixedHours: 0, fixedVacationDays: 0 }
            )
        };
    }
);

const getFilteredColumnData = createSelector(selectors.getUserView, view => {
    return utils.filterTableColumns(view, userColumData);
});

const getUserTimebankDaily = createSelector(
    state => state.timebank.daily,
    getCurrentDate,
    getCurrentUserId,
    (daily, currentDate, userId) => {
        return get(daily, [moment(currentDate).year(), userId]);
    }
);

export const getComputedTableData = createSelector(
    [getUserTimebank, getOvertimeAdditional, getFilteredColumnData],
    ({ timebank, incomingBalance }, overtimeAdditional, columnData) => {
        const tableData = [];

        forEach(columnData, dataType => {
            const rowObject = {};

            let total = 0;

            rowObject.dataType = dataType.label;

            if (dataType.id === "balanceVacationDays") {
                total += incomingBalance.fixedVacationDays || 0;
                rowObject.incomingBalance =
                    incomingBalance.fixedVacationDays || 0;
            } else if (dataType.id === "balanceHours") {
                total += incomingBalance.fixedHours || 0;
                rowObject.incomingBalance = incomingBalance.fixedHours || 0;
            } else {
                rowObject.incomingBalance = 0;
            }

            forEach(months, (month, monthIndex) => {
                if (timebank[monthIndex]) {
                    rowObject[monthIndex] = timebank[monthIndex][dataType.id];
                    total += timebank[monthIndex][dataType.id] || 0;
                } else {
                    rowObject[monthIndex] = 0;
                }
            });

            rowObject.id = dataType.id;

            if (
                dataType.id === "invoiceFactor" ||
                dataType.id === "invoiceFactorNormal" ||
                dataType.id === "invoiceFactorNormalVacation"
            ) {
                total = total / keys(timebank).length;
                rowObject.total = round(total, 0);
            } else if (dataType.id === "balanceVacationDays") {
                // These keys makes no sense to accumulate, therefore set as empty
                rowObject.total = "-";
            } else if (dataType.id === "balanceHours") {
                rowObject.total = "-";
            } else {
                rowObject.total = round(total, 6); //Round totals to 6 decimals
            }

            rowObject.separator = dataType.separator;
            rowObject.affix = dataType.affix;
            tableData.push(rowObject);
        });
        return tableData;
    }
);

const getUserName = createSelector(getCurrentUser, user => {
    return user ? `${user.firstName} ${user.lastName}`.trim() : "";
});
const getHasEditAuthorization = createSelector(
    getSignedInUser,
    user => user.userGroup !== userGroups.USER
);

export const getPossibleBalanceYears = createSelector(getCurrentUser, user => {
    if (!user || !user.startDate) return [];
    const date = moment(user.startDate);

    const years = [];

    while (date.year() <= moment().year()) {
        years.push(date.year());
        date.add(1, "y");
    }
    return years;
});

export const getIncomingBalance = createSelector(
    userSelectors.getCurrentUser,
    getCurrentDate,
    getTimebank,
    (currentUser, currentDate, timebank) =>
        _.get(
            timebank,
            ["incomingBalance", moment(currentDate).year(), currentUser.id],
            {}
        )
);

const renderWeekName = date =>
    `Uke ${date.isoWeek()} (${utils.formatShortDate(
        moment(date).startOf("isoWeek")
    )} - ${utils.formatShortDate(
        moment(date)
            .endOf("isoWeek")
            .format("MM")
    )})`;
const getCalculatedUserTimebankDaily2 = createSelector(
    getUserTimebankDaily,
    getCurrentDate,
    getCurrentUser,
    (timebankDaily, currentDate, currentUser) => {
        if (!timebankDaily) return [];
        let startOfYear = moment(currentDate).startOf("y");
        if (startOfYear.isBefore(currentUser.startDate))
            startOfYear = moment(currentUser.startDate);

        const startOfNextYear = moment(currentDate)
            .add(1, "y")
            .startOf("y");

        const weeks = [
            {
                moment: moment(startOfYear),
                name: renderWeekName(startOfYear),
                days: []
            }
        ];
        let currentDays = {};
        let currentWeek = startOfYear.isoWeek();

        while (startOfYear.isBefore(startOfNextYear)) {
            if (!timebankDaily[startOfYear.dayOfYear()]) {
                const lastWeek = weeks[weeks.length - 1];
                lastWeek.days = currentDays;
                break;
            }
            const dayOfYear = startOfYear.dayOfYear();
            if (currentWeek !== startOfYear.isoWeek()) {
                const lastWeek = weeks[weeks.length - 1];
                lastWeek.days = currentDays;
                currentDays = { [dayOfYear]: timebankDaily[dayOfYear] };
                currentWeek = startOfYear.isoWeek();
                weeks.push({
                    moment: moment(startOfYear),
                    name: renderWeekName(startOfYear),
                    days: []
                });
            } else {
                currentDays[dayOfYear] = timebankDaily[dayOfYear];
            }

            startOfYear.add(1, "d");
        }
        if (!startOfYear.isBefore(startOfNextYear)) {
            const lastWeek = weeks[weeks.length - 1];
            lastWeek.days = currentDays;
        }
        return _.reverse(weeks);
    }
);
export const columnData = [
    {
        label: "Datatype",
        type: "string",
        id: "dataType",
        bold: true,
        width: "12%"
    },
    {
        label: "Inn",
        type: "numeric",
        id: "incomingBalance",
        width: "6%"
    },
    {
        label: "Jan",
        type: "numeric",
        id: 0,
        width: "6%"
    },
    {
        label: "Feb",
        type: "numeric",
        id: 1,
        width: "6%"
    },
    {
        label: "Mar",
        type: "numeric",
        id: 2,
        width: "6%"
    },
    {
        label: "Apr",
        type: "numeric",
        id: 3,
        width: "6%"
    },
    {
        label: "Mai",
        type: "numeric",
        id: 4,
        width: "6%"
    },
    {
        label: "Jun",
        type: "numeric",
        id: 5,
        width: "6%"
    },
    {
        label: "Jul",
        type: "numeric",
        id: 6,
        width: "6%"
    },
    {
        label: "Aug",
        type: "numeric",
        id: 7,
        width: "6%"
    },
    {
        label: "Sep",
        type: "numeric",
        id: 8,
        width: "6%"
    },
    {
        label: "Okt",
        type: "numeric",
        id: 9,
        width: "6%"
    },
    {
        label: "Nov",
        type: "numeric",
        id: 10,
        width: "6%"
    },
    {
        label: "Des",
        type: "numeric",
        id: 11,
        width: "6%"
    },
    {
        label: "Total",
        type: "numeric",
        id: "total",
        bold: true,
        width: "10%"
    }
];

export const getUserTimebankTableProps = (state, props) => {
    return {
        hasEditAuthorization: getHasEditAuthorization(state, props),
        currentDate: getCurrentDate(state, props),
        possibleBalanceYears: getPossibleBalanceYears(state, props),
        name: getUserName(state, props),
        companyId: props.match.params.companyId,
        tableData: getComputedTableData(state, props),
        currentUser: getCurrentUser(state, props),
        hasStaleData: getHasStaleData(state, props),
        meta: getTimebankMeta(state),
        timebankDaily: getCalculatedUserTimebankDaily2(state, props)
    };
};
