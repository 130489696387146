import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

const cls1 = {
    fill: '#4b78fa',
};
const cls2 = {
    opacity: 0.4,
    fill: '#4b78fa',
};
export default function LogoWithText(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 272.17 95.3">
            <path style={cls1} d='M9.79,0H35.52A24.48,24.48,0,0,1,60,24.48v1A24.48,24.48,0,0,1,35.52,50H0a0,0,0,0,1,0,0V9.79A9.79,9.79,0,0,1,9.79,0Z'
            />
            <path style={cls2} d='M0,25H50.52A24.48,24.48,0,0,1,75,49.48v1A24.48,24.48,0,0,1,50.52,75H9.79A9.79,9.79,0,0,1,0,65.21V25a0,0,0,0,1,0,0Z'
            />
            <path style={cls1} d='M95,5.93h16.36V30.58a10.93,10.93,0,0,1,10.15-6.84c10.15,0,16,9.94,16,26.2,0,16.88-5.9,26.3-16,26.3a11.12,11.12,0,0,1-10.15-6.83V75H95Zm20.61,55.92c4,0,5.28-4.76,5.28-11.91s-1.24-11.8-5.28-11.8c-1.76,0-3.32,1.34-4.25,3.62V58.12C112.29,60.5,113.85,61.85,115.61,61.85Z'
            />
            <path style={cls1} d='M167,69.2c-2,3.63-7.56,7-12.43,7-8.49,0-11.8-4.86-11.8-11.7V25H159.1V58c0,2.59.93,3.83,3,3.83,2.28,0,4-2.38,4.87-4.66V25h16.36V75H167Z'
            />
            <path style={cls1} d='M193.58,58.33c2.79,2.69,7.25,5.18,11.08,5.18,3.42,0,5.18-1.25,5.18-3.21,0-1.76-2.39-2.49-5.6-3.53-6.11-2-15-4.86-15-17.08,0-8.28,6-15.95,18.74-15.95A27.9,27.9,0,0,1,225.27,30l-6.22,11a16.54,16.54,0,0,0-10.77-4.45c-2.27,0-4,1.14-4,2.69s2.39,2.28,5.49,3.31c6.32,2.08,15.64,5.08,15.64,17.3,0,9.63-7.66,16.46-19.16,16.46-8.9,0-15.43-3.1-18.84-6.73Z'
            />
            <path style={cls1} d='M236.24,80.9a3.5,3.5,0,0,0,3.52-2.48l.83-2.49L225.89,25h17.18L249,54.39,255,25h17.19L255.71,81.63c-3.21,11-8.29,13.67-17.5,13.67a27.38,27.38,0,0,1-6.84-.83l2.28-14.09A5.61,5.61,0,0,0,236.24,80.9Z'
            />

        </SvgIcon>

    )
};
