import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Card from "../../../components/layout/card/Card";
import CardHeader from "../../../components/layout/card/CardHeader";
import CardForm from "../../../components/layout/card/CardForm";
import { componentDescriptions } from "../../../constants/strings";
import InputField from "../../../common/forms/text-fields/InputField";
import SelectInput from "../../../common/forms/SelectInput";
import { updateCompanyRules } from "../../../actions/company";
import * as _ from "lodash";
import ButtonGroup from "../../../common/forms/buttons/HiddenButtonGroup";
import Button from "../../../common/forms/buttons/Button";
import { capitalize } from "@material-ui/core/utils/helpers";
import { fullMonthNames } from "../../../utils/strings";

const capitalizedMonthNames = fullMonthNames.map(capitalize);
class CalculationBasisSettings extends Component {
    state = {
        settings: {
            normalHours: 8,
            hasWeekBasedCalculation: true,
            overtimeAdditional: 0.5,
            vacationDays: 25,
            vacationDaysAdditionMonth: 5
        },
        hasInitialStateReset: false
    };

    componentDidMount() {
        if (this.props.company) {
            this.resetStateSettings();
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.company !== this.props.company) {
            this.resetStateSettings();
        }
    }

    handleInputChange = e => {
        this.mergeSettings({ [e.target.name]: e.target.value });
    };

    resetStateSettings = () => {
        const company = this.props.company;
        this.setState({
            settings: {
                normalHours: company.normalHours,
                hasWeekBasedCalculation: company.hasWeekBasedCalculation,
                overtimeAdditional: company.overtimeAdditional,
                vacationDays: company.vacationDays,
                vacationDaysAdditionMonth: company.vacationDaysAdditionMonth
            },
            hasInitialStateReset: true
        });
    };
    mergeSettings = settings => {
        this.setState({ settings: { ...this.state.settings, ...settings } });
    };
    onSubmit = e => {
        e.preventDefault();
        const update = this.filterUnchangedValues();

        if (update.normalHours !== undefined)
            update.normalHours = parseFloat(update.normalHours, 10);
        if (update.overtimeAdditional !== undefined)
            update.overtimeAdditional = parseFloat(
                update.overtimeAdditional,
                10
            );
        if (update.vacationDays !== undefined)
            update.vacationDays = parseFloat(update.vacationDays, 10);

        this.props.updateCompanyRules(
            this.props.company.id,
            this.props.company.ruleId,
            update
        );
    };

    onCancel = e => {
        e.preventDefault();
        this.resetStateSettings();
    };
    filterUnchangedValues = () => {
        const updatedValues = this.findUpdatedValues();
        const { settings } = this.state;
        const returnValue = {};
        _.forEach(updatedValues, (val, key) => {
            if (val === true) {
                returnValue[key] = settings[key];
            }
        });
        return returnValue;
    };
    findUpdatedValues = () => {
        const { settings } = this.state;
        const { company } = this.props;

        return {
            normalHours:
                parseFloat(settings.normalHours, 10) !== company.normalHours,
            hasWeekBasedCalculation:
                settings.hasWeekBasedCalculation !==
                company.hasWeekBasedCalculation,
            overtimeAdditional:
                parseFloat(settings.overtimeAdditional, 10) !==
                company.overtimeAdditional,
            vacationDays:
                parseFloat(settings.vacationDays, 10) !== company.vacationDays,
            vacationDaysAdditionMonth:
                settings.vacationDaysAdditionMonth !==
                company.vacationDaysAdditionMonth
        };
    };
    hasInputChanged = () => {
        return _.values(this.findUpdatedValues()).indexOf(true) >= 0;
    };

    handleOvertimeChange = index => {
        this.mergeSettings({
            hasWeekBasedCalculation: index !== 0
        });
    };
    handleOvertimeAdditionalChange = e => {
        this.mergeSettings({
            overtimeAdditional:
                e.target.value === ""
                    ? ""
                    : _.round(parseFloat(e.target.value) / 100, 2)
        });
    };
    handleVacationDaysAdditionMonthChange = index => {
        this.mergeSettings({ vacationDaysAdditionMonth: index });
    };
    hasInvalidValues = () => {
        const { settings } = this.state;

        const values = _.values(settings);
        return values.indexOf("") >= 0 || values.indexOf(undefined) >= 0;
    };
    render() {
        const { settings } = this.state;
        const canSubmit =
            this.hasInputChanged() && this.state.hasInitialStateReset;

        return (
            <Card>
                <CardHeader title={"Grunnlag"} />
                <CardForm>
                    <InputField
                        value={settings.normalHours}
                        description={componentDescriptions.logic.normalHours}
                        fullWidth
                        label={"Normaltid"}
                        subLabel={"timer"}
                        name={"normalHours"}
                        onChange={this.handleInputChange}
                        NumberFormatProps={{
                            isAllowed: ({ floatValue }) =>
                                !floatValue ||
                                (floatValue >= 0 && floatValue <= 24)
                        }}
                        numeric
                    />
                    <SelectInput
                        description={componentDescriptions.logic.overtime}
                        label={"Overtid"}
                        options={["Månedsbasert", "Ukesbasert"]}
                        value={settings.hasWeekBasedCalculation ? 1 : 0}
                        fullWidth
                        onChange={this.handleOvertimeChange}
                    />
                    <InputField
                        description={
                            componentDescriptions.logic.overtimeAdditional
                        }
                        name={"overtimeAdditional"}
                        value={
                            settings.overtimeAdditional === ""
                                ? ""
                                : _.round(settings.overtimeAdditional * 100, 6)
                        }
                        fullWidth
                        label={"Overtidstillegg"}
                        subLabel={"prosent"}
                        NumberFormatProps={{
                            isAllowed: ({ floatValue }) =>
                                !floatValue ||
                                (floatValue >= 0 && floatValue <= 100)
                        }}
                        numeric
                        onChange={this.handleOvertimeAdditionalChange}
                    />
                    <InputField
                        description={componentDescriptions.logic.vacationDays}
                        value={settings.vacationDays}
                        fullWidth
                        label={"Feriedager"}
                        subLabel={"dager"}
                        NumberFormatProps={{
                            isAllowed: ({ floatValue }) =>
                                !floatValue ||
                                (floatValue >= 0 && floatValue <= 365)
                        }}
                        numeric
                        onChange={this.handleInputChange}
                        name={"vacationDays"}
                    />
                    <SelectInput
                        label={"Måned for ferietrekk"}
                        description={
                            componentDescriptions.logic
                                .vacationDaysAdditionMonth
                        }
                        options={capitalizedMonthNames}
                        value={settings.vacationDaysAdditionMonth}
                        onChange={this.handleVacationDaysAdditionMonthChange}
                    />
                    <ButtonGroup show={canSubmit}>
                        <Button variant={"secondary"} onClick={this.onCancel}>
                            {"Forkast"}
                        </Button>
                        <Button
                            variant={"primary"}
                            type={"submit"}
                            onClick={this.onSubmit}
                            disabled={this.hasInvalidValues()}
                        >
                            {"Lagre"}
                        </Button>
                    </ButtonGroup>
                </CardForm>
            </Card>
        );
    }
}

const mapDispatchToProps = ({ companies }, { match }) => ({
    company: companies[match.params.companyId]
});

export default withRouter(
    connect(mapDispatchToProps, { updateCompanyRules })(
        CalculationBasisSettings
    )
);
