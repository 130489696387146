import React from "react";
import { connect } from "react-redux";
import * as companySelectors from "../../../selectors/company";
import * as userSelectors from "../../../selectors/user";
import { withRouter } from "react-router-dom";
import { updateUser } from "../../../actions/user";
import { Profile } from "containers/users/userDetails/Profile";

const mapStateToProps = (state, props) => {
    const signedInUser = userSelectors.getFullSignedInUser(state, props);
    return {
        company: companySelectors.getCurrentCompany(state, props),
        currentUser: signedInUser,
        firstName: signedInUser.firstName,
        lastName: signedInUser.lastName,
        signedInUserGroup: signedInUser.userGroup,
        isOwnPage: true
    };
};

export default withRouter(connect(mapStateToProps, { updateUser })(Profile));
