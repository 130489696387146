import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { userGroups } from "../../actionTypes";
import UserGrid from "./UserGrid";
import { fetchUsers, setShallowUserFilters } from "./actions";
import { getUserGridProps } from "./selectors";

const mapDispatchToProps = (dispatch, ownProps) => {
    const companyId = ownProps.match.params.companyId;

    return {
        fetchInitialUsers: (signedInUser, filters, displayType) => {
            if (signedInUser.userGroup === userGroups.DEPARTMENT_LEADER) {
                filters.departmentId = signedInUser.departmentId;
            } else if (signedInUser.userGroup === userGroups.ADMIN) {
                filters.departmentId = null;
            } else {
                //Invalid usergroup to see this page
                return;
            }

            dispatch(fetchUsers(filters, companyId, displayType));
            dispatch(setShallowUserFilters(filters));
        },

        onUserClick: userId => {
            ownProps.history.push(`/users/${companyId}/user/${userId}`);
        }
    };
};

export default withRouter(
    connect(getUserGridProps, mapDispatchToProps)(UserGrid)
);
