import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function LockIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 10 12.22">
            <path d="M9.76,5.8a.82.82,0,0,0-.59-.24H8.89V3.89A3.7,3.7,0,0,0,7.74,1.15,3.72,3.72,0,0,0,5,0,3.72,3.72,0,0,0,2.26,1.15,3.7,3.7,0,0,0,1.11,3.89V5.56H.83a.82.82,0,0,0-.59.24A.81.81,0,0,0,0,6.39v5A.81.81,0,0,0,.24,12a.82.82,0,0,0,.59.24H9.17A.82.82,0,0,0,9.76,12a.81.81,0,0,0,.24-.59v-5A.81.81,0,0,0,9.76,5.8ZM7.22,5.56H2.78V3.89a2.11,2.11,0,0,1,.65-1.57A2.11,2.11,0,0,1,5,1.67a2.11,2.11,0,0,1,1.57.65,2.11,2.11,0,0,1,.65,1.57Z" />
        </SvgIcon>
    );
}
