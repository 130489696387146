import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "common/Typography";
import * as _ from "lodash";

const styles = theme => ({
    root: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: "15%"
    },
    textContainer: {
        maxWidth: "80%",
        padding: "30px 60px",
        borderRadius: "6px",
        backgroundColor: theme.palette.C_DB5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        border: `solid 1px ${theme.palette.C_DB10}`
    }
});
class TablePlaceholder extends PureComponent {
    render() {
        const { description, classes } = this.props;

        const descriptionList = description.split("\n");

        const descriptionViews = _.map(descriptionList, text => (
            <Typography key={text}>{text}</Typography>
        ));

        return (
            <div className={classes.root}>
                <div className={classes.textContainer}>{descriptionViews} </div>
            </div>
        );
    }
}

TablePlaceholder.defaultProps = {
    description: ""
};

export default withStyles(styles)(TablePlaceholder);
