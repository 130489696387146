import * as modalTypes from "./modalTypes";
import * as types from "../../actionTypes";

export const openSignInModal = modalProps => ({
    type: types.OPEN_MODAL,
    payload: {
        modalType: modalTypes.SIGN_IN_MODAL,
        modalProps
    }
});
export const createDepartment = modalProps => ({
    type: types.OPEN_MODAL,
    payload: {
        modalType: modalTypes.CREATE_DEPARTMENT_MODAL,
        modalProps
    }
});

export const createKeyPerformanceIndicator = modalProps => ({
    type: types.OPEN_MODAL,
    payload: {
        modalType: modalTypes.CREATE_KEY_PERFORMANCE_INDICATOR_MODAL,
        modalProps
    }
});
export const createFullTimeEquivalent = userId => ({
    type: types.OPEN_MODAL,
    payload: {
        modalType: modalTypes.CREATE_FULL_TIME_EQUIVALENT,
        modalProps: {
            userId
        }
    }
});
export const updateFullTimeEquivalent = (userId, fullTimeEquivalentId) => ({
    type: types.OPEN_MODAL,
    payload: {
        modalType: modalTypes.UPDATE_FULL_TIME_EQUIVALENT,
        modalProps: {
            userId,
            fullTimeEquivalentId
        }
    }
});

export const createCalculationRule = (companyId, departmentId) => ({
    type: types.OPEN_MODAL,
    payload: {
        modalType: modalTypes.CREATE_CALCULATION_RULE,
        modalProps: {
            companyId,
            departmentId
        }
    }
});

export const createNotificationTargetGroup = (companyId, departmentId) => ({
    type: types.OPEN_MODAL,
    payload: {
        modalType: modalTypes.CREATE_NOTIFICATION_TARGET_GROUP,
        modalProps: {
            companyId,
            departmentId
        }
    }
});

export const updateCalculationRule = (
    companyId,
    calculationRuleId,
    departmentId
) => ({
    type: types.OPEN_MODAL,
    payload: {
        modalType: modalTypes.UPDATE_CALCULATION_RULE,
        modalProps: {
            companyId,
            calculationRuleId,
            departmentId
        }
    }
});

export const showConfirmModal = confirmationMessage => ({
    type: types.OPEN_MODAL,
    payload: {
        modalType: modalTypes.CONFIRM_DIALOG,
        modalProps: {
            confirmationMessage
        }
    }
});

export const confirmModal = () => ({
    type: types.CONFIRM_MODAL
});
export const deleteModal = () => ({
    type: types.DELETE_MODAL
});
export const closeModal = () => ({
    type: types.CLOSE_MODAL
});
