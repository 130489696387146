import * as types from "../actionTypes";
import * as SyncApi from "../api/sync";
import { fork, call, all, put, take, select, race } from "redux-saga/effects";
import { delay } from "redux-saga";
import { takeFirstAsync } from "./helpers";
import { openSignInModal } from "../containers/modals/actions";
//WORKERS

export function* pollSynchronizationStatus(companyId) {
    while (true) {
        yield delay(10000);
        const result = yield call(SyncApi.getSyncStatus, companyId);
        if (!result.inProgress) {
            return result;
        }
    }
}

export function* getSynchronizationStatus(action) {
    const { companyId } = action;
    const result = yield call(SyncApi.getSyncStatus, companyId);
    yield put({
        type: types.SET_SYNCHRONIZATION_DATA,
        payload: result
    });
    if (result.inProgress) {
        yield put({ type: types.WAIT_FOR_SYNC_DONE, companyId });
    }
}

export function* waitForSyncDone({ companyId }) {
    const { synchronizationDone, timeout } = yield race({
        synchronizationDone: call(pollSynchronizationStatus, companyId),
        timeout: delay(1000 * 60 * 5) // 5 min
    });

    if (timeout) {
        console.error("Waiting for sync timed out.");
        yield put({
            type: types.SYNCHRONIZATION_FAILED
        });
    }

    yield put({
        type: types.SET_SYNCHRONIZATION_DATA,
        payload: synchronizationDone
    });
    yield put({
        type: types.SET_APP_STATE,
        payload: {
            hasStaleData: true
        }
    });
}

export function* waitForProviderToken() {
    const result = yield race({
        providerTokenAction: take(types.SET_PROVIDER_TOKEN),
        timeout: delay(1000 * 60) // 1 min
    });
    if (result.providerTokenAction) {
        return true;
    }
    return false;
}
export function* requestSynchronization(action) {
    const { companyId, userId, isInitialSync } = action;

    const providerToken = yield select(store => store.auth.providerToken);

    const synchronizationStatus = yield call(
        SyncApi.requestSynchronization,
        companyId,
        userId,
        providerToken
    );

    yield put({
        type: types.SET_SYNCHRONIZATION_DATA,
        payload: synchronizationStatus
    });
    if (synchronizationStatus.inProgress) {
        yield put({
            type: types.WAIT_FOR_SYNC_DONE,
            companyId,
            isInitialSync: synchronizationStatus.hasInitialSync === false
        });
    } else if (isInitialSync) {
        yield put({ type: types.REQUEST_AUTH_UPDATE });
    }
}

/**
 * @return {IterableIterator<*>}
 */
function* updateProviderToken() {
    yield put(openSignInModal());

    const { confirm, close } = yield race({
        confirm: take(types.REQUEST_PROVIDER_TOKEN),
        close: take(types.CLOSE_MODAL)
    });
    if (close) return false;

    const { email, password } = confirm.payload;
    const { providerToken } = yield call(
        SyncApi.refreshProviderToken,
        email,
        password
    );

    yield put({ type: types.SET_PROVIDER_TOKEN, payload: providerToken });
}

export function* requestManualCompanySynchronizations(action) {
    const { companyId, syncType } = action;
    const providerToken = yield select(store => store.auth.providerToken);

    let synchronizationStatus = {};
    try {
        synchronizationStatus = yield call(
            SyncApi.requestManualCompanySynchronization,
            companyId,
            syncType,
            providerToken
        );
    } catch (e) {
        if (e.httpStatus === 403) {
            yield fork(updateProviderToken);
            const res = yield call(waitForProviderToken);
            if (!res) return;
            return yield call(requestManualCompanySynchronizations, action);
        }
        throw e;
    }

    yield put({
        type: types.SET_SYNCHRONIZATION_DATA,
        payload: synchronizationStatus
    });
    if (synchronizationStatus.inProgress) {
        yield put({ type: types.WAIT_FOR_SYNC_DONE, companyId });
    }
}
export function* requestManualUserSynchronizations(action) {
    const { userId, companyId } = action;

    const providerToken = yield select(store => store.auth.providerToken);

    let synchronizationStatus = {};
    try {
        synchronizationStatus = yield call(
            SyncApi.requestManualUserSynchronization,
            userId,
            providerToken
        );
    } catch (e) {
        if (e.httpStatus === 403) {
            yield fork(updateProviderToken);
            const res = yield call(waitForProviderToken);
            if (!res) return;
            return yield call(requestManualUserSynchronizations, action);
        }
        throw e;
    }

    yield put({
        type: types.SET_SYNCHRONIZATION_DATA,
        payload: synchronizationStatus
    });
    if (synchronizationStatus.inProgress) {
        yield put({ type: types.WAIT_FOR_SYNC_DONE, companyId });
    }
}
//ROOT
export default function* rootSaga() {
    yield all([
        takeFirstAsync(types.REQUEST_SYNCHRONIZE_DATA, requestSynchronization),
        takeFirstAsync(
            types.REQUEST_SYNCHRONIZE_STATUS,
            getSynchronizationStatus
        ),
        takeFirstAsync(types.WAIT_FOR_SYNC_DONE, waitForSyncDone, true, false),
        true,
        false,
        takeFirstAsync(
            types.REQUEST_MANUAL_USER_SYNCHRONIZATION,
            requestManualUserSynchronizations,
            true,
            false
        ),
        takeFirstAsync(
            types.REQUEST_MANUAL_COMPANY_SYNCHRONIZATION,
            requestManualCompanySynchronizations,
            true,
            false
        )
    ]);
}
