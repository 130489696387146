import React, { Fragment, PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "./MenuItem";

const styles = theme => ({
    root: {
        justifyContent: "center"
    },
    placeholder: {
        height: "20px",
        borderRadius: "1000px",
        width: "100%",
        margin: "0 6px",
        boxSizing: "border-box",
        backgroundColor: theme.palette.C_DB5,
        animation: "load 1500ms linear 0ms infinite"
    },
    "@keyframes load": {
        "0%": {
            opacity: "1"
        },

        "50%": {
            opacity: ".5"
        },
        "100%": {
            opacity: "1"
        }
    }
});
class MenuItemPlaceholder extends PureComponent {
    render() {
        const { count, classes } = this.props;

        const menuItems = Array(count)
            .fill()
            .map((_, i) => (
                <MenuItem key={i} disabled className={classes.root}>
                    <div className={classes.placeholder} />
                </MenuItem>
            ));
        return <Fragment>{menuItems}</Fragment>;
    }
}

export default withStyles(styles)(MenuItemPlaceholder);
