import auth from "./auth";
import companies from "./companies";
import timebank from "./timebank";
import users from "./users";
import common from "./common";
import notifications from "./notifications";
import analytics from "./analytics";
import synchronize from "./synchronize";
import { fork, all } from "redux-saga/effects";

export default function* rootSage() {
    const sagas = [
        fork(auth),
        fork(companies),
        fork(timebank),
        fork(users),
        fork(common),
        fork(notifications),
        fork(synchronize)
    ];
    if (process.env.NODE_ENV === "production") {
        sagas.push(fork(analytics));
    }
    yield all(sagas);
}
