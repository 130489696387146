import React from "react";
import CompanyCalculationSettings from "./CompanyCalculationSettings";
import DepartmentCalculationSettings from "./DepartmentCalculationSettings";
import PresentationSettingsContainer from "./PresentationSettingContainer";
import CalculationSettings from "../CalculationSettings";
import CompanyHeader from "../header/CompanyHeaderContainer";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import DefaultErrorBoundry from "../../../common/errors/DefaultErrorBoundry";
import Authenticated from "../../../common/auth/Authenticated";
import { userGroups } from "../../../actionTypes";

const styles = {
    root: {
        margin: 24
    }
};
const Department = ({ classes }) => {
    return (
        <Authenticated
            userGroups={[userGroups.ADMIN, userGroups.DEPARTMENT_LEADER]}
            fullPage
        >
            <div className={classes.root}>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <CompanyHeader />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <DefaultErrorBoundry>
                            <PresentationSettingsContainer />
                        </DefaultErrorBoundry>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <DefaultErrorBoundry>
                            <CalculationSettings editable={false} />
                        </DefaultErrorBoundry>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DefaultErrorBoundry>
                            <CompanyCalculationSettings />
                        </DefaultErrorBoundry>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DefaultErrorBoundry>
                            <DepartmentCalculationSettings />
                        </DefaultErrorBoundry>
                    </Grid>
                </Grid>
            </div>
        </Authenticated>
    );
};

export default withStyles(styles)(Department);
