import React, { Fragment } from "react";
import Typography from "common/Typography";

export default () => (
    <Fragment>
        <Typography component={"h2"}>1. Introduction</Typography>
        <Typography>
            This is our acceptable use policy. If you use our website, it means
            that the policy applies to you and that you agree with it as part of
            our Website Terms and Conditions of Use.
        </Typography>
        <Typography>
            We may change these terms, so we expect you to check this page from
            time to time as the changes will be binding on you. There may also
            be changes elsewhere on our site.
        </Typography>
        <Typography component={"h2"}>2. Who We Are</Typography>
        <Typography>
            Busy is operated by DOTS Technologies AS, a Norwegian Limited
            company registered in Norway under company number 914 761 719.
        </Typography>
        <Typography component={"h4"}>
            Some important details about us:
        </Typography>
        <Typography>Our office is at: Tordenskiolds gate 2, 0160</Typography>
        <Typography>Our VAT number is: 914 761 719 MVA</Typography>
        <Typography component={"h2"}>3. What you must not do</Typography>
        <Typography component={"h4"}>
            You must not use the site to do any of the following:
        </Typography>
        <ul>
            <Typography component={"li"}>
                Break any laws or regulations
            </Typography>
            <Typography component={"li"}>
                Do anything fraudulent, or which has a fraudulent effect Harm or
                attempt to harm minors
            </Typography>
            <Typography component={"li"}>
                Do anything with material that does not meet our content
                standards (these are listed below)
            </Typography>
            <Typography component={"li"}>
                Do anything with unsolicited advertising material (known as
                spam)
            </Typography>
            <Typography component={"li"}>
                Transmit any data or material that is harmful to other programs,
                software, or hardware (for example, viruses, Trojan horses,
                worms, etc.)
            </Typography>
            <Typography component={"li"}>
                Copy in any way or re-sell any part of our site (unless we allow
                it under our website terms) Interfere with or damage any part of
                our site, equipment, network, software or storage arrangements.
            </Typography>
        </ul>
        <Typography component={"h2"}>4. Content Standards</Typography>
        <Typography>
            Here are our content standards. They apply to all material that you
            contribute to our site and to all interactive services.
        </Typography>
        <Typography>
            You must follow these standards carefully, but please also follow
            the spirit of them.
        </Typography>
        <Typography component={"h4"}>Your contributions must be:</Typography>
        <ul>
            <Typography component={"li"}>
                Accurate (if they are factual)
            </Typography>
            <Typography component={"li"}>
                Genuine (if they state opinions)
            </Typography>
            <Typography component={"li"}>Within the law.</Typography>
        </ul>
        <Typography component={"h4"}>
            Your contributions must not be:
        </Typography>
        <ul>
            <Typography component={"li"}>
                Defamatory, obscene or offensive
            </Typography>
            <Typography component={"li"}>
                Likely to deceive, harass, annoy, threaten, or invade someone
                else's privacy.
            </Typography>
        </ul>
        <Typography component={"h4"}>And they must not:</Typography>
        <ul>
            <Typography component={"li"}>
                Promote material that is sexually explicit
            </Typography>
            <Typography component={"li"}>
                Promote violence, or discrimination based on race, sex,
                religion, nationality, age, disability, or sexual orientation
            </Typography>
            <Typography component={"li"}>
                Infringe anyone else's intellectual property
            </Typography>
            <Typography component={"li"}>
                Be used to impersonate anyone, or misrepresent anyone's identity
            </Typography>
            <Typography component={"li"}>
                Encourage or assist anything that breaks the law.
            </Typography>
        </ul>
        <Typography component={"h2"}>5. Interactive Services</Typography>
        <Typography component={"h4"}>
            Our standards for interactive services, such as chat rooms and
            bulletin boards, are as follows:
        </Typography>
        <ul>
            <Typography component={"li"}>
                We will tell you clearly about the service
            </Typography>
            <Typography component={"li"}>
                We will tell you what form of moderation we use for the site
            </Typography>
            <Typography component={"li"}>
                We will try to assess risks on the site (especially for
                children) and will moderate if we think it is appropriate.
            </Typography>
            <Typography component={"li"}>
                Please note, however, that we are not required to moderate our
                interactive service. We will not be responsible for any loss to
                anyone who does not use our site according to our standards
                (whether or not we have moderated the service).
            </Typography>
        </ul>
        <Typography component={"h2"}>
            6. Important Notice for Parents
        </Typography>
        <Typography>
            Use of our interactive service by a child is subject to parental
            consent.
        </Typography>
        <Typography>
            If you permit your child to use the service, we advise you to
            explain the risks. Moderation is not always effective.
        </Typography>
        <Typography>
            Please contact us if you have a concern about moderation.
        </Typography>
        <Typography component={"h2"}>7. Disclosure to Courts</Typography>
        <Typography>
            If you have to disclose Confidential Information by order of a court
            or other public body you may do so.
        </Typography>
        <Typography component={"h2"}>8. Suspension and Termination</Typography>
        <Typography>
            If we think you have breached this policy, we will take whatever
            steps we think are necessary.
        </Typography>
        <Typography component={"h4"}>These might include:</Typography>
        <ul>
            <Typography component={"li"}>
                Stopping your use of the site temporarily or permanently
            </Typography>
            <Typography component={"li"}>
                Removing material you have put on the site
            </Typography>
            <Typography component={"li"}>Sending you a warning</Typography>
            <Typography component={"li"}>Taking legal action</Typography>
            <Typography component={"li"}>
                Telling the right authorities
            </Typography>
        </ul>
        <Typography>
            We exclude legal responsibility and cost for actions we take to deal
            with your breach of our policy.
        </Typography>
    </Fragment>
);
