import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { unregister } from "./registerServiceWorker";
import "whatwg-fetch";
import "./assets/css/index.css";
import "./assets/css/datepicker.css";
import "./polyfill";
import ReactGA from "react-ga";
import moment from "moment-timezone";

moment.tz.setDefault("Europe/Oslo");
moment.locale("no", {
    week: {
        dow: 1
    }
});

/**
 * This file works as a config file. It handles setting correct config from NODE_ENV,
 * and renders the React app to DOM.
 */

if (
    process.env.NODE_ENV !== "development" &&
    window.location.hostname !== "localhost"
) {
    if (window.location.protocol !== "https:") {
        window.location.href =
            "https:" +
            window.location.href.substring(window.location.protocol.length);
    }
} /* else {
    const { whyDidYouUpdate } = require("why-did-you-update");
    whyDidYouUpdate(React);
}*/

if (process.env.NODE_ENV === true) {
    ReactGA.initialize("UA-113942938-2", { debug: true });
    ReactGA.set({ anonymizeIp: true });
    ReactGA.pageview(window.location.pathname + window.location.search);
}

ReactDOM.render(<App />, document.getElementById("root"));
unregister();
