import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
const styles = theme => ({
    root: {
        paddingLeft: "10px",
        paddingRight: "10px",
        display: "flex",
        flexDirection: "column",
        paddingTop: "60px"
    },
    name: {
        marginBottom: "10px",
        color: theme.palette.C_LB
    },
    title: {
        marginBottom: "25px"
    },
    description: {
        marginBottom: "35px"
    },
    error: {
        color: theme.palette.C_R
    }
});

class DialogTextContainer extends PureComponent {
    render() {
        const { name, title, description, classes, errorMessage } = this.props;

        const nameClassNames = classNames(classes.name, {
            [classes.error]: errorMessage
        });
        return (
            <div className={classes.root}>
                <Typography className={nameClassNames} variant="subheading">
                    {errorMessage || name || ""}
                </Typography>
                <Typography className={classes.title} variant="display2">
                    {title}
                </Typography>
                <Typography className={classes.description} variant="body1">
                    {description}
                </Typography>
            </div>
        );
    }
}

export default withStyles(styles)(DialogTextContainer);
