import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "./Avatar";
import * as utils from "../../utils/index";
import * as _ from "lodash";
import classNames from "classnames";
import MoreAvatar from "./MoreAvatar";
import { colors } from "../../utils/styles";

const styles = theme => ({
    root: {
        display: "flex",
        width: "100%"
    },
    avatar: {
        marginRight: "2px"
    },
    plussAvatar: {},
    avatarText: {
        fontWeight: "bold",
        color: theme.palette.C_DB120,
        left: "-1px"
    }
});

class AvatarList extends PureComponent {
    state = {
        rootWidth: null
    };

    componentDidMount() {
        this.setRootWidth();
        window.addEventListener("resize", this.setRootWidth);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.setRootWidth);
    }

    setRootWidth = () => {
        const root = ReactDOM.findDOMNode(this.root);
        this.setState({ rootWidth: root.clientWidth });
    };

    calculateAvatarLimit = () => {
        const { rootWidth, onClick } = this.state;
        const avatarCount = Math.floor(rootWidth / this.props.avatarWidth);

        if (typeof onClick === "function") {
            // Will have to show the more avatar always
            return avatarCount - 1;
        }
        return avatarCount;
    };

    renderAvatars = (people, limit) => {
        const { classes, onClick, avatarClassName } = this.props;

        // Create a subarray with the people who fits in the container
        const visiblePeople =
            limit < people.length ? people.slice(0, limit - 1) : people; // Needs room for extra avatar

        const avatars = _.map(visiblePeople, person => {
            return (
                <Avatar
                    firstName={person.firstName}
                    lastName={person.lastName}
                    color={utils.getColorFromId(person.id)}
                    extraSmall
                    className={classNames(classes.avatar, avatarClassName)}
                    photoURL={person.photoURL}
                    key={person.id}
                />
            );
        });

        // If there are more people than the container can fit. Render the rest as a single avatar
        const invisiblePeopleCount = people.length - visiblePeople.length;

        if (invisiblePeopleCount > 0) {
            const invisiblePeople = people.slice(
                visiblePeople.length,
                people.length
            );

            avatars.push(
                <Avatar
                    firstName={"+"}
                    lastName={`${invisiblePeople.length}`}
                    color={colors.C_DB20}
                    extraSmall
                    customTooltip={utils.generateNames(invisiblePeople)}
                    className={classNames(classes.avatar, avatarClassName)}
                    textClassName={classes.avatarText}
                    key={"invisible"}
                />
            );
        }
        if (typeof onClick === "function" && !this.props.disabled) {
            avatars.push(
                <MoreAvatar
                    onClick={onClick}
                    key={"more"}
                    buttonRef={this.props.buttonRef}
                    showPluss={avatars.length === 0}
                    data-test-id={"avatar-launch-button"}
                />
            );
        }
        return avatars;
    };

    render() {
        const { people, classes, className } = this.props;

        const limit = this.calculateAvatarLimit();

        return (
            <div
                ref={r => (this.root = r)}
                className={classNames(classes.root, className)}
                data-test-id={"avatar-list-root"}
            >
                {this.renderAvatars(people, limit)}
            </div>
        );
    }
}

AvatarList.propTypes = {
    people: PropTypes.arrayOf(
        PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired
        })
    ),
    /**
     * If function is defined. Will render a more button when the list stops
     */
    onClick: PropTypes.func,
    /**
     * A custom classname to be applied to the root element
     */
    className: PropTypes.string,
    /**
     * A custom classname to be applied to the avatars
     */
    avatarClassName: PropTypes.string,

    avatarWidth: PropTypes.number,
    disabled: PropTypes.bool
};

AvatarList.defaultProps = {
    avatarWidth: 50,
    disabled: false
};

export default withStyles(styles)(AvatarList);
