import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import classNames from "classnames";
import InfoTag from "common/InfoTag";

const styles = theme => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "80px"
    },
    rootBorder: {
        borderBottom: `solid 1px ${theme.palette.C_DB15}`
    },
    filters: {
        display: "flex",
        alignItems: "center"
    },
    filter: {
        marginLeft: "20px"
    },

    filterPusher: {
        position: "relative",
        left: "24px"
    },
    titleContainer: {
        display: "flex",
        alignItems: "center"
    },
    menu: {
        marginRight: "10px",
        marginLeft: "10px"
    }
});
class PaperHeader extends PureComponent {
    render() {
        const {
            classes,
            title,
            menu,
            children,
            withBorder,
            description
        } = this.props;

        const filtersClassNames = classNames(classes.filters, {
            [classes.filterPusher]: Boolean(menu)
        });
        return (
            <div
                className={classNames(classes.root, {
                    [classes.rootBorder]: withBorder
                })}
            >
                <div className={classes.titleContainer}>
                    <div>{title}</div>
                </div>
                <div className={filtersClassNames}>
                    {description ? (
                        <div>
                            <InfoTag description={description} />
                        </div>
                    ) : null}
                    {React.Children.map(
                        children,
                        child =>
                            child
                                ? React.cloneElement(child, {
                                      className: classNames(
                                          child.className,
                                          classes.filter
                                      )
                                  })
                                : null
                    )}
                    {menu
                        ? React.cloneElement(menu, {
                              className: classNames(
                                  classes.menu,
                                  menu.props.className
                              )
                          })
                        : null}
                </div>
            </div>
        );
    }
}

export const propTypes = {
    /**
     * A title component. Can either be text or a node.
     */
    title: PropTypes.node.isRequired,

    /**
     * A menu component. Will be placed right in the container
     */
    menu: PropTypes.node,
    /**
     * A list of selectors to include in the paper
     */
    children: PropTypes.node,

    /**
     * Whether there should be a border on the bottom of header
     */
    withBorder: PropTypes.bool,
    description: PropTypes.string
};

PaperHeader.propTypes = propTypes;
PaperHeader.defaultProps = {
    withBorder: false
};
export default withStyles(styles)(PaperHeader);
