import React from "react";
import { connect } from "react-redux";
import * as userSelectors from "../../../selectors/user";
import { withRouter } from "react-router-dom";
import * as companySelectors from "selectors/company";
import { updateUser } from "../../../actions/user";
import { Settings } from "../../users/userDetails/Settings";

export const mapStateToProps = (state, props) => {
    return {
        companyDepartments: companySelectors.getCompanyDepartments(
            state,
            props
        ),
        currentUser: userSelectors.getFullSignedInUser(state, props),
        userGroupList: userSelectors.getUserGroupList(state, props),
        signedInUser: userSelectors.getFullSignedInUser(state, props),
    };
};
export default withRouter(connect(mapStateToProps, { updateUser })(Settings));
