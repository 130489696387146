import React from "react";
import ContentPaper from "../../../components/layout/paper/ContentPaper";
import SetupHeader from "./SetupHeader";
import Grid from "@material-ui/core/Grid/Grid";
import DefaultErrorBoundry from "../../../common/errors/DefaultErrorBoundry";
import CategoryDisplaySettings from "./displaySettings/CategoryDisplaySettings";
import CalculationDisplaySettings from "./displaySettings/CalculationDisplaySettings";
import BalanceDisplaySettings from "./displaySettings/BalanceDisplaySettings";
import PaperScrollContainer from "../../../components/layout/paper/PaperScrollContainer";
import * as selectors from "./selectors";
import { connect } from "react-redux";
import DefaultRuleSettings from "./CalculationRuleTable";
import CalculationBasisSettings from "./CalculationBasisSettings";
import CalculationTypeSettings from "./CalculationTypeSettings";
import NotificationsSettings from "./NotificationsSettings";

const DisplaySettings = () => (
    <PaperScrollContainer>
        <Grid container spacing={24}>
            <Grid item xs={24} sm={12} md={6} lg={3}>
                <DefaultErrorBoundry>
                    <CategoryDisplaySettings />
                </DefaultErrorBoundry>
            </Grid>
            <Grid item xs={24} sm={12} md={6} lg={3}>
                <DefaultErrorBoundry>
                    <CalculationDisplaySettings />
                </DefaultErrorBoundry>
            </Grid>
            <Grid item xs={24} sm={12} md={6} lg={3}>
                <DefaultErrorBoundry>
                    <BalanceDisplaySettings />
                </DefaultErrorBoundry>
            </Grid>
        </Grid>
    </PaperScrollContainer>
);
const LogicSettings = () => (
    <PaperScrollContainer>
        <Grid container spacing={24}>
            <Grid item xs={24} sm={12} md={6} lg={3}>
                <DefaultErrorBoundry>
                    <CalculationBasisSettings />
                </DefaultErrorBoundry>
            </Grid>
            <Grid item xs={24} sm={12} md={6} lg={3}>
                <DefaultErrorBoundry>
                    <CalculationTypeSettings />
                </DefaultErrorBoundry>
            </Grid>
        </Grid>
    </PaperScrollContainer>
);

const RuleSettings = () => <DefaultRuleSettings />;

const NotificationTargetGroupSettings = () => <NotificationsSettings />;

const setupViews = [
    DisplaySettings,
    LogicSettings,
    RuleSettings,
    NotificationTargetGroupSettings
];

const Setup = props => {
    const CurrentView = setupViews[props.selectedTab];
    return (
        <ContentPaper>
            <SetupHeader {...props} />
            <CurrentView />
        </ContentPaper>
    );
};
const mapStateToProps = state => ({
    selectedTab: selectors.getSelectedTab(state)
});

export default connect(mapStateToProps)(Setup);
