import React from "react";
import * as types from "../../actionTypes";
import { userGroups } from "../../actionTypes";
import Authenticated from "common/auth/Authenticated";
import DefaultErrorBoundry from "common/errors/DefaultErrorBoundry";
import WorkTypes from "./work-types";
import Departments from "./departments";
import Profile from "./profile";
import Setup from "./setup";
import KeyPerformanceIndicators from "./key-performance-indicators";
import Privacy from "./privacy";
import * as selectors from "./selectors";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import Slider from "common/forms/Slider";
import InfoBar from "../../components/infobar/InfoBar";
import * as _ from "lodash";
import * as companySelectors from "selectors/company";
import * as userSelectors from "selectors/user";

const createPath = id => {
    return `/settings/:companyId/${id}`;
};

const Settings = props => {
    const tabs = (
        <Slider
            tabs={_.map(props.tabs, t => t.name)}
            selectedTab={props.currentTab}
            onSelectTab={index => props.onTabChange(props.tabs[index], index)}
            tabWidth={100}
            key={"settings_tabs"}
        />
    );

    const getPath = id => {
        const index = _.findIndex(props.tabs, t => t.path === id);
        if (index >= 0) {
            return createPath(id);
        }
        return "#";
    };

    return (
        <Authenticated
            userGroups={[
                userGroups.ADMIN,
                userGroups.DEPARTMENT_LEADER,
                userGroups.USER
            ]}
            fullPage
        >
            <DefaultErrorBoundry>
                <InfoBar mainContent={tabs} />
                <Switch>
                    <Route
                        path={getPath("profile")}
                        render={routeProps => (
                            <Profile {...routeProps} {...props} />
                        )}
                    />
                    <Route
                        path={getPath("setup")}
                        render={routeProps => (
                            <Setup {...routeProps} {...props} />
                        )}
                    />
                    <Route
                        path={getPath("kpi")}
                        render={routeProps => (
                            <KeyPerformanceIndicators
                                {...routeProps}
                                {...props}
                            />
                        )}
                    />
                    <Route
                        path={getPath("departments")}
                        render={routeProps => (
                            <Departments {...routeProps} {...props} />
                        )}
                    />
                    <Route
                        path={getPath("workTypes")}
                        render={routeProps => (
                            <WorkTypes {...routeProps} {...props} />
                        )}
                    />
                    <Route
                        path={getPath("privacy")}
                        render={routeProps => (
                            <Privacy {...routeProps} {...props} />
                        )}
                    />
                </Switch>
            </DefaultErrorBoundry>
        </Authenticated>
    );
};

const mapStateToProps = (state, props) => {
    return {
        currentTab: selectors.getSelectedTab(state, props),
        tabs: selectors.getTabs(state, props),
        company: companySelectors.getCurrentCompany(state, props),
        signedInUser: userSelectors.getFullSignedInUser(state, props)
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const { companyId } = ownProps.match.params;
    return {
        onTabChange: (tab, index) => {
            dispatch({
                type: types.NAVIGATE,
                payload: { url: `/settings/${companyId}/${tab.path}` }
            });
        }
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Settings)
);
