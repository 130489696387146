import React, { Fragment, PureComponent } from "react";
import ReactDOM from "react-dom";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Popup from "./Popup";
import PropTypes from "prop-types";

const styles = theme => ({
    popup: {
        height: "30px",
        padding: "0 10px",
        borderRadius: "3px",
        display: "flex",
        alignItems: "center"
    },
    title: {
        marginTop: "2px",
        fontSize: theme.typography.F_S,
        fontWeight: 600
    },
    arrow: {
        top: "8px"
    }
});

class Tooltip extends PureComponent {
    state = {
        open: false,
        target: null
    };

    handleMouseEnter = () => {
        this.enterTimer = setTimeout(() => this.setState({ open: true }), 500);
    };
    handleMouseLeave = () => {
        clearTimeout(this.enterTimer);
        this.setState({ open: false });
    };
    handleChildRef = r => {
        this.setState({ target: ReactDOM.findDOMNode(r) });
    };
    render() {
        const { classes, title, children, disabled } = this.props;

        return (
            <Fragment>
                {React.cloneElement(React.Children.only(children), {
                    onMouseEnter: this.handleMouseEnter,
                    onMouseLeave: this.handleMouseLeave,
                    ref: this.handleChildRef
                })}
                <Popup
                    open={this.state.open && !disabled}
                    className={classes.popup}
                    placement={"right"}
                    targetRef={this.state.target}
                    arrowSize={"small"}
                    onClickAway={this.handleMouseLeave}
                    arrowStyles={{ top: "8px" }}
                    popperStyle={{ left: "-7px" }}
                >
                    <Typography className={classes.title}>{title}</Typography>
                </Popup>
            </Fragment>
        );
    }
}

Tooltip.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool
};

Tooltip.defaultProps = {
    disabled: false
};

export default withStyles(styles)(Tooltip);
