import { forEach } from "lodash";
import Divider from "@material-ui/core/Divider";
import MenuItem from "./MenuItem";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import Popup from "./Popup";
import SelectButton from "./PopupButton";

const styles = theme => ({
    root: {
        display: "flex",
        zIndex: 10000,
        maxHeight: "220px"
    },
    popperClose: {
        pointerEvents: "none"
    },
    menuItem: {
        minWidth: "120px",
        cursor: "pointer"
    },
    menuTypo: {
        textOverflow: "ellipsis",
        display: "inline",
        whiteSpace: "nowrap",
        overflowX: "hidden"
    },
    popper: {
        marginTop: "15px"
    },
    popup: {
        maxHeight: "300px",
        overflow: "auto"
    }
});

class ButtonPopupMenu extends React.Component {
    state = {
        open: false
    };

    handleClose = e => {
        this.setState({ open: false });
    };

    togglePopup = e => {
        this.setState({ open: !this.state.open });
    };
    onClick = item => {
        this.props.onChange(item);
        this.setState({ open: false });
    };

    render() {
        const {
            classes,
            items,
            target,
            value,
            keepTargetChildren,
            placement,
            highlighted,
            disabled,
            locked,
            ...other
        } = this.props;
        const { open } = this.state;
        const menuItems = [];

        forEach(items, (item, i) => {
            const selected = i === value;
            menuItems.push(
                <MenuItem
                    onClick={() => this.onClick(i)}
                    className={classes.menuItem}
                    key={i}
                    selected={selected}
                    highlighted={highlighted.indexOf(i) >= 0}
                    small
                >
                    {item}
                </MenuItem>
            );
            if (i !== items.length - 1) {
                menuItems.push(<Divider key={i + "_divider"} />);
            }
        });

        return (
            <Popup
                onClickAway={this.handleClose}
                open={open}
                target={React.cloneElement(target, {
                    onClick: this.togglePopup,
                    "aria-disabled": disabled,
                    disabled,
                    locked,
                    children: keepTargetChildren
                        ? target.props.children
                        : items[value]
                })}
                placement={placement}
                preventOverflow
                flip
                boundariesElement={window}
                className={classes.popup}
                {...other}
            >
                {menuItems}
            </Popup>
        );
    }
}

export const SelectPopupPropTypes = {
    /**
     * A list of items to show
     */
    items: PropTypes.arrayOf(PropTypes.string),
    /**
     * The currently selected value
     */
    value: PropTypes.number,
    /**
     * Function called when an item is selected. Is called with the index of the item.
     */
    onChange: PropTypes.func,

    /**
     * A custom target for the input button
     */
    target: PropTypes.node,

    /**
     * The targets children will be overridden with the selected value. If this is
     * not wanted. Use keepTargetChildren = true.
     */
    keepTargetChildren: PropTypes.bool,
    placement: PropTypes.string,
    /**
     * Indices which should be highlighted
     */
    highlighted: PropTypes.arrayOf(PropTypes.number),

    disabled: PropTypes.bool
};

ButtonPopupMenu.propTypes = SelectPopupPropTypes;

ButtonPopupMenu.defaultProps = {
    items: [],
    value: 0,
    target: <SelectButton fontSize={"smallMed"} />,
    placement: "bottom",
    highlighted: [],
    disabled: false
};

export default withStyles(styles)(ButtonPopupMenu);
