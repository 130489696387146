import { isFunction, isInteger, map } from "lodash";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import SelectMenu from "../../../common/dropdown/SelectMenu";
import BorderIconButton from "../../../common/forms/BorderIconButton";
import MonthPicker from "../../../common/forms/MonthPicker";
import YearPicker from "../../../common/forms/YearPicker";
import CoinIcon from "../../../common/icons/CoinIcon";
import KeyFigure from "./KeyFigure";

const styles = theme => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap"
    },
    keyFigures: {
        display: "flex",
        alignItems: "center",
        height: "84px"
    },
    select: {
        borderColor: theme.palette.C_DB15,
        "&:hover": {
            borderColor: theme.palette.C_DB40
        },
        transition: "all 100ms",
        minWidth: "150px",
        height: "44px"
    },
    selecters: {
        display: "flex",
        justifyContent: "flex-end"
    },
    monthPicker: {
        marginLeft: theme.spacing.gridMargin
    }
});

class InfoBar extends Component {
    renderDepartments = () => {
        const {
            departments,
            currentDepartment,
            onChangeDepartement,
            classes
        } = this.props;

        if (!departments[currentDepartment]) return null;

        return (
            <SelectMenu
                value={currentDepartment}
                onChange={onChangeDepartement}
                className={classes.select}
                items={departments}
            />
        );
    };

    renderDatePicker = () => {
        const { year, month, onChangeDate, classes } = this.props;

        //MonthPicker should be rendered
        if (isInteger(month) && isInteger(year)) {
            return (
                <MonthPicker
                    year={year}
                    month={month}
                    onChange={onChangeDate}
                    className={classes.monthPicker}
                />
            );
        } else if (isInteger(year)) {
            //Else yearpicker should be rendered
            return (
                <YearPicker
                    year={year}
                    onChange={onChangeDate}
                    className={classes.monthPicker}
                />
            );
        }
        return null;
    };

    renderPayoutButton = () => {
        const { payoutTitle } = this.props;
        return (
            <BorderIconButton
                icon={<CoinIcon />}
                onClick={this.props.onRequestPayout}
                title={payoutTitle}
            />
        );
    };

    render() {
        const {
            classes,
            keyFigures,
            onChangeDepartement,
            onRequestPayout
        } = this.props;

        const keyFigureViews = map(keyFigures, (keyFigure, i) => {
            if (i > 5) return null;
            return (
                <KeyFigure
                    name={keyFigure.name}
                    value={keyFigure.value}
                    change={keyFigure.change}
                    changeType={keyFigure.changeType}
                    key={keyFigure.name}
                    type={keyFigure.type}
                    byNormalHours={keyFigure.byNormalHours}
                />
            );
        });

        return (
            <div className={classes.root}>
                <div className={classes.keyFigures}>{keyFigureViews}</div>

                <div className={classes.selecters}>
                    {isFunction(onRequestPayout) && this.renderPayoutButton()}
                    {isFunction(onChangeDepartement) &&
                        this.renderDepartments()}
                    {this.renderDatePicker()}
                </div>
            </div>
        );
    }
}

InfoBar.propTypes = {
    keyFigures: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

    departments: PropTypes.arrayOf(PropTypes.string).isRequired,

    currentDepartment: PropTypes.number,
    year: PropTypes.number.isRequired,
    month: PropTypes.number,
    onChangeDate: PropTypes.func.isRequired,
    onChangeDepartement: PropTypes.func
};

InfoBar.defaultProps = {
    keyFigures: {}
};
export default withStyles(styles)(InfoBar);
