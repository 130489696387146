import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import Popup from "../../poppers/Popup";
import InputField from "./InputField";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import MiniButton from "../mini/MiniButton";

const styles = theme => ({
    menuItem: {
        minWidth: "135px"
    },
    popper: {
        marginTop: "15px"
    },
    root: {
        width: "205px",
        padding: "20px 15px",
        height: "120px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: theme.palette.C_W,
        borderRadius: "6px",
        boxSizing: "border-box"
    },
    input: {
        height: "35px"
    },
    buttonGroup: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%"
    },
    arrow: {
        color: theme.palette.C_DB5
    },
    button: {
        color: theme.palette.C_DB120,
        "&:hover": {
            color: theme.palette.C_LB
        }
    }
});

class CellInputField extends PureComponent {
    state = {
        open: false,
        input: null
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.input !== null) return null;

        return {
            input: nextProps.initialValue
        };
    }

    onKeyDown = e => {
        if (e.key === "Enter") {
            // Enter
            this.onConfirm(e);
        } else if (e.key === "Escape") {
            this.resetState();
        }
    };

    resetState = () => {
        this.setState({ input: this.props.initialValue, open: false });
    };
    onConfirm = e => {
        this.props.onChange(this.state.input);
        this.setState({ open: false });
    };
    handleClose = () => {
        this.setState({ open: false });
    };

    togglePopup = () => {
        this.setState({ open: !this.state.open }, () => {
            if (this.state.open === true) {
                this.inputRef.focus();
            }
        });
    };
    onChange = e => {
        this.setState({ input: e.target.value });
    };

    renderInputField = () => {
        const { classes, InputProps, initialValue } = this.props;
        const { input } = this.state;
        return (
            <div className={classes.root}>
                <InputField
                    {...InputProps}
                    fullWidth
                    value={input === null ? initialValue : input}
                    onChange={this.onChange}
                    inputRef={r => (this.inputRef = r)}
                    onKeyDown={this.onKeyDown}
                    inputClassName={classes.input}
                />
                <div className={classes.buttonGroup}>
                    <MiniButton variant={"secondary"} onClick={this.resetState}>
                        {"Cancel"}
                    </MiniButton>
                    <MiniButton variant={"primary"} onClick={this.onConfirm}>
                        {"Save"}
                    </MiniButton>
                </div>
            </div>
        );
    };

    render() {
        const { classes, initialValue, disabled, isScrolling } = this.props;
        const { open } = this.state;

        const text = (
            <Typography color={"inherit"}>
                {initialValue !== undefined && initialValue !== ""
                    ? initialValue
                    : "-"}
            </Typography>
        );
        if (isScrolling) return text;
        return (
            <Popup
                onClickAway={this.handleClose}
                open={open}
                target={
                    <ButtonBase
                        disableRipple
                        onClick={this.togglePopup}
                        className={classes.button}
                        disabled={disabled}
                        data-test-id={"cell-input-field"}
                    >
                        {text}
                    </ButtonBase>
                }
                popperClassName={classes.popper}
                placement="bottom"
                preventOverflow
                whiteBackground
                flip
            >
                {this.renderInputField()}
            </Popup>
        );
    }
}

CellInputField.propTypes = {
    /**
     * The initial value of the input field
     */
    initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * Function called when the input is done writing in.
     */
    onChange: PropTypes.func,

    disabled: PropTypes.bool
};

CellInputField.defaultProps = {
    initialValue: "",
    InputProps: {},
    disabled: false
};

export default withStyles(styles)(CellInputField);
