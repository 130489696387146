import { forEach } from "lodash";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import Dropdown from "../dropdown/Dropdown";
import SelectButton from "../forms/SelectButton";

const styles = theme => ({
    root: {
        display: "flex",
        zIndex: 10000,
        maxHeight: "500px"
    },
    popperClose: {
        pointerEvents: "none"
    },
    menuItem: {
        height: "50px",
        minWidth: "150px"
    },
    menuTypo: {
        textOverflow: "ellipsis",
        display: "inline",
        whiteSpace: "nowrap",
        overflowX: "hidden",
        fontSize: theme.typography.F_S_N,
        fontWeight: 600
    },
    popper: {
        marginTop: "15px"
    }
});

class SelectMenu extends React.Component {
    state = {
        open: false
    };

    handleToggle = open => {
        this.setState({ open });
    };

    onClick = item => {
        this.props.onChange(item);
        this.setState({ open: false });
    };

    render() {
        const { classes, items, value } = this.props;
        const { open } = this.state;
        const menuItems = [];

        if (open) {
            forEach(items, (item, i) => {
                const selected = i === value;
                menuItems.push(
                    <MenuItem
                        onClick={() => this.onClick(i)}
                        className={classes.menuItem}
                        key={i}
                        selected={selected}
                    >
                        <Typography
                            variant={selected ? "body2" : "body1"}
                            color={"inherit"}
                            className={classes.menuTypo}
                        >
                            {item}
                        </Typography>
                    </MenuItem>
                );
                if (i === items.length - 1) {
                    menuItems.push(<Divider />);
                }
            });
        }

        return (
            <Dropdown
                onToggle={this.handleToggle}
                open={open}
                target={<SelectButton>{items[value]}</SelectButton>}
                popperClassName={classes.popper}
                placement="bottom"
            >
                {menuItems}
            </Dropdown>
        );
    }
}

SelectMenu.propTypes = {
    classes: PropTypes.object.isRequired
};

SelectMenu.defaultProps = {
    items: [],
    value: 0
};

export default withStyles(styles)(SelectMenu);
