import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 20 20">
            <path d="M19.94,8.89a.65.65,0,0,0-.65-.49A2.23,2.23,0,0,1,17.22,7a2.26,2.26,0,0,1,.56-2.48.54.54,0,0,0,.06-.75,10,10,0,0,0-1.58-1.6.56.56,0,0,0-.76.06A2.29,2.29,0,0,1,13,2.82,2.21,2.21,0,0,1,11.65.65a.54.54,0,0,0-.49-.58,10,10,0,0,0-2.25,0,.55.55,0,0,0-.49.57A2.24,2.24,0,0,1,7.05,2.77,2.32,2.32,0,0,1,4.57,2.2a.55.55,0,0,0-.75-.06A9.81,9.81,0,0,0,2.2,3.74a.56.56,0,0,0,.06.76A2.21,2.21,0,0,1,2.82,7,2.3,2.3,0,0,1,.64,8.35a.54.54,0,0,0-.57.49,10.13,10.13,0,0,0,0,2.27.66.66,0,0,0,.66.49A2.2,2.2,0,0,1,2.78,13a2.24,2.24,0,0,1-.56,2.48.54.54,0,0,0-.06.75,9.57,9.57,0,0,0,1.58,1.6.56.56,0,0,0,.76-.06A2.29,2.29,0,0,1,7,17.18a2.21,2.21,0,0,1,1.36,2.17.54.54,0,0,0,.49.58A8.77,8.77,0,0,0,10,20a10.28,10.28,0,0,0,1.1-.06.55.55,0,0,0,.49-.57A2.24,2.24,0,0,1,13,17.23a2.32,2.32,0,0,1,2.48.57.55.55,0,0,0,.75.06,10.22,10.22,0,0,0,1.62-1.6.56.56,0,0,0-.06-.76A2.21,2.21,0,0,1,17.18,13a2.24,2.24,0,0,1,2-1.36h.13a.54.54,0,0,0,.58-.49A10.13,10.13,0,0,0,19.94,8.89ZM10,13.36A3.34,3.34,0,1,1,13.35,10,3.35,3.35,0,0,1,10,13.36Z" />;
        </SvgIcon>
    );
}
