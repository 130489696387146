import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import SelectPopup, { SelectPopupPropTypes } from "./ButtonPopupMenu";
import SelectButton from "./PopupButton";

const styles = theme => ({
    buttonRoot: {
        border: "none",
        fontSize: theme.typography.F_N,
        fontWeight: "normal",
        color: theme.palette.C_DB120,
        padding: "0 10px",
        height: "30px",
        minWidth: 0,
        marginLeft: "-10px", // To align column with title.,
        "&:hover": {
            border: "none"
        }
    }
});

/**
 * @description A select component to be used within a table.
 */
class CellPopupMenu extends Component {
    render() {
        const {
            classes,
            items,
            value,
            isScrolling,
            locked,
            ...other
        } = this.props;
        const target = (
            <SelectButton
                fontSize="medium"
                className={classes.buttonRoot}
                children={items[value]}
                locked={locked}
            />
        );
        if (isScrolling) return target;

        return (
            <SelectPopup
                {...other}
                items={items}
                value={value}
                whiteBackground
                locked={locked}
                target={
                    <SelectButton
                        fontSize="medium"
                        className={classes.buttonRoot}
                    />
                }
            />
        );
    }
}

CellPopupMenu.propTypes = SelectPopupPropTypes;

export default withStyles(styles)(CellPopupMenu);
