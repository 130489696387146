import React, { Fragment, PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Typography from "common/Typography";
import * as _ from "lodash";
import { Link } from "react-router-dom";

const styles = theme => ({
    root: {
        display: "flex"
    },
    secondary: {
        marginLeft: "7px"
    },
    divider: {
        margin: "0 7px"
    },
    hoverableLink: {
        "&:hover": {
            color: theme.palette.C_DB120
        }
    }
});

class PaperHeaderTitle extends PureComponent {
    renderBreadCrumbs = () => {
        const { primary, backLink, classes } = this.props;

        if (primary.length > 2) {
            throw new Error("Only breadcrumbs of length 2 is supported");
        }
        const linkBreadCrumb = backLink ? (
            <Link to={backLink}>
                <Typography
                    color={"superFaded"}
                    className={classes.hoverableLink}
                    size={"supreme"}
                    bold
                >
                    {primary[0]}
                </Typography>
            </Link>
        ) : (
            <Typography color={"superFaded"} size={"supreme"} bold>
                {primary[0]}
            </Typography>
        );
        return (
            <Fragment>
                {linkBreadCrumb}
                <Typography
                    size={"supreme"}
                    color={"superFaded"}
                    className={classes.divider}
                    bold
                >
                    {"/"}
                </Typography>
                <Typography size={"supreme"} bold>
                    {primary[1]}
                </Typography>
            </Fragment>
        );
    };
    renderSingleTitle = primary => {
        const { secondary, classes } = this.props;
        return (
            <Fragment>
                <Typography size="supreme" bold>
                    {primary}
                </Typography>
                <Typography
                    size="supreme"
                    color={"superFaded"}
                    className={classes.secondary}
                    bold
                >
                    {secondary}
                </Typography>
            </Fragment>
        );
    };
    render() {
        const { primary, classes } = this.props;

        let title = null;

        if (_.isString(primary)) {
            title = this.renderSingleTitle(primary);
        } else if (Array.isArray(primary) && primary.length === 1) {
            title = this.renderSingleTitle(primary[0]);
        } else if (Array.isArray(primary)) {
            title = this.renderBreadCrumbs();
        }

        return <div className={classes.root}>{title}</div>;
    }
}

export const propTypes = {
    /**
     * A string which is shown as the primary title. If array of strings, the title will be a clickable
     * breadcrumb. Array does only support two
     */
    primary: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    /**
     * Subtitle, should include at/for workspaceName. If primary is an array, will be added to the first
     * item in the array
     */
    secondary: PropTypes.string,
    /**
     * A function to be called when the first item in primary array is called. Link will not be clickable
     * if the function is undefined. Will only be called if primary is a two item array.
     */
    backLink: PropTypes.string
};

PaperHeaderTitle.propTypes = propTypes;

PaperHeaderTitle.defaultProps = {
    primary: "",
    secondary: ""
};
export default withStyles(styles)(PaperHeaderTitle);
