import { httpRequest, assert } from "./helpers";
import { isString, isNumber, isEmpty, isObject } from "lodash";
import * as _ from "lodash";

/**
 * All API calls for reading and writing data about companies
 */

/**
 * GETTERS
 */

/**
 * getCompanyByUser - Gets a list of all companies, used by sale admins
 *
 * @param  {string}  firebaseToken Token recieved by server on login
 * @param  {string}  userId         Id for sale admin
 * @return {Promise}               Promise with list of company details.
 */

export const getCompany = async companyId => {
    if (!companyId) {
        throw new Error("Invalid input parameters in getCompany", companyId);
    }
    return await httpRequest(`/api/v1/companies/${companyId}`, "GET");
};

export const getCompanyHoursDaily = async (
    companyId,
    departmentId,
    year,
    month
) => {
    assert(
        companyId && year !== undefined && month !== undefined,
        "Invalid input params to getCompanyHoursDaily"
    );

    let url = `/api/v1/companies/${companyId}/hours/daily?year=${year}&month=${month}`;
    if (departmentId) {
        url += `&departmentId=${departmentId}`;
    }
    return await httpRequest(url, "GET");
};
export const getKeyFiguresByCompany = async (
    companyId,
    year,
    month,
    departement
) => {
    if (!companyId || !year || !month || !departement) {
        throw new Error("Invalid input parameters in getKeyFiguresByCompany");
    }
    return await httpRequest(
        `/api/v1/companies/${companyId}/keyfigures/calc?year=${year}&month=${month}&departmentId=${departement}`,
        "GET"
    );
};

export const getUserLogsByCompany = async (
    companyId,
    departmentId,
    limit,
    from
) => {
    if (!isString(companyId) || !isNumber(limit)) {
        throw new Error("Invalid input params to getUserLogsByCompany");
    }
    let path = `/api/v1/events/company/${companyId}?limit=${limit}`;
    if (isString(from)) {
        path += `&from=${from}`;
    }
    if (departmentId) {
        path += `&departmentId=${departmentId}`;
    }
    return await httpRequest(path, "GET");
};

export const runCompanyCalculations = async companyId => {
    return await httpRequest(
        `/api/v1/companies/${companyId}/hours/calc`,
        "POST"
    );
};

export const createCompany = payload => {
    return httpRequest(
        "/api/v1/authentication/createCompany",
        "POST",
        payload,
        {},
        false
    );
};

export const createNotificationTargetGroup = async (companyId, payload) => {
    if (!isString(companyId) || !isObject(payload)) {
        throw new Error(
            "Invalid input parameters to createNotificationTargetGroup"
        );
    }
    return await httpRequest(
        `/api/v1/companies/${companyId}/notificationTargetGroups`,
        "POST",
        payload
    );
};

export const updateNotificationTargetGroup = async (
    companyId,
    notificationTargetGroupId,
    payload
) => {
    if (
        !isString(companyId) ||
        !isString(notificationTargetGroupId) ||
        !isObject(payload)
    ) {
        throw new Error(
            "Invalid input parameters to updateNotificationTargetGroup"
        );
    }
    return await httpRequest(
        `/api/v1/companies/${companyId}/notificationTargetGroups/${notificationTargetGroupId}`,
        "POST",
        payload
    );
};

export const deleteNotificationTargetGroup = async (
    companyId,
    notificationTargetGroupId
) => {
    if (!isString(companyId) || !isString(notificationTargetGroupId)) {
        throw new Error(
            `Invalid input parameters to deleteNotificationTargetGroup companyId=${companyId} targetGroupId=${notificationTargetGroupId}`
        );
    }
    return await httpRequest(
        `/api/v1/companies/${companyId}/notificationTargetGroups/${notificationTargetGroupId}`,
        "DELETE"
    );
};

export const getNotificationTargetGroups = async companyId => {
    if (!isString(companyId)) {
        throw new Error(
            `Invalid input parameters to getNotificationTargetGroups companyId=${companyId}`
        );
    }

    return await httpRequest(
        `/api/v1/companies/${companyId}/notificationTargetGroups`,
        "GET"
    );
};

export const updateCompanyRules = async (companyId, ruleId, payload) => {
    assert(companyId, "CompanyId has to be defined");
    assert(ruleId, "Rule id has to be defined");
    assert(_.isObject(payload), "Payload has to be an object");

    return await httpRequest(
        `/api/v1/companies/${companyId}/rules/${ruleId}`,
        "POST",
        payload
    );
};

export const createCalculationRule = async (companyId, payload) => {
    if (!isString(companyId) || !isObject(payload)) {
        throw new Error(
            `invalid input parameters to createCalculationRule companyId=${companyId} payload=${payload}`
        );
    }

    return await httpRequest(
        `/api/v1/companies/${companyId}/calculationRules`,
        "POST",
        payload
    );
};

export const updateCalculationRule = async (
    companyId,
    calculationRuleId,
    payload
) => {
    if (
        !isString(companyId) ||
        !isString(calculationRuleId) ||
        !isObject(payload)
    ) {
        throw new Error(
            `invalid input parameters to updateCalculationRule companyId=${companyId} calculationRuleId=${calculationRuleId}`
        );
    }

    return await httpRequest(
        `/api/v1/companies/${companyId}/calculationRules/${calculationRuleId}`,
        "POST",
        payload
    );
};

export const deleteCalculationRule = async (companyId, calculationRuleId) => {
    if (!isString(companyId) || !isString(calculationRuleId)) {
        throw new Error(
            `invalid input parameters to deleteCalculationRule companyId=${companyId} calculationRuleId=${calculationRuleId}`
        );
    }

    return await httpRequest(
        `/api/v1/companies/${companyId}/calculationRules/${calculationRuleId}`,
        "DELETE"
    );
};

export const getCalculationRules = async companyId => {
    if (!isString(companyId)) {
        throw new Error(
            `Invalid input parameters to getCalculationRules companyId=${companyId}`
        );
    }

    return await httpRequest(
        `/api/v1/companies/${companyId}/calculationRules`,
        "GET"
    );
};

export const createDepartmentCalculationRule = async (
    companyId,
    departmentId,
    payload
) => {
    if (
        !_.isString(companyId) ||
        !_.isString(departmentId) ||
        !_.isObject(payload)
    ) {
        throw new Error(
            "Invalid input parameters to createDepartmentCalculationRule"
        );
    }
    return await httpRequest(
        `/api/v1/companies/${companyId}/departments/${departmentId}/calculationRules`,
        "POST",
        payload
    );
};

export const updateDepartmentCalculationRule = async (
    companyId,
    departmentId,
    calculationRuleId,
    payload
) => {
    if (
        !_.isString(companyId) ||
        !_.isString(departmentId) ||
        !_.isString(calculationRuleId) ||
        !_.isObject(payload)
    ) {
        throw new Error(
            "Invalid input parameters to updateDepartmentCalculationRule"
        );
    }
    return await httpRequest(
        `/api/v1/companies/${companyId}/departments/${departmentId}/calculationRules/${calculationRuleId}`,
        "POST",
        payload
    );
};
export const deleteDepartmentCalculationRule = async (
    companyId,
    departmentId,
    calculationRuleId
) => {
    if (
        !_.isString(companyId) ||
        !_.isString(departmentId) ||
        !_.isString(calculationRuleId)
    ) {
        throw new Error(
            "Invalid input parameters to deleteDepartmentCalculationRule"
        );
    }
    return await httpRequest(
        `/api/v1/companies/${companyId}/departments/${departmentId}/calculationRules/${calculationRuleId}`,
        "DELETE"
    );
};

export const setCalculationRuleActive = async (
    companyId,
    departmentId,
    calculationRuleId,
    isActive
) => {
    if (
        !_.isString(companyId) ||
        !_.isString(departmentId) ||
        !_.isString(calculationRuleId)
    ) {
        throw new Error(
            "Invalid input parameters to deleteDepartmentCalculationRule"
        );
    }
    return await httpRequest(
        `/api/v1/companies/${companyId}/departments/${departmentId}/calculationRules/${calculationRuleId}/active`,
        "POST",
        { active: isActive }
    );
};

export const getKeyPerformanceIndicators = async companyId => {
    if (!isString(companyId)) {
        throw new Error(
            `Invalid input parameters to getKeyPerformanceIndicator companyId=${companyId}`
        );
    }

    return await httpRequest(
        `/api/v1/companies/${companyId}/keyPerformanceIndicator`,
        "GET"
    );
};

export const createKeyPerformanceIndicator = async (companyId, payload) => {
    if (!isString(companyId) || !isObject(payload)) {
        throw new Error(
            `invalid input parameters to createKeyPerformanceIndicator companyId=${companyId} payload=${payload}`
        );
    }

    return await httpRequest(
        `/api/v1/companies/${companyId}/keyPerformanceIndicator`,
        "POST",
        payload
    );
};

export const updateKeyPerformanceIndicator = async (
    companyId,
    keyPerformanceIndicatorId,
    payload
) => {
    if (
        !isString(companyId) ||
        !isString(keyPerformanceIndicatorId) ||
        !isObject(payload)
    ) {
        throw new Error(
            `invalid input parameters to updateKeyPerformanceIndicator companyId=${companyId} payload=${payload}`
        );
    }

    return await httpRequest(
        `/api/v1/companies/${companyId}/keyPerformanceIndicator/${keyPerformanceIndicatorId}`,
        "POST",
        payload
    );
};

export const deleteKeyPerformanceIndicator = async (
    companyId,
    keyPerformanceIndicatorId
) => {
    if (!isString(companyId) || !isString(keyPerformanceIndicatorId)) {
        throw new Error(
            `invalid input parameters to deleteKeyPerformanceIndicator companyId=${companyId}`
        );
    }

    return await httpRequest(
        `/api/v1/companies/${companyId}/keyPerformanceIndicator/${keyPerformanceIndicatorId}`,
        "DELETE"
    );
};

export const getKeyPerformanceIndicatorsMetaData = async companyId => {
    if (!isString(companyId)) {
        throw new Error(
            `Invalid input parameters to getKeyPerformanceIndicatorsMetaData companyId=${companyId}`
        );
    }

    return await httpRequest(
        `/api/v1/companies/${companyId}/keyPerformanceIndicator/meta`,
        "GET"
    );
};

export const addDepartment = async (companyId, department) => {
    if (!isString(companyId) || !isObject(department)) {
        throw new Error("Invalid input parameters in addDepartment");
    }

    return await httpRequest(
        `/api/v1/companies/${companyId}/departments`,
        "POST",
        department
    );
};

export const updateDepartment = async (companyId, departmentId, payload) => {
    if (!isString(companyId) || !isString(departmentId)) {
        throw new Error("Invalid input parameters in addDepartment");
    }
    return await httpRequest(
        `/api/v1/companies/${companyId}/departments/${departmentId}`,
        "POST",
        payload
    );
};
export const deleteDepartmentView = async (companyId, departmentId) => {
    if (!isString(companyId) || !isString(departmentId)) {
        throw new Error("Invalid input parameters to deleteDepartmentView");
    }
    return await httpRequest(
        `/api/v1/companies/${companyId}/departments/${departmentId}/view`,
        "DELETE"
    );
};
export const updateCompanyDetails = async (companyId, payload) => {
    if (!isString(companyId) || isEmpty(payload)) {
        throw new Error("Invalid input parameters in updateCompanyDetails");
    }
    return await httpRequest(`/api/v1/companies/${companyId}`, "POST", payload);
};

export const updateDepartmentDetails = async (
    companyId,
    departmentId,
    payload
) => {
    if (!isString(companyId) || !isString(departmentId) || isEmpty(payload)) {
        throw new Error(
            "Invalid input parameters in updateDepartmentPresentationSettings"
        );
    }
    return await httpRequest(
        `/api/v1/companies/${companyId}/department/${departmentId}`,
        "POST",
        payload
    );
};
export const runRecentSynchronization = async (companyId, payload) => {
    if (!isString(companyId) || isEmpty(payload)) {
        throw new Error("Invalid input parameters in runRecentSynchronization");
    }
    return await httpRequest(
        `/api/v1/resync/companies/${companyId}/recent`,
        "POST",
        payload
    );
};
/**
 * DELETES
 */

export const deleteCompanyDepartment = async (companyId, departmentId) => {
    if (!isString(companyId) || !isString(departmentId)) {
        throw new Error("Invalid input parameters in deleteCompanyDepartment");
    }
    return await httpRequest(
        `/api/v1/companies/${companyId}/departments/${departmentId}`,
        "DELETE"
    );
};
