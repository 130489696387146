import React from "react";
import Grid from "@material-ui/core/Grid";
import UserCard from "components/user/UserCard";
import { map } from "lodash";

class UserGrid extends React.Component {
    componentDidMount() {
        const {
            signedInUser,
            fetchInitialUsers,
            filters,
            displayType
        } = this.props;

        fetchInitialUsers(signedInUser, filters, displayType);
    }

    renderRow = ({index}) => {

    };
    render() {
        const { users, onUserClick } = this.props;
        const userCards = map(users, user => {
            return (
                <Grid item xs={12} sm={12} md={6} lg={3} key={user.id}>
                    <UserCard
                        firstName={user.firstName}
                        lastName={user.lastName}
                        department={user.department}
                        userTitle={user.userTitle}
                        userId={user.id}
                        onItemClick={() => onUserClick(user.id)}
                    />
                </Grid>
            );
        });
        return (
            <Grid container spacing={24}>
                {userCards}
            </Grid>
        );
    }
}

export default UserGrid;
