import { combineReducers } from "redux";
import company from "./company";
import user from "./user";
import appState from "./appState";
import auth from "./auth";
import notifications from "./notifications";
import timebank from "./timebank";
import modal from "./modal";
import settings from "./settings";
import * as types from "../actionTypes";

const appReducer = combineReducers({
    auth,
    timebank,
    appState,
    notifications,
    modal,
    settings,
    ...company,
    ...user
});

const rootReducer = (state, action) => {
    if (action.type === types.RESET_STATE) {
        state = undefined; // Reset all states except modal and inital auth load
    }

    return appReducer(state, action);
};

export default rootReducer;
