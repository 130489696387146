import React from "react";
export default ({ children }) => {
    return (
        <div
            style={{
                height: "calc(100% - 80px)", //Minus header
                overflowY: "auto",
                overflowX: "hidden"
            }}
        >
            {children}
        </div>
    );
};
