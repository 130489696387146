import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import { capitalize } from "@material-ui/core/utils/helpers";
const styles = theme => ({
    base: {
        display: "flex"
    },
    justifyCenter: {
        justifyContent: "center"
    },
    justifyStart: {
        justifyContent: "flex-start"
    },
    justifyEnd: {
        justifyContent: "flex-end"
    },
    justifyBetween: {
        justifyContent: "space-between"
    },
    alignCenter: {
        alignItems: "center"
    }
});

class Flex extends PureComponent {
    render() {
        const {
            classes,
            children,
            className,
            align,
            justify,
            ...other
        } = this.props;

        const flexClassNames = classNames(
            classes.base,
            classes[`justify${capitalize(justify)}`],
            classes[`align${capitalize(align)}`],
            className
        );
        return (
            <div className={flexClassNames} {...other}>
                {children}
            </div>
        );
    }
}

Flex.propTypes = {
    justify: PropTypes.oneOf(["center", "start", "end", "between"]),
    align: PropTypes.oneOf(["center"])
};

Flex.defaultProps = {
    justify: "between",
    align: "center"
};

export default withStyles(styles)(Flex);
