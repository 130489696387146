import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import LeftPointIcon from "../icons/LeftPointIcon";
import RightPointIcon from "../icons/RightPointIcon";
import Typography from "@material-ui/core/Typography";
import classnames from "classnames";
import { isNumber } from "lodash";
import ButtonBase from "@material-ui/core/ButtonBase";
const styles = theme => ({
    root: {
        display: "flex",
        height: theme.spacing.inputHeight,
        backgroundColor: "white"
    },
    textField: {
        height: "42px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: `0 ${theme.spacing.gridMargin}`,
        borderStyle: "solid",
        borderBottomWidth: theme.spacing.normalStroke,
        borderTopWidth: theme.spacing.normalStroke,
        borderLeftStyle: "none",
        borderRightStyle: "none",
        borderColor: theme.palette.C_DB15
    },
    icon: {
        width: theme.spacing.inputHeight,
        height: theme.spacing.inputHeight,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    leftIcon: {
        borderColor: theme.palette.C_DB15,
        borderWidth: theme.spacing.normalStroke,
        borderStyle: "solid",
        borderBottomLeftRadius: theme.spacing.cornerRadius,
        borderTopLeftRadius: theme.spacing.cornerRadius,
        transition: "all 100ms",
        "&:hover": {
            borderColor: theme.palette.C_DB40
        },
        height: "44px"
    },
    rightIcon: {
        borderColor: theme.palette.C_DB15,
        borderWidth: theme.spacing.normalStroke,
        borderStyle: "solid",
        borderBottomRightRadius: theme.spacing.cornerRadius,
        borderTopRightRadius: theme.spacing.cornerRadius,
        "&:hover": {
            borderColor: theme.palette.C_DB40
        },
        transition: "all 100ms",
        height: "44px"
    },
    arrowIcon: {
        color: theme.palette.C_DB60,
        height: "11px"
    },
    text: {
        fontSize: theme.typography.F_S_N,
        fontWeight: 600
    }
});

const months = [
    "Januar",
    "Februar",
    "Mars",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Desember"
];
class MonthPicker extends Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        year: PropTypes.number.isRequired
    };

    static defaultProps = {
        year: new Date(Date.now()).getFullYear()
    };

    onChangeNext = () => {
        const { year, onChange } = this.props;
        const thisYear = parseInt(year);

        if (thisYear === new Date(Date.now()).getFullYear()) return;

        const nextYear = thisYear + 1;

        onChange(nextYear);
    };

    onChangePrev = () => {
        const { year, onChange } = this.props;
        const thisYear = parseInt(year);
        const prevYear = thisYear - 1;
        if (prevYear < 2018) return;

        onChange(prevYear);
    };

    render() {
        const { classes, year, className } = this.props;

        const combinedRootClasses = classnames(classes.root, className);

        const leftIconClasses = classnames(classes.icon, classes.leftIcon);
        const rightIconClasses = classnames(classes.icon, classes.rightIcon);

        const textValue = new Date([year]).getFullYear();
        return (
            <div className={combinedRootClasses}>
                <ButtonBase
                    disableRipple
                    onClick={this.onChangePrev}
                    className={leftIconClasses}
                >
                    <LeftPointIcon className={classes.arrowIcon} />
                </ButtonBase>

                <div className={classes.textField}>
                    <Typography className={classes.text} variant="body1">
                        {textValue}
                    </Typography>
                </div>

                <ButtonBase
                    disableRipple
                    onClick={this.onChangeNext}
                    className={rightIconClasses}
                >
                    <RightPointIcon className={classes.arrowIcon} />
                </ButtonBase>
            </div>
        );
    }
}

export default withStyles(styles)(MonthPicker);
