export const SIGN_IN_MODAL = "SIGN_IN_MODAL";
export const CREATE_DEPARTMENT_MODAL = "CREATE_DEPARTMENT_MODAL";
export const CREATE_KEY_PERFORMANCE_INDICATOR_MODAL =
    "CREATE_KEY_PERFORMANCE_INDICATOR_MODAL";
export const UPDATE_FULL_TIME_EQUIVALENT = "UPDATE_FULL_TIME_EQUIVALENT";
export const CREATE_FULL_TIME_EQUIVALENT = "CREATE_FULL_TIME_EQUIVALENT";
export const CREATE_CALCULATION_RULE = "CREATE_CALCULATION_RULE";
export const UPDATE_CALCULATION_RULE = "UPDATE_CALCULATION_RULE";
export const CREATE_NOTIFICATION_TARGET_GROUP =
    "CREATE_NOTIFICATION_TARGET_GROUP";
export const CONFIRM_DIALOG = "CONFIRM_DIALOG";
