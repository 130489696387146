import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 10 7.5">
            <path d="M9.17,0H2.92a.77.77,0,0,0-.67.37L0,3.75,2.25,7.12a.85.85,0,0,0,.67.38H9.17A.84.84,0,0,0,10,6.67V.83A.83.83,0,0,0,9.17,0ZM7.92,5.25l-.59.58-1.5-1.5-1.5,1.5-.58-.58,1.5-1.5-1.5-1.5.58-.58,1.5,1.5,1.5-1.5.59.58-1.5,1.5Z" />
        </SvgIcon>
    );
}
