import React, { Component } from "react";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { isFunction } from "lodash";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "../icons/SearchIcon";
import InputField from "../forms/text-fields/InputField";
import ButtonBase from "@material-ui/core/ButtonBase";
import BreadCrumbs from "../other/BreadCrumbs";

const styles = theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
        paddingRight: theme.spacing.gridMargin,
        paddingLeft: theme.spacing.gridMargin,
        height: theme.spacing.tableHeaderHeight,
        backgroundColor: theme.palette.C_W
    },
    searchField: {
        fontSize: 13,
        minWidth: "100px",
        maxWidth: "180px"
    },
    adornment: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    rightBar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    faded: {
        color: theme.palette.C_DB40,
        "&:hover": {
            color: theme.palette.C_AB
        },
        transition: "all 100ms"
    },
    titleRoot: {
        display: "flex"
    },
    divider: {
        color: theme.palette.C_DB40,
        marginLeft: "5px",
        marginRight: "9px"
    }
});

class TableHeader extends Component {
    static propTypes = {
        title: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
            .isRequired,
        onBreadCrumbsPress: PropTypes.func, //Will only be used if title is an array. Returns the title clicked
        onSearch: PropTypes.func,
        iconBar: PropTypes.object
    };
    static defaultProps = {
        iconBar: null
    };

    shouldComponentUpdate(nextProps) {
        return (
            nextProps.title !== this.props.title ||
            nextProps.onBreadCrumbsPress !== this.props.onBreadCrumbsPress ||
            nextProps.onSearch !== this.props.onSearch ||
            nextProps.searchValue !== this.props.searchValue ||
            nextProps.iconBar !== this.props.iconBar
        );
    }

    renderBreadCrumbs = (titleArr, onPress) => {
        const { classes } = this.props;
        if (titleArr.length !== 2) {
            throw new Error(
                "TableHeader does not support breadcrumbs of more than length 2"
            );
        }

        const componentArr = [];

        const fadedItem = (
            <Typography variant="display2" className={classes.faded}>
                {titleArr[0]}
            </Typography>
        );
        if (isFunction(onPress)) {
            componentArr.push(
                <ButtonBase onClick={onPress}>{fadedItem}</ButtonBase>
            );
        } else {
            componentArr.push(fadedItem);
        }
        componentArr.push(
            <Typography variant="display2" className={classes.divider}>
                {" / "}
            </Typography>
        );
        componentArr.push(
            <Typography variant="display2">{titleArr[1]}</Typography>
        );

        return componentArr;
    };

    render() {
        const {
            classes,
            title,
            onSearch,
            iconBar,
            onBreadCrumbsPress,
            searchValue
        } = this.props;

        return (
            <div className={classes.root}>
                <BreadCrumbs
                    title={title}
                    onBreadCrumbsPress={onBreadCrumbsPress}
                />
                <div className={classes.rightBar}>
                    {isFunction(onSearch) && (
                        <InputField
                            className={classes.searchField}
                            onChange={onSearch}
                            leftIcon={
                                <SearchIcon
                                    style={{ width: "15px", height: "15px" }}
                                />
                            }
                            value={searchValue}
                        />
                    )}
                    {iconBar}
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(TableHeader);
