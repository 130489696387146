import * as types from "../actionTypes";
import moment from "moment";

let possiblyStaleAuthObject = localStorage.getItem("auth");
if (possiblyStaleAuthObject) {
    possiblyStaleAuthObject = JSON.parse(possiblyStaleAuthObject);
}

export const initialAuthState = {
    user: {},
    accountVerification: {},
    personId: null,
    actionNeeded: {},
    hasValidatedToken: false,
    possibleUsers: [],
    possibleUsersLoaded: true,
    providerToken: possiblyStaleAuthObject
        ? possiblyStaleAuthObject.providerToken
        : null,
    firebaseToken: possiblyStaleAuthObject
        ? possiblyStaleAuthObject.firebaseToken
        : null,
    synchronizationStatus: {
        inProgress: null,
        lastSyncStart: moment(0),
        lastFailedSync: null,
        lastDeletedSync: moment(0),
        lastQuickSync: moment(0),
        lastUserAndWorkTypeSync: moment(0),
        lastFullSync: moment(0)
    }
};
export default function auth(state = initialAuthState, action) {
    switch (action.type) {
        case types.SET_AUTH:
            return {
                ...state,
                user: {
                    ...action.payload.user
                },
                firebaseToken: action.payload.firebaseToken,
                providerToken: action.payload.providerToken,
                personId: action.payload.personId,
                willUpdateRecent: action.payload.willUpdateRecent
            };
        case types.SET_VERIFY_ACCOUNT_STATUS:
            return {
                ...state,
                accountVerification: {
                    ...action.payload
                }
            };

        case types.SET_AUTH_ACTION_NEEDED: {
            return {
                ...state,
                actionNeeded: {
                    ...action.payload
                }
            };
        }
        case types.SET_POSSIBLE_USERS:
            return {
                ...state,
                possibleUsers: action.payload,
                possibleUsersLoaded: true
            };
        case types.REQUEST_POSSIBLE_CLIENTS:
            return {
                ...state,
                possibleUsersLoaded: false
            };
        case types.UPDATE_AUTH_USER: {
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload
                }
            };
        }
        case types.SET_SYNCHRONIZATION_DATA: {
            return {
                ...state,
                synchronizationStatus: {
                    ...state.synchronizationStatus,
                    ...action.payload
                }
            };
        }
        case types.SYNCHRONIZATION_FAILED: {
            return {
                ...state,
                synchronizatioonStatus: {
                    ...initialAuthState.synchronizationStatus
                }
            };
        }
        case types.SET_PROVIDER_TOKEN: {
            const newState = { ...state, providerToken: action.payload };
            localStorage.setItem("auth", JSON.stringify(newState));

            return newState;
        }

        case types.SET_LOGOUT:
            return { ...initialAuthState, hasValidatedToken: true };

        default:
            return state;
    }
}
