import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function MoreIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 15 3.75">
            <path d="M3.75,1.87A1.88,1.88,0,1,0,1.88,3.75,1.87,1.87,0,0,0,3.75,1.87Zm1.87,0A1.88,1.88,0,1,0,7.5,0,1.88,1.88,0,0,0,5.62,1.87Zm5.63,0A1.88,1.88,0,1,0,13.12,0,1.88,1.88,0,0,0,11.25,1.87Z" />
        </SvgIcon>
    );
}
