import { isString, isObject } from "lodash";

export const httpRequest = (
    url,
    method,
    body = {},
    options = {},
    withAuth = true
) => {
    // Type Check
    if (typeof url !== "string")
        throw new TypeError("httpRequest: URL must be a string.");
    if (typeof method !== "string")
        throw new TypeError("httpRequest: Method must be a string.");
    if (typeof body !== "object")
        throw new TypeError("httpRequest: Body must be an object.");
    if (typeof options !== "object")
        throw new TypeError("httpRequest: Options must be an object.");
    if (!method.match(/((post)|(patch)|(put)|(get)|(delete)|(head))/i))
        return console.error(
            `httpRequest: method ${method} is not a valid HTTP request.`
        );
    if (options.params && typeof options.params !== "object")
        throw new TypeError("httpRequest: option params must be an object.");
    if (options.headers && typeof options.headers !== "object")
        throw new TypeError("httpRequest: option headers must be an object.");

    // Determines the base fetch data.
    let fetchObj = {
        method: method,
        headers: {
            Pragma: "no-cache",
            "Cache-Control": "no-cache"
        }
    };

    // Determine if Body is an Object or FormData
    if (method.match(/((post)|(patch)|(put))/i)) {
        if (isObject(body)) {
            fetchObj.body = JSON.stringify(body);
            fetchObj.headers["Accept"] = "application/json";
            fetchObj.headers["Content-Type"] = "application/json";
        } else {
            fetchObj.body = body;
        }
    }

    // Check if XSRF Token exists and add to headers. This needs to change if using in isomorphic app to check if client exists.
    const xsrfToken = localStorage.getItem("xsrfToken");
    if (xsrfToken) fetchObj.headers["xsrfToken"] = xsrfToken;

    if (withAuth) {
        let auth = localStorage.getItem("auth");
        if (isString(auth)) auth = JSON.parse(auth);
        if (auth && auth.firebaseToken) {
            fetchObj.headers["Authorization"] = auth["firebaseToken"];
        }
    }

    // Determine if there are other headers
    if (options.headers) {
        Object.entries(options.headers).forEach(h => {
            if (options.headers[h[0]]) fetchObj.headers[h[0]] = h[1];
        });
        delete options.headers;
    }
    // Determine if there are query params in options. Add { params: { token: 'afwaf' }} to options to add query string params.
    if (options.params) {
        let queryParams = [];
        for (let p in options.params) {
            if (options.params[p])
                queryParams.push(
                    encodeURIComponent(p) +
                        "=" +
                        encodeURIComponent(options.params[p])
                );
        }
        url = `${url}?${queryParams.join("&")}`;
        delete options.params;
    }
    // This makes httpRequest flexible...Add anything extra that httpRequest doesn't automatically.
    if (options) fetchObj = Object.assign({}, fetchObj, options);

    // The HTTP Request

    return fetch(url, fetchObj).then(async response => {
        const contentType = response.headers.get("content-type");
        // Response is 2** Status, Grab json and return promise.
        if (!response.ok) {
            const err = await response.json();
            throw {
                displayMessage: err.displayMessage,
                statusCode: `${response.status}/${err.status}`,
                httpStatus: response.status
            };
        } else {
            return response.json();
        }
    });

    // When calling this method, it will return a promise which requires another then and catch callback.
};
export const assert = (bool, message) => {
    if (!bool) {
        throw new Error(`APIAssertionError: ${message}`);
    }
};
