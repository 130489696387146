import { createSelector } from "reselect";
import * as _ from "lodash";
import React from "react";
import CommentIcon from "../../common/icons/CommentIcon";
import * as utils from "../../utils/index";
import moment from "moment";
import * as companySelectors from "../../selectors/company";
import { userGroups } from "../../actionTypes";
import * as userSelectors from "selectors/user";
import { columnDescriptions } from "../../constants/strings";

const standardValuesColumns = [
    {
        label: "Navn",
        type: "name",
        id: "name"
    },
    {
        label: "Normal",
        type: "numeric",
        id: "normalHours",
        description: columnDescriptions.normalHours
    },
    {
        label: "Kunde",
        type: "numeric",
        id: "clientHours",
        description: columnDescriptions.clientHours
    },
    {
        label: "Intern",
        type: "numeric",
        id: "internalHours",
        description: columnDescriptions.internalHours
    },
    {
        label: "Ferie",
        type: "numeric",
        id: "vacationHours",
        description: columnDescriptions.vacationHours
    },
    {
        label: "Avsp.",
        type: "numeric",
        id: "timeOffHours",
        description: columnDescriptions.timeOffHours
    },
    {
        label: "Syk.",
        type: "numeric",
        id: "sickLeaveHours",
        description: columnDescriptions.sickLeaveHours
    },
    {
        label: "Perm.",
        type: "numeric",
        id: "vacatedHours",
        description: columnDescriptions.vacatedHours
    },
    {
        label: "Reg.",
        type: "numeric",
        id: "registeredHours",
        separator: true,
        description: columnDescriptions.registeredHours
    },
    {
        label: "Mer",
        type: "numeric",
        id: "extraHours",
        description: columnDescriptions.extraHours
    },
    {
        label: "Over",
        type: "numeric",
        id: "overtimeHours",
        description: columnDescriptions.overtimeHours
    },
    {
        label: "Tillegg",
        type: "numeric",
        id: "addition",
        description: columnDescriptions.addition
    },
    {
        label: "Minus",
        type: "numeric",
        id: "minusHours",
        description: columnDescriptions.minusHours
    },
    {
        label: "F.saldo",
        type: "numeric",
        id: "balanceVacationDays",
        affix: "d",
        description: columnDescriptions.balanceVacationDays
    },
    {
        label: "T.saldo",
        type: "numeric",
        id: "balanceHours",
        affix: "t",
        description: columnDescriptions.balanceHours
    },
    {
        label: "Utbetalt",
        type: "numeric",
        id: "withdrawnHours",
        description: columnDescriptions.withdrawnHours
    },
    {
        label: "FG",
        type: "numeric",
        id: "invoiceFactor",
        affix: "%",
        description: columnDescriptions.invoiceFactor
    },
    {
        label: "FGN",
        type: "numeric",
        id: "invoiceFactorNormal",
        affix: "%",
        description: columnDescriptions.invoiceFactorNormal
    },
    {
        label: "FGN-F",
        type: "numeric",
        id: "invoiceFactorNormalVacation",
        affix: "%",
        description: columnDescriptions.invoiceFactorNormalVacation
    },
    {
        label: "Omsetning",
        type: "numeric",
        id: "monthlyTurnover",
        affix: "",
        description: columnDescriptions.monthlyTurnover
    }
];

const getCurrentDepartment = (state, props) =>
    state.timebank.meta.currentDepartment;

export const getCurrentDate = (state, props) => state.timebank.meta.currentDate;

const getTimebankSearchString = (state, props) =>
    state.timebank.meta.searchString;

const getAuth = state => state.auth;
const getTimebankMonthly = state => state.timebank.monthly;
const getTimebankWeekly = state => state.timebank.weekly;
const getTimebankDaily = state => state.timebank.daily;

export const getHasStaleData = state => state.appState.hasStaleData;
const getCurrentOpenUser = state => state.timebank.meta.currentOpenUser;
export const getTimebankMeta = state => state.timebank.meta;

const getCustomKeyPerformanceIndicators = createSelector(
    companySelectors.getCurrentCompany,
    company => (company ? company.keyPerformanceIndicators : [])
);

export const getSignedInUserDepartment = createSelector(
    userSelectors.getSignedInUser,
    user => user.departmentId
);

export const getCurrentDepartmentOrDefault = createSelector(
    getCurrentDepartment,
    userSelectors.getFullSignedInUser,
    (departmentId, signedInUser) => {
        if (!departmentId) {
            if (signedInUser.userGroup === userGroups.ADMIN) {
                return null; // All deps
            } else {
                return signedInUser.departmentId;
            }
        }
        return departmentId;
    }
);
// Show only the departmentleader's department. If not found, set to blank to safeguard error.
export const getDepartmentLeaderDepartments = createSelector(
    [getSignedInUserDepartment, companySelectors.getCompanyDepartments],
    (userDepartment, departments) =>
        _.filter(departments, dep => userDepartment === dep.id)
);

export const getDepartmentsByAuth = createSelector(
    [
        userSelectors.getSignedInUser,
        companySelectors.getOrderedCompanyDepartmentsWithAll,
        getDepartmentLeaderDepartments
    ],
    (signedInUser, adminDepartments, depLeaderDepartments) => {
        return signedInUser.userGroup === userGroups.ADMIN
            ? adminDepartments
            : depLeaderDepartments;
    }
);

const getMonthlyUsers = createSelector(
    [getTimebankMonthly, getCurrentDate],
    (timebankMonthly, currentDate) => {
        const date = new Date(currentDate);
        const y = date.getFullYear(),
            m = date.getMonth();

        //Get item at path or return default empty object
        return _.get(timebankMonthly, [y, m, "users"], {});
    }
);
const getDailyHours = createSelector(
    [getTimebankDaily, getCurrentDate],
    (timebankDaily, currentDate) => {
        const date = new Date(currentDate);
        const y = date.getFullYear();
        //Get item at path or return default empty object
        return _.get(timebankDaily, [y], {});
    }
);

export const getViews = createSelector(
    companySelectors.getCurrentCompany,
    company => {
        const companyView = company ? company.view : {};

        const kpiViews = {};
        _.forEach(company && company.keyPerformanceIndicators, kpi => {
            kpiViews[kpi.id] = true;
        });

        const departmentViews = {};
        _.forEach(company && company.departments, dep => {
            departmentViews[dep.id] = dep.view || {};
        });

        return {
            companyView,
            departmentViews,
            kpiViews
        };
    }
);
export const getCurrentView = createSelector(
    [getViews, getCurrentDepartment],
    ({ companyView, departmentViews, kpiViews }, currentDepartment) => {
        return utils.mergeViews(
            companyView,
            departmentViews[currentDepartment],
            kpiViews
        );
    }
);

export const getUserView = createSelector(
    [getViews, userSelectors.getCurrentUser],
    ({ companyView, departmentViews, kpiViews }, currentUser) => {
        if (currentUser) {
            return utils.mergeViews(
                companyView,
                departmentViews[currentUser.departmentId],
                kpiViews
            );
        }
        return {};
    }
);

const getOvertimeAdditional = createSelector(
    companySelectors.getCurrentCompany,
    company => company && company.overtimeAdditional
);

const getUsersInDepartment = createSelector(
    [
        userSelectors.getUserItems,
        companySelectors.getCompanyId,
        getCurrentDepartment
    ],
    (users, companyId, currentDepartment) =>
        _.filter(users, user => {
            //Filter out users without a defined departmentId (should not happen) and users not within company

            if (!user.departmentId || !user.companyId === companyId)
                return false;
            // If all departments selected (null) or there is no currently selected department. Return all users in company
            if (currentDepartment === null || !currentDepartment) {
                return true;
            } else {
                //Return user if has departmentId is same as current department
                return user.departmentId === currentDepartment;
            }
        })
);

//Filter out users where their hours for current month is not defined
const getUsersWithHours = createSelector(
    [getUsersInDepartment, getMonthlyUsers],
    (usersInDep, monthlyUsers) =>
        _.filter(usersInDep, user => Boolean(monthlyUsers[user.id]))
);

const getUsersWithDailyHours = createSelector(
    [getUsersInDepartment, getDailyHours],
    (usersInDep, dailyHours) =>
        _.orderBy(_.filter(usersInDep, user => Boolean(dailyHours[user.id])), [
            "firstName",
            "lastName",
            ["desc", "desc"]
        ])
);

const formatTableChildren = (weeklyTimebank, currentMoment, userId) => {
    const weeks = _.get(
        weeklyTimebank,
        `${userId}.${currentMoment.year()}.${currentMoment.month()}`
    );
    return _.map(weeks, week => {
        return {
            ...week.week,
            name: `Uke ${week.week.week}`,
            tableChildren: _.map(week.days, day => ({
                ...day,
                name: utils.formatWeekdayDate(
                    moment(currentMoment).dayOfYear(parseInt(day.day, 10))
                )
            }))
        };
    });
};

export const getComputedTableRows = createSelector(
    [
        getUsersWithHours,
        getMonthlyUsers,
        getOvertimeAdditional,
        getTimebankSearchString,
        companySelectors.getCompanyId,
        getTimebankWeekly,
        getCurrentOpenUser,
        getCurrentDate,
        getCustomKeyPerformanceIndicators
    ],
    (
        usersWithHours,
        monthlyUsers,
        overtimeAdditional,
        searchString,
        companyId,
        weeklyTimebank,
        currentOpenUser,
        currentDate,
        customKeyPerformanceIndicators
    ) => {
        const currentMoment = moment(currentDate);

        //Total values for relevant columns
        const totals = {
            normalHours: 0,
            clientHours: 0,
            internalHours: 0,
            vacationHours: 0,
            timeOffHours: 0,
            sickLeaveHours: 0,
            vacatedHours: 0,
            extraHours: 0,
            addition: 0,
            registeredHours: 0,
            minusHours: 0,
            balanceVacationDays: 0,
            balanceHours: 0,
            withdrawnHours: 0,
            invoiceFactor: 0,
            overtimeHours: 0,
            invoiceFactorNormal: 0
        };

        _.forEach(customKeyPerformanceIndicators, kpi => {
            totals[kpi.id] = 0;
        });

        let invoicableUsers = 0;

        const timebankWithUserData = _.map(usersWithHours, (user, key) => {
            const fullName = `${user.firstName} ${user.lastName}`.trim();

            //Filter by search
            if (
                searchString &&
                fullName.toLowerCase().indexOf(searchString.toLowerCase()) < 0
            ) {
                return null;
            }

            //Dont include users without invoicefactor in average
            if (monthlyUsers[user.id].invoiceFactor !== undefined) {
                invoicableUsers += 1;
            }

            //Create totals
            _.forEach(monthlyUsers[user.id], (timebankEntry, key) => {
                if (totals[key] !== undefined) {
                    totals[key] += timebankEntry;
                }
            });

            // Add KPIs to each row and totals
            const keyPerformanceIndicatorsObjects =
                monthlyUsers[user.id].customKeyPerformanceIndicators;
            delete monthlyUsers[user.id].customKeyPerformanceIndicators;

            let keyPerformanceIndicators = {};

            _.forEach(keyPerformanceIndicatorsObjects, (timebankEntry, key) => {
                keyPerformanceIndicators[key] = timebankEntry;
                if (totals[key] !== undefined) {
                    totals[key] += timebankEntry;
                }
            });

            return {
                ...monthlyUsers[user.id],
                ...keyPerformanceIndicators,
                id: user.id,
                name: `${user.firstName} ${user.lastName}`.trim(),
                nameValue: {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    id: user.id,
                    nameLink: `/dashboard/${companyId}/users/${user.id}`
                },
                commentValue: monthlyUsers[user.id].comment,
                comment: (
                    <CommentIcon
                        active={Boolean(monthlyUsers[user.id].comment)}
                    />
                ),
                tableChildren: formatTableChildren(
                    weeklyTimebank,
                    currentMoment,
                    user.id
                )
            };
        });

        //Round totals and add as last entry
        _.forEach(totals, (val, key) => {
            totals[key] = _.round(val, 6); // Round totals to 6 decimals
            if (
                key === "invoiceFactor" ||
                key === "invoiceFactorNormal" ||
                key === "invoiceFactorNormalVacation"
            ) {
                //Calculate percentage and round to 0 decimals
                const totalInvoice = _.round(val / invoicableUsers, 0);

                totals[key] = !isNaN(totalInvoice) ? totalInvoice : undefined;
            }
        });

        const filteredTimebank = _.filter(timebankWithUserData);

        return {
            timebank: filteredTimebank,
            totals: filteredTimebank.length > 0 ? totals : null
        };
    }
);

const getKeyFigures = createSelector(
    [getTimebankMonthly, getCurrentDate],
    (timebank, d) => {
        const date = new Date(d);
        const y = date.getFullYear();
        const m = date.getMonth();
        return timebank[y] && timebank[y][m] && timebank[y][m]["keyFigures"];
    }
);
const getFilteredCompanyKeyFigures = createSelector(
    getCurrentView,
    getKeyFigures,
    (view, keyFigures) =>
        view
            ? _.filter(keyFigures, (keyFigure, key) => view[key] !== false)
            : keyFigures // Filter keyfigures from view
);
export const getFilteredCompanyKeyFiguresWithLimit = createSelector(
    getFilteredCompanyKeyFigures,
    keyFigures => {
        if (keyFigures.length > 5) {
            return keyFigures.slice(0, 5);
        }
        return keyFigures;
    }
);
export const getDailyComputedTableRows = createSelector(
    [
        getUsersWithDailyHours,
        getDailyHours,
        getTimebankSearchString,
        companySelectors.getCompanyId,
        getCurrentDate
    ],
    (usersWithHours, dailyHours, searchString, companyId, currentDate) => {
        const timebankWithUserData = _.map(usersWithHours, (user, i) => {
            const fullName = `${user.firstName} ${user.lastName}`.trim();

            //Filter by search
            if (
                searchString &&
                fullName.toLowerCase().indexOf(searchString.toLowerCase()) < 0
            ) {
                return null;
            }

            return {
                days: dailyHours[user.id],
                id: user.id,
                fullName,
                firstName: user.firstName,
                lastName: user.lastName,
                nameLink: `/dashboard/${companyId}/users/${user.id}`
            };
        });

        return _.filter(timebankWithUserData);
    }
);

const getAllColumns = createSelector(
    companySelectors.getCurrentCompany,
    company => {
        const keyPerformanceIndicators = company
            ? company.keyPerformanceIndicators
            : [];
        let standardColumns = [...standardValuesColumns];

        _.forEach(keyPerformanceIndicators, kpi => {
            standardColumns.push({
                label: kpi.name,
                type: "numeric",
                id: kpi.id
            });
        });

        return standardColumns;
    }
);

export const getFilteredColumnsByView = createSelector(
    [getCurrentView, getAllColumns],
    (view, allColumns) => utils.filterTableColumns(view, allColumns)
);
