import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Timebank from "../containers/dashboard";
import UserTimebank from "../containers/dashboard/user";
import Users from "../containers/users";
import UserDetails from "../containers/users/userDetails/";
import Settings from "../containers/settings";
import { SideBar, Login } from "./";
import { Switch, Route } from "react-router-dom";
import Authenticated from "../common/auth/Authenticated";
import { userGroups } from "../actionTypes";
import Department from "../containers/settings/department";

const styles = theme => ({
    root: {
        paddingLeft: theme.spacing.sidebarWidth,
        height: "100%",
        backgroundColor: theme.palette.C_DB5
    }
});

class ContentView extends Component {
    render() {
        const { classes } = this.props;

        return (
            <Authenticated
                userGroups={[
                    userGroups.USER,
                    userGroups.DEPARTMENT_LEADER,
                    userGroups.ADMIN
                ]}
                fullPage
            >
                <SideBar />

                <div
                    className={classes.root}
                    style={{ minHeight: window.innerHeight - 60 }}
                >
                    <Switch>
                        <Route path="/login" component={Login} />
                        <Route
                            exact
                            path="/dashboard/:companyId/"
                            component={Timebank}
                        />
                        <Route
                            exact
                            path="/users/:companyId/"
                            component={Users}
                        />
                        <Route
                            exact
                            path="/users/:companyId/user/:userId/"
                            component={UserDetails}
                        />
                        <Route
                            path="/settings/:companyId/"
                            component={Settings}
                        />
                        /*<Route
                            exact
                            path="/company/:companyId/department/:departmentId"
                            component={Department}
                        />*/
                        <Route
                            exact
                            path="/dashboard/:companyId/users/:userId/"
                            component={UserTimebank}
                        />
                    </Switch>
                </div>
            </Authenticated>
        );
    }
}

export default withStyles(styles)(ContentView);
