import * as actions from "../../actionTypes";
import * as _ from "lodash";

const initialKeyPerformanceIndicatorsState = {
    keyPerformanceIndicators: [],
    metaData: {}
};

export default function keyPerformanceIndicators(
    state = initialKeyPerformanceIndicatorsState,
    action
) {
    switch (action.type) {
        case actions.SET_KEY_PERFORMANCE_INDICATORS: {
            return {
                ...state,
                keyPerformanceIndicators: action.payload
            };
        }
        case actions.UPDATE_KEY_PERFORMANCE_INDICATOR: {
            return {
                ...state,
                keyPerformanceIndicators: _.map(
                    state.keyPerformanceIndicators,
                    kpi => {
                        if (
                            kpi.id === action.payload.keyPerformanceIndicatorId
                        ) {
                            return {
                                ...kpi,
                                ...action.payload.payload
                            };
                        }
                        return kpi;
                    }
                )
            };
        }
        case actions.SET_NEW_KEY_PERFORMANCE_INDICATOR: {
            return {
                ...state,
                keyPerformanceIndicators: [
                    ...state.keyPerformanceIndicators,
                    action.payload
                ]
            };
        }
        case actions.DELETE_KEY_PERFORMANCE_INDICATOR: {
            return {
                ...state,
                keyPerformanceIndicators: _.filter(
                    state.keyPerformanceIndicators,
                    kpi => kpi.id !== action.payload.keyPerformanceIndicatorId
                )
            };
        }
        case actions.SET_KEY_PERFORMANCE_INDICATORS_META_DATA: {
            return {
                ...state,
                metaData: action.payload
            };
        }
        default:
            return state;
    }
}
