import { formatDate } from "./";
import * as actions from "../actionTypes";

export const userEvents = {
    userGroup: (regarding, userGroup) => {
        let userGroupName = "";
        if (userGroup === 1) {
            userGroupName = "Standard";
        } else if (userGroup === 2) {
            userGroupName = "Avdelingsleder";
        } else if (userGroup === 3) {
            userGroupName = "Admin";
        } else {
            throw new Error("Invalid usergroup when creating event message");
        }

        return `Brukergruppe satt til ${userGroupName}`;
    },
    fullTimeEquivalent: (regarding, fullTimeEquivalent) => {
        return `${regarding} - Stillingsprosent satt til ${fullTimeEquivalent}%`;
    },

    hasOverTime: (regarding = "", hasOverTime) => {
        if (hasOverTime === true) {
            return `${regarding} - Overtid slått på`;
        } else if (hasOverTime === false) {
            return `${regarding} - Overtid slått av`;
        }
    },
    startDate: (regarding = "", startDate = "") => {
        return `${regarding} - Startdato satt til ${formatDate(startDate)}`;
    },
    endDate: (regarding = "", endDate = "") => {
        return `${regarding} - Sluttdato satt til ${formatDate(endDate)}`;
    },
    departmentId: (regarding = "", departmentName = "") => {
        return `${regarding} - Avdeling satt til ${departmentName}`;
    },
    email: (regarding = "", email = "") => {
        return `${regarding} - Epost satt til ${email}`;
    }
};

export const tutorialsText = {
    timeReport: {
        title: "Timerapport",
        alt: "Et eksempel på timerapport for et selskap.",
        filetype: "img",
        headerText: "Automatisk beregning",
        subheaderText:
            "I timerapporten får du automatisk beregning av ansattes timedata. Alle timer registreres kun én gang i 24SO, hvor de hentes og kategoriseres i Busy. Se beregnet normaltid, mertid, overtid, minustid og mer."
    },
    timeControl: {
        title: "Timekontroll",
        alt: "Et eksempel på timekontroll for et selskap.",
        filetype: "img",

        headerText: "Et raskt overblikk",
        subheaderText:
            "I timekontrollen får du et raskt overblikk på at alle timene kommer med. Ruter som viser rødt indikerer timer ført under normaltid, grønt indikerer timer ført akkurat på normaltid og blått indikerer timer ført over normaltid."
    },
    users: {
        title: "Brukere",
        alt: "Et eksempel på en brukerside for et selskap.",
        filetype: "img",
        headerText: "Administrer ansatte",
        subheaderText:
            "På brukersiden kan du gjøre justeringer tilknyttet ansattes arbeidsforhold. Trykk på en av de ansatte i oversikten og rediger avdeling, rettighetsnivå, startdato, overtid og stillingsprosent."
    },
    settings: {
        title: "Innstillinger",
        alt: "Et eksempel av innstillinger for et selskap.",
        filetype: "img",
        headerText: "Tilpasset internrutiner",
        subheaderText:
            "I innstillingene kan du gjøre endringer tilpasset virksomhetens internrutiner. Rediger hva som skal vises, hvordan logikk som skal gjelde og opprett spesialtilpassede regler for fridager, halvdager, heldager eller periodebasert sommertid."
    }
};

const eventTypes = {
    CREATE: "CREATE",
    DELETE: "DELETE",
    UPDATE: "UPDATE"
};

const userGroupConversions = {
    USER: "ansatt",
    DEPARTMENT_LEADER: "avdelingsleder",
    DEPARTMENT_LEADER_AND_ADMIN: "admin og avd. leder",
    ADMIN: "administrator"
};

/** 
 * Godkjent utbetaling av xx timer
Forespør utbetaling av xx timer
Rettighetsnivå endret til xx
Stillingsprosent (dato) endret til xx
Overtid endret til xx
Avdeling endret til xx
Startdato endret til xx
Sluttdato endret til xx
Vis timer endret til xx
Tittel endret til xx
Inngående feriesaldo for (år) endret til xx dager
Inngående timesaldo for (år) endret til xx timer

*/

export const analytics = {
    categories: {
        NOTIFICATION: "Notification",
        TABLE_EXPORT: "Table export",
        USER_DASHBOARD: "User dashboard",
        DASHBOARD: "Dashboard",
        USERS: "Users"
    },
    actions: {
        MARK_ALL_NOTIFICATIONS: "Mark all notifications",
        MARK_NOTIFICATION: "Mark single notification",
        NOTIFICATION_CLICK: "Notification click",
        EXPORT_EXCEL: "Export excel",
        EXPORT_PDF: "Export PDF",
        EXPORT_CSV: "Export CSV",
        SORT_TABLE_COLUMNS: "Sort table columns",
        ADD_DASHBOARD_COMMENT: "Add dashboard comment",
        SEARCH_DASHBOARD: "Search dashboard",
        SEARCH_USERS: "Search users",
        SET_DISPLAY_TYPE: "Set display type",
        REQUEST_USER_PAYOUT: "Payout requested",
        SET_USER_PAYOUT: "Payout confirmed",
        SET_USER_PAYOUT_FROM_NOT: "Payout confirmed from notification",
        OPEN_TABLE_ROW: "Table row opened"
    }
};
export const userDetailEvents = {
    userAuthorization: userAuthorization => {
        return `Rettighetsnivå endret til ${
            userGroupConversions[userAuthorization]
        }`;
    },
    fullTimeEquivalent: (fullTimeEquivalent, updateAction) => {
        if (updateAction === eventTypes.UPDATE) {
            return `Stillingsprosent ${formatDate(
                fullTimeEquivalent.fromDate
            )} endret til ${fullTimeEquivalent.value}%`;
        } else if (updateAction === eventTypes.CREATE) {
            return `Stillingsprosent ${formatDate(
                fullTimeEquivalent.fromDate
            )} satt til ${fullTimeEquivalent.value}%`;
        } else if (updateAction === eventTypes.DELETE) {
            if (!fullTimeEquivalent) return "Stillingsprosent slettet";
            return `Stillingsprosent ${formatDate(
                fullTimeEquivalent.fromDate
            )} på ${fullTimeEquivalent.value}% slettet`;
        } else {
            return `Stillingsprosent ${formatDate(
                fullTimeEquivalent.fromDate
            )} endret til ${fullTimeEquivalent.value}%`;
        }
    },

    hasOverTime: hasOverTime => {
        if (hasOverTime === true) {
            return "Overtid endret til aktivert";
        } else if (hasOverTime === false) {
            return "Overtid endret til deaktivert";
        }
    },
    startDate: (startDate = "") => {
        return `Startdato endret til ${formatDate(startDate)}`;
    },
    endDate: (endDate = "") => {
        return `Sluttdato endret til ${formatDate(endDate)}`;
    },
    departmentId: (departmentName = "(Slettet)") => {
        return `Avdeling endret til ${departmentName}`;
    },
    showInDashboard: value => {
        if (value === true) {
            return "Vis timer endret til aktivert";
        } else {
            return "Vis timer endret til deaktivert";
        }
    },
    userTitle: (value = "") => {
        return `Tittel endret til ${value}`;
    },
    requestedPayout: (value = { amount: "" }) => {
        return `Forespør utbetaling av ${value.amount} timer`;
    },
    approvePayout: (value = { amount: "" }) => {
        return `Godkjent utbetaling av ${value.amount} timer`;
    },
    fixedHours: (value = { year: "", value: "" }) => {
        return `Inngående timesaldo for ${value.year} endret til ${
            value.value
        } timer`;
    },
    fixedVacationBalance: (value = { year: "", value: "" }) => {
        return `Inngående feriesaldo for ${value.year} endret til ${
            value.value
        } dager`;
    },
    isActive: value => {
        if (value) {
            return "Bruker endret til aktiv";
        } else {
            return "Bruker endret til inaktiv";
        }
    },
    email: (email = "") => {
        return `Epost satt til ${email}`;
    }
};

export const updateCompleteStrings = {
    [actions.REQUEST_SET_USER_PAYOUT]: "Utbetaling er utført.",
    [actions.REQUEST_SET_INCOMING_VACATION_BALANCE]:
        "Inngående feriesaldo er endret.",
    [actions.REQUEST_SET_INCOMING_BALANCE]: "Inngående timesaldo er endret.",
    [actions.USER_REQUEST_PAYOUT]: "Forespørsel er sendt.",
    [actions.REQUEST_UPDATE_CALCULATION_RULE]: "Regel er endret.",
    [actions.REQUEST_CREATE_CALCULATION_RULE]: "Regel er opprettet.",
    [actions.REQUEST_DELETE_CALCULATION_RULE]: "Regel er slettet.",
    [actions.REQUEST_CREATE_DEPARTMENT]: "Avdeling er opprettet.",
    [actions.REQUEST_UPDATE_DEPARTMENT]: "Avdeling er endret",
    [actions.REQUEST_DELETE_DEPARTMENT]: "Avdeling er slettet",
    [actions.REQUEST_CREATE_NOTIFICATION_TARGET_GROUP]: "Varsel er opprettet",
    [actions.REQUEST_UPDATE_NOTIFICATION_TARGET_GROUP]: "Varsel er endret",
    [actions.REQUEST_DELETE_NOTIFICATION_TARGET_GROUP]: "Varsel er slettet.",
    [actions.UPDATE_WORKTYPE_CATEGORIES]: "Arbeidstype(r) er kategorisert",
    [actions.REQUEST_CREATE_FULL_TIME_EQUIVALENT]:
        "Stillingsprosent er opprettet",
    [actions.REQUEST_UPDATE_FULL_TIME_EQUIVALENT]:
        "Stillingsprosent er endret.",
    [actions.REQUEST_DELETE_FULL_TIME_EQUIVALENT]:
        "Stillingsprosent er slettet.",
    [actions.REQUEST_UPDATE_USER]: "Bruker er endret.",
    [actions.REQUEST_UPDATE_MULT_USERS]: "Brukere er endret.",
    [actions.REQUEST_UPDATE_COMPANY_RULES]:
        "Grunnlag for utregninger er endret."
};
/**
 * DATES
 */

export const weekdayShort = ["Man", "Tir", "Ons", "Tor", "Fre", "Lør", "Søn"];
export const shortLowerMonthNames = [
    "jan",
    "feb",
    "mar",
    "apr",
    "mai",
    "jun",
    "jul",
    "aug",
    "sep",
    "okt",
    "nov",
    "des"
];
export const fullMonthNames = [
    "januar",
    "februar",
    "mars",
    "april",
    "mai",
    "juni",
    "juli",
    "august",
    "september",
    "oktober",
    "november",
    "desember"
];
