import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';
export default function (props) {
    return (
        <SvgIcon {...props} viewBox='0 0 18.75 25'>
            <path d='M18.06,4.78,14,.69A2.33,2.33,0,0,0,12.31,0h-10A2.35,2.35,0,0,0,0,2.35V22.66A2.34,2.34,0,0,0,2.34,25H16.4a2.35,2.35,0,0,0,2.35-2.34V6.44A2.36,2.36,0,0,0,18.06,4.78ZM16.21,6.25H12.5V2.54ZM2.34,22.66V2.35h7.81V7.43A1.18,1.18,0,0,0,11.33,8.6H16.4V22.66ZM12.69,10.94h-1.4a.6.6,0,0,0-.52.31,26.51,26.51,0,0,0-1.39,2.81C8.7,12.64,9,13.22,8,11.25a.59.59,0,0,0-.52-.31H6.05a.58.58,0,0,0-.5.88l2.26,3.81-2.26,3.8a.58.58,0,0,0,.5.88H7.46A.59.59,0,0,0,8,20a24.51,24.51,0,0,0,1.39-2.82c.73,1.47.29.78,1.4,2.82a.58.58,0,0,0,.52.3h1.4a.58.58,0,0,0,.51-.88l-2.26-3.8,2.26-3.81A.59.59,0,0,0,12.69,10.94Z'
            />
        </SvgIcon>

    )
}
