import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isArray, forEach, isEmpty } from "lodash";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { REQUEST_AUTH_UPDATE, userGroups } from "../../actionTypes";

const VALID_GROUPS = [
    userGroups.USER,
    userGroups.DEPARTMENT_LEADER,
    userGroups.ADMIN
];

class Authenticated extends Component {
    static propTypes = {
        fullPage: PropTypes.bool,
        userGroups: PropTypes.arrayOf(PropTypes.oneOf(VALID_GROUPS)).isRequired
    };

    static defaultProps = {
        fullPage: false
    };

    componentWillMount() {
        const { auth, dispatch } = this.props;
        if ((!auth.user || isEmpty(auth.user)) && !auth.hasValidatedToken) {
            dispatch({ type: REQUEST_AUTH_UPDATE });
        }
    }

    isValidProps = userGroups => {
        const { auth, match } = this.props;

        if (auth.user.companyId !== match.params.companyId) return false;

        if (
            !isArray(userGroups) ||
            userGroups.length > 4 ||
            userGroups.length === 0
        ) {
            return false;
        }

        forEach(userGroups, group => {
            if (VALID_GROUPS.indexOf(group) < 0) {
                return false;
            }
        });
        return true;
    };

    render() {
        const {
            userGroups,
            auth,
            children,
            store,
            dispatch,
            storeSubscription,
            fullPage,
            staticContext,
            ...childProps
        } = this.props;

        if (!auth.user || isEmpty(auth.user)) {
            if (auth.hasValidatedToken) {
                return <Redirect to={"/login"} />;
            }
            return null;
        } else if (
            !this.isValidProps(userGroups) ||
            userGroups.indexOf(auth.user.userGroup) < 0
        ) {
            if (fullPage && !isEmpty(auth.user)) {
                return <Redirect to={"/login"} />;
            }
            return null;
        }

        return React.Children.map(children, child =>
            React.cloneElement(child, childProps)
        );
    }
}

export default withRouter(
    connect(store => ({
        auth: store.auth
    }))(Authenticated)
);
