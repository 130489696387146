import classNames from "classnames";
import { forEach } from "lodash";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import DefaultList from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";

const styles = theme => ({
    root: {
        ...theme.gridItemBorder,
        paddingTop: 0
    },
    listRoot: {
        height: theme.spacing.maxGridRowHeight,
        overflowY: "auto",
        paddingTop: "0"
    },
    listItem: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: theme.spacing.listHeight,
        paddingLeft: theme.spacing.gridMargin,
        paddingRight: theme.spacing.gridMargin,
        "&:first-child": {
            paddingTop: "4px"
        }
    },

    titleRoot: {
        height: theme.spacing.tableHeaderHeight,
        backgroundColor: theme.palette.C_DB8,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: theme.spacing.gridMargin,
        paddingRight: theme.spacing.gridMargin
    },
    titleIcon: {
        color: theme.palette.C_DB40,
        height: "25px",
        width: "25px",
        "&:hover": {
            color: theme.palette.C_AB
        },
        transition: "all 100ms"
    },
    titleIconButton: {
        width: "30px",
        height: "30px"
    }
});

/*

 return (
 <ListItem className={classes.listItem}>
 {renderItem(item)}
 </ListItem>
 )
 */

class List extends Component {
    shouldComponentUpdate(nextProps) {
        return (
            nextProps.data !== this.props.data ||
            nextProps.title !== this.props.title ||
            nextProps.footer !== this.props.footer ||
            nextProps.placeholder !== this.props.placeholder ||
            nextProps.listRootStyles !== this.props.listRootStyles ||
            nextProps.data !== this.props.data ||
            nextProps.data !== this.props.data ||
            nextProps.listRootClassName !== this.props.listRootClassName
        );
    }

    render() {
        const {
            classes,
            data,
            title,
            titleIcon,
            onTitleIconClick,
            footer,
            placeholder,
            renderItem,
            listRootStyles,
            listRootClassName
        } = this.props;

        const eventList = [];
        let count = 0;
        forEach(data, (item, key) => {
            if (count !== 0) {
                eventList.push(<Divider light key={key + "-divider"} />);
            }
            eventList.push(renderItem(item, key));
            count++;
        });

        const titleIconView = titleIcon ? (
            onTitleIconClick ? (
                <IconButton
                    disableRipple
                    onClick={onTitleIconClick}
                    className={classes.titleIconButton}
                >
                    {React.cloneElement(titleIcon, {
                        className: classes.titleIcon
                    })}
                </IconButton>
            ) : (
                React.cloneElement(titleIcon, { className: classes.titleIcon })
            )
        ) : null;

        const header = title ? (
            <div className={classes.titleRoot}>
                <Typography variant="display2">{title}</Typography>
                {titleIconView}
            </div>
        ) : null;

        const content =
            placeholder && data.length === 0 ? (
                <DefaultList
                    className={classNames(classes.listRoot, listRootClassName)}
                    styles={listRootStyles}
                >
                    {
                        <ListItem className={classes.listItem}>
                            <Typography variant="body1">
                                {placeholder}
                            </Typography>
                        </ListItem>
                    }
                </DefaultList>
            ) : (
                <DefaultList
                    className={classNames(classes.listRoot, listRootClassName)}
                >
                    {eventList}
                </DefaultList>
            );

        return (
            <Paper className={classes.root} elevation={0}>
                {header}
                {content}
                {footer}
            </Paper>
        );
    }
}

List.propTypes = {
    data: PropTypes.array,
    title: PropTypes.string,
    titleIcon: PropTypes.node,
    onTitleIconClick: PropTypes.func,
    footer: PropTypes.node,
    placeholder: PropTypes.string,
    renderItem: PropTypes.func.isRequired
};

List.defaultProps = {
    data: [],
    title: "",
    titleIcon: null
};
export default withStyles(styles)(List);
