import { createSelector } from "reselect";
import * as _ from "lodash";
import * as userSelectors from "./user";

export const getCompanyId = createSelector(
    userSelectors.getSignedInUser,
    user => user.companyId
);
export const getCurrentCompany = createSelector(
    state => state.companies,
    getCompanyId,
    (companies, companyId) => companies[companyId] || {}
);

export const getCompanyName = createSelector(
    getCurrentCompany,
    company => (company ? company.name : "")
);
export const getCompanyDepartments = createSelector(
    getCurrentCompany,
    company => _.values(company.departments)
);
export const getCompanyWorkTypes = createSelector(getCurrentCompany, company =>
    _.map(company.workTypes, (wt, id) => ({ ...wt, id }))
);
export const getOrderedCompanyWorkTypes = createSelector(
    getCompanyWorkTypes,
    workTypes => _.orderBy(workTypes, "name", "asc")
);

export const getOrderedCompanyDepartments = createSelector(
    getCompanyDepartments,
    departments => _.orderBy(departments, department => department.name)
);
/**
 * Returns departments without default "Unspesified". Is returned ordered by name
 */
export const getCompanyDepartmentsWithoutDefault = createSelector(
    getOrderedCompanyDepartments,
    departments => _.filter(departments, dep => dep.id !== "0")
);

export const getOrderedCompanyDepartmentsWithAll = createSelector(
    getOrderedCompanyDepartments,
    departments => _.concat({ name: "Alle avdelinger", id: null }, departments)
);

export const getCreatedCompanyDepartmentWithAll = createSelector(
    getOrderedCompanyDepartmentsWithAll,
    departments => _.filter(departments, department => department.id !== "0")
);

export const getKeyPerformanceIndicators = createSelector(
    getCurrentCompany,
    company => (company ? company.keyPerformanceIndicators : [])
);
