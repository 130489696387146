import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import InputField from "../../../common/forms/text-fields/InputField";

const styles = theme => ({
    root: {
        width: "100%",
        background: theme.palette.C_W,
        border: `solid 1px ${theme.palette.C_DB10}`,
        height: "35px",
        padding: "8px 20px",
        borderRadius: "30px",
        boxSizing: "border-box",
        color: theme.palette.C_AB,
        fontSize: theme.typography.F_N,
        "&:hover": {
            color: theme.palette.C_LB
        }
    },
    disabled: {
        border: `solid 1px ${theme.palette.C_DB10}`,
        color: theme.palette.C_DB40
    }
});

class RoundLabelContainer extends Component {
    constructor(props) {
        super(props);
    }

    handleUpdateConstant = e => {
        this.props.handleUpdateConstant(this.props.id, e.target.value);
    };

    render() {
        const { value } = this.props;

        return (
            <InputField value={value} onChange={this.handleUpdateConstant} />
        );
    }
}

export default withStyles(styles)(RoundLabelContainer);
