import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import React from "react";
import { Arrow, Manager, Popper as DefaultPopper, Target } from "react-popper";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import RootRef from "@material-ui/core/RootRef";
import { capitalize } from "@material-ui/core/utils/helpers";
import ArrowIcon from "./PopperArrow";
import classNames from "classnames";
import Backdrop from "@material-ui/core/Backdrop";

const styles = theme => ({
    arrow: {
        width: "20px",
        height: "20px",
        color: theme.palette.C_W,
        zIndex: 1,
        pointerEvents: "none"
    },
    arrowSmall: {
        width: "14px",
        height: "14px"
    },
    arrowIconRight: {
        transform: "rotate(-90deg)"
    },
    arrowIconLeft: {
        transform: "rotate(90deg)"
    },
    arrowTop: {
        paddingBottom: "5px"
    },
    arrowBottom: {
        top: "-16px"
    },
    arrowRight: {
        left: "-16px"
    },
    arrowLeft: {
        right: "-13px"
    },
    arrowRightSmall: {
        left: "-9px"
    },
    backdrop: {
        zIndex: 100
    }
});

class Popper extends React.Component {
    state = {
        forcePopperUpdateNounce: 0
    };
    componentDidUpdate(prevProps) {
        if (
            this.defaultPopper &&
            prevProps.targetRef !== this.props.targetRef
        ) {
            // Force update through inner popper methods
            //this.defaultPopper._destroyPopper();
            //this.defaultPopper._createPopper();
        }
    }
    // Checks whether the click contains the target, then propagates the event.
    // It is expected that parent will handle closing if the target is pressed.
    handleClickAway = event => {
        if (this.target && !this.target.contains(event.target)) {
            this.props.onClickAway(event);
        }
    };

    renderArrow = actualPlacement => {
        // Creates a span to position the svg icon. Then adds styling to apply correct margin between popup and target.
        const { classes, arrowClassName, arrowStyles, arrowSize } = this.props;
        return (
            <Arrow>
                {({ arrowProps }) => {
                    return (
                        <RootRef
                            rootRef={node => {
                                arrowProps.ref(node);
                            }}
                        >
                            <span
                                style={{
                                    ...arrowProps.style,
                                    position: "absolute",
                                    ...arrowStyles
                                }}
                                className={classNames(
                                    classes.arrow,
                                    classes[
                                        `arrow${capitalize(actualPlacement)}`
                                    ],
                                    classes[
                                        `arrow${capitalize(
                                            actualPlacement
                                        )}${capitalize(arrowSize)}`
                                    ],
                                    arrowClassName
                                )}
                            >
                                <this.props.ArrowComponent
                                    className={classNames(
                                        classes[
                                            `arrowIcon${capitalize(
                                                actualPlacement
                                            )}`
                                        ],
                                        classes[`arrow${capitalize(arrowSize)}`]
                                    )}
                                />
                            </span>
                        </RootRef>
                    );
                }}
            </Arrow>
        );
    };

    calculateOffset = () => {
        if (this.props.offset) return this.props.offset + "px";
        if (this.props.arrow) {
            return this.props.arrowSize === "default" ? 15 : 8;
        }
    };
    renderPopper = ({ popperProps, restProps }) => {
        const { children, placement, open, arrow, popperStyle } = this.props;

        // Finds the current placement (can be moved to different placement if flip is enabled.
        const actualPlacement = (
            popperProps["data-placement"] || placement
        ).split("-")[0];

        // Renders a container containing the popup and arrow.

        const offset = this.calculateOffset();
        const topOffset = actualPlacement === "bottom" ? offset : undefined;

        let leftOffset = actualPlacement === "left" ? -offset : undefined;

        leftOffset = actualPlacement === "right" ? offset : leftOffset;

        return (
            <div
                {...popperProps}
                {...restProps}
                style={{
                    ...popperProps.style,
                    top: topOffset ? topOffset : popperProps.style.top || 0,
                    left: leftOffset ? leftOffset : popperProps.style.left || 0,
                    ...restProps.style,
                    position: "absolute",
                    zIndex: 10000,
                    ...popperStyle
                }}
            >
                {arrow ? this.renderArrow(actualPlacement) : null}

                <ClickAwayListener
                    onClickAway={this.handleClickAway}
                    mouseEvent={"onMouseDown"}
                >
                    <Grow
                        in={open}
                        id="menu-list-grow"
                        style={{ transformOrigin: "0 0 0" }}
                        timeout={0}
                    >
                        {children}
                    </Grow>
                </ClickAwayListener>
            </div>
        );
    };

    render() {
        const {
            target,
            open,
            placement,
            preventOverflow,
            flip,
            targetRef,
            arrow,
            backdrop,
            classes,
            boundariesElement
        } = this.props;

        if (!target && !targetRef) return null;

        if (targetRef) {
            this.target = targetRef;
        }

        return (
            <Manager tag={false}>
                {!targetRef ? (
                    <Target component={false}>
                        {(
                            { targetProps } // Sets the target in popper from either the supplied ref or a root ref of target.
                        ) => {
                            targetRef && targetProps.ref(targetRef);
                            return (
                                <RootRef
                                    rootRef={node => {
                                        this.target = targetRef || node;

                                        targetProps.ref(this.target);
                                    }}
                                >
                                    {target || null}
                                </RootRef>
                            );
                        }}
                    </Target>
                ) : null}
                <DefaultPopper
                    placement={placement}
                    eventsEnabled={open}
                    target={targetRef}
                    ref={r => (this.defaultPopper = r)}
                    modifiers={{
                        flip: { enabled: flip }, // Whether the placement can change during overflow
                        preventOverflow: {
                            enabled: preventOverflow,
                            boundariesElement: boundariesElement
                                ? boundariesElement
                                : undefined
                        }, // Whether the popper should stay in viewport
                        arrow: { enabled: arrow },
                        keepTogether: { enabled: arrow },
                        hide: { enabled: false } // To prevent warnings
                    }}
                >
                    {open ? this.renderPopper : null}
                </DefaultPopper>
                {open && backdrop ? (
                    <Backdrop
                        open={true}
                        invisible={true}
                        className={classes.backdrop}
                    />
                ) : null}
            </Manager>
        );
    }
}

Popper.propTypes = {
    /**
     * A function which is called when a click event occured outside the target and children.
     */
    onClickAway: PropTypes.func.isRequired,
    /**
     * Whether the popper is open or not.
     */
    open: PropTypes.bool.isRequired,

    /**
     * A node to be rendered as the child. If there is no targetRef.
     * Target will be used to position the popper.
     */
    target: PropTypes.node,

    /**
     * A referance to the node the popper should be positioned from.
     */
    targetRef: PropTypes.any,

    /**
     * A string of the possible placements specified in popper.js documentation
     */
    placement: PropTypes.string,

    /**
     * If an arrow should be shown or not.
     */
    arrow: PropTypes.bool,
    /**
     * Whether the popper should
     */
    preventOverflow: PropTypes.bool,

    /**
     * Whether the popper should flip when moving out out boundries.
     */
    flip: PropTypes.bool,

    /**
     * A custom offset from the target.
     */
    offset: PropTypes.number,

    /**
     * A className to be attached to the arrow component.
     */
    arrowClassName: PropTypes.string,
    ArrowComponent: PropTypes.any,
    arrowSize: PropTypes.oneOf(["default", "small"]),
    boundariesElement: PropTypes.any
};

Popper.defaultProps = {
    placement: "bottom",
    ArrowComponent: ArrowIcon,
    arrow: true,
    open: false,
    flip: false,
    preventOverflow: false,
    arrowSize: "default",
    popperStyle: {}
};

export default withStyles(styles)(Popper);
