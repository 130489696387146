
import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';
export default function (props) {
    return (
        <SvgIcon {...props} viewBox='0 0 8.06 10'>
            <path d='M3.53,8.3V.5A.5.5,0,0,1,4,0a.5.5,0,0,1,.5.5V8.3L7.18,5.65a.5.5,0,1,1,.75.65l0,.06-3.5,3.5a.5.5,0,0,1-.7,0L.18,6.36a.51.51,0,0,1-.06-.71.49.49,0,0,1,.7-.06l.06.06L3.53,8.3Z'
            />
        </SvgIcon>

    )
}

