import moment from "moment";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import IndexPicker from "./IndexPicker";

class YearPicker extends PureComponent {
    onChangeNext = () => {
        const { year, onChange } = this.props;
        const thisMoment = moment([year]).add(1, "y");

        onChange(thisMoment.year());
    };
    onChangePrev = () => {
        const { year, onChange } = this.props;
        const thisMoment = moment([year]).subtract(1, "y");

        onChange(thisMoment.year());
    };

    render() {
        const { year } = this.props;

        const textValue = year.toString();

        return (
            <IndexPicker
                center={textValue}
                onLeftClick={this.onChangePrev}
                onRightClick={this.onChangeNext}
            />
        );
    }
}

YearPicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    //Dateobject of current date
    year: PropTypes.number.isRequired
};
YearPicker.defaultProps = {
    year: moment().year()
};
export default YearPicker;
