

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function (props) {
    return (
        <SvgIcon {...props} viewBox='0 0 9.8 5.4'>
            <path d='M.53,0H9.26a.53.53,0,0,1,.38.91L5.27,5.24a.51.51,0,0,1-.74,0L.16.91A.53.53,0,0,1,.53,0Z'
            />
        </SvgIcon>

    )
};

