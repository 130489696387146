import React, { PureComponent } from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import classNames from "classnames";
import Typography from "../Typography";
import CaretIcon from "../icons/CaretIcon";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as _ from "lodash";
import LockIcon from "../icons/LockIcon";

/**
 *
 * @description A component to be used as the basis for a select. Works as a button
 */

const styles = theme => ({
    button: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "inherit",
        borderRadius: "6px",
        minWidth: "110px",
        height: "40px",
        "&:hover": {
            color: theme.palette.C_LB,
            "& $caretIcon": {
                color: theme.palette.C_LB
            }
        },

        padding: "0 20px 0 15px",
        fontWeight: "600",
        color: theme.palette.C_DB120, // Will be inherited by the text content
        fontSize: theme.typography.F_S, // Will be inherited by the text content
        "&:disabled": {
            backgroundColor: theme.palette.C_DB5
        }
    },
    whiteBackground: {
        border: `solid 1px ${theme.palette.C_DB10}`,

        "&:hover": {
            border: `solid 1px ${theme.palette.C_DB15}`
        }
    },
    greyBackground: {
        border: `solid 1px ${theme.palette.C_DB15}`,

        "&:hover": {
            border: `solid 1px ${theme.palette.C_DB20}`
        }
    },
    caretIcon: {
        width: "10px",
        right: "15px",
        color: theme.palette.C_DB40,
        "pointer-events": "none", // Don't block pointer events on the select under the icon.
        marginLeft: "10px",
        marginTop: "1px"
    },
    error: {
        borderColor: theme.palette.C_R,
        "&:hover": {
            borderColor: theme.palette.C_R
        }
    }
});
class PopupButton extends PureComponent {
    renderChildren = () => {
        const { children, classes, fontSize } = this.props;
        if (_.isString(children)) {
            return (
                <Typography
                    size={fontSize ? fontSize : "smallMed"}
                    color={"inherit"}
                    className={classes.text}
                    singleLine
                    align={"left"}
                >
                    {children}
                </Typography>
            );
        } else if (!children) {
            return <Typography>{""}</Typography>;
        }
        return children;
    };
    render() {
        const {
            classes,
            className,
            caretClassName,
            background,
            disabled,
            locked,
            caret,
            error,
            ...other
        } = this.props;
        return (
            <ButtonBase
                aria-haspopup="true"
                className={classNames(
                    classes.button,
                    {
                        [classes.disabled]: disabled || locked,
                        [classes.whiteBackground]: background === "white",
                        [classes.greyBackground]: background === "grey",
                        [classes.error]: !!error
                    },
                    className
                )}
                disableRipple
                disabled={disabled || locked}
                {...other}
            >
                {this.renderChildren()}
                {!locked ? (
                    caret ? (
                        <CaretIcon
                            className={classNames(
                                classes.caretIcon,
                                caretClassName
                            )}
                        />
                    ) : null
                ) : (
                    <LockIcon
                        className={classNames(
                            classes.caretIcon,
                            caretClassName
                        )}
                    />
                )}
            </ButtonBase>
        );
    }
}

PopupButton.propTypes = {
    /**
     * A classname to customize the container
     */
    className: PropTypes.string,
    /**
     * A classname to customize the caret
     */
    caretClassName: PropTypes.string,
    /**
     * Value to render inside the button. Will be rendered text to the caret.
     * If the type is string, it will be wrapped in a typography tag.
     * If the type is a node, it will be rendered directly
     */
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    /**
     * Whether the button is clickable or not
     */
    disabled: PropTypes.bool,
    locked: PropTypes.bool,
    background: PropTypes.oneOf(["white", "grey"]),
    caret: PropTypes.bool
};

PopupButton.defaultProps = {
    disabled: false,
    background: "grey",
    caret: true,
    locked: false
};

export default withStyles(styles)(PopupButton);
