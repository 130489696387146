import { httpRequest } from "./helpers";

export const getHelpFAQData = async () => {
    const res = await httpRequest(`/api/v1/help?type=FAQ`, "GET");
    return res.data;
};

export const getHelpCalculationData = async () => {
    const res = await httpRequest(`/api/v1/help?type=calculations`, "GET");
    return res.data;
};

export const setHelpFAQData = async FAQData => {
    return await httpRequest(`/api/v1/help?type=FAQ`, "POST", {
        data: FAQData
    });
};

export const setHelpCalculationData = async calculationData => {
    return await httpRequest(`/api/v1/help?type=calculations`, "POST", {
        data: calculationData
    });
};
