import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
const styles = theme => ({
    divider: {
        width: "100%",
        height: "0px",
        borderTop: `solid 1px ${theme.palette.C_DB10}`,
        marginBottom: "15px"
    }
});

class CardDivider extends PureComponent {
    render() {
        const { classes, className } = this.props;

        return <div className={classNames(classes.divider, className)} />;
    }
}

export default withStyles(styles)(CardDivider);
