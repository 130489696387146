import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Login from "./Login";
import PickerDialog from "../dialogs/PickerDialog";
import { isEmpty, map } from "lodash";
import { Redirect, withRouter } from "react-router-dom";
import {
    REQUEST_LOG_IN,
    REQUEST_AUTH_UPDATE,
    userGroups
} from "../../actionTypes";
import qs from "query-string";

class LoginContainer extends Component {
    state = {
        email: "",
        password: "",
        userId: "",
        identities: []
    };

    componentDidMount() {
        const { auth } = this.props;
        if (auth.firebaseToken && !auth.hasValidatedToken) {
            this.props.dispatch({ type: REQUEST_AUTH_UPDATE });
        }
    }

    componentWillReceiveProps(nextProps) {
        const { actionNeeded } = nextProps.auth;
        if (!isEmpty(actionNeeded)) {
            this.setState({ identities: actionNeeded.identities });
        }
    }

    onLogin = async e => {
        e.preventDefault();
        const email = this.email.value;
        const password = this.password.value;

        const { dispatch } = this.props;
        dispatch({ type: REQUEST_LOG_IN, payload: { email, password } });
    };

    onChoiceCancel = () => {
        this.setState({ identities: [] });
    };

    onChoiceConfirm = index => {
        const email = this.email.value;
        const password = this.password.value;

        const userId = this.state.identities[index].userId;

        this.props.dispatch({
            type: REQUEST_LOG_IN,
            payload: { email, password, userId }
        });
    };

    render() {
        const { auth, appState, location } = this.props;
        const { identities } = this.state;

        //If user has had a previous session. Wait for token to be validated before showing data
        if (isEmpty(auth.user) && auth.firebaseToken && !auth.hasValidatedToken)
            return null;

        if (auth.user && !isEmpty(auth.user)) {
            const { redirectTo } = qs.parse(location.search);
            let url = `/dashboard/${auth.user.companyId}/users/${
                auth.user.id
            }/`;
            if (
                auth.user.userGroup === userGroups.DEPARTMENT_LEADER ||
                auth.user.userGroup === userGroups.ADMIN
            ) {
                url = `/dashboard/${auth.user.companyId}/`;
            }

            if (redirectTo) {
                url = redirectTo + "?redirectTo=" + url;
            }
            return <Redirect to={url} />;
        }
        const choiceDialog =
            identities.length > 0 ? (
                <PickerDialog
                    open={true}
                    onCancel={this.onChoiceCancel}
                    onConfirm={this.onChoiceConfirm}
                    values={map(
                        identities,
                        identity =>
                            `${identity.clientName} (${identity.userName})`
                    )}
                    title="Velg klient"
                    description="Du må velge hvilken klient du ønsker å logge inn med."
                />
            ) : null;

        return (
            <Fragment>
                <Login
                    emailRef={ref => (this.email = ref)}
                    passwordRef={ref => (this.password = ref)}
                    onSubmit={this.onLogin}
                    isFetching={appState.isFetching}
                    disabledConfirm={!!appState.isFetching}
                />
                {choiceDialog}
            </Fragment>
        );
    }
}

export default withRouter(
    connect(state => ({ auth: state.auth, appState: state.appState }))(
        LoginContainer
    )
);
