import React, { Component } from "react";
import { connect } from "react-redux";
import PaperHeader from "../../../components/layout/paper/PaperHeader";
import PaperHeaderTitle from "../../../components/layout/paper/PaperHeaderTitle";
import * as companySelectors from "../../../selectors/company";
import { withRouter } from "react-router-dom";
import { componentDescriptions } from "../../../constants/strings";

export class ProfileHeader extends Component {
    render() {
        return (
            <PaperHeader
                description={componentDescriptions.profile}
                title={
                    <PaperHeaderTitle
                        primary={"Din profil"}
                        secondary={`for ${this.props.companyName}`}
                    />
                }
            />
        );
    }
}

const mapStateToProps = (state, props) => ({
    companyName: companySelectors.getCompanyName(state, props)
});

export default withRouter(connect(mapStateToProps, null)(ProfileHeader));
