import { createSelector } from "reselect";
import * as _ from "lodash";
import { userDetailEvents } from "../../../utils/strings";
import moment from "moment";
import * as userSelectors from "selectors/user";
import * as companySelectors from "selectors/company";

const getCurrentUserId = (_, props) => props.match.params.userId;
const getLogItems = state => state.userLogs;

export const getFullTimeEquivalentFromUser = user => {
    if (!user) return [];

    const fullTimeEqList = _.map(user.fullTimeEquivalent, (val, id) => ({
        ...val,
        fromDate: moment(val.fromDate),
        id
    }));

    const orderedList = _.sortBy(fullTimeEqList, object => {
        return moment(object.fromDate);
    }).reverse();

    //Set fromdate as users startdate, should not be able to update this date!!
    if (orderedList.length > 0) {
        orderedList[orderedList.length - 1].fromDate = user.startDate;
    }
    return orderedList;
};
export const getFullTimeEquivalentHistory = createSelector(
    [userSelectors.getCurrentUser],
    getFullTimeEquivalentFromUser
);

export const getCurrentFullTimeEquivalent = createSelector(
    [getFullTimeEquivalentHistory],
    orderedHistory =>
        _.find(orderedHistory, (item, i) =>
            moment(item.fromDate).isSameOrBefore(moment())
        )
);

export const getUserLogItems = createSelector(
    [getCurrentUserId, getLogItems],
    (currentUserId, logItems) =>
        _.filter(logItems, item => item.regarding === currentUserId)
);

export const getOrderedUserLogItems = createSelector(
    [getUserLogItems],
    logItems => _.sortBy(logItems, item => moment(item.timestamp)).reverse()
);

export const getFormattedUserLogItems = createSelector(
    [
        getOrderedUserLogItems,
        userSelectors.getUserItems,
        userSelectors.getCurrentUser,
        companySelectors.getCompanyDepartments
    ],
    (logItems, shallowUsers, currentUser, departments) => {
        const formattedLogItems = _.map(logItems, item => {
            const actorUser = shallowUsers[item.actor]
                ? shallowUsers[item.actor]
                : {};
            const func = userDetailEvents[item.updatedKey];

            if (!func) return null;

            let description = "";

            switch (item.updatedKey) {
                case "departmentId": {
                    const department = _.find(
                        departments,
                        dep => dep.id === item.newValue
                    );
                    description = func(department && department.name);
                    break;
                }

                case "fullTimeEquivalent":
                    description = func(item.newValue, item.updateAction);
                    break;

                default:
                    description = func(item.newValue);
                    break;
            }

            return {
                ...item,
                description,
                firstName: actorUser.firstName,
                lastName: actorUser.lastName
            };
        });

        return _.filter(formattedLogItems);
    }
);

export const getUserKeyFigures = createSelector(
    getCurrentFullTimeEquivalent,
    fullTimeEquivalent => {
        return [
            {
                name: "Stillingsprosent",
                value: fullTimeEquivalent ? fullTimeEquivalent.value : "",
                type: "percent"
            }
        ];
    }
);
export const getProfileProps = (state, props) => {
    return {
        currentUser: userSelectors.getCurrentUser(state, props),
        signedInUserGroup: userSelectors.getSignedInUser(state, props).userGroup,
        isOwnPage: false
    };
};

export const getUserLogProps = (state, props) => {
    return {
        logItems: getFormattedUserLogItems(state, props)
    };
};
