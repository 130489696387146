import React, { Fragment } from "react";
import Typography from "common/Typography";

export default () => (
    <Fragment>
        <Typography component={"h2"}>Introduction</Typography>
        <Typography>
            DOTS Technologies AS understands that your privacy is important to
            you and that you care about how your information is used and shared
            online. We respect and value the privacy of everyone who visits Our
            Site and use Our Application and will only collect and use
            information in ways that are useful to you and in a manner
            consistent with your rights and Our obligations under the law.
        </Typography>
        <Typography>
            This Policy applies to Our use of any and all data collected by us
            in relation to your use of Our Site and Our Application. Please read
            this Privacy Policy carefully and ensure that you understand it.
            Your acceptance of Our Privacy Policy is deemed to occur upon your
            first use of Our Site or your first login to Our Application. If you
            do not accept and agree with this Privacy Policy, you must stop
            using Our Site and Our Application immediately.
        </Typography>
        <Typography component={"h2"}>
            1. Definitions and Interpretation
        </Typography>
        <Typography>
            In this Policy the following terms shall have the following
            meanings:
        </Typography>
        <Typography component={"h4"}>“Account”</Typography>
        <Typography>
            means an account required to access and/or use certain areas and
            features of Our Site;
        </Typography>
        <Typography component={"h4"}>“Cookie”</Typography>
        <Typography>
            means a small text file placed on your computer or device by Our
            Site when you visit certain parts of Our Site and/or when you use
            certain features of Our Site. Details of the Cookies used by Our
            Site are set out in section 12, below;
        </Typography>
        <Typography component={"h4"}>“Our Site”</Typography>
        <Typography>means this website, www.busy.no;</Typography>
        <Typography component={"h4"}>“Our Application”</Typography>
        <Typography>means the webapplication, app.busy.no;</Typography>
        <Typography component={"h4"}>“Norwegian and EU Cookie Law”</Typography>
        <Typography>
            means the relevant parts of the Privacy and Electronic
            Communications (EC Directive) Regulations 2003 as amended in 2004,
            2011 and 2015;
        </Typography>
        <Typography component={"h4"}>“We/Us/Our”</Typography>
        <Typography>
            means DOTS Technologies AS , a limited company registered in Norway
            under 914 761 719, whose registered address is Tordenskiolds gate 2,
            0160 Oslo, Norway.
        </Typography>
        <Typography component={"h2"}>2. Information About Us</Typography>
        <Typography>
            Our Site and Our Application, www.busy.no and app.busy.no, is owned
            and operated by DOTS Technologies AS, a limited company registered
            in Norway under 914 761 719, Tordenskiolds gate 2, 0160 Oslo,
            Norway.
        </Typography>
        <Typography>
            Our data protection officer is Jonas Klafstad who can be contacted
            at jonas@dots.no
        </Typography>
        <Typography component={"h2"}>
            3. Scope – What Does This Policy Cover?
        </Typography>
        <Typography>
            This Privacy Policy applies only to your use of Our Site and Our
            Application. It does not extend to any websites that are linked to
            from Our Site (whether We provide those links or whether they are
            shared by other users). We have no control over how your data is
            collected, stored or used by other websites and We advise you to
            check the privacy policies of any such websites before providing any
            data to them.
        </Typography>
        <Typography component={"h2"}>4. What Data Do We Collect?</Typography>
        <Typography>
            Some data will be collected automatically by Our Site and Our
            Application (for further details, please see section 12 on Our use
            of Cookies), other data will only be collected if you voluntarily
            submit it and consent to Us using it for the purposes set out in
            section 5, for example, when filling in the Try For Free form or
            logging in to Our Application.
        </Typography>
        <Typography component={"h4"}>
            Depending upon your use of Our Site, We may collect some or all of
            the following data:
        </Typography>
        <ul>
            <Typography component={"li"}>Name</Typography>
            <Typography component={"li"}>Email</Typography>
            <Typography component={"li"}>Phone</Typography>
            <Typography component={"li"}>Business/company name</Typography>
            <Typography component={"li"}>
                Web browser type and version (automatically collected)
            </Typography>
            <Typography component={"li"}>
                Operating system (automatically collected)
            </Typography>
            <Typography component={"li"}>
                A list of URLs starting with a referring site
            </Typography>
            <Typography component={"li"}>Your activity on our site</Typography>
            <Typography component={"li"}>
                The site you exit to (automatically collected)
            </Typography>
        </ul>
        <Typography component={"h4"}>
            Depending upon your use of Our Application, We may collect some or
            all of the following data:
        </Typography>
        <ul>
            <Typography component={"li"}>Name</Typography>
            <Typography component={"li"}>Email</Typography>
            <Typography component={"li"}>Phone</Typography>
            <Typography component={"li"}>Job title</Typography>
            <Typography component={"li"}>Profession</Typography>
            <Typography component={"li"}>Time data</Typography>
            <Typography component={"li"}>
                Work related information such as department, percentage of
                employment, date of employment, log related to changes in
                employment etc.
            </Typography>
            <Typography component={"li"}>
                Web browser type and version (automatically collected)
            </Typography>
            <Typography component={"li"}>
                Operating system (automatically collected)
            </Typography>
            <Typography component={"li"}>
                A list of URLs starting with a referring site
            </Typography>
            <Typography component={"li"}>Your activity on our site</Typography>
            <Typography component={"li"}>
                The site you exit to (automatically collected)
            </Typography>
        </ul>
        <Typography component={"h2"}>5. How Do We Use Your Data?</Typography>
        <Typography>
            All personal data is stored securely in accordance with the EU
            General Data Protection Regulation (Regulation (EU) 2016/679)
            (GDPR). For more details on security see section 6, below.
        </Typography>
        <Typography>
            With your permission and/or where permitted by law, We may also use
            your data for marketing purposes which may include contacting you by
            email AND/OR telephone with information, news and offers on Our
            products AND/OR services. We will not, however, send you any
            unsolicited marketing or spam and will take all reasonable steps to
            ensure that We fully protect your rights and comply with Our
            obligations under the GDPR and the Privacy and Electronic
            Communications (EC Directive) Regulations 2003, as amended in 2004,
            2011 and 2015.
        </Typography>
        <Typography component={"h2"}>
            6. How and Where Do We Store Your Data?
        </Typography>
        <Typography>
            We only keep your data for as long as We need to in order to use it
            as described above in section 5, and/or for as long as We have your
            permission to keep it. In any event, We will conduct an annual
            review to ascertain whether we need to keep your data. Your data
            will be deleted if we no longer need it in accordance with the terms
            of our Data Retention Policy.
        </Typography>
        <Typography>
            When using Our Application your data will only be stored within the
            European Economic Area (“the EEA”) (The EEA consists of all EU
            member states, plus Norway, Iceland and Liechtenstein).Some or all
            of your data may be stored or transferred outside of the European
            Economic Area (the EEA) (The EEA consists of all EU member states,
            plus Norway, Iceland and Liechtenstein). You are deemed to accept
            and agree to this by using Our Site and submitting information to
            Us. If We do store or transfer data outside the EEA, We will take
            all reasonable steps to ensure that your data is treated as safely
            and securely as it would be within the EEA and under the Data
            Protection Act 1998. Such steps may include, but not be limited to,
            the use of legally binding contractual terms between Us and any
            third parties We engage and the use of the EU-approved Model
            Contractual Arrangements.
        </Typography>
        <Typography>
            Data security is of great importance to Us, and to protect your data
            We have put in place suitable physical, electronic and managerial
            procedures to safeguard and secure data collected through Our Site
            and Our Application.
        </Typography>
        <Typography>
            Notwithstanding the security measures that We take, it is important
            to remember that the transmission of data via the internet may not
            be completely secure and that you are advised to take suitable
            precautions when transmitting to Us data via the internet.
        </Typography>
        <Typography component={"h2"}>7. Do We Share Your Data?</Typography>
        <Typography>
            We may share your data with other companies in Our group. This
            includes Our subsidiaries.
        </Typography>
        <Typography>
            We may contract with third parties to supply services to you on Our
            behalf. These may include payment processing, delivery of goods,
            search engine facilities, advertising and marketing. In some cases,
            the third parties may require access to some or all of your data.
            Where any of your data is required for such a purpose, We will take
            all reasonable steps to ensure that your data will be handled
            safely, securely, and in accordance with your rights, Our
            obligations, and the obligations of the third party under the law.
        </Typography>
        <Typography component={"h4"}>Our Application</Typography>
        <Typography>Google Cloud Services (Hosting and Storage)</Typography>
        <Typography>Intercom (Support)</Typography>
        <Typography component={"h4"}>Our Site</Typography>
        <Typography>Google (Analytics)</Typography>
        <Typography>Intercom (Support)</Typography>
        <Typography>Webflow (Hosting)</Typography>
        <Typography>
            We may compile statistics about the use of Our Site and Our
            Application including data on traffic, usage patterns, user numbers,
            sales and other information. All such data will be anonymised and
            will not include any personally identifying information. We may from
            time to time share such data with third parties such as prospective
            investors, affiliates, partners and advertisers. Data will only be
            shared and used within the bounds of the law.
        </Typography>
        <Typography>
            In certain circumstances We may be legally required to share certain
            data held by Us, which may include your personal information, for
            example, where We are involved in legal proceedings, where We are
            complying with the requirements of legislation, a court order, or a
            governmental authority. We do not require any further consent from
            you in order to share your data in such circumstances and will
            comply as required with any legally binding request that is made of
            Us.
        </Typography>
        <Typography component={"h2"}>
            8. What Happens If Our Business Changes Hands?
        </Typography>
        <Typography>
            We may, from time to time, expand or reduce Our business and this
            may involve the sale and/or the transfer of control of all or part
            of Our business. Data provided by users will, where it is relevant
            to any part of Our business so transferred, be transferred along
            with that part and the new owner or newly controlling party will,
            under the terms of this Privacy Policy, be permitted to use the data
            for the purposes for which it was originally collected by Us, unless
            otherwise agreed in a contract to which you are a party.
        </Typography>
        <Typography component={"h4"}>How Can You Control Your Data?</Typography>
        <Typography>
            When you submit information via Our Site, you may be given options
            to restrict Our use of your data. We aim to give you strong controls
            on Our use of your data (including the ability to opt-out of
            receiving emails from Us which you may do by unsubscribing using the
            links provided in Our emails and AND/OR by managing your Account).
        </Typography>
        <Typography>
            9. Your Right to Withhold Information and Your Right to Withdraw
            Information After You Have Given it
        </Typography>
        <Typography>
            You may access certain areas of Our Site without providing any data
            at all. However, to use all features and functions available on Our
            Site and Our Application you may be required to submit or allow for
            the collection of certain data.
        </Typography>
        <Typography>
            You may restrict your internet browser’s use of Cookies. For more
            information, see section 11.
        </Typography>
        <Typography>
            You may withdraw your consent for Us to use your personal data as
            set out in section 5 at any time by contacting Us using the details
            set out in section 15, and We will delete Your data from Our
            systems. However, you acknowledge this may limit Our ability to
            provide the best possible products and services to you.
        </Typography>
        <Typography component={"h2"}>
            10. How Can You Access Your Data?
        </Typography>
        <Typography>
            You have the legal right to ask for a copy of any of your personal
            data held by Us (where such data is held). Please contact Us for
            more details at support@busy.no, or using the contact details below
            in section 14.
        </Typography>
        <Typography component={"h2"}>
            11. What Cookies Do We Use and What For?
        </Typography>
        <Typography>
            Our Site and Our Application may place and access certain first
            party Cookies on your computer or device. First party Cookies are
            those placed directly by Us and are used only by Us. We use Cookies
            to facilitate and improve your experience of Our Site and to provide
            and improve Our products and services. For more details, please
            refer to section 5, above, and to first party cookie list below. We
            have carefully chosen these Cookies and have taken steps to ensure
            that your privacy is protected and respected at all times.
        </Typography>
        <Typography>
            By using Our Site you may also receive certain third party Cookies
            on your computer or device. Third party Cookies are those placed by
            websites, services, and/or parties other than Us. We use third party
            Cookies on Our Site for analytics and support. We use third party
            Cookies on Our Application for monitoring and support. For more
            details, please refer to section 5, above, and third party cookie
            list below. These Cookies are not integral to the functioning of Our
            Site.
        </Typography>
        <Typography>
            All Cookies used by and on Our Site and Our Application are used in
            accordance with current Norwegian and EU Cookie Law.When using Our
            Site, before any Cookies are placed on your computer or device, you
            will be shown a message bar requesting your consent to set those
            Cookies. By giving your consent to the placing of Cookies you are
            enabling Us to provide the best possible experience and service to
            you. You may, if you wish, deny consent to the placing of Cookies;
            however certain features of Our Site may not function fully or as
            intended.
        </Typography>
        <Typography>
            When using Our Application, certain features of Our Application
            depend on Cookies to function. Norwegian and EU Cookie Law deems
            these Cookies to be “strictly necessary”. These Cookies are shown
            below. Your consent will not be sought to place these Cookies. You
            may still block these Cookies by changing your internet browser’s
            settings as detailed below, but please be aware that Our Application
            may not work as intended if you do so. We have taken great care to
            ensure that your privacy is not at risk by allowing them.
        </Typography>
        <hr noshade="true" />
        <Typography component={"h4"}>
            Third party cookies when using Our Site:
        </Typography>
        <table>
            <thead>
                <tr>
                    <Typography component={"td"}>Name of cookie:</Typography>
                    <Typography component={"td"}>Provider:</Typography>
                    <Typography component={"td"}>Purpose:</Typography>
                </tr>
            </thead>
            <tr>
                <Typography component={"td"}>_ga</Typography>
                <Typography component={"td"}>Google Analytics</Typography>
                <Typography component={"td"}>Analytics</Typography>
            </tr>
            <tr>
                <Typography component={"td"}>_gid</Typography>
                <Typography component={"td"}>Google Analytics</Typography>
                <Typography component={"td"}>Analytics</Typography>
            </tr>

            <tr>
                <Typography component={"td"}>_gat</Typography>
                <Typography component={"td"}>Google Analytics</Typography>
                <Typography component={"td"}>Analytics</Typography>
            </tr>

            <tr>
                <Typography component={"td"}>_intercom-id-xx</Typography>
                <Typography component={"td"}>Intercom</Typography>
                <Typography component={"td"}>Support Chat</Typography>
            </tr>
        </table>
        <hr noshade="true" />
        <Typography component={"h4"}>
            First party cookies when using Our Application:
        </Typography>

        <table>
            <thead>
                <tr>
                    <Typography component={"td"}>Name of cookie:</Typography>
                    <Typography component={"td"}>Purpose:</Typography>
                    <Typography component={"td"}>
                        Strictly necessary:
                    </Typography>
                </tr>
            </thead>
            <tr>
                <Typography component={"td"}>auth</Typography>
                <Typography component={"td"}>Remember session</Typography>
                <Typography component={"td"}>Yes</Typography>
            </tr>
        </table>
        <hr noshade="true" />
        <Typography component={"h4"}>
            Third party cookies when using Our Application:
        </Typography>
        <table>
            <thead>
                <tr>
                    <Typography component={"td"}>Name of cookie:</Typography>
                    <Typography component={"td"}>Provider:</Typography>
                    <Typography component={"td"}>Purpose:</Typography>
                </tr>
            </thead>
            <tr>
                <Typography component={"td"}>_ga</Typography>
                <Typography component={"td"}>Google Analytics</Typography>
                <Typography component={"td"}>Analytics</Typography>
            </tr>
            <tr>
                <Typography component={"td"}>_gid</Typography>
                <Typography component={"td"}>Google Analytics</Typography>
                <Typography component={"td"}>Analytics</Typography>
            </tr>

            <tr>
                <Typography component={"td"}>_gat</Typography>
                <Typography component={"td"}>Google Analytics</Typography>
                <Typography component={"td"}>Analytics</Typography>
            </tr>

            <tr>
                <Typography component={"td"}>_intercom-id-xx</Typography>
                <Typography component={"td"}>Intercom</Typography>
                <Typography component={"td"}>Support Chat</Typography>
            </tr>
        </table>
        <hr noshade="true" />

        <Typography>
            Our Site uses analytics services provided by Google. Website
            analytics refers to a set of tools used to collect and analyse usage
            statistics, enabling Us to better understand how people use Our
            Site. This, in turn, enables Us to improve Our Site and the products
            and services offered through it. You do not have to allow Us to use
            these Cookies, as detailed below, however whilst Our use of them
            does not pose any risk to your privacy or your safe use of Our Site,
            it does enable Us to continually improve Our Site, making it a
            better and more useful experience for you.
        </Typography>
        <Typography>
            The analytics service(s) used by Our Site use(s) Cookies to gather
            the required information.
        </Typography>
        <Typography>
            You can choose to enable or disable Cookies in your internet
            browser. Most internet browsers also enable you to choose whether
            you wish to disable all cookies or only third party cookies. By
            default, most internet browsers accept Cookies but this can be
            changed. For further details, please consult the help menu in your
            internet browser or the documentation that came with your device.
        </Typography>
        <Typography>
            You can choose to delete Cookies at any time however you may lose
            any information that enables you to access Our Site more quickly and
            efficiently including, but not limited to, login and personalisation
            settings.
        </Typography>
        <Typography>
            It is recommended that you keep your internet browser and operating
            system up-to-date and that you consult the help and guidance
            provided by the developer of your internet browser and manufacturer
            of your computer or device if you are unsure about adjusting your
            privacy settings.
        </Typography>
        <Typography component={"h2"}>
            12. Summary of Your Rights under GDPR Under the GDPR, you have:
        </Typography>
        <Typography>
            <Typography component={"span"} bold>
                12.1
            </Typography>{" "}
            the right to request access to, deletion of or correction of, your
            personal data held by Us
        </Typography>
        <Typography>
            <Typography component={"span"} bold>
                12.2
            </Typography>{" "}
            the right to complain to a supervisory authority; be informed of
            what data processing is taking place
        </Typography>
        <Typography>
            <Typography component={"span"} bold>
                12.3
            </Typography>{" "}
            the right to restrict processing; the right to data portability;
            object to processing of your personal data
        </Typography>
        <Typography>
            <Typography component={"span"} bold>
                12.4
            </Typography>{" "}
            rights with respect to automated decision-making and profiling (see
            section 14 below).
        </Typography>
        <Typography>
            To enforce any of the foregoing rights or if you have any other
            questions about Our Site or this Privacy Policy, please contact Us
            using the details set out in section 15 below.
        </Typography>
        <Typography component={"h2"}>
            13. Automated Decision-Making and Profiling
        </Typography>
        <Typography>
            In the event that We use personal data for the purposes of automated
            decision-making and those decisions have a legal (or similarly
            significant effect) on You, You have the right to challenge to such
            decisions under GDPR, requesting human intervention, expressing
            their own point of view, and obtaining an explanation of the
            decision from Us.
        </Typography>
        <Typography component={"h4"}>
            The right described above does not apply in the following
            circumstances:
        </Typography>
        <ul>
            <Typography component={"li"}>
                ‍The decision is necessary for the entry into, or performance
                of, a contract between the You and Us
            </Typography>
            <Typography component={"li"}>
                ‍The decision is authorised by law; or
            </Typography>
            <Typography component={"li"}>
                ‍You have given you explicit consent.
            </Typography>
        </ul>
        <Typography component={"h4"}>
            Where We use your personal data for profiling purposes, the
            following shall apply:
        </Typography>
        <ul>
            <Typography component={"li"}>
                ‍Clear information explaining the profiling will be provided,
                including its significance and the likely consequences
            </Typography>
            <Typography component={"li"}>
                ‍Appropriate mathematical or statistical procedures will be used
            </Typography>
            <Typography component={"li"}>
                ‍Technical and organisational measures necessary to minimise the
                risk of errors and to enable such errors to be easily corrected
                shall be implemented; and All personal data processed for
                profiling purposes shall be secured in order to prevent
                discriminatory effects arising out of profiling
            </Typography>
        </ul>
        <Typography component={"h2"}>14. Contacting Us</Typography>
        <Typography>
            If you have any questions about Our Site, Our Application or this
            Privacy Policy, please contact Us by email at support@busy.no.
            Please ensure that your query is clear, particularly if it is a
            request for information about the data We hold about you (as under
            section 11, above).
        </Typography>
        <Typography component={"h2"}>
            15. Changes to Our Privacy Policy
        </Typography>
        <Typography>
            We may change this Privacy Policy as we may deem necessary from time
            to time, or as may be required by law. Any changes will be
            immediately posted on Our Site and you will be deemed to have
            accepted the terms of the Privacy Policy on your first use of Our
            Site following the alterations. We recommend that you check this
            page regularly to keep up-to-date.
        </Typography>
    </Fragment>
);
